import { InverseMap, ValuesOf } from '.'
import { OPTION_VALUE_TYPES } from './types.template'
import { CASUS_INPUT_TYPES } from './types.components'

// =============================================================================== //
// ============================== CONSTANTS & TYPES ============================== //
// =============================================================================== //
export const TEMPLATE_FLOW_MODES = {
  CONFIGURE: 'template-configuration',
  NEW: 'template-automation',
  CONTENT: 'template-content',
} as const
export type TemplateFlowMode = ValuesOf<typeof TEMPLATE_FLOW_MODES>
export const DOCUMENT_FLOW_MODES = {
  TEMPLATE_SELECT: 'document-template-select',
  NEW: 'document-generation',
  EDIT: 'document-edit',
  PREVIEW: 'document-preview',
  // CONTENT: 'document-content',
} as const
export type DocumentFlowMode = ValuesOf<typeof DOCUMENT_FLOW_MODES>
export const TEMPLATE_DIRECTORY = 'template' as const
export const DOCUMENT_DIRECTORY = 'document' as const
export const DIRECTORY_FLOWS = {
  [TEMPLATE_DIRECTORY]: TEMPLATE_FLOW_MODES,
  [DOCUMENT_DIRECTORY]: DOCUMENT_FLOW_MODES,
} as const
export const DIRECTORY_DEFAULT_FLOW_MODE = {
  [TEMPLATE_DIRECTORY]: TEMPLATE_FLOW_MODES.CONFIGURE,
  [DOCUMENT_DIRECTORY]: DOCUMENT_FLOW_MODES.TEMPLATE_SELECT,
}
export type Floki = ValuesOf<{ [K in keyof typeof DIRECTORY_FLOWS]: keyof (typeof DIRECTORY_FLOWS)[K] }>
export const WIZARD_MODE_DIRECTORY_MAP = {
  [TEMPLATE_FLOW_MODES.CONFIGURE]: TEMPLATE_DIRECTORY,
  [TEMPLATE_FLOW_MODES.NEW]: TEMPLATE_DIRECTORY,
  [TEMPLATE_FLOW_MODES.CONTENT]: TEMPLATE_DIRECTORY,
  [DOCUMENT_FLOW_MODES.TEMPLATE_SELECT]: DOCUMENT_DIRECTORY,
  [DOCUMENT_FLOW_MODES.NEW]: DOCUMENT_DIRECTORY,
  [DOCUMENT_FLOW_MODES.EDIT]: DOCUMENT_DIRECTORY,
  [DOCUMENT_FLOW_MODES.PREVIEW]: DOCUMENT_DIRECTORY,
  // [DOCUMENT_FLOW_MODES.CONTENT]: DOCUMENT_DIRECTORY,
} as const

export type WizardMode = keyof typeof WIZARD_MODE_DIRECTORY_MAP
export const isModeDocumentFlow = (mode?: WizardMode | null) => mode?.split('-')[0] === 'document'
export const isModeTemplateFlow = (mode?: WizardMode | null) => mode?.split('-')[0] === 'template'
export const DIRECTORY_WIZARD_MODE_MAP = Object.entries(WIZARD_MODE_DIRECTORY_MAP).reduce(
  //@ts-ignore
  (map, [mode, directory]) => Object.assign(map, { [directory]: (map[directory] || []).concat(mode as WizardMode) }),
  {} as InverseMap<typeof WIZARD_MODE_DIRECTORY_MAP>
)
export type Directory = keyof typeof DIRECTORY_WIZARD_MODE_MAP
export const INPUT_TYPE_MAP = {
  [OPTION_VALUE_TYPES.STRING]: CASUS_INPUT_TYPES.MULTILINE,
  [OPTION_VALUE_TYPES.NUMBER]: CASUS_INPUT_TYPES.NUMBER,
  [OPTION_VALUE_TYPES.DATE]: CASUS_INPUT_TYPES.DATE_TIME,
  [OPTION_VALUE_TYPES.DATE_TIME]: CASUS_INPUT_TYPES.DATE_TIME,
  [OPTION_VALUE_TYPES.CURRENCY]: CASUS_INPUT_TYPES.CURRENCY,
} as const
// =============================================================================== //

// =============================================================================== //
// ============================= REGULAR EXPRESSIONS ============================= //
// =============================================================================== //
export const DIRECTORY_MODE_PATHNAME_MATCH = new RegExp(
  `^/(?<directory>(${Object.keys(DIRECTORY_WIZARD_MODE_MAP).concat('public').join('|')}))(?<id>/([^/]+)){0,2}?((/(?<mode>[^/]+))?)?$`,
  's'
)
export const WIZARD_URL_PARAM_MATCH_ALL = new RegExp(`(?<match>(?<key>[^?=&]+)=(?<value>[^?=&]+))`, 'g')
// export const DIRECTORY_MATCH = new RegExp('^/(?<directory>[^/]+)', 's')
// export const MODE_PARAM_MATCH = new RegExp(`^/(${Object.keys(DIRECTORY_WIZARD_MODE_MAP).join('|')})/([^/]+)/(?<mode>[^/]+)$`, 's')
export const SUB_QUESTION_TO_MATCH = new RegExp('(?<questionId>^.[^:]+):(?<optionGroupId>.[^:]+):(?<optionId>.[^:]+$)', 's')
// export const ANSWER_VALUE_MATCH = new RegExp(`^(?<input>${CASUS_KEYSTRINGS.INPUT}:)?(?<id>(_external_[^:]+:[^:]+)|([^:]*)){1}(:(?<value>.*))?$`, 's')
export const ANSWER_VALUE_MATCH = new RegExp(`^(?<id>(_external_[^:]+:[^:]+)|([^:]*)){1}(:(?<value>.*))?$`, 's')
export const VALUE_SOURCE_MATCH = new RegExp(`^(?<id>[^:]*?){1}:(?<value>.*)?$`, 's')

export const ANSWERING_IDS_MATCH = new RegExp(
  '(?<isFirst>^:)?(?<questionLayoutGroupId>[^:]*):(?<questionId>[^:]+):(?<orderString>[^:]*)(?<isLast>:$)?',
  's'
)
// =============================================================================== //

// =============================================================================== //
// ====================== HELPER FUNCTION CONSTANTS & TYPES ====================== //
// =============================================================================== //
export const FIND_WITH_INDEX_NOT_FOUND = [undefined, -1] as const
export const COMPLEX_GETTER_NOT_FOUND = [undefined, undefined, undefined, -1] as const
// =============================================================================== //
