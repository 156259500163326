import { Question } from 'types'
import questionTypes from 'constants/question-types'
const { TEXT_BOX, DATE, RADIO_REPLACEMENT, RADIO_LINK, CHECKBOX, NUMBER } = questionTypes

interface IGenObj {
  label: string
  value: number
  disabled: boolean
}

const genObj = (label: string, value: number, disabled: boolean): IGenObj => ({
  label,
  value,
  disabled,
})

interface IReturnObj {
  [key: string]: IGenObj
}

interface IDisabledSteps {
  [key: string]: boolean
}

type ReturnTypeReturnObj = {
  selectOptions: IGenObj[]
  disabledSteps: IDisabledSteps
}

const returnObj = (obj: IReturnObj, saveDisabled: boolean): ReturnTypeReturnObj => ({
  selectOptions: Object.values(obj),
  disabledSteps: {
    answerType: obj.answerType.disabled,
    textSelection: obj.textSelection.disabled,
    description: obj.description.disabled,
    saveDisabled,
    subQuestions: obj.subQuestions !== undefined ? obj.subQuestions.disabled : false,
  },
})

export const handleTCDisableState = (currentQuestion: Question, t: any): ReturnTypeReturnObj => {
  let obj: IReturnObj = {
    question: genObj(`1. ${t('question')}`, 1, false),
    answerType: genObj(`2. ${t('response_type')}`, 2, true),
    textSelection: genObj(`3. ${t('response_configuration')}`, 3, true),
    description: genObj(`4. ${t('label_and_practical_tips')}`, 4, true),
  }
  if (!currentQuestion) return returnObj(obj, true)

  const { questionText, tags, type, locations } = currentQuestion

  const locationsRequired =
    type && [TEXT_BOX, DATE, RADIO_REPLACEMENT, NUMBER].includes(currentQuestion.type)
  const optionsRequired =
    type && [RADIO_LINK, CHECKBOX, RADIO_REPLACEMENT].includes(currentQuestion.type)

  const hasQuestionText = Boolean(questionText && questionText.length >= 3)
  const hasAnswerType = Boolean(type)
  const hasLabel = Boolean(tags && tags[0])

  obj = {
    question: genObj(`1. ${t('question')}`, 1, false),
    answerType: genObj(`2. ${t('response_type')}`, 2, !hasQuestionText),
    textSelection: genObj(
      `3. ${t('response_configuration')}`,
      3,
      !hasAnswerType || !hasQuestionText
    ),
    description: genObj(`4. ${t('label_and_practical_tips')}`, 4, true),
  }

  if (optionsRequired) {
    obj.subQuestions = genObj(`5. ${t('sub_questions')}`, 5, true)
  }

  if (!hasQuestionText || !hasAnswerType) return returnObj(obj, !hasLabel)

  if (locationsRequired && !optionsRequired) {
    obj.description.disabled = !locations || !locations.length
    return returnObj(obj, !hasLabel)
  }

  if (optionsRequired && currentQuestion.options) {
    let textSelections = 0
    let totalText = 0

    for (let opt of currentQuestion.options) {
      if (!opt.text && totalText < 2) {
        obj.description.disabled = true
        return returnObj(obj, !hasLabel)
      }

      if (opt.text) totalText += 1

      const shouldCount = Boolean(
        !locationsRequired && opt.locations && opt.locations.length
      )

      if (shouldCount) textSelections++
    }

    const isDescDisabled = !locationsRequired
      ? textSelections === 0
      : !locations || !locations.length

    obj.description.disabled = isDescDisabled
    obj.subQuestions.disabled = isDescDisabled || !hasLabel

    return returnObj(obj, !hasLabel)
  }

  return returnObj(obj, !hasLabel)
}
