import React, { useMemo } from 'react'
import { classes, parseText } from '.'

const TextInputPreview = React.memo(({ content, parseLinks }) => {
  const render = useMemo(() => (parseLinks ? parseText(content) : content), [parseLinks, content])
  return (
    <div className={classes.inputPreview.wrapper}>
      <span className={classes.inputPreview.content}>{render}</span>
    </div>
  )
})

TextInputPreview.displayName = 'Input-preview'

export default TextInputPreview
