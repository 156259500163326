import { Check, Cross } from 'assets/svgIconComponents'
import React, { useCallback, useMemo, useState } from 'react'

const classes = {
  wrapper: 'Input-wrapper',
  input: 'Input-input',
  buttonGroup: 'Input-buttonGroup',
  confirmButton: 'Input-confirmButton',
  discardButton: 'Input-discardButton',
}

export const NumberInput = React.memo(
  ({
    id,
    className: cn,
    inputClass: ic,
    disabled,
    defaultValue,
    value,
    onChange,
    onBlur,
    onDiscard,
    needsConfirmation = false,
    placeholder,
    minimum,
    maximum,
    step,
    autofocus = false,
    blurShowUnmodified = false,
  }) => {
    const [stateValue, setStateValue] = useState(value || defaultValue)
    const [inputNode, setInputNode] = useState(null)

    const ref = useCallback(node => setInputNode(node || null), [])

    const modified = useMemo(() => stateValue !== (value || defaultValue), [stateValue, value, defaultValue])

    const className = useMemo(() => [classes.wrapper, cn].filter(cn => cn).join(' '), [cn])
    const inputClass = useMemo(() => [classes.input, ic].filter(ic => ic).join(' '), [ic])

    const calculatedValue = needsConfirmation ? stateValue : value
    const direction = Math.sign(calculatedValue - (minimum + maximum) / 2) + 1
    const outside = Math.sign(calculatedValue - [minimum, NaN, maximum][direction]) + 1 === direction

    return (
      <div
        id={id}
        className={className}
        data-modified={modified}
        data-can-discard={typeof onDiscard === 'function'}
        style={{ '--unmodified': `"${value || defaultValue || placeholder}"` }}
        data-type="number"
        data-show-unmodified={blurShowUnmodified}
        data-outside-range={outside && ['below', false, 'above'][direction]}
        data-on-step={false} // implement later
        onBlur={event => typeof onBlur === 'function' && onBlur(event, modified, calculatedValue)}
      >
        <input
          ref={ref}
          type="number"
          className={inputClass}
          tabIndex={disabled ? -1 : 0}
          placeholder={placeholder || ''}
          value={String(calculatedValue).replace(/(^0+)(?!,)(?![.])(?!$)/, '')}
          min={minimum}
          max={maximum}
          step={step}
          onKeyDown={event => {
            if (event.key === 'Enter' && needsConfirmation) {
              event.preventDefault()
              event.target.blur()
              if (typeof onChange === 'function') onChange(stateValue)
            }
          }}
          onInput={event => {
            const value = Number(event.target.value)
            setStateValue(value)
            return !needsConfirmation && typeof onChange === 'function' && onChange(value)
          }}
          onChange={() => {}}
          // onChange={event => {
          //   const value = Number(event.target.value.replaceAll(/[^0-9.,]+/g, ''))
          //   setStateValue(value)
          //   return !needsConfirmation && typeof onChange === 'function' && onChange(value)
          // }}
          autoFocus={autofocus}
        />
        {needsConfirmation && modified ? (
          <div className={classes.buttonGroup} data-hide-on-blur={blurShowUnmodified}>
            <button
              className={classes.confirmButton}
              type="button"
              onClick={event => {
                event.target.blur()
                if (typeof onChange === 'function') onChange(stateValue)
              }}
            >
              <Check />
            </button>
            {typeof onDiscard === 'function' ? (
              <button
                className={classes.discardButton}
                type="button"
                onClick={() => {
                  if (inputNode) inputNode.focus()
                  setStateValue(value || defaultValue)
                  onDiscard()
                }}
              >
                <Cross />
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }
)

NumberInput.displayName = 'Input'

export default NumberInput
