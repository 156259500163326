import React, { FunctionComponent } from 'react'

import { DOCUMENTS_FILTERS } from '___types'
import { FilteredTemplatesGridProps } from '.'
import TemplatesGrid from './TemplatesGrid'

export const Company: FunctionComponent<FilteredTemplatesGridProps> = React.memo(({ onClick }) => {
  return <TemplatesGrid filter={DOCUMENTS_FILTERS.COMPANY} onClick={onClick} />
})

Company.displayName = 'Casus-Components-Grid-Templates-Company'

export default Company
