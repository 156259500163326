import { useQuery, QueryFunctionContext } from 'react-query'

import { userAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

const getExternalApiEntriesQueryFunction = ({ queryKey }: QueryFunctionContext) => userAPI.getExternalApiEntries(queryKey[1] as string)

export const useFetchExternalServiceEntries = (service: string) => {
  const templateQuery = useQuery({
    queryKey: [QUERY_KEYS.EXTERNAL_SERVICE_ENTRIES, service],
    queryFn: getExternalApiEntriesQueryFunction,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  })
  return templateQuery
}

export default useFetchExternalServiceEntries
