import { useQuery } from 'react-query'

import { documentsAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchTrashedDocumentList = () => {
  const documentListQuery = useQuery({
    queryKey: [QUERY_KEYS.TRASHED_DOCUMENTS],
    queryFn: documentsAPI.getTrashedDocumentList,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return documentListQuery
}

export default useFetchTrashedDocumentList
