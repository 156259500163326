import React, { FunctionComponent, RefCallback, useCallback, useState } from 'react'

import { EditField } from 'assets/svgIconComponents'
import { Button, Input } from 'components/CasusComponents'
import { classes, FolderTreeModalRenameProps } from '.'

export const Rename: FunctionComponent<FolderTreeModalRenameProps> = ({ currentName, renameCallback, closeCallback }) => {
  const [input, setInput] = useState<HTMLInputElement>()
  const inputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setInput(node), [])

  return (
    <div className={classes.modal.renameContent}>
      <Input
        ref={inputRef}
        type="text"
        defaultValue={currentName}
        placeholder="Folder name"
        autoselect
        onConfirm={event => {
          if ((event.target as HTMLInputElement).value) {
            renameCallback((event.target as HTMLInputElement).value)
            closeCallback()
          }
        }}
        showActionButtons={false}
      />
      <Button onClick={() => closeCallback()}>Cancel</Button>
      <Button
        onClick={() => {
          if (input) {
            if (input.value) {
              renameCallback(input.value)
              closeCallback()
            }
            input.focus()
          }
        }}
        primary
      >
        <EditField />
        Rename
      </Button>
    </div>
  )
}

Rename.displayName = 'Casus-Components-FolderTree-Modal-Rename'

export default Rename
