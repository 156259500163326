import React, { FunctionComponent, useCallback, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStore, {
  WizardTemplateIdSelector,
  WizardTitleSelector,
  WizardLanguagesSelector,
  WizardIntegrationsSelector,
  WizardIntegrationEntriesSelector,
  WizardAnswersSelector,
  WizardAnswerRelevanceSelector,
  UpdateWizardStateAction,
} from '___store'

import { DOCUMENT_DIRECTORY, DOCUMENT_FLOW_MODES, IDocument, PartialDocument } from '___types'
import { useDocument } from '___hooks'
import { Button } from 'components/CasusComponents'
import { WizardLayoutHeaderDynamicButtonProps, wizardLayoutHeaderDynamicButtonClasses as classes, useWizardLayoutContext } from 'Layouts/WizardLayout'

type UseStoreHookResultType = {
  wizardTemplateId: WizardTemplateIdSelector
  wizardTitle: WizardTitleSelector
  wizardLanguages: WizardLanguagesSelector
  wizardIntegrationEntries: WizardIntegrationEntriesSelector
  wizardIntegrations: WizardIntegrationsSelector
  wizardAnswers: WizardAnswersSelector
  wizardAnswerRelevance: WizardAnswerRelevanceSelector
  updateWizardState: UpdateWizardStateAction
}

export const Create: FunctionComponent<WizardLayoutHeaderDynamicButtonProps> = React.memo(() => {
  const history = useHistory()
  const { templateId } = useParams() as { templateId: string }
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.header' })
  const {
    wizardTemplateId,
    wizardTitle,
    wizardLanguages,
    wizardIntegrationEntries,
    wizardIntegrations,
    wizardAnswers,
    wizardAnswerRelevance,
    updateWizardState,
  } = useStore(
    'selectWizardTemplateId',
    'selectWizardTitle',
    'selectWizardLanguages',
    'selectWizardIntegrationEntries',
    'selectWizardIntegrations',
    'selectWizardAnswers',
    'selectWizardAnswerRelevance',
    'updateWizardState'
  ) as UseStoreHookResultType

  const publicFlow = useWizardLayoutContext()

  const { create, creating } = useDocument(undefined, publicFlow)

  const callbackHolder = useRef((document: IDocument) => {
    updateWizardState({ mode: DOCUMENT_FLOW_MODES.EDIT })
    history.push(`/${DOCUMENT_DIRECTORY}/${templateId}/${document.id}`)
  })

  const onSuccess = useCallback(document => {
    if (typeof callbackHolder.current === 'function') callbackHolder.current(document)
  }, [])

  const createHandler = useCallback(() => {
    const payload = {
      templateId: wizardTemplateId,
      name: `DRAFT: ${wizardTitle}`,
      languages: wizardLanguages?.selected || [],
      integrationEntries: wizardIntegrationEntries,
      integrations: wizardIntegrations,
      answers: wizardAnswers,
      answerRelevance: wizardAnswerRelevance,
    } as PartialDocument
    create!(payload, { onSuccess })
  }, [
    wizardTemplateId,
    wizardTitle,
    wizardLanguages,
    wizardIntegrationEntries,
    wizardIntegrations,
    wizardAnswers,
    wizardAnswerRelevance,
    create,
    onSuccess,
  ])

  return (
    <Button className={classes.wrapper} onClick={createHandler} disabled={creating} loading={creating}>
      {translate('save')}
    </Button>
  )
})

Create.displayName = 'WizardLayout-Header-DynamicButton-Create'

export default Create
