import { useQuery, QueryFunctionContext } from 'react-query'

import { userAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

const getExternalApiEntryQueryFunction = ({ queryKey }: QueryFunctionContext, fields: string[]) =>
  userAPI.getExternalApiEntry(queryKey[1] as string, queryKey[2] as string, fields)

export const useFetchExternalServiceEntry = (service: string, id: string, fields: string[]) => {
  const templateQuery = useQuery({
    queryKey: [QUERY_KEYS.EXTERNAL_SERVICE_ENTRY, service, id].concat(fields),
    queryFn: context => getExternalApiEntryQueryFunction(context, fields),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  })
  return templateQuery
}

export default useFetchExternalServiceEntry
