import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, className } from './index'

const PDFDocument = React.memo(() => {
  const paddingHorizontal = 18
  const paddingVertical = 4
  const maskId = useMemo(uuid, [])
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <path
            d={`M ${VIEWPORT_SIZE - paddingHorizontal} ${VIEWPORT_SIZE / 2 - paddingHorizontal + paddingVertical} H ${VIEWPORT_SIZE / 2 + 10} Q ${
              VIEWPORT_SIZE / 2
            } ${VIEWPORT_SIZE / 2 - paddingHorizontal + paddingVertical} ${VIEWPORT_SIZE / 2} ${
              VIEWPORT_SIZE / 2 - paddingHorizontal + paddingVertical - 10
            } L ${VIEWPORT_SIZE / 2} ${paddingVertical}`}
            stroke="black"
            fill="white"
            strokeWidth="2"
          />
          <text
            x="50"
            y="55"
            // textLength={`${VIEWPORT_SIZE / 2}`}
            fill="black"
            stroke="none"
            fontSize="18"
            fontWeight="700"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            .PDF
          </text>
        </mask>
      </defs>
      <path
        d={`M ${paddingHorizontal + 10} ${paddingVertical} H ${VIEWPORT_SIZE / 2} L ${VIEWPORT_SIZE - paddingHorizontal} ${
          VIEWPORT_SIZE / 2 - paddingHorizontal + paddingVertical
        } V ${VIEWPORT_SIZE - paddingVertical - 10} q 0 10 -10 10 H ${paddingHorizontal + 10} q -10 0 -10 -10 V ${
          paddingVertical + 10
        } q 0 -10 10 -10 Z`}
        stroke="#cd4527"
        strokeWidth="8"
        strokeLinejoin="round"
        fill="#cd4527"
        mask={`url(#${maskId})`}
      />
      <path
        d={`M ${VIEWPORT_SIZE / 2} ${paddingVertical} L ${VIEWPORT_SIZE - paddingHorizontal} ${
          VIEWPORT_SIZE / 2 - paddingHorizontal + paddingVertical
        } H ${VIEWPORT_SIZE / 2} Z`}
        stroke="#cd4527"
        strokeWidth="5"
        strokeLinejoin="round"
        fill="#cd4527"
        mask={`url(#${maskId})`}
      />
    </svg>
  )
})

export { PDFDocument }
export default PDFDocument
