import React from 'react'
import useStore from '___store'

import { Cogwheel, Insert, Trash } from 'assets/svgIconComponents'
import { Button } from 'components'
import { classes } from '../../../../../index'

const ActionButton = React.memo(({ button, optionId, optionGroupId, questionId }) => {
  const { removeOption, addNewSubQuestion, removeQuestion, setConfiguring } = useStore(
    'removeOption',
    'addNewSubQuestion',
    'removeQuestion',
    'setConfiguring'
  )
  const { actionSelectOption: actionSelectOptionClass } = classes.configEntry.questionConfiguration.fragment.questionOptions.optionGroup.option
  if (button === 'remove')
    return (
      <Button className={actionSelectOptionClass} tabbable={false} onClick={() => removeOption({ optionId })} colorScheme="transparent">
        <Trash />
      </Button>
    )
  if (button === 'sub')
    return (
      <Button
        className={actionSelectOptionClass}
        tabbable={false}
        onClick={() => addNewSubQuestion({ questionId, optionGroupId, optionId })}
        colorScheme="transparent"
      >
        <Insert />
      </Button>
    )
  if (button === 'configure')
    return (
      <Button
        className={actionSelectOptionClass}
        tabbable={false}
        onClick={() => setConfiguring({ key: 'option', id: optionId })}
        colorScheme="transparent"
      >
        <Cogwheel />
      </Button>
    )
  if (button === 'removeSubQuestion')
    return (
      <Button className={actionSelectOptionClass} tabbable={false} onClick={() => removeQuestion({ id: questionId })} colorScheme="transparent">
        <Trash />
      </Button>
    )
  if (button === 'configureSubQuestion')
    return (
      <Button
        className={actionSelectOptionClass}
        tabbable={false}
        onClick={() => setConfiguring({ key: 'question', id: questionId })}
        colorScheme="transparent"
      >
        <Cogwheel />
      </Button>
    )
})

ActionButton.displayName = 'Wizard-Configuration-QuestionConfiguration-QuestionOptionsFragment-QuestionOption-ActionButton'

export default ActionButton
