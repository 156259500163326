import React from 'react'

import { className } from './index'

const AlignJustify = React.memo(() => {
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path d="M 15 30 h 70" stroke="currentColor" strokeWidth="10" strokeLinecap="butt" fill="none" />
      <path d="M 15 50 h 70" stroke="currentColor" strokeWidth="10" strokeLinecap="butt" fill="none" />
      <path d="M 15 70 h 70" stroke="currentColor" strokeWidth="10" strokeLinecap="butt" fill="none" />
    </svg>
  )
})

export { AlignJustify }
export default AlignJustify
