import React, { useMemo, useRef } from 'react'

import { classes } from './index'

const Button = React.memo(props => {
  const buttonRef = useRef()
  const { className: cn, colorScheme, tabbable = true, onClick = () => {}, active = false, disabled, children, blurOnClick = false } = props
  const className = useMemo(() => [classes.Button.wrapper, cn].filter(cn => cn).join(' '), [cn])
  return (
    <button
      ref={buttonRef}
      className={className}
      type="button"
      tabIndex={tabbable ? 0 : -1}
      onClick={event => {
        if (!disabled) {
          typeof onClick === 'function' && onClick(event)
          return blurOnClick && buttonRef?.current?.blur()
        }
      }}
      disabled={disabled}
      data-inset={active}
      data-color-scheme={colorScheme}
      data-tabbable={tabbable}
    >
      <span className={classes.Button.span}>{children}</span>
    </button>
  )
})

Button.displayName = 'Button'

export default Button
