import { Button } from 'components'
import React from 'react'
import { classes } from '..'

const HeaderFooterToggleButton = React.memo(({ toggleCallback }) => {
  const {
    headerFooterToggleButton: { wrapper: wrapperClass },
  } = classes
  return (
    <Button className={wrapperClass} onClick={toggleCallback}>
      T
    </Button>
  )
})

HeaderFooterToggleButton.displayName = 'Wizard-Editor-HeaderFooterToggleButton'

export default HeaderFooterToggleButton
