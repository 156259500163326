import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, DocumentProps, className } from './index'

const ZOOM = 1

const Document: FunctionComponent<DocumentProps> = React.memo(({ function: extra }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const maskId = useMemo(uuid, [])

  const dDocument =
    'M 55 15 l 20 20 h -5 c -15 0 -15 0 -15 -15 v -5 M 55 15 h -20 c -10 0 -10 0 -10 10 v 50 c 0 10 0 10 10 10 h 30 c 10 0 10 0 10 -10 v -40 Z'
  const dPen = 'M 43 75 l 5 -10 l 32 -32 l 5 5 l -32 32 Z'

  let transform
  if (extra === 'edit') transform = 'translate(-6 0)'
  if (extra === 'duplicate') transform = 'translate(5 -5)'

  return (
    <svg className={`${className} Document`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={`${maskId}1`}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          {extra === 'edit' ? <path d={dPen} stroke="black" strokeWidth="15" fill="black" strokeLinejoin="round" transform="translate(6 0)" /> : null}
        </mask>
        <mask id={`${maskId}2`}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <circle cx="50" cy="61" r="5" stroke="none" fill="black" />
        </mask>
        <mask id={`${maskId}3`}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <path
            d="M 55 15 h -20 c -10 0 -10 0 -10 10 v 50 c 0 10 0 10 10 10 h 30 c 10 0 10 0 10 -10 v -40 l -15 -15 Z"
            stroke="black"
            strokeWidth="5"
            fill="black"
            strokeLinejoin="round"
            transform="translate(5 -5)"
          />
        </mask>
      </defs>
      {/* <path d={dDocument} stroke="black" strokeWidth="5" fill="black" strokeLinejoin="round" transform="translate(3 -3)" fillRule="" /> */}
      <path
        // d="M 55 20 l 15 15 h -5 c -10 0 -10 0 -10 -10 v -5 h -15 c -10 0 -10 0 -10 10 v 40 c 0 10 0 10 10 10 h 20 c 10 0 10 0 10 -10 v -35"
        d={dDocument}
        strokeLinejoin="bevel"
        stroke="currentColor"
        strokeWidth="5"
        fill="none"
        mask={`url(#${maskId}1)`}
        transform={transform}
      />
      {extra === 'create' ? (
        <>
          <line x1="37" x2="63" y1="58" y2="58" stroke="currentColor" strokeWidth="5" />
          <line x1="50" x2="50" y1="45" y2="71" stroke="currentColor" strokeWidth="5" />
        </>
      ) : null}
      {extra === 'update' ? (
        <path
          d="M 40 43 h 15 l 10 10 v 15 c 0 2 -3 5 -5 5 h -20 c -2 0 -5 -3 -5 -5 v -20 c 0 -2 3 -5 5 -5 Z"
          stroke="none"
          fill="currentColor"
          mask={`url(#${maskId}2)`}
        />
      ) : null}
      {extra === 'edit' ? <path d={dPen} stroke="currentColor" strokeWidth="5" fill="currentColor" strokeLinejoin="round" /> : null}
      {extra === 'duplicate' ? (
        <path
          d={dDocument}
          strokeLinejoin="bevel"
          stroke="currentColor"
          strokeWidth="5"
          fill="none"
          transform="translate(-5 5)"
          mask={`url(#${maskId}3)`}
        />
      ) : null}
      {extra === 'remove' ? <line x1="37" x2="63" y1="58" y2="58" stroke="currentColor" strokeWidth="5" /> : null}
    </svg>
  )
})

export { Document }
export default Document
