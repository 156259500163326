import React, { useMemo } from 'react'
import useStore from '___store'

import { Caret, VerticalEllipsis } from 'assets/svgIconComponents'
import { Select } from 'components'
import { classes } from '../../../../index'
import { ActionButton } from './index'
import { Button } from 'components/CasusComponents'

export const QuestionListItem = React.memo(({ id }) => {
  const {
    questionById: { text } = {},
    questionSubQuestionIds,
    reorderQuestion,
  } = useStore(`selectQuestionById[${id}]`, `selectQuestionSubQuestionIds[${id}]`, 'reorderQuestion')

  const subQuestionIds = useMemo(() => questionSubQuestionIds.split('; ').filter(sq => sq), [questionSubQuestionIds])
  const hasSubQuestions = useMemo(() => Boolean(subQuestionIds.length), [subQuestionIds])
  const subQuestions = useMemo(() => subQuestionIds.map(id => <QuestionListItem key={id} id={id} />), [subQuestionIds])

  const {
    wrapper: wrapperClass,
    row: rowClass,
    questionTextSpan: questionTextSpanClass,
    actionSelect: actionSelectClass,
    subQuestionsDiv: subQuestionsDivClass,
  } = classes.configEntry.questionGeneration.listItem

  return (
    <div className={wrapperClass}>
      <div className={rowClass}>
        <span className={questionTextSpanClass}>{text || 'No question text...'}</span>
        <div className="ReorderCaretDiv">
          <Button onClick={() => reorderQuestion({ id: id, direction: 0 })} onClickBlur transparent>
            <Caret />
          </Button>
          <Button onClick={() => reorderQuestion({ id: id, direction: 1 })} onClickBlur transparent>
            <Caret />
          </Button>
        </div>
        <Select
          className={actionSelectClass}
          options={{ removeQuestion: null, configureQuestion: null }}
          render={key => <ActionButton key={`QuestionListItem-${id}-ActionSelect-${key}`} button={key} questionId={id} />}
          chooseMode
          chooseRender={<VerticalEllipsis />}
          noCaret
          directionPriority="horizontal"
          horizontalDirection="left"
          cornerAnchor={false}
        />
      </div>
      {hasSubQuestions ? <div className={subQuestionsDivClass}>{subQuestions}</div> : null}
    </div>
  )
})

QuestionListItem.displayName = 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup-QuestionListItem'

export default QuestionListItem
