import React from 'react'
import { classes } from '.'

import './style.scss'
import { Icon } from 'components/common'
import Button from 'components/ButtonGroup'
import Input from './components/Input'
import SignersList from '../SignersList'

const Form = ({ user, handleChange, handleMessageChange, addSigner, signingWith, message, handleDelete, handleEdit }) => {
  return (
    <div className={classes.container.wrapper}>
      <div className={classes.container.title}>Fill out the signers' information</div>
      <div className={classes.container.signee.wrapper}>
        <div className={classes.container.signee.title}>Add signer</div>
        <div className={classes.container.signee.inputGroup.name}>
          <Input
            name="firstName"
            placeholder="Stefanie"
            label="First name of the signer*"
            type="text"
            value={user.firstName}
            onChange={handleChange}
          />
          <Input name="lastName" placeholder="Meier" label="Last name of the signer*" type="text" value={user.lastName} onChange={handleChange} />
        </div>
        <div className={classes.container.signee.inputGroup.email}>
          <Input
            name="email"
            placeholder="example@email.ch"
            label="Email address of the signer*"
            type="email"
            value={user.email}
            onChange={handleChange}
          />
          <Button onClick={addSigner} className={classes.container.signee.button.wrapper}>
            <Icon iconName="add" /> Add signer
          </Button>
        </div>
      </div>
      <SignersList signingWith={signingWith} handleDelete={handleDelete} handleEdit={handleEdit} />
      <div className={classes.container.signee.wrapper}>
        <div className={classes.container.signee.inputGroup.email}>
          <Input
            isTextarea
            name="message"
            placeholder="Please, sign this document"
            label="Write a message"
            type="text"
            value={message}
            onChange={handleMessageChange}
          />
        </div>
      </div>
    </div>
  )
}

export default Form

