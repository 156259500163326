import SignersListWithStatus from './SignersListWithStatus'

const classes = {
  wrapper: 'ViewSignatureModal-Signers-Container-wrapper',
  title: 'ViewSignatureModal-Signers-Title-wrapper',
  signed: {
    wrapper: 'ViewSignatureModal-Signers-Signed-Container-wrapper',
    status: 'ViewSignatureModal-Signers-Signed-Status-wrapper',
  },
  pending: {
    wrapper: 'ViewSignatureModal-Signers-Pending-Container-wrapper',
    reminderGroup: {
      wrapper: 'ViewSignatureModal-Signers-Pending-ReminderGroup-wrapper',
      status: 'ViewSignatureModal-Signers-Pending-ReminderGroup-Status-wrapper',
      divider: 'ViewSignatureModal-Signers-Pending-ReminderGroup-Divider-wrapper',
      sendButton: 'ViewSignatureModal-Signers-Pending-ReminderGroup-SendButton-wrapper',
    },
  },
}

export { classes }

export default SignersListWithStatus

