import Tag from './Tag'
import TagStrip from './TagStrip'

import './style.scss'

const classes = {
  tagStripWrapper: 'Wizard-Configuration-QuestionConfiguration-TagStrip-wrapper',
  tagStripSpan: 'Wizard-Configuration-QuestionConfiguration-TagStrip-span',
  tagStripInfo: 'Wizard-Configuration-QuestionConfiguration-TagStrip-info',
  tagStripInfoText: 'Wizard-Configuration-QuestionConfiguration-TagStrip-infoText',
  tagWrapper: 'Wizard-Configuration-QuestionConfiguration-TagStrip-Tag-wrapper',
  tagButton: 'Wizard-Configuration-QuestionConfiguration-TagStrip-Tag-button',
}

export { Tag, TagStrip, classes }
export default TagStrip
