import React, { FunctionComponent, PropsWithChildren, useCallback, useContext } from 'react'

import { Directory, Folder } from '___types'
import useModal, { RenderFunctionType } from 'Modal'
import FolderTree from './FolderTree'
import Create from './FolderTree.Modal.Create'

import './style.scss'
import Rename from './FolderTree.Modal.Rename'
import Remove from './FolderTree.Modal.Remove'

const classes = {
  wrapper: 'Casus-Components-FolderTree-wrapper',
  header: 'Casus-Components-FolderTree-header',
  spacer: 'Casus-Components-FolderTree-spacer',
  folderActionSet: 'Casus-Components-FolderTree-folderActionSet',
  children: 'Casus-Components-FolderTree-children',
  modal: {
    wrapper: 'Casus-Components-FolderTree-Modal-wrapper',
    createContent: 'Casus-Components-FolderTree-Modal-Create-content',
    removeContent: 'Casus-Components-FolderTree-Modal-Remove-content',
    renameContent: 'Casus-Components-FolderTree-Modal-Rename-content',
  },
}

export type FolderTreeProps = {
  folderStructure: Folder
  directory: Directory
  onClick?: (id: string, fullPath: string) => void
  parentId?: string | string[]
  activeId?: string
  activePath?: string
}
export type FolderTreeModalCreateProps = { createCallback: (name: string) => void; closeCallback: () => void }
export type FolderTreeModalRenameProps = { currentName: string; renameCallback: (name: string) => void; closeCallback: () => void }
export type FolderTreeModalRemoveProps = { removeCallback: (deep: boolean) => void; closeCallback: () => void }
type FolderTreeContextProps = PropsWithChildren<{
  create: (callback: (name: string) => void) => void
  rename: (callback: (name: string) => void, currentName: string) => void
  remove: (callback: (deep: boolean) => void, label: string) => void
}>
const FolderTreeContext = React.createContext<FolderTreeContextProps>({} as FolderTreeContextProps)
const FolderTreeContextProvider: FunctionComponent<FolderTreeProps> = props => {
  const { folderStructure, directory, onClick, parentId, activeId, activePath } = props
  const open = useModal({ className: classes.modal.wrapper })
  const create = useCallback(
    callback => {
      const options = {
        header: 'New folder',
        content: (close => <Create createCallback={name => callback(name)} closeCallback={close} />) as RenderFunctionType,
        footer: false,
      }
      open(options)
    },
    [open]
  )
  const rename = useCallback(
    (callback, currentName) => {
      const options = {
        header: 'Rename folder',
        content: (close => <Rename currentName={currentName} renameCallback={name => callback(name)} closeCallback={close} />) as RenderFunctionType,
        footer: false,
      }
      open(options)
    },
    [open]
  )
  const remove = useCallback(
    (callback, label) => {
      const options = {
        header: label,
        content: (close => <Remove removeCallback={deep => callback(deep)} closeCallback={close} />) as RenderFunctionType,
        footer: false,
      }
      open(options)
    },
    [open]
  )
  return (
    <FolderTreeContext.Provider value={{ create, rename, remove }}>
      <FolderTree
        folderStructure={folderStructure}
        directory={directory}
        onClick={onClick}
        parentId={parentId}
        activeId={activeId}
        activePath={activePath}
      />
    </FolderTreeContext.Provider>
  )
}

const useFolderTreeContext = () => {
  const currentFolderTreeContext = useContext(FolderTreeContext)
  if (!currentFolderTreeContext) {
    throw new Error('useFolderTreeContext has to be used within <FolderTreeContext.Provider>')
  }
  return currentFolderTreeContext
}

export { FolderTreeContextProvider as FolderTree, Create, Remove, Rename, useFolderTreeContext, classes }
export default FolderTreeContextProvider
