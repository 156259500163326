import React from 'react'
import useStore from '___store'

import { Input } from 'components'
import { classes } from '../../../index'

export const Limits = React.memo(({ id }) => {
  const { optionById: { limits = {} } = {}, updateOption } = useStore(`selectOptionById[${id}]`, 'updateOption')

  const { min, max, step } = limits

  console.log(min, max, step)

  const {
    wrapper: wrapperClass,
    // text: textClass,
    limits: { input: inputClass },
  } = classes.configEntry.optionConfiguration.fragment

  return (
    <div className={wrapperClass}>
      <Input
        className={inputClass}
        type="number"
        defaultValue={min}
        onChange={min => updateOption({ optionId: id, limits: Object.assign({}, limits, { min }) })}
        needsConfirmation
        blurShowUnmodified
      />
      <Input
        className={inputClass}
        type="number"
        defaultValue={max}
        onChange={max => updateOption({ optionId: id, limits: Object.assign({}, limits, { max }) })}
        needsConfirmation
        blurShowUnmodified
      />
      <Input
        className={inputClass}
        type="number"
        defaultValue={step}
        onChange={step => updateOption({ optionId: id, limits: Object.assign({}, limits, { step }) })}
        needsConfirmation
        blurShowUnmodified
      />
    </div>
  )
})

Limits.displayName = 'Wizard-Configuration-OptionConfiguration-Limits'

export default Limits
