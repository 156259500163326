import Api from './api'

export const integrationsApiTitles = { 'bamboo-hr': 'BambooHR' }

class Integrations extends Api {
  constructor() {
    super('')
  }

  getIntegrationsFields = async () => {
    try {
      const res = await this.api().get(`/users/external/services/fields`)
      const fields = res?.data?.data?.services.map(({ service: { type, name }, list }) => ({
        id: type,
        title: name || integrationsApiTitles[name],
        fields: list,
      }))
      return Object.assign({}, res, { data: fields })
    } catch (err) {
      throw err
    }
  }

  getIntegrationsIdedObjects = async service => {
    try {
      const res = await this.api().post(`/users/external/services/employees`, { serviceType: service })
      return Object.assign({}, res, { data: res?.data?.data?.employees })
    } catch (err) {
      throw err
    }
  }

  getIntegrationsEntries = async selectedServices => {
    return Object.entries(selectedServices).reduce(async (acc, [service, { id, fields }]) => {
      try {
        const res = await this.api().post(`/users/external/services/employees/${id}`, { serviceType: service, fields })
        return Object.assign(acc, { [service]: res?.data?.data })
      } catch (err) {
        throw err
      }
    }, {})
  }

  getIntegrationEmployeeCategories = async (employeeId, serviceType) => {
    try {
      const res = await this.api().get(`/users/external/services/${serviceType}/employee/${employeeId}/categories`)
      return res?.data?.data?.categories?.categories
    } catch (err) {
      throw err
    }
  }
}

const integrationsApi = new Integrations()

export { integrationsApi }
export default integrationsApi
