import React, { useMemo } from 'react'
import useStore from '___store'

import { Button, ButtonGroup, Input, Switch } from 'components'
import QuestionOption from './QuestionOption'
import { classes } from '../../../../../index'
import { DatePicker, NumberInput, StringInput, TimePicker, Trash } from 'assets/svgIconComponents'

const selectOptions = ['single', 'multi']

const QuestionOptionGroup = React.memo(({ questionId, id }) => {
  const {
    questionOptionGroupById: { label = '', select = 'single', minimum = 1, maximum = 1 } = {},
    questionOptionGroupOptionIds,
    addNewOption,
    updateOptionGroup,
    removeOptionGroup,
  } = useStore(
    `selectQuestionOptionGroupById[${questionId},${id}]`,
    `selectQuestionOptionGroupOptionIds[${questionId},${id}]`,
    'addNewOption',
    'updateOptionGroup',
    'removeOptionGroup'
  )

  const optionIds = useMemo(() => questionOptionGroupOptionIds.split('; ').filter(o => o), [questionOptionGroupOptionIds])
  const options = useMemo(
    () => optionIds.map(optionId => <QuestionOption key={optionId} id={optionId} optionGroupId={id} questionId={questionId} />),
    [optionIds, id, questionId]
  )

  const {
    wrapper: wrapperClass,
    header: headerClass,
    content: contentClass,
    footer: footerClass,
    selectSwitch: selectSwitchClass,
    numberInput: numberInputClass,
    actionButton: actionButtonClass,
  } = classes.configEntry.questionConfiguration.fragment.questionOptions.optionGroup

  return (
    <div className={wrapperClass}>
      <Input
        className={headerClass}
        placeholder="Group label goes here (e.g. Group 1: HR team)"
        defaultValue={label}
        onChange={label => updateOptionGroup({ id, questionId, label })}
        needsConfirmation
        blurShowUnmodified
      />
      <div className={contentClass}>{options}</div>
      <ButtonGroup className={footerClass} collapsed>
        <Switch
          className={selectSwitchClass}
          value={Boolean(selectOptions.indexOf(select))}
          onChange={value => updateOptionGroup({ id, questionId, select: selectOptions[Number(value)] })}
          labels={selectOptions}
        />
        {select === 'multi' ? (
          <>
            Minimum:
            <Input
              className={numberInputClass}
              type="number"
              placeholder="Minimum"
              defaultValue={minimum}
              onChange={value => updateOptionGroup({ id, questionId, minimum: value })}
              minimum={0}
              maximum={Math.max(Math.min(maximum, options.length), 0)}
              step={1}
              needsConfirmation
              blurShowUnmodified
            />
            Maximum:
            <Input
              className={numberInputClass}
              type="number"
              placeholder="Maximum"
              defaultValue={maximum}
              onChange={value => updateOptionGroup({ id, questionId, maximum: value })}
              minimum={Math.min(Math.max(minimum, 0), options.length)}
              maximum={options.length}
              step={1}
              needsConfirmation
              blurShowUnmodified
            />
          </>
        ) : null}
        <Button className={actionButtonClass} onClick={() => addNewOption({ id, questionId, valueType: 'string' })}>
          <StringInput />
        </Button>
        <Button className={actionButtonClass} onClick={() => addNewOption({ id, questionId, valueType: 'number' })}>
          <NumberInput />
        </Button>
        <Button className={actionButtonClass} onClick={() => addNewOption({ id, questionId, valueType: 'date' })}>
          <DatePicker />
        </Button>
        <Button className={actionButtonClass} onClick={() => addNewOption({ id, questionId, valueType: 'date-time' })}>
          <TimePicker />
        </Button>
        <Button className={actionButtonClass} onClick={() => removeOptionGroup({ id, questionId })}>
          <Trash />
        </Button>
      </ButtonGroup>
    </div>
  )
})

QuestionOptionGroup.displayName = 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup'

export default QuestionOptionGroup
