import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { DOCUMENT_STATUSES, IDocument } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { useCallback } from 'react'

export type ApproveDocumentVariables = string
export type ApproveDocumentContext = { mutationId: string }
export type ApproveDocumentMutationOptions = MutateOptions<IDocument, unknown, ApproveDocumentVariables, ApproveDocumentContext>
export type ApproveDocumentFunctionType = (options?: ApproveDocumentMutationOptions) => void
// const approveDocumentMutationFunction = (variables: ApproveDocumentVariables) => documentsAPI.approveDocument(variables)

export const useApproveDocument = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: (IDocument & { mutationId?: string })[] | undefined) => IDocument[]) =>
    queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: ApproveDocumentVariables) => {
    const mutationId = uuid()
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original?: IDocument }
    if (currentDocument) {
      const originalDocument = currentDocument.original || currentDocument
      const optimisticDocument = Object.assign({}, originalDocument, {
        status: DOCUMENT_STATUSES.LOCKED,
        mutationId,
        mutating: true,
        mutation: 'update',
        original: originalDocument,
      })
      queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], optimisticDocument)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticDocument))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: ApproveDocumentVariables, context: ApproveDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) {
      queryClient.removeQueries([QUERY_KEYS.DOCUMENT, id])
      queryClient.resetQueries([QUERY_KEYS.DOCUMENT, id])
      // queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], currentDocument.original)
      queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
      queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentDocument.original))
    }
  }

  const onSuccess = (document: IDocument, variables: ApproveDocumentVariables, context: ApproveDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], document)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, document))
  }

  const documentApproveMutation = useMutation<IDocument, unknown, ApproveDocumentVariables, ApproveDocumentContext>(
    [QUERY_KEYS.DOCUMENT, id],
    documentsAPI.approveDocument,
    { onMutate, onError, onSuccess }
  )

  const approveMutationFunction: ApproveDocumentFunctionType = useCallback(
    options => documentApproveMutation.mutate(id!, options),
    [documentApproveMutation, id]
  )

  return { approve: approveMutationFunction, approving: documentApproveMutation.isLoading }
}

export default useApproveDocument
