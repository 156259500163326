import React, { useEffect } from 'react'
import { EmptyDocuments, classes } from '.'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IDocument } from 'types'
import { ListFile } from 'components/files/File'
import { useLoadDocuments } from 'queries/documents'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { RecentDocuments } from 'assets/svgIconComponents'
import SkeletonListFile from 'components/files/File/SkeletonListFile'
import { useUser } from 'queries/user/useUser'

interface IProps {
  documents: IDocument[] | undefined
}

const DocumentsList: React.FC<IProps> = ({ documents }) => {
  const { push } = useHistory()
  const user = useUser()!!

  const previewDocument = (document: IDocument) => {
    if (user.version === 'v3') {
      if (document.type === 'pdf') return push(`/pdf/${document.id}`)
      push(`/document/${document.templateId}/${document.id}/preview`)
    } else {
      push(`/${document.type === 'pdf' ? 'pdf' : 'document'}/${document.id}`)
    }
  }

  if (documents && documents.length === 0) {
    return <EmptyDocuments />
  } else {
    return (
      <div className={classes.list}>
        {documents?.slice(0, 4).map(document => (
          <ListFile
            key={document.id}
            id={document.id}
            title={document.name}
            type={document.type}
            edited={document.edited}
            onClick={() => previewDocument(document)}
          />
        ))}
      </div>
    )
  }
}

const SkeletonList: React.FC = () => {
  return (
    <div className={classes.list} data-skeleton={true}>
      <SkeletonListFile />
      <SkeletonListFile />
      <SkeletonListFile />
      <SkeletonListFile />
    </div>
  )
}

const RecentDocumentsPanel: React.FC = () => {
  const { currentFolder } = useCurrentFolder('docs')

  const { push } = useHistory()
  const { t } = useTranslation()

  const { refetch, data, isLoading } = useLoadDocuments(currentFolder, { enabled: false })

  const documentFiles = data?.pages.flat(1) ?? []

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <div className={classes.wrapper}>
      <div className={classes.title.wrapper}>
        <span className={classes.title.textAndIcon}>
          <RecentDocuments />
          {t('recent_documents')}
        </span>
        <span onClick={() => push(`documents`)} className={classes.title.showAll}>
          {t('show_all')} &gt;
        </span>
      </div>
      {!isLoading ? <DocumentsList documents={documentFiles} /> : <SkeletonList />}
    </div>
  )
}

export default RecentDocumentsPanel
