import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import DocumentCreate from './views/DocumentCreate/DocumentCreate.page'
import DocumentEdit from './views/DocumentEdit/DocumentEdit.page'
import DocumentPreview from './views/DocumentPreview/DocumentPreview.page'

const DocumentFile: React.FC = () => {
  return (
    <Switch>
      <Route path={`/document/new`} component={DocumentCreate} />
      <Route exact path={`/document/:id/edit`} component={DocumentEdit} />
      <Route exact path={`/document/:id/preview`} component={DocumentPreview} />
      <Redirect to={`/documents`} />
    </Switch>
  )
}

export default DocumentFile
