import React, { FunctionComponent } from 'react'

import { LOCATION_TYPES } from '___types'
import { Text, Segments } from './Marker.Components'
import { useMarker } from '../customHooks'
import { WizardLayoutRightPaneEditorMarkerProps } from '../../../..'

export const Marker: FunctionComponent<WizardLayoutRightPaneEditorMarkerProps> = React.memo(({ id, type, parent, textChunks }) => {
  const { range } = useMarker(type, id)
  if (!type) return null
  if (type === LOCATION_TYPES.SEGMENTS) return <Segments id={id} parent={parent!} />
  if (type === LOCATION_TYPES.TEXT) return <Text id={id} range={range} textChunks={textChunks || []} />
  return null
})

Marker.displayName = 'WizardLayout-RightPane-Editor-Marker'

export default Marker
