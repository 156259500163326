import React from 'react'

import { classes } from '.'
import './style.scss'
import { Icon } from 'components/common'

const Signer = ({ signer, handleDelete, handleEdit }) => {
  return (
    <div className={classes.container.wrapper}>
      <div className={classes.container.text}>
        {signer.firstName} {signer.lastName} - {signer.email}
      </div>
      <div className={classes.container.icons.wrapper}>
        <div className={classes.container.icons.icon} onClick={() => handleDelete(signer)}>
          <Icon iconName="trash" />
        </div>
        <div className={classes.container.icons.icon} onClick={() => handleEdit(signer)}>
          <Icon iconName="edit" />
        </div>
      </div>
    </div>
  )
}

export default Signer

