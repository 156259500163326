import { IConfirmationPopupContent } from 'types'

export const POPUP_CONFIRM_DELETE_PROFILE: IConfirmationPopupContent = {
  heading: 'popup_confirm_delete_profile_heading',
  description:
    'popup_confirm_delete_profile_description',
  status: 'warning',
  primaryButtonText: 'Okay',
}

export const POPUP_CONFIRM_TRASH_FILE: IConfirmationPopupContent = {
  heading: 'popup_confirm_trash_file_heading',
  status: 'warning',
  primaryButtonText: 'yes_move',
  secondaryButtonText: 'not_move',
}

export const POPUP_CONFIRM_DELETE_FILE: IConfirmationPopupContent = {
  heading:
    'popup_confirm_delete_file_heading',
  status: 'warning',
  primaryButtonText: 'yes_perm_delete',
  secondaryButtonText: 'not_delete',
}

export const POPUP_CONFIRM_DELETE_ALL_FILES: IConfirmationPopupContent = {
  heading:
    'popup_confirm_delete_all_files_heading',
  status: 'warning',
  primaryButtonText: 'yes_perm_delete',
  secondaryButtonText: 'not_delete',
}

export const POPUP_CONFIRM_DELETE_HEADER: IConfirmationPopupContent = {
  heading:
    'popup_confirm_delete_header_heading',
  status: 'warning',
  primaryButtonText: 'yes_perm_delete',
  secondaryButtonText: 'not_delete',
}

export const POPUP_BROWSER_VERSION: IConfirmationPopupContent = {
  heading: 'popup_browser_version_heading',
  description:
    'popup_browser_version_description',
  status: 'warning',
  primaryButtonText: 'update_browser',
  secondaryButtonText: 'not_now',
}

export const POPUP_TC_KEEP_STYLES_ON_PASTE: IConfirmationPopupContent = {
  heading: 'Textformatierung',
  description: 'popup_tc_keep_style_on_paste_description',
  status: 'warning',
  primaryButtonText: 'Ja, behalten',
  secondaryButtonText: 'Nicht behalten',
}
