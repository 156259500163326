import { MutateOptions, useMutation, useQueryClient } from 'react-query'
import { IUser } from '___types'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'
// import { getTemplateListQueryFunction } from './useFetchTemplateList'

export type ToggleCompanyShareTemplateVariables = { id: string }
const toggleCompanyShareTemplateMutationFunction = (variables: ToggleCompanyShareTemplateVariables) => templatesAPI.toggleCompanyShare(variables.id)

export const useToggleCompanyShareTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()
  const templateShareMutation = useMutation<IUser, unknown, ToggleCompanyShareTemplateVariables, { mutationId: string }>(
    [QUERY_KEYS.TEMPLATE, id],
    toggleCompanyShareTemplateMutationFunction,
    //////////////////////////////////////////////
    // Optimistic loading on the user missing   //
    //////////////////////////////////////////////
    {
      onSuccess: (user) => {
        const currentUser = queryClient.getQueryData([QUERY_KEYS.USER]) as IUser
        if (currentUser) {
          queryClient.setQueryData([QUERY_KEYS.USER], user)
        }
      },
    }
  )

  const toggleCompanyShareMutationFunction = (
    id: string,
    options?: MutateOptions<IUser, unknown, ToggleCompanyShareTemplateVariables, { mutationId: string }>
  ) => templateShareMutation.mutate({ id: id! }, options)
  return { toggleCompanyShare: toggleCompanyShareMutationFunction, toggling: templateShareMutation.isLoading }
}

export type ToggleCompanyShareTemplateFunctionType = (
  id: string,
  options?: MutateOptions<IUser, unknown, ToggleCompanyShareTemplateVariables, { mutationId: string }>
) => void

export default useToggleCompanyShareTemplate

