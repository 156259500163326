import React, { FunctionComponent, useMemo } from 'react'

import { VIEWPORT_SIZE, PersonProps, className } from './index'

const ZOOM = 1

const Person: FunctionComponent<PersonProps> = React.memo(({ stroke = 'none', strokeWidth = '5', fill = 'currentColor' }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  return (
    <svg className={`${className} Person`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <g>
        <path
          d={'M 30 90 c -20 0 -10 -30 20 -30 c 30 0 40 30 20 30 Z'}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
          fill={fill}
        />
        <ellipse cx={50} cy={35} rx="14" ry="16" stroke={stroke} strokeWidth={strokeWidth} fill={fill} />
      </g>
    </svg>
  )
})

export { Person }
export default Person
