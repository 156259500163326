import React from 'react'

import { classes } from '.'

interface ModalElementProps {
  onClose: (...props: any) => any
  open: boolean
  style?: React.CSSProperties
  backdropStyle?: React.CSSProperties
  withBg?: boolean
}

const RootModal: React.FC<ModalElementProps> = ({ open, onClose, children }) => {

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <div data-is-open={open} className={classes.mainWrapper}>
    <div className={classes.background} onClick={handleClose}/>
      <div className={classes.wrapper}>
        {children}
      </div>
    </div>
  )
}

export default RootModal

