import { BoxTypeQuestionProps } from './Common.types'
import React from 'react'
import { Container, ActionButtonsContainer, Typography } from './Common.styles'
import { CustomButton } from 'components/common'
import TypeBox from '../../components/TypeBox/TypeBox'
import questionTypes from 'constants/question-types'
import { btnContent, forms as content } from 'pages/Authenticated/Template/constants/content'
import { useTranslation } from 'react-i18next'

const TextBoxDate: React.FC<BoxTypeQuestionProps> = ({
  toggleTextSelection,
  textSelectionIsActive,
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Typography variant='h5'>{t(content.dateHeading)}</Typography>
      <ActionButtonsContainer>
        <TypeBox
          questionType={questionTypes.DATE}
          style={{ marginRight: 'auto', pointerEvents: 'none' }}
        />
        <CustomButton
          onClick={toggleTextSelection}
          btntype='primary'
          size='large'
          style={{ width: '47%' }}
          textselectionactive={textSelectionIsActive ? 'true' : null}
          id='template-creation-btn'
        >
          {t(btnContent.markText)}
        </CustomButton>
      </ActionButtonsContainer>
    </Container>
  )
}

export default TextBoxDate
