import React, { FunctionComponent, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { ANSWERING_IDS_MATCH, DOCUMENT_DIRECTORY } from '___types'
import Question from './Question'
import {
  WizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupProps,
  wizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupClasses as classes,
  useWizardLayoutContext,
} from 'Layouts/WizardLayout'

const QuestionGroup: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupProps> = React.memo(({ id }) => {
  const { templateId, documentId } = useParams() as { templateId: string; documentId: string }

  const publicFlow = useWizardLayoutContext()

  const { questionId, orderString, isFirst, isLast } = useMemo(() => id?.match(ANSWERING_IDS_MATCH)?.groups || {}, [id])
  const question = useMemo(
    () => <Question key={questionId} id={questionId} orderString={orderString} isFirst={Boolean(isFirst)} isLast={Boolean(isLast)} />,
    [questionId, orderString, isFirst, isLast]
  )

  if (id === 'redirect-backward') return <Redirect to={`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${documentId}?step=configure`} />
  if (id === 'redirect-forward') return <Redirect to={`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${documentId}?step=review`} />

  return <div className={classes.wrapper}>{question}</div>
})

QuestionGroup.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-QuestionGroup'

export default QuestionGroup
