import { NumberTypeFormProps } from './QuestionNumber.types'
import React from 'react'
import {
  Container,
  ActionButtonsContainer,
  Collapse,
  Heading,
} from './QuestionNumber.styles'
import { CustomButton, Tabs } from 'components/common'
import TypeBox from '../../components/TypeBox/TypeBox'
import NumberInputFields from './NumberInputFields'
import questionTypes from 'constants/question-types'
import {
  btnContent,
  forms as content,
} from 'pages/Authenticated/Template/constants/content'
import { useTranslation } from 'react-i18next'

const QuestionNumber: React.FC<NumberTypeFormProps> = ({
  toggleTextSelection,
  textSelectionIsActive,
  ranges,
  handleRangeChange,
  currentTab,
  handleTabChange,
}) => {
  const showRanges = currentTab === 'with-range'
  const { t } = useTranslation()

  return (
    <Container>
      <Heading variant="h5">{t(content.numberHeading)}</Heading>
      <Tabs
        tabItems={content.numberFormTabs}
        handleTabChange={handleTabChange}
        currentTab={currentTab}
      />
      <Collapse timeout={75} in={showRanges}>
        <NumberInputFields
          handleRangeChange={handleRangeChange}
          ranges={ranges}
        />
      </Collapse>
      <ActionButtonsContainer>
        <TypeBox
          questionType={questionTypes.NUMBER}
          style={{ marginRight: 'auto', pointerEvents: 'none' }}
        />
        <CustomButton
          onClick={toggleTextSelection}
          btntype="primary"
          size="large"
          style={{ width: '47%' }}
          textselectionactive={textSelectionIsActive ? 'true' : null}
        >
          {t(btnContent.markText)}
        </CustomButton>
      </ActionButtonsContainer>
    </Container>
  )
}

export default QuestionNumber
