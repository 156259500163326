import { TabItemProps } from '../Tabs.types'
import React from 'react'
import { TabItemContainer, Typography } from './TabItem.styles'
import { useTranslation } from 'react-i18next'

const TabItem: React.FC<TabItemProps> = ({
  label,
  section,
  currentTab,
  containerStyles,
  handleTabChange,
}) => {
  const {t} = useTranslation()  
  return (
    <TabItemContainer
      onClick={() => handleTabChange(section)}
      isactive={currentTab === section ? 1 : 0}
      style={containerStyles}
    >
      <Typography
        variant='body1'
        align='center'
        data-intercom-target={`intercom-tab-item`}
      >
        {t(label)}
      </Typography>
    </TabItemContainer>
  )
}

export default TabItem
