import React from 'react'
import { classes } from '.'

const CasusLink = React.memo(({ properties }) => {
  const { text, href } = properties
  return (
    <a className={classes.inputPreview.casusLink.wrapper} href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  )
})

CasusLink.displayName = 'Input-preview-CasusLink'

export default CasusLink
