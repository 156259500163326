import { useHistory } from 'react-router'
import useStore from '___store'

import { Answers, DOCUMENT_FLOW_MODES } from '___types'
import { useUser } from 'queries/user/useUser'
import { analytics, StorageService } from 'utils'
import { useSubscriptionCheck } from './useSubscriptionCheck'

export const useTemplateActions = () => {
  // Implement properly
  const { initializeWizard } = useStore('initializeWizard')

  const { push, replace, location } = useHistory()
  const { check, isBasic, isFree, isTeam, isTrial } = useSubscriptionCheck()
  const user = useUser()!
  const onUseTemplate = (templateId: string, additionalData: { name: string; isCasus: boolean }, externalAnswers?: Answers) => {
    if (!check()) return
    replace(location.pathname)
    // v2 routing
    if (user.version === 'v1' || user.version === 'v2') {
      push(`/document/new/${templateId}`) // v2
    } else if (user.version === 'v3') {
      const initializeWizardPayload = { mode: DOCUMENT_FLOW_MODES.NEW, templateId }
      if (externalAnswers?.length) Object.assign(initializeWizardPayload, { answers: externalAnswers })
      initializeWizard(initializeWizardPayload)
      push(`/document/${templateId}/new`) // v3
    } else {
      throw new Error('Invalid user version')
    }
    if (isFree) return
    const { name, isCasus } = additionalData
    let key = ''
    if (isCasus) {
      key = isTrial ? 'trial_casus_template_used' : 'subsc_casus_template_used'
    } else {
      key = isTrial ? 'subsc_template_used' : 'trial_template_used'
    }
    if (key)
      analytics.logEvent(key, {
        id: templateId,
        name,
        subscriptionPlan: isBasic ? 'basic' : isTeam ? 'team' : undefined,
      })
  }

  const onEditTemplate = (templateId: string): void => {
    if (!check()) return
    replace(location.pathname)
    push(`/template/${templateId}/edit`)
    StorageService.remove('templateCreationState')
  }

  return { onUseTemplate, onEditTemplate }
}
