// import { v4 as uuid } from 'uuid'
// import { ORIENTATION, PAPER_NAMES } from 'Wizard/constants'

import { foundSegmentReferences } from './editor-content'
import {
  // deepAssign,
  fullAssign,
} from './index'

// Generates an index range (of segments within a page) with a given start index and returns an array with the start and end indices
// const generatePageRange = (start = 0) => [start, -Infinity]
// Generates a section layout
// const generateLayout = layout =>
//   deepAssign({ orientation: ORIENTATION.vertical, paper: PAPER_NAMES.A4, margins: { top: 1.25, left: 1, bottom: 0.75, right: 1 } }, layout)
// Generates a generic section
// const generateSection = section => deepAssign({ id: uuid(), title: 'Generic Section Title', layout: generateLayout(), pages: [] }, section)
// Extracts sections from the data structure
// const extractSections = (dataStructure = {}) => {
//   const { sections, pages } = dataStructure.segments.reduce(
//     (acc, cur, i) => {
//       const { sections, pages } = acc
//       pages[pages.length - 1][1] = i + 1
//       if (cur.break && !(cur.break.type === 'page' && pages.push(generatePageRange(i + 1)))) {
//         const { id, title, layout } = cur.break
//         const lastSection = sections[sections.length - 1]
//         Object.entries({ id, title, layout, pages: pages.slice() }).reduce(
//           (accumulated, [key, value]) => (value ? Object.assign(accumulated, { [key]: value }) : accumulated),
//           lastSection
//         )
//         sections.push(generateSection({ id: 'generic-section-id' })) //change when BE implements sectionIds
//         pages.length = 0
//         pages.push(generatePageRange(i + 1))
//       }
//       return acc
//     },
//     { sections: [generateSection({ id: 'generic-section-id' })], pages: [generatePageRange()] } //change when BE implements sectionIds
//   )
//   const lastPage = pages[pages.length - 1]
//   const lastSection = sections[sections.length - 1]
//   if (lastPage[1] <= lastPage[0]) pages.pop()
//   if (!pages.length) sections.pop()
//   else Object.assign(lastSection, { pages })
//   return sections
// }

// const initializeWizard = (state, payload = {}) => {
//   const initializedState = { ...state, ...payload, sections: extractSections(payload.dataStructure) }
//   const { locations = {} } = initializedState
//   const { choice = {}, replacement = {} } = locations
//   const choiceMarkers = Object.values(choice).reduce((acc, markerArray) => [...acc, ...markerArray.map(({ id }) => id)], [])
//   const replacementMarkers = Object.values(replacement).reduce((acc, markerArray) => [...acc, ...markerArray.map(({ id }) => id)], [])
//   const choiceEvaluatedState = choiceMarkers.reduce((acc, cmId) => evaluateChoiceMarker(acc, { id: cmId }), initializedState)
//   const replacementEvaluatedState = replacementMarkers.reduce((acc, rmId) => evaluateReplacementMarker(acc, { id: rmId }), choiceEvaluatedState)
//   return replacementEvaluatedState
// }

const initialState = {
  initialized: false,
  popup: null,
  title: null, // revert back to '' if it's causing issues for previous iterations of the Wizard
  configuring: null, // revert back to [] if it's causing issues for previous iterations of the Wizard
  answering: null,
  dataStructure: null, // revert back to {} if it's causing issues for previous iterations of the Wizard
  sections: null, // revert back to [] if it's causing issues for previous iterations of the Wizard
  locations: null, // revert back to { segments: {}, text: {} } if it's causing issues for previous iterations of the Wizard
  styles: null, // revert back to {} if it's causing issues for previous iterations of the Wizard
  numberingSystem: null, // revert back to {} if it's causing issues for previous iterations of the Wizard
  questions: null, // revert back to [] if it's causing issues for previous iterations of the Wizard
  dependencies: null, // revert back to {} if it's causing issues for previous iterations of the Wizard
  questionLayout: null, // revert back to [] if it's causing issues for previous iterations of the Wizard
  answers: null, // revert back to [] if it's causing issues for previous iterations of the Wizard
  dirty: false,
  mode: null, // revert back to '' if it's causing issues for previous iterations of the Wizard
}
const resetWizard = () => Object.keys(foundSegmentReferences).forEach(id => delete foundSegmentReferences[id]) || { ...initialState }

const polluteState = state => fullAssign({}, state, { dirty: true })

const updateTitle = (state, payload) => fullAssign({}, state, { title: payload })

// const generateConfigEntry = config => Object.assign({ key: '', id: '' }, config)
// const appendToConfiguringStack = (state, config) => fullAssign({}, state, { configuring: state.configuring.concat(generateConfigEntry(config)) })
const filterConfiguringStack = (state, test, update = false) => {
  const result = state.configuring.filter(config => !test(config)) || []
  return (state.configuring.length !== result.length && Object.assign(state, { configuring: result })) || update ? Object.assign({}, state) : state
}
const filterConfiguringStackByKey = (state, key) => filterConfiguringStack(state, config => config.key === key)
const filterConfiguringStackById = (state, id) => filterConfiguringStack(state, config => config.id === id)

// const addNewConfiguration = (state, payload) => appendToConfiguringStack(payload)
const removeConfiguration = (state, payload) =>
  payload.id ? filterConfiguringStackById(state, payload.id) : filterConfiguringStackByKey(state, payload.key)

export { initialState, resetWizard, polluteState, updateTitle, removeConfiguration }
