import React from 'react'
import { DateTime } from 'luxon'
import useStore from '___store'

import { ButtonGroup, Input, Select } from 'components'
import { classes } from '../../../index'

const formatValue = {
  string: value => value,
  number: value => Number(value),
  date: value => DateTime.fromISO(value).toFormat('yyyy-MM-dd'),
  'date-time': value => DateTime.fromISO(value).toFormat("yyyy-MM-dd'T'HH:mm"),
}
const inputTypeMap = {
  string: 'text',
  number: 'number',
  date: 'date',
  'date-time': 'datetime-local',
}

const ValueInput = React.memo(({ id }) => {
  const { optionById: { value = '', valueType = 'string' } = {}, updateOption } = useStore(`selectOptionById[${id}]`, 'updateOption')

  const predefined = Boolean(value)

  const {
    wrapper: wrapperClass,
    text: textClass,
    valueInput: { input: inputClass, select: selectClass },
  } = classes.configEntry.optionConfiguration.fragment

  const inputType = inputTypeMap[valueType]

  return (
    <div className={wrapperClass}>
      <span className={textClass}>Value:</span>
      <ButtonGroup collapsed>
        <Select
          className={selectClass}
          value={valueType}
          options={{ string: 'text', number: 'number', date: 'date', 'date-time': 'time' }}
          onChange={val => console.log(val)}
          directionPriority="vertical"
          verticalDirection="down"
          horizontalDirection="left"
          cornerAnchor={false}
        />
        {['date', 'date-time'].includes(valueType) ? (
          <Input
            key={`Option-${id}-value-input-${predefined}`}
            className={inputClass}
            type={inputType}
            value={formatValue[valueType](value)}
            onChange={value => updateOption({ optionId: id, value: String(value) })}
          />
        ) : (
          <Input
            key={`Option-${id}-value-input-${predefined}`}
            className={inputClass}
            type={inputType}
            defaultValue={formatValue[valueType](value)}
            onChange={value => updateOption({ optionId: id, value: String(value) })}
            needsConfirmation
            blurShowUnmodified
          />
        )}
      </ButtonGroup>
    </div>
  )
})

ValueInput.displayName = 'Wizard-Configuration-OptionConfiguration-ValueInput'

export default ValueInput
