import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import { useLoadMasterdata } from 'queries/app/useLoadMasterdata'
import { useOnAuthRedirect } from './hooks/useOnAuthRedirect'

import LoginFormContainer from 'pages/Auth/components/LoginForm/LoginForm.container'
import ForgotPasswordFormContainer from 'pages/Auth/components/ForgotPasswordForm/ForgotPasswordForm.container'
import NewPasswordFormContainer from 'pages/Auth/components/NewPasswordForm/NewPasswordForm.container'
import { Loader, MessagePopup } from 'components/common'
import authPageContents, { ContentStructure } from 'pages/Auth/constants/content'
import ImgVorlagen from 'assets/illustrations/dokumente-vorlagen.svg'
import ImgAktenschrank from 'assets/illustrations/aktenschrank.svg'
import ImgRechtsberatung from 'assets/illustrations/rechtsberatung.svg'
import RegistrationPage from './RegistrationPage'

import {
  CopySection,
  AuthSection,
  Container,
  CopyContent,
  Heading,
  HeadingContent,
  Text,
  BulletPoints,
  BulletPoint,
  ImageBox,
  Image,
  AuthContent,
  Logo,
} from './AuthenticationPage.styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const AuthenticationPage: React.FC = () => {
  const { data } = useLoadMasterdata()
  useOnAuthRedirect()
  const { t } = useTranslation()
  const tenant: string = window.location.host.split('.')[0]
  const authPageContent: ContentStructure = authPageContents[tenant] || authPageContents['app']
  const message = useSelector((state: any) => state.message)

  let content: React.ReactNode

  if (!data) {
    content = <Loader />
  } else {
    content = (
      <AuthContent>
        <Switch>
          <Route path='/auth/register/:type?' component={RegistrationPage} />
          <Route path='/auth/login' component={LoginFormContainer} />
          <Route
            path='/auth/passwort-wiederherstellen'
            exact
            component={ForgotPasswordFormContainer}
          />
          <Route path='/auth/passwordReset' exact component={NewPasswordFormContainer} />
          <Redirect to={'/auth/login'} />
        </Switch>
        <MessagePopup message={message} />
      </AuthContent>
    )
  }

  return (
    <Container>
      <Logo />
      <CopySection>
        <CopyContent>
          <HeadingContent>
            <Heading variant='h1'>{t(authPageContent?.heading[0])}</Heading>
          </HeadingContent>
          <Text variant='h5'>{t(authPageContent?.text)}</Text>
          <BulletPoints>
            <BulletPoint>
              <ImageBox>
                <Image src={ImgVorlagen} alt='Dokumente und Vorlagen' />
              </ImageBox>
              {t(authPageContent?.bulletpoint1)}
            </BulletPoint>
            <BulletPoint>
              <ImageBox>
                <Image src={ImgAktenschrank} alt='aktenschrank' />
              </ImageBox>
              {t(authPageContent?.bulletpoint2)}
            </BulletPoint>
            <BulletPoint>
              <ImageBox>
                <Image src={ImgRechtsberatung} alt='aktenschrank' />
              </ImageBox>
              {t(authPageContent?.bulletpoint3)}
            </BulletPoint>
          </BulletPoints>
        </CopyContent>
      </CopySection>
      <AuthSection>{content}</AuthSection>
    </Container>
  )
}

export default AuthenticationPage
