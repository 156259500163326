import { useMemo } from 'react'
import useStore, { WizardSegmentByIdSelector, WizardState, getSegmentById } from '___store'

import { SEGMENT_TYPES, TABLE_SECTION_TYPES, TableSectionsType, TableObject, Segment, TableCellObject, ParagraphObject } from '___types'
import { useEditorContext } from '..'
import { mapDataStructureStyles } from '../helpers'

type UseStoreHookResultType = { wizardSegmentById?: WizardSegmentByIdSelector }
export type ComputedTableSegmentType = TableObject & { tableSections: TableSectionsType }
type UseSegmentResultType = (ParagraphObject | ComputedTableSegmentType | TableCellObject) | undefined

export const useSegment = (id: string): UseSegmentResultType => {
  const editorContext = useEditorContext()

  const staticPseudoState = Object.assign({}, editorContext as unknown) as WizardState
  const contextSegment = getSegmentById(staticPseudoState, id, editorContext.mode)[0]

  const { wizardSegmentById } = useStore(!contextSegment ? `selectWizardSegmentById[${id}]` : undefined) as UseStoreHookResultType

  const result = Object.assign({}, contextSegment || wizardSegmentById)
  const { type, styles } = result as Segment | TableCellObject
  if (styles?.length) Object.assign(result, { styles: mapDataStructureStyles(styles) })
  const { header, body, footer } = result as TableObject
  const tableSections = useMemo(() => {
    if (type !== SEGMENT_TYPES.TABLE) return
    const sectionsObject = { [SEGMENT_TYPES.TABLE_HEADER]: header, [SEGMENT_TYPES.TABLE_BODY]: body, [SEGMENT_TYPES.TABLE_FOOTER]: footer }
    return TABLE_SECTION_TYPES.reduce((acc, cur) => {
      if (!sectionsObject[cur]?.length) return acc
      return Object.assign(acc, { [cur]: sectionsObject[cur] })
    }, {}) as TableSectionsType
  }, [type, header, body, footer])
  if (type === SEGMENT_TYPES.TABLE) Object.assign(result, { tableSections })
  const useSegmentReturn = result

  return useSegmentReturn as UseSegmentResultType
}

export default useSegment
