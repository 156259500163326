import React from 'react'
import { Container, Typography, Content } from './LabelAndDescription.styles'
import { TextInputGroup, TextareaGroup } from 'components/common'
import { forms as content } from 'pages/Authenticated/Template/constants/content'
import {
  ILabelAndDescriptionErrorState,
  ILabelAndDescriptionState,
} from './LabelAndDescription.container'
import { useTranslation } from 'react-i18next'

interface Props {
  values: ILabelAndDescriptionState
  errors: ILabelAndDescriptionErrorState
  heading: string
  superadmin: boolean
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const LabelAndDescription: React.FC<Props> = ({
  values,
  errors,
  heading,
  superadmin,
  handleChange,
  handleBlur,
}) => {
  const { label, description, answerExample, group } = values

  const { t } = useTranslation()

  return (
    <Container>
      <Typography variant='h5'>{t(heading)}</Typography>
      <Content>
        <TextInputGroup
          label={t(content.labelLabel)}
          name='label'
          type='text'
          value={label}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.label}
          placeholder={t(content.labelPlaceholder)}
        />
        <TextareaGroup
          label={t(content.descriptionLabel)}
          name='description'
          value={description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.description}
          placeholder={t(content.descriptionPlaceholder)}
          style={{ marginBottom: 24 }}
        />
        <TextareaGroup
          label={t(content.answerExampleLabel)}
          name='answerExample'
          value={answerExample}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.description}
          placeholder={t(content.answerExamplePlaceholder)}
        />
        {superadmin && (
          <TextInputGroup
            label={t(content.groupLabel)}
            name='group'
            type='text'
            value={group}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.label}
            placeholder={content.groupPlaceholder}
            InputGroupContainerProps={{ style: { marginTop: 24 } }}
          />
        )}
      </Content>
    </Container>
  )
}

export default LabelAndDescription
