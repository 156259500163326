import SecurityPicker from './SecurityPicker'

const classes = {
  container: {
    wrapper: 'Security-Form-Container-wrapper',
    title: 'Security-Form-Container-Title-wrapper',
    item: {
      wrapper: 'Security-Form-Item-wrapper',
      icon: {
        wrapper: 'Security-Form-Item-Icon-wrapper',
        svg: 'Security-Form-Item-Icon-svg-wrapper',
      },
      content: {
        wrapper: 'Security-Form-Item-Content-wrapper',
        title: 'Security-Form-Item-Content-Title-wrapper',
        description: 'Security-Form-Item-Content-Description-wrapper',
      },
    },
  },
}

export { classes }

export default SecurityPicker

