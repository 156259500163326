import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, PremiumProps, className, drawStarPath } from './index'

const ZOOM = 1

const CENTER_X = 50
const CENTER_Y = 50

const BADGE_POINT_COUNT = 15
const BADGE_OUTER_RADIUS = 38
const BADGE_MIDDLE_RADIUS = 35
const BADGE_INNER_RADIUS = 25

const STAR_POINT_COUNT = 5
const STAR_OUTER_RADIUS = 18
const STAR_INNER_RADIUS = 8

const LOCK_OUTLINE = 'M 15 90 v -45 h 10 v -10 A 25 25 0 0 1 75 35 v 10 h 10 v 45 L 15 90'
const LOCK_HOLE_1 = 'M 35 45 v -10 A 15 15 0 0 1 65 35 v 10 L 35 45'
const LOCK_HOLE_2 = 'M 45 70 L 47 60 A 5 5 0 1 1 53 60 L 55 70 L 45 70'

// { available = 'true'}
const Premium: FunctionComponent<PremiumProps> = React.memo(() => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const iconId = useMemo(uuid, [])

  const badge = useMemo(() => drawStarPath(CENTER_X, CENTER_Y, BADGE_POINT_COUNT, 3, 3, BADGE_OUTER_RADIUS, BADGE_MIDDLE_RADIUS), [])
  const star = useMemo(() => drawStarPath(CENTER_X, CENTER_Y, STAR_POINT_COUNT, 3, 3, STAR_OUTER_RADIUS, STAR_INNER_RADIUS), [])

  return (
    <svg className={`${className} Premium`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={iconId}>
          <rect x="0" y="0" width="100" height="100" stroke="none" fill="white" />
          <path d={badge} stroke="none" strokeLinejoin="round" fill="black" />
        </mask>
        <mask id={`${iconId}-lock`}>
          <rect x="0" y="0" width="100" height="100" stroke="none" fill="white" />
          <path d={[LOCK_OUTLINE, LOCK_HOLE_1, LOCK_HOLE_2].join(' ')} stroke="none" fill="rgba(0, 0, 0, 0.5)" fillRule="evenodd" />
        </mask>
      </defs>
      <path d={badge} stroke="currentColor" strokeWidth="3" strokeLinejoin="round" fill="none" />
      <circle cx={CENTER_X} cy={CENTER_Y} r={BADGE_INNER_RADIUS} stroke="currentColor" strokeWidth="3" fill="none" />
      <path d={star} stroke="none" strokeLinejoin="round" fill="currentColor" />
      <path d="M 45 52 L 61 98 L 71 94 L 55 48 Z" stroke="currentColor" strokeWidth="3" strokeLinejoin="round" fill="none" mask={`url(#${iconId})`} />
      <path d="M 55 52 L 39 98 L 29 94 L 45 48 Z" stroke="currentColor" strokeWidth="3" strokeLinejoin="round" fill="none" mask={`url(#${iconId})`} />
      {/* {!available ? <rect x="5" y="5" width="90" height="90" rx="10" ry="10" stroke="none" fill="black" mask={`url(#${iconId}-lock)`} filter='' /> : null} */}
    </svg>
  )
})

export { Premium }
export default Premium
