import { v4 as uuid } from 'uuid'

import {
  COMPLEX_GETTER_NOT_FOUND,
  FIND_WITH_INDEX_NOT_FOUND,
  LOCATION_TYPES,
  LocationType,
  Question,
  Questions,
  SegmentsLocation,
  SegmentsLocations,
  TextLocation,
  TextLocations,
  Writable,
  // ModifierType,
  // StaticCalculationType,
  // LocationModifier,
  Conditionals,
  OptionConditional,
  LanguageConditional,
  SplitConditional,
  RuleConditional,
  SubRecord,
  LanguageValue,
  ExternalServiceField,
  SignatureSecurityLevel,
} from '___types'
import { filterObjectFields } from 'utilities/helpers'
import { WizardState, deepAssign, findWithIndex, updateWizardState } from '.'

// ====================================================================================================================================== //
// ============================================================= GENERATORS ============================================================= //
// ====================================================================================================================================== //
//
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ============================================================ GET QUESTION ============================================================ //
// ====================================================================================================================================== //
const getQuestion = (state: WizardState, test: (question: Question) => boolean) => findWithIndex(state.questions || [], test)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= GET QUESTION BY ID ========================================================= //
// ====================================================================================================================================== //
export const getQuestionById = (state: WizardState, id: string): [Question, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  id ? getQuestion(state, question => question.id === id) : (FIND_WITH_INDEX_NOT_FOUND as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================== GET SUBQUESTIONS ========================================================== //
// ====================================================================================================================================== //
export const getSubQuestions = (state: WizardState): Questions => {
  const subQuestionIds = state.questionLayout?.find(group => group.type === 'sub-questions')?.questions
  return (subQuestionIds?.length && state.questions?.filter(question => subQuestionIds.includes(question.id))) || []
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================== GET SUB QUESTIONS BY QUESTION ID ================================================== //
// ====================================================================================================================================== //
export const getSubQuestionsByQuestionId = (state: WizardState, questionId: string): Questions => {
  const subQuestions = getSubQuestions(state)
  return subQuestions.filter(question => question.advanced.subQuestionTo.split(':')[0] === questionId)
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// =================================================== GET SUB QUESTIONS BY OPTION ID =================================================== //
// ====================================================================================================================================== //
export const getSubQuestionsByOptionId = (state: WizardState, optionId: string): Questions => {
  const subQuestions = getSubQuestions(state)
  return subQuestions.filter(question => question.advanced.subQuestionTo.split(':')[2] === optionId)
}
// ====================================================================================================================================== //
//
//
//
//
//
//
// ====================================================================================================================================== //
// ======================================================== GET MERGED LOCATIONS ======================================================== //
// ====================================================================================================================================== //
type MergedLocationsType = SegmentsLocations & TextLocations
export const getMergedLocations = (state: WizardState): MergedLocationsType =>
  state.locations ? Object.assign({}, state.locations.segments, state.locations.text) : {}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ============================================================= GET MARKER ============================================================= //
// ====================================================================================================================================== //
export type FoundMarkerType = [SegmentsLocation | TextLocation, string, SegmentsLocation[] | TextLocation[], number]
// ======================================================== GET MARKER: OVERLOAD ======================================================== //
function getMarker(
  state: WizardState,
  testFunction: (marker: SegmentsLocation) => boolean,
  type: typeof LOCATION_TYPES.SEGMENTS
): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND
function getMarker(
  state: WizardState,
  testFunction: (marker: TextLocation) => boolean,
  type: typeof LOCATION_TYPES.TEXT
): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND
function getMarker(
  state: WizardState,
  testFunction: ((marker: SegmentsLocation) => boolean) | ((marker: TextLocation) => boolean),
  type?: LocationType
): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND
// ====================================================================================================================================== //
function getMarker(
  state: WizardState,
  testFunction: ((marker: SegmentsLocation) => boolean) | ((marker: TextLocation) => boolean),
  type?: LocationType,
  result = undefined as never,
  parentId = undefined as never,
  markerArray = undefined as never,
  index = -1 as never
): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND {
  const locations = state.locations ? (type && state.locations[type]) || getMergedLocations(state) : ({} as MergedLocationsType)
  ;(Object.entries(locations) as [string, SegmentsLocation[] | TextLocation[]][]).some(([id, locationArray]) =>
    locationArray.some(
      //@ts-ignore
      (location, i) =>
        testFunction(location as never) && // ============== if the location passes the test function
        (result = location as never) && // ================= set the result to the found location
        (parentId = id as never) && // ===================== set the parent id to the current iterating id
        (markerArray = locationArray as never) && // ======= set the marker array to the current iterating location array
        (index = i as never) // ============================ set the index to the current iterating index
    )
  )
  return [result, parentId, markerArray, index]
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================== GET MARKER BY ID ========================================================== //
// ====================================================================================================================================== //
export const getMarkerById = (state: WizardState, id: string): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND =>
  id ? getMarker(state, (marker: SegmentsLocation | TextLocation) => marker.id === id) : COMPLEX_GETTER_NOT_FOUND
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ===================================================== GET MARKER BY TYPE AND ID ===================================================== //
// ===================================================================================================================================== //
type TestFunctionMarkerParameterType<T> = T extends typeof LOCATION_TYPES.SEGMENTS ? SegmentsLocation : TextLocation
// ================================================ GET MARKER BY TYPE AND ID: OVERLOAD ================================================ //
export function getMarkerByTypeAndId(
  state: WizardState,
  type: typeof LOCATION_TYPES.SEGMENTS,
  id: string
): [SegmentsLocation, string, SegmentsLocation[], number] | typeof COMPLEX_GETTER_NOT_FOUND
export function getMarkerByTypeAndId(
  state: WizardState,
  type: typeof LOCATION_TYPES.TEXT,
  id: string
): [TextLocation, string, TextLocation[], number] | typeof COMPLEX_GETTER_NOT_FOUND
export function getMarkerByTypeAndId(state: WizardState, type: LocationType, id: string): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND
// ====================================================================================================================================== //
// ====================================================================================================================================== //
// ====================================================================================================================================== //
export function getMarkerByTypeAndId(state: WizardState, type: LocationType, id: string): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND {
  return type && id ? getMarker(state, (marker: TestFunctionMarkerParameterType<typeof type>) => marker.id === id, type) : COMPLEX_GETTER_NOT_FOUND
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// =================================================== ADD SIMPLE MARKER CONDITIONAL =================================================== //
// ===================================================================================================================================== //
export const addSimpleMarkerConditional = <T extends SegmentsLocation | TextLocation>(
  marker: T,
  key: 'options' | 'languages' | 'splits',
  conditional: string | LanguageValue
): [T, boolean] => {
  const existingConditionals = ((marker.conditionals && marker.conditionals[key]) || []) as (string | LanguageValue)[]
  const resultingSimpleConditionals = Array.from(new Set(existingConditionals.concat(conditional)))
  if (resultingSimpleConditionals.length !== existingConditionals.length) {
    const resultingConditionals = Object.assign({}, marker.conditionals, { [key]: resultingSimpleConditionals })
    return [Object.assign({}, marker, { conditionals: resultingConditionals }), true]
  }
  return [marker, false]
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ==================================================== ADD MARKER RULE CONDITIONAL ==================================================== //
// ===================================================================================================================================== //
export const addMarkerRuleConditional = <T extends SegmentsLocation | TextLocation>(marker: T, conditional: RuleConditional): [T, boolean] => {
  const existingRule = marker.conditionals?.rules.find(({ id }) => id === conditional.id)
  if (existingRule) return [marker, false]
  const resultingRuleConditionals = (marker.conditionals?.rules || []).concat(conditional)
  const resultingConditionals = Object.assign(marker.conditionals || {}, { rules: resultingRuleConditionals })
  return [Object.assign({}, marker, { conditionals: resultingConditionals }), true]
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================== REMOVE SIMPLE MARKER CONDITIONAL ================================================== //
// ====================================================================================================================================== //
// export const removeSimpleMarkerConditional = <T extends SegmentsLocation | TextLocation>(
//   marker: T,
//   key: 'options' | 'languages' | 'splits',
//   conditional: string
// ): [T, boolean] => {
//   const existingConditionals = (marker.conditionals && marker.conditionals[key]) || []
//   const resultingSimpleConditionals = existingConditionals.filter(id => id !== conditional)
//   if (resultingSimpleConditionals.length === existingConditionals.length) return [marker, false]
//   const resultingConditionals = Object.assign(marker.conditionals, { [key]: resultingSimpleConditionals })
//   return [Object.assign({}, marker, { conditionals: resultingConditionals }), true]
// }
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// =================================================== REMOVE MARKER RULE CONDITIONAL =================================================== //
// ====================================================================================================================================== //
// export const removeMarkerRuleConditional = <T extends SegmentsLocation | TextLocation>(marker: T, conditional: string): [T, boolean] => {
//   const existingRule = marker.conditionals?.rules.find(({ id }) => id === conditional)
//   if (existingRule) return [marker, false]
//   const resultingRuleConditionals = (marker.conditionals?.rules || []).filter(({ id }) => id !== conditional)
//   const resultingConditionals = Object.assign(marker.conditionals, { rules: resultingRuleConditionals })
//   return [Object.assign({}, marker, { conditionals: resultingConditionals }), true]
// }
// ====================================================================================================================================== //
//
//
//
//
//
//
//
//
//
//
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ========================================================= REDUCER FUNCTIONS ========================================================= //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //

export type UpdateWizardDescriptionPayload = string
const updateWizardDescription = (state: WizardState, payload: UpdateWizardDescriptionPayload): WizardState =>
  updateWizardState(state, { description: payload })

export type AddLanguagePayload = LanguageValue
const addLanguage = (state: WizardState, payload: AddLanguagePayload) => {
  const resultingAvailableLanguages = Array.from(new Set((state.languages?.available || []).concat(payload)))
  if (resultingAvailableLanguages.length === state.languages?.available?.length) return state
  const resultingLanguages = Object.assign({}, state.languages, { available: resultingAvailableLanguages })
  return updateWizardState(state, { languages: resultingLanguages })
}

export type RemoveLanguagePayload = LanguageValue
const removeLanguage = (state: WizardState, payload: RemoveLanguagePayload) => {
  const resultingAvailableLanguages = (state.languages?.available || []).filter(language => language !== payload)
  if (resultingAvailableLanguages.length === state.languages?.available?.length) return state
  const resultingLanguages = Object.assign({}, state.languages, {
    available: resultingAvailableLanguages,
    selected: (state.languages?.selected || []).filter(language => language !== payload),
  })
  return updateWizardState(state, { languages: resultingLanguages })
}

export type AddApproverPayload = string
const addApprover = (state: WizardState, payload: AddApproverPayload) => {
  const resultingApprovers = Array.from(new Set((state.approverList || []).concat(payload)))
  if (resultingApprovers.length === state.approverList?.length) return state
  return updateWizardState(state, { approverList: resultingApprovers })
}

export type RemoveApproverPayload = string
const removeApprover = (state: WizardState, payload: RemoveApproverPayload) => {
  const resultingApprovers = (state.approverList || []).filter(id => id !== payload)
  if (resultingApprovers.length === state.approverList?.length) return state
  return updateWizardState(state, { approverList: resultingApprovers })
}

const toggleLanguageSelect = (state: WizardState) => {
  const resultingLanguages = Object.assign({}, state.languages, { select: ['multi', 'single'][Number(state.languages?.select === 'multi')] })
  return updateWizardState(state, { languages: resultingLanguages })
}

export type InstantiateIntegrationPayload = { id: string; cue: string }
const instantiateIntegration = (state: WizardState, payload: InstantiateIntegrationPayload) =>
  updateWizardState(state, { integrations: Object.assign({}, state.integrations, { [uuid()]: { id: payload.id, cue: payload.cue, fields: [] } }) })

export type DisconnectIntegrationPayload = string
const disconnectIntegration = (state: WizardState, payload: DisconnectIntegrationPayload) => {
  const resultingIntegrations = Object.assign({}, state.integrations)
  delete resultingIntegrations[payload]
  return !(state.integrations && Object.keys(state.integrations).length === Object.keys(resultingIntegrations).length)
    ? updateWizardState(state, { integrations: resultingIntegrations })
    : state
}

export type AddIntegrationFieldPayload = { id: string; field: ExternalServiceField }
const addIntegrationField = (state: WizardState, payload: AddIntegrationFieldPayload) => {
  const integration = state.integrations && state.integrations[payload.id]
  if (!integration) return state
  const resultingIntegrationFields = Array.from(new Set((integration.fields || []).concat(payload.field)))
  if (resultingIntegrationFields.length === integration.fields.length) return state
  const resultingIntegration = Object.assign({}, integration, { fields: resultingIntegrationFields })
  const resultingIntegrations = Object.assign({}, state.integrations, { [payload.id]: resultingIntegration })
  return updateWizardState(state, { integrations: resultingIntegrations })
}

export type RemoveIntegrationFieldPayload = { id: string; fieldId: string }
const removeIntegrationField = (state: WizardState, payload: RemoveIntegrationFieldPayload) => {
  const integration = state.integrations && state.integrations[payload.id]
  if (!integration) return state
  const resultingIntegrationFields = integration.fields?.filter(({ id }) => id !== payload.fieldId)
  if (resultingIntegrationFields.length === integration.fields.length) return state
  const resultingIntegration = Object.assign({}, integration, { fields: resultingIntegrationFields })
  const resultingIntegrations = Object.assign({}, state.integrations, { [payload.id]: resultingIntegration })
  return updateWizardState(state, { integrations: resultingIntegrations })
}

const splitDocument = (state: WizardState) => {
  const resultingSplits = Object.assign({}, state.splits, { [uuid()]: `Split ${Object.keys(state.splits || {}).length + 1}` })
  return updateWizardState(state, { splits: resultingSplits })
}

export type UpdateDocumentSplitPayload = { id: string; label: string }
const updateDocumentSplit = (state: WizardState, payload: UpdateDocumentSplitPayload) => {
  const resultingSplits = Object.assign({}, state.splits, { [payload.id]: payload.label })
  return updateWizardState(state, { splits: resultingSplits })
}

export type RemoveDocumentSplitPayload = { id: string }
const removeDocumentSplit = (state: WizardState, payload: RemoveDocumentSplitPayload) => {
  const resultingSplits = Object.assign({}, state.splits)
  if (state.splits) delete resultingSplits[payload.id]
  return Object.keys(state.splits || {}).length !== Object.keys(resultingSplits).length
    ? updateWizardState(state, { splits: resultingSplits })
    : state
}

export type SetSignatureSecurityLevelPayload = SignatureSecurityLevel
const setSignatureSecurityLevel = (state: WizardState, payload: SetSignatureSecurityLevelPayload) =>
  state.signatureSecurityLevel !== payload ? updateWizardState(state, { signatureSecurityLevel: payload }) : state

export type UpdateMarkerPayload = SubRecord<SegmentsLocation | TextLocation> & { id: string }
const updateMarker = (state: WizardState, payload: UpdateMarkerPayload) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
  if (!parentId) return state
  const filteredPayload = filterObjectFields(payload, 'id', 'type')
  if (!Object.keys(filteredPayload).length) return state
  //@ts-ignore
  const resultingMarker = deepAssign({}, marker as Record<keyof typeof marker, unknown>, filteredPayload) as (typeof markerArray)[number]
  //@ts-ignore
  markerArray.splice(index, 1, resultingMarker)
  return Object.assign({}, state)
}

export type ConnectMarkerToValueSourcePayload = { id: string; valueSource: string }
const connectMarkerToValueSource = (state: WizardState, payload: ConnectMarkerToValueSourcePayload) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
  if (!parentId) return state
  const resultingValueSources = Array.from(new Set((marker!.valueSources || []).concat(payload.valueSource)))
  if (resultingValueSources.length === (marker!.valueSources || []).length) return state
  //@ts-ignore
  const resultingMarker = Object.assign({}, marker, { valueSources: resultingValueSources }) as (typeof markerArray)[number]
  //@ts-ignore
  markerArray.splice(index, 1, resultingMarker)
  if (payload.valueSource.slice(0, 8) === 'question') {
    const [question, index] = getQuestionById(state, payload.valueSource.slice(9))
    if (index !== -1) {
      const resultingMarkers = Array.from(new Set(question!.markers.concat(marker!.id)))
      state.questions?.splice(index, 1, Object.assign({}, question, { markers: resultingMarkers }))
    }
  }
  return Object.assign({}, state)
}

export type DisconnectMarkerFromValueSourcePayload = { id: string; valueSource: string }
// const disconnectMarkerFromValueSource = (state: WizardState, payload: DisconnectMarkerFromValueSourcePayload) => {
//   const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
//   if (!parentId) return state
//   const resultingValueSources = (marker!.valueSources || []).filter(source => source !== payload.valueSource)
//   if (resultingValueSources.length === (marker!.valueSources || []).length) return state
//   //@ts-ignore
//   const resultingMarker = Object.assign({}, marker, { valueSources: resultingValueSources }) as (typeof markerArray)[number]
//   //@ts-ignore
//   markerArray.splice(index, 1, resultingMarker)
//   if (payload.valueSource.slice(0, 8) === 'question') {
//     const [question, index] = getQuestionById(state, payload.valueSource.slice(9))
//     if (index !== -1) {
//       const resultingMarkers = question!.markers.filter(id => id !== marker!.id)
//       state.questions?.splice(index, 1, Object.assign({}, question, { markers: resultingMarkers }))
//     }
//   }
//   return Object.assign({}, state)
// }
/////////////////////////////////////////////////// TEMPORARY ///////////////////////////////////////////////////
const disconnectMarkerFromValueSource = (state: WizardState, payload: DisconnectMarkerFromValueSourcePayload) => {
  let update = false
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
  if (parentId) {
    const resultingValueSources = (marker!.valueSources || []).filter(source => source !== payload.valueSource)
    if (resultingValueSources.length === (marker!.valueSources || []).length) return state
    //@ts-ignore
    const resultingMarker = Object.assign({}, marker, { valueSources: resultingValueSources }) as (typeof markerArray)[number]
    //@ts-ignore
    markerArray.splice(index, 1, resultingMarker)
    update = true
  }
  if (payload.valueSource.slice(0, 8) === 'question') {
    const [question, index] = getQuestionById(state, payload.valueSource.slice(9))
    if (index !== -1) {
      const resultingMarkers = question!.markers.filter(id => id !== payload!.id)
      state.questions?.splice(index, 1, Object.assign({}, question, { markers: resultingMarkers }))
      update = true
    }
  }
  if (!update) return state
  return Object.assign({}, state)
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

type AddMarkerConditionalPayloadType<T extends keyof Conditionals> = {
  id: string
  type: T
  conditional: Conditionals[T][number]
}
export type AddMarkerConditionalPayload = AddMarkerConditionalPayloadType<keyof Conditionals>
const addMarkerOptionConditionalMap = {
  options: (marker: SegmentsLocation | TextLocation, conditional: OptionConditional) => addSimpleMarkerConditional(marker, 'options', conditional),
  languages: (marker: SegmentsLocation | TextLocation, conditional: LanguageConditional) =>
    addSimpleMarkerConditional(marker, 'languages', conditional),
  splits: (marker: SegmentsLocation | TextLocation, conditional: SplitConditional) => addSimpleMarkerConditional(marker, 'splits', conditional),
  rules: addMarkerRuleConditional,
}
const addMarkerConditional = (state: WizardState, payload: AddMarkerConditionalPayload) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
  if (!parentId) return state
  //@ts-ignore
  const [resultingMarker, update] = addMarkerOptionConditionalMap[payload.type](marker, payload.conditional)
  if (!update) return state
  //@ts-ignore
  markerArray.splice(index, 1, resultingMarker as (typeof markerArray)[number])
  return Object.assign({}, state)
}

export type RemoveMarkerConditionalPayload = { id: string; conditional: string }
const removeMarkerConditional = (state: WizardState, payload: RemoveMarkerConditionalPayload) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
  if (!parentId) return state
  const resultingConditionals = Object.assign({}, marker!.conditionals || {})
  Object.assign(resultingConditionals, { options: (resultingConditionals.options || []).filter(id => id !== payload.conditional) })
  Object.assign(resultingConditionals, { languages: (resultingConditionals.languages || []).filter(id => id !== payload.conditional) })
  Object.assign(resultingConditionals, { splits: (resultingConditionals.splits || []).filter(id => id !== payload.conditional) })
  Object.assign(resultingConditionals, { rules: (resultingConditionals.rules || []).filter(({ id }) => id !== payload.conditional) })
  const update =
    resultingConditionals.options.length !== marker!.conditionals?.options?.length ||
    resultingConditionals.languages.length !== marker!.conditionals?.languages?.length ||
    resultingConditionals.splits.length !== marker!.conditionals?.splits?.length ||
    resultingConditionals.rules.length !== marker!.conditionals?.rules?.length
  if (!update) return state
  const resultingMarker = Object.assign({}, marker, { conditionals: resultingConditionals })
  //@ts-ignore
  markerArray.splice(index, 1, resultingMarker as (typeof markerArray)[number])
  return Object.assign({}, state)
}

export type AddMarkerValueMapPayload = { id: string; toMap: string; mapTo: string }
const addMarkerValueMap = (state: WizardState, payload: AddMarkerValueMapPayload) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
  if (!parentId) return state
  //@ts-ignore
  const resultingMarker = Object.assign({}, marker, { valueMap: Object.assign({}, marker.valueMap, { [payload.toMap]: payload.mapTo }) })
  //@ts-ignore
  markerArray.splice(index, 1, resultingMarker as (typeof markerArray)[number])
  return Object.assign({}, state)
}

export type RemoveMarkerValueMapPayload = { id: string; value: string }
const removeMarkerValueMap = (state: WizardState, payload: RemoveMarkerValueMapPayload) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
  if (!(parentId && marker!.valueMap)) return state
  //@ts-ignore
  delete marker.valueMap[payload.value]
  //@ts-ignore
  const resultingMarker = Object.assign({}, marker, { valueMap: Object.assign({}, marker.valueMap) })
  //@ts-ignore
  markerArray.splice(index, 1, resultingMarker as (typeof markerArray)[number])
  return Object.assign({}, state)
}

// export type AddMarkerModifierPayload = { markerId: string; type: ModifierType; fn: StaticCalculationType; value: string }
// const addMarkerModifier = (state: WizardState, payload: AddMarkerModifierPayload) => {
//   const [marker, parentId, markerArray, index] = getMarkerById(state, payload.markerId)
//   if (!parentId) return state
//   const resultingModifiers = (marker!.modifiers || []).concat(
//     Object.assign({}, filterObjectFields(payload, 'markerId'), { id: uuid() }) as LocationModifier
//   )
//   //@ts-ignore
//   const resultingMarker = Object.assign({}, marker, { modifiers: resultingModifiers }) as (typeof markerArray)[number]
//   //@ts-ignore
//   markerArray.splice(index, 1, resultingMarker)
//   return Object.assign({}, state)
// }

// export type RemoveMarkerModifierPayload = { markerId: string; id: string }
// const removeMarkerModifier = (state: WizardState, payload: RemoveMarkerModifierPayload) => {
//   const [marker, parentId, markerArray, index] = getMarkerById(state, payload.markerId)
//   if (!parentId) return state
//   const resultingModifiers = (marker!.modifiers || []).filter(modifier => modifier.id !== payload.id)
//   if (resultingModifiers.length === marker!.modifiers?.length) return state
//   //@ts-ignore
//   const resultingMarker = Object.assign({}, marker, { modifiers: resultingModifiers }) as (typeof markerArray)[number]
//   //@ts-ignore
//   markerArray.splice(index, 1, resultingMarker)
//   return Object.assign({}, state)
// }

export type ToggleQuestionPrivacyPayload = string
const toggleQuestionPrivacy = (state: WizardState, id: ToggleQuestionPrivacyPayload) => {
  const [question, index] = getQuestionById(state, id)
  if (index === -1) return state
  const resultingQuestion = Object.assign({}, question, { isPrivate: !question!.isPrivate })
  state.questions!.splice(index, 1, resultingQuestion)
  return Object.assign({}, state)
}

export {
  updateWizardDescription,
  addLanguage,
  removeLanguage,
  addApprover,
  removeApprover,
  toggleLanguageSelect,
  instantiateIntegration,
  disconnectIntegration,
  addIntegrationField,
  removeIntegrationField,
  splitDocument,
  updateDocumentSplit,
  removeDocumentSplit,
  setSignatureSecurityLevel,
  updateMarker,
  connectMarkerToValueSource,
  disconnectMarkerFromValueSource,
  addMarkerConditional,
  removeMarkerConditional,
  addMarkerValueMap,
  removeMarkerValueMap,
  // addMarkerModifier,
  // removeMarkerModifier,
  toggleQuestionPrivacy,
}
