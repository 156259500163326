import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  background: "#fafafa",
}))
