import React, { useMemo } from 'react'
import useStore from '___store'

import QuestionListItem from './QuestionListItem'
import Actions from './Actions'
import { classes } from '../../..'

export const QuestionGroup = React.memo(({ id }) => {
  const {
    questionLayoutGroupById: { type, label = '', questions = [] } = {},
    answering: beingAnswered,
    showGroup,
  } = useStore(`selectQuestionLayoutGroupById[${id}]`, `selectAnswering[${id}]`, `selectShowGroup[${id}]`)

  const questionListItems = useMemo(
    () => questions.map((id, i) => <QuestionListItem key={id} id={id} isStandalone={type === 'loose'} scrollToMarker={!i} />),
    [questions, type]
  )
  if (!showGroup) return null
  if (type === 'loose') return questionListItems
  const { wrapper: wrapperClass, label: labelClass } = classes.configEntry.questionnaire.quetionGroup
  return (
    <div id={id} className={wrapperClass} data-being-answered={beingAnswered}>
      <div>
        {label ? <span className={labelClass}>{label}</span> : null}
        {questionListItems}
        {beingAnswered ? <Actions /> : null}
      </div>
    </div>
  )
})

QuestionGroup.displayName = 'Wizard-Configuration-Questionnaire-QuestionGroup'

export default QuestionGroup
