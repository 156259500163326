import React from 'react'

import { className } from './index'

const EditText = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <line x1="40" y1="25" x2="60" y2="25" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" />
    <line x1="50" y1="25" x2="50" y2="75" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" />
    <line x1="40" y1="75" x2="60" y2="75" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" />
  </svg>
))

export { EditText }
export default EditText
