import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardAnsweringSelector, WizardTemplateIdSelector } from '___store'

import { QuestionGroup, QuestionOverview } from './Questionnaire.Components'
import { wizardLayoutLeftPaneDocumentQuestionnaireClasses as classes, useWizardLayoutContext } from 'Layouts/WizardLayout'
import { useTemplate } from '___hooks'
import { Redirect, useLocation } from 'react-router-dom'
import { DOCUMENT_DIRECTORY, WIZARD_URL_PARAM_MATCH_ALL } from '___types'

type UseStoreHookResultType = { wizardTemplateId: WizardTemplateIdSelector; wizardAnswering: WizardAnsweringSelector }

export const Questionnaire: FunctionComponent = React.memo(() => {
  const { wizardTemplateId, wizardAnswering } = useStore('selectWizardTemplateId', 'selectWizardAnswering') as UseStoreHookResultType
  const { search } = useLocation()
  const publicFlow = useWizardLayoutContext()
  const { paywallBlocked } = useTemplate(wizardTemplateId!, publicFlow)

  const redirectPath = useMemo(() => {
    const searchParams = Object.assign(
      Array.from(search.matchAll(WIZARD_URL_PARAM_MATCH_ALL)).reduce(
        (result, { groups: { key, value } = {} }) => (key ? Object.assign(result, { [key]: value }) : result),
        {}
      ) as Record<string, string>,
      { step: 'configure' }
    )
    const searchString = Object.entries(searchParams)
      .reduce((entries, entry) => entries.concat(entry.join('=')), [] as string[])
      .join('&')
    return `/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${wizardTemplateId}/new?${searchString}`
  }, [search, publicFlow, wizardTemplateId])

  if (paywallBlocked) return <Redirect to={redirectPath} />

  return (
    <div className={classes.wrapper}>
      <QuestionGroup key={`WizardLayout-LeftPane-Document-Questionnaire-QuestionGroup-${wizardAnswering}`} id={wizardAnswering} />
      <QuestionOverview />
    </div>
  )
})

Questionnaire.displayName = 'WizardLayout-LeftPane-Document-Questionnaire'

export default Questionnaire
