import React, { useCallback, useRef } from 'react'
import useStore from '___store'

import { Button, ButtonGroup } from 'components'
import { ShowHideFragment, SubQuestionFragment } from './components'
import { classes as entryClasses } from '../../index'
import { classes } from './index'

const AdvancedQuestionConfiguration = React.memo(props => {
  const questionParent = useRef()
  const { id } = props
  const { updateQuestionComplex, concludeConfiguring } = useStore('updateQuestionComplex', 'concludeConfiguring')

  const concludeCallback = useCallback(
    questionParent => {
      const payload = { id }
      if (questionParent) Object.assign(payload, { advanced: { subQuestionTo: questionParent } })
      updateQuestionComplex(payload)
      concludeConfiguring()
    },
    [id, updateQuestionComplex, concludeConfiguring]
  )

  return (
    <>
      <div className={classes.wrapper}>
        <ShowHideFragment questionId={id} />
        <SubQuestionFragment ref={questionParent} questionId={id} />
      </div>
      <div className={entryClasses.configEntry.footer}>
        <ButtonGroup>
          <Button onClick={() => concludeCallback(questionParent.current)} colorScheme="primary">
            Confirm
          </Button>
        </ButtonGroup>
      </div>
    </>
  )
})

AdvancedQuestionConfiguration.displayName = 'Wizard-Configuration-AdvancedQuestionConfiguration'

export default AdvancedQuestionConfiguration
