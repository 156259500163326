import { useMutation } from 'react-query'

import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type UploadImageVariables = { src: string; width: number; height: number }
const uploadTemplatePreviewImageMutationFunction = (variables: UploadImageVariables & { id: string }) =>
  templatesAPI.uploadPreviewImage(variables.id, variables.src, variables.width, variables.height)

export const useUploadTemplatePreviewImage = (id?: string | null) => {
  const templatePreviewImageUploadMutation = useMutation([QUERY_KEYS.TEMPLATE_PREVIEW_IMAGE, id], (variables: UploadImageVariables) =>
    uploadTemplatePreviewImageMutationFunction(Object.assign({}, variables, { id: id || '' }))
  )

  return templatePreviewImageUploadMutation.mutate
}

export default useUploadTemplatePreviewImage
