import ACTION_TYPES from '../../actionTypes'
import {
  SetDocumentsFolderFilterPayload,
  SetDocumentsFilterPayload,
  SetSelectedDocumentIdPayload,
  SetTemplatesFolderFilterPayload,
  SetTemplatesFilterPayload,
  SetSelectedTemplateIdPayload,
} from './helpers'

export type ResetAppStateAction = () => void
export type SetDocumentsFolderFilterAction = (payload: SetDocumentsFolderFilterPayload) => void
export type SetDocumentsFilterAction = (payload: SetDocumentsFilterPayload) => void
export type SetSelectedDocumentIdAction = (payload: SetSelectedDocumentIdPayload) => void
export type DeselectDocumentsAction = () => void

export type SetTemplatesFolderFilterAction = (payload: SetTemplatesFolderFilterPayload) => void
export type SetTemplatesFilterAction = (payload: SetTemplatesFilterPayload) => void
export type SetSelectedTemplateIdAction = (payload: SetSelectedTemplateIdPayload) => void
export type DeselectTemplatesAction = () => void

const actionCreators = {
  // =============================================================================== //
  // =================================== GENERAL =================================== //
  // =============================================================================== //
  logout: () => ({ type: ACTION_TYPES.APP.GENERAL.LOGOUT }),
  resetAppState: () => ({ type: ACTION_TYPES.APP.GENERAL.RESET }),
  // =============================================================================== //
  //
  //
  //
  // =============================================================================== //
  // ================================== DOCUMENTS ================================== //
  // =============================================================================== //
  setDocumentsFolderFilter: (payload: SetDocumentsFolderFilterPayload) => ({ type: ACTION_TYPES.APP.DOCUMENTS.FOLDER_FILTER_SET, payload }),
  setDocumentsFilter: (payload: SetDocumentsFilterPayload) => ({ type: ACTION_TYPES.APP.DOCUMENTS.FILTER_SET, payload }),
  setSelectedDocumentId: (payload: SetSelectedDocumentIdPayload) => ({ type: ACTION_TYPES.APP.DOCUMENTS.DOCUMENT_SELECTED, payload }),
  deselectDocuments: () => ({ type: ACTION_TYPES.APP.DOCUMENTS.DESELECTED }),
  // =============================================================================== //
  //
  //
  //
  // =============================================================================== //
  // ================================== TEMPLATES ================================== //
  // =============================================================================== //
  setTemplatesFolderFilter: (payload: SetTemplatesFolderFilterPayload) => ({ type: ACTION_TYPES.APP.TEMPLATES.FOLDER_FILTER_SET, payload }),
  setTemplatesFilter: (payload: SetTemplatesFilterPayload) => ({ type: ACTION_TYPES.APP.TEMPLATES.FILTER_SET, payload }),
  setSelectedTemplateId: (payload: SetSelectedTemplateIdPayload) => ({ type: ACTION_TYPES.APP.TEMPLATES.TEMPLATE_SELECTED, payload }),
  deselectTemplates: () => ({ type: ACTION_TYPES.APP.TEMPLATES.DESELECTED }),
  // =============================================================================== //
}

export { actionCreators }
export default actionCreators
