import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const OverflowWrapper = styled(Box)({
  display: 'flex',
  flex: 1,
  overflowY: 'auto',
})

export const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  position: 'relative',
})

export const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  postion: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "white"
}))
