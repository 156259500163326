import { IDocument } from 'types'
import { useQuery, UseQueryOptions } from 'react-query'
import DocumentsApi from 'api/docs.api'
import { queryKeys } from 'queries/queryKeys'
import { AxiosError } from 'axios'

export const useApprovalDocuments = (options: UseQueryOptions<IDocument[], AxiosError, IDocument[]> = {}) => {
  const keys = [queryKeys.DOCUMENTS, { approval: true }]
  const query = useQuery(keys, () => DocumentsApi.getApprovalDocuments(), {
    ...options,
    refetchOnWindowFocus: false,
  })

  return query
}

