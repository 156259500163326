import { useMutation, useQueryClient } from 'react-query'

import { useToast } from 'hooks/useToast'

import { queryKeys } from 'queries/queryKeys'
import DocsApi from 'api/docs.api'
import { IDocument } from 'types'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'
import { analytics } from 'utils'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useUser } from 'queries/user/useUser'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useTranslation } from 'react-i18next'

interface Data {
  pageParams: [string | undefined] | never[]
  pages: IDocument[][]
}

export const useDocumentsCreate = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { isTrial, isBasic, isTeam } = useSubscriptionCheck()
  const { currentFolder } = useCurrentFolder('docs')
  const { type } = currentFolder
  const user = useUser()
  const { t } = useTranslation()
  const docXMicro = !!user?.beta?.newDocxMicroservice
  const mutation = useMutation(
    queryKeys.DOCUMENT,
    (payload: any) => DocsApi.create({ ...payload, new: docXMicro }),
    {
      onMutate: async (newDocument: any) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          queryKeys.DOCUMENTS,
          { type, folderId: currentFolder.id },
        ])

        // Snapshot the previous value
        const previousDocs = queryClient.getQueryData<Data>([
          queryKeys.DOCUMENTS,
          { type, folderId: currentFolder.id },
        ])

        let tmpNewDocument = {
          ...newDocument,
          id: 'dummy',
          isLoading: true,
          name: `${newDocument.name}`,
        }

        // Optimistically update to the new value
        if (previousDocs) {
          queryClient.setQueryData<Data>(
            [queryKeys.DOCUMENTS, { type, folderId: currentFolder.id }],
            (data) => {
              const pages = data?.pages ?? []
              const pageOne = pages[0] ?? []
              pageOne.unshift(tmpNewDocument)
              pages[0] = pageOne
              return data ? { ...data, pages } : { pageParams: [], pages: [] }
            }
          )
        }

        // Return a context object with the snapshotted value
        return { previousDocs }
      },
      onSuccess: (docFile, variables) => {
        queryClient.cancelQueries([
          queryKeys.DOCUMENTS,
          { type, folderId: currentFolder.id },
        ])
        queryClient.setQueryData([queryKeys.DOCUMENT, docFile.id], docFile)
        queryClient.setQueryData(queryKeys.DOCUMENT, null)
        trackIntercomEvent('document-created', { id: docFile.id })

        const key = isTrial
          ? 'trial_document_created'
          : isTeam || isBasic
          ? 'subsc_document_created'
          : null

        if (key)
          analytics.logEvent(key, {
            id: docFile.id,
            templateId: docFile.templateId,
            templateName: docFile.templateName,
            subscriptionPlan: isBasic ? 'basic' : isTeam ? 'team' : undefined,
          })
        //TODO: Translate
        queryClient.invalidateQueries([
          queryKeys.DOCUMENTS,
          { type, folderId: currentFolder.id },
        ]).then(() => toast('success', t("toast_successfully_created_document"))
        )
      },
      onError: () => {
        toast('error', 'default')
      },
    }
  )
  return mutation
}
