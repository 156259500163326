import React, { FunctionComponent } from 'react'

import { SelectOptionProps, classes, useSelectContext } from '.'
import Button from '../Button'

export const Option: FunctionComponent<SelectOptionProps> = React.memo(({ id, label }) => {
  const { onSelect, active, unselectable } = useSelectContext()

  const isActive = active.includes(id) || undefined

  return (
    <Button
      id={id}
      className={classes.options.entry}
      onClick={onSelect}
      dataSet={{ active: isActive }}
      tertiary={isActive}
      disabled={isActive && !unselectable}
      onClickBlur
    >
      <span>{label}</span>
    </Button>
  )
})

export default Option
