import { useFetchTemplateList, useFetchTemplateFolderList, useCreateTemplateFolder, useFetchTrashedTemplateList } from '___queries'

export const useTemplateList = () => {
  const templateList = useFetchTemplateList()
  const trashedTemplates = useFetchTrashedTemplateList()
  const templateFolderList = useFetchTemplateFolderList()
  const { createTemplateFolder, creatingTemplateFolder } = useCreateTemplateFolder()
  return {
    list: templateList,
    trashed: trashedTemplates,
    folders: templateFolderList,
    createFolder: createTemplateFolder,
    creatingFolder: creatingTemplateFolder,
  }
}

export default useTemplateList
