import { useQuery, QueryFunctionContext, UseQueryResult } from 'react-query'

import { templatesAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

const getTemplatePreviewImageSrcURLQueryFunction = ({ queryKey }: QueryFunctionContext) =>
  templatesAPI.getTemplatePreviewImageSrcURL(queryKey[1] as string)

export const useFetchTemplatePreviewImageSrcURL = (id?: string | null) => {
  const templateQuery = useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_PREVIEW_IMAGE].concat(id ?? []),
    queryFn: getTemplatePreviewImageSrcURLQueryFunction,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return templateQuery as UseQueryResult<string | undefined>
}

export default useFetchTemplatePreviewImageSrcURL
