import { ITeamMember } from 'types'
import React, { Fragment } from 'react'
import TeamMemberComponent from './TeamMember/TeamMember'
import NoItemsAction from 'components/common/NoItemsAction/NoItemsAction'
import img from 'assets/illustrations/dokumente-vorlagen.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  teamMembers: ITeamMember[]
  onDelete: (teamMemberId: string) => void
  onAddTeamMember: () => void
}


const TemMemberList: React.FC<Props> = ({ teamMembers, onAddTeamMember, onDelete }) => {
  const { t } = useTranslation()

  const noTeamMembersProps = {
    text: t('no_team_members'),
    actionText: t('add_member'),
    image: img,
  }

  if (!teamMembers.length)
    return <NoItemsAction {...noTeamMembersProps} handleActionClick={onAddTeamMember} />
  return (
    <Fragment>
      {teamMembers.map(teamMember => (
        <TeamMemberComponent key={teamMember.id} {...teamMember} onDelete={onDelete} />
      ))}
    </Fragment>
  )
}

export default TemMemberList
