import React, { FunctionComponent, useEffect, useMemo } from 'react'

import { VIEWPORT_SIZE, SpinnerProps, className } from './index'

const ZOOM = 1

const STROKE_LENGTH = 442
const STROKE_FILL = 0.1
const ANIMATION_DURATION = 3000
const DASH_FILL = STROKE_LENGTH * STROKE_FILL
const DASH_GAP = STROKE_LENGTH * (1 - STROKE_FILL)
const FOLLOW_STROKE = 2

let mountCount = 0

const Spinner: FunctionComponent<SpinnerProps> = React.memo(({ animate = true, strokeWidth = 1 }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const id = `Spinner:${mountCount}`

  useEffect(() => {
    mountCount = mountCount + 1
  }, [])

  return (
    <svg className={`${className} Spinner`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <path
        d="M 70.5 35 A 21 21 0 0 0 50 19 A 25 31 0 0 0 50 81 A 21 21 0 0 0 71 61 h 19 A 40 37 0 0 1 50 97 A 45 47 0 0 1 43 96.5 L 5.5 57 A 45 47 0 0 1 50 3 A 40 37 0 0 1 89.5 35 Z"
        stroke="rgba(0, 22, 64, 1)"
        strokeWidth={strokeWidth}
        fill="rgba(30, 144, 255, 0.35)"
        strokeLinejoin="round"
        strokeLinecap="round"
      >
        {animate && ANIMATION_DURATION ? (
          <>
            <animate
              id={id}
              attributeName="stroke-dashoffset"
              values={`${DASH_FILL}; -${STROKE_LENGTH - DASH_FILL}`}
              dur={`${ANIMATION_DURATION}ms`}
              begin={`0s; ${id}.end`}
            />
            <animate
              id={`${id}dasharray`}
              attributeName="stroke-dasharray"
              values={`${DASH_FILL} ${STROKE_LENGTH}; ${DASH_FILL} ${DASH_GAP}`}
              dur={`${ANIMATION_DURATION * STROKE_FILL}ms`}
              fill="freeze"
              begin="0s"
            />
            <animate
              attributeName="stroke-dasharray"
              keyTimes={`0; ${1 - STROKE_FILL}; 1`}
              values={`${DASH_FILL - FOLLOW_STROKE} 0 ${FOLLOW_STROKE} ${DASH_GAP};  ${
                DASH_FILL - FOLLOW_STROKE
              } ${DASH_GAP} ${FOLLOW_STROKE} 0; ${DASH_FILL} ${DASH_GAP} 0 0`}
              dur={`${ANIMATION_DURATION * 4}ms`}
              begin={`${id}dasharray.end`}
              repeatCount="indefinite"
            />
          </>
        ) : null}
      </path>
    </svg>
  )
})

export { Spinner }
export default Spinner
