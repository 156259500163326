import { useMemo } from 'react'

import { useFetchTemplate } from '___queries'
import useUser from './useUser'

export const useTemplateOwned = (id?: string | null, publicFlow: boolean = false) => {
  const { data: template } = useFetchTemplate(id, publicFlow)
  const { data: user } = useUser(publicFlow)

  const owned = useMemo(() => Boolean(template && user && template.author?.id === user.id), [template, user])

  return owned
}

export default useTemplateOwned
