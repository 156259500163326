import { ISubscription } from '___types'

import API from './api'

class Subscriptions extends API {
  public constructor() {
    super('subs/') // update to subscriptions
  }

  public getSubscriptionList = async (): Promise<ISubscription[]> => {
    const res = await this.get('subscriptions/available')
    return res.data.data.subscriptions
  }

  public getSubscribeLink = async (
    id?: string,
    successUrl = `${window.location.origin}/${window.location.pathname}`,
    cancelUrl = window.location.origin
  ): Promise<undefined> => {
    if (!id) return
    const res = await this.post('subscriptions', { priceId: id, successUrl, cancelUrl, metadata: {} })
    return res.data.data.sessionURL
  }
}

export const subscriptionsAPI = new Subscriptions()

export default subscriptionsAPI
