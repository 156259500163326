import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import { ButtonOverlayProps, classes } from '.'

export const Overlay: FunctionComponent<ButtonOverlayProps> = React.memo(({ loading, borderRadius = 0 }) => {
  const [node, setNode]: [SVGRectElement | undefined, Dispatch<SetStateAction<SVGRectElement | undefined>>] = useState()
  //@ts-ignore
  const id = useMemo(() => `animate:radius:${uuid()}`.replaceAll('-', ''), [])
  const ref: RefCallback<SVGRectElement | undefined> = useCallback(node => node && setNode(node), [])

  // const [rx, ry] = useMemo(() => {
  //   if (!node) return [0, 0]
  //   const { width, height } = node.getBoundingClientRect()
  //   return [Number(((borderRadius * 100) / width).toFixed(2)), Number(((borderRadius * 100) / height).toFixed(2))]
  // }, [node, borderRadius])

  useEffect(() => {
    if (node) {
      const animateElements = node.querySelectorAll('animate')
      // @ts-ignore
      if (loading) animateElements[0]?.beginElement()
      // @ts-ignore
      else animateElements.forEach(element => element.endElement())
    }
  }, [node, loading])

  return (
    <svg className={classes.overlay} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <rect
        ref={ref}
        x="10"
        y="10"
        width="80%"
        height="80%"
        // rx={rx}
        // ry={ry}
        strokeWidth="2px"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
      >
        <animate
          id={id}
          attributeName="stroke-dasharray"
          calcMode="spline"
          keyTimes="0; 0.75; 1"
          keySplines="0 0.5 0 1; 0 0.5 0 1"
          values="0 100; 100 0; 100 0"
          dur="3000ms"
          repeatCount="indefinite"
          begin=""
        />
        <animate
          id={id}
          attributeName="opacity"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0.5 0 0.5 1"
          values="1; 0"
          dur="3000ms"
          repeatCount="indefinite"
          begin={`${id}.begin`}
        />
        <animate
          id={id}
          attributeName="stroke-dashoffset"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0 0.25 0.75 1"
          values="0; -300"
          dur="3000ms"
          repeatCount="indefinite"
          begin={`${id}.begin`}
        />
      </rect>
    </svg>
  )
})

Overlay.displayName = 'Casus-Components-Button-Overlay'

export default Overlay
