import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { BREAK_TAG_MATCH, DOCUMENT_STATUSES, SignatureSecurityLevel, Signee } from '___types'
import { useDocument } from '___hooks'
import { Signature as SignatureIcon } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import useModal from 'Modal'
import ModalContent from './Signature.ModalContent'
import { SignatureProps, classes } from '.'

export const Signature: FunctionComponent<SignatureProps> = React.memo(({ id, splitId, publicFlow }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'components.signature' })
  const { data, requestRegularSignature, requestPDFSignature, requestingSignature } = useDocument(id, publicFlow)

  const { signatureSecurityLevel, isPublic, publicSettings } = data || {}
  const notifyEmailSignee = { email: publicSettings?.notifyEmail } as Signee
  const publicMailList = (publicSettings?.signatureConfig?.signees ?? []).concat(
    publicSettings?.includeEmail && publicSettings.notifyEmail ? notifyEmailSignee ?? [] : []
  )
  const open = useModal({
    header: translate('signature-request') as string,
    close: false,
    content: (...callbacks) => (
      <ModalContent
        level={signatureSecurityLevel ?? isPublic ? publicSettings?.signatureConfig?.security : undefined}
        mailList={isPublic ? publicMailList : []}
        conclude={callbacks[2]}
      />
    ),
    onConclude: (_, ...params: unknown[]) => {
      const [security, signees, messageInput] = params as [SignatureSecurityLevel, Signee[], HTMLPreElement]
      const textarea = document.createElement('textarea')
      //@ts-ignore
      textarea.innerHTML = messageInput.innerHTML.replaceAll(BREAK_TAG_MATCH, '\n')
      const message = textarea.value
      textarea.remove()
      if (data?.type === 'regular') return requestRegularSignature(signees, signatureSecurityLevel ?? security, message, splitId)
      if (data?.type === 'pdf') return requestPDFSignature(signees, signatureSecurityLevel ?? security, message)
    },
    footer: false,
  })

  return (
    <Button
      className={classes.wrapper}
      onClick={() => open()}
      disabled={data?.status === DOCUMENT_STATUSES.LOCKED || requestingSignature}
      loading={requestingSignature}
    >
      <SignatureIcon />
      {translate('request-signatures')}
    </Button>
  )
})

Signature.displayName = 'Casus-Components-Signature'

export default Signature
