import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, CompanyProps, className } from './index'

const ZOOM = 1

const Company: FunctionComponent<CompanyProps> = React.memo(() => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const maskId = useMemo(uuid, [])

  return (
    <svg className={`${className} Company`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <g>
            <ellipse cx="65" cy="46" rx="10.5" ry="12" stroke="black" strokeWidth="15" fill="black" />
            <path d={'M 50 83 c -15 0 -7.5 -22.5 15 -22.5 c 22.5 0 30 22.5 15 22.5 Z'} stroke="black" strokeWidth="15" fill="black" />
          </g>{' '}
        </mask>
      </defs>
      {/* <rect
        x="15"
        y="15"
        width="45"
        height="70"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        fill="none"
        rx="5"
        mask={`url(#${maskId}`}
      /> */}
      <path
        d="M 15 85 v -60 c 0 -10 0 -10 10 -10 h 25 c 10 0 10 0 10 10 v 60 Z M 25 30 h 8 m 9 0 h 8 m 0 12 h -8 m -9 0 h -8 m 0 12 h 8 m 9 0  h 8 m 0 12 h -8 m -9 0 h -8"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        fill="none"
        mask={`url(#${maskId}`}
      />
      {/* <line x1="25" x2="50" y1="25" y2="25" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
      <line x1="25" x2="50" y1="40" y2="40" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
      <line x1="25" x2="50" y1="55" y2="55" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
      <line x1="25" x2="50" y1="70" y2="70" stroke="currentColor" strokeWidth="5" strokeLinecap="round" /> */}
      <g>
        <ellipse cx="65" cy="46" rx="10.5" ry="12" stroke="none" fill="currentColor" />
        <path d={'M 50 83 c -15 0 -7.5 -22.5 15 -22.5 c 22.5 0 30 22.5 15 22.5 Z'} stroke="none" fill="currentColor" />
      </g>
    </svg>
  )
})

export { Company }
export default Company
