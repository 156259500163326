import React from 'react'
import useStore from '___store'

import { Cross } from 'assets/svgIconComponents'
import { Button, ButtonGroup } from 'components'
import { QuestionOptionSelect, QuestionSelect } from 'Wizard/components/Configuration/common'
import { classes } from './index'

const ShowHideRule = React.memo(({ id, questionId }) => {
  const {
    questionRuleById: {
      questionId: qid,
      optionId: oid,
      // questionType: type,
      // condition: { needed, comparisonOperator, referenceValue } = {},
    } = {},
    updateConditionalRule,
    removeConditionalRule,
  } = useStore(`selectQuestionRuleById[${questionId},${id}]`, 'updateConditionalRule', 'removeConditionalRule')

  return (
    <div className={classes.showHideRuleWrapper}>
      <div className={classes.showHideRuleRow}>
        <ButtonGroup className={classes.showHideRuleButtonGroup}>
          <QuestionSelect
            className={classes.showHideRuleSelect}
            filter={q => q.id !== questionId}
            value={qid}
            onChange={questionId => updateConditionalRule({ id, questionId, optionId: undefined })}
          />
          {qid ? (
            <QuestionOptionSelect
              key={`ShowHideFragment-rule-${id}-question-${qid}-options`}
              className={classes.showHideRuleSelect}
              questionId={qid}
              value={oid}
              onChange={optionId => updateConditionalRule({ id, optionId })}
            />
          ) : null}
          <Button className={classes.showHideRuleButton} onClick={() => removeConditionalRule({ questionId, id })} colorScheme="transparent">
            <Cross />
          </Button>
        </ButtonGroup>
      </div>
      <div className={classes.showHideRuleRow}></div>
    </div>
  )
})

ShowHideRule.displayName = 'Wizard-Configuration-AdvancedQuestionConfiguration-Rule'

export default ShowHideRule
