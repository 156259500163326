import { PropsWithChildren, ReactChild } from 'react'

import Grid from './Grid'

import './style.scss'

const classes = {
  wrapper: 'Casus-Components-Grid-wrapper',
  title: 'Casus-Components-Grid-title',
  error: 'Casus-Components-Grid-error',
} as const

export type GridProps = PropsWithChildren<{ className?: string; isLoading?: boolean; isFetching?: boolean; error?: string; title?: ReactChild }>

export { Grid, classes }
export default Grid
