import React, { FunctionComponent, PropsWithChildren, useContext } from 'react'
import {
  WizardAnswersSelector,
  WizardDataStructureSelector,
  WizardLocationsSelector,
  WizardModeSelector,
  WizardNumberingSelector,
  WizardQuestionsSelector,
  WizardIntegrationsSelector,
  WizardActiveSplitSelector,
  WizardState,
} from '___store'

import Interact from './Interact'
import Preview from './Preview'

import './style.scss'
import {
  CASUS_IDS,
  CUSTOM_TEXT_SPLIT,
  DataStructure,
  EditorMode,
  LANGUAGES,
  LanguageValue,
  Languages,
  Locations,
  NESTED_STRUCTURE_KEYS,
  OptionValueTypeUnionType,
  SegmentsLocation,
  SegmentsLocations,
  TextChunkObject,
  TextChunks,
  TextLocation,
  TextLocations,
  extractPropertiesFromCustomText,
} from '___types'
import { evaluateMarkers, getFormattedAnswerValue, initializeAnswerRelevance } from '___store/storeSegments/wizard/typified/helpers'
import { generateParagraph, generateTextChunk } from '___store/storeSegments/wizard/helpers/editor-content'
// import { mergeChunks } from 'Wizard/parsing'

// ======================================================================================================= //
// ============================================ TABLE CONTEXT ============================================ //
// ======================================================================================================= //
type TableContextType = { id: string }
export const TableContext = React.createContext<TableContextType>({} as TableContextType)
type TableContextProviderProps = PropsWithChildren<TableContextType>
export const TableContextProvider: FunctionComponent<TableContextProviderProps> = ({ id, children }) => {
  return <TableContext.Provider value={{ id }}>{children}</TableContext.Provider>
}
export const useTableContext = () => {
  const currentTableContext = useContext(TableContext)
  if (!currentTableContext) {
    throw new Error('useTableContext has to be used within <TableContext.Provider>')
  }
  return currentTableContext
}
// ======================================================================================================= //
//
//
//
// ======================================================================================================= //
// =========================================== SECTION CONTEXT =========================================== //
// ======================================================================================================= //
type SectionContextType = { index: number }
export const SectionContext = React.createContext<SectionContextType>({} as SectionContextType)
type SectionContextProviderProps = PropsWithChildren<SectionContextType>
export const SectionContextProvider: FunctionComponent<SectionContextProviderProps> = ({ index, children }) => {
  return <SectionContext.Provider value={{ index }}>{children}</SectionContext.Provider>
}
export const useSectionContext = () => {
  const currentSectionContext = useContext(SectionContext)
  if (!currentSectionContext) {
    throw new Error('useSectionContext has to be used within <SectionContext.Provider>')
  }
  return currentSectionContext
}
// ======================================================================================================= //
//
//
//
// ======================================================================================================= //
// ======================================= EDITOR INTERACT CONTEXT ======================================= //
// ======================================================================================================= //
type EditorContextType = {
  mode: EditorMode
  dataStructure?: WizardDataStructureSelector
  numbering?: WizardNumberingSelector
  locations?: WizardLocationsSelector
}
export const EditorContext = React.createContext<EditorContextType>({} as EditorContextType)
type EditorContextProviderProps = PropsWithChildren<EditorContextType>
export const EditorContextProvider: FunctionComponent<EditorContextProviderProps> = ({ mode, dataStructure, numbering, locations, children }) => {
  return <EditorContext.Provider value={{ mode, dataStructure, numbering, locations }}>{children}</EditorContext.Provider>
}
export const useEditorContext = () => {
  const currentEditorContext = useContext(EditorContext)
  if (!currentEditorContext) {
    throw new Error('useEditorContext has to be used within <EditorContext.Provider>')
  }
  return currentEditorContext
}
// ======================================================================================================= //
//
//
//
// ======================================================================================================== //
// ======================================== EDITOR PREVIEW CONTEXT ======================================== //
// ======================================================================================================== //
type EditorPreviewContextType = {
  mode: EditorMode
  dataStructure?: WizardDataStructureSelector
  numbering?: WizardNumberingSelector
}
export const EditorPreviewContext = React.createContext<EditorPreviewContextType>({} as EditorPreviewContextType)
type EditorPreviewContextProviderProps = PropsWithChildren<EditorPreviewContextType>
export const EditorPreviewContextProvider: FunctionComponent<EditorPreviewContextProviderProps> = ({ mode, dataStructure, numbering, children }) => {
  return <EditorPreviewContext.Provider value={{ mode, dataStructure, numbering }}>{children}</EditorPreviewContext.Provider>
}
export const useEditorPreviewContext = () => {
  const currentEditorPreviewContext = useContext(EditorPreviewContext)
  if (!currentEditorPreviewContext) {
    throw new Error('useEditorPreviewContext has to be used within <EditorPreviewContext.Provider>')
  }
  return currentEditorPreviewContext
}
// ======================================================================================================== //

export { Interact, Preview }

const findIndices = <T extends 'text' | 'content'>(
  array: { [K in T]: unknown[] | string }[],
  key: T,
  range: [number, number],
  buffer = 0,
  startIndex = -1,
  endIndex = -1
): [number, number] | undefined =>
  array.find((entry, i) => {
    const start = buffer
    const end = buffer + entry[key].length
    if (range[0] >= start && range[0] <= end) startIndex = i
    return (buffer = end) && range[1] >= start && range[1] <= end && ((endIndex = i) || true)
  }) && [startIndex, endIndex]

type StructureObject = Record<string, unknown> & { id?: string; customStyle?: string; styles?: string[]; textChunks?: TextChunks }

const applyTextMarkers = (structure: StructureObject, textMarkers: TextLocation[]) => {
  const { textChunks } = structure
  if (!textMarkers.length || !textChunks?.length) return structure
  const structureText = textChunks.map(({ text }) => text).join('')
  return Object.assign({}, structure, {
    textChunks:
      // mergeChunks(
      textMarkers
        .reduce((result, marker) => {
          if (marker.keep && !(marker.replace || marker.replace === '')) return result
          const { range } = marker
          const indices = findIndices(result, 'text', range)
          if (!indices) return result
          const [startIndex, endIndex] = indices
          const startBuffer = result.slice(0, startIndex).reduce((acc, { text }) => acc + text.length, 0)
          const endBuffer = result.slice(0, endIndex).reduce((acc, { text }) => acc + text.length, 0)
          const leading = Object.assign({}, result[startIndex], { text: result[startIndex].text.slice(0, range[0] - startBuffer) })
          const trailing = Object.assign({}, result[endIndex], { text: result[endIndex].text.slice(range[1] - endBuffer) })
          // ADD CHUNK STYLE COMBINATION //
          const formattedReplace = marker.replace
            ?.split(CUSTOM_TEXT_SPLIT)
            .map(replaceString => {
              const properties = extractPropertiesFromCustomText(replaceString, 'markerReplace')
              const replacementText = properties.type
                ? getFormattedAnswerValue(properties.type as OptionValueTypeUnionType, properties.value)
                : replaceString
              return replacementText ?? null
            })
            .join(marker.concatString || ' - ')
          const insert = Object.assign({}, result[startIndex], { text: structureText.slice(...range), replace: marker.keep ? formattedReplace : '' })
          const resultingChunks = result.slice()
          resultingChunks.splice(startIndex, endIndex - startIndex + 1, leading, insert, trailing)
          return resultingChunks
        }, textChunks as (TextChunkObject & { replace?: string })[])
        .map(chunk => Object.assign({}, chunk, { text: chunk.replace ?? chunk.text })),
    // .filter(({ text }) => text.length),
    // )
  })
}

type ResultArrayEntry = { content: StructureObject[]; replace?: StructureObject[] }
const applySegmentsMarkers = (structure: StructureObject, segmentsMarkers: SegmentsLocation[]) => {
  const relevantContentKey = 'segments' in structure ? 'segments' : 'content'
  const structureContent = structure[relevantContentKey] as StructureObject[]
  if (!segmentsMarkers.length || !structureContent?.length) return structure
  return Object.assign({}, structure, {
    [relevantContentKey]: segmentsMarkers
      .reduce(
        (result, { keep, replace, range, contentCustomStyle, contentStyles }) => {
          if (keep && !(replace || replace === '')) return result
          const indices = findIndices(result, 'content', range)
          if (!indices) return result
          const [startIndex, endIndex] = indices
          const startBuffer = result.slice(0, startIndex).reduce((acc, { content }) => acc + content.length, 0)
          const endBuffer = result.slice(0, endIndex).reduce((acc, { content }) => acc + content.length, 0)
          const leading = { content: result[startIndex].content.slice(0, range[0] - startBuffer) }
          const trailing = { content: result[endIndex].content.slice(range[1] - endBuffer) }
          const insert = {
            content: structureContent.slice(...range),
            replace: keep
              ? replace?.split(CUSTOM_TEXT_SPLIT).map(replaceString => {
                  const properties = extractPropertiesFromCustomText(replaceString, 'markerReplace')
                  const replacementText = properties.type
                    ? getFormattedAnswerValue(properties.type as OptionValueTypeUnionType, properties.value)
                    : replaceString
                  const replaceParagraph = {
                    textChunks: [generateTextChunk({ text: String(replacementText), styles: contentStyles, customStyle: contentCustomStyle })],
                    styles: contentStyles,
                    customStyle: contentCustomStyle,
                  }
                  return generateParagraph(replaceParagraph)
                }) || []
              : [],
          }
          const resultingSegments = result.slice()
          resultingSegments.splice(startIndex, endIndex - startIndex + 1, leading, insert, trailing)
          return resultingSegments
        },
        [{ content: structureContent }] as ResultArrayEntry[]
      )
      .reduce((result, { content, replace }) => result.concat(replace || content), [] as StructureObject[]),
  })
}

const getNestedMarkers = <T extends SegmentsLocation | TextLocation>(
  locations: T extends SegmentsLocation ? SegmentsLocations : TextLocations,
  markerArray?: T[]
): T[] | undefined =>
  markerArray?.reduce(
    (acc, { id, keep, replace }) =>
      !keep || replace || replace === '' || !locations[id] ? acc : acc.concat(getNestedMarkers(locations, locations[id] as T[])!),
    markerArray.slice()
  )

const applyAnswerValuesToDataStructure = (structure: StructureObject, locations: Locations): [StructureObject, boolean] => {
  const id = structure.id === CASUS_IDS.DATASTRUCTURE_ID ? 'root' : structure.id
  const textMarkers = (id && getNestedMarkers(locations.text, locations.text[id])) || []
  const segmentsMarkers = (id && getNestedMarkers(locations.segments, locations.segments[id])) || []
  const markerAppliedStructure = applyTextMarkers(applySegmentsMarkers(Object.assign({}, structure), segmentsMarkers), textMarkers)
  let changed = false
  const result = NESTED_STRUCTURE_KEYS.reduce(
    (accumulated, key) =>
      Array.isArray(accumulated[key]) && (accumulated[key] as StructureObject[]).length
        ? Object.assign(accumulated, {
            [key]: (accumulated[key] as StructureObject[]).map(structure => {
              const [nestedStructure, nestedChanged] = applyAnswerValuesToDataStructure(structure, locations)
              changed = changed || nestedChanged
              return nestedStructure
            }),
          })
        : accumulated,
    markerAppliedStructure
  )
  return [result, Boolean(textMarkers.length || segmentsMarkers.length) || changed]
}

export const rasterizeDataStructure = (
  mode: WizardModeSelector,
  dataStructure: DataStructure,
  locations: WizardLocationsSelector,
  questions: WizardQuestionsSelector,
  selectedLanguages?: LanguageValue[],
  integrations?: WizardIntegrationsSelector,
  activeSplit?: WizardActiveSplitSelector,
  answers?: WizardAnswersSelector
): [DataStructure, StructureObject[], StructureObject[]] => {
  const languages = { available: Object.values(LANGUAGES), select: 'multi', selected: selectedLanguages } as Languages
  const pseudoState = Object.assign({}, { mode, dataStructure, locations, questions, languages, integrations, activeSplit, answers }) as WizardState
  if (!locations) return [dataStructure, [], []]
  const evaluatedMarkers = evaluateMarkers(initializeAnswerRelevance(pseudoState), false).locations
  const answeredDataStructure = applyAnswerValuesToDataStructure(dataStructure, evaluatedMarkers!)[0] as DataStructure
  const changedHeaders = dataStructure.headers.reduce((headers, header) => {
    const [result, changed] = applyAnswerValuesToDataStructure(header, evaluatedMarkers!)
    return headers.concat(changed ? result : [])
  }, [] as StructureObject[])
  const changedFooters = dataStructure.footers.reduce((footers, footer) => {
    const [result, changed] = applyAnswerValuesToDataStructure(footer, evaluatedMarkers!)
    return footers.concat(changed ? result : [])
  }, [] as StructureObject[])
  return [answeredDataStructure, changedHeaders, changedFooters]
}
