import React, { FunctionComponent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, { WizardShowExternalQuestionsSelector, WizardQuestionOrderSelector, ToggleShowExternalQuestionsAction } from '___store'

import { Caret, Eye, Overview } from 'assets/svgIconComponents'
import { Button, Switch } from 'components/CasusComponents'
import ListQuestion from './QuestionOverview.ListQuestion'
import { wizardLayoutLeftPaneDocumentQuestionnaireQuestionOverviewClasses as classes, useWizardLayoutContext } from 'Layouts/WizardLayout'

type UseStoreHookResultType = {
  wizardShowExternalQuestions: WizardShowExternalQuestionsSelector
  wizardQuestionOrder: WizardQuestionOrderSelector
  toggleShowExternalQuestions: ToggleShowExternalQuestionsAction
}

export const QuestionOverview: FunctionComponent = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.questionnaire' })
  const [collapsed, setCollapsed] = useState(true)

  const { wizardShowExternalQuestions, wizardQuestionOrder, toggleShowExternalQuestions } = useStore(
    'selectWizardShowExternalQuestions',
    'selectWizardQuestionOrder',
    'toggleShowExternalQuestions'
  ) as UseStoreHookResultType

  const publicFlow = useWizardLayoutContext()

  const render = useMemo(
    () =>
      wizardQuestionOrder?.map(string => (
        <ListQuestion
          key={`WizardLayout-LeftPane-Document-Questionnaire-QuestionOverview-ListQuestion-${string}`}
          id={string}
          collapseCallback={() => setCollapsed(true)}
        />
      )),
    [wizardQuestionOrder]
  )

  return (
    <div className={classes.wrapper} data-collapsed={collapsed || undefined}>
      <div className={classes.expandButton}>
        <Button onClick={() => setCollapsed(prev => !prev)} onClickBlur>
          {collapsed ? <Overview /> : <Caret />}
          {collapsed ? <span>{translate('all-questions')}</span> : null}
        </Button>
      </div>
      <span>
        Question Overview
        {!publicFlow ? (
          <span>
            show external?
            <Switch
              value={wizardShowExternalQuestions ?? false}
              onClick={toggleShowExternalQuestions}
              pin={<Eye active={wizardShowExternalQuestions ?? false} />}
              emphasized
            />
          </span>
        ) : null}
      </span>
      <div className={classes.questionListDiv}>{render}</div>
    </div>
  )
})

QuestionOverview.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-QuestionOverview'

export default QuestionOverview
