import {
  FolderStructureArrowComponentProps,
  SidebarFolderComponentProps,
  SidebarFolderStructureComponentProps,
} from './FolderStructure.types'
import React, { useState } from 'react'
import { Icon } from 'components/common'
import {
  Container,
  ArrowDropDownIcon,
  ArrowRightIcon,
  FolderItemContainer,
  FolderContentWrapper,
  SubfoldersContainer,
  TopFolderText,
  ChildFolderText,
  Collapse,
} from './FolderStructure.styles'
import { primary } from 'config/themes/colors'
import { useHistory } from 'react-router'
import RemindersNavItem from './RemindersItem/Reminders'
import { documentsRoutes } from 'constants/routes'
import { useTranslation } from 'react-i18next'

const Arrows: React.FC<FolderStructureArrowComponentProps> = ({
  open,
  type,
  baseUrl,
  isTrashFolder,
}) => {
  if (baseUrl && isTrashFolder)
    return (
      <Icon
        iconName='trash'
        fill={primary.light}
        style={{ marginRight: 16, marginLeft: 5 }}
      />
    )
  if (open) {
    return <ArrowDropDownIcon type={type} style={{ marginRight: 8 }} />
  } else {
    return <ArrowRightIcon type={type} style={{ marginRight: 8 }} />
  }
}

export const Folder: React.FC<SidebarFolderComponentProps> = ({
  folder,
  currentFolder,
  type,
  baseUrl,
  withReminders,
  handleFolderClick,
}) => {
  const { location, push } = useHistory()
  const [open, setOpen] = useState(currentFolder.path.some(f => f.id === folder.id))
  const isTopLevelFolder = folder.id === folder.type
  const isTrashFolder = folder.id === 'trashed' && folder.id === folder.type
  const TextElement = isTopLevelFolder ? TopFolderText : ChildFolderText
  const { t } = useTranslation()

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    handleFolderClick(folder)
    setOpen(prev => !prev)
    if (baseUrl && withReminders && baseUrl !== location.pathname) push(baseUrl)
  }

  const isActiveWihtReminders = withReminders ? location.pathname === baseUrl : true

  const rootActive = isActiveWihtReminders && currentFolder.type === folder.id
  const active = isActiveWihtReminders && currentFolder.id === folder.id
  const trashFolderMargin = withReminders ? 24 : 12

  return (
    <FolderItemContainer
      style={{
        marginBottom: isTopLevelFolder ? 12 : 0,
        marginTop: isTrashFolder ? trashFolderMargin : 0,
      }}
    >
      {withReminders && baseUrl && isTrashFolder && (
        <RemindersNavItem active={location.pathname === documentsRoutes.reminders} />
      )}
      <FolderContentWrapper onClick={handleClick}>
        <Arrows open={open} type={type} baseUrl={baseUrl} isTrashFolder={isTrashFolder} />
        <TextElement active={active || rootActive ? 1 : 0} type={type}>
          {t(folder.name)}
        </TextElement>
      </FolderContentWrapper>
      {folder.type !== 'trashed' && (
        <Collapse in={open && !!folder.children.length}>
          <SubfoldersContainer>
            {folder.children.map(subFolder => (
              <Folder
                key={subFolder.id}
                folder={subFolder}
                currentFolder={currentFolder}
                type={type}
                baseUrl={baseUrl}
                handleFolderClick={handleFolderClick}
              />
            ))}
          </SubfoldersContainer>
        </Collapse>
      )}
    </FolderItemContainer>
  )
}

export const FolderStructure: React.FC<SidebarFolderStructureComponentProps> = ({
  rootFolder,
  type = 'white',
  currentFolder,
  baseUrl,
  withReminders,
  handleFolderClick,
}) => {
  return (
    <Container>
      {rootFolder.children.map(folderItem =>
        !baseUrl && folderItem.type === 'trashed' ? null : (
          <Folder
            key={folderItem.id}
            folder={folderItem}
            currentFolder={{
              id: currentFolder.id,
              type: currentFolder.type,
              path: currentFolder.path,
            }}
            type={type}
            baseUrl={baseUrl}
            withReminders={withReminders}
            handleFolderClick={handleFolderClick}
          />
        )
      )}
    </Container>
  )
}

export default FolderStructure
