import { v4 as uuid } from 'uuid'

import {
  CASUS_IDS,
  CASUS_KEYSTRINGS,
  CASUS_REGEXES,
  NESTED_STRUCTURE_KEYS,
  ORIENTATION,
  PAPER_NAMES,
  SEGMENT_TAGS,
  SEGMENT_TYPES,
} from 'Wizard/constants'
import { deepAssign, filterObjectFields } from './index'

const defaultMargins = { top: 1.25, left: 1, bottom: 0.75, right: 1 }
export const defaultLayout = { orientation: ORIENTATION.vertical, paper: PAPER_NAMES.A4, margins: defaultMargins }

const genericSection = { id: 'generic-section-id', title: '', layout: defaultLayout, pages: [] }
const generateSection = section => deepAssign({}, genericSection, section)
const generatePageRange = (start = 0, end) => [start, end || -Infinity]
const genericParagraph = {
  type: SEGMENT_TYPES.paragraph,
  tag: SEGMENT_TAGS[SEGMENT_TYPES.paragraph],
  customStyle: 'Normal',
  styles: [],
  textChunks: [],
}
export const generateParagraph = paragraph => Object.assign({}, genericParagraph, { id: uuid() }, paragraph)
const genericTextChunk = { type: SEGMENT_TYPES.textChunk, tag: SEGMENT_TAGS[SEGMENT_TYPES.textChunk], customStyle: '', styles: [], text: '' }
export const generateTextChunk = chunk => Object.assign({}, genericTextChunk, chunk)

const findWithinStructure = (structure = {}, test, result = undefined, parent = undefined, key = undefined, index = -1) =>
  ((test(structure) && (result = structure)) ||
    NESTED_STRUCTURE_KEYS.find(
      structureKey =>
        Array.isArray(structure && structure[structureKey]) &&
        structure[structureKey].find((segment, i) => {
          const [r, p, k, j] = findWithinStructure(segment, test)
          return r && (result = r) && (parent = p || structure) && (key = k || structureKey) && ((index = j !== -1 ? j : i) || true)
        })
    ) ||
    true) && [result, parent, key, index]

const getSegment = (state, test) => findWithinStructure(state.dataStructure, test)
export const foundSegmentReferences = {}
export const getSegmentById = (state, id) => {
  if (foundSegmentReferences[id]) return foundSegmentReferences[id]
  const res = getSegment(state, s => s?.id === id)
  return (res[0] && (foundSegmentReferences[id] = res)) || res
}

const extractSections = (state, pageBuffer = [], lastEnd = 0) =>
  !state.dataStructure?.segments?.length
    ? state
    : Object.assign({}, state, {
        sections: state.dataStructure.segments.reduce(
          (result, { break: segmentBreak }, index) =>
            (((index === state.dataStructure.segments.length - 1 || segmentBreak) &&
              pageBuffer.push(generatePageRange(lastEnd, index + 1)) &&
              (lastEnd = index + 1)) ||
              true) &&
            (((index === state.dataStructure.segments.length - 1 || segmentBreak?.type === 'section') &&
              result.push(generateSection(filterObjectFields(Object.assign({}, segmentBreak, { pages: pageBuffer.slice() }), 'type'))) &&
              (pageBuffer.length = 0)) ||
              true) &&
            result,
          []
        ),
      })

const removeParagraphMarkers = (state, id) => {
  const previousLength = Object.keys(state.locations.text).length
  const markerArray = state.locations.text[id]
  markerArray.forEach(({ id: markerId }) => delete state.locations.text[markerId])
  delete state.locations.text[id]
  if (Object.keys(state.locations.text).length === previousLength) return state
  return Object.assign({}, state)
}

const updateParagraphTextChunk = (state, payload) => {
  if (!(payload.id && typeof payload.index === 'number' && !isNaN(payload.index) && payload.chunk)) return state
  const [segment, parent, key, i] = getSegmentById(state, payload.id)
  if (!(segment && segment.type === 'paragraph' && segment.textChunks?.length)) return state
  const resultingChunks = segment.textChunks.slice()
  resultingChunks.splice(payload.index, 1, payload.chunk)
  const resultingParagraph = Object.assign({}, segment, { textChunks: resultingChunks })
  parent[key].splice(i, 1, resultingParagraph)
  if (foundSegmentReferences[resultingParagraph.id]) foundSegmentReferences[resultingParagraph.id][0] = resultingParagraph
  return Object.assign({}, state)
}

const replaceParagraphContent = (state, payload = {}) => {
  const [segment, parent, key, index] = getSegmentById(state, payload.id)
  return index === -1
    ? state
    : delete foundSegmentReferences[payload.id] &&
        parent[key].splice(index, 1, Object.assign({}, segment, { textChunks: [generateTextChunk({ text: payload.text })] })) &&
        Object.assign({}, state)
}

const addNewParagraphStyles = (state, payload = {}) => {
  const [segment, parent, key, index] = getSegmentById(state, payload.id)
  if (index === -1) return state
  const resultingStyles = Array.from(new Set((segment.styles || []).concat(payload.styles || [])))
  return resultingStyles?.length === segment.styles?.length
    ? state
    : delete foundSegmentReferences[payload.id] &&
        parent[key].splice(index, 1, Object.assign({}, segment, { styles: resultingStyles })) &&
        Object.assign({}, state)
}

const removeParagraphStyles = (state, payload = {}) => {
  const [segment, parent, key, index] = getSegmentById(state, payload.id)
  const filteredStyles = segment?.styles?.filter(s => !payload.styles?.includes(s))
  return index === -1 || filteredStyles?.length === segment.styles?.length
    ? state
    : delete foundSegmentReferences[payload.id] &&
        parent[key].splice(index, 1, Object.assign({}, segment, { styles: filteredStyles })) &&
        Object.assign({}, state)
}

const toggleParagraphStyle = (state, payload = {}) => {
  const [segment, parent, key, index] = getSegmentById(state, payload.id)
  if (index === -1) return state
  const filteredStyles = segment.styles?.filter(s => s !== payload.style)
  const resultingStyles = filteredStyles?.length !== segment.styles?.length ? filteredStyles : (segment.styles || []).concat(payload.style)
  return (
    delete foundSegmentReferences[payload.id] &&
    parent[key].splice(index, 1, Object.assign({}, segment, { styles: resultingStyles })) &&
    Object.assign({}, state)
  )
}

const applyParagraphCustomStyle = (state, payload = {}) => {
  const [segment, parent, key, index] = getSegmentById(state, payload.id)
  return index === -1
    ? state
    : delete foundSegmentReferences[payload.id] &&
        parent[key].splice(index, 1, Object.assign({}, segment, { customStyle: segment.customStyle === payload.style ? '' : payload.style })) &&
        Object.assign({}, state)
}

const updateRangesWithCallback = (state, array, callback, rangeKey, update = false) =>
  !(array && typeof callback === 'function' && typeof rangeKey === 'string')
    ? (update && Object.assign({}, state)) || state
    : array.forEach(({ [rangeKey]: relevantField }) => Array.isArray(relevantField) && (update = true) && relevantField.forEach(callback)) ||
      (update && Object.assign({}, state)) ||
      state

const adjustPageRange = (state, index, p, update = false) =>
  updateRangesWithCallback(state, state.sections, pageRange => pageRange.forEach((limit, i) => limit > index && (pageRange[i] += p)), 'pages', update)

const adjustMarkerRange = (state, id, index, p, update = false) =>
  updateRangesWithCallback(state, state.locations?.choice[id], (limit, i, range) => limit > index && (range[i] += p), 'range', update)

const insertIntoMarkers = (state, id, index, update = false) =>
  state.locations?.choice[id]?.reduce((acc, { id }) => insertIntoMarkers(acc, id, index, update), adjustMarkerRange(state, id, index, 1, update)) ||
  state

const removeFromMarkers = (state, id, index, update = false) =>
  state.locations?.choice[id]?.reduce((acc, { id }) => removeFromMarkers(acc, id, index, update), adjustMarkerRange(state, id, index, -1, update)) ||
  state

const insertParagraph = (state, id, newId, direction) => {
  const [parent, key, index] = getSegmentById(state, id).slice(1)
  return index !== -1 && parent[key].splice(index + Number(direction === 'below'), 0, generateParagraph({ id: newId }))
    ? insertIntoMarkers(...(key === 'segments' ? [adjustPageRange(state, index, 1, true), 'root'] : [Object.assign({}, state), parent.id]), index)
    : state
}

const insertParagraphAbove = (state, payload = {}) => insertParagraph(state, payload.id, payload.newParagaphId, 'above')

const insertParagraphBelow = (state, payload = {}) => insertParagraph(state, payload.id, payload.newParagaphId, 'below')

const removeParagraph = (state, payload = {}) => {
  const [parent, key, index] = getSegmentById(state, payload.id).slice(1)
  return index !== -1 && delete foundSegmentReferences[payload.id] && parent[key].splice(index, 1)
    ? removeFromMarkers(...(key === 'segments' ? [adjustPageRange(state, index, -1, true), 'root'] : [Object.assign({}, state), parent.id]), index)
    : state
}

const numberingStyleMatchRegex = new RegExp(`${CASUS_KEYSTRINGS.numbering}-(?<systemKey>[0-9]+)-(?<depthLevel>[0-9]+)$`, 'g')

const setNumberingDepthLevel = (levels = {}, key, depth = 0, value = 0) => {
  const arrayLength = Math.max(levels[key]?.length - depth - 1, 0)
  return Object.assign(levels, {
    [key]: levels[key].slice(0, depth).concat(value, new Array(arrayLength).fill(0)),
  })
}

const incrementNumberingDepthLevel = (levels = {}, key, depth = 0) => setNumberingDepthLevel(levels, key, depth, levels[key][depth] + 1)

const generateParagraphNumbering = (mode, structure, locations, styleMap = {}, propagatedLevels = {}, propagatedObject = {}) => {
  if (structure?.id && structure?.tag === SEGMENT_TAGS[SEGMENT_TYPES.paragraph]) {
    const { customStyle = '', styles = [] } = structure
    const styleNumbering = styles.reduce((res, style) => (Array.from(style.matchAll(numberingStyleMatchRegex)) || [])[0]?.groups || res, undefined)
    const [systemKey, depthLevel] =
      (styleMap[customStyle] ? styleMap[customStyle]?.split('-') : styleNumbering && Object.values(styleNumbering)) || []
    if (!(systemKey && propagatedLevels[systemKey])) return propagatedObject
    const shouldReset = styleMap[customStyle] && styleNumbering
    const resetValue =
      styleNumbering && propagatedLevels[styleNumbering.systemKey] && propagatedLevels[styleNumbering.systemKey][styleNumbering.depthLevel]
    if (shouldReset && (resetValue || resetValue === 0))
      setNumberingDepthLevel(propagatedLevels, systemKey, depthLevel, resetValue) &&
        incrementNumberingDepthLevel(propagatedLevels, styleNumbering.systemKey, styleNumbering.depthLevel)
    return (
      incrementNumberingDepthLevel(propagatedLevels, systemKey, depthLevel) &&
      Object.assign(propagatedObject, {
        [structure.id]: {
          value: propagatedLevels[systemKey][depthLevel],
          system: `${CASUS_KEYSTRINGS.numberingSystemKey}_${systemKey}${CASUS_KEYSTRINGS.numberingLevel}_${depthLevel}`,
        },
      })
    )
  }
  return structure
    ? NESTED_STRUCTURE_KEYS.reduce((acc, structureKey) => {
        if (!Array.isArray(structure[structureKey])) return acc
        const id = structure.id === CASUS_IDS.dataStructure ? 'root' : structure.id
        const array = structure[structureKey].slice()
        if (mode === 'preview' || mode === 'document-generation') {
          const relevantLocations = (locations[id] || []).reduce(
            (accumulated, cur) => {
              const { keep, replace } = cur
              if (!keep) return accumulated.discard.push(cur) && accumulated
              if (replace) return accumulated.replace.push(cur) && accumulated
              return accumulated
            },
            { discard: [], replace: [] }
          )
          relevantLocations.replace.reduce(
            (accumulated, { id, contentStyles, contentCustomStyle, replace, range: [start, end] }) => {
              const replaceValuesCount = replace?.split(CASUS_REGEXES.customTextSplit).filter(s => s).length || 0
              const array = accumulated[0]
              const offset = accumulated[1]
              return [
                array.splice(
                  start - offset,
                  end - start,
                  ...new Array(replaceValuesCount).fill(null).map((_, i) => ({
                    id: `${id}-${i}`,
                    tag: SEGMENT_TAGS[SEGMENT_TYPES.paragraph],
                    styles: contentStyles,
                    customStyle: contentCustomStyle,
                  }))
                ) && array,
                end - start - replaceValuesCount,
              ]
            },
            [
              relevantLocations.discard.reduce(
                (accumulated, { range: [start, end] }) => accumulated.splice(start, end - start, ...new Array(end - start).fill({})) && accumulated,
                array
              ),
              0,
            ]
          )
        }
        return array.reduce(
          (accumulated, innerStructure) => generateParagraphNumbering(mode, innerStructure, locations, styleMap, propagatedLevels, accumulated),
          acc
        )
      }, propagatedObject)
    : propagatedObject
}
const generateNumberingLevels = (numberingSystem = {}) =>
  Object.entries(numberingSystem).reduce((acc, [systemKey, levels]) => Object.assign(acc, { [systemKey]: new Array(levels.length).fill(0) }), {})

const generateStyleMap = (numberingSystem = {}) =>
  Object.entries(numberingSystem).reduce(
    (acc, [systemKey, levels]) =>
      levels.reduce(
        (accumulated, { styleName }, i) => (styleName ? Object.assign(accumulated, { [styleName]: `${systemKey}-${i}` }) : accumulated),
        acc
      ),
    {}
  )

const applyParagraphNumbering = state => {
  const { dataStructure = {}, locations: { segments: segmentsLocations = {} } = {}, numberingSystem = {} } = state
  const styleLevelMap = generateStyleMap(numberingSystem)
  const numberingLevels = generateNumberingLevels(numberingSystem)
  const generatedNumbering = generateParagraphNumbering(state.mode, dataStructure, segmentsLocations, styleLevelMap, numberingLevels)
  return Object.assign({}, state, { numbering: generatedNumbering })
}

export {
  extractSections,
  removeParagraphMarkers,
  updateParagraphTextChunk,
  replaceParagraphContent,
  addNewParagraphStyles,
  removeParagraphStyles,
  toggleParagraphStyle,
  applyParagraphCustomStyle,
  insertParagraphAbove,
  insertParagraphBelow,
  removeParagraph,
  applyParagraphNumbering,
}
