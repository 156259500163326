import Form from './Form'

const classes = {
  container: {
    wrapper: 'Form-Container-wrapper',
    title: 'Form-Container-Title-wrapper',
    signee: {
      wrapper: 'Form-Container-Signee-wrapper',
      title: 'Form-Container-Signee-Title-wrapper',
      inputGroup: {
        name: 'Form-Container-Signee-InputGroup-Name-wrapper',
        email: 'Form-Container-Signee-InputGroup-Email-wrapper',
      },
      button: {
        wrapper: 'Form-Container-Signee-Button-wrapper',
      },
    },
  },
}

export { classes }

export default Form
