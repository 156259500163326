import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect } from 'react'
import useStore, { WizardQuestionsSelector, ConnectMarkerToValueSourceAction } from '___store'

import { AI, Caret, Connect } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { classes } from '../../..'

type UseStoreHookResultType = {
  wizardQuestions: WizardQuestionsSelector
  currentConfiguringQuestion: any // -------------- Apply proper type after creating a .ts selector
  connectMarkerToValueSource: ConnectMarkerToValueSourceAction
}

type Props = { id: string; valueSources: string[] }
export const Questions: FunctionComponent<Props> = React.memo(({ id, valueSources }) => {
  const [accordion, setAccordion]: [HTMLDivElement | undefined, Dispatch<SetStateAction<HTMLDivElement | undefined>>] = useState()
  const [expanded, setExpanded] = useState(true)
  const accordionRef: RefCallback<HTMLDivElement | undefined> = useCallback(node => node && setAccordion(node), [])

  const { wizardQuestions, currentConfiguringQuestion, connectMarkerToValueSource } = useStore(
    'selectWizardQuestions',
    'selectCurrentConfiguringQuestion',
    'connectMarkerToValueSource'
  ) as UseStoreHookResultType

  const currentQuestionId = currentConfiguringQuestion?.id as string

  const availableQuestions = useMemo(
    () =>
      (wizardQuestions || []).reduce((result, { id: questionId, text }) => {
        if (valueSources?.includes(`question:${questionId}`)) return result
        const current = questionId === currentQuestionId
        const button = (
          <Button
            key={`Available-Value-Source-${questionId}`}
            className={classes.configEntry.markerConfiguration.fragment.connect.tabs.valueSources.question}
            onClick={() => connectMarkerToValueSource({ id, valueSource: `question:${questionId}` })}
            tertiary={current ? true : undefined}
          >
            <Connect />
            <span>{`${current ? '(Current Question) ' : ''}${text}`}</span>
          </Button>
        )
        if (current) result.unshift(button)
        else result.push(button)
        return result
      }, [] as JSX.Element[]),
    [wizardQuestions, valueSources, currentQuestionId, connectMarkerToValueSource, id]
  )

  const style = useMemo(() => {
    if (!expanded) return
    return { height: `calc((3rem * ${availableQuestions.length + 2}) + 0.5rem + 2px)` }
  }, [expanded, availableQuestions.length])

  useEffect(() => {
    if (expanded && availableQuestions.length === 0) setExpanded(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableQuestions.length])

  return (
    <div
      ref={accordionRef}
      className={classes.configEntry.markerConfiguration.fragment.connect.tabs.accordion}
      data-collapsed={expanded ? undefined : ''}
      style={style}
      data-type="question"
    >
      <Button
        className={classes.configEntry.markerConfiguration.fragment.connect.tabs.expandButton}
        onClick={() => {
          if (!expanded && accordion) accordion.scrollIntoView({ behavior: 'smooth' })
          setExpanded(prev => !prev)
        }}
        onClickBlur
        // disabled={!availableQuestions.length}
      >
        {availableQuestions.length ? 'Questions' : 'No available questions...'}
        <Caret />
      </Button>
      {availableQuestions}
      <Button
        className={classes.configEntry.markerConfiguration.fragment.connect.tabs.valueSources.question}
        onClick={() => console.log('GENERATE WITH AI!')}
        disabled
        primary
      >
        <AI />
        <span>Generate (and connect to) a new question with AI</span>
      </Button>
    </div>
  )
})

Questions.displayName = ''

export default Questions
