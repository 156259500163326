import React from 'react'
import useStore from '___store'

import { Cogwheel, Deselect, Plus, Trash } from 'assets/svgIconComponents'
import { Button } from 'components'
import { classes } from '../../../../index'

const ActionButton = React.memo(({ button, groupId, questionId }) => {
  const { removeQuestionLayoutGroup, unpackQuestionLayoutGroup, addNewQuestion, removeQuestion, setConfiguring } = useStore(
    'removeQuestionLayoutGroup',
    'unpackQuestionLayoutGroup',
    'addNewQuestion',
    'removeQuestion',
    'setConfiguring'
  )
  const groupActionSelectOptionClass = classes.configEntry.questionGeneration.group.actionSelectOption
  const questionActionSelectOptionClass = classes.configEntry.questionGeneration.listItem.actionSelectOption

  if (button === 'removeGroup')
    return (
      <Button
        className={groupActionSelectOptionClass}
        tabbable={false}
        onClick={() => removeQuestionLayoutGroup({ id: groupId })}
        colorScheme="transparent"
      >
        <Trash />
      </Button>
    )
  if (button === 'unpackGroup')
    return (
      <Button
        className={groupActionSelectOptionClass}
        tabbable={false}
        onClick={() => unpackQuestionLayoutGroup({ id: groupId })}
        colorScheme="transparent"
      >
        <Deselect />
      </Button>
    )
  if (button === 'addNewQuestion')
    return (
      <Button
        className={groupActionSelectOptionClass}
        tabbable={false}
        onClick={() => addNewQuestion({ questionLayoutGroup: groupId })}
        colorScheme="transparent"
      >
        <Plus />
      </Button>
    )
  if (button === 'removeQuestion')
    return (
      <Button
        className={questionActionSelectOptionClass}
        tabbable={false}
        onClick={() => removeQuestion({ id: questionId })}
        colorScheme="transparent"
      >
        <Trash />
      </Button>
    )
  if (button === 'configureQuestion')
    return (
      <Button
        className={questionActionSelectOptionClass}
        tabbable={false}
        onClick={() => setConfiguring({ key: 'question', id: questionId })}
        colorScheme="transparent"
      >
        <Cogwheel />
      </Button>
    )
})

ActionButton.displayName = 'Wizard-Configuration-QuestionConfiguration-QuestionOptionsFragment-QuestionOption-ActionButton'

export default ActionButton
