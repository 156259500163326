import React, { useEffect, useState } from 'react'
import { classes } from '.'
import { useTranslation } from 'react-i18next'
import FeaturesImage from 'assets/features.png'
import { Button } from 'components/CasusComponents'
import { useSubscriptions } from 'queries/subscriptions/useSubscriptions'
import { Loader } from 'components/common'
import subscriptionsApi from 'api/subscriptions.api'
import { isTenant } from '___utilities/___general'

const SubscriptionAdPanel: React.FC = () => {
  const { t } = useTranslation()
  const [selectedSubscription, setSelectedSubscription] = useState('')
  const { data: subscriptions, isLoading: subscriptionsLoading, refetch } = useSubscriptions()

  // Tenants
  const isSBV = isTenant('sbv', window.location.host)

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleSubscriptionClick = async (): Promise<void> => {
    if (selectedSubscription) {
      const res = await subscriptionsApi.getOne(selectedSubscription)
      if (res) {
        window.location.href = res.sessionURL
      }
    }
  }

  const handleSelectSubscription = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedSubscription(e.target.value)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{isSBV ? t('sbv_subscription_ad_title') : 'Subscribe now to unlock all features'}</div>
      <div className={classes.description}>{isSBV ? t('sbv_subscription_ad_description') : null}</div>
      <div className={classes.image}>
        <img src={FeaturesImage} alt="Features" />
      </div>
      <>
        <div className={classes.subscription.wrapper}>
          {subscriptionsLoading && <Loader />}
          {subscriptions && subscriptions.length > 0
            ? subscriptions.map(subscription => (
                <div key={subscription.priceId} className={classes.subscription.input}>
                  <div>
                    <input
                      type="radio"
                      id={subscription.priceId}
                      name="subscription"
                      value={subscription.priceId}
                      onChange={handleSelectSubscription}
                    />
                    <label htmlFor={subscription.priceId}>{t(subscription.name)}</label>
                  </div>
                  <span className={classes.subscription.price}>
                    {subscription.currency.toUpperCase()} {Math.floor(subscription.price / 100)}
                  </span>
                </div>
              ))
            : null}
        </div>
      </>
      <div className={classes.button.wrapper}>
        <Button secondary className={classes.button.item} onClick={handleSubscriptionClick}>
          Subscribe now
        </Button>
      </div>
    </div>
  )
}

export default SubscriptionAdPanel

