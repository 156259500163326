import React, { FunctionComponent, useCallback } from 'react'
import useStore, { UpdateMarkerAction } from '___store'

import { extractValueFromInputEvent } from 'utilities/helpers'
import { Button, Input } from 'components/CasusComponents'
import { classes } from '../..'

type UseStoreHookResultType = { updateMarker: UpdateMarkerAction }

type Props = { id: string; label: string; color: string; concatString: string }
export const Attributes: FunctionComponent<Props> = React.memo(({ id, label, color, concatString }) => {
  const { updateMarker } = useStore('updateMarker') as UseStoreHookResultType

  const labelInputHandler = useCallback(
    event => {
      const value = extractValueFromInputEvent('string', event) as string
      updateMarker({ id, label: value || '' })
    },
    [id, updateMarker]
  )
  // const concatStringInputHandler = useCallback(
  //   event => {
  //     const value = extractValueFromInputEvent('string', event) as string
  //     updateMarker({ id, concatString: value || '' })
  //   },
  //   [id, updateMarker]
  // )

  return (
    <div className={classes.configEntry.markerConfiguration.fragment.wrapper}>
      <span className={classes.configEntry.markerConfiguration.fragment.title}>Marker Attributes</span>
      <div className={classes.configEntry.markerConfiguration.fragment.attributes.wrapper}>
        <Input
          type="multiline"
          defaultValue={label}
          placeholder="Give this marker a label..."
          debouncedInput={300}
          showActionButtons={false}
          onInput={labelInputHandler}
        />
        <div className={classes.configEntry.markerConfiguration.fragment.attributes.colors}>
          <Button
            className="variant-1"
            onClick={() => updateMarker({ id, color: 'variant-1' })}
            dataSet={{ active: color === 'variant-1' ? '' : undefined }}
          >
            Text
          </Button>
          <Button
            className="variant-2"
            onClick={() => updateMarker({ id, color: 'variant-2' })}
            dataSet={{ active: color === 'variant-2' ? '' : undefined }}
          >
            Text
          </Button>
          <Button
            className="variant-3"
            onClick={() => updateMarker({ id, color: 'variant-3' })}
            dataSet={{ active: color === 'variant-3' ? '' : undefined }}
          >
            Text
          </Button>
          <Button
            className="variant-4"
            onClick={() => updateMarker({ id, color: 'variant-4' })}
            dataSet={{ active: color === 'variant-4' ? '' : undefined }}
          >
            Text
          </Button>
        </div>
        {/* <Button onClick={() => updateMarker({ id, concatString: ' ' })} dataSet={{ active: concatString === ' ' ? '' : undefined }}>
          <span>1 2 3 4 5</span>
        </Button>
        <Button onClick={() => updateMarker({ id, concatString: ', ' })} dataSet={{ active: concatString === ', ' ? '' : undefined }}>
          <span>1, 2, 3, 4, 5</span>
        </Button>
        <Button onClick={() => updateMarker({ id, concatString: '-' })} dataSet={{ active: concatString === '-' ? '' : undefined }}>
          <span>1-2-3-4-5</span>
        </Button>
        <Input
          type="multiline"
          defaultValue={concatString}
          placeholder="Concatenation string..."
          debouncedInput={300}
          showActionButtons={false}
          onInput={concatStringInputHandler}
        /> */}
      </div>
    </div>
  )
})

Attributes.displayName = ''

export default Attributes
