import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { auth } from 'utils'

function errorHandler(error: AxiosError) {
  if (error.response) {
    console.groupCollapsed('%cRESPONSE ERROR', 'color: red')
    console.log(error.response.status)
    console.log(error.response.headers)
    console.log(error.response.data)
    console.groupEnd()
  } else if (error.request) {
    console.groupCollapsed('%cREQUEST ERROR', 'color: red')
    console.log(error.request)
    console.groupEnd()
  } else {
    console.groupCollapsed('%cERROR: ', 'color: red', error.message)
    console.log(error)
    console.groupEnd()
  }
  console.log('%cERROR CONFIG: ', 'color: red', error.config)
  throw error
}

abstract class Api {
  protected BASE_URL?: string
  protected constructor(protected endPoint: string, protected versionless?: boolean) {
    if (versionless) this.BASE_URL = process.env.REACT_APP_BASE_API_URL_VERSIONLESS
    else this.BASE_URL = process.env.REACT_APP_BASE_API_URL + endPoint
    // this.BASE_URL = 'http://192.168.1.245:5000/casus-949bf/europe-west1/api/v1' + endPoint
  }

  protected api(options?: AxiosRequestConfig) {
    const instance = axios.create({
      baseURL: this.BASE_URL,
      ...options,
    })
    // Request interceptor to refresh the token and set Authorization header
    instance.interceptors.request.use(async request => {
      try {
        const token = await auth.currentUser?.getIdToken(true) // Force refresh the token
        if (token) {
          if (!request.headers) {
            request.headers = {} // Ensure headers object exists
          }
          request.headers['Authorization'] = `Bearer ${token}`
        }
      } catch (error) {
        console.error('Error refreshing token:', error)
        throw error
      }
      return request
    })

    // Response interceptor for error handling
    instance.interceptors.response.use(response => response, errorHandler)
    return instance
  }
}

export default Api

