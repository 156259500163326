import React, { forwardRef, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect, useImperativeHandle } from 'react'

import { InputMultilineProps, classes } from '.'
import { NEW_LINE_MATCH } from '___types'

export const MultilineInput = React.memo(
  forwardRef<HTMLPreElement, InputMultilineProps>(({ disabled, defaultValue, value, placeholder, autoselect, onInput, onEnter, onEscape }, ref) => {
    const [input, setInput]: [HTMLPreElement | undefined, Dispatch<SetStateAction<HTMLPreElement | undefined>>] = useState()
    const inputRef: RefCallback<HTMLPreElement | undefined> = useCallback(node => node && setInput(node), [])

    const computedValue = useMemo(() => value || (input && input.innerHTML) || defaultValue, [value, input, defaultValue])

    const keyDownHandler = useCallback(
      event => {
        if (event.key === 'Enter' && typeof onEnter === 'function' && !event.shiftKey) {
          event.preventDefault()
          event.stopPropagation()
          onEnter(event)
        }
        if (event.key === 'Escape' && typeof onEscape === 'function') {
          event.preventDefault()
          event.stopPropagation()
          if (input) {
            // @ts-ignore
            input.innerHTML = defaultValue?.replaceAll(NEW_LINE_MATCH, '<br/>') || ''
            const range = document.createRange()
            range.selectNodeContents(input)
            range.collapse(false)
            const selection = window.getSelection()
            selection?.removeAllRanges()
            selection?.addRange(range)
            event.currentTarget.dispatchEvent(new Event('input', { bubbles: true }))
          }
          onEscape(event)
        }
      },
      [onEnter, onEscape, input, defaultValue]
    )

    useEffect(() => {
      //@ts-ignore
      if (input) input.innerHTML = computedValue?.replaceAll(NEW_LINE_MATCH, '<br/>') || ''
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input])

    useEffect(() => {
      if (input && autoselect) input.focus()
    }, [input, autoselect])

    useImperativeHandle(ref, () => input!)

    return (
      <pre
        ref={inputRef}
        className={classes.pre}
        contentEditable={String(!disabled) as 'true' | 'false'}
        data-placeholder={placeholder || undefined}
        aria-multiline
        role="textbox"
        spellCheck={false}
        onInput={onInput}
        onKeyDown={keyDownHandler}
        onPaste={event => {
          event.preventDefault()
          const paste = event.clipboardData.getData('text')
          const selection = window.getSelection()
          if (!selection?.rangeCount) return
          selection.deleteFromDocument()
          const newNode = document.createElement('span')
          //@ts-ignore
          const spanText = paste.replaceAll(NEW_LINE_MATCH, '<br/>')
          newNode.innerHTML = spanText
          const childNodes = Array.from(newNode.childNodes).slice()
          childNodes.reverse()
          childNodes.forEach(childNode => {
            const toInsert =
              //@ts-ignore
              childNode.nodeName === '#text' ? document.createTextNode(childNode.textContent?.replaceAll(NEW_LINE_MATCH, '') || '') : childNode
            selection.getRangeAt(0).insertNode(toInsert)
          })
          selection.collapseToEnd()
          event.currentTarget.dispatchEvent(new Event('input', { bubbles: true }))
        }}
        onFocus={() => {
          if (input && input.innerText && window.getSelection) {
            const range = document.createRange()
            range.selectNodeContents(input)
            const selection = window.getSelection()
            selection?.removeAllRanges()
            selection?.addRange(range)
          }
        }}
        onBlur={() => window.getSelection()?.removeAllRanges()}
      />
    )
  })
)

export default MultilineInput
