import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'

import { useDocument } from 'queries/documents'

import DocumentPreviewComponent from './components/DocumentPreview.component'
import { Loader } from 'components/common'
import { useUser } from 'queries/user/useUser'
import { DocumentGenerationWizard } from 'Wizard'
import { useFetchTemplate } from 'queries/templates'
// import { generateDataStructure } from '___store/storeSegments/wizard/helpers'
import { applyAnswerValuesToDataStructure } from 'Wizard/helpers'
import { evaluateAllMarkers } from '___store/storeSegments/wizard/helpers/index'

const DocumentPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data = {}, isLoading } = useDocument(id)
  const user = useUser()
  const version = user?.version
  const { push } = useHistory()

  //@ts-ignore
  const { name, templateId, v3Answers = [] } = data
  //@ts-ignore
  const answers = v3Answers.map(({ questionId: id, values }) => ({ id, values }))
  const { data: templateData, refetch } = useFetchTemplate(templateId, false, id)

  // console.log('TEMPLATE DATA: ', templateData)

  useEffect(() => {
    refetch()
  }, [refetch, templateId])

  const { v3 = {} } = (templateData || {}) as any
  const { dataStructure = {}, locations = { segments: {}, text: {} }, questions = [] } = v3
  const { cssData: v3cssData, numberingSystem: v3numberingSystem } = v3

  let renderOld = false
  renderOld = version !== 'v3'

  const answeredPreviewDataStructure = useMemo(() => {
    const pseudoState = { questions, answers, locations, mode: 'document-generation' }
    const evaluatedState = evaluateAllMarkers(pseudoState)
    const evaluatedMarkers = evaluatedState.locations
    return applyAnswerValuesToDataStructure(dataStructure, evaluatedMarkers)
  }, [dataStructure, locations, questions, answers])

  // console.log('GENERATED: ', answeredPreviewDataStructure)

  const closeHandler = useCallback(() => push('/documents'), [push])

  const V3component = useMemo(
    () =>
      answeredPreviewDataStructure ? (
        <DocumentGenerationWizard
          // @ts-ignore
          id={id}
          title={name}
          dataStructure={answeredPreviewDataStructure}
          cssData={v3cssData}
          numberingSystem={v3numberingSystem}
          closeHandler={closeHandler}
        />
      ) : null,
    [id, name, answeredPreviewDataStructure, v3cssData, v3numberingSystem, closeHandler]
  )

  const render = useMemo(
    //@ts-ignore
    () => (renderOld ? <DocumentPreviewComponent documentFile={data} /> : V3component),
    [renderOld, data, V3component]
  )

  if (!data || isLoading) return <Loader />

  return render
}

export default DocumentPreview
