import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { auth } from 'utils'

function errorHandler(error: AxiosError) {
  if (error.response) {
    console.groupCollapsed('%cRESPONSE ERROR', 'color: red')
    console.log(error.response.status)
    console.log(error.response.headers)
    console.log(error.response.data)
    console.groupEnd()
  } else if (error.request) {
    console.groupCollapsed('%cREQUEST ERROR', 'color: red')
    console.log(error.request)
    console.groupEnd()
  } else {
    console.groupCollapsed('%cERROR: ', 'color: red', error.message)
    console.log(error)
    console.groupEnd()
  }
  console.log('%cERROR CONFIG: ', 'color: red', error.config)
  throw error
}

abstract class API {
  protected BASE_URL: string
  protected ENDPOINT: string

  constructor(endpoint: string) {
    this.BASE_URL = `${process.env.REACT_APP_BASE_API_URL_VERSIONLESS}`
    this.ENDPOINT = endpoint
  }

  protected api(config?: AxiosRequestConfig, versionOverride?: string, endpointOverride?: string) {
    const baseURL = `${this.BASE_URL}/${versionOverride || 'v2'}/${endpointOverride || this.ENDPOINT}`
    const instance = axios.create(Object.assign({ baseURL }, config))
    // Request interceptor to refresh the token and set Authorization header
    instance.interceptors.request.use(async request => {
      try {
        const token = await auth.currentUser?.getIdToken(true) // Force refresh the token
        if (token) {
          if (!request.headers) {
            request.headers = {} // Ensure headers object exists
          }
          request.headers['Authorization'] = `Bearer ${token}`
        }
      } catch (error) {
        console.error('Error refreshing token:', error)
        throw error
      }
      return request
    })

    // Response interceptor for error handling
    instance.interceptors.response.use(response => response, errorHandler)
    return instance
  }
  protected post(url?: string, data?: object, config?: AxiosRequestConfig, versionOverride?: string, endpointOverride?: string) {
    return this.api(config, versionOverride, endpointOverride).post(url || '', data)
  }
  protected patch(url?: string, data?: object, config?: AxiosRequestConfig, versionOverride?: string, endpointOverride?: string) {
    return this.api(config, versionOverride, endpointOverride).patch(url || '', data)
  }
  protected get(url?: string, config?: AxiosRequestConfig, versionOverride?: string, endpointOverride?: string) {
    return this.api(config, versionOverride, endpointOverride).get(url || '')
  }
  protected delete(url?: string, config?: AxiosRequestConfig, versionOverride?: string, endpointOverride?: string) {
    return this.api(config, versionOverride, endpointOverride).delete(url || '')
  }
}

export default API

