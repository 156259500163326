import React, {
  FunctionComponent,
  // useState, useMemo, useEffect, useCallback
} from 'react'
// import { useTranslation } from 'react-i18next'
// import useStore, { WizardAnsweringSelector, WizardQuestionsSelector } from '___store'
// import { QUESTIONNAIRE_POST_STEPS } from '___store/storeSegments/wizard/typified/helpers/document-generation'

// import { ValuesOf } from '___types'
import {
  WizardLayoutRightPaneEditorInteractFocusModeProps,
  wizardLayoutRightPaneEditorInteractFocusModeClasses as classes,
  // wizardLayoutRightPaneEditorInteractClasses,
  // wizardLayoutRightPaneEditorMarkerSegmentsClasses,
  // wizardLayoutRightPaneEditorMarkerTextClasses,
} from '../../../..'
// import Overlay from './FocusMode.Overlay'
// import { Switch } from 'components/CasusComponents'

// type UseStoreHookResultType = {
//   wizardQuestions: WizardQuestionsSelector
//   wizardAnswering: WizardAnsweringSelector
// }

export const FocusMode: FunctionComponent<WizardLayoutRightPaneEditorInteractFocusModeProps> = React.memo(() => {
  // const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.editor.preview' })
  // const { wizardQuestions, wizardAnswering } = useStore('selectWizardQuestions', 'selectWizardAnswering') as UseStoreHookResultType

  // const [toggle, setToggle] = useState(false)

  // const focusedMarkersIds = useMemo(() => {
  //   if (!toggle) return ''
  //   const answeringQuestionId = wizardAnswering?.split(':')[1]
  //   if (!answeringQuestionId) return ''
  //   const relevantQuestion = wizardQuestions?.find(question => question.id === answeringQuestionId)
  //   if (!relevantQuestion) return ''
  //   const relevantOptionMarkers = relevantQuestion.optionGroups.reduce(
  //     (resultingMarkerIds, optionGroup) =>
  //       optionGroup.options.reduce((accumulated, option) => accumulated.concat(option.markers), resultingMarkerIds),
  //     [] as string[]
  //   )
  //   return Array.from(new Set(relevantOptionMarkers.concat(relevantQuestion.markers))).join('; ')
  // }, [toggle, wizardQuestions, wizardAnswering])

  // const setStep = useCallback(
  //   (step: WizardAnsweringSelector) => {
  //     const shouldUnfocus = Object.values(QUESTIONNAIRE_POST_STEPS).includes(step as ValuesOf<typeof QUESTIONNAIRE_POST_STEPS>)
  //     if (toggle && shouldUnfocus) setToggle(false)
  //   },
  //   [toggle, setToggle]
  // )
  // useEffect(() => setStep(wizardAnswering), [setStep, wizardAnswering])

  // useEffect(() => {
  //   const editorInteractElement = document.querySelector(`.${wizardLayoutRightPaneEditorInteractClasses.wrapper}`) as HTMLDivElement
  //   if (!editorInteractElement) return () => {}
  //   let timeout = undefined as unknown as NodeJS.Timeout
  //   timeout = setTimeout(() => {
  //     if (toggle) editorInteractElement.dataset.focusMode = ''
  //     if (!toggle) delete editorInteractElement.dataset.focusMode
  //   }, 0)
  //   return () => {
  //     if (timeout) clearTimeout(timeout)
  //     delete editorInteractElement.dataset.focusMode
  //   }
  // }, [toggle])

  // useEffect(() => {
  //   let timeout = undefined as unknown as NodeJS.Timeout
  //   let relevantMarkerElements = [] as HTMLElement[] // For some reason HTMLMarkElement does not exist :/
  //   const markerIdArray = focusedMarkersIds.split('; ')
  //   if (markerIdArray.length)
  //     timeout = setTimeout(() => {
  //       const segmentsMarkers = Array.from(
  //         document.querySelectorAll(`.${wizardLayoutRightPaneEditorMarkerSegmentsClasses.wrapper}`)
  //       ) as HTMLDivElement[]
  //       const textMarkers = Array.from(document.querySelectorAll(`.${wizardLayoutRightPaneEditorMarkerTextClasses.wrapper}`)) as HTMLElement[] // For some reason HTMLMarkElement does not exist :/
  //       const filteredSegmentsMarkers = segmentsMarkers.filter(marker => markerIdArray.includes(marker.id.replace(';', '')))
  //       const filteredTextMarkers = textMarkers.filter(marker => markerIdArray.includes(marker.id))
  //       const allFocusedMarkers = (filteredSegmentsMarkers as HTMLElement[]).concat(filteredTextMarkers)
  //       relevantMarkerElements = allFocusedMarkers
  //       relevantMarkerElements.forEach(marker => (marker.dataset.focused = ''))
  //       if (relevantMarkerElements.length) relevantMarkerElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
  //     }, 0)
  //   return () => {
  //     if (timeout) clearTimeout(timeout)
  //     relevantMarkerElements.forEach(marker => delete marker.dataset.focused)
  //   }
  // }, [focusedMarkersIds])

  return (
    <>
      <div id={classes.wrapper}>
        {/* <div>
          <div>
            <Switch className={classes.switch} value={toggle} onClick={toggle => setToggle(toggle)} emphasized>
              <span>{translate('focus-mode')}</span>
            </Switch>
          </div>
        </div> */}
      </div>
      {/* {toggle ? <Overlay focusedMarkersIds={focusedMarkersIds} /> : null} */}
    </>
  )
})

FocusMode.displayName = 'WizardLayout-RightPane-Editor-Interact-FocusMode'

export default FocusMode
