import React, {useState} from 'react'

import {useMasterData} from 'queries/app/useMasterdata'
import {useLogin} from 'pages/Auth/hooks/useLogin'

import LoginForm from './LoginForm.component'
import VisecaLoginForm from "./tenants/VisecaLoginForm.component"
import SbvLoginForm from "./tenants/SbvLoginForm.component"
import BillieLoginForm from "./tenants/BillieLoginForm.component"

import SmsVerifyModal from "./SmsVerifyModal/SmsVerifyModal"

import {useTranslation} from 'react-i18next'
import {useToast} from 'hooks/useToast'

export interface ILoginFormState {
    email: string
    password: string
    code?: string
}

const LoginFormContainer: React.FC = () => {
    const url = new URL(window.location.href)
    const emailParam = url.searchParams.get('email')
    const masterdata = useMasterData()
    const toast = useToast()
    const {t} = useTranslation()
    const {
        isLoading,
        login,
        isPromptSMSVerify,
        setIsPromptSMSVerify,
        smsCode,
        setSmsCode,
        handleVerifySMSLogin
    } = useLogin()
    const tenant = window.location.host.split('.')[0]

    const [state, setState] = useState<ILoginFormState>({
        email: emailParam ? emailParam : '',
        password: '',
        code: '',
    })

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault()
        const {email, password} = state
        const emailDomain = email.split("@").pop()
        if (emailDomain === 'viseca.ch') {
            const message = t('login_email_domain_not_allowed')
            toast('error', message)
        } else {
            login(email, password)
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const {name, value} = e.target
        if (emailParam && name === 'email') return
        setState(prev => ({...prev, [name]: value}))
    }

    const allowClientRegister = masterdata
        ? Boolean(masterdata?.client.registration_open)
        : false

    return (
        <React.Fragment>
            {tenant === 'viseca' ? (
                <VisecaLoginForm
                    isLoading={isLoading}
                    handleSubmit={handleSubmit}
                />
            ) : tenant === 'sbv' ? (
                <SbvLoginForm
                    isLoading={isLoading}
                    handleSubmit={handleSubmit}
                />
            )
             : tenant === 'billie' ? (
                <BillieLoginForm
                    isLoading={isLoading}
                    handleSubmit={handleSubmit}
                />
             ) :
             (
                <LoginForm
                    isLoading={isLoading}
                    values={state}
                    allowClientRegister={allowClientRegister}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            )}
            <SmsVerifyModal
                open={isPromptSMSVerify}
                smsCode={smsCode}
                setSmsCode={setSmsCode}
                onAction={handleVerifySMSLogin}
                onClose={() => {
                    setIsPromptSMSVerify(false)
                }}
            />
        </React.Fragment>
    )
}

export default LoginFormContainer
