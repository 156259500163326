import { ITime, IDocumentFormat, IDocumentAuthor, SharedWithItem, FolderItem } from 'types'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { DateTime, Settings } from 'luxon'
import C from './C_Logo/C_Logo'
import './style.css'
import { useTranslation } from 'react-i18next'
import { classes } from '.'
import { DOCXDocument, Pdf } from 'assets/svgIconComponents'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'

interface Props {
  title: string
  id: string
  edited: ITime
  renameActive?: boolean
  templateName?: string
  type?: IDocumentFormat
  sharedAuthor?: IDocumentAuthor | null
  isUpToDateWithTheLatestTemplateVersion?: boolean
  sharedWith?: SharedWithItem[]
  currentFolderType?: FolderItem['type']
  sharingEnabled?: boolean
  isCasus?: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>
  onContextMenu?: React.MouseEventHandler<HTMLDivElement>
  handleUpdateName?: (docId: string, newName: string) => void
  isDownloadable?: boolean
  isLoading?: boolean
  signatureStatus?: string
  isSelected?: boolean
  menuItems?: IPopupMenuItem[]
  children?: React.ReactNode
}

const ListFile: React.FC<Props> = ({
  id,
  title,
  edited,
  renameActive,
  type,
  onClick,
  isLoading = false,
  signatureStatus = null,
  isSelected = false,
  children = null,
}) => {
  const [focused, setFocused] = useState(false)
  const inputEl = useRef<HTMLTextAreaElement>(null)
  const { i18n } = useTranslation()
  Settings.defaultLocale = i18n.language.replace('_', '-')

  // const showDot = isUpToDateWithTheLatestTemplateVersion === false

  const handleFocusEl = useCallback(() => {
    if (inputEl.current) {
      setTimeout(() => {
        if (inputEl.current) {
          inputEl.current.focus()
          setFocused(true)
        }
      }, 1)
    }
  }, [inputEl])

  useEffect(() => {
    if (renameActive) handleFocusEl()
  }, [handleFocusEl, renameActive])

  useEffect(() => {
    if (focused && inputEl.current) {
      inputEl.current.select()
    }
  }, [focused])

  /*  const handleNameBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    if (handleUpdateName) handleUpdateName(id, name)
    inputEl.current?.scroll(0, 0)
    setFocused(false)
  }

  const handleEnterClick = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.keyCode === 13 && handleUpdateName) handleUpdateName(id, name)
    setFocused(false)
  } */

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (renameActive) return
    onClick(e)
  }

  return (
    <div key={id} className={classes.listView.wrapper} data-loading={isLoading} data-selected={isSelected}>
      <div className={classes.listView.selectButton} onClick={handleClick} />

      {/* {signatureStatus && (
        <div className={classes.listView.signaturesTag.wrapper} data-status={signatureStatus}>
          <div className={classes.listView.signaturesTag.icon}>
            <Signature />
          </div>
        </div>
      )} */}
      <div className={classes.listView.preview}>
        <DOCXDocument data-loading={isLoading} />
      </div>
      {children}
      <div className={classes.listView.base.wrapper}>
        <div className={classes.listView.base.title}>{title}</div>
        <span className={classes.listView.base.relativeTime}>
          {type === 'pdf' || signatureStatus ? <Pdf /> : <C category="" />}
          {DateTime.fromMillis(edited?._seconds * 1000).toRelative()}
        </span>
      </div>
    </div>
  )
}

export default ListFile

