import React, { FunctionComponent } from 'react'

import { DOCUMENTS_FILTERS } from '___types'
import { FilteredTemplatesAccordionProps } from '.'
import TemplatesAccordion from './TemplatesAccordion'

export const Shared: FunctionComponent<FilteredTemplatesAccordionProps> = React.memo(({ onClick }) => {
  return <TemplatesAccordion filter={DOCUMENTS_FILTERS.SHARED} onClick={onClick} />
})

Shared.displayName = 'Casus-Components-Accordion-Templates-Shared'

export default Shared
