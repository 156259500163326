import React, { useMemo } from 'react'
import useStore from '___store'

import { Tag, classes } from './index'
import { Info } from 'assets/svgIconComponents'

const TagStrip = React.memo(({ questionId, optionId, noTagMessage, noTagDescription }) => {
  // const [node, setNode] = useState(null)
  // const setRef = useCallback(node => setNode(node || null), [])
  const { questionMarkerIds = '', optionMarkerIds = '' } = useStore(`selectQuestionMarkerIds[${questionId}]`, `selectOptionMarkerIds[${optionId}]`)
  const markers = useMemo(
    () => (optionId ? optionMarkerIds : questionMarkerIds).split('; ').filter(id => id),
    [optionId, optionMarkerIds, questionMarkerIds]
  )
  const children = useMemo(
    () => markers.map(id => <Tag key={`TagStrip-Tag-${id}`} id={id} questionId={questionId} optionId={optionId} />),
    [markers, questionId, optionId]
  )
  const noTagRender = !children.length && (noTagMessage || noTagDescription)
  const showTagStrip = children.length || noTagRender
  // useEffect(() => node?.scroll(-node.scrollWidth, 0), [node, markers])
  return showTagStrip ? (
    <div
      // ref={setRef}
      className={classes.tagStripWrapper}
      data-strip-type={optionId ? 'option' : 'question'}
    >
      {children.length ? children : null}
      {noTagRender ? (
        <span className={classes.tagStripSpan}>
          {noTagDescription ? (
            <div className={classes.tagStripInfo}>
              <Info />
              <span className={classes.tagStripInfoText}>{noTagDescription}</span>
            </div>
          ) : null}
          {noTagMessage}
        </span>
      ) : null}
    </div>
  ) : null
})

TagStrip.displayName = 'Wizard-Configuration-QuestionConfiguration-TagStrip'

export default TagStrip
