import React, { useMemo } from 'react'
import useStore from '___store'

import { CASUS_CLASSES, SEGMENT_TAGS, SEGMENT_TYPES } from 'Wizard/constants'
import { classes } from '../..'
import { Cell } from './index'

const TableRow = React.memo(({ id, children }) => {
  const { segmentInfo = {} } = useStore(`selectSegmentInfo[${id}]`)

  const { styles = [] } = segmentInfo

  const rowClass = [CASUS_CLASSES.tableRow, classes.segment.table.row].filter(s => s).join(' ')
  return (
    <tr id={id} className={`${styles.join(" ")} ${rowClass}`}>
      {children}
    </tr>
  )
})

const TableSection = React.memo(({ tag, children }) => {
  const render = useMemo(() => {
    switch (tag) {
      case SEGMENT_TAGS[SEGMENT_TYPES.tableHeader]:
        return <thead className={`${CASUS_CLASSES.tableHeader} ${classes.segment.table.header}`}>{children}</thead>
      case SEGMENT_TAGS[SEGMENT_TYPES.tableBody]:
        return <tbody className={`${CASUS_CLASSES.tableBody} ${classes.segment.table.body}`}>{children}</tbody>
      case SEGMENT_TAGS[SEGMENT_TYPES.tableFooter]:
        return <tfoot className={`${CASUS_CLASSES.tableFooter} ${classes.segment.table.footer}`}>{children}</tfoot>
      default:
        return null
    }
  }, [tag, children])
  return render
})

// const segmentTypes = [SEGMENT_TYPES.tableHeader, SEGMENT_TYPES.tableBody, SEGMENT_TYPES.tableFooter]

const Table = React.memo(({ id, styles, tableSections }) => {
  // const { segmentById: tableSegment = {}, mode } = useStore(`selectSegmentById[${id}]`, 'selectMode')

  // const { customStyle = '', styles = [] } = tableSegment

  // const tableMode = mode === 'template-creation' ? 'edit' : 'preview'

  const sections = useMemo(
    () =>
      // Object.entries(tableSegment)
      //   .filter(([key]) => segmentTypes.includes(key))
      Object.entries(tableSections).map(([segmentType, rows]) => (
        <TableSection key={`table-${id}-${segmentType}`} tag={SEGMENT_TAGS[segmentType]}>
          {rows?.map(({ id: rowId, cells }) => (
            <TableRow key={`table-${id}-${segmentType}-row-${rowId}`} id={rowId}>
              {cells?.map(({ id: cellId }) => (
                <Cell key={`table-${id}-${segmentType}-row-${rowId}-cell-${cellId}`} id={cellId}></Cell>
              ))}
            </TableRow>
          ))}
        </TableSection>
      )),
    [tableSections, id]
  )

  // const segmentClass = [CASUS_CLASSES.segmentDiv, classes.segment.wrapper, customStyle, ...styles].filter(s => s).join(' ')
  // const tableClass = [CASUS_CLASSES.tableSegment, classes.segment.table.wrapper, customStyle, ...styles].filter(s => s).join(' ')
  const tableClass = [CASUS_CLASSES.segmentDiv, CASUS_CLASSES.tableSegment, classes.segment.wrapper, classes.segment.table.wrapper, styles]
    .filter(s => s)
    .join(' ')

  return (
    // <div className={segmentClass}>
    <table id={id} className={tableClass}>
      {sections}
    </table>
    // </div>
  )
})

Table.displayName = 'Wizard-Editor-Segment-Table'

export default Table
