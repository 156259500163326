import { CasusLinkProperties } from '___types'
import Link from './Link'

import './style.scss'

const classes = { wrapper: 'Casus-Components-Link-wrapper' } as const

export type LinkProps = { properties: CasusLinkProperties }

export { Link, classes }
export default Link
