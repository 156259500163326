import React, { Dispatch, FunctionComponent, RefCallback, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import useStore, { ConnectMarkerToValueSourceAction } from '___store'

import { Caret, Connect } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { classes } from '../../..'
import { COMPUTED_VALUES } from '___types'

type UseStoreHookResultType = { connectMarkerToValueSource: ConnectMarkerToValueSourceAction }

type Props = { id: string; valueSources: string[] }
export const Computed: FunctionComponent<Props> = React.memo(({ id, valueSources }) => {
  const [accordion, setAccordion]: [HTMLDivElement | undefined, Dispatch<SetStateAction<HTMLDivElement | undefined>>] = useState()
  const [expanded, setExpanded] = useState(false)
  const accordionRef: RefCallback<HTMLDivElement | undefined> = useCallback(node => node && setAccordion(node), [])

  const { connectMarkerToValueSource } = useStore('connectMarkerToValueSource') as UseStoreHookResultType

  const availableValues = useMemo(
    () =>
      Object.entries(COMPUTED_VALUES).reduce((result, [key, { label }]) => {
        if (valueSources?.includes(`computed:${key}`)) return result
        else
          result.push(
            <Button
              key={`Available-Value-Source-${key}`}
              className={classes.configEntry.markerConfiguration.fragment.connect.tabs.valueSources.computed}
              onClick={() => connectMarkerToValueSource({ id, valueSource: `computed:${key}` })}
            >
              <Connect />
              <span>{label}</span>
            </Button>
          )
        return result
      }, [] as JSX.Element[]),
    [valueSources, connectMarkerToValueSource, id]
  )

  const style = useMemo(() => {
    if (!expanded) return
    return { height: `calc((3rem * ${availableValues.length + 1}) + 0.5rem + 2px)` }
  }, [expanded, availableValues.length])

  useEffect(() => {
    if (expanded && availableValues.length === 0) setExpanded(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableValues.length])

  return (
    <div
      ref={accordionRef}
      className={classes.configEntry.markerConfiguration.fragment.connect.tabs.accordion}
      data-collapsed={expanded ? undefined : ''}
      style={style}
      data-type="computed"
    >
      <Button
        className={classes.configEntry.markerConfiguration.fragment.connect.tabs.expandButton}
        onClick={() => {
          if (!expanded && accordion) accordion.scrollIntoView({ behavior: 'smooth' })
          setExpanded(prev => !prev)
        }}
        onClickBlur
        disabled={!availableValues.length}
      >
        {availableValues.length ? 'Computed Values' : 'No available values...'}
        <Caret />
      </Button>
      {availableValues}
    </div>
  )
})

Computed.displayName = ''

export default Computed
