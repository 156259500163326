import React from 'react'
import { classes } from '.'
import NoRemindersImage from 'assets/illustrations/no-reminders.svg'
import { useTranslation } from 'react-i18next'

const EmptyReminders: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={classes.empty.wrapper}>
      <img src={NoRemindersImage} className={classes.empty.icon} alt="empty state" />
      <p className={classes.empty.text}>{t('no_reminders_available')}</p>
    </div>
  )
}

export default EmptyReminders

