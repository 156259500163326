import React, { FunctionComponent, useCallback } from 'react'
import useStore, { UpdateMarkerAction } from '___store'

import { parseCalculation } from '___utilities'
import { extractValueFromInputEvent } from 'utilities/helpers'
import { Button, Input, Switch } from 'components/CasusComponents'
import { classes } from '../..'

type UseStoreHookResultType = { updateMarker: UpdateMarkerAction }

type Props = { id: string; type: 'text' | 'segments'; combine: boolean; concatString: string; calculation: string }
export const Output: FunctionComponent<Props> = React.memo(({ id, type, combine, concatString, calculation }) => {
  const { updateMarker } = useStore('updateMarker') as UseStoreHookResultType

  const concatStringInputHandler = useCallback(
    event => {
      const value = extractValueFromInputEvent('string', event) as string
      updateMarker({ id, concatString: value || '' })
    },
    [id, updateMarker]
  )

  const parsedCalculation = parseCalculation(calculation)
  const error = calculation && (parsedCalculation.length !== 1 || parsedCalculation[0].error)

  return (
    <div className={classes.configEntry.markerConfiguration.fragment.wrapper}>
      <span className={classes.configEntry.markerConfiguration.fragment.title}>Output</span>
      <Switch
        className={classes.configEntry.markerConfiguration.fragment.output.switch}
        value={Boolean(combine)}
        dataSet={{ label: combine ? 'Calculate' : 'Combine Values' }}
        onClick={value => updateMarker({ id, combine: value })}
        tertiary={combine || undefined}
      />
      {!combine && type === 'text' ? (
        <div className={classes.configEntry.markerConfiguration.fragment.output.concatStringOptions}>
          <Button onClick={() => updateMarker({ id, concatString: ' ' })} dataSet={{ active: concatString === ' ' || undefined }}>
            <span>1 2 3 4 5</span>
          </Button>
          <Button onClick={() => updateMarker({ id, concatString: ', ' })} dataSet={{ active: concatString === ', ' || undefined }}>
            <span>1, 2, 3, 4, 5</span>
          </Button>
          <Button onClick={() => updateMarker({ id, concatString: '-' })} dataSet={{ active: concatString === '-' || undefined }}>
            <span>1-2-3-4-5</span>
          </Button>
          <Input
            type="text"
            defaultValue={concatString}
            placeholder="Concatenation string..."
            debouncedInput={300}
            showActionButtons={false}
            onInput={concatStringInputHandler}
          />
        </div>
      ) : null}
      {combine ? (
        <>
          <Input
            className={classes.configEntry.markerConfiguration.fragment.output.calculationInput}
            type="multiline"
            value={calculation}
            placeholder="Calculation goes here..."
            onInput={event => updateMarker({ id, calculation: extractValueFromInputEvent('string', event) as string })}
            debouncedInput={300}
          />
          {error ? <span>ERROR!</span> : null}
        </>
      ) : null}
    </div>
  )
})

Output.displayName = 'Wizard-Configuration-MarkerConfiguration-Output'

export default Output
