import React, { useMemo, useRef, useState } from 'react'
import useStore from '___store'

import { CheckBox, Input, RadioButton } from 'components'
import { CASUS_KEYSTRINGS } from 'Wizard/constants'
import { classes } from '../../..'

const focusInput = wrapper => wrapper.current?.querySelector('input')?.focus()
const inputTypeMap = {
  string: 'text',
  number: 'number',
  date: 'date',
  'date-time': 'datetime-local',
}

export const QuestionOption = React.memo(({ id, optionGroupId, questionId, select, isStandalone }) => {
  const ref = useRef()
  const {
    questionOptionGroupOptionById: { text = '', value = '', valueType, placeholder = 'User input...' } = {},
    questionOptionIsSelected: selected,
    answerWithOption,
    unanswerOption,
  } = useStore(
    `selectQuestionOptionGroupOptionById[${questionId},${optionGroupId},${id}]`,
    `selectQuestionOptionIsSelected[${questionId},${id}]`,
    'answerWithOption',
    'unanswerOption'
  )

  const [userInput, setUserInput] = useState('')

  const userValue = `${CASUS_KEYSTRINGS.input}:${id}:${userInput}`
  const answerValue = text || value ? id : userValue

  const {
    wrapper: wrapperClass,
    selectComponent: selectComponentClass,
    input: inputClass,
  } = classes.configEntry.questionnaire.questionListItem.optionGroup.option

  const selectComponent = useMemo(() => {
    const handler = activate => {
      if (!(text || value || userInput)) return activate && focusInput(ref)
      return activate ? answerWithOption({ questionId, value: answerValue }) : unanswerOption({ questionId, id })
    }
    return select === 'multi' ? (
      <CheckBox className={selectComponentClass} label={text || value} value={selected} onChange={handler} />
    ) : (
      <RadioButton className={selectComponentClass} label={text || value} value={selected} onChange={handler} />
    )
  }, [text, value, userInput, answerWithOption, questionId, answerValue, unanswerOption, id, select, selectComponentClass, selected])

  const input = useMemo(() => {
    const handler = value => {
      setUserInput(value)
      if (!value) return unanswerOption({ questionId, id })
      return answerWithOption({ questionId, value: `${CASUS_KEYSTRINGS.input}:${id}:${value}` })
    }
    return !(text || value) ? (
      <Input
        className={inputClass}
        type={inputTypeMap[valueType]}
        placeholder={placeholder}
        defaultValue={userInput}
        onChange={handler}
        needsConfirmation
        blurShowUnmodified
      />
    ) : null
  }, [unanswerOption, questionId, id, answerWithOption, text, value, inputClass, valueType, placeholder, userInput])

  return (
    <div ref={ref} className={wrapperClass} data-selected={selected}>
      {!(isStandalone && !(text || value)) ? selectComponent : null}
      {/* {selectComponent} */}
      {input}
      {/* {subQuestions} */}
    </div>
  )
})

QuestionOption.displayName = 'Wizard-Configuration-Questionnaire-QuestionListItem-QuestionOptionGroup-QuestionOption'

export default QuestionOption
