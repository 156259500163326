import React, { FunctionComponent, useMemo } from 'react'

import { DOCUMENT_FLOW_MODES } from '___types'
import Page from './Page'
import { SectionContextProvider, useEditorPreviewContext } from '..'
import { generateLayoutString } from '../../helpers'
import { WizardLayoutRightPaneEditorSectionProps, wizardLayoutRightPaneEditorSectionClasses as classes } from '../../../..'

export const Section: FunctionComponent<WizardLayoutRightPaneEditorSectionProps> = React.memo(({ index }) => {
  const { dataStructure } = useEditorPreviewContext()
  const { sections } = dataStructure || {}
  const section = sections && typeof index === 'number' && !isNaN(index) && sections[index]

  const { id, pages } = section || {}

  const render = useMemo(
    () => (pages?.length ? pages.map((_, i) => <Page key={`${DOCUMENT_FLOW_MODES.PREVIEW}-${id}-${index}/${i}`} index={i}></Page>) : []),
    [pages, id, index]
  )

  const sectionClassName = [classes.wrapper, section && generateLayoutString(section.layout)].filter(string => string).join(' ')

  return (
    <div id={id} className={sectionClassName}>
      <SectionContextProvider index={index}>{render}</SectionContextProvider>
    </div>
  )
})

Section.displayName = 'WizardLayout-RightPane-Editor-Preview-Section'

export default Section
