import { MouseEvent } from 'react'
import { TemplatesFilter } from '___types'

import TemplatesGrid from './TemplatesGrid'
import { Mine as MyTemplatesGrid } from './TemplatesGrid.Mine'
import { Shared as SharedTemplatesGrid } from './TemplatesGrid.Shared'
import { Company as CompanyTemplatesGrid } from './TemplatesGrid.Company'

import './style.scss'

const classes = { wrapper: 'FilteredTemplatesGrid-wrapper' }

export type TemplatesGridProps = {
  filter: TemplatesFilter
  onClick?: (event: MouseEvent<HTMLButtonElement>, templateId?: string | null) => void
}

export type FilteredTemplatesGridProps = {
  onClick?: (event: MouseEvent<HTMLButtonElement>, templateId?: string | null) => void
}

export { TemplatesGrid, MyTemplatesGrid, SharedTemplatesGrid, CompanyTemplatesGrid, classes }
export default TemplatesGrid
