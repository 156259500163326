import React from 'react'

import { LOCATION_TYPES, SEGMENT_IGNORE_STYLES, TextChunks, TextChunkObject, TextLocation, SegmentIgnoreTypes, STYLE_MAP, EditorMode } from '___types'
import { Marker as InteractMarker } from './Interact.Components/Marker'
import { wizardLayoutRightPaneEditorSegmentParagraphClasses as paragraphClasses } from '../../..'

const generateNewChunk = (text: string, current: TextChunkObject): TextChunkObject => Object.assign({}, current, { text })
export type ComputedTextLocation = TextLocation & { textChunks: TextChunks }
export type IteratedChunks = (ComputedTextLocation | TextChunkObject)[]
const parseChunkContent = (markers: TextLocation[], textChunks: TextChunks): IteratedChunks =>
  markers.reduce((result, marker) => {
    const iterated = result.reduce(
      (accumulated, current) => {
        const { chunks, length } = accumulated
        const { id, range } = marker
        const [markerStart, markerEnd] = range
        const { text = "" } = current as TextChunkObject
        if (current.type === LOCATION_TYPES.TEXT) {
          accumulated.length += ((current as ComputedTextLocation).textChunks || []).reduce((sum, { text }) => sum + text.length, 0)
          chunks.push(current)
          return accumulated
        }
        const chunkStart = length
        const chunkEnd = chunkStart + text.length
        if (markerStart >= chunkEnd || markerEnd <= chunkStart) {
          accumulated.length += text.length
          chunks.push(current)
          return accumulated
        }
        const relativeStart = markerStart - chunkStart
        const relativeEnd = markerEnd - chunkStart
        const before = text.slice(0, Math.max(relativeStart, 0))
        const inside = text.slice(Math.max(relativeStart, 0), Math.min(relativeEnd, text.length))
        const after = text.slice(Math.min(relativeEnd, text.length), text.length)
        const resultingChunks = [] as IteratedChunks
        const lastChunk = chunks[chunks.length - 1]
        if (lastChunk?.type === LOCATION_TYPES.TEXT && (lastChunk as ComputedTextLocation).id === id)
          (lastChunk as ComputedTextLocation).textChunks.push(generateNewChunk(inside, current as TextChunkObject))
        else {
          const computedMarkerChunk = Object.assign({}, marker, {
            textChunks: [generateNewChunk(inside, current as TextChunkObject)],
          } as ComputedTextLocation)
          resultingChunks.push(computedMarkerChunk)
        }
        if (before.length) resultingChunks.unshift(generateNewChunk(before, current as TextChunkObject))
        if (after.length) resultingChunks.push(generateNewChunk(after, current as TextChunkObject))
        chunks.push(...resultingChunks)
        accumulated.length += text?.length
        return accumulated
      },
      { chunks: [] as IteratedChunks, length: 0 }
    )
    return iterated.chunks
  }, textChunks as IteratedChunks)

const mapDataStructureStyles = (styles: string[]): string[] =>
  styles?.map(style =>
    //@ts-ignore
    Object.entries(STYLE_MAP).reduce((resultingStyle, [key, value]) => resultingStyle.replace(key, value.replaceAll('\\-', '-')), style)
  ) || []

const mapContentToSpan = (chunk: ComputedTextLocation | TextChunkObject, id: string, i: number, mode: EditorMode = 'edit') => {
  const { id: markerId, textChunks } = chunk as ComputedTextLocation
  const { text, customStyle, styles } = chunk as TextChunkObject
  if (chunk.type === LOCATION_TYPES.TEXT) {
    if (mode === 'interact') return <InteractMarker key={markerId} id={markerId} type={LOCATION_TYPES.TEXT} textChunks={textChunks} />
  }
  const chunkClasses = [paragraphClasses.chunk, customStyle, ...mapDataStructureStyles(styles)].filter(
    cn => cn && !SEGMENT_IGNORE_STYLES.includes(cn as SegmentIgnoreTypes)
  )
  if (chunkClasses?.length < 2) return text
  return (
    <span key={`paragraph-${id}-chunk-${i}`} className={chunkClasses.join(' ')}>
      {text}
    </span>
  )
}

const getSpanStyles = (textChunks: TextChunks): string[] =>
  Array.from(new Set(textChunks.reduce((acc, { styles }) => acc.concat(mapDataStructureStyles(styles)), [] as string[]))).filter(
    style => style && !SEGMENT_IGNORE_STYLES.includes(style as SegmentIgnoreTypes)
  )

export { parseChunkContent, mapContentToSpan, getSpanStyles, mapDataStructureStyles }
