import AdvancedQuestionConfiguration from './AdvancedQuestionConfiguration'

import './style.scss'

const classes = {
  wrapper: 'Wizard-Configuration-AdvancedQuestionConfiguration-wrapper',
}

export { AdvancedQuestionConfiguration, classes }
export default AdvancedQuestionConfiguration
