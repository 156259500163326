import React, { useEffect, useState } from 'react'
import './style.scss'
import { Icon } from 'components/common'
import { useUpdateTemplate } from 'queries/templates'
import { useDocumentsUpdate } from 'queries/documents'

// extrapolate scss classes from render in a const
const classes = {
  typeImage: 'type-image',
  container: 'header-container',
  title: {
    container: 'title-container',
    text: 'title-text',
  },
  renameButton: 'rename-button',
}

export const Header = React.memo(({ type, name, id }) => {
  const [renameActive, setRenameActive] = useState(false)
  const [nameState, setNameState] = useState(name)

  const tempUpdate = useUpdateTemplate()

  const docUpdate = useDocumentsUpdate()

  useEffect(() => {
    setNameState(name)
  }, [name])

  const handleNameChange = e => {
    setNameState(e.target.value)
  }

  const handleNameBlur = e => {
    setRenameActive(false)
    if (nameState === name) return
    onUpdateName(id, nameState)
  }

  const onUpdateName = (id, name) => {
    if (type === 'template') {
      tempUpdate.mutate({ id, data: { name } })
    } else {
      docUpdate.mutate({ id, data: { name } })
    }
  }

  const renderIcon = () => {
    switch (type) {
      case 'document':
        return (<Icon iconName="documents" style={{ color: '#4b5563', width: '100%', height: '100%' }} />)
      case 'pdf':
        return (<Icon iconName="pdf" style={{ color: '#4b5563', width: '100%', height: '100%' }} />)
      case 'template':
        return (<Icon iconName="templates" style={{ color: '#4b5563', width: '100%', height: '100%' }} />)
      default:
        return (<Icon iconName="sign" style={{ color: '#4b5563', width: '100%', height: '100%' }} />)
    }
  }

  return (
    <div>
      <div className={classes.typeImage}>
        { renderIcon() }
      </div>
      <div className={classes.title.container}>
        <div>
          <textarea
            value={nameState}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            disabled={!renameActive}
            className={classes.title.text}
          ></textarea>
        </div>
      </div>
    </div>
  )
})

