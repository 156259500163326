import React, { useState, useMemo, useCallback, useEffect } from 'react'
import useStore from '___store'

import { Caret, Check } from 'assets/svgIconComponents'
import { Button, ButtonGroup } from 'components'
import { classes } from '../..'
import { calculateBoundaries, generateSegmentsLocationObject, generateFixedString } from '../../selection'

const MarkSegmentsConfirmButton = React.memo(({ segments }) => {
  const [fixed, setFixed] = useState(null)
  const [node, setNode] = useState(null)
  const { markLocation } = useStore('markLocation')

  const setRef = useCallback(node => setNode(node || null), [])
  const { top, left } = useMemo(() => calculateBoundaries(segments.range), [segments.range]) || {} // extract width for align: right
  const editor = useMemo(() => document.getElementsByClassName(classes.wrapper)[0], [])
  const [editorHeight, initialScrollTop] = useMemo(() => [editor.getBoundingClientRect()?.height, editor.scrollTop], [editor])
  const ponder = useMemo(() => 2.5 * parseFloat(getComputedStyle(document.documentElement).fontSize) + 2, [])

  useEffect(() => {
    if (!editor) return
    setFixed(editorHeight, initialScrollTop, top, ponder)
    const handler = ({ currentTarget: { scrollTop } }) => setFixed(generateFixedString(editorHeight, scrollTop, top, ponder))
    editor.addEventListener('scroll', handler)
    return () => editor.removeEventListener('scroll', handler)
  }, [editor, editorHeight, initialScrollTop, top, ponder])

  useEffect(() => {
    if (!node) return
    delete node.dataset.fixedTop
    delete node.dataset.fixedBottom
    node.setAttribute('style', `--left: ${left}px; ${fixed === 'bottom' || fixed === 'top' ? '' : `--top: ${top}px`}`)
    // node.setAttribute('style', `--left: ${left + width}px; ${fixed === 'bottom' || fixed === 'top' ? '' : `--top: ${top}px`}`) // align: right
    if (fixed === 'bottom') node.dataset.fixedBottom = true
    if (fixed === 'top') node.dataset.fixedTop = true
  }, [node, left, fixed, top])

  return (
    <div ref={setRef} className={classes.selectionControls.segments.wrapper}>
      <ButtonGroup className={classes.selectionControls.segments.buttonGroup}>
        <Button
          className={classes.selectionControls.segments.button}
          tabbable={false}
          onClick={() => {
            markLocation(generateSegmentsLocationObject(segments))
            document.getSelection().removeAllRanges()
          }}
          colorScheme="transparent"
        >
          <div>
            <Caret />
            Mark Segments
          </div>
          <Check />
        </Button>
      </ButtonGroup>
    </div>
  )
})

MarkSegmentsConfirmButton.displayName = 'Wizard-Editor-SelectionControls-MarkSegmentsConfirmButton'

export default MarkSegmentsConfirmButton
