import { ReplaceSectionProps } from './RadioStandardForm.types'
import React, { Fragment } from 'react'
import OptionsList from '../../components/OptionList/OptionList'
import { CustomButton } from 'components/common'
import { btnContent } from 'pages/Authenticated/Template/constants/content'
import { useTranslation } from 'react-i18next'

const ReplaceSection: React.FC<ReplaceSectionProps> = ({
  handleAddOption,
  handleTextSelectClick,
  textSelectionIsActive,
  ...otherProps
}) => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <CustomButton
        btntype='primary'
        width={168}
        onClick={() => handleTextSelectClick('no-id')}
        style={{ marginBottom: 24 }}
        textselectionactive={textSelectionIsActive ? 1 : 0}
      >
        {t(btnContent.markText)}
      </CustomButton>
      <OptionsList actions={false} {...otherProps} />
      <CustomButton
        btntype='white'
        onClick={handleAddOption}
        style={{ margin: '8px 0' }}
        id='template-creation-new-option-btn'
      >
        {t(btnContent.newOption)}
      </CustomButton>
    </Fragment>
  )
}

export default ReplaceSection
