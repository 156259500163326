import React, { useMemo } from 'react'

import { calculateBoundaries } from '../../selection'
import { classes } from '../..'

const SegmentsBackground = React.memo(({ segments }) => {
  const boundaries = useMemo(() => calculateBoundaries(segments.range), [segments.range])

  return (
    <div
      className={classes.selectionControls.segments.background}
      style={Object.entries(boundaries || {}).reduce((acc, [k, v]) => Object.assign(acc, { [`--${k}`]: `${v}px` }), {})}
    ></div>
  )
})

SegmentsBackground.displayName = 'Wizard-Editor-SelectionControls-SegmentsBackground'

export default SegmentsBackground
