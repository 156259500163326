import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { className } from './index'

const d2r = deg => ((2 * Math.PI) / 360) * deg

const getPullPoint = (angleOffset, cx, cy, pointDistance, pullDistance, pullAngle) => {
  const calculatedSide = Math.sqrt(pointDistance ** 2 + pullDistance ** 2 - 2 * pointDistance * pullDistance * Math.cos(pullAngle))
  const calculatedAngle =
    angleOffset +
    (pullDistance <= pointDistance
      ? Math.asin(pullDistance * (Math.sin(pullAngle) / calculatedSide))
      : 180 - Math.asin(pointDistance * (Math.sin(pullAngle) / calculatedSide)))

  const pullX = String((cx + Math.sin(calculatedAngle) * calculatedSide).toFixed(2))
  const pullY = String((cy - Math.cos(calculatedAngle) * calculatedSide).toFixed(2))
  return [pullX, pullY]
}

const stars = [
  { cx: 55, cy: 60, pullDistance: 20, pullAngle: d2r(20), pointDistance: 25, pointCount: 4, angleOffset: d2r(0), scale: 1 },
  { cx: 25, cy: 32, pullDistance: 20, pullAngle: d2r(20), pointDistance: 25, pointCount: 4, angleOffset: d2r(0), scale: 0.5 },
  { cx: 48, cy: 15, pullDistance: 20, pullAngle: d2r(20), pointDistance: 25, pointCount: 4, angleOffset: d2r(0), scale: 0.25 },
]

const AI = React.memo(() => {
  const iconId = useMemo(uuid, [])
  const render = useMemo(
    () =>
      stars.map(({ cx, cy, pullDistance, pullAngle, pointDistance, pointCount, angleOffset, scale }, i) => {
        const angleStep = d2r(360 / pointCount)
        const d = new Array(pointCount)
          .fill(null)
          .reduce(
            (stringArray, _, i) => {
              const pointAngle = angleOffset + angleStep * i
              const pointX = String((cx + Math.sin(pointAngle) * pointDistance).toFixed(2))
              const pointY = String((cy - Math.cos(pointAngle) * pointDistance).toFixed(2))
              const dSegment = [
                pointX,
                pointY,
                'C',
                ...getPullPoint(pointAngle, cx, cy, pointDistance, pullDistance, pullAngle),
                ...getPullPoint(angleOffset + angleStep * (i + 1), cx, cy, pointDistance, pullDistance, -pullAngle),
              ]
              return stringArray.concat(dSegment)
            },
            ['M']
          )
          .concat(String(cx + Math.sin(angleOffset) * pointDistance), String(cy - Math.cos(angleOffset) * pointDistance), 'Z')
          .join(' ')
        return (
          <path
            key={`AI-SVG-Icon-${iconId}-${i}`}
            d={d}
            stroke="currentColor"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="currentColor"
            transform={`scale(${scale} ${scale})`}
            transform-origin={`${cx} ${cy}`}
          />
        )
      }),
    [iconId]
  )
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      {render}
    </svg>
  )
})

export { AI }
export default AI
