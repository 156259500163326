import { resetAppState } from './general'
import { setDocumentsFolderFilter, setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments } from './documents'
import { setTemplatesFolderFilter, setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates } from './templates'

import { SetDocumentsFolderFilterPayload, SetDocumentsFilterPayload, SetSelectedDocumentIdPayload } from './documents'
import { SetTemplatesFolderFilterPayload, SetTemplatesFilterPayload, SetSelectedTemplateIdPayload } from './templates'

export const initialAppState = {
  documents: { filter: [] as string[], folderFilter: [] as string[], selected: null as string | null },
  templates: { filter: [] as string[], folderFilter: [] as string[], selected: null as string | null },
}
export type AppState = typeof initialAppState
export type { SetDocumentsFolderFilterPayload, SetDocumentsFilterPayload, SetSelectedDocumentIdPayload }
export type { SetTemplatesFolderFilterPayload, SetTemplatesFilterPayload, SetSelectedTemplateIdPayload }

export { resetAppState }
export { setDocumentsFolderFilter, setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments }
export { setTemplatesFolderFilter, setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates }

export default initialAppState
