export const ERR_MSG_DEFAULT = 'err_msg_default'
export const ERR_MSG_PASSWORD_CHARACTERS_COUNT =
  'err_msg_password_characters_count'
export const ERR_PASSWORDS_DONT_MATCH = 'err_password_dont_match'
export const MESSAGE_INVALID_EMAIL = 'message_invalid_email'
export const ERR_MSG_NO_USER_WITH_EMAIL = 'err_msg_no_user_with_email'
export const USER_WITH_EMAIL_ALREADY_EXISTS = 'user_with_email_exists'
export const SUCCESS_PASSWORD_CHANGED = 'success_password_changed'
export const ERR_MSG_TO_MANY_PASSWORD_ATTEMPTS =
  'err_msg_too_many_password_attempts'
