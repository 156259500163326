import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from '___hooks'
import { Button, Dropdown, Profile } from 'components/CasusComponents'
import { mainLayoutHeaderClasses as classes } from '../..'
import { Cogwheel, Cross, Plus, Template } from 'assets/svgIconComponents'
import { useHistory } from 'react-router-dom'

export const Actions: FunctionComponent = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.header' })
  // const { pathname } = useLocation()
  const history = useHistory()
  const { data: user, logout } = useUser()
  const { firstName, lastName, email, imageUrl } = user || {}
  const profile = useMemo(() => ({ firstName, lastName, email, imageUrl }), [firstName, lastName, email, imageUrl])

  const availableOptions = useMemo(() => {
    const options = {
      SETTINGS: { path: '/settings', icon: <Cogwheel />, label: translate('account.settings') },
      LOGOUT: { path: '/logout', icon: <Cross />, label: translate('account.logout'), onClickOverride: logout },
    }
    // const directory = pathname.split('/').filter(s => s)[0]
    // delete (options as Record<string, ValuesOf<typeof OPTIONS>>)[directory]
    return options
  }, [translate, logout])

  const dropdownOptions = useMemo(
    () =>
      Object.entries(availableOptions as Record<string, { path: string; icon: JSX.Element; label: string; onClickOverride?: Function }>).map(
        ([id, { path, icon, label, onClickOverride }]) => (
          <Button
            key={`MainLayout-Header-Navigation-Button-${id}`}
            onClick={() => (typeof onClickOverride === 'function' ? onClickOverride() : history.push(path))}
            onClickBlur
            noBackgroundSVG
            noOverlaySVG
          >
            {icon}
            {label}
          </Button>
        )
      ),
    [availableOptions, history]
  )

  return (
    <div className={classes.actions}>
      <Button onClick={() => history.push('/document')} emphasized>
        <Plus />
      </Button>
      <Button onClick={() => history.push('/template/new')} primary>
        <Template />
      </Button>
      <Dropdown hideCaret label={<Profile profile={profile} />}>
        {dropdownOptions}
      </Dropdown>
    </div>
  )
})

Actions.displayName = 'MainLayout-Header-Actions'

export default Actions
