import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, BinProps, className } from './index'

const ZOOM = 1

const Bin: FunctionComponent<BinProps> = React.memo(({ full }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const maskId = useMemo(uuid, [])

  const d = 'M 25 25 h 50 l -6 60 h -38 Z'

  return (
    <svg className={`${className} Bin`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <path d={d} stroke="black" strokeWidth="5" fill="black" />
        </mask>
      </defs>
      <path d={d} stroke="currentColor" strokeWidth="5" fill="none" />
      <path d="M 40 35 v 40 M 50 35 v 40 M 60 35 v 40" stroke="currentColor" strokeWidth="3" fill="none" />
      <path
        d="M 18 25 h 16 l 8 -8 h 16 l 8 8 h 16 Z"
        stroke="currentColor"
        strokeWidth="5"
        fill="none"
        transform={full ? 'rotate(80 50 50) translate(10 -6)' : ''}
      />
      {full ? (
        <g mask={`url(#${maskId}`}>
          <circle cx="40" cy="22" r="10" stroke="currentColor" strokeWidth="5" fill="none" />
          <circle cx="60" cy="22" r="10" stroke="currentColor" strokeWidth="5" fill="none" />
          <circle cx="50" cy="14" r="10" stroke="currentColor" strokeWidth="5" fill="none" />
        </g>
      ) : null}
    </svg>
  )
})

export { Bin }
export default Bin
