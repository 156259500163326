import { useFetchDocumentList, useFetchDocumentFolderList, useCreateDocumentFolder, useFetchTrashedDocumentList } from '___queries'

export const useDocumentList = () => {
  const documentList = useFetchDocumentList()
  const trashedDocuments = useFetchTrashedDocumentList()
  const documentFolderList = useFetchDocumentFolderList()
  const { createDocumentFolder, creatingDocumentFolder } = useCreateDocumentFolder()
  return {
    list: documentList,
    trashed: trashedDocuments,
    folders: documentFolderList,
    createFolder: createDocumentFolder,
    creatingFolder: creatingDocumentFolder,
  }
}

export default useDocumentList
