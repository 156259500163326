import React from 'react'
import useStore from '___store'

import { Input } from 'components'
import { classes } from '../../../index'
import { Switch } from 'components/CasusComponents'
import { Public, Security } from 'assets/svgIconComponents'

const QuestionDetails = React.memo(({ questionId }) => {
  const {
    questionById: { description = '', isPrivate } = {},
    updateQuestion,
    toggleQuestionPrivacy,
  } = useStore(`selectQuestionById[${questionId}]`, 'updateQuestion', 'toggleQuestionPrivacy')
  const {
    wrapper: wrapperClass,
    // title: titleClass,
    text: textClass,
    questionDetails: { input: inputClass },
  } = classes.configEntry.questionConfiguration.fragment
  return (
    <div className={wrapperClass}>
      {/* <span className={titleClass}>Additional Information</span> */}
      <span className={textClass}>Question Description:</span>
      <Input
        className={inputClass}
        placeholder="Write a description to better explain the question for the potential user of this template. This description will appear when answering the question."
        defaultValue={description}
        onChange={description => updateQuestion({ id: questionId, description })}
        onDiscard={() => {}}
        needsConfirmation
        multiline
        extraLines={2}
        blurShowUnmodified
        parseLinks
      />
      {/* <span className={textClass}>Example:</span> */}
      {/* <Input
        className={inputClass}
        placeholder="Write an answer example for the potential user."
        defaultValue={example}
        onChange={example => updateQuestion({ id: questionId, example })}
        onDiscard={() => {}}
        needsConfirmation
        multiline
        extraLines={2}
        blurShowUnmodified
        parseLinks
      /> */}
      {/* <span className={textClass}>Practical Hint:</span> */}
      {/* <Input
        className={inputClass}
        placeholder="This is a field for practical hints. Use this to give hints about which options correspond to which marked segments in the document, or a any hints in general."
        defaultValue={hint}
        onChange={hint => updateQuestion({ id: questionId, hint })}
        onDiscard={() => {}}
        needsConfirmation
        multiline
        extraLines={2}
        blurShowUnmodified
        parseLinks
      /> */}
      <span className={textClass}>Private question:</span>
      <Switch value={Boolean(isPrivate)} onClick={() => toggleQuestionPrivacy(questionId)} pin={isPrivate ? <Security level="QES" /> : <Public />} />
    </div>
  )
})

QuestionDetails.displayName = 'Wizard-Configuration-QuestionConfiguration-QuestionDetails'

export default QuestionDetails
