import React, { FunctionComponent, useMemo } from 'react'

import { Button } from 'components/CasusComponents'
import { FooterProps } from '.'
import { classes } from '..'

export const Footer: FunctionComponent<FooterProps> = React.memo(props => {
  const { render, cancel, conclude, closeCallback, cancelCallback, concludeCallback, context } = props

  const cancelButton = useMemo(() => {
    if (cancel === false) return null
    if (typeof cancel === 'string')
      return (
        <Button onClick={() => cancelCallback()} onClickBlur>
          {cancel}
        </Button>
      )
    if (typeof cancel === 'function') return cancel(closeCallback, cancelCallback, concludeCallback, context)
    return (
      <Button onClick={() => cancelCallback()} onClickBlur>
        {cancel ?? 'Cancel'}
      </Button>
    )
  }, [cancel, cancelCallback, closeCallback, concludeCallback, context])

  const concludeButton = useMemo(() => {
    if (conclude === false) return null
    if (typeof conclude === 'string')
      return (
        <Button onClick={() => concludeCallback()} onClickBlur primary>
          {conclude}
        </Button>
      )
    if (typeof conclude === 'function') return conclude(closeCallback, cancelCallback, concludeCallback, context)
    return (
      <Button onClick={() => concludeCallback()} onClickBlur primary>
        {conclude ?? 'Conclude'}
      </Button>
    )
  }, [conclude, concludeCallback, closeCallback, cancelCallback, context])

  if (render === false) return null
  return (
    <div className={classes.footer}>
      {typeof render === 'string' ? (
        <>
          <span>{render}</span>
          {cancelButton}
          {concludeButton}
        </>
      ) : typeof render === 'function' ? (
        render(closeCallback, cancelCallback, concludeCallback)
      ) : (
        render ?? (
          <>
            {cancelButton}
            {concludeButton}
          </>
        )
      )}
    </div>
  )
})

Footer.displayName = 'Modal-Footer'

export default Footer
