import { useQuery } from 'react-query'

import { teamAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchTeamMembers = () => {
  const userQuery = useQuery({
    queryKey: [QUERY_KEYS.TEAM],
    queryFn: teamAPI.getTeam,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  })
  return userQuery
}

export default useFetchTeamMembers
