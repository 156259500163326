import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Document from 'components/files/File/FileComponent'

import { AI, Person, TextLoader } from 'assets/svgIconComponents'

import { classes } from '.'
import { ITemplate } from 'types'
import { useTranslation } from 'react-i18next'
import { Button, Input } from 'components/CasusComponents'
import { useUser } from 'queries/user/useUser'
import TemplatesApi from 'api/templates.api'
import { ERR_MSG_DEFAULT } from 'constants/messages'
import CasusLogo from 'assets/c_logo.png'

interface IProps {
  templates: ITemplate[] | undefined
}

const TemplatesList: React.FC<IProps> = ({ templates }) => {
  const {
    push,
    // location
  } = useHistory()

  const user = useUser()!!

  const goCreateDocument = (): void => {
    if (user?.version === 'v1' || user?.version === 'v2') {
      push('/document/new/')
    } else if (user?.version === 'v3') {
      push('/documents/configure')
    }
  }

  const { t } = useTranslation()

  if (!templates || templates?.length === 0) {
    return (
      <div className={classes.emptyTemplates}>
        <Button onClick={() => goCreateDocument()} primary>
          {t('suggest_template_go_manually')}
        </Button>
      </div>
    )
  }

  const selectTemplate = (templateId: string): void => {
    if (user?.version === 'v1' || user?.version === 'v2') {
      push(`/document/new/${templateId}`)
    } else if (user?.version === 'v3') {
      push(`/documents/${templateId}/configure`)
    }
  }

  return (
    <div className={classes.templatesList.wrapper}>
      {templates?.map(templateItem => {
        return (
          <Document
            key={templateItem.id}
            id={templateItem.id}
            title={templateItem.name}
            edited={templateItem.edited}
            isCasus={templateItem.isCasus}
            isPremium={templateItem.isPremium}
            onClick={() => selectTemplate(templateItem.id)}
          />
        )
      })}
    </div>
  )
}

interface ISuggestMessages {
  type: 'user' | 'ai'
  isLoading: boolean
  templates?: ITemplate[] | undefined
  explanation?: string
  content?: string
  isError?: boolean
}

const AiSuggestedTemplatesPanel: React.FC = () => {
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')
  const [templates, setTemplates] = useState<ITemplate[] | undefined>(undefined)
  const [threadId, setThreadId] = useState<string>('')
  const [messages, setMessages] = useState<ISuggestMessages[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const user = useUser()!!

  const onReset = () => {
    setTemplates(undefined)
    setMessages([])
    setQuery('')
    setIsLoading(false)
    setThreadId('')
  }

  const onSearch = async () => {
    setIsLoading(true)
    let queryCopy = query + ''

    setQuery('')
    setMessages(prev => [{ type: 'ai', isLoading: true }, { type: 'user', content: queryCopy, isLoading: false }, ...prev])
    setTemplates(undefined)

    // fetch templates
    const req = await TemplatesApi.getSuggestedTemplates(queryCopy, threadId)

    if (!req) {
      setIsLoading(false)
      setMessages(prev => ([{ type: 'ai', explanation: t(ERR_MSG_DEFAULT), isError: true }, ...prev] as ISuggestMessages[]).filter(e => !e.isLoading))
    } else if (req.error) {
      setIsLoading(false)
      setMessages(prev => ([{ type: 'ai', explanation: t(ERR_MSG_DEFAULT), isError: true }, ...prev] as ISuggestMessages[]).filter(e => !e.isLoading))
    } else {
      setThreadId(req.threadId)
      setIsLoading(false)
      setTemplates(req.templates)
      setMessages(prev => ([{ type: 'ai', explanation: req.explanation }, ...prev] as ISuggestMessages[]).filter(e => !e.isLoading))
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.blurBg} />
      <div className={classes.title}>
        <AI /> {t('suggest_template_title')}
      </div>

      <div className={classes.chat}>
        {templates ? <TemplatesList templates={templates} /> : null}
        {messages.length > 0
          ? messages.map((messages, index) =>
              messages.type === 'ai' ? (
                messages.isLoading ? (
                  <div key={index} data-is-ai="true" className={classes.explanation}>
                    <div data-is-error={messages.isError} className={classes.explanationText}>
                      <TextLoader />
                    </div>
                    <img alt="casus logo" src={CasusLogo} className={classes.explanationBubble} data-is-ai="true" />
                  </div>
                ) : (
                  <div key={index} data-is-ai="true" className={classes.explanation}>
                    <span data-is-error={messages.isError} className={classes.explanationText}>
                      {messages.explanation}
                    </span>
                    <img alt="casus logo" src={CasusLogo} className={classes.explanationBubble} data-is-ai="true" />
                  </div>
                )
              ) : (
                <div key={index} className={classes.explanation}>
                  {user.imageUrl ? (
                    <img src={user.imageUrl} className={classes.explanationBubble} alt="profile" />
                  ) : (
                    <div className={classes.explanationBubble}>
                      <Person />
                    </div>
                  )}
                  <span className={classes.explanationText}>{messages.content}</span>
                </div>
              )
            )
          : null}
      </div>

      <div className={classes.aiInput.wrapper}>
        <Input
          className={classes.aiInput.input}
          placeholder={t('suggest_template_placeholder')}
          type="text"
          value={query}
          onConfirm={onSearch}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
        />
        {!isLoading ? (
          <Button primary onClick={onSearch} className={classes.aiInput.button}>
            <span>{t('search')}</span>
          </Button>
        ) : (
          <Button primary onClick={() => {}} className={classes.aiInput.button}>
            <span>{t('loading')}</span>
          </Button>
        )}
        {messages.length > 0 ? (
          <Button transparent onClick={onReset} className={classes.aiInput.close}>
            <span>{t('close')}</span>
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default AiSuggestedTemplatesPanel

