import React, { FunctionComponent } from 'react'
import useStore, { WizardModeSelector } from '___store'

import { DOCUMENT_FLOW_MODES } from '___types'
import { Create, Update } from './Header.DynamicButton.Components'

type UseStoreHookResultType = {
  wizardMode: WizardModeSelector
}

export const DynamicButton: FunctionComponent = React.memo(() => {
  const { wizardMode } = useStore('selectWizardMode') as UseStoreHookResultType

  if (wizardMode === DOCUMENT_FLOW_MODES.TEMPLATE_SELECT) return null
  if (wizardMode === DOCUMENT_FLOW_MODES.NEW) return <Create />
  if (wizardMode === DOCUMENT_FLOW_MODES.EDIT) return <Update />
  return null

  // const render = useMemo(() => {
  //   if (wizardMode === DOCUMENT_FLOW_MODES.PREVIEW)
  //     return (
  //       <Button className={classes.buttons.dynamic.button} onClick={postStepHandler}>
  //         {translate('finish')}
  //       </Button>
  //     )
  // }, [])
})
DynamicButton.displayName = 'WizardLayout-Header-DynamicButton'

export default DynamicButton
