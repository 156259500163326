import { MutateOptions, useMutation } from 'react-query'
import { v4 as uuid } from 'uuid'

import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { useCallback, useState } from 'react'

export type SendDocumentSignatureReminderVariables = { id: string; requestId: string; email: string }
export type SendDocumentSignatureReminderContext = { mutationId: string }
export type SendDocumentSignatureReminderMutationOptions = MutateOptions<
  void,
  unknown,
  SendDocumentSignatureReminderVariables,
  SendDocumentSignatureReminderContext
>
export type SendDocumentSignatureReminderFunctionType = (
  requestId: string,
  email: string,
  options?: SendDocumentSignatureReminderMutationOptions
) => void
const sendDocumentSignatureReminderMutationFunction = (variables: SendDocumentSignatureReminderVariables) =>
  documentsAPI.sendDocumentSignatureReminder(variables.id, variables.requestId, variables.email)

export const useSendDocumentSignatureReminder = (id?: string | null) => {
  const [sendingSignatureReminder, setSendingSignatureReminder] = useState<Record<string, string[]>>({})

  const onMutate = (variables: SendDocumentSignatureReminderVariables) => {
    const mutationId = uuid()
    setSendingSignatureReminder(prev =>
      Object.assign(prev, {
        [`${variables.requestId}:${variables.email}`]: (prev[`${variables.requestId}:${variables.email}`] || []).concat(mutationId),
      })
    )
    return { mutationId }
  }
  const onSettled = (
    res: void,
    error: unknown,
    variables: SendDocumentSignatureReminderVariables,
    context: SendDocumentSignatureReminderContext | undefined
  ) => {
    setSendingSignatureReminder(prev =>
      Object.assign(prev, {
        [`${variables.requestId}:${variables.email}`]: (prev[`${variables.requestId}:${variables.email}`] || []).filter(
          id => id !== context?.mutationId
        ),
      })
    )
  }

  const documentSendSignatureReminderMutation = useMutation<
    void,
    unknown,
    SendDocumentSignatureReminderVariables,
    SendDocumentSignatureReminderContext
  >([QUERY_KEYS.DOCUMENT, id], sendDocumentSignatureReminderMutationFunction, {
    onMutate,
    onSettled,
  })

  const sendSignatureReminderMutationFunction: SendDocumentSignatureReminderFunctionType = useCallback(
    (requestId, email, options) => documentSendSignatureReminderMutation.mutate({ id: id!, requestId, email }, options),
    [documentSendSignatureReminderMutation, id]
  )

  return {
    sendSignatureReminder: sendSignatureReminderMutationFunction,
    sendingSignatureReminder: Object.entries(sendingSignatureReminder).reduce(
      (result, [format, ids]) => Object.assign(result, { [format]: Boolean(ids.length) }),
      {} as Record<string, boolean>
    ),
  }
}

export default useSendDocumentSignatureReminder
