import React from 'react'
import { classes } from '.'
import NoDocumentsImage from 'assets/illustrations/no-documents.svg'
import { useTranslation } from 'react-i18next'

const EmptyDocuments: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={classes.empty.wrapper}>
      <img src={NoDocumentsImage} className={classes.empty.icon} alt="empty state"/>
      <p className={classes.empty.text}>{t('no_documents_available')}</p>
    </div>
  )
}

export default EmptyDocuments

