import React, { FunctionComponent, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import useStore, { WizardTitleSelector } from '___store'

import { DIRECTORY_MODE_PATHNAME_MATCH, DOCUMENT_DIRECTORY, Directory } from '___types'
import { Document, Spinner, Template } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { DynamicButton } from '.'
import { wizardLayoutHeaderClasses as classes } from '../..'

type UseStoreHookResultType = { wizardTitle: WizardTitleSelector }

export const Header: FunctionComponent = React.memo(() => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { wizardTitle } = useStore('selectWizardTitle') as UseStoreHookResultType

  const directory = useMemo(() => pathname.match(DIRECTORY_MODE_PATHNAME_MATCH)?.groups?.directory || '', [pathname]) as Directory

  return (
    <div className={classes.wrapper}>
      {/* // Celeste's request: history.push(``) */}
      <Button className={classes.logo} onClick={() => history.push(`/${directory}s`)}>
        <Spinner animate={false} />
      </Button>
      {wizardTitle ? (
        <span className={classes.title}>
          {directory === DOCUMENT_DIRECTORY ? <Document /> : <Template />}
          {wizardTitle}
        </span>
      ) : null}
      <DynamicButton />
    </div>
  )
})
Header.displayName = 'WizardLayout-Header'

export default Header
