import { useCallback, useState } from 'react'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useToast } from 'hooks/useToast'
import { useMasterData } from 'queries/app/useMasterdata'
import { useUser } from 'queries/user/useUser'
import { IApiHookOptions } from 'types'
import { toBase64 } from 'utils'
import { useCreateTemplate } from 'queries/templates'
import { useHistory } from 'react-router'
import ConversionApi from 'api/conversion.api'

interface ISettings {
  keepStyles?: boolean
}

interface IUpload {
  file: File
  originalStylesKept: boolean
  isAutoAutomateAI?: boolean
}

const err_msg =
  'Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal'

export const useTemplateUpload = () => {
  const { push } = useHistory()
  const { currentFolder } = useCurrentFolder('templates')
  const { mutate: create } = useCreateTemplate()
  const superadmins = useMasterData()?.superadmins ?? []
  const user = useUser()
  const docXMicro = !!user?.beta?.newDocxMicroservice
  const email = user?.email
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const { id, type } = currentFolder

  const upload = useCallback(
    async (payload: IUpload, options?: IApiHookOptions) => {
      if (!email) return
      setIsLoading(true)
      const { file, isAutoAutomateAI } = payload
      try {
        let base64data: string | null = file ? await toBase64(file) : null
        if (!base64data) throw new Error('base64data missing')
        const base64dataCut = base64data.split('base64,')[1]
        const category = []
        if (type === 'my' && id !== 'my') category.push(id)

        if (type === 'casus' && superadmins.includes(email) && id !== 'casus') {
          category.push(id)
        }

        let htmlData = ""
        if (!docXMicro) htmlData = await ConversionApi.convert({ base64data: base64dataCut })

        create(
          {
            htmlData,
            originalStylesKept: true,
            name: file.name,
            category,
            base64data,
            isAutoAutomateAI: isAutoAutomateAI,
          },
          {
            onSuccess: (template) => {
              push(`/template/${template.id}edit`)
            },
            onSettled: () => setIsLoading(false),
          }
        )
      } catch (err) {
        // @ts-ignore
        if (err.response?.data?.error_code === 'E0118') {
          return toast('error', err_msg)
        }
        if (options?.onError) options.onError()
        setIsLoading(false)
      } finally {
        if (options?.onSettled) options.onSettled()
      }
    },
    [id, type, email, superadmins, create, push, toast, docXMicro]
  )
  return { upload, isLoading }
}
