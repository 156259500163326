import { Input } from './Input'

const classes = {
  wrapper: 'Form-Container-Signee-Input-wrapper',
  label: 'Form-Container-Signee-Input-Label-wrapper',
  field: 'Form-Container-Signee-Input-Field-wrapper',
  textarea: 'Form-Container-Signee-Input-Textarea-wrapper',
}

export { classes }

export default Input
