import { useMemo } from 'react'
import useStore, { WizardLocationByTypeAndIdSelector, WizardState, getMarkerByTypeAndId } from '___store'

import { LocationType } from '___types'
import { useEditorContext } from '..'

type UseStoreHookResultType = { wizardLocationByTypeAndId?: WizardLocationByTypeAndIdSelector }
type UseMarkerResultType = { range: [number, number] }

export const useMarker = (type: LocationType, id: string): UseMarkerResultType => {
  const editorContext = useEditorContext()

  const staticPseudoState = Object.assign({}, editorContext as unknown) as WizardState

  const contextLocation = getMarkerByTypeAndId(staticPseudoState, type, id)[0]

  const { wizardLocationByTypeAndId } = useStore(
    !contextLocation ? `selectWizardLocationByTypeAndId[${type},${id}]` : undefined
  ) as UseStoreHookResultType

  const result = contextLocation || wizardLocationByTypeAndId
  const useMarkerReturn = useMemo(() => Object.assign({}, { range: result?.range || [0, 0] }), [result])

  return useMarkerReturn
}

export default useMarker
