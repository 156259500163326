import React from 'react'
import { classes } from '../../..'

export const Separator = React.memo(props => {
  const { label } = props

  const { wrapper: wrapperClass } = classes.configEntry.questionnaire.separator
  return <div className={wrapperClass}>{label}</div>
})

Separator.displayName = 'Wizard-Configuration-Questionnaire-Separator'

export default Separator
