import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { UUID_REPLACE_ALL } from '___types'
import { VIEWPORT_SIZE, BellProps, className } from './index'

const ZOOM = 1.2

const Bell: FunctionComponent<BellProps> = React.memo(props => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  //@ts-ignore
  const maskId = useMemo(() => uuid().replaceAll(UUID_REPLACE_ALL, ''), [])

  const extras = useMemo(() => props.extras?.split('+').filter(s => s) || [], [props.extras])

  return (
    <svg className={`${className} Bell`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          {extras.includes('exclamation') ? (
            <g>
              <line x1="50" x2="50" y1="35" y2="55" stroke="black" strokeWidth="8" strokeLinecap="round" fill="none" />
              <circle cx="50" cy="68" r="4" stroke="none" fill="black" />
            </g>
          ) : null}
        </mask>
      </defs>
      <g>
        <circle cx="50" cy="20" r="5" stroke="none" fill="currentColor" />
        <path d="M 50 20 c 30 5 10 30 30 50 v 5 h -60 v -5 c 20 -20 0 -45 30 -50 Z" stroke="none" fill="currentColor" mask={`url(#${maskId})`} />
        <circle cx="50" cy="75" r="10" stroke="none" fill="currentColor" mask={`url(#${maskId})`} />
        {extras.includes('jitter') ? (
          <g>
            <path d="M 70 20 c 5 2.5 7.5 5 10 10" stroke="currentColor" strokeWidth="5" strokeLinecap="round" fill="none" />
            <path d="M 30 20 c -5 2.5 -7.5 5 -10 10" stroke="currentColor" strokeWidth="5" strokeLinecap="round" fill="none" />
          </g>
        ) : null}
      </g>
    </svg>
  )
})

export { Bell }
export default Bell
