import { ITime, IDocumentFormat, IDocumentAuthor, SharedWithItem, FolderItem } from 'types'
import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react'
import { DateTime, Settings } from 'luxon'

import { Icon } from 'components/common'
import { SharedThrough } from './FileComponent.styles'

import './style.css'
import { useTranslation } from 'react-i18next'

import { classes } from '.'
import { DOCXDocument, Pdf, Signature } from 'assets/svgIconComponents'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'

import Premium from 'assets/icons/Premium'

interface Props {
  title: string
  id: string
  edited: ITime
  renameActive?: boolean
  templateName?: string
  type?: IDocumentFormat
  sharedAuthor?: IDocumentAuthor | null
  isUpToDateWithTheLatestTemplateVersion?: boolean
  sharedWith?: SharedWithItem[]
  currentFolderType?: FolderItem['type']
  sharingEnabled?: boolean
  isPremium?: boolean
  isCasus?: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>
  onContextMenu?: React.MouseEventHandler<HTMLDivElement>
  handleUpdateName?: (docId: string, newName: string) => void
  isDownloadable?: boolean
  isLoading?: boolean
  signatureStatus?: string
  isSelected?: boolean
  menuItems?: IPopupMenuItem[]
  children?: React.ReactNode
  optimistic?: boolean
}

const FileComponent: React.FC<Props> = ({
  id,
  title,
  edited,
  renameActive,
  type,
  sharedAuthor,
  sharedWith,
  currentFolderType,
  sharingEnabled,
  isPremium = false,
  onClick,
  handleUpdateName,
  isLoading = false,
  signatureStatus = null,
  isSelected = false,
  children = null,
  optimistic = false,
}) => {
  const [name, setName] = useState(title)
  const [focused, setFocused] = useState(false)
  const [showShared, setShowShared] = useState(false)
  const inputEl = useRef<HTMLTextAreaElement>(null)
  const { i18n } = useTranslation()
  Settings.defaultLocale = i18n.language.replace('_', '-')

  useEffect(() => {
    if (title) setName(title)
  }, [title])
  // const showDot = isUpToDateWithTheLatestTemplateVersion === false

  const handleFocusEl = useCallback(() => {
    if (inputEl.current) {
      setTimeout(() => {
        if (inputEl.current) {
          inputEl.current.focus()
          setFocused(true)
        }
      }, 1)
    }
  }, [inputEl])

  useEffect(() => {
    if (renameActive) handleFocusEl()
  }, [handleFocusEl, renameActive])

  useEffect(() => {
    if (focused && inputEl.current) {
      inputEl.current.select()
    }
  }, [focused])

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (renameActive) return
    onClick(e)
  }

  const handleEnterClick = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.keyCode === 13 && handleUpdateName) handleUpdateName(id, name)
    setFocused(false)
  }

  const handleNameBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    if (handleUpdateName) handleUpdateName(id, name)
    inputEl.current?.scroll(0, 0)
    setFocused(false)
  }

  const onIconHover = () => {
    if (!showShared && sharedAuthor) setShowShared(true)
  }

  const onIconOut = () => {
    if (showShared) setShowShared(false)
  }

  return (
    <div id={id} className={classes.thumbnail.wrapper} data-loading={isLoading} data-selected={isSelected} data-optimistic={optimistic || undefined}>
      <div className={classes.thumbnail.selectButton} onClick={handleClick} />
      {signatureStatus && (
        <div className={classes.thumbnail.signaturesTag.wrapper} data-status={signatureStatus}>
          <div className={classes.thumbnail.signaturesTag.icon}>
            <Signature />
          </div>
        </div>
      )}
      <div className={classes.thumbnail.preview}>
        <DOCXDocument data-loading={isLoading} />
      </div>
      {children}
      <div className={classes.thumbnail.base.wrapper}>
        {!renameActive ? (
          <pre className={classes.thumbnail.base.title}>{title}</pre>
        ) : (
          <textarea
            ref={inputEl}
            className={classes.thumbnail.base.title}
            value={name}
            onBlur={handleNameBlur}
            onKeyDown={handleEnterClick}
            onChange={e => setName(e.target.value)}
          />
        )}
        <div className={classes.thumbnail.base.relativeTime}>
          {(type === 'pdf' || signatureStatus) && (
            <div className={classes.thumbnail.base.pdfIcon}>
              <Pdf />
            </div>
          )}
          {DateTime.fromMillis(edited?._seconds * 1000).toRelative()}
          {isPremium && (
            <div className={classes.thumbnail.base.premiumIcon}>
              <Premium />
            </div>
          )}
        </div>
      </div>
      {currentFolderType === 'shared' || (currentFolderType === 'my' && sharedWith?.length && sharingEnabled) ? (
        <Fragment>
          {showShared && sharedAuthor && currentFolderType === 'shared' && (
            <SharedThrough>
              Geteilt durch:
              <br />
              {`${sharedAuthor.firstName} ${sharedAuthor.lastName}`}
            </SharedThrough>
          )}
          {showShared && !!sharedWith?.length && currentFolderType === 'my' && (
            <SharedThrough>
              Geteilt mit:
              <br />
              {sharedWith.map((sh, i) => (
                <Fragment key={i}>
                  {`${sh.firstName} ${sh.lastName}`}
                  {i < sharedWith.length - 1 ? <br /> : null}
                </Fragment>
              ))}
            </SharedThrough>
          )}
          <Icon iconName="shared_with" style={{ position: 'absolute', bottom: 16, right: 16 }} onMouseEnter={onIconHover} onMouseLeave={onIconOut} />
        </Fragment>
      ) : null}
    </div>
  )
}

export default FileComponent
