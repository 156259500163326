import { RadioStandardFormProps } from './RadioStandardForm.types'
import React from 'react'
import { Container, Heading, Content } from './QuestionRadio.styles'
import { Tabs } from 'components/common'
import ConnectSection from './ConnectSection'
import ReplaceSection from './ReplaceSection'
import { forms as content } from 'pages/Authenticated/Template/constants/content'
import questionTypes from 'constants/question-types'
import { useTranslation } from 'react-i18next'

const RadioStandardForm: React.FC<RadioStandardFormProps> = ({
  handleRadioFormChange,
  selectedQuestionType,
  currentSelectionId,
  ...otherProps
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Tabs
        tabItems={content.radioFormTabs}
        handleTabChange={handleRadioFormChange}
        currentTab={selectedQuestionType}
      />
      <Heading variant='h5'>{t(content.heading)}</Heading>
      <Content>
        {selectedQuestionType === questionTypes.RADIO_LINK && (
          <ConnectSection currentSelectionId={currentSelectionId} {...otherProps} />
        )}
        {selectedQuestionType === questionTypes.RADIO_REPLACEMENT && (
          <ReplaceSection {...otherProps} />
        )}
      </Content>
    </Container>
  )
}

export default RadioStandardForm
