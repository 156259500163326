import React from 'react'
import useStore from '___store'

import { CASUS_CLASSES } from 'Wizard/constants'
import { classes } from '../index'
import Page from './Page'

const Header = React.memo(({ id }) => {
  const { headerFooterLayoutById: { paper, orientation, margins: { top, bottom, left, right } = {} } = {} } = useStore(
    `selectHeaderFooterLayoutById[${id}]`
  )

  const layoutClass = `${paper}-${orientation}-${top}-${bottom}-${left}-${right}`.replaceAll('.', '_')
  const className = [layoutClass, CASUS_CLASSES.section, classes.section.wrapper].join(' ')

  return (
    <>
      <div id={id} className={className}>
        <Page headerFooterId={id} />
      </div>
      <div className={classes.section.separator} />
    </>
  )
})

Header.displayName = 'Wizard-Editor-Header'

export default Header
