import { AppState } from '.'

export type SetDocumentsFolderFilterPayload = string | string[]
const setDocumentsFolderFilter = (state: AppState, payload: SetDocumentsFolderFilterPayload): AppState => {
  const index = state.documents.folderFilter.indexOf(typeof payload === 'string' ? payload : payload[0])
  const resultingFilter = (index === -1 ? [] : state.documents.folderFilter.slice(0, index)).concat(payload)
  if (!payload || resultingFilter.join('; ') === state.documents.folderFilter.join('; ')) return state
  return Object.assign(state.documents, { folderFilter: resultingFilter, selected: null }) && Object.assign({}, state)
}

export type SetDocumentsFilterPayload = string | string[]
const setDocumentsFilter = (state: AppState, payload: SetDocumentsFilterPayload): AppState => {
  if (typeof payload === 'string') {
    const stringPayloadFilterArray = payload.split(/[+-]/).filter(s => s)
    if (state.documents.filter.join('+') === stringPayloadFilterArray.join('+')) return state
    return Object.assign(state.documents, { filter: stringPayloadFilterArray }) && Object.assign({}, state)
  }
  if (!Array.isArray(payload)) return state
  const [add, remove] = payload.reduce(
    (result, filter) => {
      const index = ['+', '-'].indexOf(filter.slice(0, 1))
      if (index !== -1) result[index].push(filter.slice(1))
      return result
    },
    [[], []] as [string[], string[]]
  )

  const removed = state.documents.filter.filter(filter => !remove.includes(filter))
  let update = removed.length !== state.documents.filter.length
  const added = removed.concat(Array.from(new Set(add)))
  update = update || added.length !== state.documents.filter.length

  if (!update) return state
  Object.assign(state.documents, { filter: added })
  return Object.assign({}, state)
}

export type SetSelectedDocumentIdPayload = string
const setSelectedDocumentId = (state: AppState, payload: SetSelectedDocumentIdPayload): AppState =>
  !payload || payload === state.documents.selected ? state : Object.assign(state.documents, { selected: payload }) && Object.assign({}, state)

const deselectAllDocuments = (state: AppState): AppState =>
  !state.documents.selected ? state : Object.assign(state.documents, { selected: null }) && Object.assign({}, state)

export { setDocumentsFolderFilter, setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments }
