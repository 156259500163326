import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router'

import { MainLayoutContentProps, mainLayoutContentClasses as classes } from '../..'
import { Documents, Templates } from './Content.components'

export const Content: FunctionComponent<MainLayoutContentProps> = React.memo(() => {
  return (
    <section className={classes.wrapper}>
      <Switch>
        <Route path="/documents">
          <Documents />
        </Route>
        <Route path="/templates">
          <Templates />
        </Route>
      </Switch>
    </section>
  )
})

Content.displayName = 'MainLayout-Content'

export default Content
