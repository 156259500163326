import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, { SetDocumentsFolderFilterAction, DocumentsFolderFilterSelector, SetDocumentsFilterAction } from '___store'

import { Folder, DOCUMENTS_FILTERS, DOCUMENT_DIRECTORY } from '___types'
import { useDocumentList } from '___hooks'
import { generateFolderStructure } from 'utilities/helpers'
import { Spinner } from 'assets/svgIconComponents'
import { FolderTree } from 'components/CasusComponents'
import { MainLayoutLeftPaneDocumentsFoldersProps } from '../../../..'

type UseStoreHookResultType = {
  documentsFolderFilter: DocumentsFolderFilterSelector
  setDocumentsFolderFilter: SetDocumentsFolderFilterAction
  setDocumentsFilter: SetDocumentsFilterAction
}

export const Folders: FunctionComponent<MainLayoutLeftPaneDocumentsFoldersProps> = () => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.leftPane.documents' })
  const { documentsFolderFilter, setDocumentsFolderFilter, setDocumentsFilter } = useStore(
    'selectDocumentsFolderFilter',
    'setDocumentsFolderFilter',
    'setDocumentsFilter'
  ) as UseStoreHookResultType

  const {
    folders: { data: folders, isLoading },
  } = useDocumentList()

  const folderStructure = useMemo(() => {
    const result = folders ? generateFolderStructure(folders) : ({ children: {} } as Folder)
    const mine = Object.assign(result.children?.mine || { children: {} }, { id: DOCUMENTS_FILTERS.MINE, label: translate('mine') })
    Object.assign(result.children, { mine })
    const shared = Object.assign(result.children?.shared || { children: {} }, { id: DOCUMENTS_FILTERS.SHARED, label: translate('shared') })
    Object.assign(result.children, { shared })
    return result
  }, [folders, translate]) as Folder

  if (isLoading)
    return (
      <span>
        <Spinner />
        Loading Folders
      </span>
    )

  return (
    <>
      <FolderTree
        folderStructure={folderStructure.children.mine}
        directory={DOCUMENT_DIRECTORY}
        onClick={(_, fullPath) => {
          setDocumentsFolderFilter(fullPath.split('.'))
          setDocumentsFilter(['-status:trashed'])
        }}
        activeId={documentsFolderFilter.split('.').slice(-1)[0]}
        activePath={documentsFolderFilter}
      />
      <FolderTree
        folderStructure={folderStructure.children.shared}
        directory={DOCUMENT_DIRECTORY}
        onClick={(_, fullPath) => {
          setDocumentsFolderFilter(fullPath.split('.'))
          setDocumentsFilter(['-status:trashed'])
        }}
        activeId={documentsFolderFilter.split('.').slice(-1)[0]}
        activePath={documentsFolderFilter}
      />
    </>
  )
}

Folders.displayName = 'MainLayout-LeftPane-Documents-Folders'

export default Folders
