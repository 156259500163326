import { IConfirmationPopupContent, IStep } from "types";

export const steps: IStep[] = [
  { step: 1, label: "select_document" },
  { step: 2, label: "answer_questions" },
  { step: 3, label: "use_document" },
];

export const onBoardingSteps: IStep[] = [
  { step: 1, label: "select_a_document" },
  { step: 2, label: "answer_q_step_by_step" },
  { step: 3, label: "download_share_document" },
];

export const onBoardingHeading = "create_professional_legal_documents";
export const headingCreate = "create_document";
export const headingEdit = "edit_document";
export const headingSaving = "document_being_saved";

export const lastStepContent = {
  heading: "document_has_been_created",
  headingEdited: "document_has_been_changed",
  headingDownload: "document_is_being_downloaded",
  // primaryButton: 'Vorschau & Herunterladen',
  primaryButton: "save",
  secondaryButton: "to_filing_cabinet",
};

export const POPUP_ON_CLOSE_IF_EDITED_DG: IConfirmationPopupContent = {
  heading: "question_save_changes",
  status: "warning",
  primaryButtonText: "yes",
  secondaryButtonText: "no",
};

export const POPUP_ON_CLOSE_IF_CREATED_DG: IConfirmationPopupContent = {
  heading: "question_new_document_previous_answers",
  status: "warning",
  primaryButtonText: "yes",
  secondaryButtonText: "no",
};
