import React, { FunctionComponent } from 'react'
import { LinkProps, classes } from '.'

const Link: FunctionComponent<LinkProps> = React.memo(({ properties }) => {
  const { text, href } = properties
  return (
    <a className={classes.wrapper} href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  )
})

Link.displayName = 'Casus-Components-Link'

export default Link
