import React, { FunctionComponent } from 'react'

import { ContentProps } from '.'
import { classes } from '..'

export const Content: FunctionComponent<ContentProps> = React.memo(props => {
  const { render, closeCallback, cancelCallback, concludeCallback, context } = props

  if (render === false) return null
  return (
    <div className={classes.content}>
      {typeof render === 'string' ? (
        <span>{render}</span>
      ) : typeof render === 'function' ? (
        render(closeCallback, cancelCallback, concludeCallback, context)
      ) : (
        render
      )}
    </div>
  )
})

Content.displayName = 'Modal-Content'

export default Content
