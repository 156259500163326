import { QuestionProps } from './Question.types'
import React, { Fragment } from 'react'
import { QuestionCheckbox, QuestionRadio, QuestionText, QuestionNumber } from './QuestionTypes'
import { Heading, Label } from './Question.styles'
import {
  QUESTION_TYPE_TEXT_BOX,
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_CHECKBOX,
  QUESTION_TYPE_RADIO_LINK,
  QUESTION_TYPE_RADIO_REPLACEMENT,
  QUESTION_TYPE_NUMBER,
} from 'constants/question-types'
import { DatePicker } from 'components/common'

const Question: React.FC<QuestionProps> = ({ question, value, questionIndex, error, ...otherProps }) => {
  const renderContent = () => {
    switch (question?.type) {
      case QUESTION_TYPE_TEXT_BOX:
        return <QuestionText question={question} value={!value ? '' : (value.value as string)} {...otherProps} />
      case QUESTION_TYPE_DATE:
        return (
          <DatePicker
            value={!value ? null : (value.value as any)}
            {...otherProps}
            handleChange={val =>
              // @ts-ignore
              otherProps.handleChange(!val ? '' : val)
            }
          />
        )
      case QUESTION_TYPE_CHECKBOX:
        return <QuestionCheckbox question={question} value={!value ? [] : (value.value as string[])} {...otherProps} />
      case QUESTION_TYPE_RADIO_LINK:
      case QUESTION_TYPE_RADIO_REPLACEMENT:
        return <QuestionRadio question={question} value={!value ? [] : (value.value as string[])} {...otherProps} />
      case QUESTION_TYPE_NUMBER:
        return <QuestionNumber question={question} value={!value ? '' : (value.value as string)} error={error} {...otherProps} />
      default:
        return null
    }
  }

  const renderQuestion = () => {
    const splitted = question?.questionText.split(/\r?\n/)
    return (
      <Heading variant="h4">
        {questionIndex + 1}.{' '}
        {splitted?.map((t, i) => {
          if (i === splitted?.length - 1) {
            return <Fragment key={i}>{t}</Fragment>
          }
          return (
            <Fragment key={i}>
              {t}
              <br />
            </Fragment>
          )
        })}
      </Heading>
    )
  }
  return (
    <Fragment>
      <Label>{question?.tags[0]}</Label>
      {renderQuestion()}
      {renderContent()}
    </Fragment>
  )
}

export default Question
