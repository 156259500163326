import { IConfirmationPopupContent } from 'types'

export const POPUP_ON_TEMPLATE_EDIT_FINISH_WQ: IConfirmationPopupContent = {
  heading: 'warning_current_question_saved',
  status: 'warning',
  primaryButtonText: 'Okay',
  secondaryButtonText: 'return',
}

export const POPUP_ON_TEMPLATE_EDIT_CLOSE: IConfirmationPopupContent = {
  heading: 'question_save_and_exit',
  status: 'warning',
  primaryButtonText: 'yes_save_and_exit',
  secondaryButtonText: 'not_save',
}
