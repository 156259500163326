import React, {
  forwardRef,
  // useCallback,
  // useEffect,
  // useRef,
  useState,
} from 'react'
import useStore from '___store'

// import back from '../../../assets/icons/arrow-left.svg'

const classes = {
  wrapper: 'Wizard-Footer-wrapper',
  button: {
    wrapper: 'Wizard-Footer-Button-wrapper',
  },
}

const Button = React.memo(
  forwardRef((props, ref) => {
    const { disabled = false, handler, render = '', tabIndex = 0 } = props
    return (
      <button
        ref={ref}
        className={classes.button.wrapper}
        disabled={disabled}
        type="button"
        tabIndex={tabIndex}
        onClick={handler}
        data-disabled={String(disabled)}
      >
        {render}
      </button>
    )
  })
)

Button.displayName = 'Button'

export const Footer = React.memo(props => {
  const { onFinishHandler } = props
  const [
    disabled,
    // setDisabled
  ] = useState(false)
  const {
    configuring,
    // setConfiguring,
    dataStructure = {},
    locations = {},
    questions = [],
    questionLayout = [],
    answers = [],
  } = useStore(
    'selectConfiguring',
    // 'setConfiguring',
    'selectDataStructure',
    'selectLocations',
    'selectQuestions',
    'selectQuestionLayout',
    'selectAnswers'
  )

  // console.log({
  //   onFinishHandler,
  //   dataStructure,
  //   locations,
  //   questions,
  //   questionLayout,
  // })

  // const buttonRef = useRef()

  // const toggleConfiguring = useCallback(() => {
  //   setDisabled(true)
  //   setConfiguring(configuring ? null : 'final')
  // }, [setConfiguring, configuring])

  // useEffect(() => {
  //   const refCurrent = buttonRef.current
  //   if (refCurrent) {
  //     const transitionHandler = () => setDisabled(false)
  //     refCurrent.addEventListener('transitionend', transitionHandler)
  //     return () => refCurrent.removeEventListener('transitionend', transitionHandler)
  //   }
  // }, [])

  // console.log('FOOTER ID: ', id)

  return (
    <section data-shift={Boolean(configuring)} className={classes.wrapper}>
      {/* <Button
        ref={buttonRef}
        disabled={disabled}
        handler={toggleConfiguring}
        render={Boolean(configuring) ? <img src={back} alt="Back!" /> : <span>Configure</span>}
      /> */}
      <Button
        disabled={disabled}
        handler={() =>
          onFinishHandler({
            dataStructure,
            locations,
            questions,
            questionLayout,
            answers,
          })
        }
        // tabIndex={configuring !== 'final' ? -1 : 0}
        // render={configuring === 'final' ? 'Finish' : 'Save'}
        render="Finish!"
      />
    </section>
  )
})

Footer.displayName = 'Wizard-Footer'

export default Footer
