import { styled, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { scrollBarStylesLight } from "constants/common-styles";

interface WithIsDocument {
  isdocument: number;
  theme: Theme;
  signaturestatus?: string | null;
  isselected?: number;
}

export const Body = styled(Box)(({ theme, isdocument, signaturestatus, isselected }: WithIsDocument) => ({
  width: 256,
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  cursor: "pointer",
  position: "relative",
  border: isselected ? "3px solid " + theme.colorz.secondary.main :"1px solid " + theme.colorz.brand.main,
  borderBottomWidth: 2,
  height: isdocument ? 364 : 342,
  minHeight: isdocument ? 364 : 342,
}));

export const Preview = styled(Box)({});

export const PreviewWrapper = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid " + theme.colorz.brand.main,
}));

export const Cover = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  height: 228,
  color: theme.colorz.secondary[300],
  fontSize: "80%",
  textAlign: "center",
  padding: theme.spacing(2),
  overflowY: "hidden",
  "& img": {
    width: "100%",
    marginTop: theme.spacing(-1),
  },
}));

export const CoverLoading = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  height: 228,
  color: theme.colorz.secondary[300],
  fontSize: "80%",
  textAlign: "center",
  padding: theme.spacing(2),
  overflowY: "hidden",
  "& img": {
    width: "80%",
    marginTop: theme.spacing(1),
  },
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  color: theme.colorz.secondary.main,
  fontSize: 15,
  fontFamily: "inherit",
  lineHeight: 1.33,
  letterSpacing: 0.3,
  flexShrink: 1,
}));

export const Title = styled("textarea")(({ theme }) => ({
  fontSize: 15,
  fontFamily: "inherit",
  lineHeight: 1.33,
  letterSpacing: 0.3,
  color: theme.colorz.secondary.main,
  fontWeight: 600,
  // wordBreak: 'break-word',
  marginBottom: theme.spacing(2),
  // textOverflow: 'ellipsis',
  overflow: "hidden",
  // whiteSpace: 'nowrap',
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
  width: "100%",
  cursor: "pointer",
  resize: "none",
  "&:focus": {
    cursor: "auto",
  },
}));

export const Info = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const Time = styled(Box)(({ theme }) => ({
  color: theme.colorz.secondary[200],
  fontSize: 14,
}));

export const LoadingText = styled(Box)(({ theme }) => ({
  color: theme.colorz.secondary[100],
  fontSize: 14,
}));

export const TemplateName = styled(Typography)(({ theme }) => ({
  // display: 'flex',
  width: "100%",
  fontSize: 15,
  color: theme.colorz.secondary[200],
  marginTop: theme.spacing(-3),
  paddingLeft: theme.spacing(0.3),
}));

export const Details = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  paddingBottom: 8,
});

export const SharedThrough = styled(Box)(({ theme }) => ({
  lineHeight: 1.2,
  letterSpacing: 0.19,
  boxShadow: "0px 4px 28px rgba(0, 0, 0, 0.1)",
  borderRadius: 2,
  padding: theme.spacing(1, 1.5),
  position: "absolute",
  bottom: 50,
  right: -30,
  maxHeight: 200,
  overflowY: "auto",
  color: theme.colorz.secondary[300],
  backgroundColor: "#fff",
  ...scrollBarStylesLight(theme),
}));

export const Dot = styled(Box)(({ theme }) => ({
  height: 12,
  width: 12,
  borderRadius: "50%",
  backgroundColor: "#FFCB9D",
  position: "absolute",
  top: -6,
  right: -6,
}));

export const SignatureTag = styled(Typography)(({ theme, signaturestatus }: WithIsDocument) => ({
  marginLeft: theme.spacing(1),
  border: signaturestatus === 'OPEN' ? "1px solid #fef9ee" : signaturestatus === 'SIGNED' ? "1px solid #e4fcea" : signaturestatus === 'DECLINED' ? "1px solid #D9512C" :"1px solid " + theme.colorz.brand.main,
  borderRadius: "10px",
  padding: theme.spacing(0.5, 1),
  fontSize: 13,
  backgroundColor: signaturestatus === 'OPEN' ? "#fef9ee" : signaturestatus === 'SIGNED' ? "#e4fcea" : signaturestatus === 'DECLINED' ? "#faeae6" : "#fff",
  color: signaturestatus === 'OPEN' ? "#8b6208" : signaturestatus === 'SIGNED' ? "#0e822b" : signaturestatus === 'DECLINED' ? "#ac3e22" : "#fff",
}));

