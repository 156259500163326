import React, { FunctionComponent, useState, RefCallback, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStore, { DocumentsFilterSelector, DocumentsFolderFilterSelector, SetDocumentsFilterAction, SetDocumentsFolderFilterAction } from '___store'

import { DOCUMENT_DIRECTORY, DOCUMENT_STATUSES, DOCUMENTS_FILTERS, DocumentsFilter } from '___types'
import { useDocument, useDocumentList } from '___hooks'
import { Bin, Cross, Filter, Folder, Home, Plus, Signature, Upload } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { Create, classes as folderClasses } from 'components/CasusComponents/FolderTree'
import useModal from 'Modal'
import { Folders } from './Documents.components'
import { MainLayoutLeftPaneDocumentsProps, mainLayoutLeftPaneDocumentsClasses as classes } from '../../..'

type UseStoreHookResultType = {
  documentsFolderFilter: DocumentsFolderFilterSelector
  documentsFilter: DocumentsFilterSelector
  setDocumentsFolderFilter: SetDocumentsFolderFilterAction
  setDocumentsFilter: SetDocumentsFilterAction
}

export const Documents: FunctionComponent<MainLayoutLeftPaneDocumentsProps> = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.leftPane.documents' })
  const history = useHistory()
  const { documentsFolderFilter, documentsFilter, setDocumentsFolderFilter, setDocumentsFilter } = useStore(
    'selectDocumentsFolderFilter',
    'selectDocumentsFilter',
    'setDocumentsFolderFilter',
    'setDocumentsFilter'
  ) as UseStoreHookResultType
  const [uploadInput, setUploadInput] = useState<HTMLInputElement>()
  const uploadInputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setUploadInput(node), [])

  const open = useModal({ header: 'New folder', footer: false, className: folderClasses.modal.wrapper })
  const { trashed, createFolder } = useDocumentList()
  const { uploadPDF } = useDocument()

  const uploadPDFRender = useMemo(
    () => (
      <div className={classes.uploadPdf}>
        <input
          ref={uploadInputRef}
          type="file"
          accept=".pdf"
          onInput={event => {
            const file = (event.target as HTMLInputElement).files?.[0]
            if (file) return uploadPDF(file)
          }}
        />
        <Button onClick={() => uploadInput?.click()}>
          <Upload />
          {/* Upload .pdf */}
        </Button>
      </div>
    ),
    [uploadInput, uploadInputRef, uploadPDF]
  )

  const parentFolderId = useMemo(() => {
    const lastFolderId = documentsFolderFilter.split('.').slice(-1)[0]
    return Object.values(DOCUMENTS_FILTERS).includes(lastFolderId as DocumentsFilter) ? '' : lastFolderId
  }, [documentsFolderFilter])

  const atHome = useMemo(
    () => !(documentsFolderFilter || documentsFilter.split('+').includes('status:trashed')),
    [documentsFolderFilter, documentsFilter]
  )

  const inBin = useMemo(() => documentsFilter.split('+').includes('status:trashed'), [documentsFilter])

  const [signaturePendingActive, signedActive] = useMemo(() => {
    const splitDocumentsFilter = documentsFilter.split('+')
    const pending = splitDocumentsFilter.some(filter => filter.replace(/^status:/, '') === DOCUMENT_STATUSES.PENDING_SIGNATURE) || undefined
    const signed = splitDocumentsFilter.some(filter => filter.replace(/^status:/, '') === DOCUMENT_STATUSES.SIGNED) || undefined
    return [pending, signed]
  }, [documentsFilter])

  return (
    <div className={classes.wrapper}>
      <Button className={classes.newDocument} onClick={() => history.push(`/${DOCUMENT_DIRECTORY}`)} tertiary>
        <Plus />
        {translate('new')}
      </Button>
      <div className={classes.extras}>
        {uploadPDFRender}
        <Button
          className={classes.newFolder}
          onClick={() => open({ content: close => <Create createCallback={name => createFolder(name, parentFolderId)} closeCallback={close} /> })}
        >
          <Folder function="add" />
          {/* New folder */}
        </Button>
      </div>
      <Button
        className={classes.home}
        onClick={() => {
          setDocumentsFolderFilter([])
          setDocumentsFilter(['-status:trashed'])
        }}
        onClickBlur
        dataSet={{ active: atHome ? true : undefined }}
        transparent
      >
        <Home />
        {translate('home')}
      </Button>
      <Folders />
      <Button
        className={classes.bin}
        onClick={() => {
          setDocumentsFolderFilter([])
          setDocumentsFilter('status:trashed')
        }}
        loading={trashed.isLoading}
        disabled={trashed.isLoading}
        onClickBlur
        dataSet={{ active: inBin ? true : undefined }}
        transparent
      >
        <Bin />
        {translate('bin')}
      </Button>
      <div className={classes.quickAccess}>
        <Button
          className={classes.signaturePending}
          onClick={() =>
            setDocumentsFilter(
              signaturePendingActive ? [`-status:${DOCUMENT_STATUSES.PENDING_SIGNATURE}`] : `status:${DOCUMENT_STATUSES.PENDING_SIGNATURE}`
            )
          }
          onClickBlur
          dataSet={{ active: signaturePendingActive }}
          transparent
        >
          <Signature />
          {translate('signature-pending')}
          {signaturePendingActive ? <Cross /> : <Filter />}
        </Button>
        <Button
          className={classes.signed}
          onClick={() => setDocumentsFilter(signedActive ? [`-status:${DOCUMENT_STATUSES.SIGNED}`] : `status:${DOCUMENT_STATUSES.SIGNED}`)}
          onClickBlur
          dataSet={{ active: signedActive }}
          transparent
        >
          <Signature />
          {translate('signature-signed')}
          {signedActive ? <Cross /> : <Filter />}
        </Button>
      </div>
    </div>
  )
})

Documents.displayName = 'MainLayout-LeftPane-Documents'

export default Documents
