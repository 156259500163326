import ModalPopover from "./ModalPopover";

const classes = {
    container: {
        wrapper: "ModalPopover-Container-wrapper",
        exit: "ModalPopover-Exit-wrapper",
    }
}

export { classes }
export default ModalPopover;