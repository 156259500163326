import React, { useMemo } from 'react'

import { classes } from './index'

const ButtonGroup = React.memo(({ className: cn, collapsed, children, disabled }) => {
  const className = useMemo(() => [classes.wrapper, cn].filter(cn => cn).join(' '), [cn])
  return (
    <div className={className} data-collapsed={collapsed || undefined} data-disabled={disabled || undefined}>
      {children}
    </div>
  )
})

ButtonGroup.displayName = 'ButtonGroup'

export default ButtonGroup
