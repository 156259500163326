import { FocusEventHandler } from 'react'

import RadioButton from './RadioButton'

import './style.scss'

const classes = { wrapper: 'Casus-Components-RadioButton-wrapper' } as const

export type RadioButtonProps = {
  id?: string
  className?: string
  value?: boolean
  defaultValue?: boolean
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  disabled?: boolean
  dataSet?: Record<keyof any, unknown>
  tabbable?: boolean
  title?: string
  label?: JSX.Element
  onClickBlur?: boolean
  onClick?: (state: boolean) => void
  onFocus?: FocusEventHandler<HTMLButtonElement>
}

export { RadioButton, classes }
export default RadioButton
