import React from 'react'
import useStore from '___store'

import { ButtonGroup, Button } from 'components'
import { PlaceholderInput, TextInput, ValueInput, Limits } from './components'
import { classes } from '../../index'

const OptionConfiguration = React.memo(({ id }) => {
  const { optionById: { value = '', valueType } = {}, concludeConfiguring } = useStore(`selectOptionById[${id}]`, 'concludeConfiguring')

  const {
    footer: footerClass,
    optionConfiguration: { wrapper: wrapperClass },
  } = classes.configEntry

  return (
    <>
      <div className={wrapperClass}>
        <PlaceholderInput id={id} />
        {value ? <ValueInput id={id} /> : null}
        {value ? <TextInput id={id} /> : null}
        {valueType === 'number' ? <Limits id={id} /> : null}
      </div>
      <div className={footerClass}>
        <ButtonGroup>
          <Button onClick={concludeConfiguring} colorScheme="primary">
            Conclude
          </Button>
        </ButtonGroup>
      </div>
    </>
  )
})

OptionConfiguration.displayName = 'Wizard-Configuration-OptionConfiguration'

export default OptionConfiguration
