import React, { useMemo, useCallback } from 'react'
import { DateTime } from 'luxon'
import useStore from '___store'

import { VerticalEllipsis } from 'assets/svgIconComponents'
import { Input, Select } from 'components'
import TagStrip from '../TagStrip'
import ActionButton from './ActionButton'
import { classes } from '../../../../../index'
import SubQuestion from './SubQuestion'

const formatValue = {
  string: value => value,
  number: value => Number(value),
  date: value => DateTime.fromISO(value).toFormat('yyyy-MM-dd'),
  'date-time': value => DateTime.fromISO(value).toFormat("yyyy-MM-dd'T'HH:mm"),
}

const inputTypeMap = {
  string: 'text',
  number: 'number',
  date: 'date',
  'date-time': 'datetime-local',
}

const QuestionOption = React.memo(({ id, optionGroupId, questionId }) => {
  const {
    optionById: { text = '', value = '', valueType = '', type = '' } = {},
    questionOptionSubQuestionIds = '',
    updateOption,
  } = useStore(`selectOptionById[${id}]`, `selectQuestionOptionSubQuestionIds[${id}]`, 'updateOption')
  const subQuestionsIds = useMemo(() => questionOptionSubQuestionIds.split('; ').filter(id => id), [questionOptionSubQuestionIds])
  const subQuestions = useMemo(
    () => subQuestionsIds.map(questionId => <SubQuestion key={`Option-${id}-SubQuestion-${questionId}`} id={questionId} />),
    [subQuestionsIds, id]
  )
  const {
    wrapper: wrapperClass,
    input: inputClass,
    actionSelect: actionSelectClass,
    subQuestionsDiv: subQuestionsDivClass,
  } = classes.configEntry.questionConfiguration.fragment.questionOptions.optionGroup.option

  const inputCallback = useCallback(
    property => input => updateOption(Object.assign({ optionId: id, optionGroupId, questionId }, { [property]: String(input) })),
    [updateOption, id, optionGroupId, questionId]
  )

  const input = useMemo(() => {
    if (type !== 'static') return null
    if (text)
      return (
        <Input
          className={inputClass}
          type="text"
          placeholder="Option text goes here (e.g. Option 1: assignment)"
          defaultValue={text}
          onChange={inputCallback('text')}
          needsConfirmation
          blurShowUnmodified
        />
      )
    const inputType = inputTypeMap[valueType]
    if (['string', 'number'].includes(valueType))
      return (
        <Input
          className={inputClass}
          type={inputType}
          defaultValue={formatValue[valueType](value)}
          onChange={inputCallback('value')}
          needsConfirmation
          blurShowUnmodified
        />
      )
    if (['date', 'date-time'].includes(valueType))
      return <Input className={inputClass} type={inputType} value={formatValue[valueType](value)} onChange={inputCallback('value')} />
  }, [type, valueType, inputClass, text, value, inputCallback])

  return (
    <div className={wrapperClass}>
      {input}
      <Select
        className={actionSelectClass}
        options={{ remove: null, configure: null, sub: null }}
        render={key => (
          <ActionButton
            key={`QuestionOption-${id}-ActionSelect-${key}`}
            button={key}
            optionId={id}
            optionGroupId={optionGroupId}
            questionId={questionId}
          />
        )}
        chooseMode
        chooseRender={<VerticalEllipsis />}
        noCaret
        directionPriority="horizontal"
        horizontalDirection="left"
        cornerAnchor={false}
      />
      <TagStrip questionId={questionId} optionId={id} />
      {subQuestions.length ? <div className={subQuestionsDivClass}>{subQuestions}</div> : null}
    </div>
  )
})

QuestionOption.displayName = 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-Option'

export default QuestionOption
