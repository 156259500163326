import ModalPopover from 'components/common/ModalPopover/ModalPopover'
import React from 'react'
import { classes } from '.'

import './style.scss'
import SignersListWithStatus from './components/SignersListWithStatus'
import MessageLabel from './components/MessageLabel'
import SecurityLabel from './components/SecurityLabel'

export const ViewSignatureRequestModal = React.memo(({ anchorEl, handleClose, signatureRequest, signDocumentId }) => {
  return (
    <ModalPopover
      className={classes.container.wrapper}
      isOpen={!!anchorEl}
      close={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'left', horizontal: 'top' }}
    >
      <div className={classes.container.wrapper}>
        <div className={classes.container.title}>Signature request</div>
        <SignersListWithStatus signatures={signatureRequest.signatures} signDocumentId={signDocumentId} signatureRequest={signatureRequest} />
        <MessageLabel message={signatureRequest.message} />
        <SecurityLabel signatureLevel={signatureRequest.quality ? signatureRequest.quality : 'QES'} />
      </div>
    </ModalPopover>
  )
})

