import React, { useMemo } from 'react'
import { classes } from '../../..'
import useStore from '___store'
import QuestionOption from './QuestionOption'
import QuestionListItem from './QuestionListItem'

const getLimitText = (minimum, maximum, length) => {
  const relevantMinimum = Math.max(Math.min(minimum, maximum), 0)
  const relevantMaximum = Math.min(Math.max(minimum, maximum), length)
  if (relevantMinimum && !relevantMaximum) return `Select ${relevantMinimum} or more option${String(relevantMinimum).slice(-1) !== '1' ? 's' : ''}.`
  if (relevantMinimum && relevantMaximum)
    return relevantMinimum === relevantMaximum
      ? `Select exactly ${relevantMinimum} option${String(relevantMinimum).slice(-1) !== '1' ? 's' : ''}.`
      : `Select between ${relevantMinimum} and ${relevantMaximum} option${String(relevantMaximum).slice(-1) !== '1' ? 's' : ''}.`
  if (!relevantMinimum && relevantMaximum) return `Select up to ${relevantMaximum} option${String(relevantMaximum).slice(-1) !== '1' ? 's' : ''}.`
  return undefined
}

export const QuestionOptionGroup = React.memo(({ id, questionId }) => {
  const {
    questionOptionGroupById: { label = '', options = [], select = 'single', minimum = 1, maximum = 1 } = {},
    questionOptionGroupSubQuestionIds = '',
  } = useStore(`selectQuestionOptionGroupById[${questionId},${id}]`, `selectQuestionOptionGroupSubQuestionIds[${id}]`)
  const children = useMemo(
    () =>
      options.map(({ id: optionId }) => (
        <QuestionOption
          key={`Questionnaire-question-${questionId}-questionOption-${id}-option-${optionId}`}
          id={optionId}
          optionGroupId={id}
          questionId={questionId}
          select={select}
          isStandalone={select === 'single' && options.length === 1}
        />
      )),
    [options, id, questionId, select]
  )
  const subQuestionIds = useMemo(() => questionOptionGroupSubQuestionIds.split('; ').filter(id => id), [questionOptionGroupSubQuestionIds])
  const subQuestions = useMemo(() => subQuestionIds.map(id => <QuestionListItem key={id} id={id} />), [subQuestionIds])
  const { wrapper: wrapperClass, limitText: limitTextClass } = classes.configEntry.questionnaire.questionListItem.optionGroup
  const limitText = getLimitText(minimum, maximum, options.length)
  return (
    <div className={wrapperClass} data-label={label || undefined}>
      {select === 'multi' && limitText ? <span className={limitTextClass}>{limitText}</span> : null}
      {children}
      {subQuestionIds.length ? subQuestions : null}
    </div>
  )
})

QuestionOptionGroup.displayName = 'Wizard-Configuration-Questionnaire-QuestionListItem-QuestionOptionGroup'

export default QuestionOptionGroup
