import { StandardModalProps, ModalTitleProps } from './StandardModal.types'
import React, { useRef } from 'react'
import { Icon } from 'components/common'
import { BtnTypes } from 'components/common/CustomButtonV2/CustomButton.types'
import { useTranslation } from 'react-i18next'

import { classes, RootModal } from '..'

const ModalTitle: React.FC<ModalTitleProps> = ({ children, disableCloseButton, onClose, disableOutsideClickClose }) => {
  const ref = useRef<HTMLButtonElement>(null)

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disableOutsideClickClose) {
      onClose(e)
    } else {
      if (e.currentTarget === ref.current) onClose(e) // TODO
    }
  }

  return (
    <div className={classes.content.title.wrapper}>
      <span className={classes.content.title.text}>{children}</span>
      {!disableCloseButton && (
        <button className={classes.content.title.close} onClick={handleClose}>
          <Icon iconName="close" />
        </button>
      )}
    </div>
  )
}

const StandardModal: React.FC<StandardModalProps> = ({
  open,
  primaryButtonDisabled,
  primaryButtonText,
  secondaryButtonDisabled,
  secondaryButtonText,
  disableCloseButton,
  title,
  children,
  buttonWidth = 200,
  status,
  disableOutsideClickClose,
  RootModalProps,
  contentContainerStyles,
  handleClose,
  handlePrimaryClick,
  handleSecondaryClick,
}) => {
  const hasActions = Boolean(handlePrimaryClick) || Boolean(handleSecondaryClick)
  const { t } = useTranslation()

  return (
    <RootModal open={open} onClose={handleClose}>
      <div className={classes.content.wrapper}>
        <ModalTitle onClose={handleClose} disableOutsideClickClose={disableOutsideClickClose} disableCloseButton={disableCloseButton}>
          {title ? title : ''}
        </ModalTitle>
        {children}
        {hasActions && (
          <div className={classes.footer.wrapper}>
            {handleSecondaryClick && secondaryButtonText && (
              <button
                className={classes.footer.button}
                data-type={status ? 'primary' : 'secondary'}
                onClick={handleSecondaryClick}
                data-disabled={secondaryButtonDisabled}
                disabled={secondaryButtonDisabled}
              >
                {t(secondaryButtonText)}
              </button>
            )}
            <button
              className={classes.footer.button}
              data-type={status ? (`outlined-${status}` as BtnTypes) : 'primary'}
              autoFocus
              disabled={primaryButtonDisabled}
              data-disabled={primaryButtonDisabled}
              onClick={handlePrimaryClick}
            >
              {primaryButtonText && t(primaryButtonText)}
            </button>
          </div>
        )}
      </div>
    </RootModal>
  )
}

export default StandardModal

