import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const classes = {
  wrapper: 'details-sidebar-section-container',
  container: 'info-container',
  title: 'title',
  titleInfo: 'title-info',
  titleDesc: 'title-desc',
  titleValue: 'title-value',
}

export const Informations = React.memo(({ type, author, createdAt, editedAt }) => {
  const { t } = useTranslation()
  const getName = author => {
    if (author) {
      return `${author.firstName} ${author.lastName}`
    }
    return 'Unknown'
  }
  return (
    <div className={classes.wrapper}>
      <dl className={classes.container}>
        <div className={classes.titleInfo}>
          <span className={classes.titleDesc}>{type === 'pdf' ? t('uploaded_by') : t('created_by')}</span>
          <span className={classes.titleValue}>{getName(author)}</span>
        </div>
        <div className={classes.titleInfo}>
          <span className={classes.titleDesc}>{type === 'pdf' ? t('uploaded') : t('created')}</span>
          <span className={classes.titleValue}>{moment(createdAt).format('D MMM gggg, HH:mm:ss ')}</span>
        </div>
        <div className={classes.titleInfo}>
          <span className={classes.titleDesc}>{t('last_modified')}</span>
          <span className={classes.titleValue}>{moment(editedAt).format('D MMM gggg, HH:mm:ss ')}</span>
        </div>
      </dl>
    </div>
  )
})

