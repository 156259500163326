import { ERR_MSG_DEFAULT } from 'constants/messages'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setAppMessage } from 'redux/actions'
import { MessageType } from 'types'

export const useToast = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const toast = useCallback(
    (type: MessageType, message: 'default_error' | string, duration?: number) => {
      const theMessage =
        message === 'default' && type === 'error' ? t(ERR_MSG_DEFAULT) : message
      dispatch(setAppMessage(type, theMessage, duration))
    },
    [dispatch, t]
  )
  return toast
}
