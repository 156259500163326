import React, { FunctionComponent, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import useStore, {
  TemplatesFolderFilterSelector,
  SelectedTemplateIdSelector,
  SetSelectedDocumentIdAction,
  SetTemplatesFolderFilterAction,
  TemplatesFilterSelector,
  // SetTemplatesFilterAction,
} from '___store'

import { BEFolder, IListTemplate, RecursiveRecord, TEMPLATE_DIRECTORY, TEMPLATES_FILTERS, TemplatesFilter } from '___types'
import { extractValueFromObject } from 'utilities/helpers'
import { useTemplateList, useUser } from '___hooks'
import { Document, HorizontalLines, VerticalEllipsis } from 'assets/svgIconComponents'
import { Breadcrumbs, Button, Column, Grid, Table, TemplateThumbnail } from 'components/CasusComponents'
import { MainLayoutContentTemplatesProps, mainLayoutContentTemplatesClasses as classes } from '../../..'

const VIEW_STYLES = ['card-view', 'list-view'] as const
const VIEW_ICONS = [
  <Document key="MainLayout-Content-Templates-Card-View" />,
  <HorizontalLines key="MainLayout-Content-Templates-List-View" />,
] as const

const filterCheck = (filters: string[], key: string, document: RecursiveRecord<string, unknown>) => {
  if (!filters.length) return true
  const value = extractValueFromObject(document, key)
  return filters.some(filter => filter === value)
}

type UseStoreHookResultType = {
  templatesFolderFilter: TemplatesFolderFilterSelector
  templatesFilter: TemplatesFilterSelector
  selectedTemplateId: SelectedTemplateIdSelector
  setTemplatesFolderFilter: SetTemplatesFolderFilterAction
  // setTemplatesFilter: SetTemplatesFilterAction
  setSelectedTemplateId: SetSelectedDocumentIdAction
}

export const Templates: FunctionComponent<MainLayoutContentTemplatesProps> = React.memo(() => {
  const history = useHistory()
  const [view, setView] = useState<(typeof VIEW_STYLES)[number]>(VIEW_STYLES[0])

  const {
    templatesFolderFilter,
    templatesFilter,
    selectedTemplateId,
    setTemplatesFolderFilter,
    // setTemplatesFilter,
    setSelectedTemplateId,
  } = useStore(
    'selectTemplatesFolderFilter',
    'selectTemplatesFilter',
    'selectSelectedTemplateId',
    'setTemplatesFolderFilter',
    // 'setTemplatesFilter',
    'setSelectedTemplateId'
  ) as UseStoreHookResultType

  const { data: { id: userId } = {} } = useUser()
  const {
    list: { data, isLoading, isFetching, error },
    trashed: { data: trashed },
    folders: { data: folders },
  } = useTemplateList()

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') return error
    if (error instanceof Error) return error.message
    return ''
  }, [error])

  const [initialFolderFilter, activeFolderFilter] = useMemo(() => {
    const filterArray = templatesFolderFilter.split('.').filter(s => s)
    return filterArray.slice(0, 1).concat(filterArray.slice(-1))
  }, [templatesFolderFilter])

  const unnestedData = useMemo(() => {
    if (!initialFolderFilter) return data || []
    if (initialFolderFilter === TEMPLATES_FILTERS.MINE) return (data || []).filter(({ authorId }) => authorId === userId)
    if (initialFolderFilter === TEMPLATES_FILTERS.SHARED)
      return (data || []).filter(({ sharedWith }) => sharedWith?.find(authorId => authorId === userId))
    if (initialFolderFilter === TEMPLATES_FILTERS.COMPANY) return (data || []).filter(({ companyShared }) => companyShared)
    return data || []
  }, [initialFolderFilter, data, userId])

  const folderFilteredData = useMemo(() => {
    if (!unnestedData.length) return []
    if (!activeFolderFilter) return unnestedData
    if (activeFolderFilter === TEMPLATES_FILTERS.MINE) return unnestedData.filter(({ category }) => !category)
    if (Object.values(TEMPLATES_FILTERS).includes(activeFolderFilter as TemplatesFilter)) return unnestedData
    return unnestedData.filter(({ category }) => category === activeFolderFilter)
  }, [unnestedData, activeFolderFilter])

  const filter = useMemo(() => templatesFilter.split(';').filter(s => s), [templatesFilter])

  const statusFilters = useMemo(
    () => filter.reduce((result, filter) => result.concat(filter.slice(0, 6) === 'status' ? filter.slice(7) : []), [] as string[]),
    [filter]
  )

  const filteredData = useMemo(
    () =>
      statusFilters.includes('trashed')
        ? trashed || []
        : folderFilteredData?.reduce(
            (result, datum) => result.concat(filterCheck(statusFilters, 'status', datum as { [K in keyof IListTemplate]: unknown }) ? datum : []),
            [] as IListTemplate[]
          ),
    [statusFilters, trashed, folderFilteredData]
  )

  const templates = useMemo(
    () =>
      filteredData.map(template => (
        <TemplateThumbnail
          key={`MainLayout-Components-Templates-Thumbnail-${template?.id}`}
          id={template?.id}
          selected={template?.id === selectedTemplateId}
          onClick={() => setSelectedTemplateId(template?.id!)}
          name={template?.name}
          edited={template?.edited}
          sharedWith={template?.sharedWith}
        />
      )) || [],
    [filteredData, selectedTemplateId, setSelectedTemplateId]
  )

  const viewToggleButtonContent = useMemo(() => {
    const nextIndex = (VIEW_STYLES.indexOf(view) + 1) % VIEW_STYLES.length
    const nextView = VIEW_STYLES[nextIndex]
    //@ts-ignore
    return [VIEW_ICONS[nextIndex], nextView.slice(0, 1).toUpperCase() + nextView.slice(1).replaceAll('-', ' ')]
  }, [view])

  return (
    <div className={classes.wrapper}>
      <div className={classes.actionBar.wrapper}>
        <Breadcrumbs
          folders={[
            { id: 'all', name: 'All templates' } as BEFolder,
            { id: TEMPLATES_FILTERS.MINE, name: 'My templates' } as BEFolder,
            { id: TEMPLATES_FILTERS.SHARED, name: 'Shared with me' } as BEFolder,
          ].concat(folders || [])}
          activePath={templatesFolderFilter || 'all'}
          onClick={id => templatesFolderFilter && setTemplatesFolderFilter(id)}
        />
        <Button onClick={() => setView(prev => VIEW_STYLES[(VIEW_STYLES.indexOf(prev) + 1) % VIEW_STYLES.length])} onClickBlur transparent>
          {viewToggleButtonContent}
        </Button>
      </div>
      {view === VIEW_STYLES[0] ? (
        <Grid isLoading={isLoading} isFetching={isFetching} error={errorMessage}>
          {templates.length ? templates : <span>No available templates</span>}
        </Grid>
      ) : null}
      {view === VIEW_STYLES[1] ? (
        <Table
          data={(filteredData as { [K in keyof IListTemplate]: unknown }[]) || []}
          header
          defaultSort={{ key: 'edited._seconds', direction: 'descending' }}
          rowDataSet={['mutating', 'mutation']}
        >
          <Column
            className={classes.table.nameColumn}
            valueKey="name"
            header="Name"
            render={(value, entry, dataSet) => (
              <button type="button" onClick={() => history.push(`${TEMPLATE_DIRECTORY}/${entry.id}/edit`)} disabled={dataSet.mutating !== undefined}>
                <Document />
                <span>{String(value)}</span>
              </button>
            )}
            sort
          />
          <Column
            className={classes.table.statusColumn}
            valueKey="status"
            header="Status"
            render={value => <span>{`${(value as string).slice(0, 1).toUpperCase()}${(value as string).slice(1).toLowerCase()}`}</span>}
            width="8rem"
          />
          <Column
            valueKey="edited._seconds"
            header="Modified"
            render={value => String(DateTime.fromMillis(Number(value) * 1000).toRelative())}
            width="8rem"
            sort
          />
          <Column
            className={classes.table.selectColumn}
            valueKey="id"
            header=""
            render={id => (
              <button type="button" onClick={() => setSelectedTemplateId(id as string)}>
                <VerticalEllipsis />
              </button>
            )}
            width="min-content"
          />
        </Table>
      ) : null}
    </div>
  )
})

Templates.displayName = 'MainLayout-Content-Templates'

export default Templates
