import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { className } from './index'

const AutoSave = React.memo(() => {
  const maskId = useMemo(uuid, [])

  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width="100" height="100" stroke="none" fill="white" />
          <rect x="37.5" y="0" width="25" height="40" rx="3" stroke="none" fill="black" />
          <rect x="52" y="27" width="7" height="11" stroke="none" fill="white" />
          <rect x="32" y="55" width="36" height="18" rx="3" stroke="none" fill="black" />
        </mask>
      </defs>
      <path
        d="M 25 31 C 25 28 28 25 31 25 h 34 L 75 35 v 34 C 75 72 72 75 69 75 h -38 C 28 75 25 72 25 69 Z"
        stroke="none"
        fill="currentColor"
        mask={`url(#${maskId})`}
        transform="scale(1.5 1.5)"
        transform-origin="50 50"
      />
    </svg>
  )
})

export { AutoSave }
export default AutoSave
