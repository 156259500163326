import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, BulbProps, className, angleToDegrees, polarToRectangularOffset } from './index'

const ZOOM = 0.9

const RAY_COUNT = 8
const ANGLE_SWEEP = 235
const RAY_DISTANCE = 40
const RAY_LENGTH = 5

const Bulb: FunctionComponent<BulbProps> = React.memo(({ active }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const iconId = useMemo(uuid, [])

  const rays = useMemo(
    () =>
      new Array(RAY_COUNT).fill(null).map((_, i) => {
        const rayAngle = angleToDegrees(ANGLE_SWEEP / 2 - i * (ANGLE_SWEEP / (RAY_COUNT - 1)))
        const [startX, startY] = polarToRectangularOffset(rayAngle, RAY_DISTANCE, 50, 45)
        const [endX, endY] = polarToRectangularOffset(rayAngle, RAY_DISTANCE + RAY_LENGTH, 50, 45)
        return (
          <line
            key={`Bulb-SVG-Icon-${iconId}-${i}`}
            x1={startX}
            x2={endX}
            y1={startY}
            y2={endY}
            stroke="currentColor"
            strokeWidth="5"
            strokeLinecap="round"
          />
        )
      }),
    [iconId]
  )

  const d1 = 'M 45 85 c -10 0 -10 0 -10 -10 c 0 -10 -5 -7.5 -10 -15 a 30 30 0 1 1 50 0 c -2.5 5 -10 5 -10 15 c 0 10 0 10 -10 10 Z'
  const d2 = 'M 50 70 v -15 m -12 -12 a 12 12 0 1 0 24 0'

  return (
    <svg className={`${className} Bulb`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <path d={d1} stroke="currentColor" strokeWidth="5" fill="none" strokeLinejoin="round" strokeLinecap="round" />
      <path d={d2} stroke="currentColor" strokeWidth="5" fill="none" strokeLinejoin="round" strokeLinecap="round" />
      <line x1="35" x2="65" y1="75" y2="75" stroke="currentColor" strokeWidth="5" />
      {active ? rays : null}
    </svg>
  )
})

export { Bulb }
export default Bulb
