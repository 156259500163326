import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardModeSelector } from '___store'

import { EDITOR_MODES, EditorMode, WizardMode } from '___types'
import Editor from './RightPane.Editor'
import { wizardLayoutRightPaneClasses as classes } from '../..'

type UseStoreHookResultType = { wizardMode: WizardModeSelector }

export const RightPane: FunctionComponent = React.memo(() => {
  const { wizardMode: mode } = useStore('selectWizardMode') as UseStoreHookResultType

  const editorMode = useMemo(
    () => (Object.entries(EDITOR_MODES).find(([_, wizardModes]) => mode && (wizardModes as unknown as WizardMode[]).includes(mode)) || [])[0],
    [mode]
  ) as EditorMode | undefined

  return (
    <div className={classes.wrapper}>
      <Editor mode={editorMode} />
    </div>
  )
})
RightPane.displayName = 'WizardLayout-RightPane'

export default RightPane
