import React, {
  useMemo, // useMemo, // useState, // useImperativeHandle, // forwardRef,
  // useCallback,
  useEffect,
} from 'react'
import useStore from '___store'
import { Info } from 'assets/svgIconComponents'

// import QuestionOption from './QuestionOption'
// import { CASUS_KEYSTRINGS } from 'Wizard/constants'
// import MarkerCycler from 'Wizard/components/MarkerCycler'
import Actions from './Actions'
import { classes } from '../../..'
import { Button } from 'components'
import QuestionOptionGroup from './QuestionOptionGroup'
import { parseText } from 'components/Input'

// const parseUserInputValue = val =>
//   // eslint-disable-next-line no-useless-escape
//   [...val.matchAll(new RegExp(`(^${CASUS_KEYSTRINGS.input}:)([a-zA-Z0-9\-]+):(.*)`, 'g'))][0]

// const generateValue = (select, previous, active, value) => {
//   const parsed = parseUserInputValue(value)
//   if (select === 'single') return (parsed ? parsed[3] && parsed[0] : value) || previous
//   const next = previous.filter(prevValue => {
//     if (!parsed) return prevValue !== value
//     const parsedPrev = parseUserInputValue(prevValue)
//     return !(parsedPrev && parsedPrev[2] === parsed[2])
//   })
//   if (parsed && !parsed[3]) return next
//   return active ? next.concat(parsed ? parsed[0] : value) : next
// }

// const getSelected = (select, currentValue, optionId) => {
//   if (select === 'single') {
//     const parsed = parseUserInputValue(currentValue)
//     const selected = parsed ? parsed[2] === optionId : currentValue === optionId
//     const selectedValue = selected && parsed ? parsed[3] : ''
//     return [selected, selectedValue]
//   }
//   const foundValue = currentValue.find(cur => {
//     const parsed = parseUserInputValue(cur)
//     return parsed ? parsed[2] === optionId : cur === optionId
//   })
//   const selected = Boolean(foundValue)
//   const parsed = selected && parseUserInputValue(foundValue)
//   const selectedValue = parsed ? parsed[3] : ''
//   return [selected, selectedValue]
// }

const Hint = React.memo(({ hint }) => {
  const hintClass = classes.configEntry.questionnaire.questionListItem.hint
  return (
    <Button className={hintClass} tabbable={false} colorScheme="transparent">
      <Info />
      <div>{hint}</div>
    </Button>
  )
})

export const QuestionListItem = React.memo(({ id, isStandalone }) => {
  const {
    questionById: { text = 'No question text...', description = '', example = '', hint = '', optionGroups = [] } = {},
    answering: beingAnswered,
    questionIsVisible: show,
    unanswerQuestion,
  } = useStore(`selectQuestionById[${id}]`, `selectAnswering[${id}]`, `selectQuestionIsVisible[${id}]`, 'unanswerQuestion')

  const parsedHint = useMemo(() => parseText(hint), [hint])
  const parsedDescription = useMemo(() => parseText(description), [description])
  const parsedExample = useMemo(() => parseText(example), [example])

  const children = useMemo(
    () =>
      optionGroups.map(({ id: optionGroupId }) => (
        <QuestionOptionGroup key={`Questionnaire-question-${id}-optionGroup-${optionGroupId}`} id={optionGroupId} questionId={id} />
      )),
    [optionGroups, id]
  )

  // const optionText = select === 'multi' ? 'Select one or more:' : 'Choose one:'

  // const calculatedValue = stateAnswer || storeAnswer || (select === 'single' ? '' : [])
  // const valueArray = typeof calculatedValue === 'string' ? [calculatedValue] : calculatedValue

  // console.log('CALCULATED VALUE: ', id, calculatedValue)

  // const automaticConfirm = isStandalone && select === 'single'
  // const automaticConfirm = false
  // const hasUserInput = type === 'replacement' && valueArray.some(value => parseUserInputValue(value))
  // const needsConfirmation = isStandalone && (select === 'multi' || hasUserInput)
  // const needsConfirmation = isStandalone

  // const resolveOptionHandler = useCallback(
  //   (id, active, value) => {
  //     const resultingValue = generateValue(select, calculatedValue, active, value)
  //     setStateAnswer(resultingValue)
  //     const match = parseUserInputValue(value)
  //     if (match && !match[3]) return
  //     if (resultingValue !== calculatedValue) answerQuestion({ id, value: resultingValue })
  //     if (!automaticConfirm) return
  //     goToNextQuestion()
  //   },
  //   [select, calculatedValue, automaticConfirm, answerQuestion, goToNextQuestion]
  // )

  // const optionIds = useMemo(() => options.map(({ id: optionId }) => optionId).join('; '), [options])
  // const children = useMemo(
  //   () =>
  //     optionIds &&
  //     optionIds.split('; ').map(optionId => {
  //       const [selected, storedValue] = getSelected(select, calculatedValue, optionId)
  //       return (
  //         <QuestionOption
  //           key={optionId}
  //           questionId={id}
  //           id={optionId}
  //           select={select}
  //           selected={selected}
  //           storedValue={storedValue}
  //           handler={(active, value) => resolveOptionHandler(id, active, value)}
  //           disabled={disabled}
  //         />
  //       )
  //     }),
  //   [optionIds, id, select, calculatedValue, resolveOptionHandler, disabled]
  // )

  // useImperativeHandle(ref, () => ({ value: calculatedValue }), [calculatedValue])

  useEffect(() => {
    if (!show) unanswerQuestion({ id })
  }, [show, unanswerQuestion, id])

  if (!show) return null
  const {
    wrapper: wrapperClass,
    text: textClass,
    description: descriptionClass,
    example: exampleClass,
  } = classes.configEntry.questionnaire.questionListItem
  return (
    <div id={id} className={wrapperClass} data-being-answered={beingAnswered}>
      <div>
        <div className={textClass}>
          {beingAnswered && hint ? <Hint hint={parsedHint} /> : null}
          <span>{text}</span>
        </div>
        {beingAnswered && description ? <pre className={descriptionClass}>{parsedDescription}</pre> : null}
        {beingAnswered && example ? <pre className={exampleClass}>{parsedExample}</pre> : null}
        {children}
        {beingAnswered && isStandalone ? <Actions /> : null}
      </div>
    </div>
  )

  // return show ? (
  //   <div id={id} className={classes.wrapper} data-being-answered={beingAnswered}>
  //     <span className={classes.questionText}>{text}</span>
  //     {markers.length ? <MarkerCycler minimized disabled={disabled} id={id} initialScrollTo={scrollToMarker} /> : null}
  //     {description && <span className={classes.questionDescription}>{description}</span>}
  //     {options.length ? (
  //       <div className={classes.optionsDiv}>
  //         <span className={classes.optionsText}>{optionText}</span>
  //         {children}
  //       </div>
  //     ) : (
  //       <span className={classes.optionsText}>No options...</span>
  //     )}
  //     {isStandalone ||
  //     //  || markers.length
  //     needsConfirmation ? (
  //       <div className={classes.questionActionsDiv}>
  //         {isStandalone ? (
  //           <button
  //             disabled={
  //               disabled
  //               //  || isFirstQuestion
  //             }
  //             type="button"
  //             onClick={() => {
  //               if (disabled) return
  //               goToPreviousQuestion()
  //             }}
  //           >
  //             <Caret />
  //           </button>
  //         ) : null}
  //         {/* {markers.length ? <MarkerCycler disabled={disabled} id={id} initialScrollTo={scrollToMarker} /> : null} */}
  //         {needsConfirmation ? (
  //           <button
  //             disabled={disabled}
  //             type="button"
  //             onClick={() => {
  //               if (disabled) return
  //               answerQuestion({ id, value: calculatedValue })
  //               goToNextQuestion()
  //             }}
  //           >
  //             Next
  //           </button>
  //         ) : null}
  //       </div>
  //     ) : null}
  //   </div>
  // ) : null
})

QuestionListItem.displayName = 'Wizard-Questionnaire-QuestionListItem'

export default QuestionListItem
