import React, { Fragment, useState } from 'react'
import Validator from 'validator'

import { StandardModal, TextInputGroup } from 'components/common'
import { labelStyle, Container, Title } from './AddNewTeamMember.styles'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  isLoading?: boolean
  onAddTeamMember: (data: { email: string; name: string }) => void
  onClose: () => void
}

const AddTeamMemberComponent: React.FC<Props> = ({
  open,
  isLoading,
  onAddTeamMember,
  onClose,
}) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const { t } = useTranslation()
  return (
    <StandardModal
      open={open}
      title=''
      primaryButtonText="next"
      primaryButtonDisabled={!!isLoading || !Validator.isEmail(email) || !name.length}
      handleClose={onClose}
      handlePrimaryClick={() => onAddTeamMember({ name, email })}
    >
      <Container>
        <Fragment>
          <Title>{t("invite_teammate")}</Title>
          <TextInputGroup
            name='name'
            type='text'
            value={name}
            onChange={({ target }) => setName(target.value)}
            label={t('first_name')}
            placeholder='z.B. Peter'
            customvariant='thick'
            InputGroupContainerProps={{
              style: { width: 300, marginTop: 36 },
            }}
            LabelProps={{ style: labelStyle }}
          />
          <TextInputGroup
            name='email'
            type='email'
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            label={t('email')}
            placeholder='z.B. peter@yourcompany.ch'
            customvariant='thick'
            InputGroupContainerProps={{
              style: { width: 300 },
            }}
            LabelProps={{ style: labelStyle }}
          />
        </Fragment>
      </Container>
    </StandardModal>
  )
}
export default AddTeamMemberComponent
