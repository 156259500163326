import { BEFolder } from '___types'

import Breadcrumbs from './Breadcrumbs'

import './style.scss'

const classes = { wrapper: 'Casus-Components-Breadcrumbs-wrapper' }

export type BreadcrumbsProps = { folders: BEFolder[]; activePath: string; onClick: (id: string) => void }

export { Breadcrumbs, classes }
export default Breadcrumbs
