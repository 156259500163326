import React, { forwardRef, Dispatch, SetStateAction, RefCallback, useState, useCallback, useEffect, useImperativeHandle } from 'react'
import { DateTime as LuxonDateTime } from 'luxon'

import { InputDateTimeProps, classes } from '.'

export const DateTimeInput = React.memo(
  forwardRef<HTMLInputElement, InputDateTimeProps>(({ disabled, defaultValue, value, inputTime, autoselect, onInput, onEnter, onEscape }, ref) => {
    const [input, setInput]: [HTMLInputElement | undefined, Dispatch<SetStateAction<HTMLInputElement | undefined>>] = useState()
    const inputRef: RefCallback<HTMLInputElement | undefined> = useCallback(node => node && setInput(node), [])

    const formattedDefaultValue = defaultValue ? LuxonDateTime.fromISO(defaultValue).toFormat(`yyyy-MM-dd${inputTime ? "'T'HH:mm" : ''}`) : undefined
    const formattedValue = value ? LuxonDateTime.fromISO(value).toFormat(`yyyy-MM-dd${inputTime ? "'T'HH:mm" : ''}`) : undefined

    const keyDownHandler = useCallback(
      event => {
        if (event.key === 'Enter' && typeof onEnter === 'function' && !event.shiftKey) {
          event.preventDefault()
          event.stopPropagation()
          onEnter(event)
        }
        if (event.key === 'Escape' && typeof onEscape === 'function') {
          event.preventDefault()
          event.stopPropagation()
          if (input && formattedDefaultValue) {
            input.value = formattedDefaultValue
            event.currentTarget.dispatchEvent(new Event('input', { bubbles: true }))
          }
          onEscape(event)
        }
      },
      [onEnter, onEscape, input, formattedDefaultValue]
    )

    useEffect(() => {
      if (input && autoselect) input.focus()
    }, [input, autoselect])

    useImperativeHandle(ref, () => input!)

    return (
      <input
        ref={inputRef}
        className={classes.input}
        type={inputTime ? 'datetime-local' : 'date'}
        disabled={disabled}
        defaultValue={formattedDefaultValue}
        value={formattedValue}
        onChange={() => {}}
        onInput={onInput}
        onKeyDown={keyDownHandler}
        onBlur={() => window.getSelection()?.removeAllRanges()}
      ></input>
    )
  })
)

export default DateTimeInput
