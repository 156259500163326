import { OptionType, Question } from 'types'
import React, { Fragment } from 'react'

import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useEditor } from 'config/textEditor/useEditor'

import ActionButtons from '../../ActionButtons/ActionButtons'
import TextTypeContainer from './TextAndDateQuestion/QuestionText.container'
import DateTypeContainer from './TextAndDateQuestion/QuestionDate.container'
import RadioTypeContainer from './QuestionRadio/QuestionRadio.container'
import CheckboxTypeContainer from './QuestionCheckbox/QuestionCheckbox.container'
import NumberTypeContainer from './QuestionNumber/QuestionNumber.container'
import questionTypes from 'constants/question-types'
import { removeHighlightItemsByLocations } from 'utils'
import { ScrollWrapper } from '../../../TemplateEdit.styles'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'
// import { useUser } from 'queries/user/useUser'
import { useTranslation } from 'react-i18next'

const { TEXT_BOX, DATE, RADIO_LINK, RADIO_REPLACEMENT, CHECKBOX, NUMBER } =
  questionTypes

interface Props {
  nextDisabled: boolean
  setIsDisabled: (val: boolean) => void
}

const TextSelection: React.FC<Props> = ({ nextDisabled, setIsDisabled }) => {
  const editor = useEditor()
  const { set, selectedQuestionType, questionStep, question } =
    useTemplateCreation()
  // const user = useUser()
  // const docXmicro = !!user?.beta?.newDocxMicroservice

  const options = question?.options!
  const { t } = useTranslation()

  const removeOptionsWithoutText = (): void => {
    const optsWithText: OptionType[] = options?.filter(
      (op) => op.text.length
    )
    if (optsWithText.length !== options.length) {
      if ([CHECKBOX, RADIO_LINK].includes(question?.type!)) {
        const optsWithNoTextWithLocations = options.filter(
          (o) => !o.text && o.locations?.length
        )
        if (optsWithNoTextWithLocations.length) {
          const locationsToRemove = optsWithNoTextWithLocations
            .map((o) => o?.locations!)
            .flat()
          removeHighlightItemsByLocations(locationsToRemove, editor!)
        }
      }
      const updatedQuestion: Question = { ...question!, options: optsWithText }
      set({ question: updatedQuestion })
    }
  }

  const onNext = (): void => {
    trackIntercomEvent('template-creation-text-selection-added')

    if (options) removeOptionsWithoutText()
    set({ questionStep: questionStep + 1 })
  }

  const onBack = (): void => {
    set({ questionStep: questionStep - 1 })
  }

  const renderContent = () => {
    switch (selectedQuestionType) {
      case TEXT_BOX:
        return <TextTypeContainer />
      case DATE:
        return <DateTypeContainer />
      case RADIO_LINK:
      case RADIO_REPLACEMENT:
        return <RadioTypeContainer />
      case CHECKBOX:
        return <CheckboxTypeContainer />
      case NUMBER:
        return <NumberTypeContainer setIsDisabled={setIsDisabled} />
      default:
        return null
    }
  }

  return (
    <Fragment>
      <ScrollWrapper>{renderContent()}</ScrollWrapper>
      <ActionButtons
        handleBackClick={onBack}
        handleForwardClick={onNext}
        forwardDisabled={nextDisabled}
        btnNextText={t('next')}
      />
    </Fragment>
  )
}

export default TextSelection
