import React from 'react'
import Validator from 'validator'
import { useHistory } from 'react-router'

import { useToast } from 'hooks/useToast'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import AddTeamMemberComponent from './AddTeamMember.component'
import { InviteTeamMemberApi } from 'api/team.api'
import { useInviteTeamMember } from '../../hooks/useInviteTeamMember'

const AddTeamMember: React.FC = () => {
  const { location, replace } = useHistory()
  const toast = useToast()
  const { check } = useSubscriptionCheck()
  const { invite, isLoading } = useInviteTeamMember()

  const params = new URLSearchParams(location.search)
  const open = !!params.get('action')

  const onClose = () => {
    replace(location.pathname)
  }

  const isValid = (data: InviteTeamMemberApi): boolean => {
    if (!Validator.isEmail(data.email)) {
      toast('error', 'Sie müssen eine korrekte E-Mail-Adresse eingeben.')
      return false
    }

    if (data.name.trim().length < 1) {
      toast('error', 'Sie müssen den Vornamen eingeben.')
      return false
    }

    return true
  }

  const onAddTeamMember = (data: InviteTeamMemberApi): void => {
    if (!check({ isFree: true })) return
    const valid = isValid(data)
    if (!valid) return

    const sanitizedEmail = Validator.normalizeEmail(data.email)

    const sanitizedData = {
      name: data.name.trim(),
      email: sanitizedEmail ? sanitizedEmail :  '',
    }

    invite(sanitizedData, { onSuccess: onClose })
  }

  if (!open) return null

  return (
    <AddTeamMemberComponent
      open={open}
      isLoading={isLoading}
      onClose={onClose}
      onAddTeamMember={onAddTeamMember}
    />
  )
}

export default AddTeamMember
