import React from 'react'
import useStore from '___store'

import { Cross, RadioButton } from 'assets/svgIconComponents'
import Button from 'components/ButtonGroup'
import { CASUS_CLASSES } from 'Wizard/constants'
import { classes } from './index'

const scrollMarkerIntoView = (type, markerId) => {
  const markerClass = type === 'segments' ? CASUS_CLASSES.segmentsMarkerDiv : CASUS_CLASSES.textMarker
  const markers = Array.from(document.getElementsByClassName(markerClass))
  if (!markers.length) return
  const marker = markers.find(({ id }) => id.includes(markerId))
  if (!marker) return
  marker.scrollIntoView({ behavior: 'smooth', block: type === 'segments' ? 'start' : 'center' })
}

const Tag = React.memo(({ id, questionId, optionId }) => {
  const {
    locationById: { label = '', contentText = '', type = '' } = {},
    // unassignMarker,
    disconnectMarkerFromValueSource,
  } = useStore(
    `selectLocationById[${id}]`,
    // 'unassignMarker',
    'disconnectMarkerFromValueSource'
  )
  return (
    <div className={classes.tagWrapper}>
      <Button className={classes.tagButton} onClick={() => scrollMarkerIntoView(type, id)} colorScheme="transparent">
        <RadioButton active={true} />
      </Button>
      <span>{label || contentText || id}</span>
      <Button
        className={classes.tagButton}
        onClick={() => {
          // unassignMarker({ questionId, markerId: id, optionId })
          disconnectMarkerFromValueSource({ id, valueSource: `question:${questionId}` })
        }}
        colorScheme="transparent"
      >
        <Cross />
      </Button>
    </div>
  )
})

Tag.displayName = 'Wizard-Configuration-QuestionConfiguration-TagStrip-Tag'

export default Tag
