import NewDocumentPanel from './NewDocumentPanel'
import EmptyTemplates from './EmptyTemplates'

import './style.scss'

const classes = {
  wrapper: 'Homepage-dashboard-grid-newDocument-wrapper',
  blurBg: 'Homepage-dashboard-grid-newDocument-blurBg',
  title: 'Homepage-dashboard-grid-newDocument-title',
  filter: {
    wrapper: 'Homepage-dashboard-grid-newDocument-filter-wrapper',
    text: 'Homepage-dashboard-grid-newDocument-filter-text',
  },
  empty: {
    wrapper: 'EmptyStateTemplates-wrapper',
    title: 'EmptyStateTemplates-title',
    description: 'EmptyStateTemplates-description',
    illustration: 'EmptyStateTemplates-illustration',
    leftWrapper: 'EmptyStateTemplates-leftWrapper',
    textWrapper: 'EmptyStateTemplates-textWrapper',
    paperWrapper: 'EmptyStateTemplates-paperIllustration',
    buttonsGroup: 'EmptyStateTemplates-buttonsGroup',
    button: 'EmptyStateTemplates-button',
  },
  templatesList: {
    wrapper: 'Homepage-dashboard-grid-newDocument-templatesList-wrapper',
    empty: 'Homepage-dashboard-grid-newDocument-templatesList-empty',
    emptyText: 'Homepage-dashboard-grid-newDocument-templatesList-emptyText',
  },
}

export { classes, EmptyTemplates }

export default NewDocumentPanel

