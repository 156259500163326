import React, { FunctionComponent } from 'react'

import { Spinner } from 'assets/svgIconComponents'
import { GridProps, classes } from '.'

export const Grid: FunctionComponent<GridProps> = React.memo(({ className, isLoading, isFetching, error, title, children }) => (
  <div
    className={`${classes.wrapper}${className ? ` ${className}` : ''}`}
    data-loading={isLoading ? '' : undefined}
    data-fetching={isFetching ? '' : undefined}
    data-error={Boolean(error) ? '' : undefined}
    data-title={title ? '' : undefined}
  >
    {title ? <span className={classes.title}>{title}</span> : null}
    {isLoading ? <Spinner strokeWidth={7} /> : null}
    {!error ? children : <span className={classes.error}>{error}</span>}
  </div>
))

Grid.displayName = 'Casus-Components-Grid'

export default Grid
