import React, { useMemo } from 'react'
import useStore from '___store'

import Button, { ButtonGroup } from 'components/ButtonGroup'
import { QuestionLayoutGroup } from './components'
import { classes } from '../../index'

const noGroupsSpan = (
  <span>
    There are currently no questions/question groups in this template...
    <br />
    To create one, click one of the buttons below!
  </span>
)

export const QuestionGeneration = React.memo(() => {
  const {
    questionLayoutGroupIds,
    // addNewSeparator,
    addNewQuestionLayoutGroup,
    addNewQuestion,
  } = useStore(
    'selectQuestionLayoutGroupIds',
    // 'addNewSeparator',
    'addNewQuestionLayoutGroup',
    'addNewQuestion'
  )

  const groupIds = useMemo(() => questionLayoutGroupIds.split('; ').filter(id => id), [questionLayoutGroupIds])
  const groupList = useMemo(() => groupIds.map(id => <QuestionLayoutGroup key={id} id={id} />), [groupIds])

  const {
    footer: footerClass,
    questionGeneration: { wrapper: wrapperClass, groupList: groupListClass },
  } = classes.configEntry

  return (
    <>
      <div className={wrapperClass}>{groupList.length ? <div className={groupListClass}>{groupList}</div> : noGroupsSpan}</div>
      <div className={footerClass}>
        <ButtonGroup>
          {/* <Button onClick={() => addNewSeparator()}>Add separator</Button> */}
          <Button onClick={() => addNewQuestionLayoutGroup()}>New question group</Button>
          <Button onClick={() => addNewQuestion()} colorScheme="primary">
            New question
          </Button>
        </ButtonGroup>
      </div>
    </>
  )
})

QuestionGeneration.displayName = 'Wizard-Configuration-QuestionGeneration'

export default QuestionGeneration
