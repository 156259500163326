import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { BEFolder, PartialBEFolder } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type UpdateTemplateFolderVariables = PartialBEFolder
export type UpdateTemplateFolderContext = { mutationId: string }
export type UpdateTemplateFolderMutationOptions = MutateOptions<BEFolder | void, unknown, UpdateTemplateFolderVariables, UpdateTemplateFolderContext>
export type UpdateTemplateFolderFunctionType = (folder: PartialBEFolder, options?: UpdateTemplateFolderMutationOptions) => void
const updateTemplateFolderMutationFunction = (variables: UpdateTemplateFolderVariables) => templatesAPI.updateTemplateFolder(variables)

export const useUpdateTemplateFolder = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: (BEFolder & { mutationId?: string })[] | undefined) => BEFolder[]) =>
    queryClient.setQueryData([QUERY_KEYS.TEMPLATE_FOLDERS], method)

  const onMutate = (variables: UpdateTemplateFolderVariables) => {
    const mutationId = uuid()
    const replaceMethod = (currentFolder: BEFolder & { mutationId?: string; original?: BEFolder }) => {
      const original = currentFolder.original || currentFolder
      return Object.assign({}, currentFolder, variables, { mutationId, mutating: true, mutation: 'update', original })
    }
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, replaceMethod))
    return { mutationId }
  }

  const onError = (error: unknown, variables: UpdateTemplateFolderVariables, context: UpdateTemplateFolderContext | undefined) => {
    const currentFolder = (
      queryClient.getQueryData([QUERY_KEYS.TEMPLATE_FOLDERS]) as (BEFolder & { mutationId?: string; original: BEFolder })[]
    ).find(folder => folder.id === id)
    if (currentFolder && currentFolder.mutationId === context?.mutationId) {
      queryClient.cancelQueries([QUERY_KEYS.TEMPLATE_FOLDERS])
      queryClient.invalidateQueries([QUERY_KEYS.TEMPLATE_FOLDERS])
      queryClient.fetchQuery([QUERY_KEYS.TEMPLATE_FOLDERS])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentFolder.original))
    }
  }

  const onSuccess = (folder: BEFolder | void, variables: UpdateTemplateFolderVariables, context: UpdateTemplateFolderContext | undefined) => {
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, folder))
  }

  const templateFolderUpdateMutation = useMutation<BEFolder | void, unknown, UpdateTemplateFolderVariables, UpdateTemplateFolderContext>(
    [QUERY_KEYS.TEMPLATE_FOLDERS],
    updateTemplateFolderMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const updateFolderMutationFunction: UpdateTemplateFolderFunctionType = (folder, options) =>
    templateFolderUpdateMutation.mutate(Object.assign({}, folder, { id: id! }), options)

  return { updateTemplateFolder: updateFolderMutationFunction, updatingTemplateFolder: templateFolderUpdateMutation.isLoading }
}

export default useUpdateTemplateFolder
