import React from 'react'
import './style.scss'
import { DocumentActions } from './DocumentActions'
import { TemplateActions } from './TemplateActions'
import { PdfActions } from './PdfActions'

export const Actions = ({ data, type }) => {
  if (type === 'template') {
    return <TemplateActions data={data} type={type} />
  } else if (type === 'document') {
    return <DocumentActions data={data} type={type} />
  } else if (type === 'pdf') {
    return <PdfActions data={data} type={type} />
  } else {
    return null
  }
}

