import Editor from './Editor'

import './style.scss'

const classes = {
  id: 'Wizard-Editor',
  wrapper: 'Wizard-Editor-wrapper',
  controls: 'Wizard-Editor-controls',
  selectionControls: {
    segments: {
      background: 'Wizard-Editor-SelectionControls-Segments-background',
      wrapper: 'Wizard-Editor-SelectionControls-Segments-wrapper',
      buttonGroup: 'Wizard-Editor-SelectionControls-Segments-buttonGroup',
      button: 'Wizard-Editor-SelectionControls-Segments-button',
    },
    text: {
      wrapper: 'Wizard-Editor-SelectionControls-Text-wrapper',
      buttonGroup: 'Wizard-Editor-SelectionControls-Text-buttonGroup',
      button: 'Wizard-Editor-SelectionControls-Text-button',
    },
  },
  headerFooterToggleButton: {
    wrapper: 'Wizard-Editor-HeaderFooterToggleButton-wrapper',
  },
  section: {
    wrapper: 'Wizard-Editor-Section-wrapper',
    separator: 'Wizard-Editor-Section-separator',
  },
  page: {
    wrapper: 'Wizard-Editor-Page-wrapper',
  },
  segment: {
    wrapper: 'Wizard-Editor-Segment-wrapper',
    warning: 'Wizard-Editor-Segment-warning',
    paragraph: {
      wrapper: 'Wizard-Editor-Segment-Paragraph-wrapper',
      controlsWrapper: 'Wizard-Editor-Segment-Paragraph-controlsWrapper',
      actionSelect: 'Wizard-Editor-Segment-Paragraph-actionSelect',
      actionButtonGroup: 'Wizard-Editor-Segment-Paragraph-actionButtonGroup',
      actionButton: 'Wizard-Editor-Segment-Paragraph-actionButton',
      customStyleSelect: 'Wizard-Editor-Segment-Paragraph-customStyleSelect',
      // controls: 'Wizard-Editor-Segment-Paragraph-controls',
      // controlButton: 'Wizard-Editor-Segment-Paragraph-controlButton',
      textarea: 'Wizard-Editor-Segment-Paragraph-textarea',
      textareaOverlay: 'Wizard-Editor-Segment-Paragraph-textareaOverlay',
    },
    table: {
      wrapper: 'Wizard-Editor-Segment-Table-wrapper',
      header: 'Wizard-Editor-Segment-Table-header',
      body: 'Wizard-Editor-Segment-Table-body',
      footer: 'Wizard-Editor-Segment-Table-footer',
      row: 'Wizard-Editor-Segment-Table-row',
      cell: 'Wizard-Editor-Segment-Table-cell',
    },
  },
  marker: {
    markerAssignSelect: {
      wrapper: 'Wizard-Editor-Marker-MarkerAssignSelect-wrapper',
      optionGroupLabel: 'Wizard-Editor-Marker-MarkerAssignSelect-optionGroupLabel',
    },
    segments: {
      wrapper: 'Wizard-Editor-Marker-Segments-wrapper',
      header: 'Wizard-Editor-Marker-Segments-header',
      buttonGroup: 'Wizard-Editor-Marker-Segments-buttonGroup',
      button: 'Wizard-Editor-Marker-Segments-button',
      content: 'Wizard-Editor-Marker-Segments-content',
    },
    text: {
      wrapper: 'Wizard-Editor-Marker-Text-wrapper',
      buttonGroup: 'Wizard-Editor-Marker-Text-buttonGroup',
      button: 'Wizard-Editor-Marker-Text-button',
    },
  },
}

export { Editor, classes }
export default Editor
