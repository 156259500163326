import { combineReducers } from 'redux'
import templatesReducer from './templates/templates.reducer'
import docsReducer from './docs/docs.reducer'
import uiReducer from './ui/ui.reducer'
import messageReducer from './message/message.reducer'

import externalServicesReducer from './externalServices/reducer'

const rootReducer = combineReducers({
  templates: templatesReducer,
  docs: docsReducer,
  ui: uiReducer,
  message: messageReducer,
  externalServices: externalServicesReducer,
})


export type RootState2 = ReturnType<typeof rootReducer>

export default rootReducer
