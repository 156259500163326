import { FolderItem, RootFolder } from 'types'
import {
  SET_CURRENT_FOLDER_DOCUMENTS,
  SET_ROOT_FOLDER_DOCUMENTS,
  DocsActions,
} from './docs.actions'

const rootFolder: RootFolder = {
  id: 'root',
  type: 'root',
  name: 'root',
  children: [],
  path: [],
}

const my: FolderItem = {
  id: 'my',
  type: 'my',
  name: 'my_documents',
  authorId: '',
  sharedWith: [],
  children: [],
  path: [],
}

const shared: FolderItem = {
  id: 'shared',
  type: 'shared',
  name: 'shared_documents',
  authorId: '',
  sharedWith: [],
  children: [],
  path: [],
}

const trashed: FolderItem = {
  id: 'trashed',
  type: 'trashed',
  name: 'bin',
  authorId: '',
  sharedWith: [],
  children: [],
  path: [],
}

rootFolder.children.push(my, shared, trashed)

const initialState: DocsState = {
  rootFolder,
  currentFolder: rootFolder.children[0],
}

interface DocsState {
  rootFolder: RootFolder
  currentFolder: FolderItem
}

export default (
  state: DocsState = initialState,
  action: DocsActions
): DocsState => {
  switch (action.type) {
    case SET_CURRENT_FOLDER_DOCUMENTS:
      return { ...state, currentFolder: action.payload }
    case SET_ROOT_FOLDER_DOCUMENTS:
      return {
        ...state,
        rootFolder: action.payload,
      }
    default:
      return state
  }
}
