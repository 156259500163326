import React from 'react'
import { Redirect } from 'react-router'
import { Switch, Route } from 'react-router-dom'

import SelectTemplate from './SelectTemplate/SelectTemplate.page'
import GenerateDocument from './GenerateDocument/GenerateDocument.page'
import { documentsRoutes } from 'constants/routes'

const DocumentCreate: React.FC = () => {
  return (
    <Switch>
      <Route exact path={`/document/new/:templateId`} component={GenerateDocument} />
      <Route exact path={`/document/new`} render={() => <SelectTemplate isOld={true} />} />
      <Redirect to={documentsRoutes.main} />
    </Switch>
  )
}

export default DocumentCreate
