import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { classes } from '.'

export const Background: FunctionComponent = React.memo(() => {
  //@ts-ignore
  const id = useMemo(() => `animate:radius:${uuid()}`.replaceAll('-', ''), [])

  return (
    <svg className={classes.background} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
      <circle cx="50" cy="50" r="0">
        <animate
          id={id}
          attributeName="r"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0.25 0 1 0.75"
          values="0; 100"
          dur="1000ms"
          fill="freeze"
          begin=""
        />
        <animate
          attributeName="opacity"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0 0.25 0.75 1"
          values="0.5; 0"
          dur="1000ms"
          fill="freeze"
          begin={`${id}.begin`}
        />
      </circle>
    </svg>
  )
})

Background.displayName = 'Casus-Components-Button-Background'

export default Background
