import React, { useMemo, useEffect } from 'react'
import useStore from '___store'

import { QuestionGroup, Separator } from './components'
import { classes } from '../..'

export const Questionnaire = React.memo(() => {
  const { questionLayout = [], answering } = useStore('selectQuestionLayout', 'selectAnswering')

  const children = useMemo(
    () =>
      questionLayout
        .filter(({ type }) => type !== 'sub-questions')
        .map(({ id, type, label }, i) =>
          type === 'separator' ? <Separator key={`separator-${i}-${label}`} label={label} /> : <QuestionGroup key={id} id={id} />
        ),
    [questionLayout]
  )

  useEffect(() => {
    // if (!answering && questionLayout.length) {
    //   const firstGroup = questionLayout[0]
    //   return setAnswering({ id: firstGroup.type === 'loose' ? firstGroup.questionIds[0] : firstGroup.id })
    // }
    // const beingAnsweredElement = document.querySelector('[data-being-answered="true"]')
    // console.log(beingAnsweredElement?.id, answering)
    const questionElement = document.getElementById(answering)
    if (!questionElement) return
    if (window.chrome) questionElement.scrollIntoView()
    else questionElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    questionElement.childNodes[0].scrollTo(0, 0)
    // const focusableElements = questionElement.querySelectorAll(`button, input`)
    // if (!(focusableElements && focusableElements[0])) return
    // focusableElements[0].focus()
  }, [answering])

  const {
    wrapper: wrapperClass,
    content: contentClass,
    scrollBuffer: scrollBufferClass,
    questionFade: questionFadeClass,
  } = classes.configEntry.questionnaire

  // const styleObject = window.chrome ? { overflow: 'auto' } : undefined

  return (
    <div className={wrapperClass}>
      <div
        className={contentClass}
        // style={styleObject}
      >
        {/* {!window.chrome ? <div className={scrollBufferClass} /> : null} */}
        <div className={scrollBufferClass} />
        {children}
        {/* {!window.chrome ? <div className={scrollBufferClass} /> : null} */}
        <div className={scrollBufferClass} />
      </div>
      <div className={questionFadeClass} />
    </div>
  )
})

Questionnaire.displayName = 'Wizard-Configuration-Questionnaire'

export default Questionnaire
