import React from 'react'
import SettingsGroupItem from '../../components/SettingsGroupItem/SettingsGroupItem'
import { Container, ItemTitle } from './EmailSettings.styles'
import { ISettingsGroupItem } from '../../components/SettingsGroupItem/SettingsGroupItem.types'
import { useTranslation } from 'react-i18next'

interface Props {
  email: string
}

const EmailSettings: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation()
  const item: ISettingsGroupItem = {
    name: 'email',
    value: email,
    label: t('email'),
    editable: false,
  }

  return (
    <Container>
      <ItemTitle>Email</ItemTitle>
      <SettingsGroupItem item={item} />
    </Container>
  )
}

export default EmailSettings
