import React from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'
import { classes } from '.'
import Button, { ButtonGroup } from 'components/ButtonGroup'
import { useToast } from 'hooks/useToast'
import { ReactComponent as Loader } from 'assets/icons/loader.svg'

export const Footer = ({
  handleNextStep,
  handleFinishClick,
  handleClose,
  enablePrimaryButton,
  enableNextStepButton,
  isEnabled,
  panelStep,
  isLoading,
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  return panelStep >= 1 ? (
    <ButtonGroup className={classes.footer.wrapper}>
      <Button className={classes.button.close.wrapper} onClick={() => handleClose()} data-disabled={!isEnabled}>
        Back
      </Button>
      {!isLoading ? (
        <Button
          className={classes.button.submit.wrapper}
          onClick={() => (enablePrimaryButton ? handleFinishClick() : toast('error'))}
          data-disabled={!enablePrimaryButton}
        >
          Request e-sign
        </Button>
      ) : (
        <Button className={classes.button.submit.wrapper}>
          <div className={classes.button.loader.wrapper}><Loader className={classes.button.loader.svg} /></div>
        </Button>
      )}
    </ButtonGroup>
  ) : (
    <ButtonGroup className={classes.footer.solo}>
      <Button className={classes.button.submit.wrapper} onClick={() => handleNextStep()} data-disabled={!enablePrimaryButton}>
        {t('next')}
      </Button>
    </ButtonGroup>
  )
}

