import React, {useState} from 'react'
import {Container, ItemTitle} from './LangSettings.styles'
import {makeStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import UserApi from "api/user.api";
import {User} from "types";
import { useTranslation } from 'react-i18next'

interface Props {
    // lang: any
    handleLanguageChange: any
}


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))


const LangSettings: React.FC<Props> = ({handleLanguageChange}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [lang, setLang] = useState('')
    const {t, i18n} = useTranslation()
    const classes = useStyles()
    UserApi.getMe()
        .then((data: User) => {
            setLang(data.lang)
        })

    const handleChange = async (event: any) => {
        setIsLoading(true)
        handleLanguageChange(event.target.value)
        setIsLoading(false)
    }

    const getLabelLanguage = (lang: string) => {
        switch (lang) {
            case 'en_GB':
                return 'English'
            case 'de_CH':
                return 'Deutsch'
            case 'fr_FR':
                return 'Français'
            case 'it_IT':
                return 'Italiano'
        }
    }

    return (
        <Container>
            <ItemTitle>{t('language')}</ItemTitle>
            <FormControl
                variant="outlined"
                className={classes.formControl}
            >
                <InputLabel id="demo-simple-select-outlined-label">{getLabelLanguage(i18n.language)}</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={lang}
                    onChange={handleChange}
                    label={t('language')}
                    disabled={isLoading}
                    autoWidth={true}
                >
                    <MenuItem value={'en_GB'}>English</MenuItem>
                    <MenuItem value={'de_CH'}>Deutsch</MenuItem>
                    <MenuItem value={'fr_FR'}>Français</MenuItem>
                    <MenuItem value={'it_IT'}>Italiano</MenuItem>
                </Select>
            </FormControl>
        </Container>
    )
}

export default LangSettings