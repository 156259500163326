import { TQuestionTypes } from 'types'
import React, { Fragment, useState, useRef, useEffect } from 'react'
import {
  Container,
  TopWrapper,
  BottomWrapper,
  TabsWrapper,
  TextWrapper,
  Text,
  TabItemContainer,
  TabItemText,
  DummyDoc,
  URLText,
} from './QuestionDescriptionDrawer.styles'
import dummyPreview from 'assets/icons/dummyPreview.svg'
import { QUESTION_TYPE_TEXT_BOX } from 'constants/question-types'
import { hyphenateSync } from 'hyphen/de'
import { useTranslation } from 'react-i18next'

interface Props {
  description: string
  answerExample: string
  questionType: TQuestionTypes
}

const QuestionDescription: React.FC<Props> = ({ questionType, description, answerExample }) => {
  const ref = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const handleUrlClick = (url: string) => {
    window.open(url)
  }

  const formatText = (p: string): React.ReactNode => {
    const theText = p.split('{{CUSTOM}}')
    if (theText.length === 1) return p

    return (
      <Fragment>
        {theText.map((textItem, i) => {
          if (textItem.startsWith('link=')) {
            let href = ''
            let actionText = ''
            const data = textItem.split(',text=')
            href = data[0].replace('link=', '')
            actionText = data[1]
            return (
              <URLText key={actionText + href + i} onClick={() => handleUrlClick(href)}>
                {hyphenateSync(actionText)}
              </URLText>
            )
          } else if (textItem.startsWith('bold=')) {
            const boldText = textItem.replace('bold=', '')
            return <strong key={boldText + i}>{hyphenateSync(boldText)}</strong>
          } else if (textItem.startsWith('italic=')) {
            const italicText = textItem.replace('italic=', '')
            return <i key={italicText + i}>{hyphenateSync(italicText)}</i>
          }
          return hyphenateSync(textItem)
        })}
      </Fragment>
    )
  }

  const dparagraphs: string[] = description?.split(/\r?\n/)
  const aparagraphs: string[] = answerExample?.split(/\r?\n/)

  const [activeTab, setActiveTab] = useState<'answerExample' | 'description'>(
    questionType === QUESTION_TYPE_TEXT_BOX ? 'answerExample' : 'description'
  )

  useEffect(() => {
    ref.current && ref.current.scrollTo(0, 0)
  }, [activeTab, description, answerExample])

  useEffect(() => {
    if (questionType === QUESTION_TYPE_TEXT_BOX) {
      setActiveTab('answerExample')
    } else {
      setActiveTab('description')
    }
  }, [questionType])

  return (
    <Container>
      <TopWrapper>
        <DummyDoc data-intercom-target="document-preview-intercom">
          <img src={dummyPreview} alt="" width="100%" />
        </DummyDoc>
      </TopWrapper>
      <BottomWrapper ref={ref}>
        <TabsWrapper>
          <TabItemContainer id="answerExample" active={activeTab === 'answerExample' ? 1 : 0} onClick={() => setActiveTab('answerExample')}>
            <TabItemText active={activeTab === 'answerExample' ? 1 : 0} data-intercom-target="answer-example-tab-intercom">
              {t('example_text')}
            </TabItemText>
          </TabItemContainer>

          <TabItemContainer id="description" active={activeTab === 'description' ? 1 : 0} onClick={() => setActiveTab('description')}>
            <TabItemText active={activeTab === 'description' ? 1 : 0} data-intercom-target="description-tab-intercom">
              {t('pratice_tips')}
            </TabItemText>
          </TabItemContainer>
        </TabsWrapper>
        <TextWrapper>
          {activeTab === 'description'
            ? dparagraphs?.map((p, i) => (
                <Text key={i} lang="de_DE">
                  {!p.length ? '\u200D' : formatText(p)}
                </Text>
              ))
            : aparagraphs.map((p, i) => <Text key={i}>{!p.length ? '\u200D' : formatText(p)}</Text>)}
        </TextWrapper>
      </BottomWrapper>
    </Container>
  )
}

export default QuestionDescription
