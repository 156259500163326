import FoldersApi from 'api/folders.api'
import { useQuery } from 'react-query'
import { useFolderKeys } from './useFolderKeys'

export const useFetchFolder = (type: 'templates' | 'docs', id?: string | null) => {
  const enabled = !!id && !!localStorage.casusToken
  const [, single] = useFolderKeys(type)
  const query = useQuery([single, id], () => (id ? FoldersApi.getOne(type, id) : null), {
    enabled,
    refetchOnWindowFocus: false,
  })
  return query
}
