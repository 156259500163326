import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, SecurityProps, className } from './index'
import { SECURITY_LEVELS } from '___types'

const ZOOM = 1.2

// const ChainLink: FunctionComponent<{ translate?: number; strokeWidth?: number }> = React.memo(({ translate = 0, strokeWidth = 5 }) => {
//   return (
//     <g transform={`translate(0 ${translate})`}>
//       <path
//         d="M 45 40 c 0 -2.5 2.5 -5 5 -5 c 2.5 0 5 2.5 5 5 v 5 c 0 2.5 -2.5 5 -5 5 c -2.5 0 -5 -2.5 -5 -5 Z"
//         stroke="currentColor"
//         strokeWidth={strokeWidth}
//         fill="none"
//       />
//       <line x1="50" x2="50" y1="48" y2="64" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
//       {/* <rect x="40" y="37" width="20" height="26" stroke="red" strokeWidth="1" fill="none" /> */}
//     </g>
//   )
// })

// const Chain: FunctionComponent<{ length?: number; rotate?: number; strokeWidth?: number }> = React.memo(
//   ({ length = 5, rotate = 0, strokeWidth = 5 }) => {
//     return (
//       <g transform={`rotate(${rotate} 50 50)`}>
//         {new Array(length).fill(undefined).map((_, i, array) => (
//           <ChainLink translate={(i - (array.length - 1) / 2) * 27} strokeWidth={strokeWidth} />
//         ))}
//       </g>
//     )
//   }
// )

// const Chains: FunctionComponent<{ translateX?: number; translateY?: number; strokeWidth?: number }> = React.memo(
//   ({ translateX = 0, translateY = 0, strokeWidth = 5 }) => {
//     const maskId = useMemo(uuid, [])
//     return (
//       <g transform={`translate(${translateX} ${translateY})`} mask={`url(#${maskId})`}>
//         <Chain rotate={60} strokeWidth={strokeWidth} />
//         <Chain rotate={-60} strokeWidth={strokeWidth} />
//       </g>
//     )
//   }
// )

const Security: FunctionComponent<SecurityProps> = React.memo(({ level = 'max' }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const maskId = useMemo(uuid, [])

  const base = (
    <path
      d="M 50 60 c -5 0 -10 5 -10 10 c 0 5 5 10 10 10 c 5 0 10 -5 10 -10 c 0 -5 -5 -10 -10 -10 v -15 h 8 h -8 v -8 h 6 h -6 v -7 Z"
      stroke="currentColor"
      strokeWidth="6"
      fill="none"
      strokeLinejoin="round"
      transform="translate(5 -5) rotate(30 50 50)"
    />
  )

  const high = (
    <>
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <circle cx="50" cy="55" r="6" stroke="none" fill="black" />
          <line x1="50" x2="50" y1="60" y2="65" stroke="black" strokeWidth="5" strokeLinecap="round" />
        </mask>
      </defs>
      <rect x="30" y="40" width="40" height="35" rx="10" ry="10" fill="currentColor" stroke="none" mask={`url(#${maskId})`} />
      <path d="M 40 40 v -10 c 0 -5 5 -10 10 -10 c 5 0 10 5 10 10 v 10" stroke="currentColor" strokeWidth="5" fill="none" />
    </>
  )

  const max = (
    <>
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <rect x="35" y="40" width="30" height="25" rx="8" ry="8" fill="black" stroke="none" />
          <path d="M 42 41 v -3 c 0 -4 4 -8 8 -8 c 4 0 8 4 8 8 v 3" stroke="black" strokeWidth="4" fill="none" />
          <circle cx="50" cy="50" r="4" stroke="none" fill="white" />
          <line x1="50" x2="50" y1="50" y2="57" stroke="white" strokeWidth="3" strokeLinecap="round" />
        </mask>
      </defs>
      <path d="M 50 80 c -15 -5 -25 -20 -25 -50 l 25 -10 l 25 10 c 0 30 -10 45 -25 50 Z" stroke="none" fill="currentColor" mask={`url(#${maskId})`} />
      <path
        d={`M 0 30 c -15 -5 -25 -20 -25 -50 l 25 -10 l 25 10 c 0 30 -10 45 -25 50 Z`}
        stroke="currentColor"
        strokeWidth="3"
        fill="none"
        transform={`scale(1.15 1.15) translate(${50 / 1.15} ${50 / 1.15})`}
      />
    </>
  )

  return (
    <svg className={`${className} Security`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      {level === SECURITY_LEVELS.BASE ? base : null}
      {level === SECURITY_LEVELS.HIGH ? high : null}
      {level === SECURITY_LEVELS.MAX ? max : null}
    </svg>
  )
})

export { Security }
export default Security
