import { IAnswer, Question } from 'types'
import React from 'react'
import {
  QuestionContainer,
  Text,
  AnswersWrapper,
  StyledIcon,
  Dot,
} from './Question.styles'
import { extractSubquestionsFromOptions } from 'utils'
import { QuestionFlattedMap } from 'components/features/documentGeneration/views/Questionnaire/services/createQuestionMap'
import { useTranslation } from 'react-i18next'

const getAnswerValue = (
  question: Question,
  values: { [key: string]: IAnswer },
  t: any
): string[] => {
  const answerArr: string[] = []
  const answer = values[question.id]
  if (!answer || !answer.value.length) {
    answerArr.push(t("no_answer"))
    return answerArr
  }
  if (typeof answer.value === 'string') return [answer.value]
  if (question.options && typeof answer.value === 'object') {
    const answersArr: string[] = []
    for (let option of question.options) {
      if (answer.value.includes(option.id)) answersArr.push(option.text)
    }
    return answersArr
  }
  answerArr.push('Entschuldigung! Es ist ein Fehler aufgetreten. Wir kümmern uns darum.')
  return answerArr
}

const isQuestionDisabled = (
  question: Question,
  questionsMap: QuestionFlattedMap,
  values: { [key: string]: IAnswer }
): boolean => {
  if (!question.parentId) return false
  const theParentId = question.parentId.split('--')[0]
  if (!theParentId) return false

  const theParentQuestion = questionsMap.questions[theParentId]
  if (!theParentQuestion.options) return false
  const selectedOptions = values[theParentQuestion.id]?.value
  if (!selectedOptions) return true // ADDED
  const theOptions = theParentQuestion.options.filter(optItem =>
    selectedOptions.includes(optItem.id)
  )
  const subquestions = extractSubquestionsFromOptions(theOptions)

  return !subquestions.some(q => q.id === question.id)
}

interface QuestionComponentProps {
  parentId: string
  label: string
  answer: string[]
  disabled: boolean
  handleEditQuestion: React.MouseEventHandler<HTMLOrSVGElement>
}

const QuestionComponent: React.FC<QuestionComponentProps> = ({
  parentId,
  label,
  answer,
  disabled,
  handleEditQuestion,
}) => {
  const level = parentId.split('--').length
  const showDot = answer[0] === 'Keine Antwort' && !disabled
  return (
    <QuestionContainer
      disabled={disabled ? 1 : 0}
      style={{ marginLeft: parentId ? `${level * 6}%` : 0 }}
    >
      <Text>{label}</Text>
      <AnswersWrapper>
        {answer.map((answerItem, i) => {
          let theFinalText = answerItem
          const withDash = answer.length > 1
          if (withDash) theFinalText = `- ${theFinalText}`
          if (theFinalText.length > 25) {
            theFinalText = `${theFinalText.slice(0, 23)}...`
          }
          return <Text key={answerItem + i}>{theFinalText}</Text>
        })}
      </AnswersWrapper>
      {!disabled && <StyledIcon iconName='edit' onClick={handleEditQuestion} />}
      {showDot && <Dot />}
    </QuestionContainer>
  )
}

interface TheQuestionComponentProps {
  question: Question
  values: { [key: string]: IAnswer }
  questionsMap: QuestionFlattedMap
  handleEditQuestion: (qid: string) => void
}

export const TheQuestion: React.FC<TheQuestionComponentProps> = ({
  question,
  values,
  questionsMap,
  handleEditQuestion,
}) => {
  const { t } = useTranslation()
  const answer = getAnswerValue(question, values, t)
  const disabled = isQuestionDisabled(question, questionsMap, values)
  return (
    <QuestionComponent
      label={question.tags[0]}
      answer={answer}
      parentId={question.parentId}
      disabled={disabled}
      handleEditQuestion={() => handleEditQuestion(question.id)}
    />
  )
}

export default TheQuestion
