import { Icon, Loader } from 'components/common'
import React from 'react'
import { Actions } from './components/Actions/Actions'
import { Description } from './components/Description/Description'
import { Header } from './components/Header/Header'
import { Informations } from './components/Informations/Informations'
import { SharedWith } from './components/SharedWith/SharedWith'
import { Signatures } from './components/Signatures/Signatures'
import { useDetails } from './provider/DetailsProvider'
import { useUser } from 'queries/user/useUser'
import './style.scss'
import { PaywallSignatures } from './components/Signatures/PaywallSignatures'
import { getSubscriptionStatus } from 'utils'
import { useTemplate } from '___hooks'

const classes = {
  wrapper: 'details-sidebar',
  container: 'details-sidebar-container',
  closeButton: 'close-button',
  divider: 'details-sidebar-divider',
}

export const DetailsSidebar = () => {
  const { data, isLoading, isError, selectedId, clear } = useDetails()
  let { type } = useDetails()
  const user = useUser()

  const tenant = window.location.host.split('.')[0]

  if (data && data.type === 'pdf') type = 'pdf'

  const { template: { data: template } = {} } = useTemplate(data?.templateId)

  const signatureRender = () => {
    if (type === 'template') return null

    const isDocumentType = type === 'document' || type === 'pdf' || type === 'signature'
    const isTenantSBV = tenant === 'sbv'
    const hasSubscription = getSubscriptionStatus(user)

    // Check for document types and tenant/subscriptions
    if (isDocumentType && (!isTenantSBV || hasSubscription)) {
      const splits = (data.signatureRequest || []).reduce(
        (acc, request) => {
          const id = request.splitId in acc ? request.splitId : 'general'
          const resultingRequests = (acc[id]?.requests || []).concat(request)
          const resultingSplit = Object.assign(acc[id] || {}, { requests: resultingRequests })
          return Object.assign(acc, { [id]: resultingSplit })
        },
        Object.entries(template?.splits || {}).reduce((res, [id, label]) => Object.assign(res, { [id]: { label, requests: [] } }), {
          general: { label: 'Signatures', requests: [] },
        })
      )
      if (Object.keys(splits).length > 1 && splits.general.requests.length === 0) delete splits.general

      return (
        <>
          <Signatures type={type} documentId={data.id} signatureRequests={splits} />
          <div className={classes.divider} />
        </>
      )
    } else {
      return <PaywallSignatures />
    }
  }

  const content = () => {
    if (isLoading || !data) {
      return (
        <div>
          <Loader />
        </div>
      )
    } else if (isError) {
      return <div>Error</div>
    } else {
      return (
        <div className={classes.container}>
          <Header type={type} name={data.name} id={data.id} />
          <Actions data={data} type={type} />
          <Informations type={type} createdAt={data.created?._seconds * 1000} editedAt={data.edited?._seconds * 1000} author={data.author} />
          <div className={classes.divider} />
          {type === 'template' ? (
            <>
              <Description id={data.id} templateChars={data.characteristics} />
              <div className={classes.divider} />
            </>
          ) : null}
          <SharedWith id={data.id} type={type} sharedWith={data.sharedWith} />
          <div className={classes.divider} />
          {signatureRender()}
          <button className={classes.closeButton} onClick={() => clear()}>
            <Icon iconName="close" />
          </button>
        </div>
      )
    }
  }
  return selectedId ? <div className={classes.wrapper}>{content()}</div> : null
}
