import React from 'react'

import { className } from './index'

const Trash = React.memo(() => (
  <svg className={className} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.41406 17.9531C3.20312 17.9531 2.4375 17.2344 2.38281 16.0312L1.85156 4.76562H0.984375C0.609375 4.76562 0.296875 4.46875 0.296875 4.07812C0.296875 3.69531 0.609375 3.39844 0.984375 3.39844H4.45312V2.22656C4.45312 1.03125 5.21875 0.320312 6.50781 0.320312H9.47656C10.7734 0.320312 11.5312 1.03125 11.5312 2.22656V3.39844H15.0078C15.3984 3.39844 15.7031 3.69531 15.7031 4.07812C15.7031 4.46875 15.3984 4.76562 15.0078 4.76562H14.1484L13.6172 16.0234C13.5625 17.2266 12.7891 17.9531 11.5938 17.9531H4.41406ZM5.89062 2.29688V3.39844H10.0938V2.29688C10.0938 1.89062 9.8125 1.63281 9.375 1.63281H6.60938C6.17188 1.63281 5.89062 1.89062 5.89062 2.29688ZM4.5625 16.5703H11.4219C11.8672 16.5703 12.1797 16.2578 12.1953 15.7969L12.7031 4.76562H3.26562L3.79688 15.7969C3.82031 16.2578 4.13281 16.5703 4.5625 16.5703ZM5.69531 15.3125C5.38281 15.3125 5.16406 15.1094 5.15625 14.7969L4.92188 6.60938C4.91406 6.30469 5.125 6.09375 5.45312 6.09375C5.76562 6.09375 5.98438 6.29688 5.99219 6.60938L6.22656 14.7969C6.23438 15.1016 6.02344 15.3125 5.69531 15.3125ZM8 15.3125C7.67188 15.3125 7.45312 15.1016 7.45312 14.7969V6.60938C7.45312 6.30469 7.67188 6.09375 8 6.09375C8.32812 6.09375 8.54688 6.30469 8.54688 6.60938V14.7969C8.54688 15.1016 8.32812 15.3125 8 15.3125ZM10.3047 15.3125C9.97656 15.3125 9.75781 15.1016 9.76562 14.7969L10.0078 6.60938C10.0156 6.29688 10.2344 6.09375 10.5469 6.09375C10.8672 6.09375 11.0859 6.30469 11.0781 6.60938L10.8359 14.7969C10.8281 15.1094 10.6172 15.3125 10.3047 15.3125Z"
      fill="#001640"
      fillOpacity="0.4"
    />
  </svg>
))

export { Trash }
export default Trash
