import React, { FunctionComponent, RefCallback, useCallback, useState } from 'react'

import { Plus } from 'assets/svgIconComponents'
import { Button, Input } from 'components/CasusComponents'
import { classes, FolderTreeModalCreateProps } from '.'

export const Create: FunctionComponent<FolderTreeModalCreateProps> = ({ createCallback, closeCallback }) => {
  const [input, setInput] = useState<HTMLInputElement>()
  const inputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setInput(node), [])

  return (
    <div className={classes.modal.createContent}>
      <Input
        ref={inputRef}
        type="text"
        defaultValue=""
        placeholder="Folder name"
        autoselect
        onConfirm={event => {
          if ((event.target as HTMLInputElement).value) {
            createCallback((event.target as HTMLInputElement).value)
            closeCallback()
          }
        }}
        showActionButtons={false}
      />
      <Button onClick={() => closeCallback()}>Cancel</Button>
      <Button
        onClick={() => {
          if (input?.value) {
            createCallback(input.value)
            closeCallback()
          }
          input?.focus()
        }}
        primary
      >
        <Plus />
        Create
      </Button>
    </div>
  )
}

Create.displayName = 'Casus-Components-FolderTree-Modal-Create'

export default Create
