import React, { FunctionComponent, useMemo } from 'react'

import { Caret } from 'assets/svgIconComponents'
import { BreadcrumbsProps, classes } from '.'
import Button from '../Button'

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ folders, activePath, onClick }) => {
  const activeIds = useMemo(() => activePath.split('.'), [activePath])

  const activeFolderButtons = useMemo(
    () =>
      folders
        .filter(({ id }) => activeIds.includes(id))
        .sort(({ id: id1 }, { id: id2 }) => activeIds.indexOf(id1) - activeIds.indexOf(id2))
        .map(({ id, name }) => (
          <Button key={`Casus-Components-Breadcrumbs-${id}`} onClick={() => onClick(id)} onClickBlur transparent>
            <span>{name}</span>
          </Button>
        )),
    [folders, activeIds, onClick]
  )
  const render = useMemo(() => {
    return activeFolderButtons.length > 1
      ? new Array(activeFolderButtons.length * 2 - 1)
          .fill(null)
          .map((_, i) => (i % 2 ? <Caret key={`Casus-Components-Breadcrumbs-Caret-${(i + 1) / 2}`} /> : activeFolderButtons.shift()))
      : activeFolderButtons
  }, [activeFolderButtons])

  return <div className={classes.wrapper}>{render}</div>
}

Breadcrumbs.displayName = 'Casus-Components-Breadcrumbs'

export default Breadcrumbs
