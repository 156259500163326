import RemindersPanel from './RemindersPanel'
import EmptyReminders from './EmptyReminders'

const classes = {
  wrapper: 'Homepage-dashboard-grid-panel-wrapper',
  title: {
    wrapper: 'Homepage-dashboard-grid-panel-title-wrapper',
    showAll: 'Homepage-dashboard-grid-panel-title-showAll',
    textAndIcon: 'Homepage-dashboard-grid-panel-title-textAndIcon',
  },
  empty: {
    wrapper: 'Homepage-dashboard-grid-panel-empty-wrapper',
    icon: 'Homepage-dashboard-grid-panel-empty-icon',
    text: 'Homepage-dashboard-grid-panel-empty-text',
  },
  list: {
    wrapper: 'Homepage-dashboard-reminders-list-wrapper',
    item: {
      wrapper: 'Homepage-dashboard-reminders-list-item-wrapper',
      title: 'Homepage-dashboard-reminders-list-item-title',
      message: 'Homepage-dashboard-reminders-list-item-message',
      time: 'Homepage-dashboard-reminders-list-item-time',
    },
    skeletonItem: {
      wrapper: 'Homepage-dashboard-reminders-list-skeletonItem-wrapper',
      title: 'Homepage-dashboard-reminders-list-skeletonItem-title',
      message: 'Homepage-dashboard-reminders-list-skeletonItem-message',
      time: 'Homepage-dashboard-reminders-list-skeletonItem-time',
    },
  },
}

export { classes, EmptyReminders }

export default RemindersPanel

