import { bindActionCreators, compose } from 'redux'
import { interceptorMiddleware, loggerMiddleware } from './middleware'

import { actionCreators as generalStoreActions } from './storeSegments/actions'
import { interceptors as generalStoreInterceptors } from './storeSegments/interceptor'

// :::::::::::::::::::::::: WIZARD :::::::::::::::::::::::: //
import { actionCreators as wizardActions } from './storeSegments/wizard/actions'
import { actionCreators as typifiedWizardActions } from './storeSegments/wizard/typified/actions'
import { selectors as wizardSelectors } from './storeSegments/wizard/selectors.js'
import { selectors as typifiedWizardSelectors } from './storeSegments/wizard/typified/selectors'
import { interceptors as wizardInterceptors } from './storeSegments/wizard/interceptor'
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: //
// ::::::::::::::::::::::::: APP ::::::::::::::::::::::::: //
import { actionCreators as appAction } from './storeSegments/app/actions'
import { selectors as appSelectors } from './storeSegments/app/selectors'
import { interceptors as appInterceptors } from './storeSegments/app/interceptor'
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: //

const storeActions = Object.assign({}, generalStoreActions, wizardActions, typifiedWizardActions, appAction)
const storeSelectors = Object.assign({}, wizardSelectors, typifiedWizardSelectors, appSelectors)
const storeInterceptors = Object.assign({}, generalStoreInterceptors, wizardInterceptors, appInterceptors)

// console.log('STORE ACTIONS: ', storeActions)
// console.log('STORE SELECTORS: ', storeSelectors)

const unpackActionKey = key => (key.slice(-7) === 'Creator' ? key.slice(0, -7) : key)
// const unpackActionKeys = keys => keys.map(k => (k.slice(-7) === 'Creator' ? k.slice(0, -7) : k))

const getActions = keys => keys.reduce((acc, cur) => Object.assign(acc, { [cur]: storeActions[unpackActionKey(cur)] }), {})

export const mapSelectorToValue = name => {
  if (!name.match(/select[A-Z].+/)) throw Error(`${name} does not match selector naming pattern.`)
  return name[6].toLowerCase() + name.slice(7)
}

export const unpackSelectorKey = key => {
  const regex = /(?<=^)[^[]+(?=[[]|$)|(?<=[[,])[^,\]]*/g
  const params = key.match(regex)
  const selectorName = params.shift()
  // const mappedKey = mapSelectorToValue(selectorName)
  return [selectorName, params]
}

const getSelectors = keys =>
  keys.reduce((acc, cur) => {
    const [selectorName, params] = unpackSelectorKey(cur)
    const [selector, selectorMethod] = Object.entries(storeSelectors).find(([key]) => key === selectorName) || []
    if (selector) Object.assign(acc, { [cur]: state => selectorMethod(state, ...params) })
    return acc
  }, {})

// const getSelectors = keys =>
//   Object.entries(storeSelectors).reduce((acc, [key, value]) => {
//     if (keys.indexOf(key) !== -1) Object.assign(acc, { [key]: value })
//     console.log(keys, key, keys.indexOf(key))
//     return acc
//   }, {})

const logSelectors = true

const addHookMethods = store => {
  store.getActions = keys => bindActionCreators(getActions(keys), store.dispatch)
  store.getActionCreators = keys => getActions(keys)
  // store.getActionCreators = keys => getActions(unpackActionKeys(keys))
  store.getSelectors = getSelectors
  store.getAllSelectors = () => store.getSelectors(Object.keys(storeSelectors))
  store.select = keys =>
    Object.entries(store.getSelectors(keys)).reduce(
      (acc, [key, value]) => Object.assign(acc, { [mapSelectorToValue(key)]: value(store.getState()) }),
      {}
    )

  store.subscriptions = {
    watchedSelectors: {},
    watchedValues: {},
    set: new Set(),
  }

  const subscriptionsSet = store.subscriptions.set
  const { watchedSelectors } = store.subscriptions

  const watch = selectorName => {
    watchedSelectors[selectorName] = (watchedSelectors[selectorName] || 0) + 1
  }
  const unwatch = selectorName => {
    if (watchedSelectors[selectorName]) watchedSelectors[selectorName] -= 1
    if (watchedSelectors[selectorName] < 1) delete watchedSelectors[selectorName]
  }

  store.subscribe(() => {
    if (logSelectors) {
      const subCount = subscriptionsSet.size
      const uSelCount = Object.keys(watchedSelectors).length
      const selCount = Object.values(watchedSelectors).reduce((result, count) => result + count, 0)
      if (process.env.NODE_ENV === 'development') {
        console.groupCollapsed('SUBSCRIBER COUNT: ', subCount, '\nUNIQUE SELECTOR COUNT: ', uSelCount, '\nSELECTOR COUNT: ', selCount)
        Object.entries(watchedSelectors).forEach(([key, count]) => console.log(key, ': ', count))
        console.groupEnd()
      }
    }

    const { watchedValues } = store.subscriptions
    const newValues = store.select(Object.keys(watchedSelectors))
    const changed = Object.entries(newValues).reduce(
      (acc, [key, value]) => Object.assign(acc, value !== watchedValues[key] ? { [key]: value } : {}),
      {}
    )

    // console.log('SUBS: ', subscriptionsSet)
    // console.groupCollapsed('NAMES')
    // Array.from(subscriptionsSet).forEach(({ names }) => console.log('SUB NAMES ARRAY: ', names))
    // console.groupEnd()
    // console.log('NEW VALUES: ', newValues)
    // console.log('CHANGED: ', changed)

    store.subscriptions.watchedValues = newValues

    subscriptionsSet.forEach(subscription => {
      const relevantChanges = Object.entries(changed)
        .filter(([key]) => subscription.names.includes(key))
        .reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {})
      // console.log('RELEVANT CHANGES: ', relevantChanges, subscription)
      if (Object.keys(relevantChanges).length) subscription.fn(relevantChanges)
    })
  })

  store.subscribeToSelectors = (keys, callback) => {
    if (keys.length === 0) return () => {}

    const subscription = { fn: callback, names: keys.map(mapSelectorToValue) }

    subscriptionsSet.add(subscription)
    keys.forEach(watch)
    Object.assign(store.subscriptions.watchedValues, store.select(keys))
    callback(store.select(keys))

    return () => {
      subscriptionsSet.delete(subscription)
      keys.forEach(unwatch)
    }
  }
}

export const hookSubscribeEnhancer = createStore => (rootReducer, preloadedState, enhancer) => {
  const store = createStore(rootReducer, preloadedState, enhancer)
  // console.log('APPLYING: hookSubscribeEnhancer')
  addHookMethods(store)
  return store
}

const applyInterceptors = store => (store.interceptors = storeInterceptors)

export const interceptorMiddlewareEnabler = createStore => (rootReducer, preloadedState, enhancer) => {
  const store = createStore(rootReducer, preloadedState, enhancer)
  // console.log('APPLYING: interceptorMiddlewareEnhancer')
  applyInterceptors(store)
  return store
}

const applyMiddleware =
  (...middleware) =>
  createStore =>
  (rootReducer, preloadedState, enhancer) => {
    const store = createStore(rootReducer, preloadedState, enhancer)
    const middlewareWithStore = middleware.map(m => m(store))
    const composedMiddleware = compose(...middlewareWithStore)(store.dispatch)
    store.dispatch = composedMiddleware
    // console.log('APPLYING: middlewareEnhancer')
    return store
  }

export const middlewareEnhancer = applyMiddleware(interceptorMiddleware, loggerMiddleware)
