import React from 'react'
import { Container, Title, Subtitle, Image } from './TrashEmpty.styles'
import trash_svg from './trash.svg'
import { useTranslation } from "react-i18next"

const TrashEmpty: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Image src={trash_svg} alt='trash img' />
      <Title variant='h4' align='center'>
        {t("bin_is_empty")}
      </Title>
      <Subtitle>
        {t("move_files_not_needed_to_bin")}
      </Subtitle>
    </Container>
  )
}

export default TrashEmpty
