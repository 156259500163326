import React, { FunctionComponent, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { SECURITY_LEVELS } from '___types'
import { useTemplate } from '___hooks'
import { Public } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import useModal from 'Modal'
import { TemplatePublishingModalContentContext, TemplatePublishingProps } from '.'
import ModalContent from './Template.ModalContent'

const defaultSettings = {
  customLastStepAvailable: false,
  lastStepConfig: { message: '', ctaLabel: '', ctaLink: '' },
  previewAvailable: false,
  signatureAvailable: false,
  signatureConfig: { concludeText: 'Save', concludeDisabled: false, security: SECURITY_LEVELS.BASE, signees: [], message: '' },
  expires: false,
  expirationTime: DateTime.now().toISO(),
  singleUse: false,
  split: null,
}

export const Publishing: FunctionComponent<TemplatePublishingProps> = React.memo(({ id }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.template' })
  const { data: { splits, publicSettings, isPublic } = {}, publish, publishing } = useTemplate(id)
  const defaultContext = useMemo(
    () =>
      Object.assign({}, defaultSettings, publicSettings, {
        signatureConfig: Object.assign({}, defaultSettings.signatureConfig, publicSettings?.signatureConfig),
      }),
    [publicSettings]
  )
  const context = useRef(JSON.parse(JSON.stringify(defaultContext)) as TemplatePublishingModalContentContext)
  const open = useModal({
    header: 'Publish template',
    close: false,
    content: (...params) => <ModalContent conclude={params[2]} context={params[3] as TemplatePublishingModalContentContext} splits={splits} />,
    context: context.current,
    onClose: context => Object.assign(context, JSON.parse(JSON.stringify(defaultContext))),
    onConclude: (context, settings) => {
      const {
        customLastStepAvailable,
        lastStepConfig,
        previewAvailable,
        signatureAvailable,
        signatureConfig,
        expires,
        expirationTime,
        singleUse,
        split,
      } = Object.assign(context, settings) as TemplatePublishingModalContentContext
      publish(
        customLastStepAvailable,
        lastStepConfig,
        previewAvailable,
        signatureAvailable,
        signatureConfig,
        expires,
        expirationTime,
        singleUse,
        split
      )
    },
    footer: false,
  })

  useEffect(() => {
    Object.assign(context.current, publicSettings, {
      signatureConfig: Object.assign({}, context.current.signatureConfig, publicSettings?.signatureConfig),
    })
  }, [publicSettings])

  return (
    <Button onClick={() => open()} onClickBlur disabled={publishing} loading={publishing}>
      <Public />
      {translate(isPublic ? 'republish' : 'publish')}
    </Button>
  )
})

Publishing.displayName = 'Casus-Components-Publishing-Template'

export default Publishing
