import React, { useMemo, useState } from 'react'
import useStore from '___store'

import { useFetchExternalServices } from '___queries'
import { classes } from '.'
import { Button, Input, Select } from 'components/CasusComponents'
import { Cogwheel } from 'assets/svgIconComponents'

export const Integrations = React.memo(() => {
  const [integrationCue, setCue] = useState('')
  const [integrationId, setId] = useState('')
  const { wizardIntegrations, setConfiguring, instantiateIntegration } = useStore(
    'selectWizardIntegrations',
    'setConfiguring',
    'instantiateIntegration'
  )
  const { data, isLoading } = useFetchExternalServices()

  console.log('DATA: ', data)

  const entries = useMemo(
    () =>
      wizardIntegrations
        ? Object.entries(wizardIntegrations).map(([id, entry]) => (
            <div key={`Integrations-entry-${id}`} className={classes.integratons.entry}>
              <div className={classes.integratons.entryInfo}>
                <span>{entry.cue}</span>
                <span>{entry.fields?.length || 0} fields selected</span>
              </div>
              <Button onClick={() => setConfiguring({ key: 'integration', id })}>
                <Cogwheel />
                Configure fields
              </Button>
            </div>
          ))
        : null,
    [wizardIntegrations, setConfiguring]
  )

  return data?.length ? (
    <div className={`${classes.section.wrapper} integrations`}>
      <span className={classes.section.title}>INTEGRATIONS</span>
      <div className={classes.integratons.config}>
        <Input
          className={classes.integratons.cueInput}
          type="text"
          placeholder="Give this integration a cue..."
          value={integrationCue}
          onInput={event => setCue(event.target.value)}
        />
        <Select
          value={integrationId}
          options={data.reduce((result, { id, title }) => Object.assign(result, { [id]: title }), {})}
          onSelect={setId}
          loading={isLoading}
        />
        <Button
          onClick={() => {
            if (!(integrationId && integrationCue)) return
            instantiateIntegration({ id: integrationId, cue: integrationCue })
            setId('')
            setCue('')
          }}
          disabled={!(integrationId && integrationCue)}
          primary
        >
          Connect
        </Button>
      </div>
      {entries}
    </div>
  ) : null
})

Integrations.displayName = ''

export default Integrations
