import { DocumentsFilter, SignatureSecurityLevel, Signee } from '___types'

import Signature from './Signature'
import ModalContent from './Signature.ModalContent'
import Security from './Signature.Security'

import './style.scss'

const levelButtonClasses = {
  wrapper: 'Casus-Components-Signature-Security-LevelButton-wrapper',
  title: 'Casus-Components-Signature-Security-LevelButton-title',
  description: 'Casus-Components-Signature-Security-LevelButton-description',
} as const
const securityClasses = {
  wrapper: 'Casus-Components-Signature-Security-wrapper',
  title: 'Casus-Components-Signature-Security-title',
  description: 'Casus-Components-Signature-Security-description',
  entries: 'Casus-Components-Signature-Security-entries',
  configure: 'Casus-Components-Signature-Security-configure',
  levelButton: levelButtonClasses,
} as const
const signeesClasses = {
  wrapper: 'Casus-Components-Signature-ModalContent-Signees-wrapper',
  title: 'Casus-Components-Signature-ModalContent-Signees-title',
  description: 'Casus-Components-Signature-ModalContent-Signees-description',
  form: 'Casus-Components-Signature-ModalContent-Signees-form',
  entries: 'Casus-Components-Signature-ModalContent-Signees-entries',
  email: 'Casus-Components-Signature-ModalContent-Signees-email',
} as const
const modalContentClasses = {
  wrapper: 'Casus-Components-Signature-ModalContent-wrapper',
  signees: signeesClasses,
  message: 'Casus-Components-Signature-ModalContent-message',
  footer: 'Casus-Components-Signature-ModalContent-footer',
} as const
const classes = { wrapper: 'Casus-Components-Signature-wrapper', modalContent: modalContentClasses, security: securityClasses } as const

export type SignatureProps = { id: string; splitId?: string | null; publicFlow?: boolean; category?: DocumentsFilter | string }
export type ModalContentProps = {
  level?: SignatureSecurityLevel | null
  mailList?: Signee[]
  conclude: (...params: unknown[]) => void
  context?: Record<string, unknown>
}
export type SecurityProps = { level?: SignatureSecurityLevel | null; onSelect?: (security: SignatureSecurityLevel | null) => void; locked: boolean }
export type LevelButtonProps = { level: SignatureSecurityLevel; onClick?: () => void; disabled?: boolean }
export type SigneesProps = { signees?: Signee[]; onChange?: (signees: any[]) => void; locked?: string[] }

export { Signature, ModalContent, Security, classes, modalContentClasses, securityClasses, levelButtonClasses, signeesClasses }
export default Signature
