import React, { FunctionComponent, useMemo } from 'react'

import Page from './Page'
import { SectionContextProvider } from '..'
import { useSection } from '../customHooks'
import { WizardLayoutRightPaneEditorSectionProps, wizardLayoutRightPaneEditorSectionClasses as classes } from '../../../..'

export const Section: FunctionComponent<WizardLayoutRightPaneEditorSectionProps> = React.memo(({ index }) => {
  const { id, pages, layoutString } = useSection(index)!

  const render = useMemo(() => {
    if (!pages?.length) return []
    const render = pages.map((_, i) => <Page key={`${id}-${index}/${i}`} index={i}></Page>)
    return render
  }, [pages, id, index])

  const sectionClassName = [classes.wrapper, layoutString].filter(string => string).join(' ')

  return (
    <div id={id} className={sectionClassName}>
      <SectionContextProvider index={index}>{render}</SectionContextProvider>
    </div>
  )
})

Section.displayName = 'WizardLayout-RightPane-Editor-Section'

export default Section
