import ActionButton from './ActionButton'
import Cell from './Cell'
import CustomStyleSelect from './CustomStyleSelect'
import Paragraph from './Paragraph'
import Segment from './Segment'
import Table from './Table'

import './style.scss'
import './wizardEditorSegmentParagraphStyle.scss'

export { ActionButton, Cell, CustomStyleSelect, Paragraph, Segment, Table }
export default Segment
