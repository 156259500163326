import React, { useState } from 'react'

import { useHistory } from 'react-router'

import Sidebar from 'components/Sidebar/Sidebar.container'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useTranslation } from 'react-i18next'

const TeamPageSidebar: React.FC = () => {
  const { check } = useSubscriptionCheck()
  const { push, location } = useHistory()
  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const handleActionClick = (): void => {
    if (!check({ isFree: true, isStartup: true })) return
    push(`${location.pathname}?action=add`)
  }

  return (
    <Sidebar
      actionButtonText={t('new_member')}
      searchValue={search}
      isLoading={false}
      baseUrl='/team'
      handleActionClick={handleActionClick}
      handleSearchChange={e => setSearch(e.target.value)}
    />
  )
}

export default TeamPageSidebar
