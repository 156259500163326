import { SigningDocumentModal } from './SigningDocumentModal.jsx'

import './style.scss'

const classes = {
  container: 'Modal-Container-wrapper',
  input: {
    wrapper: 'Modal-Main-Input-wrapper',
    label: {
      wrapper: 'Modal-Main-Label-Wrapper',
    },
    description: {
      wrapper: 'Modal-Main-Description-Wrapper',
    },
  },
  button: {
    wrapper: 'Modal-Main-Button-wrapper',
  },
  email: {
    actions: {
      wrapper: 'Modal-Main-Email-Actions-wrapper',
    },
  },
  divider: 'Modal-Main-Divider-wrapper',
  empty: {
    container: { wrapper: 'Modal-Main-Empty-Container-Wrapper' },
    icon: { wrapper: 'Modal-Main-Empty-Icon-Wrapper' },
    span: { wrapper: 'Modal-Main-Empty-Span-Wrapper' },
  },
  normal: {
    span: { wrapper: 'Modal-Main-Span-Wrapper' },
    icon: { wrapper: 'Modal-Main-Icon-Wrapper' },
  },
}

export { classes }

export default SigningDocumentModal

