import React from 'react'

import { className } from './index'

const Check = React.memo(() => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <path d="M 30 47 L 45 62 L 70 37" stroke="currentColor" strokeWidth="10" strokeLinecap="round" fill="none" />
  </svg>
))

export { Check }
export default Check
