import { IconProps } from './Icon.types'
import React from 'react'

import * as IconEl from './Icons.styles'

const Icon = React.forwardRef((props: IconProps, ref?: React.Ref<SVGSVGElement>) => {
  const { iconName, ...otherProps } = props
  switch (iconName) {
    case 'arrow-left':
      return <IconEl.ArrowLeft ref={ref} className='customizeIcon' {...otherProps} />
    case 'arrow-left-long':
      return <IconEl.ArrowLeftLong ref={ref} className='customizeIcon' {...otherProps} />
    case 'arrow-right':
      return <IconEl.ArrowRight ref={ref} className='customizeIcon' {...otherProps} />
    case 'arrow-down-filled':
      return (
        <IconEl.ArrowDownFilled ref={ref} className='customizeIcon' {...otherProps} />
      )
    case 'arrow-up-filled':
      return <IconEl.ArrowUpFilled ref={ref} className='customizeIcon' {...otherProps} />
    case 'arrow-down-long':
      return <IconEl.ArrowDownLong ref={ref} className='customizeIcon' {...otherProps} />
    case 'close':
      return <IconEl.Close ref={ref} className='customizeIcon' {...otherProps} />
    case 'date':
    case 'calendar':
      return <IconEl.Calendar ref={ref} className='customizeIcon' {...otherProps} />
    case 'text':
    case 'textField':
      return <IconEl.Text ref={ref} className='customizeIcon' {...otherProps} />
    case 'radio':
      return <IconEl.Radio ref={ref} className='customizeIcon' {...otherProps} />
    case 'checkbox':
      return <IconEl.Checkbox ref={ref} className='customizeIcon' {...otherProps} />
    case 'cloud':
      return <IconEl.Cloud ref={ref} className='customizeIcon' {...otherProps} />
    case 'add':
      return <IconEl.Add ref={ref} className='customizeIcon' {...otherProps} />
    case 'check':
      return <IconEl.Check ref={ref} className='customizeIcon' {...otherProps} />
    case 'more':
      return <IconEl.More ref={ref} className='customizeIcon' {...otherProps} />
    case 'trash':
      return <IconEl.Trash ref={ref} className='customizeIcon' {...otherProps} />
    case 'number':
      return <IconEl.Number ref={ref} className='customizeIcon' {...otherProps} />
    case 'delete':
      return <IconEl.Delete ref={ref} className='customizeIcon' {...otherProps} />
    case 'share':
      return <IconEl.Share ref={ref} className='customizeIcon' {...otherProps} />
    case 'preview':
      return <IconEl.Preview ref={ref} className='customizeIcon' {...otherProps} />
    case 'download':
      return <IconEl.Download ref={ref} className='customizeIcon' {...otherProps} />
    case 'edit':
      return <IconEl.Edit ref={ref} className='customizeIcon' {...otherProps} />
    case 'restore':
      return <IconEl.Restore ref={ref} className='customizeIcon' {...otherProps} />
    case 'move':
      return <IconEl.Move ref={ref} className='customizeIcon' {...otherProps} />
    case 'reminder':
      return <IconEl.Reminder ref={ref} className='customizeIcon' {...otherProps} />
    case 'duplicate':
      return <IconEl.Duplicate ref={ref} className='customizeIcon' {...otherProps} />
    case 'rename':
      return <IconEl.Rename ref={ref} className='customizeIcon' {...otherProps} />
    case 'list':
      return <IconEl.List ref={ref} className='customizeIcon' {...otherProps} />
    case 'pdf':
      return <IconEl.PDF ref={ref} className='customizeIcon' {...otherProps} />
    case 'docx':
      return <IconEl.DOCX ref={ref} className='customizeIcon' {...otherProps} />
    case 'word':
      return <IconEl.WORD ref={ref} className='customizeIcon' {...otherProps} />
    case 'folder':
      return <IconEl.Folder ref={ref} className='customizeIcon' {...otherProps} />
    case 'add-folder':
      return <IconEl.AddFolder ref={ref} className='customizeIcon' {...otherProps} />
    case 'settings':
      return <IconEl.Settings ref={ref} className='customizeIcon' {...otherProps} />
    case 'upload':
      return <IconEl.Upload ref={ref} className='customizeIcon' {...otherProps} />
    case 'shared_with':
      return <IconEl.SharedWith ref={ref} className='customizeIcon' {...otherProps} />
    case 'error':
      return <IconEl.ErrorI ref={ref} className='customizeIcon' {...otherProps} />
    case 'sign':
      return <IconEl.Sign ref={ref} className='customizeIcon' {...otherProps} />
    case 'circle-plus':
      return <IconEl.CirclePlus ref={ref} className='customizeIcon' {...otherProps} />
    case 'circle-times':
      return <IconEl.CircleTimes ref={ref} className='customizeIcon' {...otherProps} />
    case 'documents':
      return <IconEl.Documents ref={ref} className='customizeIcon' {...otherProps} />
    case 'templates':
      return <IconEl.Templates ref={ref} className='customizeIcon' {...otherProps} />
    case 'users':
      return <IconEl.Users ref={ref} className='customizeIcon' {...otherProps} />
    case 'logout':
      return <IconEl.Logout ref={ref} className='customizeIcon' {...otherProps} />
    case 'use':
      return <IconEl.Use ref={ref} className='customizeIcon' {...otherProps} />
    case 'ses':
      return <IconEl.SES ref={ref} className='customizeIcon' {...otherProps} />
    case 'aes':
      return <IconEl.AES ref={ref} className='customizeIcon' {...otherProps} />
    case 'qes':
      return <IconEl.QES ref={ref} className='customizeIcon' {...otherProps} />
    case 'europe':
      return <IconEl.Europe ref={ref} className='customizeIcon' {...otherProps} />
    case 'switzerland':
      return <IconEl.Switzerland ref={ref} className='customizeIcon' {...otherProps} />
  
    default:
      return null
  }
})

export default Icon
