import React from 'react'

import { className } from './index'

const TimePicker = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <circle cx="50" cy="50" r="35" stroke="currentColor" strokeWidth="8" strokeLinecap="round" fill="none" />
    <line x1="50" y1="25" x2="50" y2="50" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" />
    <line x1="50" y1="50" x2="65" y2="50" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" />
  </svg>
))

export { TimePicker }
export default TimePicker
