import React from 'react'

import { className } from './index'

const Move = React.memo(() => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.26562 15.1641C1.57812 15.1641 0.703125 14.2969 0.703125 12.625V3.11719C0.703125 1.44531 1.57812 0.578125 3.26562 0.578125H12.7266C14.4141 0.578125 15.2891 1.44531 15.2891 3.11719V12.625C15.2891 14.2891 14.4141 15.1641 12.7266 15.1641H3.26562ZM3.35938 13.6328H12.6328C13.3516 13.6328 13.7578 13.25 13.7578 12.4922V3.24219C13.7578 2.48438 13.3516 2.10938 12.6328 2.10938H3.35938C2.63281 2.10938 2.23438 2.48438 2.23438 3.24219V12.4922C2.23438 13.25 2.63281 13.6328 3.35938 13.6328ZM12.0312 7.85156C12.0312 8.07031 11.9453 8.24219 11.7734 8.40625L9.125 11.0156C9 11.1406 8.85156 11.2109 8.65625 11.2109C8.29688 11.2109 8.03125 10.9297 8.03125 10.5703C8.03125 10.375 8.10938 10.2109 8.23438 10.0938L9.1875 9.1875L10.0703 8.47656L8.46094 8.54688H4.625C4.23438 8.54688 3.95312 8.25 3.95312 7.85156C3.95312 7.45312 4.23438 7.16406 4.625 7.16406H8.46094L10.0625 7.23438L9.17969 6.52344L8.23438 5.60938C8.11719 5.49219 8.03125 5.32812 8.03125 5.14062C8.03125 4.77344 8.29688 4.50781 8.65625 4.50781C8.85156 4.50781 9 4.5625 9.125 4.6875L11.7734 7.30469C11.9531 7.48438 12.0312 7.64844 12.0312 7.85156Z"
      fill="#001640"
      fill-opacity="0.4"
    />
  </svg>
))

export default Move

