import React, { useMemo } from 'react'
import { DateTime } from 'luxon'

const classes = {
  wrapper: 'Input-wrapper',
  input: 'Input-input',
  buttonGroup: 'Input-buttonGroup',
  confirmButton: 'Input-confirmButton',
  discardButton: 'Input-discardButton',
}

export const DateTimeInput = React.memo(({ id, className: cn, inputClass: ic, disabled, value, onChange, minimum, maximum, autofocus = false }) => {
  const className = useMemo(() => [classes.wrapper, cn].filter(cn => cn).join(' '), [cn])
  const inputClass = useMemo(() => [classes.input, ic].filter(ic => ic).join(' '), [ic])

  return (
    <div id={id} className={className} data-type="date-time">
      <input
        type="datetime-local"
        className={inputClass}
        tabIndex={disabled ? -1 : 0}
        value={value}
        min={minimum}
        max={maximum}
        onChange={event => typeof onChange === 'function' && onChange(DateTime.fromFormat(event.target.value, "yyyy-MM-dd'T'HH:mm").toISO())}
        autoFocus={autofocus}
      />
    </div>
  )
})

DateTimeInput.displayName = 'Input'

export default DateTimeInput
