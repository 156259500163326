import { FunctionComponent } from 'react'

import { ColumnProps } from '.'

export const Column: FunctionComponent<ColumnProps> = () => {
  return null
}

Column.displayName = 'Casus-Components-Table-Column'

export default Column
