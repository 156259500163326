import React, { FunctionComponent, RefCallback, useCallback, useState } from 'react'

import { Caret, Cross, Plus, Spinner } from 'assets/svgIconComponents'
import { Button, CheckBox, Input } from 'components/CasusComponents'
import { MainLayoutRightPaneTemplateSharingProps, mainLayoutRightPaneTemplateSharingClasses as classes } from '../../../..'
import { Author } from '___types'

const emailRegex = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
)

export const Sharing: FunctionComponent<MainLayoutRightPaneTemplateSharingProps> = React.memo(
  ({ id, shareCallback, unshareCallback, sharedWith }) => {
    const [collapsed, setCollapsed] = useState(true)
    const [use, setUse] = useState(true)
    const [edit, setEdit] = useState(false)
    const [emailValid, setEmailValid] = useState<boolean>()
    const [input, setInput] = useState<HTMLInputElement>()
    const inputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setInput(node), [])

    const validateEmail = useCallback((input: HTMLInputElement) => Boolean(input.value.match(emailRegex)), [])

    return (
      <div className={classes.wrapper} data-collapsed={collapsed ? '' : undefined}>
        <span className={classes.title}>
          Sharing
          <Button onClick={() => setCollapsed(prev => !prev)} onClickBlur>
            {collapsed ? 'Share' : <Caret />}
          </Button>
        </span>
        {!collapsed ? (
          <>
            <div className={classes.form}>
              <Input
                ref={inputRef}
                type="text"
                onInput={event => setEmailValid(validateEmail(event.target as HTMLInputElement))}
                placeholder="e-mail"
                autoselect
                options={{ format: 'email' }}
              />
              <Button
                onClick={() => {
                  if (emailValid && input?.value) shareCallback(input.value, use, edit)
                }}
                onClickBlur
                disabled={!emailValid}
                tertiary
              >
                <Plus />
                Share
              </Button>
              <CheckBox value={use} onChange={value => setUse(value)} label="Use" />
              <CheckBox value={edit} onChange={value => setEdit(value)} label="Edit" />
            </div>
            {sharedWith.map(({ id, firstName, lastName, email, mutating }: Author & { mutating?: boolean }) => (
              <div key={`MainLayout-RightPane-Template-Sharing-${email}`} className={classes.entry} data-mutating={mutating ? '' : undefined}>
                {mutating ? <Spinner /> : null}
                <span>{`${firstName ?? ''} ${lastName ?? ''}`}</span>
                <span>{email}</span>
                <Button onClick={() => unshareCallback(id)} disabled={!(id && !mutating)} transparent>
                  <Cross />
                </Button>
              </div>
            ))}
          </>
        ) : null}
      </div>
    )
  }
)

Sharing.displayName = 'MainLayout-RightPane-Template-Sharing'

export default Sharing
