import { IAnswer, Question } from 'types'
import React, { useState, useRef, Dispatch, SetStateAction } from 'react'
import QuestionnaireScreen, { IsOnEdgeQuestion } from 'components/features/documentGeneration/views/Questionnaire/Questionnaire.component'
import { questionnaireToStateV2, hasSameValues } from './services'
import { deepExtractSubquestionsFromOption } from 'utils'
import { createQuestionMap } from './services/createQuestionMap'
import { TDocumentGenerationSetter, useDocumentGeneration } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import { validateAnswers } from './services/validateAnswers'
import { generateDocumentHtmlData } from '../../services/generateDocumentHtmlData'
// import { useUser } from 'queries/user/useUser'
import TemplateApi from 'api/templates.api'

export const Questionnaire: React.FC<{
  finish: boolean
  isExpired?: boolean
  setFinish: Dispatch<SetStateAction<boolean>>
}> = ({ finish, isExpired, setFinish }) => {
  const { set, questions, answers, filteredQuestions, templateId, templateHtmlData, isCasus, fetchTemplateHtml, isPublic, documentId } = useDocumentGeneration()

  // const user = useUser()
  const docXmicro = true

  const questionsMapRef = useRef(createQuestionMap(questions, answers))
  const [answerState, setAnswerState] = useState(questionnaireToStateV2(createQuestionMap(questions, answers), answers))

  const updateAnswers = async (qid: string, answer: IAnswer, props: IsOnEdgeQuestion): Promise<void> => {
    if (isExpired) return
    let stateSetter: TDocumentGenerationSetter = {}

    if (finish) setFinish(false)
    const incomingVal = answer?.value
    const currentVal = answerState[qid]?.value

    const isDate = Object.prototype.toString.call(incomingVal) === '[object Date]'

    let changed = true
    if (currentVal && (typeof incomingVal === 'string' || isDate)) {
      changed = incomingVal !== currentVal
    } else if (currentVal && typeof incomingVal !== 'string' && typeof currentVal !== 'string') {
      changed = !hasSameValues(incomingVal, currentVal)
    }

    const newAnswerState = qid && answer ? { ...answerState, [qid]: answer } : answerState

    if (typeof answer.value !== 'string' && !isDate) {
      const opts = answer?.value
      const question = questionsMapRef.current.questions[answer?.questionId]
      if (question?.options) {
        const notSelectedOptions = question.options.filter(opt => !opts.includes(opt.id))
        const subqs: Question[] = []
        notSelectedOptions.forEach(opt => subqs.push(...deepExtractSubquestionsFromOption(opt)))
        subqs.forEach(subq => {
          delete newAnswerState[subq.id]
        })
      }
    }

    const { moveOn } = props

    if (qid && answer) setAnswerState(newAnswerState)

    if (changed || moveOn) {
      const sortedAnswers: IAnswer[] = []
      questionsMapRef.current.order.forEach(qid => {
        const answerChecked = newAnswerState[qid]

        if (
          qid &&
          answer &&
          answerChecked &&
          answerChecked.value &&
          (isDate ||
            answerChecked.type === 'ANSWER_TYPE_CHECKBOX' ||
            (answerChecked.type === 'ANSWER_TYPE_DATE' && answerChecked.value) ||
            answerChecked.value.length)
        ) {
          sortedAnswers.push(answerChecked)
        }
      })

      const templateDataStructure = !isPublic
        ? await (
            await TemplateApi.getOne(templateId, false, docXmicro, documentId)
          ).dataStructure
        : await (
            await TemplateApi.getOne(templateId, true, true, documentId)
          ).dataStructure

      console.log('templateDataStructure', templateDataStructure)

      const validAnswers = validateAnswers(sortedAnswers, questions)
      stateSetter = {
        templateId,
        answers: validAnswers,
        htmlData: generateDocumentHtmlData(templateHtmlData, questions, validAnswers, false),
        templateDataStructure,
        step: moveOn ? 2 : 1,
      }
    }

    if (filteredQuestions.length && moveOn) {
      stateSetter = { ...stateSetter, filteredQuestions: [] }
    }
    if (stateSetter?.step === 2 && !docXmicro) fetchTemplateHtml({ id: templateId, data: stateSetter?.answers || [] })
    set(stateSetter)
  }

  return (
    <QuestionnaireScreen
      editQuestionId={filteredQuestions[0]?.id}
      questionsMap={questionsMapRef.current}
      values={answerState}
      saving={false}
      isCasus={!!isCasus}
      finish={finish}
      updateAnswers={updateAnswers}
    />
  )
}
