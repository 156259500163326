import { IDocumentAuthor, IPermissions, SharedWithItem } from 'types'
import React, { useState, Fragment, useEffect } from 'react'
import { SharingFooter } from './Footer'
import { Loader, StandardModal, TextInputGroup } from 'components/common'
import {
  Container,
  PersonsContainer,
  StrongText,
  CustomButton,
  InputActionWrapper,
} from './Sharing.styles'
import { SharingPersonItem } from './SharingPersonItem'
import Validator from 'validator'

export interface ISharingLoaders {
  getLoading: boolean
  addSharingLoading: boolean
}

export interface ISharingFooterActions {
  handleSwitchChange: (val: boolean) => void
  handleFinishClick: () => void
}

interface Props extends ISharingLoaders, ISharingFooterActions {
  sharingEnabled: boolean
  sharedWith: SharedWithItem[]
  updateLoading: boolean
  open: boolean
  author: IDocumentAuthor | null
  error: boolean
  userId: string
  handleClose: () => void
  validateEmail: (email: string) => boolean
  handleShare: (email: string) => Promise<void>
  handleChangePermissions: (userId: string, permissions: IPermissions) => void
  handleDeletePerson: (userId: string) => void
}

export const SharingModalComponent: React.FC<Props> = ({
  sharingEnabled,
  sharedWith,
  getLoading,
  updateLoading,
  addSharingLoading,
  open,
  error,
  author,
  userId,
  handleClose,
  handleSwitchChange,
  handleShare,
  handleFinishClick,
  handleChangePermissions,
  handleDeletePerson,
  validateEmail,
}) => {
  const [sharing, setSharing] = useState(sharingEnabled)
  const [email, setEmail] = useState('')
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (saving && !addSharingLoading) {
      setSaving(false)
      if (!error) setEmail('')
    }
  }, [saving, addSharingLoading, error])

  const handleSwitchClick = (val: boolean): void => {
    handleSwitchChange(val)
    setSharing(val)
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const isValid = validateEmail(email)
    if (!isValid) return
    const sanitizedEmail = Validator.normalizeEmail(email)
    handleShare(sanitizedEmail || email)
    setSaving(true)
  }

  let content = null

  if (getLoading || updateLoading) {
    content = <Loader style={{ height: 345.5, padding: 0 }} />
  } else {
    content = (
      <Fragment>
        {sharing && (
          <Fragment>
            <StrongText style={{ fontSize: 16, margin: '0 0 24px 24px' }}>
              Per E-Mail
            </StrongText>
            <InputActionWrapper>
              <TextInputGroup
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{ height: 40, fontSize: 14 }}
                placeholder='E-Mail-Adresse eingeben'
              />
              <CustomButton
                btntype='primary-inverted'
                width={130}
                onClick={handleButtonClick}
                disabled={
                  email.length < 5 || addSharingLoading || !Validator.isEmail(email)
                }
              >
                Hinzufügen
              </CustomButton>
            </InputActionWrapper>
            <PersonsContainer>
              {author && (
                <SharingPersonItem
                  id={author.id}
                  key={author.id}
                  firstName={author.firstName}
                  lastName={`${author.lastName}${userId === author.id && ' (Ich)'}`}
                  email={author.email}
                  permissions={{ read: true, write: true }}
                  owner
                />
              )}
              {sharedWith.map(person => (
                <SharingPersonItem
                  key={person.id + person.firstName}
                  updateLoading={addSharingLoading}
                  {...person}
                  handleChangePermissions={handleChangePermissions}
                  handleDeletePerson={handleDeletePerson}
                />
              ))}
            </PersonsContainer>
          </Fragment>
        )}
      </Fragment>
    )
  }

  return (
    <StandardModal
      open={open}
      handleClose={handleClose}
      title='Datei teilen'
      primaryButtonDisabled={false}
    >
      <Container>
        {content}
        <SharingFooter
          getLoading={getLoading}
          sharingEnabled={sharing}
          addSharingLoading={addSharingLoading}
          handleSwitchChange={handleSwitchClick}
          handleFinishClick={handleFinishClick}
        />
      </Container>
    </StandardModal>
  )
}
