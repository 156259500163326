import { useQuery } from 'react-query'

import { templatesAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchTemplateFolderList = () => {
  const templateListQuery = useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_FOLDERS],
    queryFn: templatesAPI.getTemplateFolderList,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return templateListQuery
}

export default useFetchTemplateFolderList
