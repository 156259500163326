import React, { FunctionComponent } from 'react'
import { InfiniteData, useQueryClient } from 'react-query'

import { MAPPED_TEMPLATES_FILTERS, ITemplate } from '___types'
import { QUERY_KEYS } from '___queries'
import { ListItemProps, listItemClasses as classes } from '.'

export const TemplateListItem: FunctionComponent<ListItemProps> = React.memo(({ id, onClick }) => {
  const queryClient = useQueryClient()

  const allTemplates = Object.values(MAPPED_TEMPLATES_FILTERS).reduce((allTemplates: ITemplate[], filter) => {
    const templatesQuery = queryClient.getQueryData([QUERY_KEYS.TEMPLATES, filter]) as InfiniteData<ITemplate[]>
    const templates = (templatesQuery?.pages || []).reduce((filterTemplates, page) => filterTemplates.concat(page), [])
    return allTemplates.concat(templates) as ITemplate[]
  }, [])
  const template = (allTemplates.find(template => template.id === id) || {}) as ITemplate
  const { name } = template

  return (
    <button className={classes.wrapper} type="button" onClick={event => typeof onClick === 'function' && onClick(event, id)}>
      {name}
    </button>
  )
})

TemplateListItem.displayName = 'Casus-Components-TemplateListItem'

export default TemplateListItem
