import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router'

import { Templates, Documents } from './LeftPane.components'
import { MainLayoutLeftPaneProps, mainLayoutLeftPaneClasses as classes } from '../..'

export const LeftPane: FunctionComponent<MainLayoutLeftPaneProps> = React.memo(() => {
  return (
    <section className={classes.wrapper}>
      <Switch>
        <Route path="/documents">
          <Documents />
        </Route>
        <Route path="/templates">
          <Templates />
        </Route>
      </Switch>
    </section>
  )
})

LeftPane.displayName = 'MainLayout-LeftPane'

export default LeftPane
