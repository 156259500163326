import { v4 as uuid } from 'uuid'

import { evaluateQuestionConditionalOutcome, fullAssign, applyParagraphNumbering } from './index'
import { getMarkerById, getQuestionById, getQuestionOptionById } from './template-creation'
import { CASUS_KEYSTRINGS } from 'Wizard/constants'

const setAnswering = (state, payload) => {
  return fullAssign({}, state, { answering: payload.id })
}

const getFirstVisibleId = (state, ids) => ids?.find(id => evaluateQuestionConditionalOutcome(state, id))
const getNextVisibleId = (state, group, index = -1) => getFirstVisibleId(state, group?.questions?.slice(index + 1))

const getFirstVisibleGroup = (state, groups) => groups?.find(g => g.questions?.some(qid => evaluateQuestionConditionalOutcome(state, qid)))
const getNextVisibleGroup = (state, index = -1) => getFirstVisibleGroup(state, state.questionLayout.slice(index + 1))

const goToNextQuestion = state => {
  const answering = state.answering
  let groupIndex
  const group = state.questionLayout.find(
    ({ id, questions }, i) => (id === answering || questions?.includes(answering)) && ((groupIndex = i) || true)
  )
  let next
  if (group?.type === 'loose') next = getNextVisibleId(state, group, group.questions?.indexOf(answering))
  if (!next) {
    const nextGroup = getNextVisibleGroup(state, groupIndex)
    next = nextGroup?.type === 'loose' ? nextGroup.questions[0] : nextGroup?.id
  }
  return setAnswering(state, { id: next || 'finish' })
}

const getPrevVisibleId = (state, group, index) => getFirstVisibleId(state, group?.questions?.slice(0, index).reverse())
const getPrevVisibleGroup = (state, index = -1) => getFirstVisibleGroup(state, state.questionLayout.slice(0, index).reverse())

const goToPreviousQuestion = state => {
  const answering = state.answering
  let groupIndex
  const group = state.questionLayout.find(
    ({ id, questions }, i) => (id === answering || questions?.includes(answering)) && ((groupIndex = i) || true)
  )
  let prev
  if (group?.type === 'loose') prev = getPrevVisibleId(state, group, group.questions?.indexOf(answering))
  if (!prev) {
    const prevGroup = getPrevVisibleGroup(state, groupIndex)
    prev = prevGroup?.type === 'loose' ? prevGroup.questions[0] : prevGroup?.id
  }
  return setAnswering(state, { id: prev || 'start' })
}

export { goToNextQuestion, goToPreviousQuestion }

const genericAnswer = { id: '', values: [] }
const generateAnswer = answer => Object.assign({}, genericAnswer, { id: uuid(), values: [] }, answer)
const getAnswer = (state, test, index = -1) => [state.answers.find((answer, i) => test(answer) && ((index = i) || true)) || undefined, index]
const getAnswerById = (state, id) => (id ? getAnswer(state, answer => answer.id === id) : [undefined, -1])

// export const userValueRegex = /^(_casus_input:)?([^:]*){1}(:(.*))?$/
export const userValueRegex = /^(?<input>_casus_input:)?(?<id>(_external_[^:]+:[^:]+)|([^:]*)){1}(:(?<value>.*))?$/ms

const tidyAnswer = (state, id, update = false) => {
  const [answer, index] = getAnswerById(state, id)
  if (index === -1) return state
  const { optionGroups = [] } = getQuestionById(state, id)[0] || {}
  const resultingValues = answer.values
    .slice()
    .reverse()
    .reduce(
      (acc, cur) => {
        const { groups: { id } = {} } = cur.match(userValueRegex)
        const relevantGroup = acc[1].find(g => g.options.includes(id))
        return id && relevantGroup?.count ? Object.assign(relevantGroup, { count: relevantGroup.count - 1 }) && acc[0].push(cur) && acc : acc
      },
      [
        [],
        optionGroups.map(group => ({
          options: group.options.map(o => o.id),
          count: !(group.enforceLimit && group.maximum) ? Infinity : (group.select === 'single' && 1) || group.maximum,
        })),
      ]
    )[0]
    .reverse()
  return (answer.values.length !== resultingValues.length && state.answers.splice(index, 1, { id, values: resultingValues })) || update
    ? Object.assign({}, state)
    : state
}

const answerWithOption = (state, payload = {}) => {
  const [answer, index] = getAnswerById(state, payload.questionId)
  const { groups: { id: optionId, value: optionValue } = {} } = payload.value?.match(userValueRegex)
  if (!optionId) return state
  const [reducedValues, shift, update] = (answer?.values.slice().reverse() || []).reduce(
    (acc, cur) => {
      const { groups: { id, value } = {} } = cur.match(userValueRegex)
      acc[0].push(acc[1] || !(id === optionId && (acc[1] = true) && (acc[2] = acc[2] || value !== optionValue)) ? cur : payload.value)
      return acc
    },
    [[payload.value], false, false]
  )
  if (shift && !update) return state
  const resultingValues = (shift ? reducedValues.shift() && reducedValues : reducedValues).reverse()
  const resultingAnswer = generateAnswer(Object.assign({ id: payload.questionId, values: resultingValues }))
  return tidyAnswer(
    index === -1 ? fullAssign(state, { answers: state.answers.concat(resultingAnswer) }) : state.answers.splice(index, 1, resultingAnswer) && state,
    payload.questionId,
    true
  )
}

const unanswerQuestion = (state, payload = {}) => {
  const [answer, index] = getAnswerById(state, payload.id)
  return index === -1 || !answer.values.length ? state : state.answers.splice(index, 1, { id: payload.id, values: [] }) && Object.assign({}, state)
}

const unanswerOption = (state, payload = {}) => {
  const [answer, index] = getAnswerById(state, payload.questionId)
  if (index === -1) return state
  const resultingValues = answer.values.filter(string => string.match(userValueRegex)?.groups.id !== payload.id)
  return answer.values.length !== resultingValues.length && state.answers.splice(index, 1, { id: payload.questionId, values: resultingValues })
    ? Object.assign({}, state)
    : state
}

export { answerWithOption, unanswerQuestion, unanswerOption }

const evaluateMarker = (state, payload = {}, evaluateNumbering = true, update = false) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.id)
  if (!parentId) return state
  const allValues = state.answers.reduce((acc, { values }) => acc.concat(values), [])
  const keepValues = allValues.filter(string => marker.optionIds.includes(string.match(userValueRegex)?.groups.id))
  const keep = marker.defaultKeep ? !marker.optionIds.length || Boolean(keepValues.length) : keepValues.length === marker.optionIds.length
  const answer = getAnswerById(state, marker.questionId)[0]
  const replace = answer?.values
    .map(string => {
      const { groups: { id, input, value } = {} } = string.match(userValueRegex)
      if (input) return `{{${CASUS_KEYSTRINGS.replace}:${value}}}`
      const option = getQuestionOptionById(getQuestionById(state, marker.questionId)[0], id)[0]
      return `{{${CASUS_KEYSTRINGS.replace}:${option.value || option.text}}}`
    })
    .join('')
  const resultingMarker = Object.assign({}, marker)
  update = (keep !== marker.keep && Object.assign(resultingMarker, { keep })) || update
  update = (replace !== marker.replace && Object.assign(resultingMarker, { replace })) || update
  // console.group(marker.label || marker.contentText)
  // console.log('KEEP: ', keep)
  // console.log('PREV KEEP: ', marker.keep)
  // console.log('REPLACE: ', replace)
  // console.log('PREV REPLACE: ', marker.replace)
  // console.groupEnd()
  return update && markerArray.splice(index, 1, resultingMarker)
    ? (marker.type === 'segments' && evaluateNumbering && applyParagraphNumbering(state)) || Object.assign({}, state)
    : state
}

const evaluateAllMarkers = state =>
  state.mode !== 'document-generation'
    ? state
    : Object.values(Object.assign({}, state.locations.segments, state.locations.text))
        .reduce((idArray, markerArray) => idArray.concat(markerArray.map(({ id }) => id)), [])
        .reduce((currentState, id) => evaluateMarker(currentState, { id }, false), state)

export { evaluateMarker, evaluateAllMarkers }
