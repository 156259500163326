import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, CopyProps, className } from './index'

const ZOOM = 1.25

const Copy: FunctionComponent<CopyProps> = React.memo(({ type = 'plain' }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const iconId = useMemo(uuid, [])

  const linkScale = 1.2

  return (
    <svg className={`${className} Copy`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={`${iconId}-1`}>
          <rect x="0" y="0" width="100" height="100" stroke="none" fill="white" />
          <rect x="17.5" y="12.5" width="50" height="60" rx="8" ry="8" stroke="black" strokeWidth="15" fill="black" />
        </mask>
      </defs>
      {type === 'plain' ? (
        <>
          <rect
            x="32.5"
            y="27.5"
            width="50"
            height="60"
            rx="12"
            ry="12"
            stroke="currentColor"
            strokeWidth="5"
            fill="none"
            mask={`url(#${iconId}-1)`}
          />
          <rect x="17.5" y="12.5" width="50" height="60" rx="8" ry="8" stroke="currentColor" strokeWidth="5" fill="none" />
        </>
      ) : null}
      {type === 'link' ? (
        <g transform={`scale(${linkScale}) translate(${50 / linkScale - 50} ${50 / linkScale - 50}) rotate(30 50 50)`}>
          {/* <g transform={`scale(${linkScale}) translate(${42.5 / linkScale - 50} ${42.5 / linkScale - 50}) rotate(30 50 50)`}> */}
          <path
            d="M 50 44 h -2 c -5 0 -10 5 -10 10 v 15 c 0 5 5 10 10 10 h 4 c 5 0 10 -5 10 -10 v -8"
            stroke="currentColor"
            strokeWidth="5"
            strokeLinecap="round"
            fill="none"
          />
          <path
            d="M 50 56 h 2 c 5 0 10 -5 10 -10 v -15 c 0 -5 -5 -10 -10 -10 h -4 c -5 0 -10 5 -10 10 v 8"
            stroke="currentColor"
            strokeWidth="5"
            strokeLinecap="round"
            fill="none"
          />
        </g>
      ) : null}
    </svg>
  )
})

export { Copy }
export default Copy
