import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useCallback, useEffect } from 'react'

import { VIEWPORT_SIZE, CasusLogoProps, className } from '.'

const STROKE_LENGTH = 442
const STROKE_FILL = 0.1
const ANIMATION_DURATION = 3000

let mountCount = 0

const CasusLogo: FunctionComponent<CasusLogoProps> = React.memo(({ animate = false, strokeWidth = 1 }) => {
  const [path, setPath]: [SVGPathElement | undefined, Dispatch<SetStateAction<SVGPathElement | undefined>>] = useState()

  const id = `Spinner:${mountCount}`

  const ref: RefCallback<SVGPathElement | undefined> = useCallback(node => node && setPath(node), [])

  const dashFill = STROKE_LENGTH * STROKE_FILL
  const dashGap = STROKE_LENGTH * (1 - STROKE_FILL)
  const followStroke = 2

  useEffect(() => {
    mountCount = mountCount + 1
  }, [])

  useEffect(() => {
    if (path) {
      const animateElements = path.querySelectorAll('animate')
      if (animate) {
        // @ts-ignore
        animateElements[0]?.beginElement()
        // @ts-ignore
        animateElements[1]?.beginElement()
      } else {
        // @ts-ignore
        animateElements.forEach((element, i) => (i === 3 ? element.beginElement() : element.endElement()))
        // // @ts-ignore
        // if (setElement) setElement?.beginElement()
      }
      return
    }
  }, [path, animate])

  return (
    <svg
      className={`${className} CasusLogo`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${VIEWPORT_SIZE * 4.465} ${VIEWPORT_SIZE}`}
      preserveAspectRatio="none"
    >
      <defs>
        <mask id={`A-mask-${id}`} x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`}>
          <rect x="94" y="6" width="90" height="91" stroke="none" strokeWidth="0" fill="black" strokeLinejoin="round" strokeLinecap="round" />
          <path d="M 114 97 h -20 L 129 6 h 20 Z" stroke="none" strokeWidth="0" fill="white" strokeLinejoin="round" strokeLinecap="round" />
          <path d="M 164 97 h 20 L 149 6 h -20 Z" stroke="none" strokeWidth="0" fill="white" strokeLinejoin="round" strokeLinecap="round" />
          <rect x="115" y="62" width="48" height="15" stroke="none" strokeWidth="0" fill="white" strokeLinejoin="round" strokeLinecap="round" />
        </mask>
      </defs>
      <path
        ref={ref}
        d="M 70.5 35 A 21 21 0 0 0 50 19 A 25 31 0 0 0 50 81 A 21 21 0 0 0 71 61 h 19 A 40 37 0 0 1 50 97 A 45 47 0 0 1 43 96.5 L 5.5 57 A 45 47 0 0 1 50 3 A 40 37 0 0 1 89.5 35 Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="currentColor"
        strokeLinejoin="round"
        strokeLinecap="round"
      >
        <animate
          id={id}
          attributeName="stroke-dashoffset"
          values={`${dashFill}; -${STROKE_LENGTH - dashFill}`}
          dur={`${ANIMATION_DURATION}ms`}
          begin={`${id}.end`}
        />
        <animate
          id={`${id}dasharray`}
          attributeName="stroke-dasharray"
          values={`${dashFill} ${STROKE_LENGTH}; ${dashFill} ${dashGap}`}
          dur={`${ANIMATION_DURATION * STROKE_FILL}ms`}
          fill="freeze"
          begin=""
        />
        <animate
          attributeName="stroke-dasharray"
          keyTimes={`0; ${1 - STROKE_FILL}; 1`}
          values={`${dashFill - followStroke} 0 ${followStroke} ${dashGap};  ${
            dashFill - followStroke
          } ${dashGap} ${followStroke} 0; ${dashFill} ${dashGap} 0 0`}
          dur={`${ANIMATION_DURATION * 4}ms`}
          begin={`${id}dasharray.end`}
          repeatCount="indefinite"
        />
        <animate attributeName="stroke-dasharray" values={`${dashFill} ${dashGap}; ${STROKE_LENGTH} 0`} dur="1ms" fill="freeze" />
      </path>
      <rect
        x="94"
        y="6"
        width="90"
        height="91"
        stroke="none"
        strokeWidth="0"
        fill="currentColor"
        strokeLinejoin="round"
        strokeLinecap="round"
        mask={`url(#A-mask-${id})`}
      />
      <path
        d="M 205 67 h -19 C 182 110 267 110 263 67 C 260.5 39.5 210 44 208 33 C 205 14 239 17 240 33 h 19 C 259 -4 189 -4 189 33 C 189 64 243 55 243 72 C 243 89 206 89 205 67 Z"
        stroke="none"
        strokeWidth="0"
        fill="currentColor"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        d="M 274 6 v 61 C 274 110 351 110 351 67 v -61 h -20 v 60 C 329 89 296 89 294 67 v -61 Z"
        stroke="none"
        strokeWidth="0"
        fill="currentColor"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        d="M 383 67 h -19 C 360 110 445 110 441 67 C 438.5 39.5 388 44 386 33 C 383 14 417 17 418 33 h 19 C 437 -4 367 -4 367 33 C 367 64 421 55 421 72 C 421 89 384 89 383 67 Z"
        stroke="none"
        strokeWidth="0"
        fill="currentColor"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </svg>
  )
})

export { CasusLogo }
export default CasusLogo
