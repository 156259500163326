import { useQuery } from 'react-query'

import { documentsAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchDocumentFolderList = () => {
  const documentListQuery = useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_FOLDERS],
    queryFn: documentsAPI.getDocumentFolderList,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return documentListQuery
}

export default useFetchDocumentFolderList
