import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
// import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './rootReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

let configStore = applyMiddleware(...middleware)

const persistConfig = {
  key: 'primary',
  storage,
}

type RootReducer = typeof rootReducer

const theReducer: RootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER' || action.type === 'CHANGE_LANGUAGE') {
    return rootReducer(undefined, action)
  } else {
    return rootReducer(state, action)
  }
}

configStore = applyMiddleware(...middleware)

const persistedReducer = persistReducer(persistConfig, theReducer)

export default () => {
  const store = createStore(persistedReducer, configStore)
  const persistor = persistStore(store)
  return { store, persistor }
}
