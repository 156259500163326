import React, { FunctionComponent, useMemo, useState } from 'react'

import { DOCUMENT_DIRECTORY, DOCUMENTS_FILTERS, DocumentsFilter, TEMPLATE_DIRECTORY, TEMPLATES_FILTERS, TemplatesFilter } from '___types'
import { useDocumentFolder, useTemplateFolder } from '___hooks'
import { UseDocumentFolderHookUnknownIdReturnType } from '___hooks/useDocumentFolder'
import { UseTemplateFolderHookUnknownIdReturnType } from '___hooks/useTemplateFolder'
import { Bin, Caret, EditField, Folder, Person, Public, VerticalEllipsis } from 'assets/svgIconComponents'
import { FolderTreeProps, classes, useFolderTreeContext } from '.'
import Button from '../Button'
import Dropdown from '../Dropdown'

export const FolderTree: FunctionComponent<FolderTreeProps> = React.memo(
  ({ directory, folderStructure, onClick, parentId, activeId, activePath }) => {
    const [collapsed, setCollapsed] = useState(false)

    const { create: contextCreate, rename, remove: contextRemove } = useFolderTreeContext()
    const { id, label, children, mutating, mutation } = folderStructure
    const folderId = useMemo(
      () =>
        Object.values(DOCUMENTS_FILTERS)
          .concat(Object.values(TEMPLATES_FILTERS))
          .includes(id as DocumentsFilter | TemplatesFilter)
          ? ''
          : id,
      [id]
    )
    const {
      create: documentFolderCreate,
      update: documentFolderUpdate,
      remove: documentFolderRemove,
    } = useDocumentFolder((directory === DOCUMENT_DIRECTORY ? folderId! : null) as string) as UseDocumentFolderHookUnknownIdReturnType

    const {
      create: templateFolderCreate,
      update: templateFolderUpdate,
      remove: templateFolderRemove,
    } = useTemplateFolder((directory === TEMPLATE_DIRECTORY ? folderId! : null) as string) as UseTemplateFolderHookUnknownIdReturnType

    const create = useMemo(() => {
      if (directory === DOCUMENT_DIRECTORY) return documentFolderCreate
      if (directory === TEMPLATE_DIRECTORY) return templateFolderCreate
    }, [directory, documentFolderCreate, templateFolderCreate])

    const update = useMemo(() => {
      if (directory === DOCUMENT_DIRECTORY) return documentFolderUpdate
      if (directory === TEMPLATE_DIRECTORY) return templateFolderUpdate
    }, [directory, documentFolderUpdate, templateFolderUpdate])

    const remove = useMemo(() => {
      if (directory === DOCUMENT_DIRECTORY) return documentFolderRemove
      if (directory === TEMPLATE_DIRECTORY) return templateFolderRemove
    }, [directory, documentFolderRemove, templateFolderRemove])

    const fullFolderPath = useMemo(() => ([] as string[]).concat(parentId || [], id || []), [parentId, id])
    const active = useMemo(() => Boolean(activeId) && activeId === id, [activeId, id])
    const activeWithin = useMemo(() => !active && activePath && activePath.split('.').includes(id!), [active, activePath, id])

    const dropdownActions = useMemo(() => {
      if (id === DOCUMENTS_FILTERS.SHARED || id === TEMPLATES_FILTERS.SHARED) return null
      const mine = id === DOCUMENTS_FILTERS.MINE || id === TEMPLATES_FILTERS.MINE
      return (
        <div className={classes.folderActionSet}>
          <Button onClick={() => (typeof create === 'function' ? contextCreate(create) : null)} onClickBlur noBackgroundSVG noOverlaySVG>
            <Folder function="add" />
            New folder
          </Button>
          {!mine ? (
            <>
              <Button
                onClick={() => rename((name: string) => typeof update === 'function' && update({ name }), label)}
                onClickBlur
                noBackgroundSVG
                noOverlaySVG
              >
                <EditField />
                Rename
              </Button>
              <Button
                onClick={() =>
                  contextRemove(() => {
                    const match = activePath?.match(new RegExp(`.${id}`))
                    if (match) {
                      const resultingPath = activePath!.slice(0, match.index)
                      typeof onClick === 'function' && onClick(resultingPath.split('.').slice(-1)[0], resultingPath)
                    }
                    typeof remove === 'function' && remove()
                  }, label)
                }
                onClickBlur
                noBackgroundSVG
                noOverlaySVG
              >
                <Bin />
                Delete
              </Button>
            </>
          ) : null}
        </div>
      )
    }, [id, contextCreate, create, rename, update, label, contextRemove, activePath, onClick, remove])

    return (
      <div
        className={classes.wrapper}
        data-active={active ? '' : undefined}
        data-active-within={activeWithin ? '' : undefined}
        data-collapsed={collapsed ? '' : undefined}
        data-mutating={mutating ? '' : undefined}
        data-mutation={mutating ? mutation : undefined}
      >
        <div className={classes.header}>
          {!mutating && Object.keys(children || {}).length ? (
            <Button onClick={() => setCollapsed(prev => !prev)} transparent>
              <Caret />
            </Button>
          ) : (
            <div className={classes.spacer} />
          )}
          <Button
            disabled={mutating}
            onClick={() => {
              !mutating && typeof onClick === 'function' && onClick(id!, fullFolderPath.join('.'))
              setCollapsed(false)
            }}
            onClickBlur
            transparent
          >
            {id === TEMPLATES_FILTERS.MINE ? <Person /> : null}
            {id === TEMPLATES_FILTERS.SHARED ? <Public /> : null}
            {!([TEMPLATES_FILTERS.MINE, TEMPLATES_FILTERS.SHARED] as string[]).includes(id!) ? <Folder /> : null}
            <span>{label}</span>
          </Button>
          {!mutating && dropdownActions ? (
            <Dropdown hideCaret label={<VerticalEllipsis />}>
              {dropdownActions}
            </Dropdown>
          ) : (
            <div className={classes.spacer} />
          )}
        </div>
        {!mutating && Object.keys(children).length ? (
          <div className={classes.children}>
            {Object.entries(children).map(([id, folder]) => (
              <FolderTree
                key={`Casus-Components-FolderTree-childFolder-${id}`}
                folderStructure={folder}
                directory={directory}
                onClick={onClick}
                parentId={fullFolderPath}
                activeId={activeId}
                activePath={activePath}
              />
            ))}
          </div>
        ) : null}
      </div>
    )
  }
)

FolderTree.displayName = 'Casus-Components-FolderTree'

export default FolderTree
