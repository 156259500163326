import React from 'react'

import { className } from './index'

const Edit = React.memo(() => (
  <svg className={className} width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.875 18.2266V7.48438C0.875 5.80469 1.74219 4.92188 3.41406 4.92188H4.73438V3.48438C4.73438 1.79688 5.60938 0.921875 7.27344 0.921875H10.2188C11.1172 0.921875 11.8125 1.14844 12.3984 1.75L16.2969 5.71875C16.9141 6.35156 17.125 7.00781 17.125 8.03125V14.2188C17.125 15.9062 16.2578 16.7812 14.5859 16.7812H13.2578V18.2266C13.2578 19.9062 12.3984 20.7891 10.7266 20.7891H3.41406C1.74219 20.7891 0.875 19.9141 0.875 18.2266ZM12.4766 10.0156C13.1328 10.6797 13.2578 11.1641 13.2578 12.2188V15.25H14.4531C15.2188 15.25 15.5938 14.8516 15.5938 14.125V7.65625H12.1484C11.1875 7.65625 10.6719 7.15625 10.6719 6.1875V2.45312H7.40625C6.64844 2.45312 6.26562 2.85156 6.26562 3.57031V4.92969C7.13281 4.9375 7.64062 5.10156 8.24219 5.71094L12.4766 10.0156ZM11.9531 5.99219C11.9531 6.26562 12.0703 6.375 12.3359 6.375H15.1953L11.9531 3.08594V5.99219ZM2.40625 18.1328C2.40625 18.8594 2.78125 19.2578 3.53906 19.2578H10.5938C11.3516 19.2578 11.7266 18.8594 11.7266 18.1328V12.3984H7.60156C6.49219 12.3984 5.9375 11.8516 5.9375 10.7344V6.45312H3.53906C2.78125 6.45312 2.40625 6.85938 2.40625 7.57812V18.1328ZM7.75 11.0469H11.5156L7.28906 6.75V10.5859C7.28906 10.9062 7.42969 11.0469 7.75 11.0469Z"
      fill="#001640"
      fill-opacity="0.4"
    />
  </svg>
))

export default Edit

