import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { className } from './index'

const DatePicker = React.memo(() => {
  const maskId = useMemo(() => `${className}-DatePicker-calendarMask-${uuid()}`, [])
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width="100" height="100" fill="black" />
          <rect x="20" y="20" width="60" height="60" fill="white" rx="4" />
        </mask>
      </defs>
      <rect x="20" y="20" width="60" height="60" stroke="currentColor" strokeWidth="8" strokeLinecap="round" fill="none" rx="4" />
      <rect x="20" y="20" width="60" height="20" fill="currentColor" />
      <line x1="40" y1="40" x2="40" y2="80" stroke="currentColor" strokeWidth="4" strokeLinecap="butt" />
      <line x1="60" y1="40" x2="60" y2="80" stroke="currentColor" strokeWidth="4" strokeLinecap="butt" />
      <line x1="20" y1="60" x2="80" y2="60" stroke="currentColor" strokeWidth="4" strokeLinecap="butt" />
    </svg>
  )
})

export { DatePicker }
export default DatePicker
