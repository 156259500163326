import React, { FunctionComponent } from 'react'

import { DOCUMENTS_FILTERS } from '___types'
import { FilteredTemplatesAccordionProps } from '.'
import TemplatesAccordion from './TemplatesAccordion'

export const Company: FunctionComponent<FilteredTemplatesAccordionProps> = React.memo(({ onClick }) => {
  return <TemplatesAccordion filter={DOCUMENTS_FILTERS.COMPANY} onClick={onClick} />
})

Company.displayName = 'Casus-Components-Accordion-Templates-Company'

export default Company
