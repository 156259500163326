import React from 'react'

import { Select } from 'components'
import { useFetchExternalServiceIdedObjects } from 'queries/externalServices/useFetchExternalServiceIdedObjects'
import { classes } from '.'
import { integrationsApiTitles } from 'api/integrations.api'

const ExternalServiceSelectOption = React.memo(({ id, labels, onChange }) => {
  return (
    <button
      className={classes.option}
      type="button"
      tabIndex={-1}
      onClick={event => {
        event.target.blur()
        event.currentTarget.blur()
        if (onChange && typeof onChange === 'function') onChange(id)
      }}
    >
      {labels.split(';').map(label => {
        const [id, value] = label.split(':')
        return <span key={`ExternalServiceSelectOption-label-${id}`}>{value}</span>
      })}
      <span>{id}</span>
    </button>
  )
})

export const ExternalServiceSelect = React.memo(({ service, chosen, onChange }) => {
  const { data: { data: idedObjects } = {} } = useFetchExternalServiceIdedObjects(service)
  const options =
    idedObjects?.reduce(
      (acc, idedObject) =>
        Object.assign(acc, {
          [idedObject.id]: Object.entries(idedObject)
            .reduce((accumulated, entry) => (entry[0] !== 'id' ? accumulated.concat(entry.join(':')) : accumulated), [])
            .join(';'),
        }),
      {}
    ) || {}
  return (
    <>
      {integrationsApiTitles[service] + ':'}
      <Select
        className={classes.select}
        chooseMode
        chooseRender={options[chosen]
          ?.split(';')
          .map(entry => entry.split(':')[1])
          .join('\t')}
        options={options}
        render={(id, labels) => <ExternalServiceSelectOption key={`${service}-${id}`} id={id} labels={labels} onChange={onChange} />}
        directionPriority="vertical"
        verticalDirection="down"
        horizontalDirection="right"
        cornerAnchor
      />
    </>
  )
})

ExternalServiceSelect.displayName = 'ExternalServiceSelect'

export default ExternalServiceSelect
