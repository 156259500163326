import React, { FunctionComponent, useMemo } from 'react'

import {
  SEGMENT_TYPES,
  ParagraphObject,
  Segment as SegmentType,
  TableCellObject,
  TableObject,
  TABLE_SECTION_TYPES,
  TableSectionsType,
} from '___types'
import { Paragraph, Table } from './Segment.Components'
import { useEditorPreviewContext } from '..'
import { WizardLayoutRightPaneEditorSegmentProps } from '../../../..'
import { WizardState, getSegmentById } from '___store'
import { mapDataStructureStyles } from '../helpers'

export const Segment: FunctionComponent<WizardLayoutRightPaneEditorSegmentProps> = React.memo(({ id }) => {
  const editorContext = useEditorPreviewContext()

  const staticPseudoState = Object.assign({}, editorContext as unknown) as WizardState
  const contextSegment = Object.assign({}, getSegmentById(staticPseudoState, id, editorContext.mode)[0])

  const { type, customStyle, styles } = contextSegment as SegmentType | TableCellObject
  if (styles?.length) Object.assign(contextSegment, { styles: mapDataStructureStyles(styles) })
  const { header, body, footer } = contextSegment as TableObject
  const tableSections = useMemo(() => {
    if (type !== SEGMENT_TYPES.TABLE) return
    const sectionsObject = { [SEGMENT_TYPES.TABLE_HEADER]: header, [SEGMENT_TYPES.TABLE_BODY]: body, [SEGMENT_TYPES.TABLE_FOOTER]: footer }
    return TABLE_SECTION_TYPES.reduce((result, cur) => {
      if (!sectionsObject[cur]?.length) return result
      return Object.assign(result, { [cur]: sectionsObject[cur] })
    }, {}) as TableSectionsType
  }, [type, header, body, footer])
  if (type === SEGMENT_TYPES.TABLE) Object.assign(contextSegment, { tableSections })

  const { textChunks } = contextSegment as ParagraphObject

  if (!type) return null
  if (type === SEGMENT_TYPES.PARAGRAPH) return <Paragraph id={id} customStyle={customStyle} styles={contextSegment.styles} textChunks={textChunks} />
  if (type === SEGMENT_TYPES.TABLE) return <Table id={id} customStyle={customStyle} styles={contextSegment.styles} tableSections={tableSections} />
  return null
})

Segment.displayName = 'WizardLayout-RightPane-Editor-Preview-Segment'

export default Segment
