import { AppState } from './helpers'

type LocalStateType = { app: AppState }

export type DocumentsFolderFilterSelector = string
export type DocumentsFilterSelector = string
export type SelectedDocumentIdSelector = string | null

export type TemplatesFolderFilterSelector = string
export type TemplatesFilterSelector = string
export type SelectedTemplateIdSelector = string | null

const selectors = {
  // =============================================================== //
  // ========================== DOCUMENTS ========================== //
  // =============================================================== //
  selectDocumentsFolderFilter: (state: LocalStateType): DocumentsFolderFilterSelector => state.app.documents.folderFilter.join('.'),
  selectDocumentsFilter: (state: LocalStateType): DocumentsFilterSelector => state.app.documents.filter.join('+'),
  selectSelectedDocumentId: (state: LocalStateType): SelectedDocumentIdSelector => state.app.documents.selected,

  // =============================================================== //
  // ========================== TEMPLATES ========================== //
  // =============================================================== //
  selectTemplatesFolderFilter: (state: LocalStateType): TemplatesFolderFilterSelector => state.app.templates.folderFilter.join('.'),
  selectTemplatesFilter: (state: LocalStateType): TemplatesFilterSelector => state.app.templates.filter.join('+'),
  selectSelectedTemplateId: (state: LocalStateType): SelectedTemplateIdSelector => state.app.templates.selected,
}

export { selectors }
export default selectors
