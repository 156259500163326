import React from 'react'

import { className } from './index'

const Share = React.memo(() => (
  <svg className={className} width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99219 11.9219C7.60156 11.9219 7.27344 11.5938 7.27344 11.2109V3.34375L7.33594 2.17969L6.84375 2.75L5.75 3.92188C5.625 4.0625 5.4375 4.13281 5.25781 4.13281C4.90625 4.13281 4.61719 3.875 4.61719 3.50781C4.61719 3.32031 4.69531 3.17969 4.82812 3.04688L7.45312 0.523438C7.63281 0.335938 7.80469 0.273438 7.99219 0.273438C8.1875 0.273438 8.35938 0.335938 8.53906 0.523438L11.1641 3.04688C11.2969 3.17969 11.3672 3.32031 11.3672 3.50781C11.3672 3.875 11.0781 4.13281 10.7266 4.13281C10.5469 4.13281 10.3672 4.0625 10.2422 3.92188L9.14062 2.75L8.65625 2.17969L8.71875 3.34375V11.2109C8.71875 11.5938 8.39062 11.9219 7.99219 11.9219ZM3.5 18.1484C1.8125 18.1484 0.9375 17.2812 0.9375 15.6172V7.92188C0.9375 6.25781 1.8125 5.39062 3.5 5.39062H5.76562V6.92188H3.59375C2.86719 6.92188 2.46875 7.29688 2.46875 8.05469V15.4844C2.46875 16.2422 2.86719 16.6172 3.59375 16.6172H12.3984C13.1172 16.6172 13.5234 16.2422 13.5234 15.4844V8.05469C13.5234 7.29688 13.1172 6.92188 12.3984 6.92188H10.2266V5.39062H12.4922C14.1797 5.39062 15.0547 6.25781 15.0547 7.92188V15.6172C15.0547 17.2812 14.1797 18.1484 12.4922 18.1484H3.5Z"
      fill="#001640"
      fill-opacity="0.4"
    />
  </svg>
))

export default Share

