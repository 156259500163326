import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { useFetchTemplatePreviewImageSrcURL } from '___queries'
import { Person, Plus, Premium, Spinner, TemplatePlaceholder } from 'assets/svgIconComponents'
import { ThumbnailProps, thumbnailClasses as classes } from '.'

export const TemplateThumbnail: FunctionComponent<ThumbnailProps> = React.memo(
  ({
    id,
    // folderId, needed for Template Create flow
    selected,
    onClick,
    name,
    edited,
    beingDuplicated,
    beingDeleted,
    paywallBlocked,
    sharedWith,
  }) => {
    const { t: translate } = useTranslation('translation', { keyPrefix: 'components.template-thumbnail' })
    const { data: src, isLoading } = useFetchTemplatePreviewImageSrcURL(id)

    const templatePreviewImage = useMemo(() => {
      if (!id) return <Plus />
      if (isLoading) return <Spinner />
      return (
        <>
          {src ? <img src={src} alt="Template Preview" /> : <TemplatePlaceholder randomize />}
          {paywallBlocked ? <span>{translate('paywall-text')}</span> : null}
        </>
      )
    }, [id, isLoading, src, paywallBlocked, translate])

    return (
      <button
        id={`Casus-Components-TemplateThumbnail-${id}`}
        className={classes.wrapper}
        type="button"
        onClick={event => typeof onClick === 'function' && !(beingDuplicated || beingDeleted) && onClick(event, id)}
        data-new={!id ? '' : undefined}
        data-selected={selected ? '' : undefined}
        data-being-duplicated={beingDuplicated ? '' : undefined}
        data-being-deleted={beingDeleted ? '' : undefined}
        data-paywall={paywallBlocked || undefined}
      >
        <div className={classes.content.wrapper}>
          {id && paywallBlocked ? <Premium /> : null}
          {id && sharedWith?.length ? <Person /> : null}
          {templatePreviewImage}
          <div className={classes.content.info}>
            <span className={classes.content.name}>{id ? name : 'Automate a new template'}</span>
            <span className={classes.content.time}>{DateTime.fromMillis(edited?._seconds! * 1000).toRelative()}</span>
          </div>
        </div>
      </button>
    )
  }
)

TemplateThumbnail.displayName = 'Casus-Components-TemplateThumbnail'

export default TemplateThumbnail
