import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { classes, ColumnProps, SortObject, TableProps } from '.'
import { extractValueFromObject } from 'utilities/helpers'
import { RecursiveRecord, UUID_REPLACE_ALL } from '___types'
import Button from '../Button'
import { Caret, HorizontalLines } from 'assets/svgIconComponents'
import Row from './Table.Row'

const defaultSortMethod = (a: unknown, b: unknown) =>
  Math.pow(-1, Number((typeof a === 'string' ? a.toUpperCase() : Number(a)) < (typeof b === 'string' ? b.toUpperCase() : Number(b))))

export const Table: FunctionComponent<TableProps> = ({ data, header = false, defaultSort, cumulativeSort = false, rowDataSet, children }) => {
  //@ts-ignore
  const tableId = useMemo(() => uuid().replaceAll(UUID_REPLACE_ALL, ''), [])

  const columns = useMemo(
    () =>
      React.Children.toArray(children).reduce<
        {
          key: string
          header?: string | JSX.Element
          render?: (value: unknown, entry: RecursiveRecord<string, unknown>) => JSX.Element
          sort?: boolean | { id: String; method: (a: unknown, b: unknown) => number; label?: string }[]
          className?: string
          width?: string
        }[]
      >((result, child) => {
        const key = ((child as JSX.Element).props as ColumnProps).valueKey
        if (!key) return result
        const columnHeader = ((child as JSX.Element).props as ColumnProps).header
        const render = ((child as JSX.Element).props as ColumnProps).render
        const sort = ((child as JSX.Element).props as ColumnProps).sort
        const className = ((child as JSX.Element).props as ColumnProps).className
        const width = ((child as JSX.Element).props as ColumnProps).width
        const column = { key }
        if (header && columnHeader) Object.assign(column, { header: columnHeader })
        if (typeof render === 'function') Object.assign(column, { render })
        if (sort) Object.assign(column, { sort })
        if (typeof className === 'string') Object.assign(column, { className })
        if (typeof width === 'string') Object.assign(column, { width })
        return result.concat(column)
      }, []),
    [children, header]
  )

  const initialSortColumn = defaultSort && columns.find(({ key }) => key === defaultSort.key)
  const initialSortId = defaultSort?.id || 'default'
  const initialSortMethod =
    (Array.isArray(initialSortColumn?.sort) && defaultSort!.id && initialSortColumn!.sort.find(({ id }) => id === defaultSort!.id)?.method) ||
    defaultSortMethod
  const initialSort = defaultSort
    ? {
        id: initialSortId,
        key: defaultSort.key,
        direction: [1, -1][['ascending', 'descending'].indexOf(defaultSort.direction)] as 1 | -1,
        method: initialSortMethod,
      }
    : null
  const [sort, setSort] = useState<SortObject[]>(initialSort ? [initialSort] : [])

  // ============================= //
  // LOW SPEED - CORRECT TAB ORDER //
  // ============================= //
  // const sortedData = useMemo(
  //   () =>
  //     //@ts-ignore
  //     data.toSorted((a, b) => {
  //       let result = 0
  //       sort.some(({ key, direction, method }) => {
  //         const aValue = extractValueFromObject(a, key)
  //         const bValue = extractValueFromObject(b, key)
  //         return (result = method(aValue, bValue) * direction)
  //       })
  //       return result
  //     }),
  //   [data, sort]
  // )
  // ============================= //

  const rows = useMemo(
    () =>
      data.map((datum, i) => (
        <Row key={`Casus-Components-Table-${tableId}-row-${i}`} tableId={tableId} datum={datum} columns={columns} dataSetKeys={rowDataSet} />
      )),
    [data, columns, tableId, rowDataSet]
  )

  const headerRender = useMemo(
    () =>
      header ? (
        <div key={`Casus-Components-Table-${tableId}-header`} className={classes.header}>
          {columns.map(column => {
            const relevantSort = sort.find(({ key, id }) => key === column.key && id === 'default')?.direction
            const columnSort = relevantSort ? ['descending', 'ascending'][(relevantSort + 1) / 2] : undefined
            return (
              <div
                key={`Casus-Components-Table-${tableId}-header-column-${column.key}`}
                className={[classes.row.cell, column.className].filter(s => s).join(' ')}
              >
                {column.sort === true ? (
                  <Button
                    dataSet={{
                      sort: columnSort,
                    }}
                    onClick={() =>
                      setSort(prev => {
                        const existingIndex = prev.findIndex(({ key, id }) => key === column.key && id === 'default')
                        const filtered = cumulativeSort ? prev.slice() : []
                        if (cumulativeSort && existingIndex !== -1) filtered.splice(existingIndex, 1)
                        return filtered.concat({
                          key: column.key,
                          id: 'default',
                          direction: ((prev[existingIndex]?.direction || -1) * -1) as 1 | -1,
                          method: defaultSortMethod,
                        })
                      })
                    }
                    transparent
                  >
                    {column.header}
                    {columnSort ? <Caret /> : <HorizontalLines count={1} />}
                  </Button>
                ) : null}
                {!column.sort ? column.header : null}
              </div>
            )
          })}
        </div>
      ) : null,
    [header, tableId, columns, sort, cumulativeSort]
  )

  const gridTemplateColumns = useMemo(
    () =>
      columns
        .reduce<{ width: string; count: number }[]>((result, { width }, i) => {
          const currentWidth = width || 'minmax(0, auto)'
          const previousWidth = result.slice(-1)[0]
          if (previousWidth?.width === currentWidth) previousWidth.count += 1
          else result.push({ width: currentWidth, count: 1 })
          return result
        }, [])
        .map(({ width, count }) => (count > 1 ? `repeat(${count}, ${width})` : width))
        .join(' '),
    [columns]
  )

  useEffect(() => {
    if (data.length && sort.length) {
      const body = document.getElementById(tableId)?.querySelector(`.${classes.body}`)
      const rows = Array.from(body?.getElementsByClassName(classes.row.wrapper) || [])
      if (rows?.length !== data.length) return
      const temporaryDataIds = new Array(data?.length).fill(null).map(() => uuid())
      const pseudoSortedDataIds = data
        .map((datum, i) => Object.assign({}, datum, { _tableRowId: temporaryDataIds[i] }))
        //@ts-ignore
        .toSorted((a, b) => {
          let result = 0
          sort.some(({ key, direction, method }) => {
            const aValue = extractValueFromObject(a, key)
            const bValue = extractValueFromObject(b, key)
            return (result = method(aValue, bValue) * direction)
          })
          return result
        })
        //@ts-ignore
        .map(({ _tableRowId }) => _tableRowId)

      // ================================ //
      // MEDIUM SPEED - CORRECT TAB ORDER //
      // ================================ //
      // rows.forEach(row => body?.removeChild(row))
      // // @ts-ignore
      // pseudoSortedDataIds.forEach(id => body?.appendChild(rows[temporaryDataIds.indexOf(id)]))
      // ================================ //

      // ================================ //
      // HIGH SPEED - INCORRECT TAB ORDER //
      // ================================ //
      Array.from(rows || []).forEach((row, i) => {
        ;(row as HTMLDivElement).style.order = String(pseudoSortedDataIds.indexOf(temporaryDataIds[i]) + 1)
        // ====================================================== //
        // TAB ORDER CORRECTION (KINDA) (HORRIBLE IMPLEMENTATION) //
        // ====================================================== //
        // row
        //   .querySelectorAll('.Casus-Components-Button-button')
        //   ?.forEach(button => ((button as HTMLButtonElement).tabIndex = pseudoSortedDataIds.indexOf(temporaryDataIds[i]) + 1))
        // ====================================================== //
      })
      // ================================ //
    }
  }, [data, sort, tableId])

  useEffect(() => {
    const styleElement = document.createElement('style')
    styleElement.dataset.sheet = `Table-${tableId}`
    styleElement.setAttribute('type', 'text/css')
    styleElement.innerHTML = `#${tableId} { grid-template-columns: [table-start] ${gridTemplateColumns} [table-end]; grid-template-rows: auto [header-body-line] }`
    document.head.appendChild(styleElement)
    return () => {
      document.head.removeChild(styleElement)
    }
  }, [tableId, gridTemplateColumns])

  return (
    <div key={tableId} id={tableId} className={classes.wrapper}>
      {headerRender}
      <div className={classes.body}>{rows}</div>
    </div>
  )
}

Table.displayName = 'Casus-Components-Table'

export default Table
