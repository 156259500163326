import SignaturesPanel from './SignaturesPanel'
import EmptySignatures from './EmptySignatures'

const classes = {
  wrapper: 'Homepage-dashboard-grid-panel-wrapper',
  title: {
    wrapper: 'Homepage-dashboard-grid-panel-title-wrapper',
    showAll: 'Homepage-dashboard-grid-panel-title-showAll',
    textAndIcon: 'Homepage-dashboard-grid-panel-title-textAndIcon',
  },
  empty: {
    wrapper: 'Homepage-dashboard-grid-panel-empty-wrapper',
    icon: 'Homepage-dashboard-grid-panel-empty-icon',
    text: 'Homepage-dashboard-grid-panel-empty-text',
  },
  list: {
    wrapper: 'Homepage-dashboard-signatures-list-wrapper',
    item: {
      wrapper: 'Homepage-dashboard-signatures-list-item-wrapper',
      title: 'Homepage-dashboard-signatures-list-item-title',
      counter: 'Homepage-dashboard-signatures-list-item-counter',
    },
  },
}

export { classes, EmptySignatures }

export default SignaturesPanel

