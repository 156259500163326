import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTemplateUpload } from './hooks/useTemplateUpload'

import UploadTemplateComponent from './UploadTemplate.component'
import { useCreateTemplate } from 'queries/templates'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { MainLayout } from 'layout'

const UploadTemplate: React.FC = () => {
  const { goBack } = useHistory()
  const { upload, isLoading } = useTemplateUpload()
  const { mutate: create, isLoading: createIsLoading } = useCreateTemplate()
  const { check } = useSubscriptionCheck()

  const onCreate = (file: File | null, originalStylesKept: boolean = false, isAutoAutomateAI: boolean = false) => {
    if (!check({ isFree: true, isStartup: true })) return
    if (!file) return create({})
    upload({ file, originalStylesKept, isAutoAutomateAI })
  }

  return (
    <MainLayout>
      <UploadTemplateComponent
        isLoading={isLoading || createIsLoading}
        onCreate={onCreate}
        onBack={goBack}
      />
    </MainLayout>
  )
}

export default UploadTemplate
