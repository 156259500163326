import { PropsWithChildren } from 'react'

import Accordion from './Accordion'

import './style.scss'

const classes = {
  wrapper: 'Casus-Components-Accordion-wrapper',
} as const

export type AccordionProps = PropsWithChildren<{
  isLoading: boolean
  isFetching: boolean
}>

export { Accordion, classes }
export default Accordion
