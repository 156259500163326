import React, { useMemo } from 'react'
import useStore from '___store'

import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Bold,
  EditText,
  InsertAbove,
  InsertBelow,
  Italic,
  Trash,
  Underline,
} from 'assets/svgIconComponents'
import { Button } from 'components'
import { classes } from '../../index'
import { CustomStyleSelect } from '.'

const ActionButton = React.memo(({ button, id }) => {
  const { segmentStyles, setConfiguring, alignParagraphContent, toggleParagraphStyle, insertParagraphAbove, insertParagraphBelow, removeParagraph } =
    useStore(
      `selectSegmentStyles[${id}]`,
      'setConfiguring',
      'alignParagraphContent',
      'toggleParagraphStyle',
      'insertParagraphAbove',
      'insertParagraphBelow',
      'removeParagraph'
    )
  const styles = useMemo(() => segmentStyles.split('; ').filter(s => s), [segmentStyles])

  if (button === 'removeSegment')
    return (
      <Button className={classes.segment.paragraph.actionButton} tabbable={false} onClick={() => removeParagraph({ id })} colorScheme="transparent">
        <Trash />
      </Button>
    )
  if (button === 'editText')
    return (
      <Button
        className={classes.segment.paragraph.actionButton}
        tabbable={false}
        onClick={() => setConfiguring({ key: 'paragraph', id })}
        colorScheme="transparent"
      >
        <EditText />
      </Button>
    )
  if (button === 'insertAbove')
    return (
      <Button
        className={classes.segment.paragraph.actionButton}
        tabbable={false}
        onClick={() => insertParagraphAbove({ id })}
        colorScheme="transparent"
      >
        <InsertAbove />
      </Button>
    )
  if (button === 'insertBelow')
    return (
      <Button
        className={classes.segment.paragraph.actionButton}
        tabbable={false}
        onClick={() => insertParagraphBelow({ id })}
        colorScheme="transparent"
      >
        <InsertBelow />
      </Button>
    )
  if (button === 'emphasis')
    return (
      <div className={classes.segment.paragraph.actionButtonGroup}>
        <Button
          className={classes.segment.paragraph.actionButton}
          tabbable={false}
          active={styles.includes('_casus_font-weight-700')}
          onClick={() => toggleParagraphStyle({ id, style: '_casus_font-weight-700' })}
          colorScheme="transparent"
        >
          <Bold />
        </Button>
        <Button
          className={classes.segment.paragraph.actionButton}
          tabbable={false}
          active={styles.includes('_casus_font-style-italic')}
          onClick={() => toggleParagraphStyle({ id, style: '_casus_font-style-italic' })}
          colorScheme="transparent"
        >
          <Italic />
        </Button>
        <Button
          className={classes.segment.paragraph.actionButton}
          tabbable={false}
          active={styles.includes('_casus_text-decoration-underline')}
          onClick={() => toggleParagraphStyle({ id, style: '_casus_text-decoration-underline' })}
          colorScheme="transparent"
        >
          <Underline />
        </Button>
      </div>
    )
  if (button === 'alignment')
    return (
      <div className={classes.segment.paragraph.actionButtonGroup}>
        <Button
          className={classes.segment.paragraph.actionButton}
          tabbable={false}
          active={styles.includes('_casus_align-left')}
          onClick={() => alignParagraphContent({ id, alignment: 'left' })}
          colorScheme="transparent"
        >
          <AlignLeft />
        </Button>
        <Button
          className={classes.segment.paragraph.actionButton}
          tabbable={false}
          active={styles.includes('_casus_align-center')}
          onClick={() => alignParagraphContent({ id, alignment: 'center' })}
          colorScheme="transparent"
        >
          <AlignCenter />
        </Button>
        <Button
          className={classes.segment.paragraph.actionButton}
          tabbable={false}
          active={styles.includes('_casus_align-right')}
          onClick={() => alignParagraphContent({ id, alignment: 'right' })}
          colorScheme="transparent"
        >
          <AlignRight />
        </Button>
        <Button
          className={classes.segment.paragraph.actionButton}
          tabbable={false}
          active={styles.includes('_casus_align-justify')}
          onClick={() => alignParagraphContent({ id, alignment: 'justify' })}
          colorScheme="transparent"
        >
          <AlignJustify />
        </Button>
      </div>
    )
  if (button === 'customStyle') return <CustomStyleSelect id={id} />
})

ActionButton.displayName = 'Wizard-Editor-Segment-Paragraph-ActionButton'

export default ActionButton
