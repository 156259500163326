import MessageLabel from './MessageLabel'

const classes = {
  wrapper: 'MessageLabel-Container-wrapper',
  title: 'MessageLabel-Title-wrapper',
  label: 'MessageLabel-Label-wrapper',
}

export { classes }

export default MessageLabel
