import React, { FunctionComponent, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import { CasusLogo } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import Navigation from './Header.Navigation'
import Actions from './Header.Actions'
import { MainLayoutHeaderProps, mainLayoutHeaderClasses as classes } from '../..'

export const Header: FunctionComponent<MainLayoutHeaderProps> = React.memo(() => {
  const { pathname } = useLocation()
  const { push } = useHistory()

  const directory = useMemo(() => pathname.split('/').filter(s => s)[0] || 'dashboard', [pathname])

  return (
    <section className={classes.wrapper}>
      <Navigation />
      <Button className={classes.homeButton} onClick={() => push('/')} title="Home - Dashboard" transparent>
        <CasusLogo />
      </Button>
      {directory ? <span>/&nbsp;&nbsp;&nbsp;{`${directory.slice(0, 1).toUpperCase()}${directory.slice(1)}`}</span> : null}
      <Actions />
    </section>
  )
})

Header.displayName = 'MainLayout-Header'

export default Header
