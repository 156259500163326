import React from 'react'

import { className } from './index'

const Preview = React.memo(props => {
  const { strokeWidth = 5 } = props

  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path
        d="M 20 50 A 40 55 0 0 1 80 50 A 40 55 0 0 1 20 50"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <circle cx="50" cy="50" r="10" stroke="currentColor" strokeWidth={strokeWidth} fill="none" />
    </svg>
  )
})

export { Preview }
export default Preview
