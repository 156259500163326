import React from 'react'

import { className } from './index'

const RecentDocuments = React.memo(({ active = false }) => (
  <svg className={className} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.88281 8.8125V2.63281C3.88281 0.96875 4.78125 0.0703125 6.42969 0.0703125H10.0703C11.1172 0.0703125 11.7031 0.242188 12.3906 0.945312L16.0156 4.60938C16.7266 5.32812 16.8906 5.88281 16.8906 7.00781V13.7031C16.8906 15.3672 15.9922 16.2734 14.3438 16.2734H9.00781C9.34375 15.7344 9.57812 15.1172 9.64844 14.4688H14.1328C14.7812 14.4688 15.0859 14.1406 15.0859 13.5234V7.40625H11.4141C10.1875 7.40625 9.60156 6.82031 9.60156 5.58594V1.86719H6.64062C5.99219 1.86719 5.6875 2.20312 5.6875 2.8125V8.8125C5.38281 8.75 5.08594 8.71875 4.77344 8.71875C4.46875 8.71875 4.17188 8.75 3.88281 8.8125ZM11.0938 5.44531C11.0938 5.75781 11.2422 5.90625 11.5625 5.90625H14.9219L11.0938 2.03125V5.44531ZM4.78906 17.5625C2.66406 17.5625 0.914062 15.8047 0.914062 13.6875C0.914062 11.5703 2.66406 9.82031 4.78906 9.82031C6.90625 9.82031 8.65625 11.5703 8.65625 13.6875C8.65625 15.7969 6.89062 17.5625 4.78906 17.5625ZM3.01562 14.3828H4.83594C5.17188 14.3828 5.42969 14.125 5.42969 13.7891V11.6172C5.42969 11.2891 5.17188 11.0312 4.83594 11.0312C4.50781 11.0312 4.24219 11.2891 4.24219 11.6172V13.2031H3.01562C2.6875 13.2031 2.42188 13.4609 2.42188 13.7891C2.42188 14.1172 2.6875 14.3828 3.01562 14.3828Z"
      fill="#001640"
    />
  </svg>
))

export { RecentDocuments }
export default RecentDocuments

