import { MutateOptions, useMutation, useQueryClient } from 'react-query'
import { v4 as uuid } from 'uuid'

import { DocumentDownloadFormat, IDocument, ITemplate } from '___types'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { useCallback, useState } from 'react'
import { parseAnswersForDownload } from '.'

export type DownloadContext = { mutationId: string }
// ======================================================= //
// ======================= REGULAR ======================= //
// ======================================================= //
export type DownloadRegularDocumentVariables = {
  id: string
  payload: { storageId: string; paragraphs: unknown[] }
  format: DocumentDownloadFormat
  publicFlow?: boolean
}
export type DownloadRegularDocumentMutationOptions = MutateOptions<void, unknown, DownloadRegularDocumentVariables, DownloadContext>
export type DownloadRegularDocumentFunctionType = (
  format: DocumentDownloadFormat,
  splitId?: string | null,
  options?: DownloadRegularDocumentMutationOptions
) => void
// ======================================================= //

// ======================================================= //
// ========================= PDF ========================= //
// ======================================================= //
export type DownloadPDFDocumentVariables = { id: string; format: 'pdf' }
export type DownloadPDFDocumentMutationOptions = MutateOptions<void, unknown, DownloadPDFDocumentVariables, DownloadContext>
export type DownloadPDFDocumentFunctionType = (options?: DownloadPDFDocumentMutationOptions) => void
// ======================================================= //

// ======================================================= //
// ======================= SIGNED ======================= //
// ======================================================= //
export type DownloadSignedDocumentVariables = { id: string; format: 'pdf'; splitId: string | null; publicFlow?: boolean }
export type DownloadSignedDocumentMutationOptions = MutateOptions<void, unknown, DownloadSignedDocumentVariables, DownloadContext>
export type DownloadSignedDocumentFunctionType = (splitId: string | null, options?: DownloadSignedDocumentMutationOptions) => void
// ======================================================= //

const downloadRegularDocumentMutationFunction = (variables: DownloadRegularDocumentVariables) =>
  documentsAPI.downloadRegularDocument(variables.id, variables.payload, variables.format, variables.publicFlow)
const downloadPDFDocumentMutationFunction = (variables: DownloadPDFDocumentVariables) => documentsAPI.downloadPDFDocument(variables.id)
const downloadSignedDocumentMutationFunction = (variables: DownloadSignedDocumentVariables) =>
  documentsAPI.downloadSignedDocument(variables.id, variables.splitId, variables.publicFlow)

export const useDownloadDocument = (id?: string | null, publicFlow: boolean = false) => {
  const [downloading, setDownloading] = useState<Record<DocumentDownloadFormat | 'signed', string[]>>({ docx: [], pdf: [], signed: [] })
  const queryClient = useQueryClient()

  const onMutate = (variables: DownloadRegularDocumentVariables | DownloadPDFDocumentVariables) => {
    const mutationId = uuid()
    setDownloading(prev => Object.assign(prev, { [variables.format]: prev[variables.format].concat(mutationId) }))
    return { mutationId }
  }
  const onSettled = (
    res: void,
    error: unknown,
    variables: DownloadRegularDocumentVariables | DownloadPDFDocumentVariables,
    context: DownloadContext | undefined
  ) => {
    setDownloading(prev => Object.assign(prev, { [variables.format]: prev[variables.format].filter(id => id !== context?.mutationId) }))
  }

  const documentRegularDownloadMutation = useMutation<void, unknown, DownloadRegularDocumentVariables, DownloadContext>(
    [QUERY_KEYS.DOCUMENT_DOWNLOAD, id, 'regular'],
    downloadRegularDocumentMutationFunction,
    { onMutate, onSettled }
  )
  const documentPDFDownloadMutation = useMutation<void, unknown, DownloadPDFDocumentVariables, DownloadContext>(
    [QUERY_KEYS.DOCUMENT_DOWNLOAD, id, 'pdf'],
    downloadPDFDocumentMutationFunction,
    { onMutate, onSettled }
  )
  const documentSignedDownloadMutation = useMutation<void, unknown, DownloadSignedDocumentVariables, DownloadContext>(
    [QUERY_KEYS.DOCUMENT_DOWNLOAD, id, 'signed'],
    downloadSignedDocumentMutationFunction,
    { onMutate, onSettled }
  )

  const downloadRegularMutationFunction: DownloadRegularDocumentFunctionType = useCallback(
    (format, splitId = null, options) => {
      const document = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument
      const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, document?.templateId]) as ITemplate
      if (!(document && template)) return
      const { answers, languages, integrations } = document
      const [paragraphs, headers, footers] = parseAnswersForDownload(template!, languages, integrations, splitId, answers)
      const payload = { storageId: template.dataStructure.storageId, paragraphs }
      if (headers?.length) Object.assign(payload, { headers })
      if (footers?.length) Object.assign(payload, { footers })
      documentRegularDownloadMutation.mutate({ id: id!, payload, format, publicFlow }, options)
    },
    [queryClient, documentRegularDownloadMutation, id, publicFlow]
  )
  const downloadPDFMutationFunction: DownloadPDFDocumentFunctionType = useCallback(
    options => documentPDFDownloadMutation.mutate({ id: id!, format: 'pdf' }, options),
    [documentPDFDownloadMutation, id]
  )
  const downloadSignedMutationFunction: DownloadSignedDocumentFunctionType = useCallback(
    (splitId, options) => documentSignedDownloadMutation.mutate({ id: id!, format: 'pdf', splitId, publicFlow }, options),
    [documentSignedDownloadMutation, id, publicFlow]
  )

  return {
    downloadRegular: downloadRegularMutationFunction,
    downloadPDF: downloadPDFMutationFunction,
    downloadSigned: downloadSignedMutationFunction,
    downloading: Object.entries(downloading).reduce(
      (result, [format, ids]) => Object.assign(result, { [format]: Boolean(ids.length) }),
      {} as Record<DocumentDownloadFormat | 'signed', boolean>
    ),
  }
}

export default useDownloadDocument
