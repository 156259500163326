import React, { FunctionComponent, useMemo } from 'react'
import useStore, { AddMarkerConditionalAction, RemoveMarkerConditionalAction, WizardLanguagesSelector } from '___store'

import { LANGUAGE_INFO, Conditionals } from '___types'
import { CheckBox } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { classes } from '../../..'

type UseStoreHookResultType = {
  wizardLanguages: WizardLanguagesSelector
  addMarkerConditional: AddMarkerConditionalAction
  removeMarkerConditional: RemoveMarkerConditionalAction
}

type Props = { id: string; conditionals: Conditionals }
export const Languages: FunctionComponent<Props> = React.memo(({ id, conditionals }) => {
  const { wizardLanguages, addMarkerConditional, removeMarkerConditional } = useStore(
    'selectWizardLanguages',
    'addMarkerConditional',
    'removeMarkerConditional'
  ) as UseStoreHookResultType

  const render = useMemo(
    () =>
      wizardLanguages?.available?.map(language => {
        const active = conditionals?.languages?.includes(language)
        return (
          <Button
            key={`Marker-Connect-ShowHide-Languages-${language}`}
            className={classes.configEntry.markerConfiguration.fragment.connect.tabs.conditionals.entry}
            onClick={() =>
              active ? removeMarkerConditional({ id, conditional: language }) : addMarkerConditional({ id, type: 'languages', conditional: language })
            }
            tertiary={active || undefined}
          >
            <img src={LANGUAGE_INFO[language].icon} alt={LANGUAGE_INFO[language].label} />
            <span>{LANGUAGE_INFO[language].label}</span>
            <CheckBox active={active} strokeWidth={5} />
          </Button>
        )
      }) || [],
    [wizardLanguages, conditionals, removeMarkerConditional, id, addMarkerConditional]
  )

  return <div className={classes.configEntry.markerConfiguration.fragment.connect.tabs.conditionals.languages}>{render}</div>
})

Languages.displayName = ''

export default Languages
