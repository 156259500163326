import { PropsWithChildren } from 'react'

import Column from './Table.Column'
import Table from './Table'

import './style.scss'
import { RecursiveRecord } from '___types'

const rowClasses = { wrapper: 'Casus-Components-Table-Row-wrapper', cell: 'Casus-Components-Table-Row-cell' }
const classes = {
  wrapper: 'Casus-Components-Table-wrapper',
  header: 'Casus-Components-Table-header',
  body: 'Casus-Components-Table-body',
  row: rowClasses,
}

export type SortObject = { id: string; key: string; direction: 1 | -1; method: (a: unknown, b: unknown) => number }

export type TableProps = PropsWithChildren<{
  data: RecursiveRecord<string, unknown>[]
  header?: boolean | ((label: string | JSX.Element) => string | JSX.Element)
  defaultSort?: { key: string; direction: 'ascending' | 'descending'; id?: string }
  cumulativeSort?: boolean
  rowDataSet?: string[]
}>
export type ColumnProps = {
  valueKey: string
  header?: string | JSX.Element
  render?: (value: unknown, entry: RecursiveRecord<string, unknown>, dataSet: Record<string, string>) => string | JSX.Element
  sort?: boolean | { id: string; method: (a: unknown, b: unknown) => number; label?: string }[]
  className?: string
  width?: string
}
export type RowProps = {
  tableId: string
  datum: RecursiveRecord<string, unknown>
  columns: {
    key: string
    render?: (value: unknown, entry: RecursiveRecord<string, unknown>, dataSet: Record<string, string>) => string | JSX.Element
    className?: string
  }[]
  dataSetKeys?: string[]
}

export { Column, Table, classes, rowClasses }
export default Table
