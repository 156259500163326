import React from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'

const classes = {
  title: 'title',
  noSignatureContainer: 'no-signature-container',
}

export const PaywallSignatures = () => {
  const { t } = useTranslation()

  return (
    <div className="details-sidebar-section-container">
      <h3 className={classes.title}>{t('signature_request')} </h3>
      <h3 className={classes.title}> {t('need_to_be_subscribed')}</h3>
    </div>
  )
}
