import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { MutationOptions, useMutation, useQueryClient } from 'react-query'

import { Author, IUser, BEFolder } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

const generateFolder = (author: Author, name: string, parent?: string | null) => {
  const moment = { _seconds: Math.floor(Date.now() / 1000), _nanoseconds: 0 }
  return {
    status: 'new',
    name: name,
    authorId: author?.id,
    author: { id: author?.id, email: author?.email, firstName: author?.firstName, lastName: author?.lastName, imageUrl: author?.imageUrl },
    parentCategoryId: parent,
    created: moment,
    edited: moment,
    sharingEnabled: false,
    sharedWith: [],
  }
}

export type CreateTemplateFolderVariables = { name: string; parentFolderId?: string | null }
export type CreateTemplateFolderContext = { mutationId: string }
export type CreateTemplateFolderMutationOptions = MutationOptions<BEFolder, unknown, CreateTemplateFolderVariables, CreateTemplateFolderContext>
export type CreateTemplateFolderFunctionType = (name: string, parentFolderId?: string, options?: CreateTemplateFolderMutationOptions) => void
const createTemplateFolderMutationFunction = (variables: CreateTemplateFolderVariables) =>
  templatesAPI.createTemplateFolder(variables.name, variables.parentFolderId)

export const useCreateTemplateFolder = () => {
  const queryClient = useQueryClient()
  const mutationId = useMemo(uuid, [])

  const updateListing = (method: (data: BEFolder[] | undefined) => BEFolder[]) => queryClient.setQueryData([QUERY_KEYS.TEMPLATE_FOLDERS], method)

  const onMutate = (variables: CreateTemplateFolderVariables) => {
    const author = queryClient.getQueryData([QUERY_KEYS.USER]) as IUser
    const optimisticPayload = { id: mutationId, mutating: true, mutation: 'create' }
    const optimisticTemplateFolder = Object.assign(generateFolder(author, variables.name, variables.parentFolderId), optimisticPayload) as BEFolder
    updateListing(data => [optimisticTemplateFolder].concat(data || []))
    return { mutationId }
  }

  const onError = (error: unknown, variables: CreateTemplateFolderVariables, context: CreateTemplateFolderContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.TEMPLATE_FOLDERS, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.TEMPLATE_FOLDERS, context?.mutationId])
    updateListing((data: BEFolder[] | undefined) => data?.filter(({ id }) => id !== context?.mutationId) || [])
  }

  const onSuccess = (templateFolder: BEFolder, variables: CreateTemplateFolderVariables, context: CreateTemplateFolderContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.TEMPLATE_FOLDERS, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.TEMPLATE_FOLDERS, context?.mutationId])
    queryClient.setQueryData([QUERY_KEYS.TEMPLATE_FOLDERS, templateFolder.id], templateFolder)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === context?.mutationId, templateFolder))
  }

  const generatedId = useMemo(() => uuid(), [])
  const templateFolderCreateMutation = useMutation<BEFolder, unknown, CreateTemplateFolderVariables, CreateTemplateFolderContext>(
    [QUERY_KEYS.TEMPLATE_FOLDERS, generatedId],
    createTemplateFolderMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const createFolderMutationFunction: CreateTemplateFolderFunctionType = (name, parentFolderId, options) =>
    templateFolderCreateMutation.mutate({ name, parentFolderId }, options)

  return { createTemplateFolder: createFolderMutationFunction, creatingTemplateFolder: templateFolderCreateMutation.isLoading }
}

export default useCreateTemplateFolder
