import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useUser } from 'queries/user/useUser'

export const EmailReminderRedirect: React.FC = () => {
  const { type, id } = useParams<{ type: 'document' | 'template'; id: string }>()

  const {
    replace,
    location: { pathname },
  } = useHistory()
  const user = useUser()

  const redirectUrl =
    type === 'template' ? `/templates?template=${id}&action=preview` : `/document/${id}`

  useEffect(() => {
    if (!user) {
      const encodedUrl = encodeURIComponent(pathname)
      replace(`/auth/login?redirect=${encodedUrl}`)
    } else {
      replace(redirectUrl)
    }
  }, [user, type, id, redirectUrl, pathname, replace])

  return null
}
