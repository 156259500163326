import useStore, { WizardTableCellContentByIdSelector, WizardState, getMappedSegmentIds, getSegmentById } from '___store'

import { useEditorContext } from '..'
import { TableCellObject } from '___types'

type UseStoreHookResultType = { wizardTableCellContentById?: WizardTableCellContentByIdSelector }
type UseTextMarkerResultType = { contentIds: string[]; styles: string[] }

export const useTableCell = (id: string): UseTextMarkerResultType => {
  const editorContext = useEditorContext()
  const { mode, dataStructure, numbering, locations } = editorContext

  const staticPseudoState = Object.assign({}, { dataStructure, numbering } as unknown) as WizardState
  if (mode === 'interact') Object.assign(staticPseudoState, { locations })

  const contextCell = getSegmentById(staticPseudoState, id, mode)[0] as TableCellObject
  // ========================================================================= //
  // IMPLEMENT DYNAMIC STYLE FETCHING FROM THE STORE FOR TEMPLATE AUTOMATION MODE
  // const { wizardCellStylesById } = useStore(!contextCell ? `selectWizardCellStylesById[${id}]` : undefined)
  // ========================================================================= //

  const styles =
    contextCell?.styles.concat(contextCell.customStyle) ||
    // || wizardCellStylesById.split(' ')
    []

  const mappedSegmentIds = getMappedSegmentIds(
    staticPseudoState,
    id,
    contextCell.content.map(({ id }) => id),
    mode
  )

  const topLayerContentIds = mappedSegmentIds.map(id => id.split(';').pop())
  const contextTableCellContentIds = `${Array.from(new Set(topLayerContentIds)).join(';')}`

  const { wizardTableCellContentById = '' } = useStore(
    !contextTableCellContentIds ? `selectWizardTableCellContentById[${id}]` : undefined
  ) as UseStoreHookResultType

  const resultingTopLayerContentIds = contextTableCellContentIds || wizardTableCellContentById
  const useTableCellReturn = { contentIds: resultingTopLayerContentIds.split(';'), styles }

  return useTableCellReturn
}

export default useTableCell
