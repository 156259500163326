import { useQuery } from 'react-query'

import { subscriptionsAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchSubscriptionList = () => {
  const subscriptionListQuery = useQuery({
    queryKey: [QUERY_KEYS.SUBSCRIPTIONS],
    queryFn: subscriptionsAPI.getSubscriptionList,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  })
  return subscriptionListQuery
}

export default useFetchSubscriptionList
