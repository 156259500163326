export interface ContentStructure {
  heading: string[];
  text: string;
  bulletpoint1: string;
  bulletpoint2: string;
  bulletpoint3: string;
}


const content: { [key: string]: ContentStructure} = {
  app: {
    heading: ['login_title'],
    text: 'login_subtitle',
    bulletpoint1: 'login_bullet_1',
    bulletpoint2: 'login_bullet_2',
    bulletpoint3: 'login_bullet_3',
  },
  viseca: {
    heading: ['viseca_login_title'],
    text: 'viseca_login_subtitle',
    bulletpoint1: 'viseca_login_bullet_1',
    bulletpoint2: 'viseca_login_bullet_2',
    bulletpoint3: 'viseca_login_bullet_3',
  },
  sbv: {
    heading: ['sbv_login_title'],
    text: 'sbv_login_subtitle',
    bulletpoint1: 'sbv_login_bullet_1',
    bulletpoint2: 'sbv_login_bullet_2',
    bulletpoint3: 'sbv_login_bullet_3',
  },
  billie: {
    heading: ['billie_login_title'],
    text: 'billie_login_subtitle',
    bulletpoint1: 'billie_login_bullet_1',
    bulletpoint2: 'billie_login_bullet_2',
    bulletpoint3: 'billie_login_bullet_3',
  },
}

export default content
