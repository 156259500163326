import React from 'react'
import LangSettings from 'pages/Authenticated/AccountSettings/views/LangSettings/LangSettings.component'
import UserApi from 'api/user.api'
import { queryKeys } from 'queries'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const LangSettingsPage: React.FC = () => {
  const queryCache = useQueryClient()
  const dispatch = useDispatch()

  const { i18n } = useTranslation()

  const handleLanguageChange = async (passedLang: string) => {
    //await UserApi.update({lang: passedLang})
    // change translations (i18n) to new language
    i18n.changeLanguage(passedLang, async () => {
      const updatedUser = await UserApi.getMe()
      dispatch({ type: 'CHANGE_LANGUAGE' });
      await queryCache.invalidateQueries()
      await queryCache.setQueryData(queryKeys.USER, updatedUser)
      moment.locale(passedLang)

      window.location.replace(`/settings/lang`)
      // dispatch(A.switchLanguage()
    })
  }

  return (
    <LangSettings
      // @ts-ignore
      // lang={user.lang}
      handleLanguageChange={handleLanguageChange}
    />
  )
}

export default LangSettingsPage
