import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router'

import { MainLayoutRightPaneProps, mainLayoutRightPaneClasses as classes } from '../..'
import { Document, Template } from './RightPane.components'

export const RightPane: FunctionComponent<MainLayoutRightPaneProps> = React.memo(() => {
  return (
    <section className={classes.wrapper}>
      <Switch>
        <Route path="/documents">
          <Document />
        </Route>
        <Route path="/templates">
          <Template />
        </Route>
      </Switch>
    </section>
  )
})

RightPane.displayName = 'MainLayout-RightPane'

export default RightPane
