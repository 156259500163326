import {
  FULL_RANGE,
  LOCATION_TYPES,
  SEGMENTS_MARKER_ID_MATCH,
  LocationType,
  Question,
  QuestionLayoutGroup,
  Segment,
  TableCellObject,
  TextLocation,
  Answer,
  EditorMode,
} from '___types'

import { SegmentsLocation, WizardStateDataStructureSection } from '___types'
import { LocalStateType } from './selectors'
import { getMappedSegmentIds, getMarkerByTypeAndId, getSegmentById } from './helpers'
import { getMarkerById } from './helpers/template-automation'

// ============================================================== //
// ======================= DATA STRUCTURE ======================= //
// ============================================================== //
// selectWizardSectionByIndex
export type WizardSectionByIndexSelector = WizardStateDataStructureSection | undefined
export const selectWizardSectionByIndex = (state: LocalStateType, index: number): WizardSectionByIndexSelector =>
  state.wizard.dataStructure?.sections ? state.wizard.dataStructure?.sections[index] : undefined

// selectWizardSectionPageRangeByIndices
type WizardSectionPageRangeByIndicesSelector = string
const selectWizardSectionPageRangeByIndices = (
  state: LocalStateType,
  sectionIndex: number,
  pageIndex: number
): WizardSectionPageRangeByIndicesSelector => selectWizardSectionByIndex(state, sectionIndex)?.pages[pageIndex].join('-') || FULL_RANGE.join('-')

// selectWizardSectionPageContentIdsByIndices
export type WizardSectionPageContentIdsByIndicesSelector = string
export const selectWizardSectionPageContentIdsByIndices = (
  state: LocalStateType,
  sectionIndex: number,
  pageIndex: number
): WizardSectionPageContentIdsByIndicesSelector => {
  const pageRange = selectWizardSectionPageRangeByIndices(state, sectionIndex, pageIndex).split('-')
  const mappedSegmentIds = getMappedSegmentIds(state.wizard, 'root').slice(...pageRange.map(string => Number(string)))
  const contentIds = mappedSegmentIds.map(id => id.split(';').pop())
  return Array.from(new Set(contentIds)).join(';')
}

// selectWizardSegmentsMarkerContentByIdAndParent
export type WizardSegmentsMarkerContentByIdAndParentSelector = string
export const selectWizardSegmentsMarkerContentByIdAndParent = (
  state: LocalStateType,
  id: string,
  parent: string
): WizardSegmentsMarkerContentByIdAndParentSelector => {
  const location = selectWizardLocationByTypeAndId(state, LOCATION_TYPES.SEGMENTS, id)
  if (!location) return ''
  const { isHeaderFooter, parentId, sectionIndex, pageIndex } = parent.match(SEGMENTS_MARKER_ID_MATCH)?.groups || {}
  // const { isHeaderFooter, headerFooterId, parentId, sectionIndex, pageIndex } = parent.match(SEGMENTS_MARKER_ID_MATCH)?.groups || {}
  let pageStart = FULL_RANGE[0] as number
  let pageEnd = FULL_RANGE[1]
  if (!isHeaderFooter && sectionIndex && pageIndex) {
    const pageRangeByIndices = selectWizardSectionPageRangeByIndices(state, Number(sectionIndex), Number(pageIndex)).split('-')
    pageStart = Number(pageRangeByIndices[0])
    pageEnd = Number(pageRangeByIndices[1])
  }
  const relevantRange = [Math.max(location.range[0], pageStart), Math.min(location.range[1], pageEnd)]
  const headerFooterSegments = undefined as unknown as string[] // Implement later
  //   const headerFooterSegments = isHeaderFooter ? (selectors.selectHeaderFooterById(state, parentId)?.segments || []).map(({ id }) => id) : undefined
  const mappedSegmentIds = getMappedSegmentIds(state.wizard, parentId, headerFooterSegments).slice(...relevantRange)
  const contentIds = mappedSegmentIds.map(segmentId => segmentId.split(`;marker:${id}`)[0].split(';').pop())
  return `${location.range[0] >= pageStart ? ';' : ''}${Array.from(new Set(contentIds)).join(';')}${location.range[1] <= pageEnd ? ';' : ''}`
}

// selectWizardSegmentById
export type WizardSegmentByIdSelector = Segment | TableCellObject | undefined
export const selectWizardSegmentById = (state: LocalStateType, id: string, mode: EditorMode = 'edit'): WizardSegmentByIdSelector =>
  getSegmentById(state.wizard, id, mode)[0]

// selectWizardTableCellContentById
export type WizardTableCellContentByIdSelector = string
export const selectWizardTableCellContentById = (state: LocalStateType, id: string): WizardTableCellContentByIdSelector => {
  const mappedSegmentIds = getMappedSegmentIds(state.wizard, id)
  const contentIds = mappedSegmentIds.map(id => id.split(';').pop())
  return `${Array.from(new Set(contentIds)).join(';')}`
}

// selectWizardParagraphNumberingById
export type WizardParagraphNumberingByIdSelector = string
export const selectWizardParagraphNumberingById = (state: LocalStateType, id: string): WizardParagraphNumberingByIdSelector => {
  const numbering = state.wizard.dataStructure?.numbering
  const relevantNumbering = numbering && numbering[id]
  return relevantNumbering ? `${relevantNumbering.system}:${relevantNumbering.value}` : ':'
}
// ============================================================== //
//
// =============================================================== //
// ========================== LOCATIONS ========================== //
// =============================================================== //
// selectWizardSegmentsMarkerLocationsByParentId
export type WizardSegmentsMarkerLocationsByParentIdSelector = SegmentsLocation[] | undefined
export const selectWizardSegmentsMarkerLocationsByParentId = (
  state: LocalStateType,
  parentId: string
): WizardSegmentsMarkerLocationsByParentIdSelector => (state.wizard.locations?.segments || {})[parentId]

// selectWizardTextMarkerLocationsByParentId
export type WizardTextMarkerLocationsByParentIdSelector = TextLocation[] | undefined
export const selectWizardTextMarkerLocationsByParentId = (state: LocalStateType, parentId: string): WizardTextMarkerLocationsByParentIdSelector =>
  (state.wizard.locations?.text || {})[parentId]

// selectWizardSegmentsMarkerLocationById
export type WizardSegmentsMarkerLocationByIdSelector = SegmentsLocation | undefined
export const selectWizardSegmentsMarkerLocationById = (state: LocalStateType, id: string): WizardSegmentsMarkerLocationByIdSelector =>
  getMarkerByTypeAndId(state.wizard, LOCATION_TYPES.SEGMENTS, id)[0]

// selectWizardTextMarkerLocationById
export type WizardTextMarkerLocationByIdSelector = TextLocation | undefined
export const selectWizardTextMarkerLocationById = (state: LocalStateType, id: string): WizardTextMarkerLocationByIdSelector =>
  getMarkerByTypeAndId(state.wizard, LOCATION_TYPES.TEXT, id)[0]

// selectWizardLocationByTypeAndId
export type WizardLocationByTypeAndIdSelector = SegmentsLocation | TextLocation | undefined
export const selectWizardLocationByTypeAndId = (state: LocalStateType, type: LocationType, id: string): WizardLocationByTypeAndIdSelector =>
  getMarkerByTypeAndId(state.wizard, type, id)[0]

// selectWizardLocationById
export type WizardLocationByIdSelector = SegmentsLocation | TextLocation | undefined
export const selectWizardLocationById = (state: LocalStateType, id: string): WizardLocationByIdSelector => getMarkerById(state.wizard, id)[0]

// =============================================================== //
//
// =============================================================== //
// ========================== QUESTIONS ========================== //
// =============================================================== //
// selectWizardQuestionById
export type WizardQuestionByIdSelector = Question | undefined
export const selectWizardQuestionById = (state: LocalStateType, questionId: string): WizardQuestionByIdSelector =>
  state.wizard.questions?.find(({ id }) => id === questionId)
// =============================================================== //
//
// =============================================================== //
// ======================= QUESTION LAYOUT ======================= //
// =============================================================== //
// selectWizardQuestionLayoutGroupByIndex
export type WizardQuestionLayoutGroupByIndexSelector = QuestionLayoutGroup | undefined
export const selectWizardQuestionLayoutGroupByIndex = (state: LocalStateType, index: number): WizardQuestionLayoutGroupByIndexSelector =>
  state.wizard.questionLayout ? state.wizard.questionLayout[index] : undefined
// =============================================================== //
//
// =============================================================== //
// =========================== ANSWERS =========================== //
// =============================================================== //
// selectWizardAnswerById
export type WizardAnswerByIdSelector = Answer | undefined
export const selectWizardAnswerById = (state: LocalStateType, questionId: string): WizardAnswerByIdSelector =>
  state.wizard.answers?.find(({ id }) => id === questionId)
// =============================================================== //
