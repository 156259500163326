import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { MutationOptions, useMutation, useQueryClient } from 'react-query'

import { ITemplate, PartialTemplate } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type CreateTemplateVariables = PartialTemplate & { base64data: string }
export type CreateTemplateContext = { mutationId: string }
export type CreateTemplateMutationOptions = MutationOptions<ITemplate, unknown, CreateTemplateVariables, CreateTemplateContext>
export type CreateTemplateFunctionType = (template: PartialTemplate & { base64data: string }, options?: CreateTemplateMutationOptions) => void
// const createTemplateMutationFunction = (variables: CreateTemplateVariables) => templatesAPI.createTemplate(variables)

export const useCreateTemplate = () => {
  const queryClient = useQueryClient()
  const mutationId = useMemo(uuid, [])

  const updateListing = (method: (data: ITemplate[] | undefined) => ITemplate[]) => queryClient.setQueryData([QUERY_KEYS.TEMPLATES], method)

  const onMutate = (variables: CreateTemplateVariables) => {
    const moment = { _seconds: Math.floor(Date.now() / 1000) }
    const optimisticPayload = { id: mutationId, created: moment, edited: moment, mutating: true, mutation: 'create' }
    const optimisticTemplate = Object.assign({}, variables, optimisticPayload) as ITemplate
    updateListing(data => [optimisticTemplate].concat(data || []))
    return { mutationId }
  }

  const onError = (error: unknown, variables: CreateTemplateVariables, context: CreateTemplateContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
    updateListing((data: ITemplate[] | undefined) => data?.filter(({ id }) => id !== context?.mutationId) || [])
  }

  const onSuccess = (template: ITemplate, variables: CreateTemplateVariables, context: CreateTemplateContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
    queryClient.setQueryData([QUERY_KEYS.TEMPLATE, template.id], template)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === context?.mutationId, template))
  }

  const templateCreateMutation = useMutation<ITemplate, unknown, CreateTemplateVariables, CreateTemplateContext>(
    [QUERY_KEYS.TEMPLATE, mutationId],
    templatesAPI.createTemplate,
    { onMutate, onError, onSuccess }
  )

  // const createMutationFunction: CreateTemplateFunctionType = (template, options) =>
  //   templateCreateMutation.mutate(template, options)
  // return { create: createMutationFunction, creating: templateCreateMutation.isLoading }

  return { create: templateCreateMutation.mutate, creating: templateCreateMutation.isLoading }
}

export default useCreateTemplate
