import React from 'react'

import { className } from './index'

const Pen = React.memo(() => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <path d="M 70 20 25 65 25 75 35 75 80 30 C 80 20 80 20 70 20" stroke="none" fill="currentColor" />
    {/* <path d="M 70 20 30 60 20 80 40 70 80 30 C 80 20 80 20 70 20" stroke="none" fill="currentColor" /> */}
    <line x1="20" y1="90" x2="80" y2="90" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
  </svg>
))

export { Pen }
export default Pen
