import React, { FunctionComponent } from 'react'

import { DOCUMENTS_FILTERS } from '___types'
import { FilteredTemplatesGridProps } from '.'
import TemplatesGrid from './TemplatesGrid'

export const Mine: FunctionComponent<FilteredTemplatesGridProps> = React.memo(({ onClick }) => {
  return <TemplatesGrid filter={DOCUMENTS_FILTERS.MINE} onClick={onClick} />
})

Mine.displayName = 'Casus-Components-Grid-Templates-Mine'

export default Mine
