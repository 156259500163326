import ExternalServiceSelect from './ExternalServiceSelect'

import './style.scss'

const classes = {
  wrapper: 'TemplatePreviewModal-content-wrapper',
  leftPanel: {
    wrapper: 'TemplatePreviewModal-leftPanel-wrapper',
    thumbnail: 'TemplatePreviewModal-leftPanel-thumbnail',
    author: {
      wrapper: 'TemplatePreviewModal-leftPanel-author-wrapper',
    },
  },
  rightPanel: {
    wrapper: 'TemplatePreviewModal-rightPanel-wrapper',
    top: 'TemplatePreviewModal-rightPanel-top-wrapper',
    title: 'TemplatePreviewModal-rightPanel-title',
    description: 'TemplatePreviewModal-rightPanel-description',
    premiumDescription: {
      wrapper: 'TemplatePreviewModal-rightPanel-premiumDescription',
      icon: 'TemplatePreviewModal-rightPanel-premiumDescription-icon',
    },
    subscription: {
      wrapper: 'TemplatePreviewModal-rightPanel-subscription-wrapper',
      input: 'TemplatePreviewModal-rightPanel-subscription-input',
      price: 'TemplatePreviewModal-rightPanel-subscription-price',
    },
  },
  select: 'ExternalServiceSelect-select',
  option: 'ExternalServiceSelect-option',
}

export { ExternalServiceSelect, classes }

