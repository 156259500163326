import { useMemo } from 'react'
import useStore, {
  WizardDataStructureSelector,
  WizardSectionByIndexSelector,
  WizardSegmentsMarkerContentByIdAndParentSelector,
  WizardLocationByTypeAndIdSelector,
  NavigateQuestionnaireToAction,
  WizardState,
  getMappedSegmentIds,
  getMarkerByTypeAndId,
} from '___store'

import { FULL_RANGE, LOCATION_TYPES, SEGMENTS_MARKER_ID_MATCH } from '___types'
import { useEditorContext } from '..'

type UseStoreHookContentResultType = { wizardSegmentsMarkerContentByIdAndParent: WizardSegmentsMarkerContentByIdAndParentSelector }
type UseStoreHookLocationResultType = {
  wizardLocationByTypeAndId: WizardLocationByTypeAndIdSelector
  navigateQuestionnaireTo: NavigateQuestionnaireToAction
}
type UseTextMarkerResultType = {
  contentIds: string[]
  keep: boolean
  start: boolean
  end: boolean
  contentCustomStyle: string
  contentStyles: string[]
  replace?: string
  markerNavigate: (() => void) | null
}

export const useSegmentsMarker = (id: string, parent: string): UseTextMarkerResultType => {
  const editorContext = useEditorContext()
  const { dataStructure } = editorContext
  const { sections } = dataStructure || ({} as WizardDataStructureSelector)!

  let pageStart = FULL_RANGE[0] as number
  let pageEnd = FULL_RANGE[1]
  const staticPseudoState = Object.assign({}, editorContext as unknown) as WizardState
  const { range, contentCustomStyle = '', contentStyles = [] } = getMarkerByTypeAndId(staticPseudoState, LOCATION_TYPES.SEGMENTS, id)[0] || {}
  const contextLocationRange = range || FULL_RANGE
  const { isHeaderFooter, parentId, sectionIndex, pageIndex } = parent.match(SEGMENTS_MARKER_ID_MATCH)?.groups || {}
  // const { isHeaderFooter, headerFooterId, parentId, sectionIndex, pageIndex } = parent.match(SEGMENTS_MARKER_ID_MATCH)?.groups || {}
  if (!isHeaderFooter && sectionIndex && pageIndex) {
    const contextSection = sections && sectionIndex && sections[Number(sectionIndex)]
    const { pages } = contextSection || ({} as WizardSectionByIndexSelector)!
    const contextPageRange = (pages && pageIndex && pages[Number(pageIndex)]) || FULL_RANGE
    pageStart = Number(contextPageRange[0])
    pageEnd = Number(contextPageRange[1])
  }
  const relevantRange = [Math.max(contextLocationRange[0], pageStart), Math.min(contextLocationRange[1], pageEnd)]
  const headerFooterSegments = undefined as unknown as string[] // Implement later
  //   const headerFooterSegments = isHeaderFooter ? (selectors.selectHeaderFooterById(state, parentId)?.segments || []).map(({ id }) => id) : undefined

  const mappedSegmentIds = getMappedSegmentIds(staticPseudoState, parentId, headerFooterSegments).slice(...relevantRange)

  const topLayerContentIds = mappedSegmentIds.map(segmentId => segmentId.split(`;marker:${id}`)[0].split(';').pop())
  const contextSegmentsMarkerContentIds = `${contextLocationRange[0] >= pageStart ? ';' : ''}${Array.from(new Set(topLayerContentIds)).join(';')}${
    contextLocationRange[1] <= pageEnd ? ';' : ''
  }`

  const { wizardSegmentsMarkerContentByIdAndParent = '' } = useStore(
    !contextSegmentsMarkerContentIds ? `selectWizardSegmentsMarkerContentByIdAndParent[${id},${parent}]` : undefined
  ) as UseStoreHookContentResultType

  const { wizardLocationByTypeAndId, navigateQuestionnaireTo } = useStore(
    `selectWizardLocationByTypeAndId[${LOCATION_TYPES.SEGMENTS},${id}]`,
    'navigateQuestionnaireTo'
  ) as UseStoreHookLocationResultType
  const { valueSources, keep, replace } = wizardLocationByTypeAndId || ({} as WizardLocationByTypeAndIdSelector)!

  const resultingTopLayerContentIds = contextSegmentsMarkerContentIds || wizardSegmentsMarkerContentByIdAndParent
  const [start, contentIds, end] = useMemo(() => {
    const contentIdsSplit = resultingTopLayerContentIds.split(';')
    const splitCount = contentIdsSplit.length
    const [start, end] = [contentIdsSplit[0] === '', contentIdsSplit[splitCount - 1] === '']
    const result = contentIdsSplit.slice(Number(start), splitCount - Number(end)).filter(id => id)
    return [start, result, end]
  }, [resultingTopLayerContentIds])

  const markerNavigate = useMemo(() => {
    const firstConnectedQuestion = valueSources?.find(source => source.split(':')[0] === 'question')
    if (!firstConnectedQuestion) return null
    if (editorContext) return () => navigateQuestionnaireTo(`:${firstConnectedQuestion.split(':')[1]}::`)
    return null // Implement template-automation mode question navigation
  }, [valueSources, editorContext, navigateQuestionnaireTo])

  const useSegmentsMarkerReturn = { contentIds, keep, replace, start, end, contentCustomStyle, contentStyles, markerNavigate }

  return useSegmentsMarkerReturn
}

export default useSegmentsMarker
