import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, { WizardIntegrationsSelector } from '___store'

import Integration from './Integrations.Instance'
import {
  WizardLayoutLeftPaneDocumentConfigurationIntegrationsProps,
  wizardLayoutLeftPaneDocumentConfigurationIntegrationsClasses as classes,
} from 'Layouts/WizardLayout'

type UseStoreHookResultType = { wizardIntegrations: WizardIntegrationsSelector }

export const Integrations: FunctionComponent<WizardLayoutLeftPaneDocumentConfigurationIntegrationsProps> = React.memo(({ loadingCallback }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.configuration' })

  const { wizardIntegrations } = useStore('selectWizardIntegrations') as UseStoreHookResultType

  const render = useMemo(
    () =>
      wizardIntegrations
        ? Object.entries(wizardIntegrations).map(([id, { id: integrationId, cue, fields }]) => (
            <Integration
              key={`WizardLayout-LeftPane-Document-Configuration-Integrations-Integration-${id}`}
              id={id}
              integrationId={integrationId}
              title={cue}
              fields={fields}
              loadingCallback={loadingCallback}
            />
          ))
        : null,
    [wizardIntegrations, loadingCallback]
  )

  return render?.length ? (
    <div className={classes.wrapper}>
      <span className={classes.title}>{translate('integrations')}</span>
      <div className={classes.entries}>{render}</div>
    </div>
  ) : null
})

Integrations.displayName = 'WizardLayout-LeftPane-Document-Configuration-Integrations'

export default Integrations
