import React, { useState, useMemo, useEffect } from 'react'
import useStore from '___store'

import { generatePageLayoutString, generateListStylesString, generateStyleString } from 'Wizard/helpers'
import { Footer, Header, HeaderFooterToggleButton, Section, SelectionControls } from './components'
import { classes } from './index'
import { CASUS_CLASSES } from 'Wizard/constants'

const scrollToParagraph = id => {
  const paragraphs = Array.from(document.getElementsByClassName(CASUS_CLASSES.textarea))
  if (!paragraphs.length) return
  const paragraph = paragraphs.find(p => p.id === id)
  if (!paragraph) return
  paragraph.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const Editor = React.memo(() => {
  const {
    sectionCount,
    headerIds,
    footerIds,
    sectionLayouts,
    styles,
    numberingSystem,
    currentConfiguringKey: configuringParagraph,
    mode,
    removeConfiguring,
  } = useStore(
    'selectSectionCount',
    'selectHeaderIds',
    'selectFooterIds',
    'selectSectionLayouts',
    'selectStyles',
    'selectNumberingSystem',
    'selectCurrentConfiguringKey[paragraph]',
    'selectMode',
    'removeConfiguring'
  )

  const [headerFooterMode, setHeaderFooterMode] = useState(false)

  const sections = useMemo(() => new Array(sectionCount).fill(null), [sectionCount])
  const documentRender = useMemo(() => sections.map((_, i) => <Section key={`Section-${i}`} index={i}></Section>), [sections])
  const headers = useMemo(() => headerIds.split('; ').filter(id => id), [headerIds])
  const headerRender = useMemo(() => headers.map(id => <Header key={`Header-${id}`} id={id} />), [headers])
  const footers = useMemo(() => footerIds.split('; ').filter(id => id), [footerIds])
  const footerRender = useMemo(() => footers.map(id => <Footer key={`Footer-${id}`} id={id} />), [footers])

  const styleSheet = useMemo(() => {
    const pageLayoutString = generatePageLayoutString(sectionLayouts)
    const listStylesString = generateListStylesString(numberingSystem)
    // const pageListStartString = generatePageListStartString(numberingSystem) // IMPLEMENT IF NUMBERING RESETS AFTER NEW-PAGE/PAGE-BREAK
    const styleString = generateStyleString(styles)
    return [
      pageLayoutString,
      listStylesString,
      // pageListStartString,
      styleString,
    ]
      .filter(s => s)
      .join('\n')
  }, [sectionLayouts, numberingSystem, styles])

  useEffect(() => {
    const styleElement = document.createElement('style')
    styleElement.dataset.sheet = 'Wizard-Editor-Style-Sheet'
    styleElement.setAttribute('type', 'text/css')
    styleElement.innerHTML = styleSheet
    document.head.appendChild(styleElement)
    const revealEditorStyleElement = document.createElement('style')
    revealEditorStyleElement.setAttribute('type', 'text/css')
    revealEditorStyleElement.innerHTML = '.Wizard-Editor-wrapper { display: flex !important; }'
    let timeoutId = setTimeout(() => document.head.appendChild(revealEditorStyleElement), 500)
    return () => {
      document.head.removeChild(styleElement)
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      } else document.head.removeChild(revealEditorStyleElement)
    }
  }, [styleSheet])

  useEffect(() => {
    if (configuringParagraph) scrollToParagraph(configuringParagraph)
  }, [configuringParagraph])

  // console.log('EDITOR RENDER!')

  const editorMode = ['template-creation', 'content-editing'].includes(mode) ? 'edit' : 'preview'

  return (
    <section
      id={classes.id}
      className={classes.wrapper}
      data-mode={editorMode}
      data-configuring-paragraph={Boolean(editorMode === 'edit' && configuringParagraph)}
      onKeyDownCapture={event => {
        if (!(editorMode === 'edit' && configuringParagraph && event.key === 'Escape')) return
        event.stopPropagation()
        removeConfiguring({ key: 'paragraph' })
      }}
    >
      {mode === 'template-creation' ? <SelectionControls /> : null}
      <HeaderFooterToggleButton toggleCallback={() => setHeaderFooterMode(prev => !prev)} />
      {headerFooterMode ? (
        <>
          {headerRender}
          {footerRender}
        </>
      ) : (
        documentRender
      )}
    </section>
  )
})

Editor.displayName = 'Wizard-Editor'

export default Editor
