import React, { FunctionComponent } from 'react'

import { AccordionProps, classes } from '.'

export const Accordion: FunctionComponent<AccordionProps> = React.memo(({ isLoading, isFetching, children }) => {
  return <div className={classes.wrapper}>{children}</div>
})

Accordion.displayName = 'Casus-Components-Accordion'

export default Accordion
