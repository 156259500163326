import ParagraphConfiguration from './ParagraphConfiguration'

import './style.scss'

const classes = {
  wrapper: 'ParagraphConfiguration-wrapper',
  content: 'ParagraphConfiguration-content',
  inputDiv: 'ParagraphConfiguration-inputDiv',
  footer: 'ParagraphConfiguration-footer',
}

export { ParagraphConfiguration, classes }
export default ParagraphConfiguration
