import { MoreDropdownProps } from './MoreDropdown.types'
import React, { Fragment } from 'react'
import { MenuIcon, Body, BodyItem, Popover } from './MoreDropdown.styles'
import { moreDropdownContent as content } from 'pages/Authenticated/Template/constants/content'
import { useTranslation } from 'react-i18next'

const MoreDropdown: React.FC<MoreDropdownProps> = ({
  handlePreviewClick,
  handleEditClick,
  handleDeleteClick,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const { t } = useTranslation()
  const handleDelete = () => {
    if (handleDeleteClick) handleDeleteClick()
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<SVGElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Fragment>
      <MenuIcon iconName='more' onClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 30,
        }}
      >
        <Body>
          {handlePreviewClick && (
            <BodyItem onClick={handlePreviewClick}>{t(content.preview)}</BodyItem>
          )}
          {handleEditClick && (
            <BodyItem onClick={handleEditClick}>{t(content.edit)}</BodyItem>
          )}
          <BodyItem onClick={handleDelete} disabled={disabled}>
            {t(content.delete)}
          </BodyItem>
        </Body>
      </Popover>
    </Fragment>
  )
}

export default MoreDropdown
