// const convertDecimalToBaseN = (decimal: number, baseN: number, precision: number = 8): string => {
//   let integral = Math.trunc(decimal)
//   let fractional = decimal - integral
//   const left = []
//   while (integral / baseN > 1) {
//     left.push(integral % baseN)
//     integral = Math.floor(integral / baseN)
//   }
//   const right = []
//   while (right.length < precision && fractional !== 0) {
//     const res = Math.trunc(fractional * baseN)
//     right.push(res)
//     fractional -= res / baseN
//     fractional *= baseN
//   }
//   let result = `${left.join('') || '0'}`
//   if (right.length) result+= `.${right.join('')}`
//   return result
// }

// export { convertDecimalToBaseN }

// const numberToAlphabetic = (decimal: number)=> Array.from(convertDecimalToBaseN(decimal,26,0)).map(string=>string.charCodeAt

const camelToSnake = (string: string) => string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
const camelToDash = (string: string) => `${string.slice(0, 1)}${string.slice(1).replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)}`
const isTenant = (tenant: string, host: string) => host.split(/[.:/]+?/g)[0] === tenant

export { camelToSnake, camelToDash, isTenant }
