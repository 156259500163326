import React, { FunctionComponent, PropsWithChildren, useContext } from 'react'
import { DateTime } from 'luxon'

import { OPTION_VALUE_TYPES, OptionLimits, OptionValueTypeUnionType, Question as QuestionType } from '___types'

import QuestionGroup from './QuestionGroup'
import QuestionOverview from './QuestionOverview'

import './style.scss'

export const applyLimitsToValue = (valueType: OptionValueTypeUnionType, value: number | string, limits: OptionLimits): number | string => {
  if (valueType === OPTION_VALUE_TYPES.NUMBER) {
    const { min = -Infinity, max = +Infinity, step = 0 } = limits
    const clampedValue = Math.min(Math.max(Number(value), Number(min)), Number(max))
    const remainder = step ? clampedValue % Number(step) : 0
    return clampedValue - remainder // Implement other valueType limits
  }
  return value
}

export const getCurrentInputValue = (valueType: OptionValueTypeUnionType, input: HTMLPreElement | HTMLInputElement) => {
  if (!input) return ''
  switch (valueType) {
    case OPTION_VALUE_TYPES.STRING:
      return (input as HTMLPreElement).innerText
    case OPTION_VALUE_TYPES.DATE:
      return DateTime.fromFormat((input as HTMLInputElement).value, 'yyyy-MM-dd')?.toISO() || ''
    case OPTION_VALUE_TYPES.DATE_TIME:
      return DateTime.fromFormat((input as HTMLInputElement).value, "yyyy-MM-dd'T'HH:mm")?.toISO() || ''
    case OPTION_VALUE_TYPES.NUMBER:
      return Number((input as HTMLInputElement).value) || ''
    default:
      return (input as HTMLInputElement).value
  }
}

export const QuestionContext = React.createContext<QuestionType>({} as QuestionType)

type QuestionContextProviderProps = PropsWithChildren<{ question: QuestionType }>

export const QuestionContextProvider: FunctionComponent<QuestionContextProviderProps> = ({ question, children }) => {
  return <QuestionContext.Provider value={question}>{children}</QuestionContext.Provider>
}

export const useQuestionContext = () => {
  const currentQuestionContext = useContext(QuestionContext)
  if (!currentQuestionContext) {
    throw new Error('useQuestionContext has to be used within <QuestionContext.Provider>')
  }
  return currentQuestionContext
}

export { QuestionGroup, QuestionOverview }
