import { v4 as uuid } from 'uuid'

const SEGMENT_TYPES = {
  container: 'container',
  mark: 'mark',
  paragraph: 'paragraph',
  span: 'span',
  table: 'table',
  tableHeader: 'header',
  tableBody: 'body',
  tableFooter: 'footer',
  tableRow: 'row',
  tableData: 'cell',
  image: 'image',
}

const SEGMENT_TAGS = {
  [SEGMENT_TYPES.container]: 'div',
  [SEGMENT_TYPES.mark]: 'mark',
  [SEGMENT_TYPES.paragraph]: 'pre',
  [SEGMENT_TYPES.span]: 'span',
  [SEGMENT_TYPES.table]: 'table',
  [SEGMENT_TYPES.tableHeader]: 'thead',
  [SEGMENT_TYPES.tableBody]: 'tbody',
  [SEGMENT_TYPES.tableFooter]: 'tfoot',
  [SEGMENT_TYPES.tableRow]: 'tr',
  [SEGMENT_TYPES.tableData]: 'td',
  [SEGMENT_TYPES.image]: 'img',
}

const REPLACEMENT_TYPES = {
  text: 'QUESTION_TYPE_TEXT_BOX',
  date: 'QUESTION_TYPE_DATE',
  number: 'QUESTION_TYPE_NUMBER',
  radio: 'QUESTION_TYPE_RADIO_REPLACEMENT',
}

const REPLACEMENT_TYPES_ANSWER = {
  text: 'ANSWER_TYPE_TEXT_BOX',
  date: 'ANSWER_TYPE_DATE',
  number: 'ANSWER_TYPE_NUMBER',
  radio: 'ANSWER_TYPE_RADIO_REPLACEMENT',
}

// const CHOICE_TYPES = ['QUESTION_TYPE_CHECKBOX', 'QUESTION_TYPE_RADIO_LINK']

const KEYSTRINGS = {
  remove: '_casus_remove',
  keep: '_casus_keep',
  highlight: '_casus_highlight',
}

const CASUS_IDS = {
  rootElement: '_casus_root_element',
}

const CASUS_CLASSES = {
  pageContentRoot: '_casus_page_content_root',
  depthContainer: '_casus_depth_container',
  counterResetter: '_casus_counter_resetter',
}

const dataStructure = {
  segments: [
    {
      id: '10001',
      type: 'paragraph',
      tag: 'pre',
      styleName: 'paragraph-class',
      textChunks: [
        {
          styles: ['chunk-style-1', 'chunk-style-2'],
          text: 'sample text',
        },
      ],
    },
    {
      id: '10002',
      type: 'table',
      tag: 'table',
      styleName: 'table-class',
      tableHeader: [
        {
          styleName: 'table-header-class',
          cells: [
            {
              styleName: 'table-cell-class',
              content: [],
            },
          ],
        },
      ],
      tableBody: [
        {
          styleName: 'table-header-class',
          cells: [
            {
              styleName: 'table-cell-class',
              content: [],
            },
          ],
        },
      ],
      tableFooter: [
        {
          styleName: 'table-header-class',
          cells: [
            {
              styleName: 'table-cell-class',
              content: [],
            },
          ],
        },
      ],
    },
  ],
  numberingSystem: {
    'System-name': [
      {
        prefix: '',
        type: 'decimal',
        suffix: '.',
        combined: false,
        combineString: '',
        styleName: 'style-name-1',
      },
      {
        prefix: '',
        type: 'lower-latin',
        suffix: ')',
        combined: true,
        combineString: '',
        styleName: 'style-name-2',
      },
    ],
  },
}

const KEY_BLACKLIST = [
  // 'segments',
  'styles',
]
const MEASURE_RESET_KEYS = ['segments', 'content']

const extractNestedKeys = (structure, set = new Set()) => {
  Object.entries(structure).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (!KEY_BLACKLIST.includes(key)) set.add(key)
      value.forEach(obj => extractNestedKeys(obj, set))
    }
  })
  return set
}

const NESTED_STRUCTURE_KEYS = Array.from(extractNestedKeys(dataStructure))

const compareObjects = (prev, next) =>
  Object.entries(prev).reduce((acc, [key, value]) => acc && next[key] === value, true)

const filterRedundantStyles = (styles = []) =>
  styles.reduce((filtered, style) => {
    const prevStyle = filtered[filtered.length - 1] || null
    if (!(prevStyle && compareObjects(prevStyle, style))) filtered.push(style)
    return filtered
  }, [])

const compareStyles = (prev, next) =>
  prev.length === next.length && next.reduce((acc, cur) => acc && prev.some(style => compareObjects(style, cur)), true)

const mergeChunks = (chunks = []) =>
  chunks
    .reduce((acc, chunk) => {
      const prevChunk = acc[acc.length - 1] || null
      const prevChunkStyles = [prevChunk?.styleName, ...(prevChunk?.styles || [])].filter(s => s)
      const currentChunkStyles = [chunk.styleName, ...chunk.styles].filter(s => s)
      const comparedStyles = compareStyles(
        filterRedundantStyles(prevChunkStyles),
        filterRedundantStyles(currentChunkStyles)
      )
      if (prevChunk && comparedStyles && !chunk.drawing) prevChunk.text += chunk.text
      else acc.push(chunk)
      return acc
    }, [])
    .filter(({ text }) => text)

const enrichCSS = (css = {}, numberingSystem = {}) =>
  Object.entries(numberingSystem).reduce(
    (acc, [systemKey, systemObj]) =>
      systemObj.reduce((accumulated, styleConfig, i, entries) => {
        const { styleName } = styleConfig
        const content = styleConfig.combined
          ? `${entries.reduce((contentString, entry, j) => {
              return j < i
                ? `${contentString}'${entry.prefix}'counter(${CASUS_CLASSES.depthContainer}_${j + 1}_${systemKey}, ${
                    entry.type
                  })'${entry.suffix}${entry.combineString}'`
                : contentString
            }, '')}'${styleConfig.prefix}'counter(${CASUS_CLASSES.depthContainer}_${i + 1}_${systemKey}, ${
              styleConfig.type
            })'${styleConfig.suffix}${styleConfig.combineString} '`
          : `'${styleConfig.prefix}'counter(${CASUS_CLASSES.depthContainer}_${i + 1}_${systemKey}, ${
              styleConfig.type
            })'${styleConfig.suffix} '`
        return {
          ...accumulated,
          [`${SEGMENT_TAGS[SEGMENT_TYPES.paragraph]}.${styleName}::before`]: `counter-increment: ${
            CASUS_CLASSES.depthContainer
          }_${i + 1}_${systemKey}; content: ${content};`,
        }
      }, acc),
    Object.entries(css).reduce((acc, [key, value]) => ({ ...acc, [`${key}`]: value }), {})
  )
// Object.entries(css).reduce((acc, [key, value]) => ({ ...acc, [`${key}`]: value }), {})

const parseRows = (rows = [], id, type) => {
  return rows.reduce(
    (acc, row, rowIndex) => [
      ...acc,
      {
        tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
        customStyle: row?.styleName || '',
        styles: row?.styles || [],
        v2Styles: row?.v2Styles || null,
        cells: row?.cells?.map((cell, columnIndex) => ({
          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
          customStyle: cell?.styleName || '',
          styles: cell?.styles || [],
          v2Styles: cell?.v2Styles || null,
          content: parseSegments(cell?.content, `${id}(${type}) r/c: ${rowIndex}/${columnIndex}`),
        })),
      },
    ],
    []
  )
}

const parseSegments = (segments = [], propagatedId) =>
  segments.map(({ id, styleName, type, textChunks: chunks, header, body, footer, styles, v2Styles }, index) => {
    const segment = {
      type,
      tag: SEGMENT_TAGS[type],
      customStyle: styleName || '',
      styles: styles || [],
      v2Styles: v2Styles || {},
    }
    if (id) segment.id = String(id)
    else segment.id = propagatedId ? `${propagatedId}-${index}` : uuid()
    if (chunks) {
      /* const textChunks = mergeChunks(chunks)
      if (textChunks.length) */ segment.textChunks = chunks
    }
    if (header) {
      const tableHeader = parseRows(header, id, SEGMENT_TYPES.tableHeader)
      if (tableHeader.length) segment.tableHeader = tableHeader
    }
    if (body) {
      const tableBody = parseRows(body, id, SEGMENT_TYPES.tableBody)
      if (tableBody.length) segment.tableBody = tableBody
    }
    if (footer) {
      const tableFooter = parseRows(footer, id, SEGMENT_TYPES.tableFooter)
      if (tableFooter.length) segment.tableFooter = tableFooter
    }
    return segment
  })

const measure = (object = {}, start = 0) => {
  if (object.text && object.text.length) {
    return { ...object, start, length: object.text.length }
  }
  const [result, end] = NESTED_STRUCTURE_KEYS.reduce(
    (acc, key) => {
      if (Object.keys(acc[0]).includes(key) && acc[0][key].length) {
        const calculatedStart = acc[0].id ? 0 : acc[1]
        const [measuredArray, arrayEnd] = acc[0][key].reduce(
          (accumulated, object) => {
            const nestedObject = measure(object, MEASURE_RESET_KEYS.includes(key) ? 0 : accumulated[1])
            accumulated[0].push(nestedObject)
            accumulated[1] += nestedObject.length
            return accumulated
          },
          [[], calculatedStart]
        )
        acc[0][key] = measuredArray
        acc[1] += arrayEnd - calculatedStart
      }
      return acc
    },
    [object, start]
  )
  return { ...result, start, length: end - start }
}

const measureStructureSegments = (structure = {}, start = 0) =>
  structure.segments?.reduce(
    (acc, segment) => {
      const measuredSegment = measure(segment, acc[1])
      acc[0].push(measuredSegment)
      acc[1] += measuredSegment.length
      return acc
    },
    [[], start]
  )[0] || []

const parseQuestionLocations = (question = {}) =>
  (Object.values(REPLACEMENT_TYPES).includes(question.type)
    ? question?.locations
    : question?.options?.reduce(
        (locations, option) => [
          ...locations,
          ...option.locations.map(location => ({
            ...location,
            optionId: option.id,
          })),
        ],
        []
      ) || []
  )?.map(({ id, start, length, questionId: qid, locationId: lid, optionId }) => ({
    id,
    start,
    length,
    qid,
    lid,
    optionId,
  }))

export {
  SEGMENT_TYPES,
  SEGMENT_TAGS,
  REPLACEMENT_TYPES,
  REPLACEMENT_TYPES_ANSWER,
  MEASURE_RESET_KEYS,
  NESTED_STRUCTURE_KEYS,
  KEYSTRINGS,
  CASUS_IDS,
  CASUS_CLASSES,
  mergeChunks,
  enrichCSS,
  parseSegments,
  measure,
  measureStructureSegments,
  parseQuestionLocations,
}
