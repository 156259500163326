import React from 'react'

import './style.css'
import { classes } from '.'
import { DOCXDocument } from 'assets/svgIconComponents'

const SkeletonFileComponent: React.FC = () => {
  return (
    <div className={classes.thumbnail.wrapper} data-loading={true}>
      <div className={classes.thumbnail.selectButton} />
      <div className={classes.thumbnail.preview}>
        <DOCXDocument data-loading={true} />
      </div>
      <div className={classes.thumbnail.base.wrapper}>
        <pre className={classes.thumbnail.base.title}></pre>
        <span className={classes.thumbnail.base.relativeTime}></span>
      </div>
    </div>
  )
}

export default SkeletonFileComponent

