import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardDataStructureSelector, WizardLocationsSelector, WizardNumberingSelector } from '___store'

import { EditorContextProvider } from '.'
import Section from './Interact.Components'
// import Section, { FocusMode } from './Interact.Components'
import { wizardLayoutRightPaneEditorInteractClasses as classes } from '../../..'

type UseStoreHookResultType = {
  wizardDataStructure: WizardDataStructureSelector
  wizardNumbering: WizardNumberingSelector
  wizardLocations: WizardLocationsSelector
}

export const Interact: FunctionComponent = React.memo(() => {
  const { wizardDataStructure, wizardNumbering, wizardLocations } = useStore(
    'selectWizardDataStructure',
    'selectWizardNumbering',
    'selectWizardLocations'
  ) as UseStoreHookResultType

  const sections = useMemo(
    () => new Array(wizardDataStructure?.sections?.length || 0).fill(null).map((_, i) => <Section key={`Section-${i}`} index={i}></Section>),
    [wizardDataStructure]
  )

  return (
    <div className={classes.wrapper}>
      <EditorContextProvider
        key="interact"
        mode="interact"
        dataStructure={wizardDataStructure}
        numbering={wizardNumbering}
        locations={wizardLocations}
      >
        {/* <FocusMode /> */}
        <div className={classes.content}>{sections}</div>
      </EditorContextProvider>
    </div>
  )
})

Interact.displayName = 'WizardLayout-RightPane-Editor-Interact'

export default Interact
