import { User } from 'types'
import React, { useEffect, useState } from 'react'
import {
  Redirect,
  Switch,
  useHistory,
  // useLocation
} from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import * as Sentry from '@sentry/react'

import { useWatchSubscriptions } from 'components/subscriptions/hooks/useWatchSubscriptions'
import { useUser } from 'queries/user/useUser'
import { useMasterData } from 'queries/app/useMasterdata'
import { useLogout } from 'queries/auth/useLogout'
import { useModalComponents } from 'hooks/useModalComponents'

import Dashboard from './Dashboard/Dashboard'
// import Documents from './Documents/Documents.page'
// import DocumentFile from './DocumentFile/DocumentFile.page'
// import Templates from './Templates/Templates.page'
import Constulting from './LegalAdvice/LegalAdvice.page'
import AccountSettings from './AccountSettings/AccountSettings.page'
import Team from './Team/TeamPage'
import Template from './Template/Template.page'
import Logout from './Logout/LogoutPage'
import Pdf from './Pdf/Pdf'
//import ProfileInfoContainer from 'components/user/AfterSignUpProfileSetup/AfterSignUpProfileSetup'
import SubscriptionPlans from './SubscriptionPlans/SubscriptionPlans.page'

import PrivateRoute from 'hoc/PrivateRoute'
import { Redirects } from 'components/helpers/redirects/Redirects'
import { Container } from './Authenticated.page.styles'
import { USER_TYPE_CLIENT_ADMIN, USER_TYPE_LAWYER_ADMIN } from 'constants/user-types'
import { isDev } from 'config'
import AuthApi from 'api/auth.api'
import { auth } from 'utils'
import { SetReminderModal } from 'components/files/FileAndFolderActions/reminders/SetReminderModal.container'
import SignedPage from './Signature/SignaturePage'

import DevPage from 'pages/dev'
import MainLayout, { WizardLayout } from 'Layouts'
import SelectTemplate from './DocumentFile/views/DocumentCreate/SelectTemplate/SelectTemplate.page'
import DocumentFile from './DocumentFile/DocumentFile.page'
import { Loader } from 'components/common'
// import SelectTemplate from './DocumentFile/views/DocumentCreate/SelectTemplate/SelectTemplate.page'

const useData = () => {
  const masterdata = useMasterData()
  const user = useUser()
  const teamClientAllowed = Boolean(masterdata?.client.team_members_enabled) && user?.type === USER_TYPE_CLIENT_ADMIN
  const teamLawyerAllowed = Boolean(masterdata?.lawyer.team_members_enabled) && user?.type === USER_TYPE_LAWYER_ADMIN

  return {
    user,
    teamPageAllowed: teamClientAllowed || teamLawyerAllowed,
    consultingPageAllowed: Boolean(masterdata?.legal_stuff.advice_tab_shown),
    dataFetched: !!user && !!masterdata,
  }
}

const useAuthRefresh = (user?: User | null) => {
  const [intervalId, setIntervalId] = useState<null | NodeJS.Timeout>(null)

  useEffect(() => {
    if (user && !intervalId) {
      const interval = setInterval(async () => {
        const firebaseUser = auth.currentUser
        if (firebaseUser) {
          await AuthApi.authenticate(firebaseUser)
        }
      }, 1000 * 60 * 45)
      setIntervalId(interval)
    }
  }, [user, intervalId])
}

const Main: React.FC = () => {
  const { location } = useHistory()
  const [authInitialized, setAuthInitialized] = useState(false)
  const { consultingPageAllowed, teamPageAllowed } = useData()
  const user = useUser()
  const logout = useLogout()
  useAuthRefresh(user)
  const modalComponents = useModalComponents()
  // const currentLocation = useLocation()

  useWatchSubscriptions()

  useEffect(() => {
    if (user && !isDev) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
      })
    }
  }, [user])


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setAuthInitialized(true);

      if (firebaseUser) {
        AuthApi.authenticate(firebaseUser);
      } else {
        logout();
      }
    });

    return () => unsubscribe();
  }, [logout]);

  if (!authInitialized) {
    return <Loader/>;
  }

  if (!user) {
    // const redirectURL = `${currentLocation.pathname}${currentLocation.search}`
    const redirectURL = ''
    localStorage.setItem('loginRedirect', redirectURL)
    return <Redirect to="/auth/login" />
  } else {
    const redirectURL = localStorage.getItem('loginRedirect')
    if (redirectURL) {
      localStorage.removeItem('loginRedirect')
      return <Redirect to={redirectURL} />
    }
  }

  // @ts-ignore
  return (
    <IdleTimer timeout={1000 * 60 * 60 * 3} onIdle={() => logout()}>
      <Container>
        <Switch location={location}>
          <PrivateRoute path="/redirect" component={Redirects} isAuthenticated={Boolean(user)} />
          <PrivateRoute exact path="/" component={Dashboard} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/templates" exact={true} component={MainLayout} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/documents" exact={true} component={MainLayout} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/document/new" component={DocumentFile} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/document/new/:templateId" component={DocumentFile} isAuthenticated={Boolean(user)} />
          <PrivateRoute path={`/document/:id/preview`} exact={true} component={DocumentFile} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/document" exact={true} component={SelectTemplate} isAuthenticated={Boolean(user)} />
          {/* <PrivateRoute path="/document" exact={true} component={WizardLayout} isAuthenticated={Boolean(user)} /> */}
          <PrivateRoute path="/document/:templateId" exact={true} component={WizardLayout} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/document/:templateId/:documentId" exact={true} component={WizardLayout} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/document/:templateId/:documentId/:mode" exact={true} component={WizardLayout} isAuthenticated={Boolean(user)} />
          {/* <PrivateRoute path={`/documents/:templateId/configure/:documentId`} exact={true} component={WizardLayout} isAuthenticated={Boolean(user)} /> */}
          {/* <PrivateRoute path={`/documents/:id`} exact={true} component={WizardLayout} isAuthenticated={Boolean(user)} /> */}
          {/* <PrivateRoute path={`/documents/:templateId/configure`} exact={true} component={WizardLayout} isAuthenticated={Boolean(user)} /> */}
          {/* <PrivateRoute path={`/documents/:templateId/new`} exact={true} component={WizardLayout} isAuthenticated={Boolean(user)} /> */}
          {/* <PrivateRoute path={`/documents`} component={Documents} isAuthenticated={Boolean(user)} /> */}
          {/* <PrivateRoute path={`/document`} component={DocumentFile} isAuthenticated={Boolean(user)} /> */}
          <PrivateRoute path={`/template`} component={Template} isAuthenticated={Boolean(user)} />
          {/* <PrivateRoute path={`/templates`} component={Templates} isAuthenticated={Boolean(user)} /> */}
          <PrivateRoute path={`/signatures`} component={SignedPage} isAuthenticated={Boolean(user)} />
          <PrivateRoute path={`/pdf`} component={Pdf} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/rechtsberatung" component={Constulting} isAuthenticated={Boolean(user)} isAllowed={consultingPageAllowed} />
          <PrivateRoute path={`/settings`} component={AccountSettings} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/team" component={Team} isAuthenticated={Boolean(user)} isAllowed={teamPageAllowed} />
          <PrivateRoute path="/abo" component={SubscriptionPlans} isAuthenticated={Boolean(user)} />
          <PrivateRoute exact path="/logout" component={Logout} isAuthenticated={Boolean(user)} />
          <PrivateRoute path="/dev" component={DevPage} isAuthenticated={Boolean(user)} />
          <Redirect to={`/documents`} />
        </Switch>
        {/* <ProfileInfoContainer /> */}
        {modalComponents.map((Component, i) => (
          <Component key={i} />
        ))}
      </Container>
      <SetReminderModal />
    </IdleTimer>
  )
}

export default Main
