import Section from './Section'
import Page from './Page'
import SelectionControls from './SelectionControls'
import HeaderFooterToggleButton from './HeaderFooterToggleButton'
import Header from './Header'
import Footer from './Footer'
// import EditorControls from './EditorControls'
// import { PageProvider } from './PageContext'

import './style.scss'

export const mapContentIds = contentIds =>
  contentIds.map(id => (id.slice(0, 6) === 'marker' ? { type: 'marker', id: id.slice(7) } : { type: 'segment', id }))

export {
  Section,
  Page,
  //   EditorControls,
  //   PageProvider,
  SelectionControls,
  HeaderFooterToggleButton,
  Header,
  Footer,
}
