import { TupleToUnion, ValuesOf } from '.'
import { CASUS_KEYSTRINGS, CASUS_PREFIX } from './types.general'
import { TEMPLATE_FLOW_MODES, DOCUMENT_FLOW_MODES } from './types.wizard'

// =============================================================================== //
// ============================== CONSTANTS & TYPES ============================== //
// =============================================================================== //
export const EDITOR_MODES = {
  preview: [DOCUMENT_FLOW_MODES.PREVIEW],
  interact: [DOCUMENT_FLOW_MODES.NEW, DOCUMENT_FLOW_MODES.EDIT],
  edit: [TEMPLATE_FLOW_MODES.NEW],
  content: [TEMPLATE_FLOW_MODES.CONTENT],
} as const
export type EditorMode = keyof typeof EDITOR_MODES

export const SEGMENT_IGNORE_STYLES = ['Normal'] as const
export type SegmentIgnoreTypes = TupleToUnion<typeof SEGMENT_IGNORE_STYLES>

export const STYLE_MAP = {
  [`${CASUS_PREFIX}fsize`]: `${CASUS_PREFIX}font-size`,
  [`${CASUS_PREFIX}bold`]: `${CASUS_PREFIX}font-weight-700`,
  [`${CASUS_PREFIX}italic`]: `${CASUS_PREFIX}font-style-italic`,
  [`${CASUS_PREFIX}underline`]: `${CASUS_PREFIX}text-decoration-underline`,
  [`${CASUS_PREFIX}strikethrough`]: `${CASUS_PREFIX}text-decoration-line\\-through`,
  [`${CASUS_PREFIX}indentation-left`]: `${CASUS_PREFIX}indent-left`,
  [`${CASUS_PREFIX}indentation-right`]: `${CASUS_PREFIX}indent-right`,
  [`${CASUS_PREFIX}tab`]: `${CASUS_PREFIX}tab-size`, // BE needs to implement tab-stop style naming properly
  [`${CASUS_PREFIX}jl`]: `${CASUS_PREFIX}align-left`,
  [`${CASUS_PREFIX}jc`]: `${CASUS_PREFIX}align-center`,
  [`${CASUS_PREFIX}jr`]: `${CASUS_PREFIX}align-right`,
  [`${CASUS_PREFIX}jj`]: `${CASUS_PREFIX}align-justify`,
  [`${CASUS_PREFIX}superscript`]: `${CASUS_PREFIX}vertical-align-super ${CASUS_PREFIX}line-height-0 ${CASUS_PREFIX}font-size-x\\-small`,
  [`${CASUS_PREFIX}subscript`]: `${CASUS_PREFIX}vertical-align-sub`,
  [`${CASUS_PREFIX}top-border`]: `${CASUS_PREFIX}border-top-1px__solid__black`,
  [`${CASUS_PREFIX}bottom-border`]: `${CASUS_PREFIX}border-bottom-1px__solid__black`,
  [`${CASUS_PREFIX}left-border`]: `${CASUS_PREFIX}border-left-1px__solid__black`,
  [`${CASUS_PREFIX}right-border`]: `${CASUS_PREFIX}border-right-1px__solid__black`,
} as const

export type StyleMapKeys = keyof typeof STYLE_MAP

export const STYLE_INFO = {
  'font-weight': { property: 'font-weight', prefix: '', suffix: '', defaultValue: 'inherit', affixCondition: true },
  'font-style': { property: 'font-style', prefix: '', suffix: '', defaultValue: 'inherit', affixCondition: true },
  'text-decoration': { property: 'text-decoration', prefix: '', suffix: '', defaultValue: 'inherit', affixCondition: true },
  // indent: { property: 'margin-left', prefix: '', suffix: 'pt', defaultValue: '0', affixCondition: (value: string) => !Number.isNaN(Number(value)) },
  'indent-left': { property: '--indent-left', prefix: '', suffix: 'pt', defaultValue: '0', affixCondition: true },
  'indent-right': { property: '--indent-right', prefix: '', suffix: 'pt', defaultValue: '0', affixCondition: true },
  'tab-size': {
    property: 'tab-size',
    prefix: '',
    suffix: 'pt',
    defaultValue: 'inherit',
    affixCondition: (value: string) => !Number.isNaN(Number(value)),
  },
  align: { property: 'text-align', prefix: '', suffix: '', defaultValue: 'left', affixCondition: true },
  'vertical-align': { property: 'vertical-align', prefix: '', suffix: '', defaultValue: 'baseline', affixCondition: true },
  'line-height': {
    property: 'line-height',
    prefix: '',
    suffix: 'em',
    defaultValue: '0',
    affixCondition: (value: string) => !Number.isNaN(Number(value)),
  },
  border: { property: 'border', prefix: '', suffix: '', defaultValue: '1px solid black', affixCondition: true },
  'border-top': { property: 'border-top', prefix: '', suffix: '', defaultValue: '1px solid black', affixCondition: true },
  'border-bottom': { property: 'border-bottom', prefix: '', suffix: '', defaultValue: '1px solid black', affixCondition: true },
  'border-left': { property: 'border-left', prefix: '', suffix: '', defaultValue: '1px solid black', affixCondition: true },
  'border-right': { property: 'border-right', prefix: '', suffix: '', defaultValue: '1px solid black', affixCondition: true },
  'spacing-before': {
    property: 'margin-top',
    prefix: '',
    suffix: 'pt',
    defaultValue: '0',
    affixCondition: (value: string) => !Number.isNaN(Number(value)),
  },
  'spacing-after': {
    property: 'margin-bottom',
    prefix: '',
    suffix: 'pt',
    defaultValue: '0',
    affixCondition: (value: string) => !Number.isNaN(Number(value)),
  },
  'font-family': { property: 'font-family', prefix: '"', suffix: '", sans-serif', defaultValue: 'Arial', affixCondition: true },
  'font-size': {
    property: 'font-size',
    prefix: '',
    suffix: 'pt',
    defaultValue: '11',
    affixCondition: (value: string) => !Number.isNaN(Number(value)),
  },
  color: { property: 'color', prefix: '#', suffix: '', defaultValue: 'black', affixCondition: true },
  background: { property: 'background-color', prefix: '#', suffix: '', defaultValue: 'FFFFFF', affixCondition: true },
  width: { property: 'width', prefix: '', suffix: 'pt', defaultValue: 'auto', affixCondition: (value: string) => !Number.isNaN(Number(value)) },
  height: { property: 'height', prefix: '', suffix: 'pt', defaultValue: 'auto', affixCondition: (value: string) => !Number.isNaN(Number(value)) },
} as const

export type StylePropertyTypes = ValuesOf<typeof STYLE_INFO>['property']

export const STYLE_PROPERTIES = Object.entries(STYLE_INFO).reduce(
  (acc, [key, { property }]) => Object.assign(acc, { [`${CASUS_PREFIX}${key}`]: property }),
  {} as Record<string, string>
)

export const STYLE_AFFIXES = Object.entries(STYLE_INFO).reduce(
  (acc, [key, { prefix, suffix, affixCondition }]) => Object.assign(acc, { [`${CASUS_PREFIX}${key}`]: { prefix, suffix, affixCondition } }),
  {} as Record<string, { prefix: string; suffix: string; affixCondition: boolean | ((value: string) => boolean) }>
)

export const STYLE_DEFAULTS = Object.entries(STYLE_INFO).reduce(
  (acc, [key, { defaultValue }]) => (defaultValue && Object.assign(acc, { [`${CASUS_PREFIX}${key}`]: defaultValue })) || acc,
  {} as Record<string, string>
)

export type WizardEditorStylesType = {
  customStyles: Record<string, { [k: string]: string }>
  customProperties: Record<string, { [k: string]: string }>
  standardStyles: Record<string, { [k in StylePropertyTypes]: string }>
}
// =============================================================================== //

// =============================================================================== //
// ============================= REGULAR EXPRESSIONS ============================= //
// =============================================================================== //
export const SEGMENTS_MARKER_ID_MATCH = new RegExp(
  '(?<parentId>(?<=(?<isHeaderFooter>^headerFooter-)|(^(?!headerFooter-)))(root|.*))((?<=root)(-(?<sectionIndex>[0-9]+)-(?<pageIndex>[0-9]+)))?$',
  's'
)
export const PARAGRAPH_NUMBERING_MATCH = new RegExp(`^${CASUS_KEYSTRINGS.NUMBERING}-(?<systemKey>[0-9]+)-(?<depthLevel>[0-9]+)$`, 's')
export const MARKER_REPLACE_MATCH = new RegExp(`^{{${CASUS_KEYSTRINGS.REPLACE}(\\s)*(?<properties>([^{}]|\\{|\\})*)}}$`, 'm')
export const CSS_PROPERTY_VALUE_MATCH_ALL = new RegExp('(?<=^|;)(?<property>[^;:]+):(?<value>[^;:]+)(?=;|$)', 'g')
export const CSS_CUSTOM_CLASS_APPENDICES_SPLIT = new RegExp('(?=>|:)(?<!:)')
export const CSS_CUSTOM_CLASS_PROPERTY_MATCH = new RegExp(
  '(?<=^(?<isCustomClass>.)|(?<isCustomProperty>@|^))(?<customString>-?([a-zA-Z_]|\\~|\\@|\\#|\\$|\\%|\\[|\\]|\\(|\\)){1}([a-zA-Z0-9_-]|\\~|\\@|\\#|\\$|\\%|\\[|\\]|\\(|\\))*)$'
)
// =============================================================================== //

// =============================================================================== //
// ====================== HELPER FUNCTION CONSTANTS & TYPES ====================== //
// =============================================================================== //
//
// =============================================================================== //
