import IntegrationConfig from './IntegrationConfig'

import './style.scss'

const classes = {
  wrapper: 'IntegrationConfiguration-wrapper',
}

export { IntegrationConfig, classes }
export default IntegrationConfig
