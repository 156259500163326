import React from 'react'

import { className } from './index'

const Edit = React.memo(() => (
  <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3125 3.09375L10.7969 1.58594L11.6484 0.75C12.0625 0.34375 12.5312 0.304688 12.9141 0.6875L13.2188 0.992188C13.6016 1.38281 13.5781 1.84375 13.1562 2.26562L12.3125 3.09375ZM2.78125 12.6094L0.898438 13.3281C0.671875 13.4141 0.429688 13.1641 0.523438 12.9375L1.28125 11.1016L10.0625 2.32812L11.5625 3.82812L2.78125 12.6094Z"
      fill="#001640"
      fillOpacity="0.4"
    />
  </svg>
))

export default Edit

