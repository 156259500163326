import React, { FunctionComponent, useMemo } from 'react'

import Segment from './Segment'
import { useEditorPreviewContext, useSectionContext } from '..'
import { WizardLayoutRightPaneEditorPageProps, wizardLayoutRightPaneEditorPageClasses as classes } from '../../../..'
import { DOCUMENT_FLOW_MODES } from '___types'
import { WizardState, getMappedSegmentIds } from '___store'

export const Page: FunctionComponent<WizardLayoutRightPaneEditorPageProps> = React.memo(({ index }) => {
  const { index: sectionIndex } = useSectionContext()
  const editorContext = useEditorPreviewContext()
  const { mode, dataStructure, numbering } = editorContext

  const { sections } = dataStructure || {}
  const section = sections && typeof sectionIndex === 'number' && !isNaN(sectionIndex) && sections[sectionIndex]
  const { pages } = section || {}
  const pageRange = pages && typeof index === 'number' && !isNaN(index) && pages[index]

  const staticPseudoState = Object.assign({}, { dataStructure, numbering } as unknown) as WizardState

  const mappedContentIds = getMappedSegmentIds(staticPseudoState, 'root', mode)
    .slice(...(pageRange || [0, 0]))
    .map(id => id.split(';').pop()!)
  const contentIds = Array.from(new Set(mappedContentIds)).filter(id => id)

  const render = useMemo(() => contentIds?.map(id => <Segment key={`${DOCUMENT_FLOW_MODES.PREVIEW}-${id}`} id={id} />), [contentIds])

  return <div className={classes.wrapper}>{render}</div>
})

Page.displayName = 'WizardLayout-RightPane-Editor-Preview-Page'

export default Page
