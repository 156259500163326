import { Footer } from "./Footer";

const classes = {
    footer: {
      wrapper: 'Modal-Footer-wrapper',
      solo: 'Modal-Footer-Solo-wrapper',
    },
    button: {
      close: { wrapper: 'Modal-Footer-Button-Close-wrapper' },
      submit: { wrapper: 'Modal-Footer-Button-Submit-wrapper' },
      loader: { wrapper: 'Modal-Footer-Button-Loader-wrapper', svg: 'Modal-Footer-Button-Loader-svg' },
    },
  }

export { classes }

export default Footer;