import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SignatureListPage from './view/SignatureList/SignatureListPage'
import { SignaturePreviewPage } from './view/SignaturePreview/SignaturePreviewPage'

const SignaturePage: React.FC = () => {
  return (
    <Switch>
      <Route exact path={`/signatures`} component={SignatureListPage} />
      <Route exact path={`/signatures/:id`} component={SignaturePreviewPage} />
    </Switch>
  )
}

export default SignaturePage