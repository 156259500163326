import { StatusModalComponentProps } from './StatusModal.types'
import React from 'react'
import StandardModal from '../StandardModal/StandardModal'
import { Container, Heading, Description } from './StatusModal.styles'
import { useTranslation } from 'react-i18next'

const StatusModal: React.FC<StatusModalComponentProps> = ({
  heading,
  description,
  ...standardModalProps
}) => {
  const { t } = useTranslation()
  return (
    <StandardModal
      handleSecondaryClick={standardModalProps.handleClose}
      {...standardModalProps}
    >
      <Container>
        <Heading
          style={standardModalProps.open && !description ? { marginBottom: 24 } : {}}
        >
          {t(heading)}
        </Heading>
        {description && <Description>{t(description)}</Description>}
      </Container>
    </StandardModal>
  )
}

export default StatusModal
