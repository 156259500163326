import React, { Fragment, useState } from 'react'

import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useEditor } from 'config/textEditor/useEditor'

import ActionButtons from '../../ActionButtons/ActionButtons'
import CreateQuestionComponent from './CreateQuestion.component'
import { ScrollWrapper } from '../../../TemplateEdit.styles'
import { deepFindParentFromCurrentQuestion, StorageService } from 'utils'
import { QUESTION_TYPE_TEXT_BOX } from 'constants/question-types'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'
import { useUser } from 'queries/user/useUser'
import { useTranslation } from 'react-i18next'

export const CreateQuestion: React.FC = () => {
  const editor = useEditor()
  const { set, question, mainQuestion, questionStep, htmlData } = useTemplateCreation()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  const { t } = useTranslation()

  const [questionText, setQuestionText] = useState(question ? question.questionText : '')

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.target
    setQuestionText(value)
  }

  const onNext = (): void => {
    trackIntercomEvent('template-creation-question-text-added')
    set({ question: { ...question!, questionText }, questionStep: questionStep + 1 })
  }

  const onBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    StorageService.remove('textSelectionHtmlData')
    // console.log("BACK")
    if (docXmicro) return set({ question: null, questionStep: 0 })

    // ADD CONFIRM
    if (!mainQuestion) {
      if (editor) editor.setContent(htmlData)
      return set({
        selectedQuestionType: QUESTION_TYPE_TEXT_BOX,
        question: null,
        questionStep: 0,
      })
    }

    if (mainQuestion) {
      const parentQuestion = deepFindParentFromCurrentQuestion(mainQuestion, question!)
      if (parentQuestion) {
        if (editor) editor.setContent(htmlData)
        set({
          question: parentQuestion,
          selectedQuestionType: parentQuestion.type,
          mainQuestion: parentQuestion?.id === mainQuestion.id ? null : mainQuestion,
          questionStep: 5,
        })
      }
    }
  }

  return (
    <Fragment>
      <ScrollWrapper>
        <CreateQuestionComponent
          value={questionText}
          handleChange={handleChange}
          error={false}
        />
      </ScrollWrapper>
      <ActionButtons
        handleBackClick={onBack}
        handleForwardClick={onNext}
        forwardDisabled={questionText.length < 3}
        backDisabled={false}
        btnNextText={t('next')}
        id='template-creation-create-question'
      />
    </Fragment>
  )
}

export default CreateQuestion
