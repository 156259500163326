import Dashboard from './Dashboard'

import './style.scss'

const classes = {
  container: 'Homepage-dashboard-container',
  wrapper: 'Homepage-dashboard-wrapper',
  content: 'Homepage-dashboard-content',
  title: 'Homepage-dashboard-title',
  grid: {
    wrapper: 'Homepage-dashboard-grid-wrapper',
    panel: {
      wrapper: 'Homepage-dashboard-grid-panel-wrapper',
      title: { wrapper: 'Homepage-dashboard-grid-panel-title-title-wrapper', showAll: 'Homepage-dashboard-grid-panel-title-title-showAll' },
    },
    halfPanel: 'Homepage-dashboard-grid-halfPanel-wrapper',
    newDocument: {
      wrapper: 'Homepage-dashboard-grid-newDocument-wrapper',
      blurBg: 'Homepage-dashboard-grid-newDocument-blurBg',
      title: 'Homepage-dashboard-grid-newDocument-title',
      filter: {
        wrapper: 'Homepage-dashboard-grid-newDocument-filter-wrapper',
        text: 'Homepage-dashboard-grid-newDocument-filter-text',
      },
      templatesList: {
        wrapper: 'Homepage-dashboard-grid-newDocument-templatesList-wrapper',
        empty: 'Homepage-dashboard-grid-newDocument-templatesList-empty',
        emptyText: 'Homepage-dashboard-grid-newDocument-templatesList-emptyText',
      },
    },
  },
}

export { classes }

export default Dashboard

