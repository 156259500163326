import { useContext, useMemo, useReducer, useEffect } from 'react'
import { unpackSelectorKey } from './enhancers'
import { StoreContext } from './storeComposer'

export const useStore = (...keys) => {
  const store = useContext(StoreContext)
  if (!store) throw new Error('Could not find a store provider.')

  // console.log('useStore KEYS: ', keys)

  const keystring = keys.filter(key => key && unpackSelectorKey(key)[1].every(param => param !== 'undefined')).join('; ')

  const [selectorKeys, actions, actionCreators, selectedState] = useMemo(() => {
    if (!keystring) return []
    const selectKeys = []
    const actionKeys = []
    const creatorKeys = []
    keystring.split('; ').forEach(key => {
      if (!key) return
      if (key.slice(0, 6) === 'select') return selectKeys.push(key)
      if (key.slice(-7) === 'Creator') return creatorKeys.push(key)
      return actionKeys.push(key)
    })
    const boundActions = store.getActions(actionKeys)
    const creators = store.getActionCreators(creatorKeys)
    const state = Object.entries(store.select(selectKeys)).reduce(
      (acc, [key, value]) => Object.assign(acc, { [unpackSelectorKey(key)[0]]: value }),
      {}
    )
    // console.log('STATE UPON SUBSCRIBING: ', selectKeys, state)
    // console.log('KEYS: ', keystring.split('; '))
    // console.log('SELECT KEYS: ', selectKeys)
    // console.log('ACTION KEYS: ', actionKeys)
    // console.log('CREATOR KEYS: ', creatorKeys)
    return [selectKeys, boundActions, creators, state]
  }, [store, keystring])

  // console.log('SELECTOR KEYS: ', selectorKeys)
  // console.log('BOUND ACTIONS: ', actions)
  // console.log('ACTION CREATORS: ', actionCreators)
  // console.log('SELECTED STATE: ', selectedState)

  const initialState = Object.assign({}, selectedState, actions, actionCreators)
  const [state, setState] = useReducer((resultingState, action) => {
    if (typeof action === 'function') return action(resultingState)
    return Object.entries(action).reduce(
      (acc, [key, value]) => Object.assign(acc, { [unpackSelectorKey(key)[0]]: value }),
      Object.assign({}, resultingState)
    )
  }, initialState)

  useEffect(() => {
    if (selectorKeys) return store.subscribeToSelectors(selectorKeys, setState)
  }, [store, selectorKeys])

  return state
}
