import {
  ITemplate,
  DataStructure,
  CASUS_IDS,
  TemplatesFilter,
  TEMPLATES_FILTERS,
  PartialTemplate,
  BEFolder,
  IListTemplate,
  IUser,
  PartialBEFolder,
  SegmentsLocations,
} from '___types'
import API from './api'
import { getImageSrcURL, mapStructureKeys, parseCssData, parseStyles, unparseDataStructure, uploadBlobToFirebaseStorage } from './helpers'

// import testFodlers from './testTemplateFolderList.json'

export const TEMPLATE_LIST_PER_PAGE = 10

const parseTemplate = (template: any) => {
  const parsedCssData = parseCssData(template.cssData as Record<string, string>)
  const parsedStyles = Object.assign(parsedCssData, { standardStyles: parseStyles(parsedCssData, template.dataStructure) })
  Object.assign(template, { styles: parsedStyles })
  /////////////////////////////////////////////////////////////////////////// //
  ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
  /////////////////////////////////////////////////////////////////////////// //
  // remove parsing when BE fixes styleName => customStyle & id(number) => id(string)
  const parsedDataStructure = Object.assign(mapStructureKeys(template.dataStructure) as DataStructure, { id: CASUS_IDS.DATASTRUCTURE_ID })
  Object.assign(template, { dataStructure: parsedDataStructure })
  Object.assign(template, { integrations: template.externalAPIs })
  delete template.externalAPIs
  // // remove parsing when BE migrates (removes) externalAPI field duplicates
  // const parsedIntegrations = Object.entries((template.externalAPIs || {}) as Integrations).reduce(
  //   (result, [id, fields]) => Object.assign(result, { [id]: Array.from(new Set(fields)) }),
  //   {}
  // )
  // Object.assign(template, { integrations: parsedIntegrations })
  // delete template.externalAPIs
  // remove parsing when BE migrates (removes) segments locations contentStyle duplicates
  Object.values((template.locations?.segments || {}) as SegmentsLocations)
    .flat(1)
    .forEach(marker => {
      if (!marker.contentStyles?.length) return
      const arrayOfUniques = Array.from(new Set(marker.contentStyles))
      if (arrayOfUniques.length !== marker.contentStyles.length) Object.assign(marker, { contentStyles: arrayOfUniques })
    })
  Object.assign(template, { description: template.characteristics }) && delete template.characteristics
  Object.assign(template, { approvals: template.approvers }) && delete template.approvers
  if (Array.isArray(template.category)) Object.assign(template, { category: template.category[0] })
  /////////////////////////////////////////////////////////////////////////// //
  /////////////////////////////////////////////////////////////////////////// //
  /////////////////////////////////////////////////////////////////////////// //
}

class Templates extends API {
  public constructor() {
    super('templates/')
  }

  public getTemplateList = async () => {
    const res = await this.get('', undefined, undefined, 'list/templates')
    return res.data.templates.map((template: Record<string, unknown>) =>
      Object.assign(template, {
        category: Array.isArray(template.category) ? template.category[0] : template.category,
        companyShared: res.data.sharedCompanyTemplates.includes(template.id),
      })
    ) as IListTemplate[]
  }

  public getTrashedTemplateList = async () => {
    const res = await this.get('list/trashed', undefined, 'v1')
    return res.data.data.templates.map((template: Record<string, unknown>) =>
      Object.assign(template, { category: Array.isArray(template.category) ? template.category[0] : template.category })
    ) as IListTemplate[]
  }

  public getTemplateFolderList = async () => {
    const res = await this.get('', undefined, undefined, 'list/templateFolders')
    return res.data.templateCategories as BEFolder[]
  }

  public getTemplate = async (id?: string | null, publicFlow: boolean = false): Promise<ITemplate | undefined> => {
    if (!id) return
    const res = await this.get(`${publicFlow ? 'public/' : ''}${id}`)
    parseTemplate(res.data.data)
    return res.data.data as ITemplate
  }

  public createTemplate = async (template: PartialTemplate & { base64data: string }) => {
    const { data: { data: { storageId = '' } = {} } = {} } = await this.post('upload/docx', { base64data: template.base64data })
    const res = await this.post('', { new: true, storageId, name: template.name, category: template.category ?? [] })
    parseTemplate(res.data.data)
    return res.data.data as ITemplate
  }

  public uploadPreviewImage = async (id?: string, src?: string, width?: number, height?: number) => {
    if (!(id && src && width && height)) return
    const tempImg = document.createElement('img')
    tempImg.src = src
    const canvas = document.createElement('canvas')
    // canvas.width = width
    // canvas.height = height
    canvas.width = width / 2
    canvas.height = height / 2
    const ctx = canvas.getContext('2d')
    let res = undefined as unknown as string
    const onLoadHandler = ({ target }: Event) => {
      if (!target) return
      // ctx!.drawImage(target as HTMLImageElement, 0, 0)
      ctx!.drawImage(target as HTMLImageElement, 0, 0, width / 2, height / 2)
      canvas.toBlob(async blob => {
        if (!blob) return
        res = await uploadBlobToFirebaseStorage(`/templates/${id}/preview.png`, blob)
      })
    }
    tempImg.addEventListener('load', onLoadHandler)
    document.body.appendChild(tempImg)
    // tempImg.setAttribute('style', 'position: absolute')
    document.body.removeChild(tempImg)
    return res
  }

  public updateTemplate = async (template: PartialTemplate) => {
    if (!template.id) return
    const retainKeys = ['name', 'dataStructure', 'locations', 'questions', 'questionLayout', 'languages', 'externalAPIs', 'splits', 'category']
    const payload = Object.assign({}, template)
    ;(Object.keys(payload) as (keyof ITemplate)[]).forEach(key => !retainKeys.includes(key) && delete payload[key])
    /////////////////////////////////////////////////////////////////////////// //
    ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // remove unparsing when BE fixes styleName => customStyle & id(number) => id(string)
    if (template.dataStructure) Object.assign(payload, { dataStructure: unparseDataStructure(template.dataStructure) })
    if (template.description) Object.assign(payload, { characteristics: template.description }) && delete template.description
    if (template.approvals) Object.assign(payload, { approvers: template.approvals }) && delete template.approvals
    if (template.integrations) Object.assign(payload, { externalAPIs: template.integrations }) && delete template.integrations
    if (template.category !== undefined) Object.assign(payload, { category: [template.category].filter(s => s) })
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    const res = await this.post(`${template.id}`, payload)
    parseTemplate(res.data.data)
    return res.data.data as ITemplate
  }

  public duplicateTemplate = async (id: string) => {
    if (!id) return
    const res = await this.post(`${id}/duplicate`)
    parseTemplate(res.data.data)
    return res.data.data as ITemplate
  }

  public removeTemplate = async (id: string) => {
    if (!id) return
    await this.post(id, { status: 'trashed' })
    return
  }

  public shareTemplate = async (id: string, email: string, permissions: { use: boolean; edit: boolean }) => {
    if (!id) return
    /////////////////////////////////////////////////////////////////////////// //
    ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // remove when BE updates permissions
    const payload = { email, permissions: { read: permissions.use, write: permissions.edit } }
    const res = await this.post(`${id}/share`, payload, undefined, 'v1')
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    parseTemplate(res.data.data)
    return res.data.data as ITemplate
  }

  public unshareTemplate = async (id: string, shareId: string) => {
    if (!(id && shareId)) return
    const res = await this.delete(`${id}/share/${shareId}`, undefined, 'v1')
    parseTemplate(res.data.data)
    return res.data.data as ITemplate
  }

  public toggleCompanyShare = async (id: string) => {
    const res = await this.post(`${id}/company`)
    return res.data.data as IUser
  }

  public publishTemplate = async (id: string, payload: Record<string, unknown>) => {
    const res = await this.post(`${id}/publish`, payload)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseTemplate(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data.document as ITemplate
  }

  public getTemplatePreviewImageSrcURL = async (id?: string) => (id ? getImageSrcURL(`/templates/${id}/preview.png`) : undefined)

  public createTemplateFolder = async (name: string, parentFolder?: string | null) => {
    const payload = { name, parentCategoryId: '' }
    if (parentFolder && !Object.values(TEMPLATES_FILTERS).includes(parentFolder as TemplatesFilter))
      Object.assign(payload, { parentCategoryId: parentFolder })
    const res = await this.post('', payload, undefined, 'v1', 'templateCategories')
    return res.data.data as BEFolder
  }

  public updateTemplateFolder = async (folder: PartialBEFolder) => {
    if (!folder.id) return
    const res = await this.post(`${folder.id}`, folder, undefined, 'v1', 'templateCategories')
    return res.data.data as BEFolder
  }

  public removeTemplateFolder = async (id: string) => {
    await this.post(id, { status: 'trashed' }, undefined, 'v1', 'templateCategories')
    return
  }
}

export const templatesAPI = new Templates()

export default templatesAPI
