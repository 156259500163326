import React, { FunctionComponent, useCallback } from 'react'
import useStore from '___store'

import { extractValueFromInputEvent } from 'utilities/helpers'
import { Button, Input } from 'components/CasusComponents'
import { classes } from '.'

type UseStoreHookResultType = {
  segmentInfo: Record<string, unknown>
  textMarkerLocationsByParentId: unknown[]
  removeParagraphMarkers: (id: string) => void
  updateParagraphTextChunk: (...params: unknown[]) => void
  concludeConfiguring: () => void
}

type Props = { id: string }
export const ParagraphConfiguration: FunctionComponent<Props> = React.memo(({ id }) => {
  const { segmentInfo, textMarkerLocationsByParentId, removeParagraphMarkers, updateParagraphTextChunk, concludeConfiguring } = useStore(
    `selectSegmentInfo[${id}]`,
    `selectTextMarkerLocationsByParentId[${id}]`,
    'removeParagraphMarkers',
    'updateParagraphTextChunk',
    'concludeConfiguring'
  ) as UseStoreHookResultType

  const inputHandler = useCallback(
    (event, index, chunk) => {
      const value = extractValueFromInputEvent('string', event)
      const resultingChunk = Object.assign({}, chunk, { text: value })
      updateParagraphTextChunk({ id, index, chunk: resultingChunk })
    },
    [updateParagraphTextChunk, id]
  )

  return (
    <div className={classes.wrapper}>
      {textMarkerLocationsByParentId?.length ? (
        <Button onClick={() => removeParagraphMarkers(id)} onClickBlur>
          Remove Markers
        </Button>
      ) : (
        <div className={classes.content}>
          <div className={classes.inputDiv}>
            {
              //@ts-ignore
              segmentInfo?.textChunks?.map((chunk: { customStyle: string; styles: string[]; text: string }, index: number) => {
                return (
                  <Input
                    type="multiline"
                    value={chunk.text}
                    onInput={event => inputHandler(event, index, chunk)}
                    debouncedInput={300}
                    showActionButtons={false}
                  />
                )
              })
            }
          </div>
          <span>
            {
              //@ts-ignore
              segmentInfo?.textChunks?.reduce((res, { text }) => res.concat(text), '')
            }
          </span>
        </div>
      )}
      <div className={classes.footer}>
        <Button onClick={() => concludeConfiguring()} onClickBlur primary>
          Conclude
        </Button>
      </div>
    </div>
  )
})

ParagraphConfiguration.displayName = 'Paragraph-configuration'

export default ParagraphConfiguration
