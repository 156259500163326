import React, { FunctionComponent } from 'react'

import { DOCUMENTS_FILTERS } from '___types'
import { FilteredTemplatesGridProps } from '.'
import TemplatesGrid from './TemplatesGrid'

export const Shared: FunctionComponent<FilteredTemplatesGridProps> = React.memo(({ onClick }) => {
  return <TemplatesGrid filter={DOCUMENTS_FILTERS.SHARED} onClick={onClick} />
})

Shared.displayName = 'Casus-Components-Grid-Templates-Shared'

export default Shared
