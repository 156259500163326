import React, { FunctionComponent, useMemo } from 'react'
import { DateTime } from 'luxon'

import { Plus, DocumentPlaceholder, Public } from 'assets/svgIconComponents'
import { ThumbnailProps, thumbnailClasses as classes } from '.'

export const DocumentThumbnail: FunctionComponent<ThumbnailProps> = React.memo(({ id, selected, onClick, name, edited, isPublic, mutation }) => {
  const documentPreviewImage = useMemo(() => {
    if (!id) return <Plus />
    return <DocumentPlaceholder randomize />
  }, [id])

  return (
    <button
      id={`Casus-Components-DocumentThumbnail-${id}`}
      className={classes.wrapper}
      type="button"
      onClick={event => typeof onClick === 'function' && !mutation && onClick(event, id)}
      data-new={!id ? '' : undefined}
      data-selected={selected ? '' : undefined}
      data-mutating={Boolean(mutation) ? '' : undefined}
      data-mutation={mutation}
    >
      <div className={classes.content.wrapper}>
        {id && isPublic ? <Public /> : null}
        {documentPreviewImage}
        <div className={classes.content.info}>
          <span className={classes.content.name}>{id ? name : 'Generate a new document'}</span>
          <span className={classes.content.time}>{DateTime.fromMillis(edited?._seconds! * 1000).toRelative()}</span>
        </div>
      </div>
    </button>
  )
})

DocumentThumbnail.displayName = 'Casus-Components-DocumentThumbnail'

export default DocumentThumbnail
