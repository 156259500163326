import React, { FunctionComponent, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, { WizardQuestionByIdSelector, NavigateQuestionnaireForwardAction, NavigateQuestionnaireBackwardAction } from '___store'

import { OptionGroup as OptionGroupType, Question as QuestionType, CUSTOM_TEXT_SPLIT, extractPropertiesFromCustomText } from '___types'
import { Caret } from 'assets/svgIconComponents'
import { Button, Link } from 'components/CasusComponents'
import OptionGroup from './OptionGroup'
import { QuestionContextProvider } from '.'
import {
  WizardLayoutLeftPaneDocumentQuestionnaireQuestionProps,
  wizardLayoutLeftPaneDocumentQuestionnaireQuestionClasses as classes,
} from 'Layouts/WizardLayout'

type UseStoreHookResultType = {
  wizardQuestionById: WizardQuestionByIdSelector
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
  navigateQuestionnaireBackward: NavigateQuestionnaireBackwardAction
}

export const Question: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireQuestionProps> = React.memo(
  ({ id, orderString, isFirst, isLast }) => {
    const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.questionnaire' })
    const {
      wizardQuestionById: question,
      navigateQuestionnaireForward,
      navigateQuestionnaireBackward,
    } = useStore(`selectWizardQuestionById[${id}]`, 'navigateQuestionnaireForward', 'navigateQuestionnaireBackward') as UseStoreHookResultType
    // @ts-ignore
    const { text = '', description = '', hint = '', optionGroups = [] } = question || {} // TODO: remove this line, and uncomment the line below, when done with template-automation rework
    // const { text = '', questionDetails: { description = '', example = '', hint = '' } = {} } = question || {}

    const parsedDescription = useMemo(
      () =>
        (description as string).split(CUSTOM_TEXT_SPLIT).reduce((resultingDescription, string, i) => {
          const properties = extractPropertiesFromCustomText(string, 'casusLink')
          if (Object.keys(properties).length)
            return resultingDescription.concat(
              (<Link key={`Question-description-link-${i}`} properties={properties} />) as unknown as HTMLLinkElement
            )
          if (string) return resultingDescription.concat(string)
          return resultingDescription
        }, [] as (string | HTMLLinkElement)[]),
      [description]
    )

    const groups = useMemo(() => {
      if (!optionGroups?.length)
        return (
          <OptionGroup optionGroup={{ options: new Array(3).fill(null) } as OptionGroupType} singleGroup={true} indexOffset={0} firstGroup={false} />
        )
      return (
        optionGroups.reduce(
          ([result, indexOffset], group, i, array) => {
            const { id } = group
            const res = result.concat(
              <OptionGroup key={id} optionGroup={group} singleGroup={array.length === 1} indexOffset={indexOffset} firstGroup={i === 0} />
            )
            return [res, group.options.length]
          },
          [[], 0] as [ReactElement[], number]
        ) as [ReactElement[], number]
      )[0]
    }, [optionGroups])

    return (
      <div className={classes.wrapper} data-order={orderString}>
        <div className={classes.details.wrapper}>
          {text ? <span className={classes.details.text}>{text}</span> : null}
          {parsedDescription.length ? <span className={classes.details.description}>{parsedDescription}</span> : null}
          {hint ? <span className={classes.details.hint}>{hint}</span> : null}
        </div>
        <QuestionContextProvider question={question || ({} as QuestionType)}>
          <div className={classes.optionGroups.wrapper}>{groups}</div>
        </QuestionContextProvider>
        <div className={classes.actions.wrapper}>
          <Button onClick={navigateQuestionnaireBackward}>
            <Caret />
            {isFirst ? translate('configure') : translate('previous')}
          </Button>
          <Button onClick={navigateQuestionnaireForward} tertiary>
            {isLast ? translate('continue') : translate('next')}
            <Caret />
          </Button>
        </div>
      </div>
    )
  }
)

Question.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-Question'

export default Question
