import React, { useState, useEffect } from 'react'
import { CheckboxGroup, CustomButton, FilePicker, Loader } from 'components/common'
import {
  Container,
  Heading,
  Description,
  SubtitleDescription,
  Content,
  UploadOptionsContainer,
  Col,
  Header,
  Footer,
  Img,
  BackBtn,
} from './UploadTemplate.styles'
import {
  btnContent,
  uploadContent as content,
} from 'pages/Authenticated/Template/constants/content'
import upload_svg from './assets/upload_template.svg'
import { useTranslation } from 'react-i18next'
import { AI } from 'assets/svgIconComponents'
import "./style.scss"

interface Props {
  isLoading: boolean
  onCreate: (file: File | null, keepStyles: boolean, isAutoAutomateAI?: boolean) => void
  onBack: () => void
}

const UploadTemplateComponent: React.FC<Props> = ({ isLoading, onCreate, onBack }) => {
  const [file, setFile] = useState<File | null>(null)
  const [isAutoAutomateAI, setIsAutoAutomateAI] = useState(false)
  const [keepStyles, setKeepStyles] = useState(false)
  const { t } = useTranslation()

  let btnText = btnContent.lookup
  if (isLoading) btnText = btnContent.uploading

  const reset = () => {
    setFile(null)
    setKeepStyles(false)
  }

  useEffect(() => {
    if (file) {
      onCreate(file, keepStyles, isAutoAutomateAI)
      reset()
    }
  }, [file, keepStyles, isAutoAutomateAI, onCreate])

  let theContent = null

  if (isLoading) {
    theContent = <Loader />
  } else {
    theContent = (
      <Content>
        <Header>
          <Heading variant='h3'>{t(content.headingTop)}</Heading>
        </Header>
        <UploadOptionsContainer>
          <Col>
            <Img src={upload_svg} alt='Upload document illustration' />
            <Heading variant='h4'>{t(content.headingUpload)}</Heading>
            <Description variant='body1'>{t(content.descriptionUpload)} </Description>
            <FilePicker accept='.doc, .docx' setFile={setFile}>
              <CustomButton
                id='template-creation-upload-btn'
                width={180}
                btntype='primary'
                disabled={isLoading}
                size='medium'
                style={{ flexShrink: 0 }}
              >
                {t(btnText)}
              </CustomButton>
            </FilePicker>
            <CheckboxGroup
              label={<div className="OLD-UploadTemplate-container-wrapper"><AI/> {t('auto_automate_ai')}</div>}
              checked={isAutoAutomateAI}
              handleChange={() => setIsAutoAutomateAI(prev => !prev)}
              style={{ marginBottom: 0, marginTop: 0}}
            />
            <SubtitleDescription>{t(content.subtitle)}</SubtitleDescription>
          </Col>
        </UploadOptionsContainer>
        <Footer>
          <BackBtn onClick={onBack}>{t('return')}</BackBtn>
        </Footer>
      </Content>
    )
  }

  return <Container>{theContent}</Container>
}

export default UploadTemplateComponent
