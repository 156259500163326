import React, { forwardRef, Dispatch, SetStateAction, RefCallback, useState, useCallback, useEffect, useImperativeHandle } from 'react'

import { InputTextProps, classes } from '.'

export const TextInput = React.memo(
  forwardRef<HTMLInputElement, InputTextProps>(
    ({ disabled, defaultValue, value, placeholder, format, autoselect, onInput, onEnter, onEscape }, ref) => {
      const [input, setInput]: [HTMLInputElement | undefined, Dispatch<SetStateAction<HTMLInputElement | undefined>>] = useState()
      const inputRef: RefCallback<HTMLInputElement | undefined> = useCallback(node => node && setInput(node), [])

      const keyDownHandler = useCallback(
        event => {
          if (event.key === 'Enter' && typeof onEnter === 'function' && !event.shiftKey) {
            event.preventDefault()
            event.stopPropagation()
            onEnter(event)
          }
          if (event.key === 'Escape' && typeof onEscape === 'function') {
            event.preventDefault()
            event.stopPropagation()
            if (input && defaultValue) {
              input.value = String(defaultValue)
              event.currentTarget.dispatchEvent(new Event('input', { bubbles: true }))
            }
            onEscape(event)
          }
        },
        [onEnter, onEscape, input, defaultValue]
      )

      useEffect(() => {
        if (input && autoselect) input.focus()
      }, [input, autoselect])

      useImperativeHandle(ref, () => input!)

      return (
        <input
          ref={inputRef}
          className={classes.input}
          type={format ?? 'text'}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={() => {}}
          onInput={onInput}
          onKeyDownCapture={keyDownHandler}
          maxLength={format === 'email' ? 64 : undefined}
          data-validate={format === 'email' ? '' : undefined}
        />
      )
    }
  )
)

export default TextInput
