import React from 'react'
import './styles.scss'

interface SidebarItemProps {
    filter: any
    handleItemClick: () => void
    isCurrent: boolean
}

export const SidebarItem: React.FC<SidebarItemProps> = ({filter, handleItemClick, isCurrent} : SidebarItemProps) => {
  return (
    <div key={filter.id} onClick={handleItemClick} className="Sidebar-Filter-Container">
      <span className={isCurrent ? "Sidebar-Filter-Text-Current" : "Sidebar-Filter-Text"}>{filter.name}</span>
    </div>
  )
}
