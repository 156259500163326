import SecurityLabel from './SecurityLabel'

const classes = {
  container: {
    wrapper: 'SecurityLabel-Container-wrapper',
    title: 'SecurityLabel-Title-wrapper',
    card: {
      wrapper: 'SecurityLabel-Card-wrapper',
      title: 'SecurityLabel-Card-Title-wrapper',
      icon: 'SecurityLabel-Card-Icon-wrapper',
      text: {
        wrapper: 'SecurityLabel-Card-Text-wrapper',
        title: 'SecurityLabel-Card-Text-Title-wrapper',
        description: 'SecurityLabel-Card-Text-Description-wrapper',
      },
    },
  },
}

export { classes }

export default SecurityLabel

