import React, { forwardRef, Dispatch, SetStateAction, RefCallback, useState, useCallback, useEffect, useImperativeHandle } from 'react'

import { InputNumberProps, classes } from '.'

const clampValue = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max)
const applyDatasetOutsideBounds = (input: HTMLDivElement, value: number, min: number, max: number): void => {
  const clampedValue = clampValue(Number(value), Number(min), Number(max))
  const outsideBounds = clampedValue !== Number(value)
  if (outsideBounds) input!.dataset.outsideBounds = `${outsideBounds}`
  else delete input!.dataset.outsideBounds
}

export const NumberInput = React.memo(
  forwardRef<HTMLInputElement, InputNumberProps>(
    ({ disabled, defaultValue, value, min = -Infinity, max = +Infinity, step, autoselect, onInput, onEnter, onEscape }, ref) => {
      const [input, setInput]: [HTMLInputElement | undefined, Dispatch<SetStateAction<HTMLInputElement | undefined>>] = useState()
      const inputRef: RefCallback<HTMLInputElement | undefined> = useCallback(node => node && setInput(node), [])

      const keyDownHandler = useCallback(
        event => {
          if (event.key === 'Enter' && typeof onEnter === 'function' && !event.shiftKey) {
            event.preventDefault()
            event.stopPropagation()
            onEnter(event)
          }
          if (event.key === 'Escape' && typeof onEscape === 'function') {
            event.preventDefault()
            event.stopPropagation()
            if (input && defaultValue) {
              input.value = String(defaultValue)
              event.currentTarget.dispatchEvent(new Event('input', { bubbles: true }))
            }
            onEscape(event)
          }
        },
        [onEnter, onEscape, input, defaultValue]
      )

      useEffect(() => {
        if (input && autoselect) input.focus()
      }, [input, autoselect])

      useImperativeHandle(ref, () => input!)

      return (
        <input
          ref={inputRef}
          className={classes.input}
          type="number"
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          data-outside-bounds={undefined}
          min={(Number(min) || undefined) as unknown as number}
          max={(Number(max) || undefined) as unknown as number}
          step={(Number(step) || undefined) as unknown as number}
          onChange={() => {}}
          onInput={event => {
            applyDatasetOutsideBounds(
              input!.parentElement as HTMLDivElement,
              Number((event.target as HTMLInputElement).value),
              Number(min),
              Number(max)
            )
            onInput(event)
          }}
          onKeyDown={keyDownHandler}
          onBlur={event => {
            const value = event.target.value
            const clampedValue = clampValue(Number(value), Number(min), Number(max))
            const remainder = step ? clampedValue % Number(step) : 0
            input!.value = `${clampedValue - remainder}`
            input!.dispatchEvent(new Event('input', { bubbles: true }))
            window.getSelection()?.removeAllRanges()
          }}
        ></input>
      )
    }
  )
)

export default NumberInput
