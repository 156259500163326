import React, { FunctionComponent, useMemo } from 'react'

import { Cross } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { HeaderProps } from '.'
import { classes } from '..'

export const Header: FunctionComponent<HeaderProps> = React.memo(props => {
  const { render, close, closeCallback, cancelCallback, concludeCallback, context } = props

  const closeButton = useMemo(() => {
    if (close === false) return null
    if (typeof close === 'string')
      return (
        <Button onClick={() => closeCallback()} onClickBlur transparent>
          {close}
        </Button>
      )
    if (typeof close === 'function') return close(closeCallback, cancelCallback, concludeCallback, context)
    return (
      <Button onClick={() => closeCallback()} onClickBlur transparent>
        {close ?? <Cross />}
      </Button>
    )
  }, [close, closeCallback, cancelCallback, concludeCallback, context])

  if (render === false) return null
  return (
    <div className={classes.header}>
      {typeof render === 'string' ? (
        <>
          <span>{render}</span>
          {closeButton}
        </>
      ) : typeof render === 'function' ? (
        render(closeCallback, cancelCallback, concludeCallback, context)
      ) : (
        render ?? closeButton
      )}
    </div>
  )
})

Header.displayName = 'Modal-Header'

export default Header
