import React from 'react'

const DOCXDocument = React.memo(() => {
  return (
    <svg viewBox="0 0 232 151" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2663_11060)">
        <rect width="232" height="151" fill="white" />
        <rect x="16" y="24" width="59" height="6" fill="currentColor" />
        <rect x="16" y="48" width="36" height="6" fill="currentColor" />
        <rect x="16" y="109" width={Math.random() * 200} height="6" fill="currentColor" />
        <rect x="16" y="121" width={Math.random() * 200} height="6" fill="currentColor" />
        <rect x="16" y="134" width={Math.random() * 200} height="6" fill="currentColor" />
        <rect x="16" y="72" width={Math.random() * 200} height="6" fill="currentColor" />
        <rect x="16" y="84" width={Math.random() * 200} height="6" fill="currentColor" />
        <rect x="16" y="97" width={Math.random() * 200} height="6" fill="currentColor" />
        <rect x="-1" y="109" width="229" height="42" fill="url(#paint0_linear_2663_11060)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_2663_11060" x1="113.5" y1="109" x2="113.5" y2="151" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_2663_11060">
          <rect width="232" height="151" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})

export { DOCXDocument }
export default DOCXDocument

