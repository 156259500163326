import SubscriptionAdPanel from './SubscriptionAdPanel'

const classes = {
  wrapper: 'Homepage-dashboard-grid-ad-wrapper',
  title: 'Homepage-dashboard-grid-ad-title',
  description: 'Homepage-dashboard-grid-ad-description',
  image: 'Homepage-dashboard-grid-ad-image',
  button: { wrapper: 'Homepage-dashboard-grid-ad-button-wrapper', item: 'Homepage-dashboard-grid-ad-button-item' },
  subscription: {
    wrapper: 'Homepage-dashboard-ad-subscription-wrapper',
    input: 'Homepage-dashboard-ad-subscription-input',
    price: 'Homepage-dashboard-ad-subscription-price',
  },
}

export { classes }

export default SubscriptionAdPanel

