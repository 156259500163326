import React, { FunctionComponent, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useTemplate } from '___hooks'
import { Bin, Burger, Document, EditField, Premium } from 'assets/svgIconComponents'
import { Button, Dropdown } from 'components/CasusComponents'
import { MainLayoutRightPaneTemplateActionsProps, mainLayoutRightPaneTemplateActionsClasses as classes } from '../../../..'

export const Actions: FunctionComponent<MainLayoutRightPaneTemplateActionsProps> = React.memo(
  ({ id, renameCallback, duplicateCallback, removeCallback }) => {
    const history = useHistory()
    const { use, paywallBlocked } = useTemplate(id)

    const actions = {
      rename: { callback: renameCallback, icon: <EditField />, label: 'Rename template' },
      duplicate: { callback: duplicateCallback, icon: <Document function="duplicate" />, label: 'Duplicate template' },
      remove: { callback: removeCallback, icon: <Bin />, label: 'Delete template' },
    } as const

    const dropdownActions = useMemo(
      () =>
        Object.entries(actions).map(([id, { callback, icon, label }]) => (
          <Button key={`MainLayout-Header-Navigation-Button-${id}`} onClick={callback} onClickBlur noBackgroundSVG noOverlaySVG>
            {label}
            {icon}
          </Button>
        )),
      [actions]
    )

    return (
      <div className={classes.wrapper}>
        <Button
          className={classes.use}
          onClick={() => (paywallBlocked ? null : use())}
          dataSet={{ premium: paywallBlocked ? '' : undefined }}
          primary
        >
          {paywallBlocked ? <Premium /> : null}
          {/* {paywallBlocked ? <Premium /> : <Document />} */}
          {paywallBlocked ? 'Subscribe' : 'Use'}
        </Button>
        <Button className={classes.edit} onClick={() => history.push(`/template/${id}/edit`)}>
          Edit
        </Button>
        <Dropdown hideCaret label={<Burger lines={3} />}>
          {dropdownActions}
        </Dropdown>
      </div>
    )
  }
)

Actions.displayName = 'MainLayout-RightPane-Template-Actions'

export default Actions
