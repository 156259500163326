import React from 'react'
import './style.scss'
import { Signature } from './Signature'

export const Signatures = ({ type, signatureRequests, documentId, storageId }) =>
  Object.entries(signatureRequests).map(([splitId, split]) => (
    <div key={`Signatures-Split-${splitId}`} className={`details-sidebar-signatures ${splitId}`}>
      <span>{split.label}</span>
      {split.requests?.length ? (
        split.requests.map(request => (
          <Signature
            key={`Signatures-Split-${splitId}-request-${request.id}`}
            signatureRequest={request}
            documentId={documentId}
            storageId={storageId}
            type={type}
            splitId={splitId}
          />
        ))
      ) : (
        <Signature signatureRequest={null} documentId={documentId} storageId={storageId} type={type} splitId={splitId} />
      )}
    </div>
  ))

// return signatureRequests && signatureRequests?.length > 0
//   ? signatureRequests.map((signature, index) => {
//       return <Signature key={index} signatureRequest={signature} documentId={documentId} storageId={storageId} type={type} />
//     })
//   : <Signature signatureRequest={null} documentId={documentId} storageId={storageId} type={type} />
