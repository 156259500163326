import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
})

export const PagesContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 6),
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}))

export const ContentContainer = styled(Box)({
  display: 'flex',
  flex: 1,
})

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0.2,
}))

export const PageAction = styled(Typography)(({ theme }) => ({
  color: theme.colorz.primary.main,
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: 0.2,
  transition: 'all .2s',
  cursor: 'pointer',
  marginLeft: 'auto',
  '&:hover': {
    color: theme.colorz.primary.dark,
  },
}))

export const PageAssetsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 56,
  width: '100%',
  marginBottom: theme.spacing(1),
}))
