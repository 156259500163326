import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, EyeProps, className } from './index'

const ZOOM = 1.2

const Eye: FunctionComponent<EyeProps> = React.memo(({ active }) => {
  const [animate, setAnimate]: [SVGAnimateElement | undefined, Dispatch<SetStateAction<SVGAnimateElement | undefined>>] = useState()
  // @ts-ignore
  const [set, setSet]: [SVGSetElement | undefined, Dispatch<SetStateAction<SVGSetElement | undefined>>] = useState()
  const animateRef: RefCallback<SVGAnimateElement | undefined> = useCallback(node => node && setAnimate(node), [])
  // @ts-ignore
  const setRef: RefCallback<SVGSetElement | undefined> = useCallback(node => node && setSet(node), [])

  const maskId = useMemo(uuid, [])
  //@ts-ignore
  const animationId = useMemo(() => uuid().replaceAll('-', ''), [])
  //@ts-ignore
  const setId = useMemo(() => uuid().replaceAll('-', ''), [])

  //@ts-ignore
  const pathSetter1 = useMemo(() => <set attributeName="d" to="M 50 60 L 50 60 M 50 60 L 50 60 M 50 60 L 50 60" begin={`${setId}.begin`} />, [setId])
  //@ts-ignore
  const pathSetter2 = useMemo(() => <set attributeName="d" to="M 38 52 L 25 65 M 50 52 L 50 72 M 62 52 L 75 65" begin={`${setId}.begin`} />, [setId])
  //@ts-ignore
  const maskSetter1 = useMemo(() => <set attributeName="d" to="M 20 52 v -2 C 35 70 65 70 80 50 v 2 Z" begin={`${setId}.begin`} />, [setId])
  //@ts-ignore
  const maskSetter2 = useMemo(() => <set attributeName="r" to="50" begin={`${setId}.begin`} />, [setId])

  const animateElement = useMemo(
    () => (
      <animate
        //@ts-ignore
        ref={animateRef}
        id={animationId}
        attributeName="d"
        to="M 20 50 C 35 30 65 30 80 50"
        dur="150ms"
        fill="freeze"
        begin=""
        restart="whenNotActive"
      />
    ),
    [animateRef, animationId]
  )
  //@ts-ignore
  const setElement = useMemo(() => <set ref={setRef} id={setId} attributeName="d" to="M 20 50 C 35 70 65 70 80 50" />, [setRef, setId])

  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  useEffect(() => {
    let timeout = undefined as unknown as NodeJS.Timeout
    if (active) {
      if (animate) {
        // @ts-ignore
        timeout = setTimeout(() => animate.beginElement(), 100)
      }
      // @ts-ignore
    } else if (set) timeout = setTimeout(() => set.beginElement(), 100)
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [animate, set, active])

  return (
    <svg className={`${className} Eye`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={`${maskId}1`}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <path d="M 20 52 v -2 C 35 70 65 70 80 50 v 2 Z" stroke="none" fill="black">
            <animate attributeName="d" to="M 20 50 C 35 30 65 30 80 50" dur="150ms" begin={`${animationId}.begin`} fill="freeze" />
            {maskSetter1}
          </path>
          <path d="M 20 48 v 2 C 35 70 65 70 80 50 v -2 Z" stroke="none" fill="black" />
        </mask>
        <mask id={`${maskId}2`}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="black" />
          <path d="M 20 52 v -2 C 35 70 65 70 80 50 v 2 Z" stroke="none" fill="white">
            <animate attributeName="d" to="M 20 50 C 35 30 65 30 80 50" dur="150ms" begin={`${animationId}.begin`} fill="freeze" />
            {maskSetter1}
          </path>
          <path d="M 20 48 v 2 C 35 70 65 70 80 50 v -2 Z" stroke="none" fill="white" />
          <circle r="50" cx="50" cy="50" stroke="none" fill="black">
            <animate attributeName="r" to="12" dur="100ms" begin={`${animationId}.begin+50ms`} fill="freeze" />
            {maskSetter2}
          </circle>
        </mask>
      </defs>
      <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="currentColor" mask={`url(#${maskId}2`} />
      <path d="M 20 50 C 35 70 65 70 80 50" stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" fill="none"></path>
      <path d="M 20 50 C 35 70 65 70 80 50" stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" fill="none">
        {animateElement}
        {setElement}
      </path>
      <path
        d="M 50 60 L 50 60 M 50 60 L 50 60 M 50 60 L 50 60"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        fill="none"
        mask={`url(#${maskId}1`}
      >
        <animate
          attributeName="d"
          to="M 50 60 L 25 35 M 50 60 L 50 28 M 50 60 L 75 35"
          dur="150ms"
          begin={`${animationId}.begin+50ms`}
          fill="freeze"
        />
        {pathSetter1}
      </path>
      <path
        d="M 38 52 L 25 65 M 50 52 L 50 72 M 62 52 L 75 65"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        fill="none"
        mask={`url(#${maskId}1`}
      >
        <animate
          attributeName="d"
          to="M 50 40 L 50 40 M 50 40 L 50 40 M 50 40 L 50 40"
          dur="100ms"
          begin={`${animationId}.begin+50ms`}
          fill="freeze"
        />
        {pathSetter2}
      </path>
    </svg>
  )
})

export { Eye }
export default Eye
