import React from 'react'
import { classes } from '.'

import './style.scss'

const MessageLabel = ({ message }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Message</div>
      <div className={classes.label}>{message}</div>
    </div>
  )
}

export default MessageLabel

