import React from 'react'
import { Container, Text, TextSmall } from './ErrorPage.styles'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/CasusComponents'
import { useHistory } from 'react-router-dom'

const ErrorPage: React.FC = () => {

  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Container>
      <Text>{`${t('error_go_back')}`}</Text>
      <TextSmall>{`${t('error_contact_us')}`}</TextSmall>
      <Button className={'idk'} onClick={() => {
          history.push('/')
      }}>{`${t('go_back')}`}</Button>
    </Container>
  )
}

export default ErrorPage
