import React from 'react'

import { classes } from '.'
import useStore from '___store'

const Popup = React.memo(() => {
  const { popup } = useStore('selectPopup')

  return (
    <div className={classes.wrapper} data-active={Boolean(popup)}>
      <div className={classes.header.wrapper}></div>
      <div className={classes.content}></div>
      <div className={classes.footer.wrapper}></div>
    </div>
  )
})

Popup.displayName = 'Wizard-Popup'

export default Popup
