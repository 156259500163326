import Button from './Button'
import ButtonGroup from './ButtonGroup'

import './style.scss'

const classes = {
  wrapper: 'ButtonGroup-wrapper',
  Button: {
    wrapper: 'ButtonGroup-Button-wrapper',
    span: 'ButtonGroup-Button-span',
  },
}

export { Button, ButtonGroup, classes }
export default Button
