import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, className } from './index'

const Split: FunctionComponent = React.memo(() => {
  const maskId = useMemo(uuid, [])

  const rectHeight = 30
  const rectWidth = 60
  const rectX = (VIEWPORT_SIZE - rectWidth) / 2
  const splitGap = 30
  const topRectY = (VIEWPORT_SIZE - splitGap) / 2 - rectHeight
  const bottomRectY = (VIEWPORT_SIZE + splitGap) / 2
  const radius = 5
  // const perimeter = (rectWidth + rectHeight) * 2 - 4 * 2 * radius + 2 * radius * Math.PI
  return (
    <svg
      className={`${className} Split`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${VIEWPORT_SIZE} ${VIEWPORT_SIZE}`}
      preserveAspectRatio="none"
    >
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="black" />
          <rect x="0" y="20" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE - 40}`} stroke="none" fill="white" />
        </mask>
      </defs>
      <rect
        x={rectX}
        y={topRectY}
        rx={radius}
        ry={radius}
        width={rectWidth}
        height={rectHeight}
        stroke="currentColor"
        strokeWidth="3"
        fill="none"
        strokeLinecap="round"
        // strokeDasharray={`${perimeter / 2} ${perimeter / 2}`}
        // strokeDashoffset={`-${rectWidth - 2 * radius + (rectHeight - 2 * radius) / 2 + (radius / 2) * Math.PI}`}
        mask={`url(#${maskId}`}
      />
      <rect
        x={rectX}
        y={bottomRectY}
        rx={radius}
        ry={radius}
        width={rectWidth}
        height={rectHeight}
        stroke="currentColor"
        strokeWidth="3"
        fill="none"
        strokeLinecap="round"
        // strokeDasharray={`${perimeter / 2} ${perimeter / 2}`}
        // strokeDashoffset={`${(radius / 2) * Math.PI + (rectHeight - 2 * radius) / 2}`}
        mask={`url(#${maskId}`}
      />
      <path d="M 10 50 h 80" stroke="currentColor" strokeWidth="5" fill="none" strokeLinecap="round" strokeDasharray="8 10" />
      {/* <path
        d="M 25 15 v 25 h 50 v -25 M 25 85 v -25 h 50 v 25 M 10 50 h 80"
        stroke="currentColor"
        strokeWidth="5"
        fill="none"
        strokeLinecap="round"
      /> */}
    </svg>
  )
})

export { Split }
export default Split
