import React, { FunctionComponent, useMemo } from 'react'

import { VIEWPORT_SIZE, RadioButtonProps, className } from './index'

const ZOOM = 1

const RadioButton: FunctionComponent<RadioButtonProps> = React.memo(({ active = false, strokeWidth = 10 }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <circle cx="50" cy="50" r="35" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" fill="none" />
      <circle cx="50" cy="50" r="20" fill={active ? 'currentColor' : 'none'} />
    </svg>
  )
})

export { RadioButton }
export default RadioButton
