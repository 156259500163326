import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { Button } from 'components/CasusComponents'
import {
  MainLayoutRightPaneDocumentSignatureModalContentProps,
  mainLayoutRightPaneDocumentSignatureModalContentClasses as classes,
} from 'Layouts/___MainLayout'
import { Check } from 'assets/svgIconComponents'
import { useDocument } from '___hooks'

export const ModalContent: FunctionComponent<MainLayoutRightPaneDocumentSignatureModalContentProps> = React.memo(({ id, requestId }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.document' })

  const { data: document, sendSignatureReminder, sendingSignatureReminder } = useDocument(id)

  const request = useMemo(() => document?.signatureRequest?.find(request => request.id === requestId), [document, requestId])
  const loading = useMemo(
    () =>
      Object.entries(sendingSignatureReminder).reduce((result, [key, mutating]) => {
        const match = key.match(':')
        const id = key.slice(0, match?.index || 0)
        const email = key.slice((match?.index || 0) + 1)
        return result.concat(mutating && id === requestId ? email : [])
      }, [] as string[]) || [],
    [sendingSignatureReminder, requestId]
  )

  return (
    <div className={classes.wrapper}>
      {request?.signatures.map(signature => {
        const isLoading = loading.includes(signature.signer_identity_data.email_address)
        return (
          <div className={classes.signature} data-status={signature.status_code.toLowerCase()}>
            <span>
              <span>{`${[signature.signer_identity_data.first_name, signature.signer_identity_data.last_name].filter(s => s).join(' ')}`}</span>
              <span>{signature.signer_identity_data.email_address}</span>
            </span>
            {signature.status_code === 'OPEN' ? (
              <Button
                onClick={() => sendSignatureReminder(request.id, signature.signer_identity_data.email_address)}
                disabled={isLoading}
                loading={isLoading}
              >
                {isLoading ? translate('sending') : translate('send-reminder')}
              </Button>
            ) : (
              <span>
                <span>
                  <Check />
                  {translate('signed')}
                </span>
                <span>{DateTime.fromISO(signature.signed_at).toRelative()}</span>
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
})

ModalContent.displayName = 'MainLayout-RightPane-Document-Signature-ModalContent'

export default ModalContent
