import React, { FunctionComponent } from 'react'
// import { useTranslation } from 'react-i18next'

import { mainLayoutRightPaneDocumentProgressDraftClasses as classes } from '../../../../..'

export const Legend: FunctionComponent = React.memo(() => {
  // const { t: translate } = useTranslation('translation', { keyPrefix: 'general' })

  return (
    <div className={classes.legend}>
      <span>
        <span>&#9679;</span>
        &nbsp;&nbsp;Public Answered
      </span>
      <span>
        <span>&#9679;</span>
        &nbsp;&nbsp;Public Remaining
      </span>
      <span>
        <span>&#9679;</span>
        &nbsp;&nbsp;Private Answered
      </span>
      <span>
        <span>&#9679;</span>
        &nbsp;&nbsp;Private Remaining
      </span>
    </div>
  )
})

Legend.displayName = 'MainLayout-RightPane-Document-Progress-Draft-Legend'

export default Legend
