import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import ViewListIcon from '@material-ui/icons/ViewList'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import {
  Download,
  Preview,
} from 'components/common/Icons/Icons.styles'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useUser } from 'queries/user/useUser'
import { useDocumentsUpdate } from 'queries/documents'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import Container from 'components/files/components/Container/Container'
import DocumentWithMenu from 'components/files/File/FileWithMenu'
import LoadMoreButton from 'components/files/components/LoadMoreButton/LoadMoreButton'
import NoDocs from 'components/common/NoItemsAction/NoItemsAction'
import doc_png from 'assets/illustrations/aktenschrank.svg'
import TrashEmpty from 'components/files/components/TrashEmpty/TrashEmpty'
import { isEditorIsOwner } from 'utils'
import moment from 'moment'
import dummyPreview from '../../../../../assets/icons/dummyPreview.svg'
import loaderAnimation from '../../../../../assets/icons/loader.svg'

import TableContainer from 'components/files/components/TableContainer/TableContainer'
import { Loader, MenuPopover } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useLoadSignatureDocuments } from 'queries/documents/useLoadSignatureDocuments'
import { useDetails } from 'components/DetailsSidebar/provider/DetailsProvider'

interface IProps {
  filter: string
}

const SignatureList: React.FC<IProps> = ({filter}) => {
  const [view, setView] = useState('card')
  // const queryClient = useQueryClient()
  const { currentFolder } = useCurrentFolder('docs')
  const { type, children } = currentFolder

  const { fetchNextPage, refetch, data, hasNextPage, isLoading, isFetchingNextPage,  isIdle } =
    useLoadSignatureDocuments(filter, {enabled: false})

  const {select, selectedId} = useDetails()

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const update = useDocumentsUpdate()
  const user = useUser()!
  // const superadmins = useMasterData()?.superadmins ?? []
  const { onOpenSigned, onDownload, onDownloadSigned } = useDocumentFileActions()
  const { push } = useHistory()
  const { check } = useSubscriptionCheck()
  const [renameId, setRenameId] = useState<null | string>(null)
  const { t } = useTranslation()

  const noDocsProps = {
    text: t('no_documents'),
    actionText: t('new_document'),
    heading: t('u_documents'),
    actionBtn: t('new_document'),
    image: doc_png,
  }

  useEffect(() => {
    if(isIdle){
      refetch()
    }
  }, [filter, isIdle, refetch])

  // const isSuperadmin = superadmins.includes(user.email)

  const documentFiles = data?.pages.flat(1) ?? []

  const getTypeIcon = useCallback((doc: any) => {
    if (doc.isLoading) {
      return (
        <div>
          <img src={loaderAnimation} alt="" className="documentRow-fileType" />
        </div>
      )
    } else {
      return (
        <div>
          <img src={dummyPreview} alt="" className="documentRow-fileType" />
        </div>
      )
    }
  }, [])

  const generateActions = useCallback(
    (doc: any) => {
      return (
        <div key={doc.id} className="documentRow-actionsWrapper">
          <button
            type="button"
            className="documentRow-actionButton"
            onClick={() => doc.signatureStatus === 'SIGNED' ? onDownloadSigned(doc.id, "0") : onDownload(doc.id, 'pdf')}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') doc.signatureStatus === 'SIGNED' ? onDownloadSigned(doc.id, "0") : onDownload(doc.id, 'pdf')
            }}
          >
            <Download />
          </button>
          <button
            type="button"
            className="documentRow-actionButton"
            disabled={!!doc.signatureRequest}
            onClick={() => onOpenSigned(doc.id)}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') onOpenSigned(doc.id)
            }}
          >
            <Preview />
          </button>
        </div>
      )
    },
    [onDownload, onDownloadSigned, onOpenSigned]
  )

  const render = useMemo(() => {
    switch (view) {
      case 'card':
        return (
          <Container>
            {documentFiles?.map((docItem) => {
              const { id, authorId, sharedWith } = docItem

              const { isOwner } = isEditorIsOwner(
                user?.id,
                authorId,
                sharedWith
              )

              const isShared =
                docItem.sharedWith?.some((sh) => sh.id === user?.id) ||
                (isOwner && !!docItem.sharedWith.length)

              const updateName = (docId: string = id, newName: string) => {
                setRenameId(null)
                if (newName !== docItem.name)
                  update.mutate({ id: docId, data: { name: newName } })
              }

              const menuItems: IPopupMenuItem[] = []

              if (type !== 'trashed') {
                menuItems.push({
                  icon: 'preview',
                  label: t('preview'),
                  handleClick: () => onOpenSigned(id),
                })
              }
      
              menuItems.push({
                icon: 'download',
                label: t('download'),
                handleClick: () => {},
                subItems: [
                  {
                    icon: 'pdf',
                    label: 'PDF',
                    handleClick: () => docItem.signatureStatus === 'SIGNED' ? onDownloadSigned(id, "0") : onDownload(id, 'pdf'),
                  },
                ],
              })

              return (
                <DocumentWithMenu
                  key={id}
                  document={docItem}
                  menuItems={menuItems}
                  renameActive={renameId === id}
                  isShared={
                    !!isShared ||
                    !!(isOwner && sharedWith.length && type === 'my')
                  }
                  currentFolderType={type}
                  handleLeftClick={() => select(id)}
                  handleDoubleClick={() => onOpenSigned(id)}
                  handleUpdateName={updateName}
                  isDownloadable={true}
                  isLoading={docItem.isLoading}
                  showSignatureStatus
                  isSelected={selectedId === id}
                />
              )
            })}
          </Container>
        )
      case 'table': {
        const createColumn = (
          id?: any,
          label?: any,
          minWidth?: any,
          align?: any
        ) => ({ id, label, minWidth, align })
        const columns = [
          createColumn('type', 'Type', 30, 'left'),
          createColumn('name', 'Name', 250, 'left'),
          createColumn('template', 'Template', 250, 'left'),
          createColumn('modified', 'Last modified', 150, 'right'),
          createColumn('actions', 'Actions', 200, 'right'),
        ]
        const createRow = (
          id: String,
          name?: any,
          template?: any,
          modified?: any,
          actions?: any,
          type?: any
        ) => ({ type, id, name, template, modified, actions })
        const rows = documentFiles.map((d) => {
          return createRow(
            d.id,
            d.name,
            d.templateName,
            moment.unix(d.edited?._seconds).fromNow(),
            generateActions(d),
            getTypeIcon(d)
          )
        })
        return (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((c) => (
                    <TableCell
                      key={c.id}
                      align={c.align}
                      style={{ minWidth: c.minWidth }}
                    >
                      {c.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((r: any) => {
                  return (
                    <TableRow key={r.id}>
                      {columns.map((c) => {
                        const key = String(c.id)
                        const value = r[key]

                        return (
                          <TableCell
                            key={`${r.id}-${c.id}`}
                            align={c.align}
                            style={{ minWidth: c.minWidth }}
                          >
                            {value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
                <MenuPopover
                  anchorOrigin={{
                    vertical: 20,
                    horizontal: 'left',
                  }}
                  handleClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                />
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
      default:
        return null
    }
  }, [
    view,
    select,
    selectedId,
    generateActions,
    documentFiles,
    onDownload,
    onDownloadSigned,
    onOpenSigned,
    renameId,
    type,
    update,
    user,
    getTypeIcon,
    anchorEl,
    t
  ])

  if (type === 'trashed' && !documentFiles.length && !children.length) {
    return <TrashEmpty />
  }

  if (!documentFiles.length && !children.length) {
    return (
      <NoDocs
        {...noDocsProps}
        handleActionClick={
          type === 'my'
            ? () => {
                if (check()) push(`document/new`)
              }
            : undefined
        }
      />
    )
  }

  // const onDuplicate = async (id: string) => {
  //   await DocsApi.duplicate(id)
  //   queryClient.invalidateQueries([
  //     queryKeys.DOCUMENTS,
  //     {type, folderId: currentFolder.id },
  //   ])
  // }

  return (
    <>
      <div className="view-buttons-wrapper">
        <button
          className={(view === 'card' && 'active') || ''}
          type="button"
          onClick={() => setView('card')}
          onKeyDown={(event) => event.key === 'ENTER' && setView('card')}
        >
          <InsertDriveFileIcon />
        </button>
        <button
          className={(view === 'table' && 'active') || ''}
          type="button"
          onClick={() => setView('table')}
          onKeyDown={(event) => event.key === 'ENTER' && setView('table')}
        >
          <ViewListIcon />
        </button>
      </div>
      {isLoading ?  <Loader />: render}
      {hasNextPage && (
        <LoadMoreButton
          text={t('show_more')}
          isLoading={isLoading || isFetchingNextPage}
          onClick={fetchNextPage}
        />
      )}
    </>
  )
}

export default SignatureList
