import { FocusEventHandler } from 'react'

import CheckBox from './CheckBox'

import './style.scss'

const classes = { wrapper: 'Casus-Components-CheckBox-wrapper' } as const

export type CheckBoxProps = {
  className?: string
  value?: boolean
  defaultValue?: boolean
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  disabled?: boolean
  dataSet?: Record<keyof any, unknown>
  tabbable?: boolean
  title?: string
  label?: string | JSX.Element
  onClickBlur?: boolean
  onChange?: (state: boolean) => void
  onFocus?: FocusEventHandler<HTMLButtonElement>
}

export { CheckBox, classes }
export default CheckBox
