import React from 'react'

import { className } from './index'

const Warning = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <line x1="50" y1="20" x2="50" y2="55" stroke="currentColor" strokeWidth="12" strokeLinecap="round" />
    <circle cx="50" cy="75" r="8" fill="currentColor" />
  </svg>
))

export { Warning }
export default Warning
