import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useDocument } from '___hooks'
import { Copy } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { DocumentPublishing } from 'components/CasusComponents/Publishing'
import { MainLayoutRightPaneDocumentPublishingProps, mainLayoutRightPaneDocumentPublishingClasses as classes } from 'Layouts/___MainLayout'

export const Publishing: FunctionComponent<MainLayoutRightPaneDocumentPublishingProps> = React.memo(({ id }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.document' })
  const { data: { isPublic, templateId } = {}, publishing } = useDocument(id)

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <span>{translate(isPublic ? 'published' : 'publishing')}</span>
        {isPublic ? (
          <Button
            onClick={() => navigator.clipboard.writeText(`${document.location.origin}/public/${templateId}/${id}?step=configure`)}
            onClickBlur
            disabled={publishing}
          >
            {translate('copy-link')}
            <Copy type="link" />
          </Button>
        ) : null}
        <DocumentPublishing id={id} />
      </div>
    </div>
  )
})

Publishing.displayName = 'MainLayout-RightPane-Document-Publishing'

export default Publishing
