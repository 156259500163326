import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { ITemplate } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type DuplicateTemplateVariables = string
export type DuplicateTemplateContext = { mutationId: string }
export type DuplicateTemplateMutationOptions = MutateOptions<ITemplate | void, unknown, DuplicateTemplateVariables, DuplicateTemplateContext>
export type DuplicateTemplateFunctionType = (options?: DuplicateTemplateMutationOptions) => void
// const duplicateTemplateMutationFunction = (variables: DuplicateTemplateVariables) => templatesAPI.duplicateTemplate(variables)

export const useDuplicateTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()
  const mutationId = useMemo(uuid, [])

  const updateListing = (method: (data: ITemplate[] | undefined) => ITemplate[]) => queryClient.setQueryData([QUERY_KEYS.TEMPLATES], method)

  const onMutate = (variables: DuplicateTemplateVariables) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate
    const moment = { _seconds: Math.floor(Date.now() / 1000) }
    const optimisticPayload = { id: mutationId, created: moment, edited: moment, mutating: true, mutation: 'create' }
    const optimisticTemplate = Object.assign(JSON.parse(JSON.stringify(currentTemplate || {})), optimisticPayload)
    queryClient.setQueryData([QUERY_KEYS.TEMPLATE, mutationId], optimisticTemplate)
    updateListing(data => [optimisticTemplate].concat(data || []))
    return { mutationId }
  }

  const onError = (error: unknown, variables: DuplicateTemplateVariables, context: DuplicateTemplateContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
    updateListing((data: ITemplate[] | undefined) => data?.filter(({ id }) => id !== context?.mutationId) || [])
  }

  const onSuccess = (template: ITemplate | void, variables: DuplicateTemplateVariables, context: DuplicateTemplateContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
    // @ts-ignore
    queryClient.setQueryData([QUERY_KEYS.TEMPLATE, template?.id], template)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === context?.mutationId, template))
  }

  const templateDuplicateMutation = useMutation<ITemplate | void, unknown, DuplicateTemplateVariables, DuplicateTemplateContext>(
    [QUERY_KEYS.TEMPLATE, mutationId],
    templatesAPI.duplicateTemplate,
    { onMutate, onError, onSuccess }
  )

  const duplicateMutationFunction: DuplicateTemplateFunctionType = options => templateDuplicateMutation.mutate(id!, options)

  return { duplicate: duplicateMutationFunction, duplicating: templateDuplicateMutation.isLoading }
}

export default useDuplicateTemplate
