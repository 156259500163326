import React from 'react'
import { classes } from '.'

import './style.scss'
import { Icon } from 'components/common'

const SecurityItem = ({ title, description, icon, isSelected, value, handleChange, countryLaw, handleCountryLawChange }) => {
  return (
    <label>
      <div className={classes.container.item.wrapper}>
        <div className={classes.container.item.icon.wrapper}>
          <div className={classes.container.item.icon.svg}>
            <Icon iconName={icon} />
          </div>
        </div>
        <div className={classes.container.item.content.wrapper}>
          <div className={classes.container.item.content.title}>{title}</div>
          <div className={classes.container.item.content.description}>{description}</div>
          {isSelected && countryLaw ? (
            <div>
              <label>
                <div className={classes.container.item.wrapper}>
                  <div className={classes.container.item.icon.wrapper}>
                    <div className={classes.container.item.icon.svg}>
                      <Icon iconName="switzerland" style={{ width: '24px', height: '24px' }} />
                    </div>
                  </div>
                  <div className={classes.container.item.content.wrapper}>
                    <div className={classes.container.item.content.title}>Switzerland</div>
                  </div>
                  <input type="radio" name="countryLaw" value="ZERTES" onChange={handleCountryLawChange} checked={countryLaw === "ZERTES"} />
                </div>
              </label>
              <label>
                <div className={classes.container.item.wrapper}>
                  <div className={classes.container.item.icon.wrapper}>
                    <div className={classes.container.item.icon.svg}>
                      <Icon iconName="europe" style={{ width: '36px', height: '24px' }} />
                    </div>
                  </div>
                  <div className={classes.container.item.content.wrapper}>
                    <div className={classes.container.item.content.title}>Europe</div>
                  </div>
                  <input type="radio" name="countryLaw" value="EIDAS" onChange={handleCountryLawChange} checked={countryLaw === "EIDAS"} />
                </div>
              </label>
            </div>
          ) : null}
        </div>
        <input id={value} type="radio" name="security" value={value} onChange={handleChange} checked={isSelected} />
      </div>
    </label>
  )
}

const SecurityPicker = ({ handleSignatureLevelChange, handleCountryLawChange, countryLaw, signatureLevel }) => {
  return (
    <div className={classes.container.wrapper}>
      <p className={classes.container.title}>Choose signature security</p>
      <SecurityItem
        title="Basic Security Signature"
        description="Simple electronic signature (SES), for documents without legal form requirements and with low liability risk."
        icon="ses"
        isSelected={signatureLevel === 'SES'}
        value="SES"
        handleChange={handleSignatureLevelChange}
      />
      <SecurityItem
        title="High Security Signature"
        description="Advanced electronic signature (AES), for documents without legal form requirements and with calculable liability risk."
        icon="aes"
        isSelected={signatureLevel === 'AES'}
        value="AES"
        handleChange={handleSignatureLevelChange}
      />
      <SecurityItem
        title="Maximum Security Signature"
        description="Qualified electronic signature (QES), for documents with legal form requirements or high liability risk."
        icon="qes"
        isSelected={signatureLevel === 'QES'}
        value="QES"
        handleChange={handleSignatureLevelChange}
        countryLaw={countryLaw}
        handleCountryLawChange={handleCountryLawChange}
      />
    </div>
  )
}

export default SecurityPicker

