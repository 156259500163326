import React, { FunctionComponent, useCallback, useState } from 'react'

import { Button, Input } from 'components/CasusComponents'
import { TemplatePublishingCustomLastStepProps, TemplatePublishingCustomLastStepClasses as classes } from '.'

export const CustomLastStep: FunctionComponent<TemplatePublishingCustomLastStepProps> = React.memo(({ context, conclude }) => {
  const [messageInput, setMessageInput] = useState<HTMLInputElement>()
  const [ctaLabelInput, setCtaLabelInput] = useState<HTMLInputElement>()
  const [ctaLinkInput, setCtaLinkInput] = useState<HTMLInputElement>()

  const messageInputRef = useCallback(node => node && setMessageInput(node), [])
  const ctaLabelInputRef = useCallback(node => node && setCtaLabelInput(node), [])
  const ctaLinkInputRef = useCallback(node => node && setCtaLinkInput(node), [])

  return (
    <div className={classes.wrapper}>
      <Input ref={messageInputRef} type="multiline" defaultValue={(context?.message as string) ?? ''} placeholder="Write a message for the users." />
      <Input
        ref={ctaLabelInputRef}
        className={classes.callToAction}
        type="text"
        defaultValue={(context?.ctaLabel as string) ?? ''}
        placeholder="Call to action!"
      />
      <Input
        ref={ctaLinkInputRef}
        type="text"
        defaultValue={(context?.ctaLink as string) ?? ''}
        placeholder="Write a link for the call to action..."
      />
      <Button onClick={() => conclude(messageInput, ctaLabelInput, ctaLinkInput)} emphasized>
        Save
      </Button>
    </div>
  )
})

CustomLastStep.displayName = 'Casus-Components-Publishing-Template-CustomLastStep'

export default CustomLastStep
