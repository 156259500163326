import React from 'react'
import Popover from '@material-ui/core/Popover'

import './style.scss'
import { classes } from '.'
import Icon from '../Icons/Icon'

const ModalPopover = ({ children, anchorEl, anchorOrigin, transformOrigin, anchorPosition, close }) => {
  const open = Boolean(anchorEl)
  return (
    <Popover
      style={{ overflow: "hidden" }}
      anchorReference={anchorPosition && 'anchorPosition'}
      open={open}
      anchorEl={anchorEl}
      onClose={close}
      anchorPosition={anchorPosition && anchorPosition}
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : {
              vertical: 100,
              horizontal: 'right',
            }
      }
      transformOrigin={
        transformOrigin
          ? transformOrigin
          : {
              vertical: 'bottom',
              horizontal: 'left',
            }
      }
      elevation={20}
    >
      <div className={classes.container.wrapper}>
        <div className={classes.container.exit} onClick={close}><Icon iconName='close'/></div>
        {children}
      </div>
    </Popover>
  )
}

export default ModalPopover

