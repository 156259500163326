import ShowHideRule from './ShowHideRule'

import './style.scss'

const classes = {
  showHideRuleWrapper: 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment-Rule-wrapper',
  showHideRuleRow: 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment-Rule-row',
  showHideRuleButtonGroup: 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment-Rule-buttonGroup',
  showHideRuleButton: 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment-Rule-button',
  showHideRuleSelect: 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment-Rule-select',
}

export { ShowHideRule, classes }
export default ShowHideRule
