import React from 'react'

import { className } from './index'

const Info = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <circle cx="50" cy="50" r="35" stroke="currentColor" strokeWidth="6" fill="none" />
    <line x1="50" y1="45" x2="50" y2="70" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
    <circle cx="50" cy="30" r="6" fill="currentColor" />
  </svg>
))

export { Info }
export default Info
