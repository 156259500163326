import React, { FunctionComponent, RefCallback, useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { extractValueFromObject } from 'utilities/helpers'
import { RowProps, rowClasses as classes } from '.'

export const Row: FunctionComponent<RowProps> = ({ tableId, datum, columns, dataSetKeys }) => {
  const [row, setRow] = useState<HTMLDivElement>()
  const rowRef: RefCallback<HTMLDivElement> = useCallback(node => node && setRow(node), [])

  const rowId = useMemo(uuid, [])

  const dataSet = useMemo(
    () => (dataSetKeys || []).reduce((result, key) => Object.assign(result, { [key]: extractValueFromObject(datum, key) }), {}),
    [dataSetKeys, datum]
  )

  useEffect(() => {
    if (row) {
      Object.keys(dataSet).forEach(key => delete row.dataset[key])
      Object.entries(dataSet).forEach(([key, value]) => {
        delete row.dataset[key]
        if (value === undefined) return
        if (typeof value === 'boolean') return (row.dataset[key] = value ? '' : undefined)
        row.dataset[key] = String(value)
      })
    }
  }, [row, dataSet, datum])

  return (
    <div ref={rowRef} className={classes.wrapper}>
      {columns.map(column => {
        const value = extractValueFromObject(datum, column.key)
        return (
          <div
            key={`Casus-Components-Table-${tableId}-row-${rowId}-column-${column.key}`}
            className={[classes.cell, column.className].filter(s => s).join(' ')}
          >
            {column.render ? column.render(value, datum, dataSet) : String(value)}
          </div>
        )
      })}
    </div>
  )
}

Row.displayName = 'Casus-Components-Table-Row'

export default Row
