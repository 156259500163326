import React, { useMemo } from 'react'
import useStore from '___store'

import { CASUS_CLASSES } from 'Wizard/constants'
import { SegmentsMarker } from './Markers'
import Segment from './Segment'
import { classes } from '../index'
import { mapContentIds } from '.'

const Page = React.memo(({ section, index, headerFooterId }) => {
  const { sectionPageContentIds, headerFooterContentIds } = useStore(
    `selectSectionPageContentIds[${section},${index}]`,
    `selectHeaderFooterContentIds[${headerFooterId}]`
  )

  const contentIds = useMemo(
    () => (headerFooterContentIds || sectionPageContentIds || '').split('; ').filter(id => id),
    [headerFooterContentIds, sectionPageContentIds]
  )
  const markerParent = headerFooterContentIds ? `headerFooter-${headerFooterId}` : `root-${section}-${index}`
  const segments = useMemo(
    () =>
      mapContentIds(contentIds).map(({ type, id }) =>
        type === 'segment' ? <Segment key={id} id={id} /> : <SegmentsMarker key={id} id={id} parent={markerParent} />
      ),
    [contentIds, markerParent]
  )

  // console.groupCollapsed(`PAGE %c${section}/${index}`, 'color: lightgreen;', 'RENDER!')
  // console.log(contentIds)
  // console.groupEnd()

  const className = [CASUS_CLASSES.pageContentRoot, classes.page.wrapper].join(' ')

  return (
    <div className={className} data-collapsed={headerFooterId && true}>
      {segments}
    </div>
  )
})

Page.displayName = 'Wizard-Editor-Page'

export default Page
