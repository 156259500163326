import {
  CreateDocumentFolderFunctionType,
  CreateDocumentFolderMutationOptions,
  RemoveDocumentFolderFunctionType,
  UpdateDocumentFolderFunctionType,
  useCreateDocumentFolder,
  useRemoveDocumentFolder,
  useUpdateDocumentFolder,
} from '___queries'

export type UseDocumentFolderHookUnknownIdReturnType = {
  create: CreateDocumentFolderFunctionType
  remove?: RemoveDocumentFolderFunctionType
  update?: UpdateDocumentFolderFunctionType
  creating: boolean
  removing?: boolean
  updating?: boolean
}

export type CreateFolderWithinFunctionType = (name: string, options?: CreateDocumentFolderMutationOptions) => void

// ==================== USE DOCUMENT FOLDER OVERLOAD ==================== //
function useDocumentFolder(id?: null): { create: CreateDocumentFolderFunctionType; creating: boolean }
function useDocumentFolder(id: string): {
  create: CreateFolderWithinFunctionType
  remove: RemoveDocumentFolderFunctionType
  update: UpdateDocumentFolderFunctionType
  creating: boolean
  removing: boolean
  updating: boolean
}
// ====================================================================== //
function useDocumentFolder(id?: string | null) {
  const { createDocumentFolder: create, creatingDocumentFolder: creating } = useCreateDocumentFolder()
  const { removeDocumentFolder: remove, removingDocumentFolder: removing } = useRemoveDocumentFolder(id)
  const { updateDocumentFolder: update, updatingDocumentFolder: updating } = useUpdateDocumentFolder(id)

  const createFolderWithin: CreateFolderWithinFunctionType = (name, options) => create(name, id!, options)

  return id ? { create: createFolderWithin, remove, update, creating, removing, updating } : { create, creating }
}

export default useDocumentFolder
