import React from 'react'
import { Form, Column, labelStyles, ItemTitle } from './ChangePassword.styles'
import { CustomButton, TextInputGroup } from 'components/common'
import { TUpdatePasswordState } from './ChangePassword.container'
import { useTranslation } from 'react-i18next'

type ChangePasswordFields = 'passwordCurrent' | 'passwordNew' | 'passwordNewConfirm'

interface ChangePasswordField {
  name: ChangePasswordFields
  label: string
}

interface Props {
  values: TUpdatePasswordState
  isLoading: boolean
  onSubmit: React.FormEventHandler<HTMLFormElement>
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const ChangePasswordComponent: React.FC<Props> = ({
  onChange,
  onSubmit,
  values,
  isLoading,
}) => {
  const {t} = useTranslation()

  const fields: ChangePasswordField[] = [
    { name: 'passwordCurrent', label: t('current_password') },
    { name: 'passwordNew', label: t('new_password') },
    { name: 'passwordNewConfirm', label: t('new_password_confirmation') },
  ]
  return (
    <Column>
      <Form onSubmit={onSubmit}>
        <ItemTitle>{t('change_password')}</ItemTitle>
        {fields.map(({ name, label }) => (
          <TextInputGroup
            type='password'
            key={name}
            name={name}
            value={values[name]}
            onChange={onChange}
            label={label}
            LabelProps={{
              style: labelStyles,
            }}
          />
        ))}
        <CustomButton
          type='submit'
          btntype='primary'
          size='large'
          disabled={isLoading}
          isLoading={isLoading}
          width={216}
          style={{ marginTop: 16 }}
        >
          {!isLoading ? t('save_changes') : 'Einen Moment bitte...'}
        </CustomButton>
      </Form>
    </Column>
  )
}
