import React, { FunctionComponent } from 'react'

import { Bin } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { classes, FolderTreeModalRemoveProps } from '.'

export const Remove: FunctionComponent<FolderTreeModalRemoveProps> = ({ removeCallback, closeCallback }) => {
  return (
    <div className={classes.modal.removeContent}>
      <span>Are you sure you want to remove this folder?</span>
      <Button onClick={() => closeCallback()}>No, go back!</Button>
      <Button
        onClick={() => {
          removeCallback(false)
          closeCallback()
        }}
        red
      >
        <Bin />
        Yes, Remove!
      </Button>
    </div>
  )
}

Remove.displayName = 'Casus-Components-FolderTree-Modal-Remove'

export default Remove
