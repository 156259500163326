import { IMenuItemEl } from './MenuPopover.types'
import { styled } from '@material-ui/core/styles'
import { Box, Typography, Popover } from '@material-ui/core'

export const PopupMenuContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 0),
  zIndex: -1,
}))

export const MenuItem = styled(Box)(({ theme, disabled, subitem }: IMenuItemEl) => ({
  padding: theme.spacing(2, 0),
  width: 240,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '#fff',
  pointerEvents: disabled ? 'none' : 'auto',
  opacity: disabled ? 0.6 : 1,
  transition: 'all 200ms',
  '&:not(:last-child)': {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colorz.brand.light,
  },
  '&:hover': {
    backgroundColor: theme.colorz.brand.light,
  },
}))

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  fontWeight: 700,
  fontSize: 16,
  letterSpacing: 0.4,
  flexGrow: 1,
}))

export const IconWrapper = styled(Box)(({ theme }) => ({
  width: 48,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export { Popover }
