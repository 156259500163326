import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, className } from './index'

const Overview = React.memo(props => {
  const { count = 3, linecap = 'butt', strokeWidth = 10 } = props
  const iconId = useMemo(uuid, [])
  const spacing = (VIEWPORT_SIZE - count * strokeWidth) / (count + 1)
  const lines = new Array(count)
    .fill(null)
    .map((_, i) => (
      <line
        key={`Overview-SVG-Icon-line-${iconId}-${i}`}
        x1={`${(strokeWidth + spacing).toFixed(2)}`}
        y1={`${(spacing + (spacing + strokeWidth) * i).toFixed(2)}`}
        x2={`${(VIEWPORT_SIZE - spacing / 2).toFixed(2)}`}
        y2={`${(spacing + (spacing + strokeWidth) * i).toFixed(2)}`}
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        strokeLinecap={`${linecap}`}
      />
    ))
  const dots = new Array(count)
    .fill(null)
    .map((_, i) => (
      <rect
        key={`Overview-SVG-Icon-dot-${iconId}-${i}`}
        x={`${(spacing / 2).toFixed(2)}`}
        y={`${(spacing - strokeWidth / 2 + (spacing + strokeWidth) * i).toFixed(2)}`}
        width={strokeWidth}
        height={strokeWidth}
        rx={linecap === 'round' ? '50%' : 0}
        ry={linecap === 'round' ? '50%' : 0}
        fill="currentColor"
        stroke="none"
      />
    ))
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      {dots}
      {lines}
    </svg>
  )
})

export { Overview }
export default Overview
