import SegmentsMarker from './SegmentsMarker'
import TextMarker from './TextMarker'

import './style.scss'
import './wizardEditorMarkerTextStyle.scss'

export { SegmentsMarker, TextMarker }

// const mapChunks = textChunks =>
//   textChunks.map(chunk => ({
//     spanStyles: [chunk.customStyle || '', ...(chunk.styles || [])].filter(s => s && !chunkStylesToIgnore.includes(s)),
//     text: chunk.text || '',
//   }))
const chunkStylesToIgnore = ['Normal']
const getSpanStyles = textChunks =>
  Array.from(new Set(textChunks.reduce((acc, { styles }) => [...acc, ...styles], []))).filter(s => s && !chunkStylesToIgnore.includes(s))

export {
  // mapChunks,
  getSpanStyles,
}
