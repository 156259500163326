import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect } from 'react'
import useStore, {
  WizardAnswerByIdSelector,
  NavigateQuestionnaireForwardAction,
  NavigateQuestionnaireBackwardAction,
  AnswerWithOptionAction,
  UnanswerOptionAction,
  getFormattedAnswerValue,
  getOptionPropertiesFromAnswerById,
} from '___store'

import {
  CASUS_KEYSTRINGS,
  ANSWER_VALUE_MATCH,
  OPTION_GROUP_SELECT,
  OPTION_TYPES,
  Question,
  StaticOption,
  INPUT_TYPE_MAP,
  OPTION_VALUE_TYPES,
  OptionLimits,
  OptionValueTypeUnionType,
} from '___types'
import { extractValueFromInputEvent } from 'utilities/helpers'
import { RadioButton as RadioButtonIcon, CheckBox as CheckBoxIcon } from 'assets/svgIconComponents'
import { Button, CheckBox, Input, RadioButton } from 'components/CasusComponents'
import { applyLimitsToValue, getCurrentInputValue, useQuestionContext } from '.'
import {
  WizardLayoutLeftPaneDocumentQuestionnaireOptionProps,
  wizardLayoutLeftPaneDocumentQuestionnaireOptionClasses as classes,
} from 'Layouts/WizardLayout'

type UseStoreHookResultType = {
  wizardAnswerById: WizardAnswerByIdSelector
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
  navigateQuestionnaireBackward: NavigateQuestionnaireBackwardAction
  answerWithOption: AnswerWithOptionAction
  unanswerOption: UnanswerOptionAction
}

const UserInputStaticOption: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireOptionProps> = React.memo(props => {
  const { id: questionId } = useQuestionContext() as Question
  const { wizardAnswerById, navigateQuestionnaireForward, answerWithOption, unanswerOption } = useStore(
    `selectWizardAnswerById[${questionId}]`,
    'navigateQuestionnaireForward',
    'answerWithOption',
    'unanswerOption'
  ) as UseStoreHookResultType
  const { index, option, select, autoConfirm, singleOption, firstOption } = props
  const { id, valueType, limits, placeholder, label } = (option || {}) as StaticOption
  const [input, setInput]: [HTMLPreElement | HTMLInputElement | undefined, Dispatch<SetStateAction<HTMLPreElement | HTMLInputElement | undefined>>] =
    useState()
  const inputRef: RefCallback<HTMLPreElement | HTMLInputElement> = useCallback(node => node && setInput(node), [])

  const [foundValue, selected] = useMemo(() => {
    const optionProperties = wizardAnswerById && getOptionPropertiesFromAnswerById(wizardAnswerById, id)
    return optionProperties?.type !== undefined
      ? [getFormattedAnswerValue(optionProperties.type as OptionValueTypeUnionType, optionProperties.value, { dateFormat: 'yyyy-MM-dd' }), true]
      : [undefined, false]
  }, [wizardAnswerById, id])

  const inputOptions = useMemo(
    () => Object.assign({}, valueType === OPTION_VALUE_TYPES.DATE_TIME ? { inputTime: true } : undefined, limits),
    [valueType, limits]
  )

  const inputHandler = useCallback(
    event => {
      if (!questionId) return
      const value = applyLimitsToValue(valueType, extractValueFromInputEvent(valueType, event), (limits || {}) as OptionLimits)
      const isValueInvalid = value === undefined || (typeof value === 'number' && isNaN(value))
      const escapedValue = Array.from(String(value).matchAll(new RegExp('"|\'', 'g')))
        .sort(({ index: a }, { index: b }) => b! - a! || 0)
        .reduce((result, { index }) => result.slice(0, index) + '\\' + result.slice(index), String(value))
      if (!isValueInvalid)
        answerWithOption({
          questionId,
          value: `${id}:{{${CASUS_KEYSTRINGS.INPUT} type="${valueType}" value="${escapedValue}"}}`,
        })
      if (selected && isValueInvalid) unanswerOption({ id, questionId })
    },
    [questionId, valueType, limits, answerWithOption, id, selected, unanswerOption]
  )
  const confirmHandler = useCallback(() => {
    if (!(id && questionId && input)) return
    const currentInputValue = applyLimitsToValue(valueType, getCurrentInputValue(valueType, input), (limits || {}) as OptionLimits)
    answerWithOption({ questionId, value: `${id}:{{${CASUS_KEYSTRINGS.INPUT} type="${valueType}" value="${currentInputValue}"}}` })
    if (select === OPTION_GROUP_SELECT.SINGLE && autoConfirm) navigateQuestionnaireForward()
  }, [id, questionId, input, valueType, limits, answerWithOption, select, autoConfirm, navigateQuestionnaireForward])
  const discardHandler = useCallback(() => {
    if (!(id && questionId && selected)) return
    unanswerOption({ id, questionId })
  }, [id, questionId, selected, unanswerOption])

  return (
    <div
      className={classes.wrapper}
      data-type={OPTION_TYPES.STATIC}
      data-select={select}
      data-single-option={singleOption ? '' : undefined}
      data-selected={selected ? '' : undefined}
    >
      {select === OPTION_GROUP_SELECT.MULTI ? (
        <CheckBox
          className={classes.optionLabel}
          value={selected}
          tabbable={false}
          dataSet={{ label: selected ? undefined : label || (index + 10).toString(36).toUpperCase() }}
          onChange={toggle => {
            if (!toggle) unanswerOption({ id, questionId })
            else {
              confirmHandler()
              if (input) input.focus()
            }
          }}
          onClickBlur
          emphasized
        />
      ) : singleOption ? null : (
        <RadioButton
          className={classes.optionLabel}
          value={selected}
          tabbable={false}
          dataSet={{ label: selected ? undefined : label || (index + 10).toString(36).toUpperCase() }}
          onClick={toggle => {
            if (!toggle) unanswerOption({ id, questionId })
            else {
              confirmHandler()
              if (input) input.focus()
            }
          }}
          onClickBlur
          emphasized
        />
      )}
      <Input
        ref={inputRef}
        type={INPUT_TYPE_MAP[valueType]}
        tertiary
        defaultValue={foundValue}
        placeholder={placeholder}
        options={inputOptions}
        debouncedInput={300}
        autoselect={firstOption}
        // showActionButtons={!(select === OPTION_GROUP_SELECT.SINGLE && singleOption)}
        showActionButtons={false}
        onInput={inputHandler}
        onConfirm={confirmHandler}
        onDiscard={discardHandler}
      />
    </div>
  )
})

const PredefinedStaticOption: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireOptionProps> = React.memo(props => {
  const { id: questionId } = useQuestionContext() as Question
  const { wizardAnswerById, answerWithOption, unanswerOption, navigateQuestionnaireForward } = useStore(
    `selectWizardAnswerById[${questionId}]`,
    'answerWithOption',
    'unanswerOption',
    'navigateQuestionnaireForward'
  ) as UseStoreHookResultType
  const { index, option, select, autoConfirm, firstOption } = props
  const { id, value, text, valueType, label } = (option || {}) as StaticOption

  const [button, setButton]: [HTMLButtonElement | undefined, Dispatch<SetStateAction<HTMLButtonElement | undefined>>] = useState()

  const selected = useMemo(() => wizardAnswerById?.values?.some(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id === id), [wizardAnswerById, id])
  const dataSet = useMemo(
    () =>
      Object.entries({
        type: OPTION_TYPES.STATIC,
        predefined: '',
        select,
        singleOption: props.singleOption ? '' : undefined,
        selected: selected ? '' : undefined,
      }).reduce((result, [key, value]) => (value !== undefined ? Object.assign(result, { [key]: value }) : result), {}),
    [select, props.singleOption, selected]
  )

  const formattedText = useMemo(() => text || getFormattedAnswerValue(valueType, value), [text, valueType, value])

  const buttonRef: RefCallback<HTMLButtonElement | undefined> = useCallback(node => node && setButton(node), [])
  const clickHandler = useCallback(() => {
    if (!questionId) return
    if (select === OPTION_GROUP_SELECT.SINGLE) {
      answerWithOption({ questionId, value: `${id}:{{${CASUS_KEYSTRINGS.PREDEFINED} type="${valueType}" value="${value}"}}` })
      if (autoConfirm) navigateQuestionnaireForward()
    } else if (select === OPTION_GROUP_SELECT.MULTI) {
      if (!selected) answerWithOption({ questionId, value: `${id}:{{${CASUS_KEYSTRINGS.PREDEFINED} type="${valueType}" value="${value}"}}` })
      else unanswerOption({ id, questionId })
    }
  }, [questionId, select, answerWithOption, id, valueType, value, autoConfirm, navigateQuestionnaireForward, selected, unanswerOption])

  useEffect(() => {
    if (button && firstOption) button.focus()
  }, [button, firstOption])

  return (
    <Button ref={buttonRef} className={classes.wrapper} dataSet={dataSet} onClick={clickHandler} noOverlaySVG>
      {select === OPTION_GROUP_SELECT.SINGLE && props.singleOption ? null : (
        <div className={classes.optionLabel} data-label={selected ? undefined : label || (index + 10).toString(36).toUpperCase()}>
          {select === OPTION_GROUP_SELECT.SINGLE ? (
            <RadioButtonIcon active={selected} strokeWidth={5} />
          ) : (
            <CheckBoxIcon active={selected} strokeWidth={5} />
          )}
        </div>
      )}
      {formattedText}
    </Button>
  )
})

export const Option: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireOptionProps> = React.memo(
  ({ option, index, select, autoConfirm, singleOption, firstOption }) => {
    const { type, value } = option || {}
    const predefined = Boolean(value)

    if (type === OPTION_TYPES.STATIC) {
      if (predefined)
        return (
          <PredefinedStaticOption
            index={index}
            option={option as StaticOption}
            select={select}
            autoConfirm={autoConfirm}
            singleOption={singleOption}
            firstOption={firstOption}
          />
        )
      else
        return (
          <UserInputStaticOption
            index={index}
            option={option as StaticOption}
            select={select}
            autoConfirm={autoConfirm}
            singleOption={singleOption}
            firstOption={firstOption}
          />
        )
    }
    return null
  }
)

Option.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-Option'

export default Option
