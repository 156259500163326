import { MainLayout, WizardLayout } from 'Layouts'
import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

const layouts = {
  main: {
    layoutComponent: <MainLayout />,
    paths: ['/dev/documents', '/dev/templates', '/dev/profile'],
  },
  wizard: {
    layoutComponent: <WizardLayout />,
    paths: ['/dev/document/new', '/dev/document/:templateId/new', '/dev/template/new'],
  },
  noSidebar: {
    layoutComponent: 'Some Component',
    paths: [],
  },
}

const DevPage = React.memo(() => {
  return (
    <Switch>
      <Route path={layouts.wizard.paths}>{layouts.wizard.layoutComponent}</Route>
      <Route path={layouts.main.paths}>{layouts.main.layoutComponent}</Route>
      <Redirect to="/dev/documents" />
    </Switch>
  )
})

DevPage.displayName = 'DevPage'

export default DevPage
