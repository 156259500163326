import React from 'react'

import { className } from './index'

const Circle = React.memo(() => (
  <svg className={`${className} Circle`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <circle cx="50" cy="50" r="20" stroke="none" fill="currentColor" />
  </svg>
))

export { Circle }
export default Circle
