import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  /* minWidth: 'calc(100vw - 800px)',
  maxWidth: 'calc(100vw - 800px)', */
  width: '100%',
  marginLeft: 0,
  position: 'relative',
  maxHeight: '100vh',
  overflowY: 'scroll',
}))

export const ContentContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: theme.spacing(4, 6),
  paddingBottom: theme.spacing(10),
}))
