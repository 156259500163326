import React, { FunctionComponent, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DocumentProgress } from '___types'
import { Check } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import Legend from './Draft.Legend'
import { MainLayoutRightPaneDocumentProgressDraftProps, mainLayoutRightPaneDocumentProgressDraftClasses as classes } from '../../../../..'

const progressBarColors = ['emphasis', 'tertiary', 'low-contrast', 'base']

export const Draft: FunctionComponent<MainLayoutRightPaneDocumentProgressDraftProps> = React.memo(
  ({ id, active, publishing, publishingCallback, progress }) => {
    const { t: translate } = useTranslation('translation', { keyPrefix: 'general' })
    const history = useHistory()

    const { public: publicProgress, private: privateProgress } = (progress || {}) as DocumentProgress
    const publicAnswered = Math.min(publicProgress?.questions || 0, publicProgress?.answered || 0)
    const publicQuestions = publicProgress?.questions || 0
    const privateAnswered = Math.min(privateProgress?.questions || 0, privateProgress?.answered || 0)
    const privateQuestions = privateProgress?.questions || 0
    const questions = publicQuestions + privateQuestions
    const answered = publicAnswered + privateAnswered
    const percentage = ((answered / questions || 0) * 100).toFixed(2)

    const filledOut = answered >= questions
    const statusText = translate(`document.status.draft${filledOut ? '' : active ? '-active' : '-done'}`)

    const progressBarGradient = useMemo(() => {
      const stops = [publicAnswered / questions, answered / questions, (publicAnswered + privateQuestions) / questions]
      return `linear-gradient(to right, var(--${progressBarColors[0]}-color) 0%, ${stops
        .map(
          (stop, i) =>
            `var(--${progressBarColors[i]}-color) ${(stop * 100).toFixed(2)}%, var(--${progressBarColors[i + 1]}-color) ${(stop * 100).toFixed(2)}%`
        )
        .join(', ')}, var(--${progressBarColors[stops.length]}-color) 100%)`
    }, [publicAnswered, questions, answered, privateQuestions])

    return (
      <div className={classes.wrapper} data-filled-out={active && filledOut ? '' : undefined} data-complete={!active ? '' : undefined}>
        <span className={classes.statusText}>
          {!active ? <Check /> : null}
          {`${statusText.slice(0, 1).toUpperCase()}${statusText.slice(1)}`}
          {active && filledOut ? (
            <span>
              &#183;&nbsp;{`${answered}/${questions}`}&nbsp;&#183;&nbsp;{percentage}%
            </span>
          ) : null}
        </span>
        {active && publicAnswered < publicQuestions && !publishing ? (
          <Button className={classes.callToAction} onClick={publishingCallback} onClickBlur transparent>
            Publish
          </Button>
        ) : null}
        {active && !filledOut ? (
          <Button className={classes.callToAction} onClick={() => history.push(`/documents/${id}/configure`)} onClickBlur transparent>
            Fill Out
          </Button>
        ) : null}
        {active && filledOut ? (
          <Button className={classes.callToAction} onClick={() => console.log('FINALIZE!')} onClickBlur tertiary>
            Submit
          </Button>
        ) : null}
        {active && !filledOut ? (
          <>
            <span className={classes.progressText}>Questions Answered</span>
            <span className={classes.progressValue}>{`${answered}/${questions}`}</span>
            <div className={classes.progressBar} style={{ background: progressBarGradient }} />
            <span className={classes.progressValue}>{percentage}%</span>
            <Legend />
          </>
        ) : null}
      </div>
    )
  }
)

Draft.displayName = 'MainLayout-RightPane-Document-Progress-Draft'

export default Draft
