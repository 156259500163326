import { useMemo } from 'react'
import useStore, { WizardDataStructureSelector, WizardSectionByIndexSelector } from '___store'

import { WizardStateDataStructureSection } from '___types'
import { useEditorContext } from '..'
import { generateLayoutString } from '../../helpers'

type UseStoreHookResultType = { wizardSectionByIndex?: WizardSectionByIndexSelector }
type UseSectionResultType = (WizardStateDataStructureSection & { layoutString: string }) | undefined

export const useSection = (index?: number): UseSectionResultType => {
  const { dataStructure } = useEditorContext()
  const { sections } = dataStructure || ({} as WizardDataStructureSelector)!

  const contextSection = sections && typeof index === 'number' && !isNaN(index) && sections[index]
  const { wizardSectionByIndex } = useStore(!contextSection ? `selectWizardSectionByIndex[${index}]` : undefined) as UseStoreHookResultType

  const result = contextSection || wizardSectionByIndex
  const useSectionReturn = useMemo(() => result && Object.assign({}, result, { layoutString: generateLayoutString(result.layout) }), [result])

  return useSectionReturn
}

export default useSection
