import React, { FunctionComponent } from 'react'

import { Switch } from 'components/CasusComponents'
import { MainLayoutRightPaneTemplateCompanySharingProps, mainLayoutRightPaneTemplateCompanySharingClasses as classes } from '../../../..'

export const CompanySharing: FunctionComponent<MainLayoutRightPaneTemplateCompanySharingProps> = React.memo(
  ({ id, toggleCallback, isShared, toggling }) => {
    return (
      <div className={classes.wrapper}>
        <span className={classes.title}>Company share</span>
        <Switch disabled={toggling} className={classes.switch} value={isShared} onClick={() => toggleCallback(id)} />
      </div>
    )
  }
)

CompanySharing.displayName = 'MainLayout-RightPane-Template-Sharing'

export default CompanySharing

