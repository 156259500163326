import { useQuery } from 'react-query'

import { templatesAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchTemplateList = () => {
  const templateListQuery = useQuery({
    queryKey: [QUERY_KEYS.TEMPLATES],
    queryFn: templatesAPI.getTemplateList,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return templateListQuery
}

export default useFetchTemplateList
