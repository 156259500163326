import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, PublicProps, className } from './index'

const ZOOM = 0.7

const Public: FunctionComponent<PublicProps> = React.memo(({ stroke = 'none', strokeWidth = '5', fill = 'currentColor' }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const iconId = useMemo(uuid, [])

  const person = (
    x: number = 0,
    y: number = 0,
    maskId?: string,
    stroke = 'currentColor',
    strokeWidth: string | number = 0,
    fill: string = 'currentColor'
  ) => (
    <g>
      <path
        d={`M ${x + 30} ${y + 90} c -20 0 -10 -30 20 -30 c 30 0 40 30 20 30 Z`}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        fill={fill}
        mask={maskId && `url(#${maskId})`}
      />
      <ellipse cx={x + 50} cy={y + 35} rx="14" ry="16" stroke={stroke} strokeWidth={strokeWidth} fill={fill} mask={maskId && `url(#${maskId})`} />
    </g>
  )

  return (
    <svg className={`${className} Public`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={iconId}>
          <rect x="0" y="0" width="100" height="100" stroke="none" fill="white" />
          {person(-35, -5, undefined, 'black', 12)}
          {person(35, 5, undefined, 'black', 12)}
        </mask>
      </defs>
      {person(0, -15, iconId, stroke, strokeWidth, fill)}
      {person(-35, -5, undefined, stroke, strokeWidth, fill)}
      {person(35, 5, undefined, stroke, strokeWidth, fill)}
    </svg>
  )
})

export { Public }
export default Public
