import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, FilterProps, className } from './index'

const ZOOM = 0.85

const Filter: FunctionComponent<FilterProps> = React.memo(({ lines = 3 }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const iconId = useMemo(uuid, [])

  const render = useMemo(
    () =>
      new Array(lines || 3).fill(null).map((_, i) => {
        const y = `${((VIEWPORT_SIZE - 10) / lines) * (i + 0.5)}`
        const xOffset = (30 / (lines - 1)) * i
        return (
          <line
            key={`Filter-SVG-Icon-${iconId}-${i}`}
            x1={10 + xOffset}
            x2={90 - xOffset}
            y1={y}
            y2={y}
            stroke="currentColor"
            strokeWidth="8"
            strokeLinecap="round"
            shapeRendering="geometricPrecision"
          />
        )
      }),
    [lines, iconId]
  )

  return (
    <svg className={`${className} Filter`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <g>{render}</g>
      <line x1="50" x2="50" y1={(VIEWPORT_SIZE - 10) * (1 - (1 / lines) * 0.5)} y2="95" stroke="currentColor" strokeWidth="8" strokeLinecap="round" />
    </svg>
  )
})

export { Filter }
export default Filter
