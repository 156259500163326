import { findSegmentById } from 'Wizard/parsing'
import { evaluateQuestionConditionalOutcome } from './helpers/index' // remove /index
import { contentIdsSelectorFunction, getParentQuestionId } from './helpers'
import {
  cellContentIdsSelector,
  headerFooterContentIdsSelector,
  markerContentIdsSelector,
  sectionLayoutsSelector,
  sectionPageContentIdsSelector,
  segmentByIdSelector,
  numberingByIdSelector,
} from './selectorHelpers'
import { userValueRegex } from './helpers/document-generation'
import { defaultLayout, getSegmentById } from './helpers/editor-content'

const selectors = {
  // =============================================================== //
  // =========================== GENERAL =========================== //
  // =============================================================== //
  selectTitle: state => state.wizard.title,
  selectDirty: state => state.wizard.dirty,
  // =============================================================== //
  // =============================================================== //
  // =============================================================== //

  // =============================================================== //
  // ========================= CONFIGURING ========================= //
  // =============================================================== //
  selectConfigStack: state => state.wizard.configuring,
  selectConfigStackKeys: state => (selectors.selectConfigStack(state)?.map(c => `${c.key}:${c.id}`) || []).join('; ') || '',
  selectConfig: state => selectors.selectConfigStack(state)?.slice().pop(),
  selectConfigKey: state => Object.values(selectors.selectConfig(state) || {}).join(':'),
  selectBeingConfigured: (state, id) => Boolean(state.wizard.configuring?.find(c => c.id === id)),
  selectKeyBeingConfigured: (state, key) => Boolean(state.wizard.configuring?.find(c => c.key === key)),
  selectCurrentConfiguringKey: (state, key) =>
    state.wizard.configuring
      ?.slice()
      .reverse()
      .find(c => c.key === key)?.id,
  selectCurrentConfiguringQuestion: state => selectors.selectQuestionById(state, selectors.selectCurrentConfiguringKey(state, 'question')),
  // selectCurrentConfiguringQuestionId: state => selectors.selectCurrentConfiguringQuestion(state)?.id,
  // =============================================================== //
  // =============================================================== //
  // =============================================================== //

  // =============================================================== //
  // ======================= QUESTION LAYOUT ======================= //
  // =============================================================== //
  selectQuestionLayout: state => state.wizard.questionLayout,
  selectQuestionLayoutGroupIds: state => (state.wizard.questionLayout?.map(({ id }) => id) || []).join('; '),
  selectQuestionLayoutGroupById: (state, id) => state.wizard.questionLayout?.find(qlg => qlg.id === id),
  selectQuestionLayoutGroupType: (state, groupId) => selectors.selectQuestionLayoutGroupById(state, groupId)?.type,
  selectQuestionLayoutGroupLabel: (state, groupId) => selectors.selectQuestionLayoutGroupById(state, groupId)?.label,
  selectQuestionLayoutGroupQuestionIds: (state, groupId) => (selectors.selectQuestionLayoutGroupById(state, groupId)?.questions || []).join('; '),

  // =============================================================== //
  // =============================================================== //
  // =============================================================== //

  // =============================================================== //
  // ========================== QUESTIONS ========================== //
  // =============================================================== //
  selectQuestions: state => state.wizard.questions,
  selectQuestionById: (state, id) => state.wizard.questions?.find(q => q.id === id),
  selectQuestionMarkerIds: (state, qid) => (selectors.selectQuestionById(state, qid)?.markers || []).join('; '),
  selectQuestionUnassignedMarkerIds: (state, qid) => {
    const question = selectors.selectQuestionById(state, qid)
    const assignedMarkers = new Set(question?.options?.reduce((acc, { markers = [] }) => acc.concat(markers), []))
    return (question?.markers?.filter(id => !assignedMarkers.has(id)) || []).join('; ') || ''
  },
  selectQuestionSubQuestions: (state, qid) => state.wizard.questions?.filter(q => q?.advanced?.subQuestionTo?.split(':')[0] === qid),
  selectQuestionSubQuestionIds: (state, qid) => (selectors.selectQuestionSubQuestions(state, qid)?.map(q => q.id) || []).join('; '),
  selectQuestionConditionalOutcome: (state, id) => true,
  selectQuestionParentOptionSelected: (state, id) => {
    const temporary = selectors.selectAdvancedQuestionConfiguration(state, id)?.subQuestionTo?.split(':') || []
    temporary.splice(1, 1)
    const [parentQuestionId, parentOptionId] = temporary
    return parentOptionId ? selectors.selectQuestionOptionIsSelected(state, parentQuestionId, parentOptionId) : true
  },
  selectQuestionIsVisible: (state, id) =>
    selectors.selectQuestionConditionalOutcome(state, id) && selectors.selectQuestionParentOptionSelected(state, id),
  selectAdvancedQuestionConfiguration: (state, id) => id && selectors.selectQuestionById(state, id)?.advanced,
  selectQuestionOptionGroups: (state, qid) => selectors.selectQuestionById(state, qid)?.optionGroups,
  selectQuestionOptionGroupIds: (state, qid) => (selectors.selectQuestionOptionGroups(state, qid)?.map(og => og.id) || []).join('; '),
  selectQuestionOptionGroupById: (state, qid, id) => selectors.selectQuestionOptionGroups(state, qid)?.find(og => og.id === id),
  selectQuestionOptionGroupOptions: (state, qid, id) => selectors.selectQuestionOptionGroupById(state, qid, id)?.options,
  selectQuestionOptionGroupOptionIds: (state, qid, id) =>
    (selectors.selectQuestionOptionGroupOptions(state, qid, id)?.map(o => o.id) || []).join('; '),
  selectQuestionOptionGroupSubQuestions: (state, id) => state.wizard.questions?.filter(q => q?.advanced?.subQuestionTo?.split(':')[1] === id),
  selectQuestionOptionGroupSubQuestionIds: (state, id) =>
    (selectors.selectQuestionOptionGroupSubQuestions(state, id)?.map(q => q.id) || []).join('; '),
  selectQuestionOptionIds: (state, qid) =>
    (selectors.selectQuestionOptionGroups(state, qid)?.reduce((acc, { options }) => acc.concat(options), []) || []).map(o => o.id).join('; '),
  selectQuestionOptionById: (state, qid, id) =>
    selectors
      .selectQuestionById(state, qid)
      ?.optionGroups.reduce((acc, cur) => acc.concat(cur.options), [])
      .find(o => o.id === id),
  selectQuestionOptionGroupOptionById: (state, qid, ogid, id) =>
    selectors.selectQuestionOptionGroupById(state, qid, ogid)?.options.find(o => o.id === id),
  selectQuestionOptionSubQuestions: (state, oid) => state.wizard.questions?.filter(q => q?.advanced?.subQuestionTo?.split(':')[2] === oid),
  selectQuestionOptionSubQuestionIds: (state, oid) => (selectors.selectQuestionOptionSubQuestions(state, oid)?.map(q => q.id) || []).join('; '),
  // selectQuestionOptionValueType: (state, qid, oid) => selectors.selectQuestionOptionById(state, qid, oid)?.valueType,
  // selectQuestionValueType: (state, qid) => selectors.selectQuestionById(state, qid)?.valueType,
  // selectQuestionAdvanced: (state, qid) => selectors.selectQuestionById(state, qid)?.advanced,
  // selectQuestionRules: (state, qid) => selectors.selectQuestionAdvanced(state, qid)?.rules,
  // selectQuestionRuleIds: (state, qid) => (selectors.selectQuestionRules(state, qid)?.map(r => r.id) || []).join('; '),
  // selectQuestionRuleById: (state, qid, id) => selectors.selectQuestionRules(state, qid)?.find(r => r.id === id),
  // ====================== ADVANCED QUESTION ====================== //
  selectQuestionVisibility: (state, qid) => selectors.selectQuestionById(state, qid)?.advanced?.visibility,
  selectQuestionRuleLogic: (state, qid) => selectors.selectQuestionById(state, qid)?.advanced?.logic,
  selectQuestionParent: (state, qid) => selectors.selectQuestionById(state, qid)?.advanced?.subQuestionTo || '',
  // =============================================================== //
  // =============================================================== //
  // =============================================================== //

  // =============================================================== //
  // =========================== OPTIONS =========================== //
  // =============================================================== //
  selectOptionById: (state, id, res = undefined) =>
    state.wizard.questions?.some(question => question.optionGroups?.some(group => (res = group.options?.find(option => option.id === id))))
      ? res
      : res,
  selectOptionMarkerIds: (state, optionId) => (selectors.selectOptionById(state, optionId)?.markers || []).join('; '),
  // =============================================================== //
  // =============================================================== //
  // =============================================================== //

  // =============================================================== //
  // ===================== EDITOR/SECTION/PAGE ===================== //
  // =============================================================== //
  selectDataStructure: state => state.wizard.dataStructure,
  selectSegments: state => state.wizard.dataStructure?.segments,
  selectSegmentsIds: state => (state.wizard.dataStructure?.segments?.map(({ id }) => id) || []).join('; '),
  selectSegmentById: (state, id) => segmentByIdSelector(state, selectors, id),
  selectSegmentTag: (state, id) => selectors.selectSegmentById(state, id)[0]?.tag,
  selectSegmentCustomStyle: (state, id) => selectors.selectSegmentById(state, id)[0]?.customStyle,
  selectSegmentStyles: (state, id) => (selectors.selectSegmentById(state, id)[0]?.styles || []).join('; '),
  selectSections: state => state.wizard.dataStructure?.sections,
  selectSectionCount: state => state.wizard.dataStructure?.sections?.length || 0,
  selectSectionLayouts: state => sectionLayoutsSelector(state, selectors),
  selectSectionInfoByIndex: (state, index = 0) => (state.wizard.dataStructure?.sections || [])[Number(index)],
  selectHeaders: state => state.wizard.dataStructure?.headers,
  selectHeaderIds: state => (state.wizard.dataStructure?.headers?.map(({ id }) => id) || []).join('; '),
  selectFooters: state => state.wizard.dataStructure?.footers,
  selectFooterIds: state => (state.wizard.dataStructure?.footers?.map(({ id }) => id) || []).join('; '),
  selectHeadersAndFooters: state => (state.wizard.dataStructure?.headers || []).concat(state.wizard.dataStructure?.footers || []),
  selectHeaderFooterById: (state, id) => selectors.selectHeadersAndFooters(state).find(headerfooter => headerfooter.id === id),
  selectHeaderFooterLayoutById: (state, id) => state.wizard.sections?.find(({ headers }) => headers?.includes(id))?.layout || defaultLayout,
  selectLocations: state => state.wizard.locations,
  selectSegmentsMarkerLocations: state => state.wizard.locations?.segments,
  selectSegmentsMarkerLocationsUnnested: state =>
    Object.entries(state.wizard.locations?.segments || {}).reduce((acc, entry) => acc.concat(entry[1]), []), // DO NOT USE IN useStore - ALWAYS RETURNS A NEW ARRAY
  selectTextMarkerLocations: state => state.wizard.locations?.text,
  selectTextMarkerLocationsUnnested: state => Object.entries(state.wizard.locations?.text || {}).reduce((acc, entry) => acc.concat(entry[1]), []), // DO NOT USE IN useStore - ALWAYS RETURNS A NEW ARRAY
  selectLocationsUnnested: state => selectors.selectSegmentsMarkerLocationsUnnested(state).concat(selectors.selectTextMarkerLocationsUnnested(state)),
  selectLocationById: (state, id) => selectors.selectLocationsUnnested(state).find(location => location.id === id),
  selectSegmentsMarkerLocationsByParentId: (state, pid) => (state.wizard.locations?.segments || {})[pid],
  selectTextMarkerLocationsByParentId: (state, pid) => (state.wizard.locations?.text || {})[pid],
  selectSectionPageRanges: (state, sIndex) => selectors.selectSectionInfoByIndex(state, sIndex)?.pages,
  selectSectionPageRangeByIndex: (state, sIndex, index = 0) => (selectors.selectSectionPageRanges(state, sIndex) || [])[Number(index)],
  selectSectionPageContentIds: (state, sIndex, index) => sectionPageContentIdsSelector(state, selectors, sIndex, index),
  selectHeaderFooterContentIds: (state, id) => headerFooterContentIdsSelector(state, selectors, id),
  selectCellContentIds: (state, cid) => cellContentIdsSelector(state, selectors, cid),
  selectMarkerContentIds: (state, mid, parent) => markerContentIdsSelector(state, selectors, mid, parent),
  selectNumbering: state => state.wizard.numbering,
  selectNumberingById: (state, pid) => numberingByIdSelector(state, selectors, pid),
  // =============================================================== //
  // =============================================================== //
  // =============================================================== //

  // =============================================================== //
  // ========================== EXTERNALS ========================== //
  // =============================================================== //
  selectExternals: state => state.wizard.externals,
  // =============================================================== //
  // =============================================================== //
  // =============================================================== //

  // =============================================================== //
  // =========================== ANSWERS =========================== //
  // =============================================================== //
  selectAnswers: state => state.wizard.answers,
  selectAnswerById: (state, id) => state.wizard.answers?.find(a => a.id === id),
  selectQuestionOptionIsSelected: (state, qid, id) =>
    Boolean(selectors.selectAnswerById(state, qid)?.values.find(string => string.match(userValueRegex)?.groups.id === id)),
  // =============================================================== //
  // =============================================================== //
  // =============================================================== //
  selectStyles: state => state.wizard.styles,
  selectNumberingSystem: state => state.wizard.numberingSystem,
  // =============================================================== //
  // =============================================================== //
  // =============================================================== //

  selectAnswering: (state, id) => {
    const parentQuestionId = id && getParentQuestionId(state, id)
    const isParentBeingAnswered = parentQuestionId && parentQuestionId !== id && selectors.selectAnswering(state, parentQuestionId)
    return id
      ? state.wizard.answering === id ||
          isParentBeingAnswered ||
          state.wizard.questionLayout?.find(({ id: layoutId }) => layoutId === state.wizard.answering)?.questions?.includes(id) ||
          false
      : state.wizard.answering
  },
  selectConditionalRule: (state, id, rule = null) =>
    selectors.selectQuestions(state)?.find(q => {
      const { advanced: { rules = [] } = {} } = q
      return (rule = rules.find(rule => rule.id === id))
    }) && rule,
  // selectAnswers: (state, id) => (id ? state.wizard.answers?.find(q => q.id === id) : state.wizard.answers),
  selectMode: state => state.wizard.mode,

  selectChoiceMarkers: state =>
    Object.values(state.wizard.locations?.choice || {}).reduce((acc, cur) => {
      acc.push(...cur)
      return acc
    }, []),
  selectReplacementMarkers: (state, parentId) => {
    const replacementLocations = state.wizard.locations?.replacement || {}
    return parentId ? replacementLocations[parentId] : Object.values(replacementLocations).reduce((acc, cur) => acc.push(...cur) && acc, []) // Always returns a new array if no parent Id is given - FIX!
  },
  selectMarker: (state, id) =>
    [...selectors.selectChoiceMarkers(state), ...selectors.selectReplacementMarkers(state)].find(({ id: markerId }) => id === markerId),
  selectQuestionMarkers: (state, id) => selectors.selectQuestions(state, id)?.markers?.join('; '),
  selectCurrentQuestion: (state, type, id) => {
    const current = selectors.selectConfig(state)
    if (current?.key === 'question') {
      const question = selectors.selectQuestions(state, current.id)
      if (!question) return false
      if (!type) return question
      if (question.type === type) return id ? question.markers?.includes(id) : question
    }
    return false
  },
  selectDependencies: (state, id) => (id ? (Object.entries(state.wizard.dependencies)?.find(([d]) => d === id) || [])[1] : state.wizard.dependencies),
  // selectQuestionConditionalOutcome: (state, id) => evaluateQuestionConditionalOutcome(state.wizard, id),
  selectShowGroup: (state, id) =>
    selectors.selectQuestionLayoutGroupById(state, id)?.questions?.some(qid => evaluateQuestionConditionalOutcome(state.wizard, qid)),
  selectSubQuestionStatus: (state, id) => selectors.selectQuestions(state, id)?.advanced?.subQuestionTo,
  selectSubQuestionIds: (state, id) =>
    selectors
      .selectQuestions(state)
      ?.filter(q => q?.advanced?.subQuestionTo?.split(':').includes(id))
      .map(q => q.id)
      .join('; '),
  // selectPageIds: (state, sectionIndex, pageIndex) => pageIdsSelectorFunction(state, sectionIndex, pageIndex),
  // selectMarkerContentIds: (state, id, range) => markerIdsSelectorFunction(state, id, range),
  // selectPageLocations: (state, sectionIndex, pageIndex)F =>
  //   pageLocationsSelectorFunction(state, sectionIndex, pageIndex),
  // selectSectionIdsString: (state, sectionIndex = 0) =>
  //   selectors
  //     .selectSections(state)
  //     [sectionIndex]?.pages?.reduce((acc, { ids }) => [...acc, ...ids], [])
  //     .join('; ') || '',
  // selectSectionIdsString: (state, sectionIndex = 0) => {
  //   const range = selectors.selectSections(state)[Number(sectionIndex)]?.range || [0, 0]
  //   console.log('RANGE: ', range)
  //   return (
  //     state.wizard.dataStructure?.segments
  //       ?.slice(...range)
  //       .reduce((acc, cur) => [acc, cur.id].filter(e => e).join('; '), '') || ''
  //   )
  // },
  // selectDocumentSettings: state => state.wizard.documentSettings,
  selectContentIds: (state, type, ...extra) => contentIdsSelectorFunction(state, type, ...extra),
  selectSegment: (state, segmentId) => {
    if (!segmentId) {
      return state.wizard.dataStructure?.segments
      // return console.warn('Missing segment ID for selectSegment selector! Please provide a valid ID.')
    }
    return findSegmentById(state.wizard.dataStructure?.segments, segmentId)
    // return findSegmentById(state.wizard.sections, segmentId)
  },
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// OPTIMIZATION //////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  selectSegmentInfo: (state, id) => getSegmentById(state.wizard, id)[0],
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
}

export { selectors }
export default selectors
