import React, { FunctionComponent, RefCallback, useCallback, useState } from 'react'
import useStore, { AddMarkerValueMapAction, RemoveMarkerValueMapAction } from '___store'

import { BREAK_TAG_MATCH } from '___types'
import { Cross } from 'assets/svgIconComponents'
import { Button, Input } from 'components/CasusComponents'
import { classes } from '../..'

type UseStoreHookResultType = { addMarkerValueMap: AddMarkerValueMapAction; removeMarkerValueMap: RemoveMarkerValueMapAction }

type Props = { id: string; map: Record<string, string> }
export const Mapping: FunctionComponent<Props> = React.memo(({ id, map }) => {
  const { addMarkerValueMap, removeMarkerValueMap } = useStore('addMarkerValueMap', 'removeMarkerValueMap') as UseStoreHookResultType
  const [valueInput, setValueInput] = useState<HTMLInputElement | undefined>()
  const [mapInput, setMapInput] = useState<HTMLInputElement | undefined>()

  const valueRef: RefCallback<HTMLInputElement> = useCallback(node => node && setValueInput(node), [])
  const mapRef: RefCallback<HTMLInputElement> = useCallback(node => node && setMapInput(node), [])

  return (
    <div className={classes.configEntry.markerConfiguration.fragment.wrapper}>
      <span className={classes.configEntry.markerConfiguration.fragment.title}>Value mapping</span>
      <div className={classes.configEntry.markerConfiguration.fragment.mapping.inputWrapper}>
        <Input ref={valueRef} className={classes.configEntry.markerConfiguration.fragment.mapping.valueInput} type="text" placeholder="To map..." />
        <Input ref={mapRef} className={classes.configEntry.markerConfiguration.fragment.mapping.mapInput} type="multiline" placeholder="Map to..." />
        <Button
          onClick={() => {
            const toMap = valueInput?.value
            // SINGLE LINE //
            // const mapTo = mapInput?.value || ''

            // MULTI LINE //
            const textarea = document.createElement('textarea')
            //@ts-ignore
            textarea.innerHTML = mapInput.innerHTML.replaceAll(BREAK_TAG_MATCH, '\n')
            const mapTo = textarea.value
            textarea.remove()

            if (!toMap) return valueInput?.focus()
            addMarkerValueMap({ id, toMap, mapTo })
            valueInput!.value = ''
            mapInput!.value = ''
          }}
          tertiary
        >
          Map
        </Button>
      </div>
      {Object.entries(map || {}).map(([key, value]) => (
        <div className={classes.configEntry.markerConfiguration.fragment.mapping.mapEntry}>
          <span>{key}</span>
          <span>=&gt;</span>
          <span>{value}</span>
          <Button onClick={() => removeMarkerValueMap({ id, value: key })} onClickBlur transparent>
            <Cross />
          </Button>
        </div>
      ))}
    </div>
  )
})

Mapping.displayName = 'Wizard-Configuration-MarkerConfiguration-Mapping'

export default Mapping
