import React from 'react'

import { className } from './index'

const Underline = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <text x="50" y="50" dy="7" fontFamily="Times New Roman" fontSize="80" dominantBaseline="middle" textAnchor="middle" fill="currentColor">
      U
    </text>
    <line x1="25" y1="85" x2="75" y2="85" stroke="currentColor" strokeWidth="4" />
  </svg>
))

export { Underline }
export default Underline
