import React from 'react'

import { className } from './index'

const InsertBelow = React.memo(() => {
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path d="M 24 20 h 60" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" fill="none" />
      <path d="M 24 36 h 60" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" fill="none" />
      <path d="M 24 52 h 60" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" fill="none" />
      <rect x="14" y="68" width="68" height="16" stroke="currentColor" strokeWidth="6" fill="none" />
    </svg>
  )
})

export { InsertBelow }
export default InsertBelow
