import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { ITemplate } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type UnshareTemplateVariables = { id: string; shareId: string }
export type UnshareTemplateContext = { mutationId: string }
export type UnshareTemplateMutationOptions = MutateOptions<ITemplate | void, unknown, UnshareTemplateVariables, UnshareTemplateContext>
export type UnshareTemplateFunctionType = (shareId: string, options?: UnshareTemplateMutationOptions) => void
const unshareTemplateMutationFunction = (variables: UnshareTemplateVariables) => templatesAPI.unshareTemplate(variables.id, variables.shareId)

export const useUnshareTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: (ITemplate & { mutationId?: string })[] | undefined) => ITemplate[]) =>
    queryClient.setQueryData([QUERY_KEYS.TEMPLATES], method)

  const onMutate = (variables: UnshareTemplateVariables) => {
    const mutationId = uuid()
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original?: ITemplate }
    if (currentTemplate) {
      const originalTemplate = currentTemplate.original || currentTemplate
      const resultingShared = originalTemplate.sharedWith.filter(share => share.id !== variables.shareId)
      const optimisticTemplate = Object.assign({}, originalTemplate, variables, {
        sharedWith: resultingShared,
        mutationId,
        mutating: true,
        mutation: 'unshare',
        original: originalTemplate,
      })
      queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticTemplate))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: UnshareTemplateVariables, context: UnshareTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
      queryClient.removeQueries([QUERY_KEYS.TEMPLATE, id])
      queryClient.resetQueries([QUERY_KEYS.TEMPLATE, id])
      // queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], currentTemplate.original)
      queryClient.cancelQueries([QUERY_KEYS.TEMPLATES])
      queryClient.invalidateQueries([QUERY_KEYS.TEMPLATES])
      queryClient.fetchQuery([QUERY_KEYS.TEMPLATES])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentTemplate.original))
    }
  }

  const onSuccess = (template: ITemplate | void, variables: UnshareTemplateVariables, context: UnshareTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], template)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, template))
  }

  const templateUnshareMutation = useMutation<ITemplate | void, unknown, UnshareTemplateVariables, UnshareTemplateContext>(
    [QUERY_KEYS.TEMPLATE, id],
    unshareTemplateMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const unshareMutationFunction: UnshareTemplateFunctionType = (shareId, options) => templateUnshareMutation.mutate({ id: id!, shareId }, options)

  return { unshare: unshareMutationFunction, unsharing: templateUnshareMutation.isLoading }
}

export default useUnshareTemplate
