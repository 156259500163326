import RootModal from './RootModal'

import './style.scss'

const classes = {
  mainWrapper: 'RootModal-wrapper',
  background: 'RootModal-background',
  wrapper: 'RootModal-container-wrapper',
  content: {
    wrapper: 'RootModal-content-wrapper',
    title: {
      wrapper: 'RootModal-title-wrapper',
      text: 'RootModal-title-text',
      close: 'RootModal-title-close',
    },
  },
  footer: {
    wrapper: 'RootModal-footer-wrapper',
    button: 'RootModal-footer-button',
  },
}

export { RootModal, classes }

