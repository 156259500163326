import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { useDocument } from '___hooks'
import { Button, Signature as SignatureButton } from 'components/CasusComponents'
import { Bell, Check, Download, Pending } from 'assets/svgIconComponents'
import useModal from 'Modal'
import { MainLayoutRightPaneDocumentSignatureProps, mainLayoutRightPaneDocumentSignatureClasses as classes } from 'Layouts/___MainLayout'
import ModalContent from './Signature.ModalContent'

export const Signature: FunctionComponent<MainLayoutRightPaneDocumentSignatureProps> = React.memo(({ id }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.document' })

  const open = useModal({ header: 'Signature Request', footer: false })

  const {
    data: document,
    template: { data: template },
    downloadSigned,
  } = useDocument(id)

  const { signatureRequest } = document || {}

  const splits = Object.entries(template?.splits || {})

  const splitRender = useMemo(() => {
    return (
      <div className={classes.requests}>
        {[[null, translate('full-document')]].concat(splits).map(([splitId, label], i) => {
          const request = signatureRequest?.find(request => request.splitId === splitId)
          const signed = request?.signatures.filter(signature => signature.status_code === 'SIGNED').length || 0
          const status = request?.status_overall

          return (
            <div className={classes.split} key={`MainLayout-RightPane-Document-${id}-Signature-split-${splitId}`}>
              <div className={classes.requestInfo}>
                <span>{label}</span>
                {request ? <span>{`Requested ${DateTime.fromISO(request?.created_at).toRelative()}`}</span> : null}
              </div>
              {request ? (
                <Button
                  onClick={() => {
                    if (status === 'SIGNED') return downloadSigned(splitId)
                    if (status === 'OPEN')
                      return open({
                        content: <ModalContent id={id} requestId={request.id} />,
                      })
                  }}
                  // orange={status === 'OPEN' ? true : undefined}
                  // green={status === 'SIGNED' ? true : undefined}
                >
                  {status === 'OPEN' ? (
                    <>
                      {translate('remind')}
                      <Download />
                    </>
                  ) : null}
                  {status === 'SIGNED' ? (
                    <>
                      {translate('download')}
                      <Download />
                    </>
                  ) : null}
                </Button>
              ) : null}
              <span data-status={status?.toLowerCase()}>
                {request ? (
                  <>
                    <span>{`${status!.slice(0, 1)}${status!.slice(1).toLowerCase()}`}</span>
                    <span>{`${signed} / ${request.signatures.length}`}</span>
                  </>
                ) : (
                  <SignatureButton id={id} splitId={splitId} />
                )}
              </span>
            </div>
          )
        })}
      </div>
    )
  }, [translate, splits, signatureRequest, downloadSigned, open, id])

  const noSplitRender = useMemo(() => {
    if (!signatureRequest?.length) return <SignatureButton id={id} splitId={null} />
    const request = signatureRequest[0]
    if (!request) return
    const signed = request.signatures.filter(signature => signature.status_code === 'SIGNED').length || 0
    const status = request.status_overall

    return (
      <div className={classes.requests}>
        <div className={classes.split}>
          <Button
            onClick={() => {
              if (status === 'SIGNED') return downloadSigned(null)
              if (status === 'OPEN') return open({ content: <ModalContent id={id} requestId={request.id} /> })
            }}
            // orange={status === 'OPEN' ? true : undefined}
            // green={status === 'SIGNED' ? true : undefined}
          >
            {status === 'OPEN' ? <Bell extras="jitter+exclamation" /> : null}
            {status === 'SIGNED' ? <Download /> : null}
          </Button>
          <div className={classes.requestInfo}>
            <span>{translate('full-document')}</span>
            <span>{`Requested ${DateTime.fromISO(request.created_at || '').toRelative()}`}</span>
          </div>
          <span data-status={status?.toLowerCase()}>
            {status === 'OPEN' ? <Pending /> : null}
            {status === 'OPEN' ? <span>{translate('pending')}</span> : null}
            {status === 'SIGNED' ? <Check /> : null}
            {status === 'SIGNED' ? <span>{translate('signed')}</span> : null}
            <span>{`${signed} / ${request.signatures.length || 0}`}</span>
          </span>
        </div>
      </div>
    )
  }, [signatureRequest, id, translate, downloadSigned, open])

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <span>{translate('signature')}</span>
        {splits.length ? splitRender : noSplitRender}
      </div>
    </div>
  )
})

Signature.displayName = 'MainLayout-RightPane-Document-Signature'

export default Signature
