import ACTION_TYPES, { Action } from '___store/actionTypes'
import initialAppState, {
  resetAppState,
  setDocumentsFolderFilter,
  setDocumentsFilter,
  setSelectedDocumentId,
  deselectAllDocuments,
  setTemplatesFolderFilter,
  setTemplatesFilter,
  setSelectedTemplateId,
  deselectAllTemplates,
  SetDocumentsFolderFilterPayload,
  SetDocumentsFilterPayload,
  SetSelectedDocumentIdPayload,
  SetTemplatesFolderFilterPayload,
  SetTemplatesFilterPayload,
  SetSelectedTemplateIdPayload,
} from './helpers'

const reducer = (state = JSON.parse(JSON.stringify(initialAppState)), action: Action) => {
  const { type, payload } = action

  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ////////////////////////////////////////////// GENERAL ////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.APP.GENERAL.RESET) return resetAppState()
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //

  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////// DOCUMENTS ///////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.APP.DOCUMENTS.FOLDER_FILTER_SET) return setDocumentsFolderFilter(state, payload as SetDocumentsFolderFilterPayload)
  if (type === ACTION_TYPES.APP.DOCUMENTS.FILTER_SET) return setDocumentsFilter(state, payload as SetDocumentsFilterPayload)
  if (type === ACTION_TYPES.APP.DOCUMENTS.DOCUMENT_SELECTED) return setSelectedDocumentId(state, payload as SetSelectedDocumentIdPayload)
  if (type === ACTION_TYPES.APP.DOCUMENTS.DESELECTED) return deselectAllDocuments(state)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //

  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////// TEMPLATES ///////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.APP.TEMPLATES.FOLDER_FILTER_SET) return setTemplatesFolderFilter(state, payload as SetTemplatesFolderFilterPayload)
  if (type === ACTION_TYPES.APP.TEMPLATES.FILTER_SET) return setTemplatesFilter(state, payload as SetTemplatesFilterPayload)
  if (type === ACTION_TYPES.APP.TEMPLATES.TEMPLATE_SELECTED) return setSelectedTemplateId(state, payload as SetSelectedTemplateIdPayload)
  if (type === ACTION_TYPES.APP.TEMPLATES.DESELECTED) return deselectAllTemplates(state)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  return state
}

export { reducer }
export default reducer
