import React from 'react'
import TextInput from './TextInput'
import NumberInput from './NumberInput'
import DateInput from './DateInput'
import DateTimeInput from './DateTimeInput'

export const Input = React.memo(
  ({
    id,
    type = 'text',
    className,
    inputClass,
    disabled,
    defaultValue,
    value,
    onChange,
    onBlur,
    onDiscard,
    needsConfirmation = false,
    placeholder,
    multiline = false,
    maxLines,
    extraLines = 1,
    minimum,
    maximum,
    step,
    autofocus = false,
    blurShowUnmodified = false,
    parseLinks = false,
  }) => {
    switch (type) {
      case 'text':
        return (
          <TextInput
            id={id}
            className={className}
            inputClass={inputClass}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onDiscard={onDiscard}
            needsConfirmation={needsConfirmation}
            placeholder={placeholder}
            multiline={multiline}
            maxLines={maxLines}
            extraLines={extraLines}
            autofocus={autofocus}
            blurShowUnmodified={blurShowUnmodified}
            parseLinks={parseLinks}
          />
        )
      case 'number':
        return (
          <NumberInput
            id={id}
            className={className}
            inputClass={inputClass}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onDiscard={onDiscard}
            needsConfirmation={needsConfirmation}
            placeholder={placeholder}
            minimum={minimum}
            maximum={maximum}
            step={step}
            autofocus={autofocus}
            blurShowUnmodified={blurShowUnmodified}
          />
        )
      case 'date':
        return (
          <DateInput
            id={id}
            className={className}
            inputClass={inputClass}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            minimum={minimum}
            maximum={maximum}
            autofocus={autofocus}
          />
        )
      case 'datetime-local':
        return (
          <DateTimeInput
            id={id}
            className={className}
            inputClass={inputClass}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            minimum={minimum}
            maximum={maximum}
            autofocus={autofocus}
          />
        )
      default:
        return null
    }
  }
)

Input.displayName = 'Input'

export default Input
