import React, { useMemo } from 'react'
import useStore from '___store'

import { CASUS_CLASSES } from 'Wizard/constants'
import { SegmentsMarker } from '../Markers'
import Segment from './index'
import { classes } from '../..'
import { mapContentIds } from '..'

const Cell = React.memo(({ id: cellId }) => {
  const { cellContentIds } = useStore(`selectCellContentIds[${cellId}]`)

  const { segmentInfo } = useStore(`selectSegmentInfo[${cellId}]`)

  const { styles = [] } = segmentInfo

  const contentIds = useMemo(() => cellContentIds.split('; ').filter(id => id), [cellContentIds])

  const segments = useMemo(
    () =>
      mapContentIds(contentIds).map(({ type, id }) =>
        type === 'segment' ? <Segment key={id} id={id} /> : <SegmentsMarker key={id} id={id} parent={cellId} />
      ),
    [contentIds, cellId]
  )

  const cellClass = [CASUS_CLASSES.tableCell, classes.segment.table.cell].filter(s => s).join(' ')
  return (
    <td id={cellId} className={`${styles.join(' ')} ${cellClass}`}>
      {segments}
    </td>
  )
})

Cell.displayName = 'Wizard-Editor-Segment-Table-Cell'

export default Cell
