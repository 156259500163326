import { SettingsGroupItemProps } from './SettingsGroupItem.types'
import React, { useState } from 'react'
import { Container, Label, Value, ValueWrapper } from './SettingsGroupItem.styles'
import { SelectGroup, TextInputGroup, ButtonSquare, Icon } from 'components/common'

const SettingsGroupItem: React.FC<SettingsGroupItemProps> = ({
  item,
  handleSaveChange,
}) => {
  const [editing, setEditing] = useState(false)
  const { label, value, options } = item
  const [state, setState] = useState(item.value)
  const [saved, setSaved] = useState(false)

  const handleValueEnter = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!handleSaveChange) return
    setEditing(true)
  }

  const handleValueLeave = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!handleSaveChange) return
    setEditing(false)
    if (saved) setSaved(false)
    if(item.value !== state) handleSaveChange(item.name, state)
  }

  let component: null | React.ReactNode = null

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSaved(false)
    const { value } = e.target
    setState(value)
  }

  const handleSelectChange = (
    e: React.ChangeEvent<{ name?: string; value: string }>
  ): void => {
    setSaved(false)
    const { value } = e.target
    setState(value)
  }

  const handleSaveClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setSaved(true)
    if(editing) setEditing(false)
    if (handleSaveChange) handleSaveChange(item.name, state)
  }

  const handleEnterClick = (e: React.KeyboardEvent): void => {
    if (e.keyCode !== 13) return
    setSaved(true)
    if(editing) setEditing(false)
    if (handleSaveChange) handleSaveChange(item.name, state)
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (saved && !editing) setEditing(true)
  }

  if (options) {
    component = (
      <SelectGroup
        name='123'
        options={item.options!}
        value={state}
        InputElProps={{ style: { marginBottom: 0, height: 32, paddingLeft: 4 } }}
        handleChange={handleSelectChange}
      />
    )
  } else {
    component = (
      <TextInputGroup
        name={'123'}
        value={state}
        InputGroupContainerProps={{ style: { marginBottom: 0 } }}
        style={{ height: 32 }}
        onChange={handleChange}
        onKeyDown={handleEnterClick}
      />
    )
  }

  return (
    <Container onMouseLeave={handleValueLeave}>
      <Label>{label}</Label>
      <ValueWrapper onMouseEnter={handleValueEnter} onMouseMoveCapture={handleMouseMove}>
        {editing ? component : <Value>{value}</Value>}
        {editing && (
          <ButtonSquare
            size={32}
            style={{ marginLeft: 4, border: 'none' }}
            btntype='primary'
            onClick={handleSaveClick}
          >
            <Icon iconName='check' fill='#fff' />
          </ButtonSquare>
        )}
      </ValueWrapper>
    </Container>
  )
}

export default SettingsGroupItem
