import { IOption, OptionType, IQuestionRadio, TRadioQuestionTypes } from 'types'
import React, { useState, useEffect, useCallback } from 'react'

import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useEditor } from 'config/textEditor/useEditor'

import QuestionRadioComponent from './QuestionRadio.component'
import {
  removeHighlightItemsByQuestionId,
  removeHighlightItemsByLocations,
  checkIfQuestionHasValues,
} from 'utils'
import {
  generateNewLocationObject,
  generateNewOptionObject,
  templateOptionsToState,
  generateQuestionPropsByType,
} from '../../../../services'
import { forms as content } from 'pages/Authenticated/Template/constants/content'

import {
  addLocationToQuestion,
  addLocationToQuestionOption,
  addOptionToQuestion,
  addTextToQuestionOption,
  deleteQuestionOption,
  editOption,
} from 'pages/Authenticated/Template/views/TemplateEdit/question.utils'
import questionTypes from 'constants/question-types'
import { useUser } from 'queries/user/useUser'
import { useTranslation } from 'react-i18next'

const { RADIO_LINK, RADIO_REPLACEMENT } = questionTypes

type OptWithChecked = OptionType & {
  checked?: boolean
}

const QuestionRadio: React.FC = () => {
  const editor = useEditor()
  const {
    set,
    textSelectionIsActive,
    selectionData,
    selectedQuestionType,
    question,
  } = useTemplateCreation()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice
  const {
    id,
    start,
    end,
    currentQuestionId: qid,
    locationId,
  } = selectionData as any

  const [currentSelectionId, setCurrentSelectionId] = useState<string | null>(
    null
  )
  const currentQuestion = question as IQuestionRadio
  const options = currentQuestion.options!
  const { type } = currentQuestion
  const [state, setState] = useState(templateOptionsToState(options))
  const { t} = useTranslation()

  // RADIO LINK
  const addSelectedLocationRL = useCallback(
    (
      id: string,
      optId: string,
      start: number,
      end: number,
      qid?: string,
      locId?: string
    ) => {
      const newLocation = generateNewLocationObject(
        id,
        start,
        end,
        qid,
        locId,
        docXmicro
      )
      set({
        question: addLocationToQuestionOption(question, {
          id: optId,
          location: newLocation,
        }),
        selectionData: {
          id: null,
          start: null,
          end: null,
          currentQuestionId: null,
          locationId: null,
        },
      })
    },
    [set, question, docXmicro]
  )

  // RADIO REPLACEMENT
  const addSelectedLocationRR = useCallback(
    (id: string, start: number, end: number, qid?: string, locId?: string) => {
      const newLocation = generateNewLocationObject(
        id,
        start,
        end,
        qid,
        locId,
        docXmicro
      )
      set({
        question: addLocationToQuestion(question, newLocation),
        selectionData: {
          id: null,
          start: null,
          end: null,
          currentQuestionId: null,
          locationId: null,
        },
      })
    },
    [set, question, docXmicro]
  )

  useEffect(() => {
    if (start !== null && end !== null && id && currentSelectionId) {
      if (type === RADIO_LINK) {
        addSelectedLocationRL(
          id,
          currentSelectionId,
          start,
          end,
          qid,
          locationId
        )
      } else if (type === RADIO_REPLACEMENT) {
        addSelectedLocationRR(id, start, end, qid, locationId)
      }
    }
  }, [
    id,
    start,
    end,
    currentSelectionId,
    qid,
    locationId,
    type,
    addSelectedLocationRL,
    addSelectedLocationRR,
  ])

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target // name = id
    setState((prev) => ({
      ...prev,
      [name]: { ...prev[name], text: value },
    }))
    // TODO IMRPOVE
    set({
      question: addTextToQuestionOption(currentQuestion, {
        id: name,
        text: value,
      }),
    })
  }

  const handleFocusTextInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target // name = id
    if (name !== currentSelectionId) {
      setCurrentSelectionId(name)
      set({ textSelectionIsActive: false })
    }
  }

  const handleTextSelectClick = (id: string) => {
    if (id === currentSelectionId)
      return set({ textSelectionIsActive: !textSelectionIsActive })
    setCurrentSelectionId(id)
    set({ textSelectionIsActive: true })
  }

  const removeLocationsFromOption = (optId: string, option: IOption) => {
    const newOpt = { ...option, locations: [] }
    removeHighlightItemsByLocations(option.locations!, editor!)
    set({ question: editOption(question, newOpt) })
    if (!editor) return
    // const html = editor.getBody().innerHTML
    // localStorage.setItem('textSelectionHtmlData', html)
  }

  const handleCheckboxChange = (id: string): void => {
    const { checked } = state[id]
    const clickedOption = options.find((opt: OptionType) => opt.id === id)
    const hasLocations = clickedOption?.locations?.length
    if (!clickedOption) return
    if (!checked && hasLocations) removeLocationsFromOption(id, clickedOption)
    set({ textSelectionIsActive: false })

    setState((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        checked: !prev[id].checked,
      },
    }))
  }

  const handleAddOption = (): void => {
    let newOption: OptWithChecked = {
      ...generateNewOptionObject(type === RADIO_LINK),
    }
    if (type === RADIO_LINK) {
      newOption.checked = false
    }
    setState((prev) => ({
      ...prev,
      [newOption.id]: newOption,
    }))

    delete newOption.checked

    let updatedOption: OptionType = { ...newOption }

    set({
      question: addOptionToQuestion(currentQuestion, updatedOption),
      textSelectionIsActive: false,
    })
  }

  const handleRadioFormChange = (formType: TRadioQuestionTypes): void => {
    if (formType === type) return

    let updatedQuestion: IQuestionRadio = { ...currentQuestion }
    const newProps = generateQuestionPropsByType(formType)
    updatedQuestion.type = newProps.type as TRadioQuestionTypes
    updatedQuestion.options = newProps.options!
    if (newProps.locations) updatedQuestion.locations = newProps.locations

    const requireConfirm = checkIfQuestionHasValues(currentQuestion)

    if (requireConfirm) {
      if (!window.confirm(t(content.confirmText))) return
      removeHighlightItemsByQuestionId(currentQuestion.id, editor!)
    }

    setState(templateOptionsToState(updatedQuestion.options!))

    set({
      selectedQuestionType: formType,
      question: updatedQuestion,
      textSelectionIsActive: false,
    })
  }

  const handleDeleteOption = (opt: OptionType): void => {
    if (window.confirm(t(content.confirmTextOption))) {
      set({
        question: deleteQuestionOption(question, opt.id),
      })
      opt.subquestions.forEach((subqItem) =>
        removeHighlightItemsByQuestionId(subqItem.id, editor!)
      )
    }
  }

  return (
    <QuestionRadioComponent
      handleRadioFormChange={handleRadioFormChange}
      handleTextSelectClick={handleTextSelectClick}
      handleAddOption={handleAddOption}
      handleTextChange={handleTextChange}
      handleFocusTextInput={handleFocusTextInput}
      handleCheckboxChange={handleCheckboxChange}
      handleDeleteOption={handleDeleteOption}
      selectedQuestionType={selectedQuestionType}
      textSelectionIsActive={textSelectionIsActive}
      currentSelectionId={currentSelectionId}
      options={options}
      deleteOptionDisabled={options.length < 3}
      values={state}
    />
  )
}

export default QuestionRadio
