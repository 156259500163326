import React, { useMemo, useState, useEffect } from 'react'
import useStore from '___store'
import { Caret } from 'assets/svgIconComponents'
import { CASUS_CLASSES } from 'Wizard/constants'

const classes = {
  // wrapper: 'Wizard-MarkerCycler-wrapper',
  wrapper: 'Wizard-Configuration-Questionnaire-Actions-MarkerCycler',
  previousButton: 'Wizard-MarkerCycler-previousButton',
  thisButton: 'Wizard-MarkerCycler-thisButton',
  nextButton: 'Wizard-MarkerCycler-nextButton',
  markerIdentifier: 'Wizard-MarkerCycler-markerIdentifier',
}

const scrollToMarker = markerId => {
  const marker =
    document.getElementById(markerId) ||
    Array.from(document.getElementsByClassName(CASUS_CLASSES.segmentsMarkerDiv)).find(({ id }) => id.includes(markerId))
  return (
    marker &&
    (!window.chrome
      ? marker.scrollIntoView({
          behavior: 'smooth',
          block: Array.from(marker.classList).includes(CASUS_CLASSES.segmentsMarkerDiv) ? 'start' : 'center',
        })
      : marker.scrollIntoView())
  )
}


export const MarkerCycler = React.memo(({ minimized = false, disabled }) => {
  const { answering, questionLayout = [], questions = [] } = useStore('selectAnswering', 'selectQuestionLayout', 'selectQuestions')

  const markers = useMemo(
    () =>
      (questionLayout.find(({ id }) => id === answering)?.questions || [answering]).reduce((acc, questionId) => {
        const question = questions.find(({ id }) => id === questionId)
        return acc.concat(
          question.optionGroups.reduce(
            (accumulated, { options }) => options.reduce((a, { markers }) => a.concat(markers), accumulated),
            question.markers
          )
        )
      }, []),
    [questionLayout, answering, questions]
  )

  if (!markers.length || markers.length < 2) return

  const first = markers[0]
  const [, setCurrent] = useState(first)

  useEffect(() => {
    if (disabled || !first) return
    scrollToMarker(first)
    setCurrent(first)
  }, [disabled, first])

  return (
    <>
    <div className={classes.wrapper} data-minimized={minimized} data-disabled={disabled}>
      <button
        className={classes.previousButton}
        data-solo={markers.length === 1}
        disabled={disabled}
        type="button"
        onClick={() =>
          !disabled &&
          setCurrent(prev => {
            const markerId = markers[(markers.indexOf(prev) + markers.length - 1) % markers.length]
            scrollToMarker(markerId)
            return markerId
          })
        }
      >
        <Caret />
      </button>
    </div>
    <div className={classes.wrapper} data-minimized={minimized} data-disabled={disabled}>
    <button
      className={classes.nextButton}
      data-solo={markers.length === 1}
      disabled={disabled}
      type="button"
      onClick={() =>
        !disabled &&
        setCurrent(prev => {
          const markerId = markers[(markers.indexOf(prev) + markers.length + 1) % markers.length]
          scrollToMarker(markerId)
          return markerId
        })
      }
    >
      <Caret />
    </button>
  </div>
  </>
  )
})

MarkerCycler.displayName = 'Wizard-Questionnaire-MarkerCycler'

export default MarkerCycler
