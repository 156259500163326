import { styled } from '@material-ui/core'

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, 256px)',
  gridTemplateRows: 'max-content',
  gap: '2.5rem',
  position: 'relative',
})

export default Container
