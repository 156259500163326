import React, { FunctionComponent } from 'react'
import { InfiniteData, useQueryClient } from 'react-query'

import { MAPPED_DOCUMENTS_FILTERS, IDocument } from '___types'
import { QUERY_KEYS } from '___queries'
import { ListItemProps, listItemClasses as classes } from '.'

export const DocumentListItem: FunctionComponent<ListItemProps> = React.memo(({ id, onClick }) => {
  const queryClient = useQueryClient()

  const allDocuments = Object.values(MAPPED_DOCUMENTS_FILTERS).reduce((allDocuments: IDocument[], filter) => {
    const documentsQuery = queryClient.getQueryData([QUERY_KEYS.DOCUMENTS, filter]) as InfiniteData<IDocument[]>
    const documents = (documentsQuery?.pages || []).reduce((filterDocuments, page) => filterDocuments.concat(page), [])
    return allDocuments.concat(documents) as IDocument[]
  }, [])
  const document = (allDocuments.find(document => document.id === id) || {}) as IDocument
  const { name } = document

  return (
    <button className={classes.wrapper} type="button" onClick={event => typeof onClick === 'function' && onClick(event, id)}>
      {name}
    </button>
  )
})

DocumentListItem.displayName = 'Casus-Components-DocumentListItem'

export default DocumentListItem
