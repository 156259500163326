import React, { Fragment } from 'react'
import { ActionButtonsProps, LastStepContentProps } from './ActionButtons.types'
import { ActionButtonsContainer } from './ActionButtons.style'
import { CustomButton, ButtonSquare, Icon } from 'components/common'
import { btnContent as content } from 'pages/Authenticated/Template/constants/content'
import { useTranslation } from 'react-i18next'

const LastStepContent: React.FC<LastStepContentProps> = ({ isSubquestion }) => {
  const { t } = useTranslation()
  
  return (
  <Fragment>
    {isSubquestion ? t(content.save) : t(content.saveFinish)}{' '}
    <Icon iconName='arrow-right' fill='#fff' style={{ marginLeft: 'auto' }} />
  </Fragment>
)}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  handleBackClick,
  handleForwardClick,
  forwardDisabled,
  backDisabled = false,
  btnNextText,
  isSubquestion = false,
  lastStep,
  id,
}) => {
  return (
    <ActionButtonsContainer>
      <ButtonSquare
        id={id}
        btntype='secondary'
        onClick={handleBackClick}
        size={48}
        style={{ marginRight: 16, flexShrink: 0 }}
        disabled={backDisabled}
      >
        <Icon iconName='arrow-left' />
      </ButtonSquare>
      <CustomButton
        onClick={handleForwardClick}
        btntype='primary'
        size='large'
        disabled={forwardDisabled}
        width={!lastStep || isSubquestion ? 162 : undefined}
        data-intercom-target={`forward-btn`}
        style={{ flexShrink: 1, flexGrow: lastStep ? 1 : 0 }}
        id={`forward-btn`}
      >
        {!lastStep ? btnNextText : <LastStepContent isSubquestion={isSubquestion} />}
      </CustomButton>
    </ActionButtonsContainer>
  )
}

export default ActionButtons
