import SignersList from './SignersList'

const classes = {
  container: {
    wrapper: 'SignersList-Container-wrapper',
    title: 'SignersList-Title-wrapper',
    empty: { container: 'SignersList-Empty-Container-wrapper', text: 'SignersList-Empty-Text-wrapper' },
    signers: {
        container: 'SignersList-Signers-Container-wrapper',
        
    }
  },
}

export { classes }
export default SignersList

