import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { ITemplate, PartializeKeys, SignatureSecurityLevel, Signee } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { useCallback } from 'react'

export type PublishTemplateVariables = {
  id: string
  customLastStepAvailable: boolean
  lastStepConfig?: { message?: string; ctaLabel: string; ctaLink: string }
  previewAvailable: boolean
  signatureAvailable: boolean
  signatureConfig?: { security: SignatureSecurityLevel; signees: Signee[]; message?: string }
  expires: boolean
  expirationTime?: string | null
  singleUse: boolean
  splitId?: string | null
}
export type PublishTemplateContext = { mutationId: string }
export type PublishTemplateMutationOptions = MutateOptions<ITemplate, unknown, PublishTemplateVariables, PublishTemplateContext>
export type PublishTemplateFunctionType = (
  customLastStepAvailable?: boolean,
  lastStepConfig?: { message?: string; ctaLabel: string; ctaLink: string },
  previewAvailable?: boolean,
  signatureAvailable?: boolean,
  signatureConfig?: { security: SignatureSecurityLevel; signees: Signee[]; message?: string },
  expires?: boolean,
  expirationTime?: string | null,
  singleUse?: boolean,
  splitId?: string | null,
  options?: PublishTemplateMutationOptions
) => void
const publishTemplateMutationFunction = (variables: PublishTemplateVariables) => {
  const payload = Object.assign({}, variables, { split: variables.splitId }) as PartializeKeys<PublishTemplateVariables, 'id'>
  delete payload.id
  delete payload.splitId
  return templatesAPI.publishTemplate(variables.id!, payload)
}

export const usePublishTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: (ITemplate & { mutationId?: string })[] | undefined) => ITemplate[]) =>
    queryClient.setQueryData([QUERY_KEYS.TEMPLATES], method)

  const onMutate = (variables: PublishTemplateVariables) => {
    const mutationId = uuid()
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original?: ITemplate }
    if (currentTemplate) {
      const originalTemplate = currentTemplate.original || currentTemplate
      const optimisticTemplate = Object.assign({}, originalTemplate, { mutationId, mutating: true, mutation: 'update', original: originalTemplate })
      queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticTemplate))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: PublishTemplateVariables, context: PublishTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
      queryClient.removeQueries([QUERY_KEYS.TEMPLATE, id])
      queryClient.resetQueries([QUERY_KEYS.TEMPLATE, id])
      // queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], currentTemplate.original)
      queryClient.cancelQueries([QUERY_KEYS.TEMPLATES])
      queryClient.invalidateQueries([QUERY_KEYS.TEMPLATES])
      queryClient.fetchQuery([QUERY_KEYS.TEMPLATES])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentTemplate.original))
    }
  }

  const onSuccess = (template: ITemplate, variables: PublishTemplateVariables, context: PublishTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], template)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, template))
  }

  const templatePublishMutation = useMutation<ITemplate, unknown, PublishTemplateVariables, PublishTemplateContext>(
    [QUERY_KEYS.TEMPLATE, id],
    publishTemplateMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const publishMutationFunction: PublishTemplateFunctionType = useCallback(
    (
      customLastStepAvailable = false,
      lastStepConfig,
      previewAvailable = false,
      signatureAvailable = false,
      signatureConfig,
      expires = false,
      expirationTime,
      singleUse = false,
      splitId,
      options
    ) =>
      templatePublishMutation.mutate(
        {
          id: id!,
          customLastStepAvailable,
          lastStepConfig,
          previewAvailable,
          signatureAvailable,
          signatureConfig,
          expires,
          expirationTime,
          singleUse,
          splitId,
        },
        options
      ),
    [templatePublishMutation, id]
  )

  return { publish: publishMutationFunction, publishing: templatePublishMutation.isLoading }
}

export default usePublishTemplate
