import { useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import useStore from '___store'

import { getTenant } from '___utilities'
import { userAPI } from '___api'
import { QUERY_KEYS } from '___queries'

const logoutUserFunction = () => userAPI.logoutUser()

export const useLogoutUser = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { logout } = useStore('logout')

  const userLogoutMutation = useMutation([QUERY_KEYS.USER, 'logout'], logoutUserFunction, {
    onMutate: () => {
      queryClient.clear()
      history.push('/logout')
      logout()
    },
    onSuccess: () => {
      const tenant = getTenant()
      if (tenant && tenant !== 'localhost') history.push(`https://${tenant}.app.v2.casus.ch/auth/login`)
      else history.push('/auth/login')
    },
    onSettled: async () => {
      queryClient.cancelQueries()
      queryClient.invalidateQueries()
    },
  })

  return userLogoutMutation.mutate
}

export default useLogoutUser
