import React, { FunctionComponent, useMemo } from 'react'

import { VIEWPORT_SIZE, EditFieldProps, className } from './index'

const ZOOM = 1

const EditField: FunctionComponent<EditFieldProps> = React.memo(({ type = 'text' }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const text = useMemo(() => {
    switch (type) {
      case 'text':
        return 'A'
      case 'number':
        return '12'
      case 'currency':
        return '$'
      default:
        return 'A'
    }
  }, [type])

  return (
    <svg className={`${className} EditField`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <line x1="15" x2="85" y1="85" y2="85" stroke="currentColor" strokeWidth="5" />
      <path d="M 65 85 l 5 -15 l 40 -40 l 10 10 l -40 40 Z" stroke="currentColor" strokeWidth="5" fill="none" transform="rotate(-30 50 70)" />
      <text x="62" y="70" fontSize="70" dominantBaseline="text-top" alignmentBaseline="baseline" textAnchor="end" fill="currentColor">
        {text}
      </text>
    </svg>
  )
})

export { EditField }
export default EditField
