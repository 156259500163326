import React from 'react'

import PagesLayout from 'layout/PagesLayout/PagesLayout'
import Sidebar from './TeamPageSidebar'
import { Container } from '../../Authenticated.page.styles'
import { MainLayout } from 'layout'

interface Props {
  isLoading: boolean
}
const Layout: React.FC<Props> = ({ isLoading, children }) => {
  return (
    <MainLayout>
      <Container>
        <Sidebar />
        <PagesLayout hideHeader isLoading={isLoading}>
          {children}
        </PagesLayout>
      </Container>
    </MainLayout>
  )
}

export default Layout

