import React, { FunctionComponent, useMemo } from 'react'

import { VIEWPORT_SIZE, ClockProps, className } from './index'

const ZOOM = 0.8

const MINUTE_HAND_LENGTH = 25
const HOUR_HAND_LENGTH = 20

const Clock: FunctionComponent<ClockProps> = React.memo(({ h = 4, m = 0 }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const minuteAngle = (m / 60) * 2 * Math.PI
  const hourAngle = (h / 12) * 2 * Math.PI

  return (
    <svg className={`${className} Clock`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <path
        d={`M ${50 + Math.sin(hourAngle) * HOUR_HAND_LENGTH} ${50 - Math.cos(hourAngle) * HOUR_HAND_LENGTH} L 50 50 L ${
          50 + Math.sin(minuteAngle) * MINUTE_HAND_LENGTH
        } ${50 - Math.cos(minuteAngle) * MINUTE_HAND_LENGTH}`}
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <circle cx="50" cy="50" r="40" stroke="currentColor" strokeWidth="5" fill="none" />
    </svg>
  )
})

export { Clock }
export default Clock
