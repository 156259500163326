import React from 'react'
import { DateTime } from 'luxon'
import useStore from '___store'

import { ButtonGroup, Input, Switch } from 'components'
import { classes } from '../../../index'

const generateOptionValue = valueType => {
  switch (valueType) {
    case 'number':
      return '0'
    case 'date':
    case 'date-time':
      return DateTime.now().toISO()
    case 'string':
    default:
      return 'Option text'
  }
}

const PlaceholderInput = React.memo(({ id }) => {
  const { optionById: { value = '', placeholder = '', valueType = 'string' } = {}, updateOption } = useStore(
    `selectOptionById[${id}]`,
    'updateOption'
  )

  const predefined = Boolean(value)

  const {
    wrapper: wrapperClass,
    text: textClass,
    placeholderInput: { input: inputClass, switch: switchClass },
  } = classes.configEntry.optionConfiguration.fragment

  return (
    <div className={wrapperClass}>
      <span className={textClass}>Value Source:</span>
      <ButtonGroup collapsed>
        <Switch
          className={switchClass}
          value={predefined}
          onChange={result =>
            result ? updateOption({ optionId: id, value: generateOptionValue(valueType) }) : updateOption({ optionId: id, value: '' })
          }
          labels={['User input', 'Predefined']}
        />
        <Input
          className={inputClass}
          disabled={predefined}
          placeholder="Option placeholder goes here (e.g. John Doe)"
          defaultValue={placeholder}
          onChange={placeholder => updateOption({ optionId: id, placeholder })}
          needsConfirmation
          blurShowUnmodified
        />
      </ButtonGroup>
    </div>
  )
})

PlaceholderInput.displayName = 'Wizard-Configuration-OptionConfiguration-PlaceholderInput'

export default PlaceholderInput
