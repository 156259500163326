import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { BEFolder } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type RemoveTemplateFolderVariables = string
export type RemoveTemplateFolderContext = { mutationId: string }
export type RemoveTemplateFolderMutationOptions = MutateOptions<void, unknown, RemoveTemplateFolderVariables, RemoveTemplateFolderContext>
export type RemoveTemplateFolderFunctionType = (options?: RemoveTemplateFolderMutationOptions) => void
// const removeTemplateFolderMutationFunction = (variables: RemoveTemplateFolderVariables) => templatesAPI.removeTemplateFolder(variables)

export const useRemoveTemplateFolder = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: BEFolder[] | undefined) => BEFolder[]) => queryClient.setQueryData([QUERY_KEYS.TEMPLATE_FOLDERS], method)

  const onMutate = () => {
    const mutationId = uuid()
    const replaceMethod = (currentFolder: BEFolder & { mutationId?: string; original?: BEFolder }) => {
      const original = currentFolder.original || currentFolder
      return Object.assign({}, currentFolder, { mutationId, mutating: true, mutation: 'remove', original })
    }
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, replaceMethod))
    return { mutationId }
  }

  const onError = (error: unknown, variables: RemoveTemplateFolderVariables, context: RemoveTemplateFolderContext | undefined) => {
    const currentFolder = (
      queryClient.getQueryData([QUERY_KEYS.TEMPLATE_FOLDERS]) as (BEFolder & { mutationId?: string; original: BEFolder })[]
    ).find(folder => folder.id === id)
    if (currentFolder && currentFolder.mutationId === context?.mutationId) {
      queryClient.cancelQueries([QUERY_KEYS.TEMPLATE_FOLDERS])
      queryClient.invalidateQueries([QUERY_KEYS.TEMPLATE_FOLDERS])
      queryClient.fetchQuery([QUERY_KEYS.TEMPLATE_FOLDERS])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentFolder.original))
    }
  }

  const onSuccess = (response: void, variables: RemoveTemplateFolderVariables, context: RemoveTemplateFolderContext | undefined) => {
    updateListing(data => data?.filter(datum => datum.id !== id) || [])
  }

  const templateFolderRemoveMutation = useMutation<void, unknown, RemoveTemplateFolderVariables, RemoveTemplateFolderContext>(
    [QUERY_KEYS.TEMPLATE, id],
    templatesAPI.removeTemplateFolder,
    { onMutate, onError, onSuccess }
  )

  const removeFolderMutationFunction: RemoveTemplateFolderFunctionType = options => templateFolderRemoveMutation.mutate(id!, options)

  return { removeTemplateFolder: removeFolderMutationFunction, removingTemplateFolder: templateFolderRemoveMutation.isLoading }
}

export default useRemoveTemplateFolder
