import DocsApi from 'api/docs.api'
// import { SUCCESS_PASSWORD_CHANGED } from 'constants/messages'
import { useToast } from 'hooks/useToast'
import { queryKeys } from 'queries'
import { useMutation } from 'react-query'

export const useDownloadPublic = (id: string) => {
  const toast = useToast()
  const pdf = useMutation(queryKeys.DOCUMENT, (payload: any) => DocsApi.downloadPublic(id, 'pdf', true, 'v2'), {
    onSuccess: () => {
      toast('success', 'Your document has been downloaded.')
    },

    onError: error => {
      toast('error', 'An error has occurred.')
    },
  })

  const docx = useMutation(queryKeys.DOCUMENT, (payload: any) => DocsApi.downloadPublic(id, 'docx', true, 'v2'), {
    onSuccess: () => {
      toast('success', 'Your document has been downloaded.')
    },

    onError: error => {
      toast('error', 'An error has occurred.')
    },
  })
  return { pdf, docx }
}
