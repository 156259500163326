import { useQuery } from 'react-query'

import { userAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

const getExternalApiFieldsQueryFunction = () => userAPI.getExternalApiFields()

export const useFetchExternalServices = () => {
  const templateQuery = useQuery({
    queryKey: [QUERY_KEYS.EXTERNAL_SERVICES],
    queryFn: getExternalApiFieldsQueryFunction,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: 0,
  })
  return templateQuery
}

export default useFetchExternalServices
