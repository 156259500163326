import {
  CreateTemplateFolderFunctionType,
  CreateTemplateFolderMutationOptions,
  RemoveTemplateFolderFunctionType,
  UpdateTemplateFolderFunctionType,
  useCreateTemplateFolder,
  useRemoveTemplateFolder,
  useUpdateTemplateFolder,
} from '___queries'

export type UseTemplateFolderHookUnknownIdReturnType = {
  create: CreateTemplateFolderFunctionType
  remove?: RemoveTemplateFolderFunctionType
  update?: UpdateTemplateFolderFunctionType
  creating: boolean
  removing?: boolean
  updating?: boolean
}

export type CreateFolderWithinFunctionType = (name: string, options?: CreateTemplateFolderMutationOptions) => void

// ==================== USE TEMPLATE FOLDER OVERLOAD ==================== //
function useTemplateFolder(id?: null): { create: CreateTemplateFolderFunctionType; creating: boolean }
function useTemplateFolder(id: string): {
  create: CreateFolderWithinFunctionType
  remove: RemoveTemplateFolderFunctionType
  update: UpdateTemplateFolderFunctionType
  creating: boolean
  removing: boolean
  updating: boolean
}
// ====================================================================== //
function useTemplateFolder(id?: string | null) {
  const { createTemplateFolder: create, creatingTemplateFolder: creating } = useCreateTemplateFolder()
  const { removeTemplateFolder: remove, removingTemplateFolder: removing } = useRemoveTemplateFolder(id)
  const { updateTemplateFolder: update, updatingTemplateFolder: updating } = useUpdateTemplateFolder(id)

  const createFolderWithin: CreateFolderWithinFunctionType = (name, options) => create(name, id!, options)

  return id ? { create: createFolderWithin, remove, update, creating, removing, updating } : { create, creating }
}

export default useTemplateFolder
