import React, { Dispatch, FunctionComponent, RefCallback, SetStateAction, useCallback, useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import { useTemplate } from '___hooks'
import { Bulb, Clock } from 'assets/svgIconComponents'
import { Input, Profile } from 'components/CasusComponents'
import { MainLayoutRightPaneTemplateInfoProps, mainLayoutRightPaneTemplateInfoClasses as classes } from '../../../..'

export const Info: FunctionComponent<MainLayoutRightPaneTemplateInfoProps> = React.memo(({ id, renaming, renameCallback }) => {
  const [input, setInput]: [HTMLInputElement | undefined, Dispatch<SetStateAction<HTMLInputElement | undefined>>] = useState()
  const inputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setInput(node), [])

  const { data: template } = useTemplate(id)
  const { name = '', edited, author, displayAuthor } = template || {}
  const { email } = author || {}
  const { firstName, lastName, imageUrl } = displayAuthor || {}

  //@ts-ignore
  const { hour = 0, minute = 0, second = 0 } = (edited?._seconds && DateTime.fromMillis(edited?._seconds * 1000)?.c) || {}
  const m = minute + second / 60
  const h = hour + m / 60

  const templateName = useMemo(
    () => (
      <Input
        key={`MainLayout-RightPane-Template-${id}-Input-${name}`}
        id={`MainLayout-RightPane-Template-${id}-Input`}
        ref={inputRef}
        className={classes.title}
        type="multiline"
        defaultValue={name}
        onConfirm={() => renameCallback(input?.innerText)}
        onDiscard={() => {
          renameCallback()
          if (input) input.innerText = name
        }}
        disabled={!renaming}
        autoselect={renaming}
        primary={renaming}
        transparent
      />
    ),
    [id, inputRef, name, renameCallback, input, renaming]
  )

  const [editedDateTime, createdDateTime] = useMemo(() => {
    const created = !template?.created ? '' : DateTime.fromMillis(template?.created._seconds * 1000).toLocaleString(DateTime.DATETIME_SHORT)
    const edited = !template?.edited ? '' : DateTime.fromMillis(template?.edited._seconds * 1000).toLocaleString(DateTime.DATETIME_SHORT)
    return [edited, created]
  }, [template])

  return (
    <div className={classes.wrapper}>
      <Profile profile={{ firstName, lastName, email, imageUrl }} showInfo />
      {templateName}
      <span className={classes.description}>{template?.description}</span>
      {edited ? (
        <>
          <span className={classes.entry}>
            <Clock h={h} m={m} />
            {editedDateTime}
          </span>
          <span className={classes.entry}>
            <Bulb active />
            {createdDateTime}
          </span>
        </>
      ) : null}
    </div>
  )
})

Info.displayName = 'MainLayout-RightPane-Template-Info'

export default Info
