import React, { FunctionComponent, useMemo } from 'react'
import useStore, { RemoveApproverAction } from '___store'

import { Author } from '___types'
import { useTeam, useUser } from '___hooks'
import { Cross } from 'assets/svgIconComponents'
import { Button, Profile } from 'components/CasusComponents'

type UseStoreHookResultType = { removeApprover: RemoveApproverAction }

export const Approver: FunctionComponent<{ id: string; onClickCallback: (id: string) => void }> = React.memo(({ id, onClickCallback }) => {
  const { data: user } = useUser()
  const { removeApprover } = useStore('removeApprover') as UseStoreHookResultType

  const {
    members: { data: members },
  } = useTeam()

  const approver = useMemo(() => (members || []).concat(user as Author).find(({ id: memberId }) => memberId === id), [members, user, id])

  return (
    <Button onClick={() => removeApprover(id)}>
      {approver ? <Profile profile={approver} showInfo /> : null}
      <Cross />
    </Button>
  )
})

Approver.displayName = ''

export default Approver
