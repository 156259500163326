import React, { useMemo } from 'react'
import useStore from '___store'

import { classes } from '../../index'
import { Select } from 'components'

const CustomStyleSelect = React.memo(({ id }) => {
  const {
    segmentCustomStyle,
    styles: { customStyles = [] } = {},
    applyParagraphCustomStyle,
  } = useStore(`selectSegmentCustomStyle[${id}]`, 'selectStyles', 'applyParagraphCustomStyle')

  const value = segmentCustomStyle || 'none'

  const options = useMemo(() => Object.keys(customStyles).reduce((acc, cur) => Object.assign(acc, { [cur]: cur }), {}), [customStyles])

  return (
    <Select
      className={classes.segment.paragraph.customStyleSelect}
      options={options}
      value={value}
      onChange={style => applyParagraphCustomStyle({ id, style })}
      chooseMode={true}
      chooseRender={value.charAt(0).toUpperCase() + value.slice(1)}
      directionPriority="vertical"
      horizontalDirection="right"
      verticalDirection="down"
    />
  )
})

CustomStyleSelect.displayName = 'Wizard-Editor-Segment-Paragraph-CustomStyleSelect'

export default CustomStyleSelect
