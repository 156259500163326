import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useTemplate } from '___hooks'
import { Copy } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { TemplatePublishing } from 'components/CasusComponents/Publishing'
import { MainLayoutRightPaneTemplatePublishingProps, mainLayoutRightPaneTemplatePublishingClasses as classes } from 'Layouts/___MainLayout'

export const Publishing: FunctionComponent<MainLayoutRightPaneTemplatePublishingProps> = React.memo(({ id }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.template' })
  const { data: { isPublic } = {}, publishing } = useTemplate(id)

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <span>{translate(isPublic ? 'published' : 'publishing')}</span>
        {isPublic ? (
          <Button
            onClick={() => navigator.clipboard.writeText(`${document.location.origin}/public/${id}/new?step=configure`)}
            onClickBlur
            disabled={publishing}
          >
            {translate('copy-link')}
            <Copy type="link" />
          </Button>
        ) : null}
        <TemplatePublishing id={id} />
      </div>
    </div>
  )
})

Publishing.displayName = 'MainLayout-RightPane-Template-Publishing'

export default Publishing
