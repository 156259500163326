import { v4 as uuid } from 'uuid'
import { DateTime } from 'luxon'

import {
  CASUS_KEYSTRINGS,
  SUB_QUESTION_TO_MATCH,
  ANSWER_VALUE_MATCH,
  FIND_WITH_INDEX_NOT_FOUND,
  ANSWERING_IDS_MATCH,
  Answer,
  Answers,
  Questions,
  QuestionLayout,
  Options,
  OptionGroupSelectUnionType,
  ValuesOf,
  SegmentsLocation,
  TextLocation,
  Writable,
  Option,
  OptionValueTypeUnionType,
  extractPropertiesFromCustomText,
  OptionValueProperties,
  AnswerRelevance,
  SubRecord,
  isModeDocumentFlow,
  // STATIC_CALCULATION_FUNCTIONS,
  LanguageValue,
  VALUE_SOURCE_TYPE_MATCH,
  COMPUTED_VALUES,
  ValueSourceProperties,
  VALUE_SOURCE_MATCH,
} from '___types'
import { calculate, parseCalculation } from '___utilities'
import { WizardState, extractFromNestedStructure, findWithIndex, fullAssign } from '.'
import { updateWizardState } from './general'
import { getQuestionById, FoundMarkerType, getMarkerById, getSubQuestionsByQuestionId } from './template-automation'
import { applyParagraphNumbering } from './editor-content'
import { generateLooseQuestionLayoutGroup, mergeQuestionLayoutGroups } from '../../helpers/template-creation'

// ====================================================================================================================================== //
// ============================================================= GENERATORS ============================================================= //
// ====================================================================================================================================== //
const generateRegularGroupIdOrderNumber = (id: string, orderNumber: number, index: number): string =>
  `${id}:${orderNumber.toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}-${(index + 10).toString(36)}`
const generateLooseGroupIdOrderNumber = (id: string, orderNumber: number): string =>
  `${id}:${orderNumber.toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}`
const genericAnswer = { id: '', values: [] } as Answer
const generateAnswer = (answer: Answer): Answer => Object.assign({}, genericAnswer, { id: uuid(), values: [] }, answer)
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================== INSERT SUB QUESTION ======================================================== //
// ===================================================================================================================================== //
const insertSubQuestion = (
  questionOrder: QuestionLayout,
  subQuestionId: string,
  questions: Questions,
  answerRelevance: AnswerRelevance
): QuestionLayout => {
  const question = questions.find(({ id }) => id === subQuestionId)
  const isRelevant = answerRelevance[subQuestionId]
  if (!(question && isRelevant)) return questionOrder
  const parentQuestionId = question.advanced.subQuestionTo.match(SUB_QUESTION_TO_MATCH)?.groups?.questionId
  let inserted = false
  return mergeQuestionLayoutGroups({
    questionLayout: questionOrder
      .reduce((result, group) => {
        const resultingGroup = Object.assign({}, group, { questions: group.questions.slice() })
        result.push(resultingGroup)
        const [subbedQuestionId, index] = findWithIndex(resultingGroup.questions, id => {
          const currentQuestionId = id.slice(Array.from(id.matchAll(new RegExp('(?<questionId>(?<=(sub-)|^)(sub-))', 'g'))).length * 4)
          return currentQuestionId === parentQuestionId
        })
        if (index === -1) return result
        const newDepth = Array.from(subbedQuestionId!.matchAll(new RegExp('(?<questionId>(?<=(sub-)|^)(sub-))', 'g'))).length + 1
        const trailingSubQuestionsOffset = findWithIndex(
          resultingGroup.questions.slice(index + 1),
          id => id.slice(0, newDepth * 4) !== new Array(newDepth).fill('sub-').join('')
        )[1]
        if (trailingSubQuestionsOffset === -1) resultingGroup.questions.push(`${new Array(newDepth).fill('sub-').join('')}${subQuestionId}`)
        else
          resultingGroup.questions.splice(index + 1 + trailingSubQuestionsOffset, 0, `${new Array(newDepth).fill('sub-').join('')}${subQuestionId}`)
        return (inserted = true) && result
      }, [] as QuestionLayout)
      .concat(
        !inserted ? generateLooseQuestionLayoutGroup({ id: '__casus_unknown-parent-sub-question-insertion-group', questions: [subQuestionId] }) : []
      ),
  }).questionLayout
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ====================================================== MAP IDS TO GROUP NUMBER ====================================================== //
// ===================================================================================================================================== //
const mapIdsToRegularGroupNumber = (groupId: string, questions: string[], counter: number): string[] =>
  questions.map((questionId, i) => `${groupId}:${generateRegularGroupIdOrderNumber(questionId, counter, i)}`)
const mapIdsToLooseGroupNumber = (groupId: string, questions: string[], counter: number): string[] =>
  questions.map((questionId, i) => `${groupId}:${generateLooseGroupIdOrderNumber(questionId, counter + i)}`)
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ====================================================== GENERATE QUESTION ORDER ====================================================== //
// ===================================================================================================================================== //
export const generateQuestionOrder = (
  questionLayout: QuestionLayout,
  questions: Questions,
  answerRelevance: AnswerRelevance,
  showExternal: boolean = true,
  showInternal: boolean = true
): string[] => {
  const subQuestionIds = questionLayout.find(({ type }) => type === 'sub-questions')?.questions.slice() || []
  const baseLayout = questionLayout.filter(({ type }) => type !== 'sub-questions')
  const layoutWithSubQuestions = subQuestionIds
    .reduce((result, id) => insertSubQuestion(result, id, questions, answerRelevance), baseLayout)
    .reduce(
      (result, group) =>
        result.concat(
          group.questions.length
            ? Object.assign(group, {
                questions: group.questions.map(id =>
                  id.slice(Array.from(id.matchAll(new RegExp('(?<questionId>(?<=(sub-)|^)(sub-))', 'g'))).length * 4)
                ),
              })
            : []
        ),
      [] as QuestionLayout
    )
  const [questionOrder] = layoutWithSubQuestions.reduce(
    ([result, counter], { id, type, questions: questionIds }) => {
      if (type === 'sub-questions') return [result, counter]
      console.log(showExternal, showInternal)
      const externalFilter = showExternal
        ? questionIds
        : questionIds.filter(questionId => questions.find(question => question.id === questionId)?.isPrivate)
      const internalFilter = showInternal
        ? externalFilter
        : externalFilter.filter(questionId => !questions.find(question => question.id === questionId)?.isPrivate)
      if (type === 'loose') return [result.concat(mapIdsToLooseGroupNumber(id, internalFilter, counter)), counter + internalFilter.length]
      return [result.concat(mapIdsToRegularGroupNumber(id, internalFilter, counter)), counter + 1]
    },
    [[] as string[], 1]
  ) as [string[], number]
  questionOrder[0] = `:${questionOrder[0]}`
  questionOrder[questionOrder.length - 1] = `${questionOrder[questionOrder.length - 1]}:`
  return questionOrder
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ==================================================== GET UNANSWERED QUESTION INFO ==================================================== //
// ====================================================================================================================================== //
export const getUnansweredQuestionInfo = (answers: Answers, questionOrder: string[]): [string | undefined, number] => {
  const answeredQuestionIds = (answers || []).reduce((result, { id, values }) => (values.length ? result.concat(id) : result), [] as string[])
  return (questionOrder || []).reduce(
    ([result, count], questionOrderId) => {
      const answered = answeredQuestionIds.includes(questionOrderId.match(ANSWERING_IDS_MATCH)?.groups?.questionId as string)
      if (answered) return [result, count]
      return [result || questionOrderId, count + 1] as [string, number]
    },
    [undefined, 0] as [string | undefined, number]
  )
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================= QUESTIONNAIRE NAVIGATION CONSTANTS ================================================= //
// ====================================================================================================================================== //
// export const QUESTIONNAIRE_PRE_STEPS = { PRE_STEP: 'pre-answering' } as const
// export const QUESTIONNAIRE_POST_STEPS = {
//   SKIPPED: 'skipped-question-review',
//   CONFIRM: 'confirmation',
//   RENAME: 'document-rename',
// } as const
const QUESTIONNAIRE_NAVIGATION_DIRECTIONS = { FORWARDS: 'forwards', BACKWARDS: 'backwards' } as const
type QuestionnaireNavigationDirection = ValuesOf<typeof QUESTIONNAIRE_NAVIGATION_DIRECTIONS>
type QuestionnaireNavigationConditionalMethodsType = {
  [key: string]: {
    conditional: (state: WizardState, direction: QuestionnaireNavigationDirection, previous: string) => boolean
    method: (state: WizardState, direction: QuestionnaireNavigationDirection) => WizardState
  }
}
const QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS = {
  // skipQuestionsSkippedSection: {
  //   conditional: (state: WizardState): boolean =>
  //     state.answering === QUESTIONNAIRE_POST_STEPS.SKIPPED &&
  //     Boolean(state.answers?.length && state.questionOrder?.length) &&
  //     getUnansweredQuestionInfo(state.answers!, state.questionOrder!)[1] === 0,
  //   method: (state: WizardState, direction: QuestionnaireNavigationDirection): WizardState => {
  //     if (direction === QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS) return updateWizardState(state, { answering: QUESTIONNAIRE_POST_STEPS.CONFIRM })
  //     const lastQuestionInOrder = state.questionOrder![state.questionOrder!.length - 1]
  //     return updateWizardState(state, { answering: lastQuestionInOrder })
  //   },
  // },
} as QuestionnaireNavigationConditionalMethodsType
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ============================================================= GET ANSWER ============================================================= //
// ====================================================================================================================================== //
const getAnswer = (state: WizardState, test: (answer: Answer) => boolean): [Answer, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  findWithIndex(state.answers || [], test)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================== GET ANSWER BY ID ========================================================== //
// ====================================================================================================================================== //
const getAnswerById = (state: WizardState, id: string): [Answer, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  id ? getAnswer(state, answer => answer.id === id) : (FIND_WITH_INDEX_NOT_FOUND.slice() as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>)
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ============================================================ TIDY ANSWER ============================================================ //
// ===================================================================================================================================== //
type TemporaryOptionGroupType = { options: Options; select: OptionGroupSelectUnionType; maximum: number; enforceLimit: boolean }
const tidyAnswer = (state: WizardState, answer: Answer): Answer => {
  const { optionGroups = [] } = getQuestionById(state, answer.id)[0] || {}
  // remove type conversion after the below is fixed/implemented
  const optionGroupsWithLimits = (optionGroups as unknown as TemporaryOptionGroupType[]).map(({ options, select, maximum, enforceLimit = true }) => {
    // remove the line above and uncomment the next one after fixing the optionGroup select (string => Object({ select: string, minimum: number, maximum: number, enforceLimit: boolean }))
    // const optionGroupsWithLimits = optionGroups.map(({ options, select: { select, maximum, enforceLimit } }) => ({
    const selectMaximum = (select as never as OptionGroupSelectUnionType) === 'single' ? 1 : maximum // remove type conversion after the above is fixed/implemented
    return { optionIds: options.map(option => option.id), count: (enforceLimit && selectMaximum) || Infinity }
  })
  const reversedValues = answer!.values.slice().reverse()
  const [resultingValues] = reversedValues.reduce(
    ([result, groups], answerValue) => {
      const id = answerValue.match(ANSWER_VALUE_MATCH)?.groups?.id as string
      const relevantGroup = groups.find(group => group.optionIds.includes(id))
      if (relevantGroup?.count) Object.assign(relevantGroup, { count: relevantGroup.count - 1 }) && result.push(answerValue)
      return [result, groups] as [string[], typeof optionGroupsWithLimits]
    },
    [[], optionGroupsWithLimits] as [string[], typeof optionGroupsWithLimits]
  )
  const valueLengthDiffers = answer!.values.length !== resultingValues.length
  if (!valueLengthDiffers) return answer
  return { id: answer.id, values: resultingValues.reverse() }
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ===================================================== GET FORMATTED ANSWER VALUE ===================================================== //
// ====================================================================================================================================== //
type FormatOptions = { dateFormat?: string; dateTimeFormat?: string }
export const getFormattedAnswerValue = (valueType: OptionValueTypeUnionType, value: string = '', options?: FormatOptions): string | number => {
  switch (valueType) {
    case 'date': {
      const luxonFromISO = DateTime.fromISO(value)
      if (!luxonFromISO?.isValid) return value
      if (options?.dateFormat) return luxonFromISO?.toFormat(options.dateFormat)
      return !luxonFromISO?.isValid ? value : luxonFromISO?.toFormat('dd.MM.yyyy')
    }
    case 'date-time': {
      const luxonFromISO = DateTime.fromISO(value)
      if (!luxonFromISO?.isValid) return value
      if (options?.dateFormat) return luxonFromISO?.toFormat(options.dateFormat)
      return !luxonFromISO?.isValid ? value : luxonFromISO?.toFormat('dd.MM.yyyy HH:mm')
    }
    case 'number':
      return Number(value)
    default:
      return value || ''
  }
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ======================================================= GET VALUES FROM ANSWER ======================================================= //
// ====================================================================================================================================== //
export const getOptionPropertiesFromAnswer = (answer: Answer): Record<string, OptionValueProperties> =>
  answer.values.reduce((result, valueString) => {
    const { id, value } = valueString.match(ANSWER_VALUE_MATCH)?.groups || {}
    const properties = extractPropertiesFromCustomText(value, 'optionValue')
    return Object.assign(result, { [id]: properties })
  }, {})
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================= GET OPTION VALUE FROM ANSWER BY ID ================================================= //
// ====================================================================================================================================== //
export const getOptionPropertiesFromAnswerById = (answer: Answer, id: string): OptionValueProperties => getOptionPropertiesFromAnswer(answer)[id]
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================== GET PARENT QUESTION ======================================================== //
// ===================================================================================================================================== //
// =================================================== GET PARENT QUESTION: OVERLOAD =================================================== //
// function getParentQuestion(state: WizardState, question: Question): Question | undefined
// function getParentQuestion(state: WizardState, questionId: string): Question | undefined
// ===================================================================================================================================== //
// function getParentQuestion(state: WizardState, questionParam: Question | string): Question | undefined {
//   const question = typeof questionParam === 'string' ? state.questions?.find(({ id }) => id === questionParam) : questionParam
//   const parentQuestionId = question?.advanced.subQuestionTo.match(SUB_QUESTION_TO_MATCH)?.groups?.questionId
//   return parentQuestionId ? state.questions?.find(({ id: questionId }) => questionId === parentQuestionId) : undefined
// }
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================ GENERATE DOWNSTREAM ANSWER RELEVANCE ================================================ //
// ====================================================================================================================================== //
const applyDownstreamAnswerRelevance = (
  relevance: AnswerRelevance,
  questions: Questions,
  answers: Answers,
  questionId: string,
  questionIds: string[] = []
) => {
  const subQuestions = questions.reduce((result, question) => {
    const { questionId: parentQuestionId, optionId } = question.advanced.subQuestionTo.match(SUB_QUESTION_TO_MATCH)?.groups || {}
    if (parentQuestionId === questionId) result.push([question.id, optionId])
    return result
  }, [] as [string, string][])
  const isAnswerRelevant = Boolean(relevance[questionId])
  subQuestions.forEach(([subQuestionId, optionId]) => {
    const newRelevance =
      isAnswerRelevant &&
      Boolean(answers.find(({ id }) => id === questionId)?.values.find(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id === optionId))
    const oldRelevance = relevance[subQuestionId]
    if (newRelevance === oldRelevance) return
    Object.assign(relevance, { [subQuestionId]: newRelevance })
    questionIds.push(subQuestionId)
    applyDownstreamAnswerRelevance(relevance, questions, answers, subQuestionId, questionIds)
  })
}
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ===================================================== GENERATE ANSWER RELEVANCE ===================================================== //
// ===================================================================================================================================== //
export const generateAnswerRelevance = (state: WizardState): AnswerRelevance =>
  (state.questions || []).reduce((result, question) => {
    if (!question.advanced.subQuestionTo) {
      Object.assign(result, { [question.id]: true })
      applyDownstreamAnswerRelevance(result, state.questions!, state.answers || [], question.id)
    }
    return result
  }, {} as AnswerRelevance)
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= GET COMPUTED VALUE ========================================================= //
// ====================================================================================================================================== //
const getComputedValue = (id: keyof typeof COMPUTED_VALUES): ValueSourceProperties & { id: string } =>
  Object.assign(extractPropertiesFromCustomText(COMPUTED_VALUES[id]?.value().match(VALUE_SOURCE_MATCH)?.groups?.value!, 'valueSource'), { id })
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= GET QUESTION VALUE ========================================================= //
// ====================================================================================================================================== //
const getExternalValue = (state: WizardState, ids: string): (ValueSourceProperties & { id: string }) | null | undefined => {
  const [integrationId, fieldId] = ids.split(':')
  const relevantField = state.integrations && state.integrations[integrationId]?.fields?.find(({ id }) => id === fieldId)
  return relevantField && { id: ids, type: relevantField?.type, value: relevantField.value }
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= GET QUESTION VALUE ========================================================= //
// ====================================================================================================================================== //
const getQuestionValue = (state: WizardState, id: string): (ValueSourceProperties & { id: string })[] | undefined => {
  const answer = getAnswerById(state, id)[0]
  return (
    answer &&
    Object.entries(getOptionPropertiesFromAnswer(answer)).map(([optionId, properties]) => Object.assign(properties, { id: `${id}:${optionId}` }))
  )
}
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= EVALUATE KNOWN MARKER ======================================================= //
// ===================================================================================================================================== //
const evaluateKnownMarker = <T extends SegmentsLocation | TextLocation>(
  state: WizardState,
  marker: T,
  markerArray: T[],
  index: number,
  evaluateNumbering?: boolean,
  update?: never
): WizardState => {
  // ======================================================================================================= //
  // ============================================ VALUE SOURCES ============================================ //
  // ======================================================================================================= //
  const values = (marker.valueSources || []).reduce((resultingValues, source) => {
    const { type, id } = source.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
    let resultingValue
    if (type === 'question') {
      const questionValue = state.answerRelevance && state.answerRelevance[id] && getQuestionValue(state, id)
      if (questionValue) resultingValue = questionValue
    }
    if (type === 'external') {
      const externalValue = getExternalValue(state, id)
      if (externalValue?.value) resultingValue = externalValue
    }
    if (type === 'computed') resultingValue = getComputedValue(id as keyof typeof COMPUTED_VALUES)
    if (!resultingValue) return resultingValues
    return resultingValues.concat(resultingValue)
  }, [] as (ValueSourceProperties & { id: string })[])

  let replace = undefined

  if (marker.combine) {
    const parsedCalculation = parseCalculation(marker?.calculation || '')
    const references = (marker.valueSources || []).map(source => {
      const { id: sourceId } = source.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
      const relevantValues = values.filter(({ id }) => id.startsWith(sourceId)).map(({ value }) => Number(value))
      // const relevantValues = values.filter(({ id }) => id.startsWith(sourceId)).map(({ type, value }) => (type === 'number' ? Number(value) : NaN))
      if (!relevantValues.length) relevantValues.push(NaN)
      return relevantValues
    })
    replace = calculate(parsedCalculation, references)
      .map(value => `{{${CASUS_KEYSTRINGS.REPLACE} type="number" value="${value}" }}`)
      .join('')
  } else
    replace = values.length
      ? values
          .map(({ type, value }) => `{{${CASUS_KEYSTRINGS.REPLACE} type="${type}" value="${(marker.valueMap || {})[value!] ?? value}" }}`)
          .join('')
      : undefined
  // ======================================================================================================= //
  //
  //
  //
  // ====================================================================================================== //
  // ============================================ CONDITIONALS ============================================ //
  // ====================================================================================================== //
  const languages =
    !marker.conditionals?.languages?.length ||
    (marker.defaultKeep
      ? !state.languages?.selected?.length || marker.conditionals?.languages.some(language => state.languages!.selected!.includes(language))
      : marker.conditionals?.languages.every(language => state.languages?.selected?.includes(language)))
  const split = !marker.conditionals?.splits?.length || !state.activeSplit || marker.conditionals?.splits.some(split => split === state.activeSplit)
  // ====================================================================================================== //

  const allValues =
    state.answers?.reduce(
      (result, { id, values }) => (state.answerRelevance && state.answerRelevance[id] ? result.concat(values) : result),
      [] as string[]
    ) || []
  const keepValues = allValues.filter(string => (marker?.optionIds || []).includes(string.match(ANSWER_VALUE_MATCH)?.groups?.id!))
  const keep =
    (marker.defaultKeep ? !(marker?.optionIds || []).length || Boolean(keepValues.length) : keepValues.length === (marker?.optionIds || []).length) &&
    languages &&
    split
  // const answer = getAnswerById(state, marker.questionId)[0]

  // ======================================================================================================= //
  // ============================= TEMPORARY STATIC CALCULATION IMPLEMENTATION ============================= //
  // ======================================================================================================= //
  // type ValueSource = { [K in string]: { type: string; value: string } }
  // const isAnswerExternal = marker.questionId?.slice(0, 10) === '_external_'
  // const valueSources = [
  //   (isAnswerExternal || (state.answerRelevance && state.answerRelevance[marker.questionId])) && answer && getOptionPropertiesFromAnswer(answer),
  // ].filter(s => s) as ValueSource[]
  // console.log('VALUE SOURCES: ', valueSources)
  // const markerValues = valueSources.reduce(
  //   (resultingValues, source) =>
  //     resultingValues.concat(
  //       Object.values(source).map(({ type, value }) => {
  //         //@ts-ignore
  //         const modifiedValue = (marker.modifiers || []).reduce((resultingValue, modifier) => {
  //           if (modifier.type !== type) return resultingValue
  //           return STATIC_CALCULATION_FUNCTIONS[modifier.fn](resultingValue, modifier.value)
  //         }, value)
  //         return `{{${CASUS_KEYSTRINGS.REPLACE} type="${type}" value="${modifiedValue}" }}`
  //       })
  //     ),
  //   [] as string[]
  // )
  // const replace = markerValues.length ? markerValues.join('') : undefined
  // ======================================================================================================= //

  // const replace =
  //   state.answerRelevance && state.answerRelevance[marker.questionId] && answer
  //     ? Object.values(getOptionPropertiesFromAnswer(answer))
  //         .map(
  //           properties =>
  //             `{{${CASUS_KEYSTRINGS.REPLACE} ${Object.entries(properties)
  //               .reduce((propertyString, [key, value]) => propertyString.concat(`${key}="${value}"`), [] as string[])
  //               .join(' ')}}}`
  //         )
  //         .join('')
  //     : undefined

  const resultingMarker = Object.assign({}, marker)
  update = ((keep !== marker.keep && Object.assign(resultingMarker, { keep })) as never) || update
  update = ((replace !== marker.replace && Object.assign(resultingMarker, { replace })) as never) || update
  if (update) {
    markerArray.splice(index, 1, resultingMarker)
    if (evaluateNumbering) applyParagraphNumbering(state) // FOR TESTING
    // if (marker.type === LOCATION_TYPES.SEGMENTS && evaluateNumbering) applyParagraphNumbering(state)
    return Object.assign({}, state)
  }
  return state
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= EVALUATE MARKER BY ID ======================================================= //
// ===================================================================================================================================== //
const evaluateMarkerById = (state: WizardState, markerId: string, evaluateNumbering?: boolean, update?: never): WizardState => {
  const markerById = getMarkerById(state, markerId)
  return markerById[0] ? evaluateKnownMarker(state, markerById[0], markerById[2], markerById[3], evaluateNumbering, update) : state
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ========================================================== EVALUATE MARKER ========================================================== //
// ===================================================================================================================================== //
// ===================================================== EVALUATE MARKER: OVERLOAD ===================================================== //
function evaluateMarker(state: WizardState, marker: FoundMarkerType, evaluateNumbering?: boolean, update?: never): WizardState
function evaluateMarker(state: WizardState, markerId: string, evaluateNumbering?: boolean, update?: never): WizardState
// ===================================================================================================================================== //
function evaluateMarker(state: WizardState, arg2: FoundMarkerType | string, evaluateNumbering: boolean = true, update = false as never): WizardState {
  if (typeof arg2 === 'string') return evaluateMarkerById(state, arg2, evaluateNumbering)
  if (Array.isArray(arg2) && arg2[0]) return evaluateKnownMarker(state, arg2[0], arg2[2], arg2[3], evaluateNumbering, update)
  return state
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= UPDATE QUESTION ORDER ======================================================= //
// ===================================================================================================================================== //
export const updateQuestionOrder = (state: WizardState) => {
  const { questions, questionLayout, answerRelevance } = state
  if (!(questions && questionLayout && answerRelevance && isModeDocumentFlow(state.mode))) return state
  const questionOrder = generateQuestionOrder(
    questionLayout,
    questions,
    answerRelevance,
    Boolean(state.showExternalQuestions),
    Boolean(state.showInternalQuestions)
  )
  if (questionOrder.join('') === state.questionOrder?.join('')) return state
  const payload = { questionOrder }
  if (!state.answering || !questionOrder.includes(state.answering)) Object.assign(payload, { answering: questionOrder[0] })
  return updateWizardState(state, payload)
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ================================================== GET MARKER IDS BY VALUE SOURCES ================================================== //
// ===================================================================================================================================== //
const getMarkerIdsByValueSources = (state: WizardState, valueSources: string[]) =>
  Object.values(Object.assign({}, state.locations?.segments, state.locations?.text))
    .flat(1)
    .reduce(
      (result, marker) => (marker.valueSources?.find(source => valueSources.includes(source)) ? result.concat(marker.id) : result),
      [] as string[]
    )
// ===================================================================================================================================== //
//
//
//
//
//
//
//
//
//
//
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ========================================================= REDUCER FUNCTIONS ========================================================= //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //

export type ToggleDocumentLanguagePayload = LanguageValue
const toggleDocumentLanguage = (state: WizardState, payload: ToggleDocumentLanguagePayload) => {
  const select = state.languages?.select || 'single'
  const languageSlice = state.languages?.selected?.slice() || []
  const index = languageSlice.indexOf(payload)
  if (index === -1) languageSlice.push(payload)
  else languageSlice.splice(index, 1)
  const resultingSelectedLanguages = select === 'multi' ? languageSlice : (index !== -1 && []) || [payload]
  const resultingLanguages = Object.assign({}, state.languages, { selected: resultingSelectedLanguages })

  const languageDifference = select === 'single' ? (state.languages?.selected || []).concat(payload) : [payload]
  const relevantMarkerIds = Object.values(Object.assign({}, state.locations?.segments, state.locations?.text))
    .flat(1)
    .reduce(
      (result, { id, conditionals }) =>
        languageDifference.some(language => conditionals?.languages?.includes(language)) ? result.concat(id) : result,
      [] as string[]
    )
  relevantMarkerIds.reduce((previousState, id) => evaluateMarker(previousState, id), state)
  return updateWizardState(state, { languages: resultingLanguages })
}

export type ChooseIntegrationEntryPayload = { integrationId: string; entryId: string }
const chooseIntegrationEntry = (state: WizardState, payload: ChooseIntegrationEntryPayload) => {
  if (!(payload.integrationId && payload.entryId)) return state
  const resultingIntegrationEntries = Object.assign({}, state.integrationEntries, { [payload.integrationId]: payload.entryId })
  return updateWizardState(state, { integrationEntries: resultingIntegrationEntries })
}

export type UpdateIntegrationFieldValuesPayload = Record<string, Record<string, string>>
const updateIntegrationFieldValues = (state: WizardState, payload: UpdateIntegrationFieldValuesPayload) => {
  const resultingIntegrations = Object.entries(payload).reduce((result, [integrationId, fields]) => {
    const integration = result[integrationId] || {}
    const resultingFields = integration.fields?.slice().map(field => Object.assign({}, field, { value: fields[field.id] }))
    const resultingIntegration = Object.assign({}, integration, { fields: resultingFields })
    return Object.assign(result, { [integrationId]: resultingIntegration })
  }, Object.assign({}, state.integrations))
  const changedFields = Object.entries(payload).reduce(
    (result, [integrationId, fields]) =>
      result.concat(
        Object.entries(fields).reduce(
          (result, [fieldId, fieldValue]) =>
            state.integrations && state.integrations[integrationId]?.fields?.find(({ id }) => id === fieldId)?.value !== fieldValue
              ? result.concat(`integration:${integrationId}:${fieldId}`)
              : result,
          [] as string[]
        )
      ),
    [] as string[]
  )
  const relevantMarkerIds = getMarkerIdsByValueSources(state, changedFields)
  relevantMarkerIds.reduce((previousState, id) => evaluateMarker(previousState, id), state)
  return updateWizardState(state, { integrations: resultingIntegrations })
}

const resetIntegrationFieldValues = (state: WizardState) => {
  const resultingIntegrations = Object.entries(state.integrations || {}).reduce((result, [integrationId, integration]) => {
    const resultingFields = integration.fields?.map(field => {
      const res = Object.assign({}, field)
      delete res.value
      return res
    })
    const resultingIntegration = Object.assign({}, integration, { fields: resultingFields })
    return Object.assign(result, { [integrationId]: resultingIntegration })
  }, {})
  const changedFields = Object.entries(state.integrations || {}).reduce(
    (result, [integrationId, { fields }]) => result.concat(fields.map(({ id: fieldId }) => `integration:${integrationId}:${fieldId}`)),
    [] as string[]
  )
  const relevantMarkerIds = getMarkerIdsByValueSources(state, changedFields)
  relevantMarkerIds.reduce((previousState, id) => evaluateMarker(previousState, id), state)
  return updateWizardState(state, { integrations: resultingIntegrations })
}

const toggleShowExternalQuestions = (state: WizardState): WizardState =>
  updateWizardState(state, { showExternalQuestions: !state.showExternalQuestions }) as WizardState

const navigateQuestionnaireForward = (state: WizardState): WizardState => {
  const { questionOrder, answering } = state
  if (!questionOrder?.length) return state
  if (!answering) return updateWizardState(state, { answering: questionOrder[0] })
  const fullOrder = ['redirect-backward'].concat(questionOrder).concat('redirect-forward')
  // const fullOrder = questionOrder.concat(Object.values(QUESTIONNAIRE_POST_STEPS))
  // fullOrder.unshift(...Object.values(QUESTIONNAIRE_PRE_STEPS).slice().reverse())
  const index = fullOrder.findIndex(
    orderString =>
      orderString.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id === answering.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id
  )
  const resultingAnswering = fullOrder[index + 1]
  return Object.values(QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS).reduce(
    (result, { conditional, method }) =>
      conditional(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS, answering)
        ? method(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS)
        : result,
    updateWizardState(state, { answering: resultingAnswering || 'redirect-forward' }) as WizardState
  )
}

const navigateQuestionnaireBackward = (state: WizardState): WizardState => {
  const { questionOrder, answering } = state
  if (!questionOrder?.length) return state
  if (!answering) return updateWizardState(state, { answering: questionOrder[0] })
  const fullOrder = ['redirect-backward'].concat(questionOrder).concat('redirect-forward')
  // const fullOrder = questionOrder.concat(Object.values(QUESTIONNAIRE_POST_STEPS))
  // fullOrder.unshift(...Object.values(QUESTIONNAIRE_PRE_STEPS).slice().reverse())
  const index = fullOrder.findIndex(
    orderString =>
      orderString.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id === answering.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id
  )
  const resultingAnswering = fullOrder[index - 1]
  return Object.values(QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS).reduce(
    (result, { conditional, method }) =>
      conditional(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.BACKWARDS, answering)
        ? method(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.BACKWARDS)
        : result,
    updateWizardState(state, { answering: resultingAnswering || 'redirect-backward' }) as WizardState
  )
}

export type NavigateQuestionnaireToPayload = string
const navigateQuestionnaireTo = (state: WizardState, payload: NavigateQuestionnaireToPayload): WizardState => {
  const { questionLayoutGroupId, questionId, orderString } = payload.match(ANSWERING_IDS_MATCH)?.groups || {}
  if (!questionId || state.answering === payload) return state
  if (questionLayoutGroupId && orderString) return updateWizardState(state, { answering: payload })
  const answeringString = state.questionOrder?.find(orderString => orderString.split(':')[1] === questionId)
  return updateWizardState(state, { answering: answeringString })
}

export type AnswerWithOptionPayload = { questionId: string; value: string }
const answerWithOption = (state: WizardState, payload: AnswerWithOptionPayload): WizardState => {
  const { questionId, value } = payload
  const [answer, index] = getAnswerById(state, questionId)
  const { id: optionId, value: optionValue } = value?.match(ANSWER_VALUE_MATCH)?.groups || {}
  if (!optionId) return state
  const [values, shift, update] = (answer?.values.slice().reverse() || []).reduce(
    ([result, optionFound, valuesDiffer], existingAnswerValue) => {
      const { id: existingAnswerOptionId, value: existingAnswerOptionValue } = existingAnswerValue.match(ANSWER_VALUE_MATCH)?.groups || {}
      const sameOptionId = existingAnswerOptionId === optionId
      const sameOptionValue = existingAnswerOptionValue === optionValue
      if (optionFound || !sameOptionId || sameOptionValue) result.push(existingAnswerValue)
      else result.push(value)
      return [result, optionFound || sameOptionId, valuesDiffer || !sameOptionValue]
    },
    [[value], false, false]
  )
  if (shift) {
    // ================================= if the option was already in the answer (previously selected):       shift   === true
    if (!update) return state // ======= and the value of the already-in option is the same in the payload:   update  === false    => just don't update => return state
    values.shift() // ================== otherwise shift the prepended value
  }
  const resultingValues = values.reverse()
  const resultingAnswer = tidyAnswer(state, generateAnswer(Object.assign({ id: questionId, values: resultingValues })))
  const previouslySelectedOptionIds = answer?.values.map(value => value?.match(ANSWER_VALUE_MATCH)?.groups?.id).filter(id => id)
  const resultingSelectedOptionIds = resultingAnswer.values.map(value => value?.match(ANSWER_VALUE_MATCH)?.groups?.id).filter(id => id)
  const resultingOptionIdDifference = previouslySelectedOptionIds?.filter(id => !resultingSelectedOptionIds.includes(id))

  if (index === -1) updateWizardState(state, { answers: (state.answers || []).concat(resultingAnswer) })
  else state.answers!.splice(index, 1, resultingAnswer)

  const [question] = getQuestionById(state, payload.questionId)
  const relevantOptionIds = (resultingOptionIdDifference || []).concat(optionId)

  const relevantReplacementMarkerIds = getMarkerIdsByValueSources(state, [`question:${payload.questionId}`])

  const relevantMarkerIds = Array.from(
    new Set(
      question?.optionGroups
        .reduce((resultingOptions, optionGroup) => resultingOptions.concat(optionGroup.options), [] as Options)
        .reduce(
          (resultingMarkerIds, option) => resultingMarkerIds.concat(relevantOptionIds.includes(option.id) ? option.markers : []),
          relevantReplacementMarkerIds
        )
    )
  )

  const markerIgnoreList = [] as string[]
  const subQuestions = getSubQuestionsByQuestionId(state, questionId)
  if (subQuestions.length) {
    const resultingRelevance = Object.assign({}, state.answerRelevance)
    const changedRelevance = [] as string[]
    applyDownstreamAnswerRelevance(resultingRelevance, state.questions!, state.answers!, questionId, changedRelevance)
    Object.keys(resultingRelevance).forEach(key => {
      if (!changedRelevance.includes(key)) delete resultingRelevance[key]
    })
    updateAnswerRelevance(state, resultingRelevance, markerIgnoreList as never)
    updateQuestionOrder(state)
  }
  relevantMarkerIds.reduce((previousState, id) => (!markerIgnoreList.includes(id) ? evaluateMarker(state, id) : previousState), state)
  return Object.assign({}, state)
}

export type UnanswerOptionPayload = { id: string; questionId: string }
const unanswerOption = (state: WizardState, payload: UnanswerOptionPayload): WizardState => {
  const { id, questionId } = payload
  const [answer, index] = getAnswerById(state, questionId)
  if (index === -1) return state
  const resultingValues = answer!.values.filter(answerValue => answerValue.match(ANSWER_VALUE_MATCH)?.groups?.id !== id)
  if (answer!.values.length === resultingValues.length) return state
  state.answers!.splice(index, 1, { id: questionId, values: resultingValues })
  const [question] = getQuestionById(state, payload?.questionId)
  const option = (question
    ? extractFromNestedStructure(question, ['optionGroups', 'options'], option => option.id === id)
    : [])[0] as unknown as Option

  const relevantReplacementMarkerIds = getMarkerIdsByValueSources(state, [`question:${payload.questionId}`])

  const relevantMarkerIds = relevantReplacementMarkerIds.concat(option?.markers || [])

  const markerIgnoreList = [] as string[]
  const subQuestions = getSubQuestionsByQuestionId(state, questionId)
  if (subQuestions.length) {
    const resultingRelevance = Object.assign({}, state.answerRelevance)
    const changedRelevance = [] as string[]
    applyDownstreamAnswerRelevance(resultingRelevance, state.questions!, state.answers!, questionId, changedRelevance)
    Object.keys(resultingRelevance).forEach(key => {
      if (!changedRelevance.includes(key)) delete resultingRelevance[key]
    })
    updateAnswerRelevance(state, resultingRelevance, markerIgnoreList as never)
    updateQuestionOrder(state)
  }
  relevantMarkerIds.reduce((previousState, id) => (!markerIgnoreList.includes(id) ? evaluateMarker(state, id) : previousState), state)
  return Object.assign({}, state)
}

const evaluateMarkers = (state: WizardState, evaluateNumbering: boolean = true): WizardState => {
  if (!isModeDocumentFlow(state.mode)) return state
  const markers = Object.entries(Object.assign({}, state.locations?.segments, state.locations?.text)).reduce(
    (result, [parentId, markerArray]) => result.concat(markerArray.map((marker, index) => [marker, parentId, markerArray, index])),
    [] as FoundMarkerType[]
  )
  return markers.reduce((currentState, marker) => evaluateMarker(currentState, marker, evaluateNumbering), state)
}

const initializeAnswerRelevance = (state: WizardState): WizardState =>
  fullAssign({}, state, { answerRelevance: Object.assign(state.answerRelevance || {}, generateAnswerRelevance(state)) }) as WizardState

const updateAnswerRelevance = (state: WizardState, payload?: SubRecord<AnswerRelevance>, markerIgnoreList = [] as never) => {
  const { questions } = state
  if (!questions) return state
  const answerRelevance = Object.assign({}, state.answerRelevance || generateAnswerRelevance(state), payload || {})
  const resultingState = updateWizardState(state, { answerRelevance })
  if (payload) {
    const changedRelevance = Object.keys(payload)
    const relevantMarkerIds = changedRelevance.reduce((result, id) => {
      const question = getQuestionById(state, id)[0]!
      const answer = getAnswerById(state, id)[0]
      const optionIds = Object.keys(answer ? getOptionPropertiesFromAnswer(answer) : {})
      const optionMarkers = Array.from(
        new Set(
          question.optionGroups.reduce(
            (accumulated, optionGroup) =>
              optionGroup.options.reduce(
                (resultingOptions, option) => (optionIds.includes(option.id) ? accumulated.concat(option.markers) : resultingOptions),
                accumulated
              ),
            [] as string[]
          )
        )
      )
      return result.concat(getMarkerIdsByValueSources(state, [`question:${id}`]) || []).concat(optionMarkers)
    }, [] as string[])
    relevantMarkerIds.reduce((_, id) => evaluateMarker(_, id), resultingState)
    ;(markerIgnoreList as string[]).push(...relevantMarkerIds)
  }
  if (state.answerRelevance && !Object.entries(answerRelevance).some(([id, isRelevant]) => state.answerRelevance![id] !== isRelevant)) return state
  return resultingState
}

export type ActivateSplitPayload = string
const activateSplit = (state: WizardState, payload: ActivateSplitPayload) => {
  if (state.activeSplit === payload) return state
  const relevantMarkerIds = Object.values(Object.assign({}, state.locations?.segments, state.locations?.text))
    .flat(1)
    .reduce((result, { id, conditionals }) => {
      const relevantToPrevious = conditionals?.splits?.includes(state.activeSplit as string)
      const relevantToNext = conditionals?.splits?.includes(payload)
      return (relevantToPrevious && relevantToNext) !== (relevantToPrevious || relevantToNext) ? result.concat(id) : result
    }, [] as string[])
  relevantMarkerIds.reduce((previousState, id) => evaluateMarker(previousState, id), state)
  return updateWizardState(state, { activeSplit: payload })
}

export {
  toggleDocumentLanguage,
  chooseIntegrationEntry,
  updateIntegrationFieldValues,
  resetIntegrationFieldValues,
  toggleShowExternalQuestions,
  navigateQuestionnaireForward,
  navigateQuestionnaireBackward,
  navigateQuestionnaireTo,
  answerWithOption,
  unanswerOption,
  evaluateMarkers,
  initializeAnswerRelevance,
  updateAnswerRelevance,
  activateSplit,
}
