import React, { useMemo } from 'react'
import useStore from '___store'

import { CASUS_CLASSES } from 'Wizard/constants'
import { classes } from '../..'

export const Paragraph = React.memo(({ id, customStyle, styles, text }) => {
  //   console.log(id, customStyle, styles, text)
  const { numberingById: numbering } = useStore(`selectNumberingById[${id}]`)

  const [numberingSystem, numberingValue] = useMemo(() => (numbering || '').split(':'), [numbering])

  const paragraphClass = [CASUS_CLASSES.paragraphSegment, classes.segment.paragraph.wrapper, customStyle].concat(styles).join(' ')
  return (
    <pre id={id} className={paragraphClass} style={{ counterSet: numberingSystem && `${numberingSystem} ${numberingValue}` }}>
      {text}
    </pre>
  )
})

Paragraph.displayName = 'Wizard-Editor-SegmentsMarker-Paragraph'

export default Paragraph
