import React, { FunctionComponent, useMemo } from 'react'

import { DOCUMENT_STATUSES } from '___types'
import { Draft } from './Progress.components'
import { MainLayoutRightPaneDocumentProgressProps, mainLayoutRightPaneDocumentProgressClasses as classes } from '../../../..'

const stages = Object.values(DOCUMENT_STATUSES)
const draftIndex = stages.indexOf(DOCUMENT_STATUSES.DRAFT)
// const finalIndex = stages.indexOf(DOCUMENT_STATUSES.FINAL)
// const approvalIndex = stages.indexOf(DOCUMENT_STATUSES.PENDING_APPROVAL)
// const signatureIndex = stages.indexOf(DOCUMENT_STATUSES.PENDING_SIGNATURE)

export const Progress: FunctionComponent<MainLayoutRightPaneDocumentProgressProps> = React.memo(
  ({ id, status, progress, publishing, publishingCallback }) => {
    const currentStageIndex = stages.indexOf(status!)
    const draftRender = useMemo(
      () =>
        currentStageIndex >= draftIndex ? (
          <Draft
            id={id}
            active={currentStageIndex === draftIndex}
            progress={progress}
            publishing={publishing}
            publishingCallback={publishingCallback}
          />
        ) : null,
      [id, currentStageIndex, progress, publishing, publishingCallback]
    )
    // const finalRender = useMemo(
    //   () =>
    //     currentStageIndex >= finalIndex ? (
    //       <Draft
    //         id={id}
    //         active={currentStageIndex === finalIndex}
    //         publishing={publishing}
    //         publishingCallback={publishingCallback}
    //         progress={progress}
    //       />
    //     ) : null,
    //   [currentStageIndex, id, publishing, publishingCallback]
    // )
    // const approvalRender = useMemo(
    //   () =>
    //     currentStageIndex >= approvalIndex ? (
    //       <Draft
    //         id={id}
    //         active={currentStageIndex === approvalIndex}
    //         publishing={publishing}
    //         publishingCallback={publishingCallback}
    //         progress={{ public: { questions: 15, answered: 12 }, private: { questions: 7, answered: 3 } }}
    //       />
    //     ) : null,
    //   [currentStageIndex, id, publishing, publishingCallback]
    // )
    // const signatureRender = useMemo(
    //   () =>
    //     currentStageIndex >= signatureIndex ? (
    //       <Draft
    //         id={id}
    //         active={currentStageIndex === signatureIndex}
    //         publishing={publishing}
    //         publishingCallback={publishingCallback}
    //         progress={{ public: { questions: 15, answered: 12 }, private: { questions: 7, answered: 3 } }}
    //       />
    //     ) : null,
    //   [currentStageIndex, id, publishing, publishingCallback]
    // )

    return (
      <div className={classes.wrapper}>
        {draftRender}
        {/* {finalRender}
        {approvalRender}
        {signatureRender} */}
      </div>
    )
  }
)

Progress.displayName = 'MainLayout-RightPane-Document-Progress'

export default Progress
