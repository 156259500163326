import React from 'react'
import { SidebarContainer, Container } from './Sidebar.styles'
import { SettingsSidebarProps } from './Sidebar.types'
import SidebarLink from './SidebarLink'

const SettingsSidebar: React.FC<SettingsSidebarProps> = ({
  children,
  urlLinks,
  baseUrl,
}) => {
  return (
    <SidebarContainer variant='main'>
      <Container sidebarvariant='main'>
        {urlLinks &&
          urlLinks.map(({ url, urlName }) => (
            <SidebarLink
              key={urlName}
              url={baseUrl + url}
              urlName={urlName}
              variant='main'
            />
          ))}
        {children}
      </Container>
    </SidebarContainer>
  )
}

export default SettingsSidebar
