import React from 'react'

import { className } from './index'

const TextLoader = React.memo(() => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g>
      <circle fill="#1793d1" r="10" cy="50" cx="84">
        <animate
          begin="0s"
          keySplines="0 0.5 0.5 1"
          values="10;0"
          keyTimes="0;1"
          calcMode="spline"
          dur="0.25s"
          repeatCount="indefinite"
          attributeName="r"
        ></animate>
        <animate
          begin="0s"
          values="#1793d1;#333333;#1793d1;#333333;#1793d1"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="discrete"
          dur="1s"
          repeatCount="indefinite"
          attributeName="fill"
        ></animate>
      </circle>
      <circle fill="#1793d1" r="10" cy="50" cx="16">
        <animate
          begin="0s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          values="0;0;10;10;10"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="spline"
          dur="1s"
          repeatCount="indefinite"
          attributeName="r"
        ></animate>
        <animate
          begin="0s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          values="16;16;16;50;84"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="spline"
          dur="1s"
          repeatCount="indefinite"
          attributeName="cx"
        ></animate>
      </circle>
      <circle fill="#333333" r="10" cy="50" cx="50">
        <animate
          begin="-0.25s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          values="0;0;10;10;10"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="spline"
          dur="1s"
          repeatCount="indefinite"
          attributeName="r"
        ></animate>
        <animate
          begin="-0.25s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          values="16;16;16;50;84"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="spline"
          dur="1s"
          repeatCount="indefinite"
          attributeName="cx"
        ></animate>
      </circle>
      <circle fill="#1793d1" r="10" cy="50" cx="84">
        <animate
          begin="-0.5s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          values="0;0;10;10;10"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="spline"
          dur="1s"
          repeatCount="indefinite"
          attributeName="r"
        ></animate>
        <animate
          begin="-0.5s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          values="16;16;16;50;84"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="spline"
          dur="1s"
          repeatCount="indefinite"
          attributeName="cx"
        ></animate>
      </circle>
      <circle fill="#333333" r="10" cy="50" cx="16">
        <animate
          begin="-0.75s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          values="0;0;10;10;10"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="spline"
          dur="1s"
          repeatCount="indefinite"
          attributeName="r"
        ></animate>
        <animate
          begin="-0.75s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          values="16;16;16;50;84"
          keyTimes="0;0.25;0.5;0.75;1"
          calcMode="spline"
          dur="1s"
          repeatCount="indefinite"
          attributeName="cx"
        ></animate>
      </circle>
      <g></g>
    </g>
  </svg>
))

export default TextLoader

