import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { WizardLayoutLeftPaneDocumentReviewSkippedQuestionsProps, wizardLayoutLeftPaneDocumentReviewClasses as classes } from 'Layouts/WizardLayout'

const SkippedQuestions: FunctionComponent<WizardLayoutLeftPaneDocumentReviewSkippedQuestionsProps> = React.memo(({ questionCount }) => {
  const { t: translate } = useTranslation()

  const reviewMessage = useMemo(() => {
    const interpolationKeys = {
      count: questionCount,
      object: translate(`general.question.${questionCount === 1 ? 'singular' : 'plural'}`),
      'modifier-verb': translate(`general.to-be.${questionCount === 1 ? 'singular' : 'plural'}.3rd`),
    }
    return translate('wizard.document-flow.review.skipped-message', interpolationKeys)
  }, [questionCount, translate])

  return (
    <div className={classes.message.wrapper}>
      <span className={classes.message.primary}>{reviewMessage}</span>
      <span className={classes.message.secondary}>{translate(`wizard.document-flow.review.skipped-guide`)}</span>
    </div>
  )
})

SkippedQuestions.displayName = 'WizardLayout-LeftPane-Document-Review-SkippedQuestions'

export default SkippedQuestions
