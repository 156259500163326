import React, { useMemo } from 'react'
import { DateTime } from 'luxon'
import useStore from '___store'

import { Cogwheel, Deselect } from 'assets/svgIconComponents'
import { Button, ButtonGroup, Input } from 'components'
import { CASUS_CLASSES, CASUS_REGEXES } from 'Wizard/constants'
import Segment from '../Segment'
import { classes } from '../..'
import { mapContentIds } from '..'
// import MarkerAssignSelect from './MarkerAssignSelect'
import Paragraph from './SegmentMarker.Paragraph'

const SegmentsMarkerHeader = React.memo(
  ({
    id,
    label,
    // currentQuestionId, assignedQuestionId, assignedOptionIds
  }) => {
    const { setConfiguring, updateSegmentsMarker, unwrapMarker } = useStore('setConfiguring', 'assignMarker', 'updateSegmentsMarker', 'unwrapMarker')

    const { header: headerClass, buttonGroup: buttonGroupClass, button: buttonClass } = classes.marker.segments
    const headerClassName = [headerClass, CASUS_CLASSES.segmentsMarkerHeader].join(' ')

    return (
      <div className={headerClassName}>
        <ButtonGroup className={buttonGroupClass} collapsed>
          {/* <MarkerAssignSelect
          key={currentQuestionId}
          markerId={id}
          assignedQuestionId={assignedQuestionId}
          currentQuestionId={currentQuestionId}
          assignedOptionIds={assignedOptionIds}
        /> */}
          <Input
            placeholder={'Marker label...'}
            defaultValue={label || id}
            needsConfirmation
            onChange={value => updateSegmentsMarker({ id, label: value })}
            blurShowUnmodified
          />
          <Button className={buttonClass} tabbable={false} onClick={() => setConfiguring({ key: 'marker', id })}>
            <Cogwheel />
          </Button>
          <Button className={buttonClass} tabbable={false} onClick={() => unwrapMarker({ id })}>
            <Deselect />
          </Button>
        </ButtonGroup>
      </div>
    )
  }
)

SegmentsMarkerHeader.displayName = 'Wizard-Editor-SegmentsMarker-SegmentMarkerHeader'

export const SegmentsMarker = React.memo(({ id, parent }) => {
  const {
    locationById: {
      label,
      questionId,
      optionIds = [],
      defaultKeep,
      keep,
      replace,
      contentCustomStyle,
      contentStyles = [],
      // color
    } = {},
    currentConfiguringQuestion: { id: currentQuestionId } = {},
    markerContentIds,
    mode,
  } = useStore(`selectLocationById[${id}]`, 'selectCurrentConfiguringQuestion', `selectMarkerContentIds[${id},${parent}]`, 'selectMode')

  const [start, contentIds, end] = useMemo(() => {
    const match = markerContentIds.match(/(^[:]?)((([^:;\s]+(; )?)|(marker:[^:]+;?))+)([:]?$)/)?.slice(1) || []
    return match.splice(2, 4) && [Boolean(match[0]), match[1]?.split('; ').filter(id => id), Boolean(match[2])]
  }, [markerContentIds])

  const replaceSegments = useMemo(() => {
    if (!(start && replace)) return null
    const replaceSegmentTexts =
      replace
        ?.split(CASUS_REGEXES.customTextSplit)
        .filter(s => s)
        .map(string => (Array.from(string.matchAll(CASUS_REGEXES.markerReplaceMatchAll)) || [])[0]?.groups?.value) || []
    // const replaceSegmentTexts = replace.split('; ').filter(s => s)
    // const segmentClass = [CASUS_CLASSES.segmentDiv, classes.segment.wrapper, contentCustomStyle].join(' ')
    // const paragraphClass = [CASUS_CLASSES.paragraphSegment, classes.segment.paragraph.wrapper, contentCustomStyle].concat(contentStyles).join(' ')
    return replaceSegmentTexts.map((text, i) => {
      const luxonFromISO = DateTime.fromISO(text)
      const formattedText = luxonFromISO?.invalid ? text : luxonFromISO?.toLocaleString()
      return (
        <Paragraph
          key={`SegmentsMarker-${id}-list-paragraph-${i}`}
          id={`${id}-${i}`}
          customStyle={contentCustomStyle}
          styles={contentStyles}
          text={formattedText}
        />
        // <div key={`SegmentsMarker-${id}-list-paragraph-${i}`} className={segmentClass}>
        // <pre key={`SegmentsMarker-${id}-list-paragraph-${i}`} className={paragraphClass}>
        //   {formattedText}
        // </pre>
        // </div>
      )
    })
  }, [start, replace, id, contentCustomStyle, contentStyles])

  const markerMode = mode === 'template-creation' ? 'edit' : 'preview'

  const segments = useMemo(
    () =>
      mapContentIds(contentIds).map(({ type, id }) =>
        type === 'segment' ? <Segment key={id} id={id} /> : <SegmentsMarker key={id} id={id} parent={parent} />
      ),
    [contentIds, parent]
  )

  // console.groupCollapsed(`CHOICE MARKER %c${id}`, 'color: lightgreen;', 'RENDER!')
  // console.log(contentIds)
  // console.groupEnd()

  const markerClassName = [classes.marker.segments.wrapper, CASUS_CLASSES.segmentsMarkerDiv].join(' ')
  const contentClassName = [classes.marker.segments.content, CASUS_CLASSES.segmentsMarkerContent].join(' ')

  const availability = (currentQuestionId && ((!questionId && 'available') || (questionId !== currentQuestionId && 'unavailable'))) || undefined

  return (
    <div
      id={`${parent}/${id}:${start ? 's' : ''}${end ? 'e' : ''}`}
      className={markerClassName}
      data-mode={markerMode}
      data-start={start}
      data-end={end}
      data-availability={availability}
      data-remove={markerMode === 'preview' ? !(keep === undefined ? defaultKeep : keep) : undefined}
      // style={{ '--color': !currentQuestionId ? color : undefined }}
    >
      <div className={contentClassName} data-start={start} data-end={end}>
        {markerMode === 'edit' && start ? (
          <SegmentsMarkerHeader
            id={id}
            label={label}
            currentQuestionId={currentQuestionId}
            assignedQuestionId={questionId}
            assignedOptionIds={optionIds}
          />
        ) : null}
        {replaceSegments || segments}
      </div>
    </div>
  )
})

SegmentsMarker.displayName = 'Wizard-Editor-SegmentsMarker'

export default SegmentsMarker
