import React from 'react'
import useStore from '___store'

import { ButtonGroup, Input, Switch } from 'components'
import { classes } from '../../../index'

const TextInput = React.memo(({ id }) => {
  const { optionById: { value = '', text = '' } = {}, updateOption } = useStore(`selectOptionById[${id}]`, 'updateOption')

  const {
    wrapper: wrapperClass,
    text: textClass,
    textInput: { input: inputClass, switch: switchClass },
  } = classes.configEntry.optionConfiguration.fragment

  const differentText = text && text !== value

  return (
    <div className={wrapperClass}>
      <span className={textClass}>Option Text:</span>
      <ButtonGroup collapsed>
        <Switch
          className={switchClass}
          value={differentText}
          onChange={result => (result ? updateOption({ optionId: id, text: `${value} ` }) : updateOption({ optionId: id, text: '' }))}
          labels={['Same as value', 'Different text']}
        />
        <Input
          key={`Option-${id}-text-input-${differentText}`}
          className={inputClass}
          disabled={!differentText}
          placeholder="Option text goes here (e.g. John Doe)"
          defaultValue={text}
          onChange={text => updateOption({ optionId: id, text })}
          needsConfirmation
          blurShowUnmodified
        />
      </ButtonGroup>
    </div>
  )
})

TextInput.displayName = 'Wizard-Configuration-OptionConfiguration-TextInput'

export default TextInput
