import React, { FunctionComponent, useMemo } from 'react'

import { useEditorPreviewContext } from '../..'
import { mapContentToSpan } from '../../helpers'
import { WizardLayoutRightPaneEditorSegmentParagraphProps, wizardLayoutRightPaneEditorSegmentParagraphClasses as classes } from '../../../../..'

export const Paragraph: FunctionComponent<WizardLayoutRightPaneEditorSegmentParagraphProps> = React.memo(
  ({ id, customStyle, styles, textChunks }) => {
    const editorContext = useEditorPreviewContext()
    const { numbering } = editorContext
    const contextNumbering = numbering && numbering[id]
    const render = useMemo(() => textChunks?.map((chunk, i) => mapContentToSpan(chunk, id, i, 'preview')), [textChunks, id])

    const paragraphClasses = [classes.wrapper, customStyle, ...styles].filter(cn => cn)

    return (
      <pre
        id={id}
        className={paragraphClasses.join(' ')}
        style={{ counterSet: contextNumbering && `${contextNumbering.system} ${contextNumbering.value}` }}
      >
        {render}
      </pre>
    )
  }
)

Paragraph.displayName = 'WizardLayout-RightPane-Editor-Preview-Segment-Paragraph'

export default Paragraph
