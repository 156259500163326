import React, { FunctionComponent, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import useStore, { SetTemplatesFilterAction, SetTemplatesFolderFilterAction, TemplatesFilterSelector, TemplatesFolderFilterSelector } from '___store'

import { TEMPLATES_FILTERS, TemplatesFilter } from '___types'
import { useTemplateList } from '___hooks'
import { Bin, Company, Folder, Home, Plus } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { Create, classes as folderClasses } from 'components/CasusComponents/FolderTree'
import useModal from 'Modal'
import { Folders } from './Templates.components'
import { MainLayoutLeftPaneTemplatesProps, mainLayoutLeftPaneTemplatesClasses as classes } from '../../..'

type UseStoreHookResultType = {
  templatesFolderFilter: TemplatesFolderFilterSelector
  templatesFilter: TemplatesFilterSelector
  setTemplatesFolderFilter: SetTemplatesFolderFilterAction
  setTemplatesFilter: SetTemplatesFilterAction
}

export const Templates: FunctionComponent<MainLayoutLeftPaneTemplatesProps> = React.memo(() => {
  const history = useHistory()

  const { templatesFolderFilter, templatesFilter, setTemplatesFolderFilter, setTemplatesFilter } = useStore(
    'selectTemplatesFolderFilter',
    'selectTemplatesFilter',
    'setTemplatesFolderFilter',
    'setTemplatesFilter'
  ) as UseStoreHookResultType

  const open = useModal({ header: 'New folder', footer: false, className: folderClasses.modal.wrapper })
  const { list, trashed, createFolder } = useTemplateList()

  const parentFolderId = useMemo(() => {
    const lastFolderId = templatesFolderFilter.split('.').slice(-1)[0]
    return Object.values(TEMPLATES_FILTERS).includes(lastFolderId as TemplatesFilter) ? '' : lastFolderId
  }, [templatesFolderFilter])

  const atHome = useMemo(
    () => !(templatesFolderFilter || templatesFilter.split('+').includes('status:trashed')),
    [templatesFolderFilter, templatesFilter]
  )

  const inCompany = useMemo(() => templatesFolderFilter === TEMPLATES_FILTERS.COMPANY, [templatesFolderFilter])
  const inBin = useMemo(() => templatesFilter.split('+').includes('status:trashed'), [templatesFilter])

  return (
    <div className={classes.wrapper}>
      <Button className={classes.newTemplate} onClick={() => history.push('template/new')} tertiary>
        <Plus />
        New Template
      </Button>
      <Button
        className={classes.newFolder}
        onClick={() => open({ content: close => <Create createCallback={name => createFolder(name, parentFolderId)} closeCallback={close} /> })}
      >
        <Folder function="add" />
      </Button>
      <Button
        className={classes.home}
        onClick={() => {
          setTemplatesFolderFilter([])
          setTemplatesFilter(['-status:trashed'])
        }}
        onClickBlur
        dataSet={{ active: atHome ? true : undefined }}
        transparent
      >
        <Home />
        Home
      </Button>
      <Folders />
      <Button
        className={classes.bin}
        onClick={() => {
          setTemplatesFolderFilter(TEMPLATES_FILTERS.COMPANY)
          setTemplatesFilter(['-status:trashed'])
        }}
        loading={list.isLoading}
        disabled={list.isLoading}
        onClickBlur
        dataSet={{ active: inCompany ? true : undefined }}
        transparent
      >
        <Company />
        Company templates
      </Button>
      <Button
        className={classes.bin}
        onClick={() => {
          setTemplatesFolderFilter([])
          setTemplatesFilter('status:trashed')
        }}
        loading={trashed.isLoading}
        disabled={trashed.isLoading}
        onClickBlur
        dataSet={{ active: inBin ? true : undefined }}
        transparent
      >
        <Bin />
        Bin
      </Button>
      {/* <div className={classes.quickAccess}>
      </div> */}
    </div>
  )
})

Templates.displayName = 'MainLayout-LeftPane-Templates'

export default Templates
