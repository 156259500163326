import React, { FunctionComponent, RefCallback, useCallback, useEffect, useMemo, useState } from 'react'

import { ModalProps, classes } from 'Modal'
import { Content, Footer, Header } from './Modal.Components'

type CallbackFunctionType = (context: Record<string, unknown>, ...params: unknown[]) => unknown | void
export type RenderFunctionType = (
  close: (...params: unknown[]) => void,
  cancel: (...params: unknown[]) => void,
  conclude: (...params: unknown[]) => void,
  context?: Record<string, unknown>
) => JSX.Element

export type OnOpenOptions = {
  context?: Record<string, unknown>
  onClose?: CallbackFunctionType
  onCancel?: CallbackFunctionType
  onConclude?: CallbackFunctionType
  content?: string | JSX.Element | RenderFunctionType
  header?: boolean | string | JSX.Element | RenderFunctionType
  close?: boolean | string | JSX.Element | RenderFunctionType
  footer?: boolean | string | JSX.Element | RenderFunctionType
  cancel?: boolean | string | JSX.Element | RenderFunctionType
  conclude?: boolean | string | JSX.Element | RenderFunctionType
  type?: 'warning' | 'error'
  className?: string
}
export type OnOpenFunctionType = (options: OnOpenOptions) => void

export const Modal: FunctionComponent<ModalProps> = React.memo(({ connectCallback, disconnectCallback }) => {
  const [options, setOptions] = useState<(OnOpenOptions & { context: Record<string, unknown> })[]>([])
  const [open, setOpen] = useState(false)
  const [wrapper, setWrapper] = useState<HTMLDivElement>()

  const wrapperRef: RefCallback<HTMLDivElement> = useCallback(node => node && setWrapper(node), [])

  const currentOptions = useMemo(() => options.slice(-1)[0] || {}, [options])

  const closeCallback = useCallback(
    (...params: unknown[]) => {
      if (typeof currentOptions.onClose === 'function') currentOptions.onClose(currentOptions.context, ...params)
      setOptions([])
      setOpen(false)
    },
    [currentOptions]
  )

  const cancelCallback = useCallback(
    (...params: unknown[]) => {
      if (typeof currentOptions.onCancel === 'function') currentOptions.onCancel(currentOptions.context, ...params)
      if (typeof currentOptions.onClose === 'function') currentOptions.onClose(currentOptions.context, ...params)
      const resultingOptions = options.slice(0, -1)
      setOptions(resultingOptions)
      setOpen(Boolean(resultingOptions.length))
    },
    [currentOptions, options]
  )

  const concludeCallback = useCallback(
    (...params: unknown[]) => {
      if (typeof currentOptions.onConclude === 'function') currentOptions.onConclude(currentOptions.context, ...params)
      if (typeof currentOptions.onClose === 'function') currentOptions.onClose(currentOptions.context, ...params)
      const resultingOptions = options.slice(0, -1)
      setOptions(resultingOptions)
      setOpen(Boolean(resultingOptions.length))
    },
    [currentOptions, options]
  )

  const onOpenCallback = useCallback<OnOpenFunctionType>(options => {
    const payload = { content: <></>, context: {} }
    if ('context' in options) Object.assign(payload, { context: options.context })
    if (typeof options.onCancel === 'function') Object.assign(payload, { onCancel: options.onCancel })
    if (typeof options.onConclude === 'function') Object.assign(payload, { onConclude: options.onConclude })
    if (typeof options.onClose === 'function') Object.assign(payload, { onClose: options.onClose })
    if ('content' in options) Object.assign(payload, { content: options.content })
    if ('header' in options) Object.assign(payload, { header: options.header })
    if ('close' in options) Object.assign(payload, { close: options.close })
    if ('footer' in options) Object.assign(payload, { footer: options.footer })
    if ('cancel' in options) Object.assign(payload, { cancel: options.cancel })
    if ('conclude' in options) Object.assign(payload, { conclude: options.conclude })
    if ('type' in options) Object.assign(payload, { type: options.type })
    if ('className' in options) Object.assign(payload, { className: options.className })
    setOpen(true)
    setOptions(prev => prev.concat(payload))
  }, [])

  const onFocusCatchCallback = useCallback(
    (last: boolean = false) => {
      const focusable = wrapper?.querySelectorAll("button:not(:disabled), input, pre[contenteditable='true'") || [] // add contentEditable divs
      if (focusable.length) (focusable[Number(Boolean(last)) * (focusable.length - 1)] as HTMLButtonElement | HTMLInputElement).focus()
    },
    [wrapper]
  )

  const onEscapeClose = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        event.stopPropagation()
        closeCallback()
      }
    },
    [closeCallback]
  )

  useEffect(() => {
    if (open) {
      ;(wrapper?.getElementsByClassName(classes.focusCatcher)[0] as HTMLDivElement).focus()
      document.addEventListener('keydown', onEscapeClose, true)
      return () => document.removeEventListener('keydown', onEscapeClose, true)
    }
  }, [open, wrapper, onEscapeClose])

  useEffect(() => {
    connectCallback(onOpenCallback)
    return () => disconnectCallback()
  }, [connectCallback, onOpenCallback, disconnectCallback])

  const className = [classes.wrapper, currentOptions.className].filter(s => s).join(' ')

  return (
    <div ref={wrapperRef} className={className} data-open={open ? '' : undefined} data-type={currentOptions.type}>
      <div className={classes.backdrop} onClick={() => closeCallback()} />
      {open ? (
        <div className={classes.body}>
          <div className={classes.focusCatcher} tabIndex={0} onFocus={() => onFocusCatchCallback(true)} />
          <Header
            render={currentOptions.header}
            close={currentOptions.close}
            closeCallback={closeCallback}
            cancelCallback={cancelCallback}
            concludeCallback={concludeCallback}
            context={currentOptions.context}
          />
          <Content
            render={currentOptions.content}
            closeCallback={closeCallback}
            cancelCallback={cancelCallback}
            concludeCallback={concludeCallback}
            context={currentOptions.context}
          />
          <Footer
            render={currentOptions.footer}
            cancel={currentOptions.cancel}
            conclude={currentOptions.conclude}
            closeCallback={closeCallback}
            cancelCallback={cancelCallback}
            concludeCallback={concludeCallback}
            context={currentOptions.context}
          />
          <div className={classes.focusCatcher} tabIndex={0} onFocus={() => onFocusCatchCallback()} />
        </div>
      ) : null}
    </div>
  )
})

Modal.displayName = 'Modal'

export default Modal
