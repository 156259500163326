import React from 'react'

import { className } from './index'

const VerticalEllipsis = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <circle cx="50" cy="25" r="8" fill="currentColor" />
    <circle cx="50" cy="50" r="8" fill="currentColor" />
    <circle cx="50" cy="75" r="8" fill="currentColor" />
  </svg>
))

export { VerticalEllipsis }
export default VerticalEllipsis
