import React, { FormEvent, FunctionComponent, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import useStore, {
  WizardIntegrationsSelector,
  UpdateIntegrationFieldValuesAction,
  WizardIntegrationEntriesSelector,
  InitializeWizardAction,
  UpdateWizardStateAction,
  WizardQuestionOrderSelector,
} from '___store'

import { DOCUMENT_DIRECTORY, DOCUMENT_FLOW_MODES, OptionValueTypeUnionType } from '___types'
import { extractValueFromInputEvent } from 'utilities/helpers'
import { useSubscribe } from '___queries'
import { useTemplate } from '___hooks'
import { Caret } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { classes as buttonClasses } from 'components/CasusComponents/Button'
import { classes as inputClasses } from 'components/CasusComponents/Input'
import {
  WizardLayoutLeftPaneDocumentConfigurationActionsProps,
  wizardLayoutLeftPaneDocumentConfigurationActionsClasses as classes,
  wizardLayoutLeftPaneDocumentConfigurationIntegrationsInstanceClasses as integrationInstanceClasses,
  wizardLayoutLeftPaneDocumentConfigurationPaywallClasses as paywallClasses,
  useWizardLayoutContext,
} from 'Layouts/WizardLayout'

type UseStoreHookResultType = {
  wizardIntegrations: WizardIntegrationsSelector
  wizardIntegrationEntries: WizardIntegrationEntriesSelector
  wizardQuestionOrder: WizardQuestionOrderSelector
  initializeWizard: InitializeWizardAction
  updateWizardState: UpdateWizardStateAction
  updateIntegrationFieldValues: UpdateIntegrationFieldValuesAction
}

const TYPE_MAP = { 'datetime-local': 'datetime', text: 'string' } as Record<string, string>

export const Actions: FunctionComponent<WizardLayoutLeftPaneDocumentConfigurationActionsProps> = React.memo(({ isLoading }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.configuration' })
  const history = useHistory()
  const { templateId, documentId } = useParams() as { templateId: string; documentId: string }
  const subscribe = useSubscribe()

  const { wizardIntegrations, wizardIntegrationEntries, wizardQuestionOrder, initializeWizard, updateWizardState, updateIntegrationFieldValues } =
    useStore(
      'selectWizardIntegrations',
      'selectWizardIntegrationEntries',
      'selectWizardQuestionOrder',
      'initializeWizard',
      'updateWizardState',
      'updateIntegrationFieldValues'
    ) as UseStoreHookResultType

  const publicFlow = useWizardLayoutContext()

  const { paywallBlocked } = useTemplate(templateId!, publicFlow)

  const allIntegrationEntriesChosen = useMemo(
    () => Object.keys(wizardIntegrations || {}).every(id => wizardIntegrationEntries && wizardIntegrationEntries[id]),
    [wizardIntegrations, wizardIntegrationEntries]
  )

  const subscribeHandler = useCallback(() => {
    const priceId = Array.from(document.querySelectorAll(`.${paywallClasses.wrapper} > .${buttonClasses.wrapper} > .${buttonClasses.button}`)).reduce(
      (result, button) => ((button.parentNode as HTMLDivElement)?.dataset?.toggle === 'on' ? button.id : result),
      undefined as string | undefined
    )
    if (priceId)
      subscribe(
        { priceId, successUrl: `${window.location.origin}/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/new?step=configure` },
        { onSuccess: response => (window.location.href = response!) }
      )
  }, [subscribe, publicFlow, templateId])

  const continueHandler = useCallback(() => {
    const integrations = Array.from(document.querySelectorAll(`.${integrationInstanceClasses.wrapper}`))
    const resultingFieldValues = integrations.reduce((result, integration) => {
      const { id } = integration
      const inputValues = Array.from(integration.querySelectorAll(`.${inputClasses.wrapper}`)).reduce((resultingValues, div) => {
        const { id: fieldId } = div
        const input = div.childNodes[0]
        const type = input.nodeName === 'PRE' ? 'string' : (input as HTMLInputElement).type
        const mappedType = (TYPE_MAP[type] || type) as OptionValueTypeUnionType
        const pseudoInputEvent = { target: input } as unknown as FormEvent<HTMLPreElement | HTMLInputElement>
        const value = extractValueFromInputEvent(mappedType, pseudoInputEvent)
        return Object.assign(resultingValues, { [fieldId]: value })
      }, {} as Record<string, string>)
      return Object.assign(result, { [id]: inputValues })
    }, {})
    updateIntegrationFieldValues(resultingFieldValues)
    updateWizardState({ answering: wizardQuestionOrder ? wizardQuestionOrder[0] : 'redirect-backward' })
    history.push(`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${documentId || 'new'}`)
  }, [updateIntegrationFieldValues, updateWizardState, wizardQuestionOrder, history, publicFlow, templateId, documentId])

  return (
    <div className={classes.wrapper}>
      <Button
        onClick={() => {
          initializeWizard({ mode: DOCUMENT_FLOW_MODES.TEMPLATE_SELECT })
          history.push(`/document`)
        }}
        disabled={publicFlow}
      >
        <Caret />
        {translate('to-template-select')}
      </Button>
      <Button
        onClick={paywallBlocked ? subscribeHandler : continueHandler}
        tertiary
        loading={isLoading}
        disabled={isLoading || !allIntegrationEntriesChosen}
        showSpinner
      >
        {paywallBlocked ? translate('subscribe') : translate('to-questionnaire')}
        <Caret />
      </Button>
    </div>
  )
})

Actions.displayName = 'WizardLayout-LeftPane-Document-Configuration-Actions'

export default Actions
