import React, { FunctionComponent, useMemo } from 'react'

import { useParagraph } from '../../customHooks'
import { mapContentToSpan } from '../../helpers'
import { WizardLayoutRightPaneEditorSegmentParagraphProps, wizardLayoutRightPaneEditorSegmentParagraphClasses as classes } from '../../../../..'

export const Paragraph: FunctionComponent<WizardLayoutRightPaneEditorSegmentParagraphProps> = React.memo(
  ({ id, customStyle, styles, textChunks }) => {
    const { content, numberingSystem, numberingValue } = useParagraph(id, textChunks)

    const render = useMemo(() => content.map((chunk, i) => mapContentToSpan(chunk, id, i, 'interact')), [content, id])
    const paragraphClasses = [classes.wrapper, customStyle, ...styles].filter(cn => cn)
    return (
      <pre id={id} className={paragraphClasses.join(' ')} style={{ counterSet: numberingSystem && `${numberingSystem} ${numberingValue}` }}>
        {render}
      </pre>
    )
  }
)

Paragraph.displayName = 'WizardLayout-RightPane-Editor-Segment-Paragraph'

export default Paragraph
