import React from 'react'

import { className } from './index'

const InsertAbove = React.memo(() => {
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <rect x="14" y="16" width="68" height="16" stroke="currentColor" strokeWidth="6" fill="none" />
      <path d="M 24 48 h 60" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" fill="none" />
      <path d="M 24 64 h 60" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" fill="none" />
      <path d="M 24 80 h 60" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" fill="none" />
    </svg>
  )
})

export { InsertAbove }
export default InsertAbove
