import React, { useCallback, useMemo, useState } from 'react'
import useStore from '___store'
import { Caret } from 'assets/svgIconComponents'
import { Button } from 'components'

import { ConfigEntry } from './components'
import { classes } from './index'

const headerMap = {
  'template-creation': 'Template Creation',
  question: 'Question Configuration',
  'question-advanced': 'Advanced Question Configuration',
  option: 'Option Configuration',
  marker: 'Marker Configuration',
}
const parseStack = stack =>
  stack
    .split('; ')
    .filter(s => s)
    .map(keyid => keyid.split(':').filter(s => s))

export const Configuration = React.memo(() => {
  const [collapsed, setCollapsed] = useState(false)
  const { configStackKeys: keys } = useStore('selectConfigStackKeys')

  const configKeyIds = useMemo(() => parseStack(keys), [keys])
  const content = useMemo(
    () => configKeyIds.map(([keystring, id]) => <ConfigEntry key={`${keystring}:${id}`} keystring={keystring} id={id} />),
    [configKeyIds]
  )

  const style = {}
  if (configKeyIds.length >= 2) Object.assign(style, { '--custom-shift': `-${100 * (configKeyIds.length - 1)}%` })

  const onClick = useCallback(event => event.currentTarget.blur() || setCollapsed(prev => !prev), [])

  const dataKey = configKeyIds[0] && configKeyIds[0][0]

  return (
    <section data-collapsed={collapsed} className={classes.wrapper} data-key={dataKey}>
      <div className={classes.content} style={style}>
        <div className={classes.header}>
          <span className={classes.headerText}>{configKeyIds.length ? headerMap[configKeyIds[configKeyIds.length - 1][0]] : null}</span>
          <Button className={classes.headerCollapseButton} onClick={onClick} colorScheme="transparent">
            <Caret />
          </Button>
        </div>
        <div className={classes.entries}>{content}</div>
      </div>
    </section>
  )
})

Configuration.displayName = 'Wizard-Configuration'

export default Configuration
