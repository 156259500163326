import { useMutation } from 'react-query'

import { subscriptionsAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type SubscribeVariables = { priceId: string; successUrl?: string; cancelUrl?: string }
const subscribeMutationFunction = (variables: SubscribeVariables) =>
  subscriptionsAPI.getSubscribeLink(variables.priceId, variables.successUrl, variables.cancelUrl)

export const useSubscribe = () => {
  const subscribe = useMutation([QUERY_KEYS.SUBSCRIPTION], subscribeMutationFunction)
  return subscribe.mutate
}

export default useSubscribe
