import { IListItemText, IIconStyles } from './FolderStructure.types'
import { styled } from '@material-ui/core/styles'
import { Box, Typography, Theme, Collapse } from '@material-ui/core'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const getStyles = (active: number, type: string, theme: Theme) => {
  const commonStyles: CreateCSSProperties<IListItemText> = {
    fontSize: 16,
    letterSpacing: 0.4,
    lineHeight: 1.31,
    cursor: 'pointer',
    width: 'max-content',
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: active ? theme.colorz.primary.main : 'transparent',
    transition: 'all .2s',
    '&:hover': {
      transform: 'translateY(-1px)',
    },
  }

  if (type === 'white') {
    return {
      color: "#001640",//theme.colorz.brand.main,
      ...commonStyles,
    }
  } else {
    return {
      color: "#001640",//theme.colorz.secondary.main,
      ...commonStyles,
    }
  }
}

export const TopFolderText = styled(Typography)(
  ({ theme, active, type }: IListItemText) => ({
    fontWeight: 700,
    ...getStyles(active, type, theme),
  })
)

export const ChildFolderText = styled(Typography)(
  ({ theme, active, type }: IListItemText) => ({
    fontWeight: 500,
    ...getStyles(active, type, theme),
    lineHeight: 1.6,
  })
)

const iconStyles = ({ type, theme }: IIconStyles) => ({
  opacity: 0.9,
  fontSize: 20,
  color: type === 'white' ? "#001640"/* '#fff' */ : theme.colorz.secondary.main,
})

export const ArrowDropDownIcon = styled(ArrowDropDown)(iconStyles)
export const ArrowRightIcon = styled(ArrowRight)(iconStyles)

export const FolderItemContainer = styled(Box)(({ theme }) => ({}))

export const FolderContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  transition: 'all .2s',
  padding: theme.spacing(0.475, 0),
  '&:hover': {
    opacity: 0.8,
  },
}))

export const SubfoldersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: theme.spacing(2),
}))

export const ListContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
}))

export { Collapse }
