import React, { FunctionComponent, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useUser } from '___hooks'
import { Burger, Document, Signature, Squares, Team, Template } from 'assets/svgIconComponents'
import { Button, Dropdown } from 'components/CasusComponents'
import { MainLayoutHeaderNavigationProps, mainLayoutHeaderClasses as classes } from '../..'

export const Navigation: FunctionComponent<MainLayoutHeaderNavigationProps> = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.header' })
  // const { pathname } = useLocation()
  const { push } = useHistory()
  const { data: { type } = {} } = useUser()

  const availablePages = useMemo(() => {
    const pages = {
      dashboard: { path: '/', icon: <Squares />, label: translate('pages.dashboard') },
      documents: { path: '/documents', icon: <Document />, label: translate('pages.documents') },
      templates: { path: '/templates', icon: <Template />, label: translate('pages.templates') },
      signatures: { path: '/signatures', icon: <Signature />, label: translate('pages.signatures') },
      team: { path: '/team', icon: <Team />, label: translate('pages.team') },
    }
    // const directory = pathname.split('/').filter(s => s)[0] // remove the page the user is currently on
    // delete pages[directory]
    //@ts-ignore
    if (type !== 'USER_TYPE_CLIENT_ADMIN') delete pages.templates
    return pages
  }, [translate, type])

  const dropdownOptions = useMemo(
    () =>
      Object.entries(availablePages).map(([id, { path, icon, label }]) => (
        <Button key={`MainLayout-Header-Navigation-Button-${id}`} onClick={() => push(path)} onClickBlur noBackgroundSVG noOverlaySVG>
          {icon}
          {label}
        </Button>
      )),
    [availablePages, push]
  )

  return (
    <Dropdown className={classes.navigation} hideCaret label={<Burger />} transparent>
      {dropdownOptions}
    </Dropdown>
  )
})

Navigation.displayName = 'MainLayout-Header-Navigation'

export default Navigation
