import { PXPI, PAGE_SIZES, PaperMargin, PageLayout, RecursiveRecord, CASUS_KEYSTRINGS, Numbering } from '___types'
import {
  wizardLayoutRightPaneEditorPageClasses as pageClasses,
  wizardLayoutRightPaneEditorMarkerSegmentsClasses as segmentsMarkerClasses,
} from '../..'
import { isObject } from 'utilities/helpers'

const rulesToString = (identifier: string, rules: Record<keyof any, unknown>): string =>
  [identifier, '{', ...Object.entries(rules).map(([attribute, value]) => `${attribute}: ${value};`), '}'].join(' ')

const applyRule = (
  property: string,
  position: keyof PaperMargin,
  values: PaperMargin,
  ruleObject: Record<keyof any, unknown> = {}
): Record<keyof any, unknown> => Object.assign(ruleObject, { [`${property}-${position}`]: `${values[position] || 0}px` })

const applyRules = (
  property: string,
  positionArray: (keyof PaperMargin)[],
  values: PaperMargin,
  startingRules: Record<keyof any, unknown> = {}
): Record<keyof any, unknown> => positionArray.reduce((acc, cur) => applyRule(property, cur, values, acc), startingRules)

const generateLayoutString = ({ paper, orientation, margins: { top, bottom, left, right } }: PageLayout): string =>
  //@ts-ignore
  `${paper}-${orientation}-${top}-${bottom}-${left}-${right}`.replaceAll('.', '_')

const generateSectionPageLayoutStyleSheet = (pageLayout: PageLayout) => {
  const { paper, orientation, margins: layoutMargins } = pageLayout
  const { width: portraitShortSide, height: portraitLongSide } = PAGE_SIZES[paper]
  const [width, minHeight] = orientation === 'portrait' ? [portraitShortSide, portraitLongSide] : [portraitLongSide, portraitShortSide]
  const margins = Object.entries(layoutMargins).reduce((acc, [key, value = 0]) => Object.assign(acc, { [key]: value * PXPI }), {}) as PaperMargin
  const layoutClass = generateLayoutString(pageLayout)
  const pageIdentifier = `.${layoutClass} > .${pageClasses.wrapper}`
  const pagePaddingPositions = Object.keys(layoutMargins) as (keyof PaperMargin)[]
  const pageStyleRules = applyRules('padding', pagePaddingPositions, margins, { width: `${width}px`, 'min-height': `${minHeight}px` })
  const pageStyle = rulesToString(pageIdentifier, pageStyleRules)
  const markerIdentifier = `.${layoutClass} .${segmentsMarkerClasses.wrapper}`
  const markerMarginPositions = ['left', 'right'] as (keyof PaperMargin)[]
  const markerMargins = Object.entries(margins).reduce((result, [key, value = 0]) => Object.assign(result, { [key]: -value }), {})
  const markerPaddingPositions = ['left', 'right'] as (keyof PaperMargin)[]
  const markerStyleRules = applyRules('margin', markerMarginPositions, markerMargins, applyRules('padding', markerPaddingPositions, margins))
  const markerStyle = rulesToString(markerIdentifier, markerStyleRules)
  // const paragraphIdentifier = `${pageIdentifier} > .${paragraphClasses.wrapper}`
  // const paragraphMarginPositions = ['left', 'right'] as (keyof PaperMargin)[]
  // const paragraphStyleRules = applyRules('--page-margin', paragraphMarginPositions, margins)
  // const paragraphStyle = rulesToString(paragraphIdentifier, paragraphStyleRules)
  return [
    pageStyle,
    markerStyle,
    // paragraphStyle
  ].join('\n')
}

const generateNumberingSystemStyleSheet = (key: string, numbering: Numbering) => {
  const listCounterStyles = numbering.reduce((accumulated, styleConfig, i, entries) => {
    const { styleName, prefix, type, suffix, combined, combineString } = styleConfig
    const leading = combined
      ? entries.reduce((contentString, { prefix, type, suffix, combineString }, j) => {
          if (j >= i) return contentString
          const counter = `counter(${CASUS_KEYSTRINGS.NUMBERING_SYSTEM_KEY}_${key}${CASUS_KEYSTRINGS.NUMBERING_LEVEL}_${j}, ${type})`
          return `${contentString}'${prefix}'${counter}'${suffix}${combineString}'`
        }, '')
      : ''
    const counter = `counter(${CASUS_KEYSTRINGS.NUMBERING_SYSTEM_KEY}_${key}${CASUS_KEYSTRINGS.NUMBERING_LEVEL}_${i}, ${type})`
    const numbering = `'${prefix}'${counter}'${suffix}`
    const trailing = combined ? `${combineString} '` : " '"
    const content = `${leading}${numbering}${trailing}`
    const cssClass = styleName || `${CASUS_KEYSTRINGS.NUMBERING}-${key}-${i}`
    const tag = `pre.${cssClass}::before`
    const style = `content: ${content};`
    const listString = `${tag} { ${style} }`
    return accumulated.concat(listString)
  }, [] as string[])
  return listCounterStyles.join('\n')
}

// const generateListStylesString = (numberingSystem = {}) =>
//   Object.entries(numberingSystem)
//     .reduce((acc, [systemKey, systemObj]) => {
//       const listCounterStyles = systemObj.reduce((accumulated, styleConfig, i, entries) => {
//         const { styleName, prefix, type, suffix, combined, combineString } = styleConfig
//         const leading = combined
//           ? entries.reduce((contentString, { prefix, type, suffix, combineString }, j) => {
//               if (j >= i) return contentString
//               const counter = `counter(${CASUS_KEYSTRINGS.numberingSystemKey}_${systemKey}${CASUS_KEYSTRINGS.numberingLevel}_${j}, ${type})`
//               return `${contentString}'${prefix}'${counter}'${suffix}${combineString}'`
//             }, '')
//           : ''
//         const counter = `counter(${CASUS_KEYSTRINGS.numberingSystemKey}_${systemKey}${CASUS_KEYSTRINGS.numberingLevel}_${i}, ${type})`
//         const numbering = `'${prefix}'${counter}'${suffix}`
//         const trailing = combined ? `${combineString} '` : " '"
//         const content = `${leading}${numbering}${trailing}`
//         const cssClass = styleName || `${CASUS_KEYSTRINGS.numbering}-${systemKey}-${i}`
//         const tag = `${SEGMENT_TAGS[SEGMENT_TYPES.paragraph]}.${cssClass}::before`
//         // const textareaTag = `${SEGMENT_TAGS[SEGMENT_TYPES.textarea]}.${styleName}::before`
//         const style = `content: ${content};`
//         const listString = `${tag} { ${style} }`
//         // const textareaListString = `${textareaTag} { ${style} }`
//         return accumulated.concat(listString)
//       }, [])
//       return acc.concat(listCounterStyles)
//     }, [])
//     .join('\n')

const parseStyleObject = (styleObject: RecursiveRecord<string, string>, prefix: string = '') =>
  Object.entries(styleObject).reduce((sheetEntries, [className, properties]) => {
    const propertyStyles = Object.entries(properties).reduce((sheetEntry, [property, value]) => {
      if (typeof value === 'string') return sheetEntry.concat(`${property}: ${value}`)
      if (isObject(value))
        parseStyleObject({ [property]: value } as RecursiveRecord<string, string>, `${prefix}${className}`).forEach(entry => sheetEntries.push(entry))
      return sheetEntry
    }, [] as string[])
    if (propertyStyles.length) sheetEntries.push(`${prefix}${className} { ${propertyStyles.join('; ')} }`)
    return sheetEntries
  }, [] as string[])

export { generateLayoutString, generateSectionPageLayoutStyleSheet, generateNumberingSystemStyleSheet, parseStyleObject }
