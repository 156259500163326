import RecentDocumentsPanel from './RecentDocumentsPanel'
import EmptyDocuments from './EmptyDocuments'

const classes = {
  wrapper: 'Homepage-dashboard-grid-panel-wrapper',
  title: {
    wrapper: 'Homepage-dashboard-grid-panel-title-wrapper',
    showAll: 'Homepage-dashboard-grid-panel-title-showAll',
    textAndIcon: 'Homepage-dashboard-grid-panel-title-textAndIcon',
  },
  empty: {
    wrapper: 'Homepage-dashboard-grid-panel-empty-wrapper',
    icon: 'Homepage-dashboard-grid-panel-empty-icon',
    text: 'Homepage-dashboard-grid-panel-empty-text',
  },
  list: 'Homepage-dashboard-recentDocuments-list-wrapper',
}

export { classes, EmptyDocuments }

export default RecentDocumentsPanel

