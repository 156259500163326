import React, { forwardRef, RefCallback, useCallback, useImperativeHandle, useMemo, useState } from 'react'

import { Signee } from '___types'
import { Cross, Plus } from 'assets/svgIconComponents'
import { Button, Input } from 'components/CasusComponents'
import { SigneesProps, signeesClasses as classes } from '.'

export const Signees = React.memo(
  forwardRef<Signee[], SigneesProps>(({ signees: propsSignees, onChange, locked }, ref) => {
    const [stateSignees, setStateSignees] = useState([] as Signee[])

    const [firstNameInput, setFirstNameInput] = useState<HTMLInputElement>()
    const firstNameInputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setFirstNameInput(node), [])

    const [lastNameInput, setLastNameInput] = useState<HTMLInputElement>()
    const lastNameInputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setLastNameInput(node), [])

    const [emailInput, setEmailInput] = useState<HTMLInputElement>()
    const emailInputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setEmailInput(node), [])

    const computedSignees = useMemo(() => propsSignees ?? stateSignees, [propsSignees, stateSignees])

    useImperativeHandle(ref, () => computedSignees)

    return (
      <div className={classes.wrapper} data-signees={computedSignees.length || undefined}>
        <span className={classes.title}>{`Signees${computedSignees.length ? ` (${computedSignees.length})` : ''}`}</span>
        {!computedSignees.length ? <span className={classes.description}>Add one or more signees.</span> : null}
        <div className={classes.form}>
          <Input ref={firstNameInputRef} type="text" defaultValue="" placeholder="First name" />
          <Input ref={lastNameInputRef} type="text" defaultValue="" placeholder="Last name" />
          <Input ref={emailInputRef} className={classes.email} type="text" defaultValue="" placeholder="e-mail" options={{ format: 'email' }} />
          <Button
            onClick={() => {
              if (!firstNameInput?.value) return firstNameInput?.focus()
              if (!lastNameInput?.value) return lastNameInput?.focus()
              if (!(emailInput?.value && emailInput?.validity.valid)) return emailInput?.focus()
              if (computedSignees.find(({ email }) => emailInput.value === email)) return emailInput?.focus()
              setStateSignees(prev => prev.concat({ email: emailInput.value, firstName: firstNameInput.value, lastName: lastNameInput.value }))
              if (typeof onChange === 'function')
                onChange(computedSignees.concat({ email: emailInput.value, firstName: firstNameInput.value, lastName: lastNameInput.value }))
              firstNameInput.value = ''
              firstNameInput.dispatchEvent(new Event('input', { bubbles: true }))
              lastNameInput.value = ''
              lastNameInput.dispatchEvent(new Event('input', { bubbles: true }))
              emailInput.value = ''
              emailInput.dispatchEvent(new Event('input', { bubbles: true }))
              firstNameInput.focus()
            }}
            onClickBlur
            primary
          >
            <Plus />
            Add
          </Button>
        </div>
        <div className={classes.entries}>
          {computedSignees.length ? (
            computedSignees.map(signee => (
              <Button
                key={`Casus-Components-Signature-ModalContent-Signees-${signee.email}`}
                onClick={() => {
                  setStateSignees(prev => prev.filter(({ email }) => email !== signee.email))
                  if (typeof onChange === 'function') onChange(computedSignees.filter(({ email }) => email !== signee.email))
                }}
                transparent
                disabled={locked?.includes(signee.email)}
              >
                {[[signee.firstName, signee.lastName].filter(s => s).join(' ')]
                  .concat(signee.email)
                  .filter(s => s)
                  .join(' - ')}
                {!locked?.includes(signee.email) ? <Cross /> : null}
              </Button>
            ))
          ) : (
            <span>No added signers.</span>
          )}
        </div>
      </div>
    )
  })
)

Signees.displayName = 'Casus-Components-Signature-ModalContent-Signees'

export default Signees
