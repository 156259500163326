import React from 'react'

import './style.scss'

import { classes } from '.'

export const Input = ({ value, onChange, name, label, placeholder, type, isTextarea }) => {
  return (
    <div className={classes.wrapper}>
      <label className={classes.label}>{label}</label>
      {isTextarea ? (
        <textarea rows={3} name={name} className={classes.textarea} type={type} placeholder={placeholder} value={value} onChange={onChange} />
      ) : (
        <input name={name} className={classes.field} type={type} placeholder={placeholder} value={value} onChange={onChange} />
      )}
    </div>
  )
}

