import React from 'react'

import { className } from './index'

const Reminders = React.memo(() => (
  <svg className={className} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.47656 4.3125C1.28125 4.3125 1.10938 4.1875 1.02344 3.99219C0.9375 3.79688 0.882812 3.5 0.882812 3.1875C0.882812 1.94531 1.89844 0.953125 3.16406 0.953125C3.65625 0.953125 4.19531 1.10156 4.52344 1.38281C4.67188 1.50781 4.75 1.65625 4.75 1.82031C4.75 1.96094 4.67969 2.08594 4.54688 2.17969L1.79688 4.19531C1.67969 4.28906 1.60156 4.3125 1.47656 4.3125ZM14.5156 4.3125C14.3984 4.3125 14.3125 4.28906 14.2031 4.19531L11.4453 2.17969C11.3203 2.08594 11.25 1.96094 11.25 1.82031C11.25 1.65625 11.3281 1.50781 11.4688 1.38281C11.7969 1.10156 12.3359 0.953125 12.8359 0.953125C14.1016 0.953125 15.1094 1.94531 15.1094 3.1875C15.1094 3.5 15.0625 3.80469 14.9766 3.99219C14.8828 4.17969 14.7188 4.3125 14.5156 4.3125ZM1.39844 17.2109C1.14062 16.9531 1.13281 16.5312 1.40625 16.2734L2.60938 15.0703C1.28906 13.7188 0.476562 11.8594 0.476562 9.82031C0.476562 5.66406 3.84375 2.29688 8 2.29688C12.1562 2.29688 15.5156 5.66406 15.5156 9.82031C15.5156 11.8594 14.7031 13.7188 13.3828 15.0703L14.5938 16.2734C14.8594 16.5312 14.8516 16.9531 14.6016 17.2109C14.3281 17.4766 13.9062 17.4688 13.6328 17.1953L12.3672 15.9453C11.1406 16.8203 9.625 17.3438 8 17.3438C6.36719 17.3438 4.85938 16.8203 3.625 15.9453L2.36719 17.1953C2.09375 17.4766 1.66406 17.4766 1.39844 17.2109ZM8 15.7969C11.2891 15.7969 13.9609 13.125 13.9609 9.82031C13.9609 6.52344 11.2891 3.85156 8 3.85156C4.69531 3.85156 2.02344 6.52344 2.02344 9.82031C2.02344 13.125 4.69531 15.7969 8 15.7969ZM4.59375 10.7812C4.25 10.7812 3.99219 10.5156 3.99219 10.1797C3.99219 9.84375 4.25 9.57812 4.59375 9.57812H7.39062V5.61719C7.39062 5.28125 7.65625 5.01562 7.99219 5.01562C8.33594 5.01562 8.60156 5.28125 8.60156 5.61719V10.1797C8.60156 10.5156 8.33594 10.7812 7.99219 10.7812H4.59375Z" fill="currentColor"/>
</svg>

))

export default Reminders

