import {
  FIND_WITH_INDEX_NOT_FOUND,
  DataStructure,
  Questions,
  QuestionLayout,
  Answers,
  WizardMode,
  Locations,
  TupleToUnion,
  Writable,
  WizardEditorStylesType,
  AnswerRelevance,
  Languages,
  Integrations,
  SignatureSecurityLevel,
} from '___types'
import { isObject } from 'utilities/helpers'

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// =================================================================== EXPORTS =================================================================== //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
export const initialWizardState = {
  initialized: false,
  mode: null as WizardMode | null,
  templateId: null as string | null,
  title: null as string | null,
  description: null as string | null,
  dataStructure: null as DataStructure | null,
  styles: null as WizardEditorStylesType | null,
  locations: null as Locations | null,
  showExternalQuestions: null as boolean | null,
  showInternalQuestions: null as boolean | null,
  questions: null as Questions | null,
  questionLayout: null as QuestionLayout | null,
  languages: null as Languages | null,
  approverList: null as string[] | null,
  integrationEntries: null as Record<string, string> | null,
  integrations: null as Integrations | null,
  splits: null as Record<string, string> | null,
  activeSplit: null as string | null,
  signatureSecurityLevel: null as SignatureSecurityLevel | null,
  answering: null as string | null,
  answers: null as Answers | null,
  answerRelevance: null as AnswerRelevance | null,
  questionOrder: null as string[] | null,
}
export type WizardState = typeof initialWizardState
// =================================================================== GENERAL =================================================================== //
export type { InitializeWizardPayload, UpdateWizardStatePayload, SetWizardTemplateIdPayload, UpdateWizardTitlePayload } from './general' // ====== TYPES
export { initializeWizard, resetWizard, updateWizardState, setWizardTemplateId, resetWizardTemplateId, updateWizardTitle } from './general' // === REDUCER
// =============================================================================================================================================== //

// =============================================================== EDITOR CONTENT =============================================================== //
export type { PotentionallyFoundSegmentType } from './editor-content' // ======================================================================== TYPES
export { initializeSections, applyParagraphNumbering } from './editor-content' // =============================================================== REDUCER
export { extractDataStructureSections, getSegmentById, getMappedSegmentIds } from './editor-content' // ========================================= HELPERS
// ============================================================================================================================================== //

// ============================================================= DOCUMENT GENERATION ============================================================= //
export type {
  ToggleDocumentLanguagePayload,
  ChooseIntegrationEntryPayload,
  UpdateIntegrationFieldValuesPayload,
  NavigateQuestionnaireToPayload,
  AnswerWithOptionPayload,
  UnanswerOptionPayload,
  ActivateSplitPayload,
} from './document-generation' // ================================================================================================================ TYPES
export {
  toggleDocumentLanguage,
  chooseIntegrationEntry,
  updateIntegrationFieldValues,
  resetIntegrationFieldValues,
  updateQuestionOrder,
  toggleShowExternalQuestions,
  navigateQuestionnaireForward,
  navigateQuestionnaireBackward,
  navigateQuestionnaireTo,
  answerWithOption,
  unanswerOption,
  evaluateMarkers,
  initializeAnswerRelevance,
  activateSplit,
} from './document-generation' // ================================================================================================================ REDUCER
export {
  generateQuestionOrder,
  getUnansweredQuestionInfo,
  getFormattedAnswerValue,
  getOptionPropertiesFromAnswer,
  getOptionPropertiesFromAnswerById,
  generateAnswerRelevance,
} from './document-generation' // ================================================================================================================ HELPERS
// =============================================================================================================================================== //

// ============================================================= TEMPLATE AUTOMATION ============================================================= //
export type {
  FoundMarkerType,
  UpdateWizardDescriptionPayload,
  AddLanguagePayload,
  RemoveLanguagePayload,
  AddApproverPayload,
  RemoveApproverPayload,
  InstantiateIntegrationPayload,
  DisconnectIntegrationPayload,
  AddIntegrationFieldPayload,
  RemoveIntegrationFieldPayload,
  UpdateDocumentSplitPayload,
  RemoveDocumentSplitPayload,
  SetSignatureSecurityLevelPayload,
  UpdateMarkerPayload,
  ConnectMarkerToValueSourcePayload,
  DisconnectMarkerFromValueSourcePayload,
  AddMarkerConditionalPayload,
  RemoveMarkerConditionalPayload,
  AddMarkerValueMapPayload,
  RemoveMarkerValueMapPayload,
  // AddMarkerModifierPayload,
  // RemoveMarkerModifierPayload,
  ToggleQuestionPrivacyPayload,
} from './template-automation' // ================================================================================================================ TYPES
export {
  updateWizardDescription,
  addLanguage,
  removeLanguage,
  addApprover,
  removeApprover,
  toggleLanguageSelect,
  instantiateIntegration,
  disconnectIntegration,
  addIntegrationField,
  removeIntegrationField,
  splitDocument,
  updateDocumentSplit,
  removeDocumentSplit,
  setSignatureSecurityLevel,
  updateMarker,
  connectMarkerToValueSource,
  disconnectMarkerFromValueSource,
  addMarkerConditional,
  removeMarkerConditional,
  addMarkerValueMap,
  removeMarkerValueMap,
  // addMarkerModifier,
  // removeMarkerModifier,
  toggleQuestionPrivacy,
} from './template-automation' // ================================================================================================================ REDUCER
export { getQuestionById, getMarkerByTypeAndId } from './template-automation' // ============================================= HELPERS
export default initialWizardState
// =============================================================================================================================================== //

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
//
//
//
//
//
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ========================================================== GENERAL HELPER FUNCTIONS ========================================================== //
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
export const deepAssign = (baseObject: Record<keyof any, unknown>, ...objectArray: Record<keyof any, unknown>[]): Record<keyof any, unknown> =>
  objectArray.reduce(
    (result, toApply) =>
      Object.entries(toApply).reduce((acc, [k, v]) => {
        if (!(isObject(acc[k]) && isObject(v))) return Object.assign(acc, { [k]: v })
        return Object.assign(acc, { [k]: deepAssign(acc[k] as Record<keyof any, unknown>, v as Record<keyof any, unknown>) })
      }, result),
    baseObject
  )

export const fullAssign = (...objectArray: Record<keyof any, unknown>[]): Record<keyof any, unknown> =>
  objectArray.reverse().reduce((acc, cur) => Object.assign(cur, acc))

export const findWithIndex = <T extends unknown>(
  array: T[],
  test: (param: T) => boolean,
  found = FIND_WITH_INDEX_NOT_FOUND[0] as never,
  index = FIND_WITH_INDEX_NOT_FOUND[1] as never
): [T, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  (array?.some((entry, i) => test(entry) && ((found = entry as never) || true) && ((index = i as never) || true)) || true) && [found, index]

//@ts-ignore
type ExtractFromNestedStructureReturnType<T> = [...([T, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND>), ...Record<keyof any, unknown>[]]
export const extractFromNestedStructure = <T extends Record<keyof any, unknown> & { id: string }, U extends string[]>(
  object: Record<keyof any, unknown>,
  nestArray: U,
  test: (entry: T) => boolean,
  knownIds: { [K in TupleToUnion<U>]?: string } = {},
  nestedResult = undefined as never
): ExtractFromNestedStructureReturnType<T> => {
  const key = nestArray[0]
  const array = object[key]
  //@ts-ignore
  if (!Array.isArray(array)) return FIND_WITH_INDEX_NOT_FOUND.slice() as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>
  const knownId = (knownIds as Record<string, string>)[key]
  const depthReached = nestArray.length === 1
  const check =
    (depthReached && test) ||
    (knownId && ((entry: T) => entry.id === knownId)) ||
    ((entry: Record<keyof any, unknown>) => {
      const inner = extractFromNestedStructure<T, string[]>(entry, nestArray.slice(1), test, knownIds)
      if (inner[0]) nestedResult = inner as never
      return Boolean(inner[0])
    })
  const found = findWithIndex(array, check)
  //@ts-ignore
  if (depthReached) return !found[0] ? (FIND_WITH_INDEX_NOT_FOUND as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>) : found
  if (knownId)
    //@ts-ignore
    return !found[0]
      ? (FIND_WITH_INDEX_NOT_FOUND as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>)
      : (extractFromNestedStructure<T, string[]>(found[0], nestArray.slice(1), test, knownIds).concat(
          found
        ) as ExtractFromNestedStructureReturnType<T>)
  //@ts-ignore
  return !found[0]
    ? (FIND_WITH_INDEX_NOT_FOUND as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>)
    : ((nestedResult as ExtractFromNestedStructureReturnType<T>).concat(found) as ExtractFromNestedStructureReturnType<T>)
}
