import ACTION_TYPES from '___store/actionTypes'
import { AnyAction, Dispatch, Store } from 'redux'

const interceptors = {
  [ACTION_TYPES.APP.GENERAL.LOGOUT]: {
    check: () => true,
    method: (store: Store, next: Dispatch<AnyAction>, action: AnyAction) => {
      const batchActions = {
        type: ACTION_TYPES.BATCH_ACTIONS,
        actionArray: [{ type: ACTION_TYPES.APP.GENERAL.RESET }, { type: ACTION_TYPES.WIZARD.RESET }],
      }
      store.dispatch(batchActions)
    },
  },
}

export { interceptors }
export default interceptors
