import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useUser } from 'queries/user/useUser'

export const useOnAuthRedirect = () => {
  const url = new URL(window.location.href)
  const redirectParam = url.searchParams.get('redirect')

  const redirectUrl = redirectParam ? decodeURIComponent(redirectParam) : null

  const user = useUser()
  const { replace } = useHistory()
  const onRedirect = () => {
    if (!user) return
    if (redirectUrl) return replace(redirectUrl)
    replace("/")
  }

  useEffect(onRedirect)
}
