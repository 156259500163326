import React, { FunctionComponent, RefCallback, useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, PendingProps, className } from './index'
import { UUID_REPLACE_ALL } from '___types'

const ZOOM = 0.8

const Pending: FunctionComponent<PendingProps> = React.memo(({ active = false }) => {
  const [animate, setAnimate] = useState<SVGAnimateElement | undefined>()
  const animateRef: RefCallback<SVGAnimateElement | undefined> = useCallback(node => node && setAnimate(node), [])
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  //@ts-ignore
  const maskId = useMemo(() => uuid().replaceAll(UUID_REPLACE_ALL, ''), [])
  //@ts-ignore
  const animationId = useMemo(() => uuid().replaceAll(UUID_REPLACE_ALL, ''), [])

  useEffect(() => {
    let timeout = undefined as unknown as NodeJS.Timeout
    // @ts-ignore
    if (active && animate) if (animate) timeout = setTimeout(() => animate.beginElement(), 100)
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [animate, active])

  return (
    <svg className={`${className} Pending`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <g>
            <animate
              //@ts-ignore
              ref={animateRef}
              id={animationId}
              dur="4000ms"
              begin=""
              repeatCount="indefinite"
            />
            <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="black" />
            <rect x="0" y="20" width={`${VIEWPORT_SIZE}`} height="21" fill="white">
              <animate
                attributeName="y"
                dur="4000ms"
                keyTimes="0; 0.6; 1"
                values="20; 41; 41"
                begin={`${animationId}.begin`}
                repeatCount="indefinite"
              />
              <animate
                attributeName="height"
                dur="4000ms"
                keyTimes="0; 0.6; 1"
                values="21; 0; 0"
                begin={`${animationId}.begin`}
                repeatCount="indefinite"
              />
            </rect>
            <rect x="0" y="80" width={`${VIEWPORT_SIZE}`} height="21" fill="white">
              <animate
                id={animationId}
                attributeName="y"
                dur="4000ms"
                keyTimes="0; 0.6; 1"
                values="80; 59; 59"
                begin={`${animationId}.begin`}
                repeatCount="indefinite"
              />
              <animate
                attributeName="height"
                dur="4000ms"
                keyTimes="0; 0.6; 1"
                values="0; 21; 21"
                begin={`${animationId}.begin`}
                repeatCount="indefinite"
              />
            </rect>
          </g>
        </mask>
      </defs>
      <g>
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="4000ms"
          calcMode="spline"
          keyTimes="0; 0.6; 1"
          keySplines="0 0 1 1; 0.7 0 0.3 1;"
          values="0 50 50; 0 50 50; 180 50 50"
          begin={`${animationId}.begin`}
          repeatCount="indefinite"
        />
        <line x1="20" x2="80" y1="8" y2="8" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
        <path
          d="M 20 15 l 24 28 v 14 l -24 28 h 60 l -24 -28 v -14 l 24 -28 Z"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinejoin="round"
          fill="none"
        />
        <line x1="20" x2="80" y1="92" y2="92" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
        <g mask={`url(#${maskId}`}>
          <path d="M 50 41 l 18 -21 h -36 Z" stroke="none" fill="currentColor" />
          <path d="M 50 59 l 18 21 h -36 Z" stroke="none" fill="currentColor" />
        </g>
      </g>
    </svg>
  )
})

export { Pending }
export default Pending
