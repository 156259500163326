import React, { FunctionComponent, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSubscriptions } from '___hooks'
import { Premium } from 'assets/svgIconComponents'
import { RadioButton } from 'components/CasusComponents'
import { wizardLayoutLeftPaneDocumentConfigurationPaywallClasses as classes } from 'Layouts/WizardLayout'

export const Paywall: FunctionComponent = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.configuration' })
  const { t: commonTranslate } = useTranslation()
  const [priceId, setPriceId] = useState(undefined as string | undefined)
  const {
    subscriptionList: { data: subscriptions },
  } = useSubscriptions()

  const render = useMemo(
    () =>
      subscriptions
        ?.slice()
        .sort((sub1, sub2) => Number(sub1.price) - Number(sub2.price))
        .map(subscription => (
          <RadioButton
            key={`WizardLayout-DocumentConfiguration-Paywall-subscription-entry-${subscription.priceId}`}
            id={subscription.priceId}
            className={classes.subscriptions.entry}
            value={subscription.priceId === priceId}
            onClick={() => setPriceId(subscription.priceId)}
            // tertiary={subscription.priceId === priceId}
            label={
              <>
                {commonTranslate(subscription.name)}
                <span>{`${(Number(subscription.price) / 100).toFixed(2)} ${subscription.currency.toUpperCase()}`}</span>
              </>
            }
          />
        )),
    [subscriptions, priceId, commonTranslate]
  )

  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>
        <Premium />
        {translate('subscriptions')}
      </span>
      <div className={classes.subscriptions.wrapper}>{render}</div>
      <span className={classes.description}>{translate(`paywall-${window.location.host.split(/[.:/]+?/g)[0]}`)}</span>
    </div>
  )
})

Paywall.displayName = 'WizardLayout-LeftPane-Document-Configuration-Paywall'

export default Paywall
