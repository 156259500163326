import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import Caret from 'assets/svgIconComponents/Caret'

const classes = {
  wrapper: 'Select-wrapper',
  optionsDiv: 'Select-optionsDiv',
  option: 'Select-option',
}

// const getVectors = (anchor, direction) => {
//   let result = null
//   const verticalSet = new Set(['top', 'bottom'])
//   const horizontalSet = new Set(['left', 'right'])
//   const anchorSetArray = [verticalSet, horizontalSet]
//   const anchorSet = new Set(
//     anchor.split('-').filter(a => {
//       if (anchorSetArray[0].has(a)) return anchorSetArray.shift()
//       if (anchorSetArray[1].has(a)) return anchorSetArray.pop()
//       return false
//     })
//   )
//   const directionSetArray = [verticalSet, horizontalSet]
//   const directionSet = new Set(
//     direction.split('-').filter(d => {
//       if (directionSetArray[0].has(d)) return directionSetArray.shift()
//       if (directionSetArray[1].has(d)) return directionSetArray.pop()
//       return false
//     })
//   )
//   if (!anchorSet.size) {
//     if (!directionSet.size)
//       result = [
//         [Array.from(verticalSet)[0], Array.from(horizontalSet)[0]],
//         [Array.from(verticalSet)[0], Array.from(horizontalSet)[0]],
//       ]
//     const directionArray = Array.from(directionSet)
//     const anchorArray = directionArray.map(d => Array.from([verticalSet, horizontalSet].find(s => s.has(d)))[0])
//     result = [anchorArray, directionArray]
//   } else {
//     const anchorArray = Array.from(anchorSet)
//     if (anchorArray.length === 1) {
//       const set = [verticalSet, horizontalSet].find(s => s.has(anchorArray[0]))
//       const directionArray = Array.from(directionSet).filter(d => set.has(d))
//       if (!directionArray.length) directionArray.push(Array.from(set)[0])
//       result = [anchorArray, directionArray]
//     } else {
//       const directionArray = anchorArray.reduce((acc, cur) => {
//         const set = [verticalSet, horizontalSet].find(s => s.has(cur))
//         if (!acc.some(d => set.has(d))) acc.push(Array.from(set)[0])
//         return acc
//       }, Array.from(directionSet))
//       result = [anchorArray, directionArray]
//     }
//   }
//   const sets = [verticalSet, horizontalSet]
//   result.forEach(r => r.sort((a, b) => sets.findIndex(s => s.has(a)) - sets.findIndex(s => s.has(b))))
//   const vectorObject = {
//     anchorVertical: result[0][0],
//     anchorHorizontal: result[0][1],
//     directionVertical: result[1][0],
//     directionHorizontal: result[1][1],
//   }
//   return Object.entries(vectorObject).reduce((acc, [key, value]) => (value ? Object.assign(acc, { [key]: value }) : acc), {})
// }

export const Select = React.memo(
  forwardRef((props, ref) => {
    const {
      className: cn,
      options = {},
      render,
      value,
      onChange,
      defaultValue,
      chooseMode = false,
      chooseRender = '',
      noCaret = false,
      noFocus = false,
      // noHover = false,
      caretLeft = false,
      // anchor = 'bottom',
      // direction = 'bottom',
      directionPriority = 'vertical',
      horizontalDirection = 'left',
      verticalDirection = 'down',
      cornerAnchor = true,
    } = props

    // const [node, setNode] = useState(null)
    // const setRef = useCallback(node => setNode(node || null), [])
    const [stateValue, setStateValue] = useState(defaultValue)

    const disabled = !Object.keys(options).length
    const className = useMemo(() => [classes.wrapper, cn].filter(cn => cn).join(' '), [cn])
    const componentId = useMemo(uuid, [])
    const computedValue = useMemo(() => value || stateValue, [value, stateValue])

    // const selectDisplay = useMemo(() => {
    //   if (!chooseMode) return options[computedValue] || ''
    //   if (typeof chooseRender === 'function') return chooseRender()
    //   return chooseMode ? chooseRender : options[computedValue] || ''
    // })

    const optionRender = useMemo(
      () =>
        Object.entries(options).map(([id, label]) => {
          if (typeof render === 'function') return render(id, label)
          return (
            <button
              key={`${componentId}-${id}`}
              className={classes.option}
              type="button"
              onClick={event => {
                event.target.blur()
                event.currentTarget.blur()
                if (onChange && typeof onChange === 'function' && (chooseMode || id !== computedValue)) onChange(id)
                else setStateValue(id)
              }}
            >
              <span>{label}</span>
            </button>
          )
        }),
      [options, render, componentId, onChange, chooseMode, computedValue]
    )

    useImperativeHandle(ref, () => ({ value: computedValue }), [computedValue])

    // useEffect(() => {
    //   if (node) Object.assign(node.dataset, getVectors(anchor, direction))
    // }, [node, anchor, direction])

    return (
      <div
        className={className}
        data-mirror={caretLeft}
        tabIndex={!(noFocus || disabled) ? 0 : -1}
        data-disabled={String(disabled)}
        data-focusable={String(!noFocus)}
        //data-hoverable={String(!noHover)}
      >
        <span>{chooseMode ? chooseRender : options[computedValue] || ''}</span>
        {!noCaret ? (
          <button type="button" tabIndex={-1} data-focusable={!noFocus} onFocusCapture={event => event.stopPropagation()} disabled={disabled}>
            <Caret />
          </button>
        ) : null}
        <div
          // ref={setRef}
          className={classes.optionsDiv}
          data-direction-priority={directionPriority}
          data-horizontal-direction={horizontalDirection}
          data-vertical-direction={verticalDirection}
          data-corner-anchor={cornerAnchor}
        >
          {optionRender}
        </div>
      </div>
    )
  })
)

Select.displayName = 'Select'

export default Select
