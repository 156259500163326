import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { classes } from '.'
import NewDocumentPanel from './components/NewDocumentPanel'
import { useTranslation } from 'react-i18next'
import TemplatePreview from '../DocumentFile/views/DocumentCreate/SelectTemplate/components/TemplatePreview'
import SignaturesPanel from './components/SignaturesPanel'
import RemindersPanel from './components/RemindersPanel'
import RecentDocumentsPanel from './components/RecentDocumentsPanel'
import { useUser } from 'queries/user/useUser'
import { MainLayout } from 'layout'
import SubscriptionAdPanel from './components/SubscriptionAdPanel'
import AiSuggestedTemplatesPanel from './components/AiSuggestedTemplatesPanel'
import NeedApprovalPanel from './components/NeedApprovalPanel'

const getIsMember = (user: any) => {
  const tenantMetadata = user?.tenantMetadata
  return tenantMetadata?.isMember
}

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const { location } = useHistory()
  const params = new URLSearchParams(location.search)
  const templateId = params.get('template')
  const user = useUser()

  useEffect(() => {
    document.title = `${t('u_dashboard')} | CASUS`
  }, [t])

  const tenant = window.location.host.split('.')[0]

  return (
    <MainLayout>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <p className={classes.title}>
              {t('dashboard_hi')} {user?.firstName}! {t('dashboard_what_to_do')}
            </p>
            {user?.privacy?.gptServicesEnabled ? <AiSuggestedTemplatesPanel /> : null}
            <NewDocumentPanel />
            <div className={classes.grid.wrapper}>
              <RecentDocumentsPanel />
              {tenant === 'sbv' && !getIsMember(user) && !user?.subs?.some(subscription => subscription.status === 'active') ? (
                <SubscriptionAdPanel />
              ) : (
                <div className={classes.grid.halfPanel}>
                  <SignaturesPanel />
                  <RemindersPanel />
                </div>
              )}
            </div>
            <div className={classes.grid.wrapper}>
              <NeedApprovalPanel />
            </div>
          </div>
        </div>
        {templateId && <TemplatePreview templateId={templateId} />}
      </div>{' '}
    </MainLayout>
  )
}

export default Dashboard

