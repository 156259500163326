import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import useStore, {
  SelectedDocumentIdSelector,
  SelectedTemplateIdSelector,
  DeselectDocumentsAction,
  DeselectTemplatesAction,
  ResetAppStateAction,
} from '___store'

import { MainLayoutProps, classes } from '.'
import { Header, LeftPane, Content, RightPane } from './components'

type UseStoreHookResultType = {
  selectedDocumentId: SelectedDocumentIdSelector
  selectedTemplateId: SelectedTemplateIdSelector
  deselectDocuments: DeselectDocumentsAction
  deselectTemplates: DeselectTemplatesAction
  resetAppState: ResetAppStateAction
}

export const MainLayout: FunctionComponent<MainLayoutProps> = React.memo(() => {
  const { pathname } = useLocation()
  const { selectedDocumentId, selectedTemplateId, deselectDocuments, deselectTemplates, resetAppState } = useStore(
    'selectSelectedTemplateId',
    'selectSelectedDocumentId',
    'deselectDocuments',
    'deselectTemplates',
    'resetAppState'
  ) as UseStoreHookResultType

  const directory = useMemo(() => pathname.split('/').filter(s => s)[0], [pathname])
  const showRightPane = (directory === 'documents' && selectedDocumentId) || (directory === 'templates' && selectedTemplateId)

  const keyDownHandler = useCallback(
    event => {
      if (event.key === 'Escape') {
        if (directory === 'documents') return deselectDocuments()
        if (directory === 'templates') return deselectTemplates()
      }
    },
    [directory, deselectDocuments, deselectTemplates]
  )

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      resetAppState()
    }
  }, [keyDownHandler, resetAppState])

  return (
    <main className={classes.wrapper} data-show-header data-show-left-pane data-show-right-pane={showRightPane ? '' : undefined}>
      <Header />
      <LeftPane />
      <Content />
      {showRightPane ? <RightPane /> : null}
    </main>
  )
})

MainLayout.displayName = 'MainLayout'

export default MainLayout
