import React from 'react'
import useStore, { WizardSplitsSelector, SplitDocumentAction, UpdateDocumentSplitAction, RemoveDocumentSplitAction } from '___store'

import { Cross, Split } from 'assets/svgIconComponents'
import { Button, Input } from 'components/CasusComponents'
import { classes } from '.'

type UseStoreHookResultType = {
  wizardSplits: WizardSplitsSelector
  splitDocument: SplitDocumentAction
  updateDocumentSplit: UpdateDocumentSplitAction
  removeDocumentSplit: RemoveDocumentSplitAction
}

export const Splits = React.memo(() => {
  const { wizardSplits, splitDocument, updateDocumentSplit, removeDocumentSplit } = useStore(
    'selectWizardSplits',
    'splitDocument',
    'updateDocumentSplit',
    'removeDocumentSplit'
  ) as UseStoreHookResultType
  return (
    <div className={`${classes.section.wrapper} splits`}>
      <span className={classes.section.title}>DOCUMENT SPLITS</span>
      {Object.entries(wizardSplits || {}).map(([id, label], i) => (
        <div key={`TemplateConfig-split-${id}`} className={classes.splits.entry}>
          <Input
            type="text"
            placeholder={`Split ${i + 1}`}
            defaultValue={label}
            onInput={event => updateDocumentSplit({ id, label: (event.target as HTMLInputElement).value })}
            debouncedInput={300}
          />
          <Button onClick={() => removeDocumentSplit({ id })}>
            <Cross />
          </Button>
        </div>
      ))}
      <Button onClick={splitDocument}>
        <Split />
        Split document
      </Button>
    </div>
  )
})

Splits.displayName = ''

export default Splits
