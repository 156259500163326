import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { ITemplate } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type RemoveTemplateVariables = string
export type RemoveTemplateContext = { mutationId: string }
export type RemoveTemplateMutationOptions = MutateOptions<void, unknown, RemoveTemplateVariables, RemoveTemplateContext>
export type RemoveTemplateFunctionType = (options?: RemoveTemplateMutationOptions) => void
// const removeTemplateMutationFunction = (variables: RemoveTemplateVariables) => templatesAPI.removeTemplate(variables)

export const useRemoveTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: ITemplate[] | undefined) => ITemplate[]) => queryClient.setQueryData([QUERY_KEYS.TEMPLATES], method)

  const onMutate = () => {
    const mutationId = uuid()
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original?: ITemplate }
    if (currentTemplate) {
      const originalTemplate = currentTemplate.original || currentTemplate
      const optimisticTemplate = Object.assign({}, originalTemplate, {
        mutationId,
        mutating: true,
        mutation: 'remove',
        original: originalTemplate,
      })
      queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticTemplate))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: RemoveTemplateVariables, context: RemoveTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
      queryClient.removeQueries([QUERY_KEYS.TEMPLATE, id])
      queryClient.resetQueries([QUERY_KEYS.TEMPLATE, id])
      // queryClient.setQueryData(templateQueryKey, currentTemplate.original)
      queryClient.cancelQueries([QUERY_KEYS.TEMPLATES])
      queryClient.invalidateQueries([QUERY_KEYS.TEMPLATES])
      queryClient.fetchQuery([QUERY_KEYS.TEMPLATES])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentTemplate.original))
    }
  }

  const onSuccess = (response: void, variables: RemoveTemplateVariables, context: RemoveTemplateContext | undefined) => {
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.TEMPLATE, id] })
    queryClient.resetQueries({ queryKey: [QUERY_KEYS.TEMPLATE, id] })
    updateListing(data => data?.filter(datum => datum.id !== id) || [])
  }

  const templateRemoveMutation = useMutation<void, unknown, RemoveTemplateVariables, RemoveTemplateContext>(
    [QUERY_KEYS.TEMPLATE, id],
    templatesAPI.removeTemplate,
    { onMutate, onError, onSuccess }
  )

  const removeMutationFunction: RemoveTemplateFunctionType = options => templateRemoveMutation.mutate(id!, options)

  return { remove: removeMutationFunction, removing: templateRemoveMutation.isLoading }
}

export default useRemoveTemplate
