import React from 'react'
import SidebarContainer from 'components/Sidebar/Sidebar.container'
import FolderTree from './SidebarFolderTree'

const SignedSidebar: React.FC = () => {
  return (
    <SidebarContainer
      actionButtonDisabled
      isLoading={false}
    >
      <FolderTree />
    </SidebarContainer>
  )
}

export default SignedSidebar
