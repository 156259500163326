import { PropsWithChildren, ReactNode } from 'react'
import Dropdown from './Dropdown'
// import DropdownOption from './Dropdown.Option'

import './style.scss'

// export const optionClasses = {
//   wrapper: 'Casus-Components-Dropdown-Option-wrapper',
// } as const
export const dropdownClasses = {
  wrapper: 'Casus-Components-Dropdown-wrapper',
  button: 'Casus-Components-Dropdown-button',
  options: {
    wrapper: 'Casus-Components-Dropdown-options-wrapper',
    // option: optionClasses,
  },
} as const

export type DropdownProps = PropsWithChildren<{
  className?: string
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  transparent?: boolean
  disabled?: boolean
  dataSet?: Record<keyof any, unknown>
  loading?: boolean
  showSpinner?: boolean
  label?: ReactNode
  hideCaret?: boolean
}>

export {
  Dropdown,
  // DropdownOption,
  dropdownClasses as classes,
}
export default Dropdown
