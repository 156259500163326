import React from 'react'
import { styled, Typography } from '@material-ui/core'

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  padding: theme.spacing(0.5, 1.5),
  fontWeight: 700,
  letterSpacing: 0.4,
  cursor: 'pointer',
  transition: 'all .2s',
  width: 'max-content',
  zIndex: 1,
  marginTop: 20,
  borderRadius: 4,
  alignSelf: 'center',
  '&:active': { background: 'transparent' },
  '&:hover': {
    background: theme.colorz.secondary[200],
  },
}))

interface Props {
  text: string
  isLoading?: boolean
  onClick: () => void
}

const LoadMoreButton: React.FC<Props> = ({ text, isLoading, onClick }) => {
  return (
    <Text
      style={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'auto' }}
      onClick={onClick}
    >
      {text}
    </Text>
  )
}

export default LoadMoreButton
