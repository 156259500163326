import { Icon, MenuPopover } from 'components/common'
import React, { useCallback, useState } from 'react'
import './style.scss'
import { POPUP_CONFIRM_DELETE_FILE, POPUP_CONFIRM_TRASH_FILE } from 'constants/confirmation-popups'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useHistory } from 'react-router-dom'
import { useDetails } from 'components/DetailsSidebar/provider/DetailsProvider'
import { useTemplatesDuplicate, useUpdateTemplate } from 'queries/templates'
import { useTemplateActions } from 'hooks/useTemplateActions'
import { isEditorIsOwner } from 'utils'
import { useUser } from 'queries/user/useUser'
import { useTranslation } from 'react-i18next'

const classes = {
  container: 'actions-container',
  row: 'actions-row',
  primary: 'primary-button',
  secondary: 'secondary-button',
  more: 'more-button',
}

export const TemplateActions = ({ data, type }) => {
  const update = useUpdateTemplate()
  const duplicate = useTemplatesDuplicate()
  const { push, location } = useHistory()
  const { clear } = useDetails()
  const { popup, close, setIsLoading } = useConfirmPopup()

  const { currentFolder } = useCurrentFolder('templates')
  const { type: folderType } = currentFolder

  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)

  // const superadmins = useMasterData()?.superadmins ?? []

  const user = useUser()
  const { isOwner, isEditor } = isEditorIsOwner(user?.id, data.authorId, data.sharedWith)

  const { onUseTemplate, onEditTemplate } = useTemplateActions()

  const deleteTemplate = useCallback(
    id => {
      const status = folderType === 'trashed' ? 'deleted' : 'trashed'
      const POPUP = folderType === 'trashed' ? POPUP_CONFIRM_DELETE_FILE : POPUP_CONFIRM_TRASH_FILE

      popup({
        ...POPUP,
        onConfirm: () => {
          setIsLoading(true)
          update.mutate(
            { id: id, data: { status } },
            {
              onSuccess: () => {
                close()
                clear()
              },
              onSettled: () => setIsLoading(false),
            }
          )
        },
        onCancel: close,
      })
    },
    [close, clear, popup, setIsLoading, folderType, update]
  )

  const structure = [
    [
      {
        type: 'primary',
        iconName: 'use',
        label: t('use'),
        action: () => onUseTemplate(data.id, { name: data.name, isCasus: data.isCasus }),
        disabled: data.questions?.length === 0,
      },
      {
        type: 'secondary',
        iconName: 'edit',
        label: t('edit'),
        action: () => onEditTemplate(data.id),
        disabled: !isOwner && !isEditor,
      },
    ],
    /* [
      {
        type: 'secondary',
        iconName: 'move',
        label: 'Move',
        action: () => push(`${location.pathname}?file=${data.id}&action=move`),
        disabled: !isOwner && !isEditor,
      },
      {
        type: 'secondary',
        iconName: 'duplicate',
        label: 'Duplicate',
        action: () => {
          duplicate.mutate(data)
          clear()
        },
        disabled: type === 'signature' || (!isOwner && !isEditor),
      },

      {
        type: 'secondary',
        iconName: 'trash',
        label: 'Delete',
        action: () => deleteTemplate(data.id),
        disabled: type === 'signature' || (!isOwner && !isEditor),
      },
    ], */
  ]

  const menuItems = [
    {
      label: t('move_template'),
      icon: 'move',
      handleClick: () => push(`${location.pathname}?file=${data.id}&action=move`),
      disabled: !isOwner && !isEditor,
    },
    {
      label: t('duplicate'),
      icon: 'duplicate',
      handleClick: () => {
        duplicate.mutate(data)
        clear()
      },
      disabled: type === 'signature' || (!isOwner && !isEditor),
    },
    {
      label: t('delete'),
      icon: 'trash',
      handleClick: () => deleteTemplate(data.id),
      disabled: type === 'signature' || (!isOwner && !isEditor),
    },
  ].filter(item => !item.disabled)

  const renderButton = (key, type, iconName, label, action, disabled) => {
    return (
      !disabled && (
        <button title={label} key={key} onClick={() => action()} type="button" className={classes[type]}>
          <Icon iconName={iconName} fill="#9ca4b5" /> {label}
        </button>
      )
    )
  }

  return (
    <div className={classes.container}>
      {structure.map((row, rowIndex) => (
        <div key={rowIndex} className={classes.row}>
          {row.map((item, index) => renderButton(index, item.type, item.iconName, item.label, item.action, item.disabled))}
          {menuItems.length > 0 && (
            <>
              <button title={'more'} key={'more'} onClick={e => setAnchorEl(e.currentTarget)} type="button" className={classes.more}>
                <Icon iconName="more" fill="#9ca4b5" />
              </button>

              <MenuPopover
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                menuItems={menuItems}
              />
            </>
          )}
        </div>
      ))}
    </div>
  )
}

