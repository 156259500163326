import React, { FunctionComponent, PropsWithChildren, useCallback, useContext, useState } from 'react'

import Modal, { OnOpenFunctionType, OnOpenOptions } from './Modal'

import './style.scss'

export type { RenderFunctionType } from './Modal'
// ===================================================================================================== //
// =========================================== MODAL CONTEXT =========================================== //
// ===================================================================================================== //
export type ModalProps = { connectCallback: (onOpen: OnOpenFunctionType) => void; disconnectCallback: () => void }
type ModalContextProps = PropsWithChildren<{ open: OnOpenFunctionType }>
const ModalContext = React.createContext<ModalContextProps>({} as ModalContextProps)
const ModalContextProvider: FunctionComponent = ({ children }) => {
  const [onOpen, setOnOpen] = useState<(...params: unknown[]) => void>()
  const openCallback = useCallback((...params: unknown[]) => typeof onOpen === 'function' && onOpen(...params), [onOpen])
  const connectCallback = useCallback((onOpen: OnOpenFunctionType) => typeof onOpen === 'function' && setOnOpen(() => onOpen), [])
  const disconnectCallback = useCallback(() => setOnOpen(undefined), [])
  return (
    <ModalContext.Provider value={{ open: openCallback }}>
      {children}
      <Modal connectCallback={connectCallback} disconnectCallback={disconnectCallback} />
    </ModalContext.Provider>
  )
}

const useModalContext = () => {
  const currentModalContext = useContext(ModalContext)
  if (!currentModalContext) {
    throw new Error('useModalContext has to be used within <ModalContext.Provider>')
  }
  return currentModalContext
}

export const useModal = (hookOptions?: OnOpenOptions) => {
  const { open } = useModalContext()
  return (options?: OnOpenOptions) => open(Object.assign({}, hookOptions, options))
}
// ===================================================================================================== //
// ===================================================================================================== //
// ===================================================================================================== //

export const classes = {
  wrapper: 'Modal-wrapper',
  backdrop: 'Modal-backdrop',
  body: 'Modal-body',
  header: 'Modal-header',
  content: 'Modal-content',
  footer: 'Modal-footer',
  focusCatcher: 'Modal-focusCatcher',
}

export { ModalContextProvider }
export default useModal
