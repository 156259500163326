import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, FolderProps, className } from './index'

const ZOOM = 1.2

const Folder: FunctionComponent<FolderProps> = React.memo(({ function: extra }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const maskId = useMemo(uuid, [])

  const d =
    'M 15 30 c 0 -10 0 -10 10 -10 h 20 c 5 0 5 0 10 7.5 c 5 7.5 5 7.5 10 7.5 h 10 c 10 0 10 0 10 10 v 20 c 0 10 0 10 -10 10 h -50 c -10 0 -10 0 -10 -10 Z'

  const cn = [className, 'Folder', extra].filter(s => s).join(' ')

  return (
    <svg className={cn} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <path d={d} stroke="none" fill="black" />
        </mask>
      </defs>
      <path d={d} stroke="currentColor" strokeWidth="5" fill="none" />
      <rect x="20" y="25" width="60" height="40" rx="5" ry="5" stroke="currentColor" strokeWidth="5" fill="none" mask={`url(#${maskId}`} />
      {extra === 'remove' ? <line x1="40" x2="60" y1="50" y2="50" stroke="currentColor" strokeWidth="5" /> : null}
      {extra === 'add' ? (
        <>
          <line x1="50" x2="50" y1="40" y2="60" stroke="currentColor" strokeWidth="5" />
          <line x1="40" x2="60" y1="50" y2="50" stroke="currentColor" strokeWidth="5" />
        </>
      ) : null}
      {extra === 'move' ? <path d="M 30 30 v 35 l 23 -17.5 Z" stroke="none" fill="currentColor" strokeLinejoin="miter" /> : null}
    </svg>
  )
})

export { Folder }
export default Folder
