import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import useStore from '___store'

import { Cross } from 'assets/svgIconComponents'
import { Button, ButtonGroup } from 'components'
import { QuestionOptionSelect, QuestionSelect } from 'Wizard/components/Configuration/common'
import { classes } from './index'

const SubQuestionFragment = React.memo(
  forwardRef(({ questionId }, ref) => {
    const { questionParent, removeSubQuestionStatus } = useStore(`selectQuestionParent[${questionId}]`, 'removeSubQuestionStatus')

    const [storeParentQuestionId, storeParentOptionId] = useMemo(() => questionParent.split(':').filter(id => id), [questionParent])
    const [parentQuestionId, setParentQuestionId] = useState(storeParentQuestionId)
    const [parentOptionId, setParentOptionId] = useState(storeParentOptionId)

    const resultingQuestionParent = `${parentQuestionId}:${parentOptionId}`

    useImperativeHandle(
      ref,
      () => (parentQuestionId && parentOptionId && questionParent !== resultingQuestionParent ? resultingQuestionParent : undefined),
      [parentQuestionId, parentOptionId, questionParent, resultingQuestionParent]
    )

    return (
      <div className={classes.fragmentWrapper}>
        <span className={classes.fragmentTitle}>Sub-question Configuration</span>
        <span className={classes.fragmentText}>Make this question a sub-question to another by selecting it and its option below.</span>
        <ButtonGroup className={classes.subQuestionFragmentButtonGroup}>
          <QuestionSelect
            className={classes.subQuestionFragmentSelect}
            filter={q => q.id !== questionId}
            value={parentQuestionId}
            onChange={qid => {
              setParentQuestionId(qid)
              setParentOptionId(null)
            }}
          />
          {parentQuestionId ? (
            <>
              <QuestionOptionSelect
                key={`SubQuestionFragment-question-${parentQuestionId}-options`}
                className={classes.subQuestionFragmentSelect}
                questionId={parentQuestionId}
                value={parentOptionId}
                onChange={setParentOptionId}
              />
              <Button
                className={classes.subQuestionFragmentButton}
                onClick={() => {
                  setParentQuestionId(null)
                  setParentOptionId(null)
                  if (questionParent) removeSubQuestionStatus({ id: questionId })
                }}
                colorScheme="transparent"
              >
                <Cross />
              </Button>
            </>
          ) : null}
        </ButtonGroup>
      </div>
    )
  })
)

SubQuestionFragment.displayName = 'Wizard-Configuration-AdvancedQuestionConfiguration-SubQuestionFragment'

export default SubQuestionFragment
