import UserApi from 'api/user.api'
import { queryKeys } from 'queries/queryKeys'
import { useQuery } from 'react-query'
// import { useHistory } from 'react-router-dom'

export const useLoadUser = () => {
  const token = localStorage.getItem('casusToken')
  // const history = useHistory()
  const query = useQuery(queryKeys.USER, UserApi.getMe, {
    retry: 1,
    enabled: !!token,
    onSuccess: data => {
      if (data) {
        if (data.beta.newDocxMicroservice) {
          if (window.location.hostname !== process.env.REACT_APP_DOMAIN_V2) {
            // show new version available screen
            // history.push('newversion')
          }
        } else {
          if (window.location.hostname !== process.env.REACT_APP_DOMAIN_V1) {
            // redirect to v1 domain
            window.location.assign(`https://${process.env.REACT_APP_DOMAIN_V1}/`)
          }
        }
      }
    },
    refetchOnWindowFocus: false,
  })
  return query
}
