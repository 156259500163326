import Signer from './Signer'

const classes = {
  container: {
    wrapper: 'Signer-Container-wrapper',
    text: 'Signer-Text-wrapper',
    icons: {
      wrapper: 'Signer-Icons-wrapper',
      icon: 'Signer-Icon-wrapper',
    },
  },
}

export { classes }
export default Signer
