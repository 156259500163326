import { Select } from 'components'
import React, { useCallback, useMemo } from 'react'
import useStore from '___store'

const QuestionOptionSelect = React.memo(props => {
  const {
    questionId,
    filter: filterTest,
    sort: sortFunction,
    append: appendOptionsObject,
    className,
    chooseRender = 'Select an option',
    caretLeft,
    directionPriority = 'vertical',
    horizontalDirection = 'left',
    verticalDirection = 'down',
    cornerAnchor = false,
    value,
    onChange,
  } = props

  const { questionOptions = [] } = useStore(`selectQuestionOptions[${questionId}]`)
  const selectedOption = useMemo(() => questionOptions.find(o => o.id === value), [questionOptions, value])

  const filterCallback = useCallback(o => (typeof filterTest === 'function' ? o?.filter(filterTest) : o), [filterTest])
  const sortCallback = useCallback(o => (typeof sortFunction === 'function' ? o?.sort(sortFunction) : o), [sortFunction])
  const questionOptionSelectOptionObject = useMemo(
    () =>
      Object.assign(
        sortCallback(filterCallback(questionOptions))
          ?.filter(o => o.id !== value)
          .reduce((obj, o) => Object.assign(obj, { [o.id]: o.text || 'User input...' }), {}) || {},
        appendOptionsObject
      ),
    [sortCallback, filterCallback, questionOptions, value, appendOptionsObject]
  )

  const fallbackText = useMemo(
    () => (Object.keys(questionOptionSelectOptionObject).length ? chooseRender : 'No options available'),
    [questionOptionSelectOptionObject, chooseRender]
  )
  const selectText = useMemo(() => (selectedOption ? selectedOption.text || 'User input...' : fallbackText), [selectedOption, fallbackText])

  return (
    <Select
      className={className}
      chooseMode={true}
      chooseRender={selectText}
      caretLeft={caretLeft}
      directionPriority={directionPriority}
      horizontalDirection={horizontalDirection}
      verticalDirection={verticalDirection}
      cornerAnchor={cornerAnchor}
      value={value}
      onChange={id => onChange(id)}
      options={questionOptionSelectOptionObject}
    />
  )
})

export default QuestionOptionSelect
