import { useQuery, QueryFunctionContext, useQueryClient, InfiniteData, UseQueryResult } from 'react-query'

import { MAPPED_DOCUMENTS_FILTERS, IDocument, ITemplate } from '___types'
import { documentsAPI } from '___api'
import { QUERY_KEYS, STALE_TIME, useFetchTemplate } from '___queries'
// import { extractDataStructureCustomStyles } from '___utilities/dataStructure'
// import { evaluateAllMarkers } from '___store/storeSegments/wizard/helpers/index'
// import { applyAnswerValuesToDataStructure } from 'Wizard/helpers'

const getDocumentQueryFunction = ({ queryKey }: QueryFunctionContext) => documentsAPI.getDocument(queryKey[1] as string, queryKey[2] as boolean)

// const generatePartialPreviewInfo = (document?: IDocument, template?: ITemplate) => {
// if (!(document && template)) return null
// console.log(document)
// const { answers } = document
// const { dataStructure, locations, questions, cssData } = template
// const pseudoState = { mode: 'document-generation', locations, questions, answers }
// console.log(pseudoState.answers)
// const evaluatedMarkers = evaluateAllMarkers(pseudoState).locations
// const previewDataStructure = applyAnswerValuesToDataStructure(dataStructure, evaluatedMarkers, questions, answers)
// const partialPreview = previewDataStructure.segments.slice(0, 20)
// const customStyles = extractDataStructureCustomStyles({ segments: partialPreview })
// console.log(partialPreview)
// console.log(cssData)
// console.log(customStyles)
// return null
// }

export const useFetchDocument = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const documentQuery = useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []),
    queryFn: getDocumentQueryFunction,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: () => {
      const allDocuments = Object.values(MAPPED_DOCUMENTS_FILTERS).reduce((allDocuments: IDocument[], filter) => {
        const documentsQuery = queryClient.getQueryData([QUERY_KEYS.DOCUMENTS, filter]) as InfiniteData<IDocument[]>
        const documents = (documentsQuery?.pages || []).reduce((filterDocuments, page) => filterDocuments.concat(page), [])
        return allDocuments.concat(documents) as IDocument[]
      }, [])
      return allDocuments.find(document => document.id === id)
    },
    onSuccess: (document?: IDocument) => {
      // const { templateId } = document || {}
      // const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, templateId]) as ITemplate
      // if (template) {
      // const partialPreview = generatePartialPreviewInfo(document, template)
      // console.log(partialPreview)
      // }
    },
  })
  const { data: document } = documentQuery
  const templateId = document?.templateId
  const templateQuery = useFetchTemplate(templateId, publicFlow)
  // const templateQuery = useQuery({
  //   queryKey: [QUERY_KEYS.TEMPLATE, templateId],
  //   queryFn: getTemplateQueryFunction,
  //   enabled: Boolean(templateId),
  //   staleTime: STALE_TIME,
  //   refetchOnWindowFocus: false,
  //   // onSuccess: template => {
  //   //   // const partialPreview = generatePartialPreviewInfo(document!, template)
  //   // },
  // })
  // const { data: template } = templateQuery
  // if (false) console.log(template)

  return Object.assign(documentQuery, { template: templateQuery }) as UseQueryResult<IDocument> & { template: UseQueryResult<ITemplate> }
}

export default useFetchDocument
