import React, { FunctionComponent } from 'react'

import { wizardLayoutFooterClasses as classes } from '../..'

export const Footer: FunctionComponent = React.memo(() => {
  return <div className={classes.wrapper}></div>
})

Footer.displayName = 'WizardLayout-Footer'

export default Footer
