import { useMutation, useQueryClient } from 'react-query'

import { useToast } from 'hooks/useToast'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { queryKeys } from 'queries/queryKeys'
import TemplatesApi from 'api/templates.api'
import { useUser } from 'queries/user/useUser'
import { ITemplate } from 'types'
import { useTranslation } from 'react-i18next'

interface Data {
  pageParams: [string | undefined] | never[]
  pages: ITemplate[][]
}

export const useUpdateTemplate = () => {
  const {
    currentFolder: { type, id },
  } = useCurrentFolder('templates')
  const toast = useToast()
  const user = useUser()
  const docXMicro = !!user?.beta?.newDocxMicroservice
  const keys = [queryKeys.TEMPLATES, { type, folderId: id }]
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const mutation = useMutation(queryKeys.TEMPLATE, (payload: any) => TemplatesApi.update({ ...payload, new: docXMicro }), {
    onMutate: async templateToUpdate => {
      await queryClient.cancelQueries(keys)

      const previousTemplates = queryClient.getQueryData<Data>(keys)

      const fullTemplate = queryClient.getQueryData<ITemplate>([queryKeys.TEMPLATE, templateToUpdate.id])

      let tmpTemplateToUpdate = {
        ...fullTemplate,
        ...templateToUpdate,
        isLoading: true,
      }

      if (previousTemplates) {
        queryClient.setQueryData<Data>(keys, data => {
          const pages = data?.pages ?? []
          const pageOne = pages[0] ?? []
          const index = pageOne.findIndex(e => e.id === templateToUpdate.id)
          pageOne.splice(index, 1)
          pageOne.unshift(tmpTemplateToUpdate)
          pages[0] = pageOne
          return data ? { ...data, pages } : { pageParams: [], pages: [] }
        })
      }

      return { previousTemplates }
    },
    onSuccess: (updatedTemplate, variables) => {
      queryClient.invalidateQueries(keys).then(() => toast('success', t('toast_template_edited_successfully')))
      queryClient.setQueryData([queryKeys.TEMPLATE, updatedTemplate.id], updatedTemplate)
    },
    onError: () => {
      toast('error', 'default')
    },
  })

  return mutation
}
