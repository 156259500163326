import React from 'react'

import { className } from './index'

const Deselect = React.memo(() => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <rect
      x="15"
      y="15"
      width="70"
      height="70"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="round"
      fill="none"
      strokeDasharray="12 20"
      strokeDashoffset="8"
      rx="8"
    />
    <line x1="35" y1="35" x2="65" y2="65" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
    <line x1="35" y1="65" x2="65" y2="35" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
  </svg>
))

export { Deselect }
export default Deselect
