import { useMemo } from 'react'

import { tenantPaywallCheck } from '___utilities'
import { useFetchTemplate } from '___queries'
import useUser from './useUser'

export const useTemplatePaywallBlocked = (id?: string | null, publicFlow: boolean = false) => {
  const { data: template } = useFetchTemplate(id, publicFlow)
  const { data: user } = useUser(publicFlow)

  const paywallBlocked = useMemo(() => {
    const paywall = template?.isPremium && !(user && !tenantPaywallCheck(user))
    const paid = user?.subscriptions?.some(subscription => subscription.status === 'active')
    return Boolean(paywall && !paid)
  }, [template, user])

  return !publicFlow && paywallBlocked
}

export default useTemplatePaywallBlocked
