import React, { FunctionComponent } from 'react'

import { DOCUMENTS_FILTERS } from '___types'
import { FilteredTemplatesAccordionProps } from '.'
import TemplatesAccordion from './TemplatesAccordion'

export const Mine: FunctionComponent<FilteredTemplatesAccordionProps> = React.memo(({ onClick }) => {
  return <TemplatesAccordion filter={DOCUMENTS_FILTERS.MINE} onClick={onClick} />
})

Mine.displayName = 'Casus-Components-Accordion-Templates-Mine'

export default Mine
