import actions from './actions'

const initialState = {
  services: {},
}

const setServices = (state, payload) => Object.assign({}, state, { services: payload })

const clearServices = state => Object.assign({}, state, { services: {} })

const reducer = (state = initialState, { type, payload }) => {
  if (type === actions.SERVICE_CHOSEN_ID_SET) return setServices(state, payload)
  if (type === actions.SERVICE_CHOSEN_IDS_CLEARED) return clearServices(state)
  return state
}

export default reducer
