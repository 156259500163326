import { QueryFunctionContext, useQuery } from 'react-query'

import { userAPI } from '___api'
import { QUERY_KEYS } from '___queries'

const getUserQueryFunction = ({ queryKey }: QueryFunctionContext) => userAPI.getUser(queryKey[1] as boolean)

export const useFetchUser = (publicFlow: boolean = false) => {
  const userQuery = useQuery({
    queryKey: [QUERY_KEYS.USER].concat(publicFlow ? 'public' : []),
    queryFn: getUserQueryFunction,
    staleTime: publicFlow ? Infinity : 0,
    refetchOnWindowFocus: !publicFlow,
  })
  return userQuery
}

export default useFetchUser
