import React, { FunctionComponent, useMemo } from 'react'

import { Caret } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { DropdownProps, classes } from '.'

export const Dropdown: FunctionComponent<DropdownProps> = React.memo(props => {
  const { className: cn = '', disabled, loading, showSpinner, label, hideCaret, children } = props

  const className = useMemo(() => [classes.wrapper, cn].filter(c => c).join(' '), [cn])
  const emphasis = ['emphasized', 'primary', 'secondary', 'tertiary', 'transparent'].find(prop => props[prop as keyof DropdownProps])
  const baselineDataSet = useMemo(() => ({ emphasis, disabled, loading, showSpinner }), [emphasis, disabled, loading, showSpinner])
  const dataSet = useMemo(() => Object.assign({}, baselineDataSet, props.dataSet), [baselineDataSet, props.dataSet])

  return (
    <div className={className}>
      <Button className={classes.button} onClick={() => {}} dataSet={dataSet}>
        {label}
        {hideCaret ? null : <Caret />}
      </Button>
      <div className={classes.options.wrapper}>{children}</div>
    </div>
  )
})

Dropdown.displayName = 'Casus-Components-Dropdown'

export default Dropdown
