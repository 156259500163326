import React from 'react'
import OptionsList from '../../components/OptionList/OptionList'

import { CustomButton } from 'components/common'
import {
  btnContent,
  forms,
} from 'pages/Authenticated/Template/constants/content'
import { Container, Heading } from './QuestionCheckbox.styles'
import { IOption, OptionType } from 'types'
import { TemplateOptionsState } from 'pages/Authenticated/Template/views/TemplateEdit/services'
import { useTranslation } from 'react-i18next'

interface Props {
  options: IOption[]
  values: TemplateOptionsState
  textSelectionIsActive: boolean
  currentSelectionId: string | null
  deleteOptionDisabled: boolean
  handleTextChange: React.ChangeEventHandler<HTMLInputElement>
  handleFocusTextInput: React.FocusEventHandler<HTMLInputElement>
  handleAddOption: React.MouseEventHandler<HTMLButtonElement>
  handleTextSelectClick: (id: string) => void
  handleCheckboxChange: (id: string) => void
  handleDeleteOption: (option: OptionType) => void
}

const QuestionCheckbox: React.FC<Props> = ({ handleAddOption, ...otherProps }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Heading variant='h5'>{t(forms.heading)}</Heading>
      <OptionsList actions={true} {...otherProps} />
      <CustomButton
        btntype='white'
        onClick={handleAddOption}
        style={{ marginTop: 8 }}
        id='template-creation-add-option-button'
      >
        {t(btnContent.newOption)}
      </CustomButton>
    </Container>
  )
}

export default QuestionCheckbox
