import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { wizardLayoutLeftPaneDocumentReviewClasses as classes } from 'Layouts/WizardLayout'

const Rename: FunctionComponent = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.review' })

  return (
    <div className={classes.message.wrapper}>
      <span className={classes.message.primary}>{translate('rename-message')}</span>
    </div>
  )
})

Rename.displayName = 'WizardLayout-LeftPane-Document-Review-Rename'

export default Rename
