import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, { WizardLanguagesSelector, ToggleDocumentLanguageAction } from '___store'

import { LANGUAGE_INFO, LanguageValue } from '___types'
import { Check } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { wizardLayoutLeftPaneDocumentConfigurationLanguagesClasses as classes } from 'Layouts/WizardLayout'

type UseStoreHookResultType = {
  wizardLanguages: WizardLanguagesSelector
  toggleDocumentLanguage: ToggleDocumentLanguageAction
}

export const Languages: FunctionComponent = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.configuration' })

  const { wizardLanguages, toggleDocumentLanguage } = useStore('selectWizardLanguages', 'toggleDocumentLanguage') as UseStoreHookResultType

  return wizardLanguages?.available?.length ? (
    <div className={classes.wrapper}>
      <span className={classes.title}>{translate('languages')}</span>
      <div className={classes.entries}>
        {wizardLanguages?.available?.map(language => {
          const active = wizardLanguages?.selected?.includes(language)
          const info = LANGUAGE_INFO[language as LanguageValue]
          return (
            <Button
              key={`WizardLayout-LeftPane-Document-Configuration-Languages-${language}-toggle-button`}
              onClick={() => toggleDocumentLanguage(language as LanguageValue)}
              dataSet={{ active }}
              emphasized={active}
            >
              {active ? <Check /> : null}
              <img src={info.icon} alt={info.label} />
            </Button>
          )
        })}
      </div>
    </div>
  ) : null
})

Languages.displayName = 'WizardLayout-LeftPane-Document-Configuration-Languages'

export default Languages
