import { useCallback, useEffect, useRef, useState } from 'react'
import useStore, { WizardLocationsSelector } from '___store'

import { SubRecord, TextLocation } from '___types'
import { debounceFunction } from 'utilities/helpers'

const getParagraphTextContent = (node: Node) => {
  if (!(node.nodeName === 'PRE' && (node as Element).id)) return ''
  const getChildTextContent = (child: ChildNode): string => {
    if (child.nodeName === '#text') return child.textContent || ''
    if (child.childNodes.length) return Array.from(child.childNodes).reduce((text, node) => `${text}${getChildTextContent(node)}`, '')
    return ''
  }
  return getChildTextContent(node as ChildNode)
}

const isTextMarkerInvalid = (offsets: [number, number], relevantLocations: TextLocation[]) =>
  relevantLocations.some(
    ({ range }) =>
      !(offsets[1] <= range[0] || offsets[0] >= range[1] || offsets[0] === range[0] || offsets[1] === range[1]) &&
      Boolean((-1) ** (Number(offsets[0] > range[0]) + Number(offsets[1] > range[1])) + 1)
  )

const getParagraphOffsets = (
  node: Node,
  startNode: Node,
  endNode: Node,
  accumulated: [number, number] = [0, 0],
  offsetsFound: [boolean, boolean] = [false, false]
): [number, number] => {
  if (node.nodeName === '#text') {
    if (!offsetsFound[0]) accumulated[0] += offsetsFound[0] ? 0 : node.textContent?.length || 0
    if (!offsetsFound[1]) accumulated[1] += node.textContent?.length || 0
  } else if (node.childNodes.length)
    Array.from(node.childNodes).some(child => {
      if (child === startNode) offsetsFound[0] = true
      if (child === endNode) offsetsFound[1] = true
      return getParagraphOffsets(child, startNode, endNode, accumulated, offsetsFound) && offsetsFound[0] && offsetsFound[1]
    })
  return accumulated
}

const NODE_NAME_STOPS = ['BODY', 'HTML', 'MAIN', 'SECTION']

const getParagraphParent = (element: Element): Element | void => {
  const nodeName = element.nodeName
  if (element.id && nodeName === 'PRE') return element
  if (NODE_NAME_STOPS.includes(nodeName)) return
  if (element.parentElement) return getParagraphParent(element.parentElement)
  return
}

type UseStoreHookResultType = { wizardLocations: WizardLocationsSelector }

export const useSelection = () => {
  const timeout = useRef(undefined as unknown as NodeJS.Timeout)
  const [textLocation, setTextLocation] = useState(undefined as (SubRecord<TextLocation> & { parent: Element }) | undefined)
  const { wizardLocations } = useStore('selectWizardLocations') as UseStoreHookResultType
  const {
    text,
    // segments
  } = wizardLocations || {}

  const debouncedCallback = useCallback(
    debounceFunction(() => {
      const selection = document.getSelection()
      if (!selection || selection.isCollapsed) return setTextLocation(undefined)
      const { startContainer, endContainer, commonAncestorContainer, startOffset, endOffset } = selection.getRangeAt(0)
      const parentParagraph = getParagraphParent(commonAncestorContainer as Element)
      if (!parentParagraph) return setTextLocation(undefined)
      const offsets = getParagraphOffsets(parentParagraph as Node, startContainer, endContainer, [startOffset, endOffset])
      if (text && text[parentParagraph.id] && isTextMarkerInvalid(offsets, text[parentParagraph.id])) return setTextLocation(undefined)
      setTextLocation({ parent: parentParagraph, range: offsets, contentText: getParagraphTextContent(parentParagraph).slice(...offsets) })
    }) as () => NodeJS.Timeout,
    [text]
  )

  const selectionHandler = useCallback(() => {
    timeout.current = debouncedCallback()
  }, [debouncedCallback])

  useEffect(() => {
    document.addEventListener('selectionchange', selectionHandler)
    return () => {
      clearTimeout(timeout.current)
      document.removeEventListener('selectionchange', selectionHandler)
    }
  }, [selectionHandler])

  return { text: textLocation, segments: undefined }
}

export default useSelection
