import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'

const classes = {
  wrapper: 'Switch-wrapper',
  text: 'Switch-text',
  pin: 'Switch-pin',
}

export const Switch = React.memo(
  forwardRef((props, ref) => {
    const { className: cn, defaultValue = false, value, onChange, labels = [] } = props
    const [falseLabel = '', trueLabel = ''] = labels
    const [state, setState] = useState(value ?? defaultValue)

    const result = useMemo(() => value ?? state, [value, state])
    useImperativeHandle(ref, () => result, [result])

    const className = useMemo(() => [classes.wrapper, cn].filter(cn => cn).join(' '), [cn])

    return (
      <button className={className} type="button" onClick={() => (typeof onChange === 'function' ? onChange(!result) : setState(!result))}>
        <span className={classes.text} data-active={!result}>
          {falseLabel}
        </span>
        <span className={classes.text} data-active={result}>
          {trueLabel}
        </span>
        <div className={classes.pin} data-switched={result} />
      </button>
    )
  })
)

Switch.displayName = 'Switch'

export default Switch
