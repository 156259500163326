import React, { FunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useStore, { UpdateWizardStateAction } from '___store'

import { DOCUMENT_DIRECTORY, DOCUMENT_FLOW_MODES } from '___types'
import { MyTemplatesGrid, SharedTemplatesGrid } from 'components/CasusComponents'
import { wizardLayoutLeftPaneDocumentTemplateSelctionClasses as classes } from 'Layouts/WizardLayout'

type UseStoreHookResultType = { updateWizardState: UpdateWizardStateAction }

export const TemplateSelection: FunctionComponent = React.memo(() => {
  const history = useHistory()
  const { updateWizardState } = useStore('updateWizardState') as UseStoreHookResultType
  const onClick = useCallback(
    (_, templateId) => {
      if (!templateId) return
      updateWizardState({ templateId, mode: DOCUMENT_FLOW_MODES.NEW })
      history.push(`${DOCUMENT_DIRECTORY}/${templateId}/new?step=configure`)
    },
    [updateWizardState, history]
  )
  return (
    <div className={classes.wrapper}>
      <MyTemplatesGrid onClick={onClick} />
      <SharedTemplatesGrid onClick={onClick} />
    </div>
  )
})

TemplateSelection.displayName = 'WizardLayout-LeftPane-Document-TemplateSelection'

export default TemplateSelection
