import i18next, { i18n as i18nInstance } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import english from 'locales/en_GB.json'
import german from 'locales/de_CH.json'
import french from 'locales/fr_FR.json'
import italian from 'locales/it_IT.json'

const createI18n = (): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next)

  i18n.use(LanguageDetector).init({
    fallbackLng: 'de_CH',
    supportedLngs: ['de_CH', 'en_GB', 'fr_FR', 'it_IT'],
    resources: {
      de_CH: {
        translation: german,
      },
      en_GB: {
        translation: english,
      },
      fr_FR: {
        translation: french,
      },
      it_IT: {
        translation: italian
      }
    },
    debug: false,
    react: { useSuspense: true },
    // have a common namespace used around the full app
    defaultNS: 'translation',
    // keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
  })

  return i18n
}

export const i18n = createI18n()
