import React from 'react'

import { className } from './index'

const StringInput = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <text
      x="20"
      y="50"
      dy="8"
      fontFamily="Times New Roman"
      fontSize="100"
      fontWeight="1000"
      dominantBaseline="middle"
      textAnchor="middle"
      fill="currentColor"
    >
      a
    </text>
    <line x1="50" y1="20" x2="80" y2="20" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" />
    <line x1="65" y1="20" x2="65" y2="80" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" />
    <line x1="50" y1="80" x2="80" y2="80" stroke="currentColor" strokeWidth="6" strokeLinecap="butt" />
  </svg>
))

export { StringInput }
export default StringInput
