import { useMemo } from 'react'
import useStore, {
  WizardDataStructureSelector,
  WizardSectionByIndexSelector,
  WizardSectionPageContentIdsByIndicesSelector,
  WizardState,
  getMappedSegmentIds,
} from '___store'

import { useEditorContext } from '..'

type UseStoreHookResultType = { wizardSectionPageContentIdsByIndices?: WizardSectionPageContentIdsByIndicesSelector }

export const usePage = (sectionIndex?: number, pageIndex?: number): string[] => {
  const editorContext = useEditorContext()
  const { mode, dataStructure, numbering, locations } = editorContext
  const { sections } = dataStructure || ({} as WizardDataStructureSelector)!
  const contextSection = sections && typeof sectionIndex === 'number' && !isNaN(sectionIndex) && sections[sectionIndex]
  const { pages } = contextSection || ({} as WizardSectionByIndexSelector)!
  const contextPageRange = pages && typeof pageIndex === 'number' && !isNaN(pageIndex) && pages[pageIndex]

  const staticPseudoState = Object.assign({}, { dataStructure, numbering } as unknown) as WizardState
  if (mode === 'interact') Object.assign(staticPseudoState, { locations })

  const mappedContentIds = getMappedSegmentIds(staticPseudoState, 'root', mode)
    .slice(...(contextPageRange || [0, 0]))
    .map(id => id.split(';').pop()!)
  const contextPageContentIds = mappedContentIds && Array.from(new Set(mappedContentIds)).join('; ')

  const { wizardSectionPageContentIdsByIndices = '' } = useStore(
    !contextPageContentIds ? `selectWizardSectionPageContentIdsByIndices[${sectionIndex},${pageIndex}]` : undefined
  ) as UseStoreHookResultType

  const result = contextPageContentIds || wizardSectionPageContentIdsByIndices
  const usePageReturn = useMemo(() => result.split('; ').filter(id => id), [result])

  return usePageReturn
}

export default usePage
