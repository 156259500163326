import React from 'react'
import useStore from '___store'

import { classes } from '../../../index'
import { Input } from 'components'

const QuestionText = React.memo(({ questionId }) => {
  const { questionById: { text = '' } = {}, updateQuestion } = useStore(`selectQuestionById[${questionId}]`, 'updateQuestion')
  const {
    wrapper: wrapperClass,
    // title: titleClass,
    text: textClass,
    questionText: { input: inputClass },
  } = classes.configEntry.questionConfiguration.fragment
  return (
    <div className={wrapperClass}>
      {/* <span className={titleClass}>Question Text</span> */}
      <span className={textClass}>Question Text:</span>
      <Input
        className={inputClass}
        placeholder="Question text goes here (e.g. What is the name of the company?)"
        defaultValue={text}
        onChange={text => updateQuestion({ id: questionId, text })}
        needsConfirmation
        blurShowUnmodified
      />
    </div>
  )
})

QuestionText.displayName = 'Wizard-Configuration-QuestionConfiguration-QuestionText'

export default QuestionText
