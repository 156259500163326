import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { SignedLayout } from '../../layout/Layout'

import { useTranslation } from 'react-i18next'
import SignatureList from './SignatureList'
import { useLoadSignatureDocuments } from 'queries/documents/useLoadSignatureDocuments'
import { Loader } from 'components/common'

const SignatureListPage: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const filter = params.get('filter') || ''
  const { data: signatures } = useLoadSignatureDocuments(filter)

  useEffect(() => {
    document.title = `${t('u_signatures')} | CASUS`
  }, [t])

  return <SignedLayout>{!signatures ? <Loader /> : <SignatureList filter={filter} />}</SignedLayout>
}

export default SignatureListPage

