import React from 'react'
import { combineReducers, compose, createStore } from 'redux'
import { hookSubscribeEnhancer, interceptorMiddlewareEnabler, middlewareEnhancer } from './enhancers'

import { reducer as wizardReducer } from './storeSegments/wizard/reducer'
import { reducer as appReducer } from './storeSegments/app/reducer'

const rootReducer = combineReducers({ wizard: wizardReducer, app: appReducer })
const storeEnhancer = compose(hookSubscribeEnhancer, interceptorMiddlewareEnabler, middlewareEnhancer)

const store = createStore(rootReducer, storeEnhancer)

export const StoreContext = React.createContext(null)

export const StoreProvider = ({ children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
