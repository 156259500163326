// import { NESTED_STRUCTURE_KEYS } from 'Wizard/constants'
import { getSegmentById } from './helpers/editor-content'

// const getLocationParent = (locations, locationId, parent = undefined) =>
//   locations.find(([parentId, array]) => array.some(({ id }) => id === locationId && (parent = parentId)))
//     ? getLocationParent(locations, parent)
//     : locationId

// const getLocationRangeAndParent = (locations, locationId, locationRange = undefined, parent = undefined) =>
//   (locations.some(([parentId, array]) => array.some(({ id, range }) => id === locationId && (locationRange = range) && (parent = parentId))) ||
//     true) && [locationRange, getLocationParent(locations, parent)]

// const findSegmentById = (array = [], id = '', res = undefined) =>
//   array.find(segment =>
//     segment.id === id ? (res = segment) : NESTED_STRUCTURE_KEYS.find(key => Array.isArray(segment[key]) && (res = findSegmentById(segment[key], id)))
//   ) && res

// const filterLocations = (filterRange = [], locations = []) =>
//   locations
//     .slice()
//     .map(({ id, range }) => {
//       const [filterStart, filterEnd] = filterRange
//       const [locationStart, locationEnd] = range
//       const originalMarkerLength = locationEnd - locationStart
//       const markerLength = Math.max(Math.min(filterEnd, locationEnd) - Math.max(filterStart, locationStart), 0)
//       if (!markerLength) return null
//       const markerRangeStart = Math.max(filterStart - locationStart, 0)
//       const markerRangeEnd = markerRangeStart + markerLength
//       const markerRange = [markerRangeStart, markerRangeEnd]
//       const containerLength = filterEnd - filterStart
//       const containerRangeStart = Math.max(locationStart - filterStart, 0)
//       const containerRangeEnd = Math.min(locationEnd - filterStart, containerLength)
//       const containerRange = [containerRangeStart, containerRangeEnd]
//       const start = locationStart >= filterStart
//       const end = locationEnd <= filterEnd
//       return { id, markerRange, containerRange, originalMarkerLength, start, end }
//     })
//     .filter(l => l)

// const mapSegmentsWithLocationInfo = (segments = [], locations = []) =>
//   locations.forEach(({ id, markerRange, containerRange, originalMarkerLength, start, end }) => {
//     const [mStart, mEnd] = markerRange
//     const [cStart, cEnd] = containerRange
//     const suffix = (start && end && 'se') || (start && 's') || (end && 'e') || ''
//     const insert = new Array(cEnd - cStart).fill(`${id}[${mStart},${mEnd},${originalMarkerLength}]${suffix}`)
//     segments.splice(cStart, cEnd - cStart, ...insert)
//   })

// ============================================================= //
// ========================= SELECTORS ========================= //
// ============================================================= //

const segmentByIdSelector = (state, selectors, id) => getSegmentById(state.wizard, id)

const getSegmentsByParentId = (state, selectors, id) =>
  id === 'root' ? selectors.selectSegments(state) : segmentByIdSelector(state, selectors, id)[0]?.content

const sectionLayoutsSelector = (state, selectors) =>
  Array.from(
    new Set(
      state.wizard.dataStructure?.sections?.reduce(
        (acc, { layout: { paper, orientation, margins: { top, bottom, left, right } = {} } = {} }) =>
          acc.concat(`${paper}-${orientation}-${top}-${bottom}-${left}-${right}`),
        []
      ) || []
    )
  ).join('; ')

const applyMarkerIdToSegments = (segmentIds = [], id = '', range = []) =>
  segmentIds.splice(range[0], range[1] - range[0], ...segmentIds.slice(...range).map(s => `${s}; marker:${id}`)) && segmentIds

const getMappedSegments = (state, selectors, parentId, segmentIds, markerArray) =>
  (markerArray || selectors.selectSegmentsMarkerLocationsByParentId(state, parentId) || []).reduce((acc, { id, range }) => {
    const childMarkers = selectors.selectSegmentsMarkerLocationsByParentId(state, id)
    const mappedInner = childMarkers ? getMappedSegments(state, selectors, id, acc, childMarkers) : acc
    return applyMarkerIdToSegments(mappedInner, id, range)
  }, segmentIds || (getSegmentsByParentId(state, selectors, parentId) || []).map(({ id }) => id))

const sectionPageContentIdsSelector = (state, selectors, sectionIndex, pageIndex) =>
  Array.from(
    new Set(
      getMappedSegments(state, selectors, 'root')
        .slice(...(selectors.selectSectionPageRangeByIndex(state, sectionIndex, pageIndex) || [0, 0]))
        .map(id => id.split('; ').pop())
    )
  ).join('; ')

const headerFooterContentIdsSelector = (state, selectors, id) =>
  Array.from(
    new Set(
      getMappedSegments(
        state,
        selectors,
        id,
        (selectors.selectHeaderFooterById(state, id)?.segments || []).map(({ id }) => id)
      ).map(id => id.split('; ').pop())
    )
  ).join('; ')

const cellContentIdsSelector = (state, selectors, id) =>
  Array.from(new Set(getMappedSegments(state, selectors, id).map(id => id.split('; ').pop()))).join('; ')

const markerContentIdsSelector = (state, selectors, id, parent) => {
  const location = selectors.selectLocationById(state, id)
  if (!location) return ''
  const [isHeaderFooter, parentId, sectionIndex, pageIndex] = (
    parent.match(/()(^root)-([0-9]+)-([0-9]+)$/) || parent.match(/(^headerFooter-)?(.+)/)
  )?.slice(1)
  const [pStart, pEnd] =
    (!(sectionIndex ?? pageIndex ?? false) && [0, +Infinity]) || selectors.selectSectionPageRangeByIndex(state, sectionIndex, pageIndex) || []
  const relevantRange = pStart || pEnd ? [Math.max(location.range[0], pStart), Math.min(location.range[1], pEnd)] : location.range
  const headerFooterSegments = isHeaderFooter ? (selectors.selectHeaderFooterById(state, parentId)?.segments || []).map(({ id }) => id) : undefined
  return `${location.range[0] >= pStart ? ':' : ''}${Array.from(
    new Set(
      getMappedSegments(state, selectors, parentId, headerFooterSegments)
        .slice(...relevantRange)
        .map(s => s.split(`; marker:${id}`)[0].split('; ').pop())
    )
  ).join('; ')}${location.range[1] <= pEnd ? ':' : ''}`
}

const numberingByIdSelector = (state, selectors, id) => {
  const numbering = state.wizard.numbering
  if (!numbering) return
  const relevantNumbering = numbering[id]
  if (!relevantNumbering) return
  return `${relevantNumbering.system}:${relevantNumbering.value}`
}

export {
  segmentByIdSelector,
  sectionLayoutsSelector,
  sectionPageContentIdsSelector,
  headerFooterContentIdsSelector,
  cellContentIdsSelector,
  markerContentIdsSelector,
  numberingByIdSelector,
}
