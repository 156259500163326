import React, { FunctionComponent, useMemo } from 'react'
import useStore, { DisconnectMarkerFromValueSourceAction, WizardIntegrationsSelector, WizardQuestionsSelector } from '___store'

import { COMPUTED_VALUES, VALUE_SOURCE_TYPE_MATCH } from '___types'
import { Cross } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { classes } from '../../..'

type UseStoreHookResultType = { disconnectMarkerFromValueSource: DisconnectMarkerFromValueSourceAction }

type Props = {
  id: string
  valueSources: string[]
  questions: WizardQuestionsSelector
  externals: WizardIntegrationsSelector
}
export const Replacement: FunctionComponent<Props> = React.memo(({ id, valueSources, questions, externals }) => {
  const { disconnectMarkerFromValueSource } = useStore('disconnectMarkerFromValueSource') as UseStoreHookResultType
  const render = useMemo(
    () =>
      valueSources?.length
        ? valueSources.map(source => {
            const { type, id: valueSourceId } = source.match(VALUE_SOURCE_TYPE_MATCH)?.groups as {
              type: 'question' | 'external' | 'computed'
              id: string
            }
            let label = ''
            if (type === 'question') label = questions?.find(({ id: questionId }) => questionId === valueSourceId)?.text || 'Question not found!'
            if (type === 'external')
              label =
                (externals &&
                  externals[valueSourceId.split(':')[0]]?.fields?.find(({ id: fieldId }) => fieldId === valueSourceId.split(':')[1])?.name) ||
                'Field not found!'
            if (type === 'computed') label = COMPUTED_VALUES[valueSourceId as keyof typeof COMPUTED_VALUES]?.label || 'Value not found!'
            return (
              <Button
                key={`Connected-Value-Source-${source}`}
                className={classes.configEntry.markerConfiguration.fragment.connect.tabs.valueSources.entry}
                onClick={() => disconnectMarkerFromValueSource({ id, valueSource: source })}
                dataSet={{ type }}
              >
                <span>{label}</span>
                <Cross />
              </Button>
            )
          })
        : null,
    [valueSources, questions, externals, disconnectMarkerFromValueSource, id]
  )
  return <div className={classes.configEntry.markerConfiguration.fragment.connect.tabs.valueSources.wrapper}>{render}</div>
})

Replacement.displayName = ''

export default Replacement
