import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useQueryClient } from 'react-query'
// import { useLocation } from 'react-router-dom'

import AuthApi from 'api/auth.api'
import { queryKeys } from 'queries/queryKeys'
import { QUERY_KEYS } from '___queries'

export const useLogout = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { push } = useHistory()
  const tenant = window.location.host.split('.')[0]
  // const tenant = 'viseca' // dev

  const logout = useCallback(async () => {
    push('/logout')
    await AuthApi.logoutUser()
    queryClient.invalidateQueries(queryKeys.USER)
    queryClient.invalidateQueries(QUERY_KEYS.USER)
    queryClient.invalidateQueries()
    queryClient.clear()
    localStorage.clear()
    AuthApi.setAuthHeaders(null, null)
    if (tenant === 'viseca') {
      // todo: use env
      push('https://viseca.app.v2.casus.ch/auth/login')
    } else if (tenant === 'sbv') {
      // todo: use env
      push('https://sbv.app.v2.casus.ch/auth/login')
    } else {
      push('/auth/login')
    }
    dispatch({ type: 'LOGOUT' })
  }, [push, queryClient, tenant, dispatch])

  return logout
}
