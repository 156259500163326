import { QuestionTextProps } from './QuestionText.types'
import React from 'react'
import { TextInput } from './QuestionText.styles'
import { useTranslation } from 'react-i18next'

const QuestionText: React.FC<QuestionTextProps> = ({ question, disabled, handleChange, value }) => {
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(e.target.value)
  }
  const { t } = useTranslation()

  return (
    <TextInput
      id="custom-css-standard-input"
      name={question.id}
      type='text'
      placeholder={t('your_answer')}
      onChange={handleTextChange}
      disabled={disabled}
      value={value}
      multiline
      rowsMax={20}
    />
  )
}

export default QuestionText
