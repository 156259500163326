import React from 'react'

import { className } from './index'

const Caret = React.memo(() => (
  <svg className={`${className} Caret`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <path d="M 25 40 L 50 60 L 75 40" stroke="currentColor" strokeWidth="10" strokeLinecap="round" fill="none" />
  </svg>
))

export { Caret }
export default Caret
