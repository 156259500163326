import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, className } from './index'

const circles = [
  { cx: 23, cy: 28, r: 10, strokeWidth: 5 },
  { cx: 77, cy: 28, r: 15, strokeWidth: 8 },
  { cx: 50, cy: 75, r: 10, strokeWidth: 5 },
]

const Connect = React.memo(() => {
  const maskId = useMemo(uuid, [])
  const path = useMemo(() => {
    const d = circles.reduce((result, { cx, cy }) => result.concat(['L', cx, cy]), []).concat('Z')
    d.splice(0, 1, 'M')
    return <path d={d.join(' ')} stroke="currentColor" strokeWidth="8" strokeDasharray="8 8" fill="none" mask={`url(#${maskId}`} />
  }, [maskId])
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${VIEWPORT_SIZE} ${VIEWPORT_SIZE}`}
      preserveAspectRatio="none"
    >
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          {circles.map(({ cx, cy, r }) => (
            <circle key={`Connect-mask-${maskId}-circle-${cx}-${cy}`} cx={cx} cy={cy} r={r} stroke="black" strokeWidth="10" fill="black" />
          ))}
        </mask>
      </defs>
      {path}
      {circles.map(({ cx, cy, r, strokeWidth }) => (
        <circle key={`Connect-${maskId}-circle-${cx}-${cy}`} cx={cx} cy={cy} r={r} stroke="currentColor" strokeWidth={strokeWidth} fill="none" />
      ))}
    </svg>
  )
})

export { Connect }
export default Connect
