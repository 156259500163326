import questionTypes from '../../../../constants/question-types'
import { IStep } from 'types'
import { IconNames } from 'components/common/Icons/Icon.types'
import { ITabItem } from 'components/common/Tabs/Tabs.types'
const { RADIO_REPLACEMENT, RADIO_LINK, CHECKBOX, TEXT_BOX, DATE, NUMBER } = questionTypes

export const steps: IStep[] = [
  { step: 1, label: 'upload_document' },
  { step: 2, label: 'select_components' },
  { step: 3, label: 'use_template' },
]

export const onBoardingSteps: IStep[] = [
  { step: 1, label: 'upload_template' },
  { step: 2, label: 'select_dynamic_components' },
  { step: 3, label: 'save_use_template' },
]

export const onBoardingHeading = 'digitise_contract_templates'
export const heading = 'create_template'

export const lastStepContent = {
  heading: 'template_created',
  primaryButton: 'test_template',
  primaryButtonDisabled: 'no_questions',
  secondaryButton: 'go_to_templates',
}

export const descriptionContent = {
  heading:
    'optional_add_a_label',
}

export const btnContent = {
  next: 'next',
  save: 'save',
  saveFinish: 'save_finish',
  lookup: 'search',
  uploading: 'upload_ellipsis',
  newOption: 'add_new_option',
  addSubquestion: 'add_subquestion',
  markText: 'mark_text',
  addQuestion: 'plus_add_question',
  preview: 'preview',
}

export const uploadContent = {
  headingTop: 'upload_template_or_blank',
  headingUpload: 'heading_upload',
  headingEmpty: 'heading_empty',
  descriptionUpload:
    'description_upload',
  descriptionEmpty:
    'description_empty',
  subtitle: 'subtitle_upload',
}

export const radioFormTabs = [
  { label: 'link_text_section', section: questionTypes.RADIO_LINK },
  { label: 'replace_text_section', section: questionTypes.RADIO_REPLACEMENT },
]

const numberFormTabs: ITabItem[] = [
  { label: 'number', section: 'no-range' },
  { label: 'number_range', section: 'with-range' },
]

export const forms = {
  heading: 'add_answer_option',
  subquestionsHeading: 'optional_sub_question',
  createQuestionLabel: 'create_question_label',
  dateHeading:
    'mark_text_date',
  textBoxHeading:
    'mark_text_field',
  numberHeading:
    'mark_text_number',
  labelLabel: 'Label *',
  groupLabel: 'group',
  labelPlaceholder: 'title_of_question',
  groupPlaceholder: 'Table of content',
  descriptionLabel: 'pratice_tips',
  answerExampleLabel: 'sample_answer',
  answerExamplePlaceholder: 'sample_answer',
  descriptionPlaceholder: 'pratice_tips',
  questionTypesHeading: 'choose_answer_type',
  confirmText:
    'change_answer_type_confirmation',
  confirmTextOption:
    'data_option_delete_confirmation',
  radioFormTabs,
  numberFormTabs,
}

export const moreDropdownContent = {
  preview: 'preview',
  edit: 'edit',
  delete: 'delete',
}

export const questionsOverviewContent = {
  noQuestionsText: 'no_questions_yet',
  isLoadingText: 'one_moment_please'
}

interface IBoxType {
  label: string
  icon: IconNames
}
interface IBoxTypes {
  [key: string]: IBoxType
}

export const boxTypes: IBoxTypes = {
  [TEXT_BOX]: { label: 'Text', icon: 'text' },
  textField: { label: 'textfield', icon: 'text' },
  [DATE]: { label: 'date', icon: 'date' },
  [CHECKBOX]: { label: 'checkbox', icon: 'checkbox' },
  [RADIO_LINK]: { label: 'single_selection', icon: 'radio' },
  [RADIO_REPLACEMENT]: { label: 'single_selection', icon: 'radio' },
  [NUMBER]: { label: 'number', icon: 'number' },
}
