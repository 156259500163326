import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { BEFolder, PartialBEFolder } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { useCallback } from 'react'

export type UpdateDocumentFolderVariables = PartialBEFolder
export type UpdateDocumentFolderContext = { mutationId: string }
export type UpdateDocumentFolderMutationOptions = MutateOptions<BEFolder | void, unknown, UpdateDocumentFolderVariables, UpdateDocumentFolderContext>
export type UpdateDocumentFolderFunctionType = (folder: PartialBEFolder, options?: UpdateDocumentFolderMutationOptions) => void
const updateDocumentFolderMutationFunction = (variables: UpdateDocumentFolderVariables) => documentsAPI.updateDocumentFolder(variables)

export const useUpdateDocumentFolder = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: (BEFolder & { mutationId?: string; original?: BEFolder })[] | undefined) => BEFolder[]) =>
    queryClient.setQueryData([QUERY_KEYS.DOCUMENT_FOLDERS], method)

  const onMutate = (variables: UpdateDocumentFolderVariables) => {
    const mutationId = uuid()
    const replaceMethod = (currentFolder: BEFolder & { mutationId?: string; original?: BEFolder }) => {
      const original = currentFolder.original || currentFolder
      return Object.assign({}, currentFolder, variables, { mutationId, mutating: true, mutation: 'update', original })
    }
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, replaceMethod))
    return { mutationId }
  }

  const onError = (error: unknown, variables: UpdateDocumentFolderVariables, context: UpdateDocumentFolderContext | undefined) => {
    const currentFolder = (
      queryClient.getQueryData([QUERY_KEYS.DOCUMENT_FOLDERS]) as (BEFolder & { mutationId?: string; original: BEFolder })[]
    ).find(folder => folder.id === id)
    if (currentFolder && currentFolder.mutationId === context?.mutationId) {
      queryClient.cancelQueries([QUERY_KEYS.DOCUMENT_FOLDERS])
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENT_FOLDERS])
      queryClient.fetchQuery([QUERY_KEYS.DOCUMENT_FOLDERS])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentFolder.original))
    }
  }

  const onSuccess = (folder: BEFolder | void, variables: UpdateDocumentFolderVariables, context: UpdateDocumentFolderContext | undefined) => {
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, folder))
  }

  const documentFolderUpdateMutation = useMutation<BEFolder | void, unknown, UpdateDocumentFolderVariables, UpdateDocumentFolderContext>(
    [QUERY_KEYS.DOCUMENT_FOLDERS],
    updateDocumentFolderMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const updateFolderMutationFunction: UpdateDocumentFolderFunctionType = useCallback(
    (folder, options) => documentFolderUpdateMutation.mutate(Object.assign({}, folder, { id: id! }), options),
    [documentFolderUpdateMutation, id]
  )

  return { updateDocumentFolder: updateFolderMutationFunction, updatingDocumentFolder: documentFolderUpdateMutation.isLoading }
}

export default useUpdateDocumentFolder
