import {
  TemplatesActions,
  SET_CURRENT_FOLDER_TEMPLATES,
  SET_ROOT_FOLDER_TEMPLATES,
} from './templates.actions'
import { FolderItem, RootFolder } from 'types'

const rootFolder: RootFolder = {
  id: 'root',
  type: 'root',
  name: 'root',
  children: [],
  path: [],
}

const my: FolderItem = {
  id: 'my',
  type: 'my',
  name: 'my_templates',
  authorId: '',
  sharedWith: [],
  children: [],
  path: [],
}
const casus: FolderItem = {
  id: 'casus',
  type: 'casus',
  name: 'CASUS-Vorlagen',
  authorId: '',
  sharedWith: [],
  children: [],
  path: [],
}
const shared: FolderItem = {
  id: 'shared',
  type: 'shared',
  name: 'shared_templates',
  authorId: '',
  sharedWith: [],
  children: [],
  path: [],
}
const trashed: FolderItem = {
  id: 'trashed',
  type: 'trashed',
  name: 'bin',
  authorId: '',
  sharedWith: [],
  children: [],
  path: [],
}

rootFolder.children.push(my, casus, shared, trashed)

interface TemplatesState {
  currentFolder: FolderItem
  rootFolder: RootFolder
}

const initialState: TemplatesState = {
  currentFolder: rootFolder.children.find((ch) => ch.type === 'my')!,
  rootFolder,
}

export default (
  state: TemplatesState = initialState,
  action: TemplatesActions
): TemplatesState => {
  switch (action.type) {
    case SET_CURRENT_FOLDER_TEMPLATES:
      return { ...state, currentFolder: action.payload }
    case SET_ROOT_FOLDER_TEMPLATES:
      return {
        ...state,
        rootFolder: action.payload,
      }
    default:
      return state
  }
}
