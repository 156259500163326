import React, { forwardRef, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect } from 'react'

import { Caret, Check, Cross, Spinner } from 'assets/svgIconComponents'
import { SelectProps, classes } from '.'
import { HighlightStringResultEntryType, highlightString } from 'utilities/helpers'
import Button from '../Button'

export const Select = React.memo(
  forwardRef<HTMLButtonElement, SelectProps>((props, ref) => {
    const [wrapper, setWrapper]: [HTMLDivElement | undefined, Dispatch<SetStateAction<HTMLDivElement | undefined>>] = useState()
    const wrapperRef: RefCallback<HTMLDivElement | undefined> = useCallback(node => node && setWrapper(node), [])
    const [input, setInput]: [HTMLInputElement | undefined, Dispatch<SetStateAction<HTMLInputElement | undefined>>] = useState()
    const inputRef: RefCallback<HTMLInputElement | undefined> = useCallback(node => node && setInput(node), [])
    const {
      className: cn,
      options,
      defaultValue,
      value,
      multiselect,
      unselectable = false,
      placeholder = 'Select an option',
      disabled,
      dataSet,
      loading,
      showSpinner = false,
      searchable = false,
      entryRender,
      onSelect,
    } = props
    const [active, setActive] = useState(typeof defaultValue === 'string' ? defaultValue.split(';').filter(s => s) : defaultValue || [])
    const [filter, setFilter] = useState('')

    const emphasis = ['emphasized', 'primary', 'secondary', 'tertiary', 'transparent'].find(prop => props[prop as keyof SelectProps])

    const hasOptions = useMemo(() => Boolean(Object.keys(options).length), [options])

    const computedValue = useMemo(() => {
      const relevantValue = typeof value === 'string' ? value.split(';').filter(s => s) : value || active
      if (multiselect) return relevantValue
      const resultingValue = relevantValue.pop()
      return resultingValue ? [resultingValue] : []
    }, [value, active, multiselect])

    const text = useMemo(() => {
      if (computedValue.length === 0) return
      if (computedValue.length === 1) return options[computedValue[0]]
      return Object.entries(options)
        .reduce((result, [id, label]) => (computedValue.includes(id) ? result.concat(label) : result), [] as string[])
        .join(', ')
    }, [computedValue, options])

    const baselineDataSet = useMemo(
      () => ({ emphasis, loading, disabled, multiselect, selected: text || undefined }),
      [emphasis, loading, disabled, multiselect, text]
    )

    const selected = useMemo(
      () =>
        Object.entries(options).reduce((result, [id, label]) => {
          if (computedValue.includes(id))
            return result.concat(
              typeof entryRender === 'function' ? (
                entryRender(id, label, () => setActive(prev => prev.filter(activeId => activeId !== id)), options)
              ) : (
                <Button onClick={() => setActive(prev => prev.filter(activeId => activeId !== id))} tertiary>
                  {label}
                  <Cross />
                </Button>
              )
            )
          return result
        }, [] as JSX.Element[]),
      [options, computedValue, entryRender]
    )

    const render = useMemo(
      () =>
        Object.entries(options).reduce((result, [id, label]) => {
          let buttonRender = label as string | (string | JSX.Element)[]
          const isActive = computedValue.includes(id)
          if (isActive) return result
          if (filter.length) {
            const regex = new RegExp(filter.toLowerCase(), 'g')
            const match = Array.from(label.toLowerCase().matchAll(regex) || [])
            if (!match.length) return result
            const indices = match.map(({ index }) => [index, index! + filter.length] as [number, number])
            const highlight = highlightString(label, indices)
            buttonRender = highlight.map(({ type, value }: HighlightStringResultEntryType, i: number) =>
              type === 'text' ? value : <mark key={`Casus-Components-Select-entry-${id}-highlight-${i}`}>{value}</mark>
            )
          }
          return result.concat(
            <Button
              key={`Casus-Components-Select-entry-${id}`}
              className={classes.options.entry}
              onClick={event => {
                event.stopPropagation()
                const relevantIndex = computedValue.indexOf(id)
                const resultingValueArray =
                  relevantIndex === -1
                    ? computedValue.concat(id)
                    : computedValue.slice(0, relevantIndex).concat(computedValue.slice(relevantIndex + 1))
                const resultingValue = multiselect ? resultingValueArray : ([resultingValueArray.pop()].filter(v => v) as string[])
                if (typeof onSelect === 'function') onSelect(id, typeof value === 'string' ? resultingValue.join(';') : resultingValue)
                setActive(resultingValue)
                setFilter('')
              }}
              dataSet={{ active: isActive }}
              tertiary={isActive}
              disabled={isActive && !unselectable}
              onClickBlur
              noOverlaySVG
            >
              <span>{buttonRender}</span>
            </Button>
          )
        }, [] as JSX.Element[]),
      [options, filter, computedValue, multiselect, onSelect, value, unselectable]
    )

    const keyDownHandler = useCallback(
      event => {
        if (event.key === 'Escape') {
          event.preventDefault()
          event.stopPropagation()
          if (input) {
            input.value = ''
            event.currentTarget.dispatchEvent(new Event('input', { bubbles: true }))
          }
        }
      },
      [input]
    )

    useEffect(() => {
      if (wrapper) {
        Object.keys(wrapper.dataset).forEach(key => delete wrapper.dataset[key])
        Object.entries(Object.assign({}, baselineDataSet, dataSet)).forEach(
          ([key, value]) => value !== undefined && (wrapper.dataset[key] = String(value))
        )
      }
    }, [wrapper, dataSet, baselineDataSet])

    const className = useMemo(() => [classes.wrapper, cn].filter(c => c).join(' '), [cn])

    return (
      <div ref={wrapperRef} className={className} onClick={() => input?.focus()}>
        {multiselect ? <>{selected}</> : null}
        {searchable ? (
          <input
            ref={inputRef}
            type="text"
            placeholder={hasOptions ? placeholder : 'No options available...'}
            value={filter}
            disabled={!hasOptions || loading}
            onInput={event => setFilter(event.currentTarget.value)}
            onKeyDown={keyDownHandler}
            onChange={() => {}}
          />
        ) : (
          <span tabIndex={0}>{hasOptions ? placeholder : 'No options available...'}</span>
        )}
        {loading && showSpinner ? <Spinner /> : !hasOptions ? null : computedValue.length && !multiselect ? <Check /> : <Caret />}
        {hasOptions && !loading ? <div className={classes.options.wrapper}>{render}</div> : null}
      </div>
    )
  })
)

Select.displayName = 'Casus-Components-Select'

export default Select
