import React from 'react'

import { className } from './index'

const Extract = React.memo(() => {
  const width = 70
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <rect
        x={`${(100 - width) / 2}`}
        y={`${(100 - width) / 2}`}
        width={width}
        height={width}
        stroke="currentColor"
        strokeWidth="8"
        strokeLinecap="round"
        strokeDasharray={`${2 * width} ${width * 0.95}`}
        strokeDashoffset={`${width * 1.15}`}
        fill="none"
        rx="8"
      />
      <path d="M 84 50 L 54 32 v 36 Z" stroke="none" fill="currentColor" />
    </svg>
  )
})

export { Extract }
export default Extract
