import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { IDocument, ITemplate } from 'types'
import { useDocument } from 'queries/documents'
import { useFetchTemplate } from 'queries/templates'

interface Props {
  children: React.ReactNode
  type: 'document' | 'template' | 'signature'
}

interface Context {
  select: (id: string) => void
  clear: () => void
  reload: () => void
  selectedId: string | null
  type: 'document' | 'template' | 'signature' | null
  data?: IDocument | ITemplate | null
  isLoading: boolean
  isError: boolean
}

const Details = createContext<Context | undefined>(undefined)

export const DetailsProvider: React.FC<Props> = ({ children, type }) => {
  const [selectedId, setSelectedId] = useState(null)

  const {
    data: document,
    isLoading: isLoadingDocument,
    isError: isErrorDocument,
    refetch: refetchDocument,
  } = useDocument(selectedId, {
    enabled: false,
  })

  const { data: template, isLoading: isLoadingTemplate, isError: isErrorTemplate, refetch: refetchTemplate } = useFetchTemplate(selectedId, false)

  const select = useCallback(id => {
    setSelectedId(id)
  }, [])

  const clear = useCallback(() => {
    setSelectedId(null)
  }, [])

  const reload = useCallback(() => {
    if (type === 'document' || type === 'signature') {
      refetchDocument()
    } else {
      refetchTemplate()
    }
  }, [type, refetchDocument, refetchTemplate])

  const data = type === 'document' || type === 'signature' ? document : template

  const isLoading = isLoadingDocument || isLoadingTemplate
  const isError = isErrorDocument || isErrorTemplate

  useEffect(() => {
    if (type === 'document' || type === 'signature') {
      refetchDocument()
    } else {
      refetchTemplate()
    }
  }, [selectedId, type, refetchDocument, refetchTemplate])

  return <Details.Provider value={{ select, clear, reload, selectedId, type, data, isLoading, isError }}>{children}</Details.Provider>
}

export const useDetails = () => {
  const context = useContext(Details)

  if (!context) {
    throw new Error('useDetails must be used within a DetailsProvider')
  }
  return context
}

