import { AppState } from '.'

export type SetTemplatesFolderFilterPayload = string | string[]
const setTemplatesFolderFilter = (state: AppState, payload: SetTemplatesFolderFilterPayload): AppState => {
  const index = state.templates.folderFilter.indexOf(typeof payload === 'string' ? payload : payload[0])
  const resultingFilter = (index === -1 ? [] : state.templates.folderFilter.slice(0, index)).concat(payload)
  if (!payload || resultingFilter.join('; ') === state.templates.folderFilter.join('; ')) return state
  return Object.assign(state.templates, { folderFilter: resultingFilter, selected: null }) && Object.assign({}, state)
}

export type SetTemplatesFilterPayload = string | string[]
const setTemplatesFilter = (state: AppState, payload: SetTemplatesFilterPayload): AppState => {
  if (typeof payload === 'string') {
    const stringPayloadFilterArray = payload.split(/[+-]/).filter(s => s)
    if (state.templates.filter.join('+') === stringPayloadFilterArray.join('+')) return state
    return Object.assign(state.templates, { filter: stringPayloadFilterArray }) && Object.assign({}, state)
  }
  if (!Array.isArray(payload)) return state
  const [add, remove] = payload.reduce(
    (result, filter) => {
      const index = ['+', '-'].indexOf(filter.slice(0, 1))
      if (index !== -1) result[index].push(filter.slice(1))
      return result
    },
    [[], []] as [string[], string[]]
  )

  const removed = state.templates.filter.filter(filter => !remove.includes(filter))
  let update = removed.length !== state.templates.filter.length
  const added = removed.concat(Array.from(new Set(add)))
  update = update || added.length !== state.templates.filter.length

  if (!update) return state
  Object.assign(state.templates, { filter: added })
  return Object.assign({}, state)
}

export type SetSelectedTemplateIdPayload = string
const setSelectedTemplateId = (state: AppState, payload: SetSelectedTemplateIdPayload): AppState =>
  !payload || payload === state.templates.selected ? state : Object.assign(state.templates, { selected: payload }) && Object.assign({}, state)

const deselectAllTemplates = (state: AppState): AppState =>
  !state.templates.selected ? state : Object.assign(state.templates, { selected: null }) && Object.assign({}, state)

export { setTemplatesFolderFilter, setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates }
