import React from 'react'
import useStore from '___store'

import { VerticalEllipsis } from 'assets/svgIconComponents'
import { Select } from 'components'
import ActionButton from './ActionButton'
import { classes } from '../../../../../index'

const SubQuestion = React.memo(({ id }) => {
  const { questionById: { text = '' } = {} } = useStore(`selectQuestionById[${id}]`)
  const {
    actionSelect: actionSelectClass,
    subQuestion: { wrapper: wrapperClass },
  } = classes.configEntry.questionConfiguration.fragment.questionOptions.optionGroup.option
  return (
    <div className={wrapperClass}>
      <span>{text}</span>
      <Select
        className={actionSelectClass}
        options={{ removeSubQuestion: null, configureSubQuestion: null }}
        render={key => <ActionButton key={`SubQuestion-${id}-ActionSelect-${key}`} button={key} questionId={id} />}
        chooseMode
        chooseRender={<VerticalEllipsis />}
        noCaret
        directionPriority="horizontal"
        horizontalDirection="left"
        cornerAnchor={false}
      />
    </div>
  )
})

SubQuestion.displayName = 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-Option-SubQuestion'

export default SubQuestion
