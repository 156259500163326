import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStore, {
  WizardLanguagesSelector,
  WizardIntegrationsSelector,
  WizardIntegrationEntriesSelector,
  WizardAnswersSelector,
  WizardAnswerRelevanceSelector,
} from '___store'

import { useDocument } from '___hooks'
import { Save } from 'assets/svgIconComponents'
import { Button, Switch } from 'components/CasusComponents'
import {
  WizardLayoutHeaderDynamicButtonProps,
  wizardLayoutHeaderDynamicButtonClasses as classes,
  wizardLayoutHeaderClasses as headerClasses,
  useWizardLayoutContext,
} from 'Layouts/WizardLayout'
import { useIsDocumentDirty } from 'Layouts/WizardLayout/hooks'

type UseStoreHookResultType = {
  wizardLanguages: WizardLanguagesSelector
  wizardIntegrationEntries: WizardIntegrationEntriesSelector
  wizardIntegrations: WizardIntegrationsSelector
  wizardAnswers: WizardAnswersSelector
  wizardAnswerRelevance: WizardAnswerRelevanceSelector
}

export const Update: FunctionComponent<WizardLayoutHeaderDynamicButtonProps> = React.memo(() => {
  const { documentId } = useParams() as { documentId: string }
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.header' })
  const [autoSave, setAutoSave] = useState(false)
  const { wizardLanguages, wizardIntegrationEntries, wizardIntegrations, wizardAnswers, wizardAnswerRelevance } = useStore(
    'selectWizardLanguages',
    'selectWizardIntegrationEntries',
    'selectWizardIntegrations',
    'selectWizardAnswers',
    'selectWizardAnswerRelevance'
  ) as UseStoreHookResultType

  const publicFlow = useWizardLayoutContext()

  const {
    data: { templateContentVersionId, languages, integrations, answers } = {},
    update,
    creating,
    updating,
  } = useDocument(documentId, publicFlow)
  const mutating = creating || updating

  const isDirty = useIsDocumentDirty(languages, integrations, answers)

  const callbackHolder = useRef(() => setAutoSave(false))

  const onError = useCallback(() => {
    if (typeof callbackHolder.current === 'function') callbackHolder.current()
  }, [])

  const updateHandler = useCallback(() => {
    const payload = { id: documentId! }
    if (wizardLanguages?.selected?.length) Object.assign(payload, { languages: wizardLanguages.selected })
    if (Object.values(wizardIntegrationEntries || {})?.length) Object.assign(payload, { integrationEntries: wizardIntegrationEntries })
    if (Object.values(wizardIntegrations || {})?.length) Object.assign(payload, { integrations: wizardIntegrations })
    if (wizardAnswers?.length) Object.assign(payload, { answers: wizardAnswers, answerRelevance: wizardAnswerRelevance })
    if (templateContentVersionId) {
      Object.assign(payload, { templateContentVersionId })
      update!(payload, { onError })
    }
  }, [
    documentId,
    wizardLanguages,
    wizardIntegrationEntries,
    wizardIntegrations,
    wizardAnswers,
    wizardAnswerRelevance,
    templateContentVersionId,
    update,
    onError,
  ])

  useEffect(() => {
    if (autoSave && isDirty && !mutating) updateHandler()
  }, [autoSave, isDirty, mutating, updateHandler])

  return (
    <>
      <Switch className={headerClasses.autoSaveSwitch} value={autoSave} onClick={setAutoSave} pin={<Save />} primary />
      <Button className={classes.wrapper} onClick={updateHandler} disabled={mutating} loading={mutating}>
        {translate('update')}
        {isDirty ? <div className={classes.dot} /> : null}
      </Button>
    </>
  )
})

Update.displayName = 'WizardLayout-Header-DynamicButton-Update'

export default Update
