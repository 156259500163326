import { isProd, isQa, isDev } from 'config'

export const openIntercomChat = () => {
  console.log('openIntercomChat')
  const theWindow = window as any
  const intercom: any = theWindow?.Intercom ?? theWindow?.intercom
  if (!intercom && !isDev) return console.log('intercom not found')
  if (isQa || isProd) {
    console.log('[Intercom]: showNewMessage')
    intercom('showNewMessage')
    intercom('show')
  }
}
