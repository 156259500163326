import React from 'react'
import useStore from '___store'

import { Caret } from 'assets/svgIconComponents'
import { Button, ButtonGroup } from 'components'
import MarkerCycler from 'Wizard/components/MarkerCycler'
import { classes } from '../../..'

export const Actions = React.memo(({ disabled }) => {
  const { goToPreviousQuestion, goToNextQuestion } = useStore('goToPreviousQuestion', 'goToNextQuestion')
  console.log(goToNextQuestion, goToPreviousQuestion)
  const { buttonGroup: buttonGroupClass, innerButtonGroup: innerButtonGroupClass, button: buttonClass } = classes.configEntry.questionnaire.actions
  return (
    <ButtonGroup className={buttonGroupClass} disabled={disabled}>
      <Button className={buttonClass} disabled={disabled} onClick={goToPreviousQuestion} blurOnClick>
        <Caret />
      </Button>

      <ButtonGroup className={innerButtonGroupClass}>
        <MarkerCycler disabled={disabled} />
        <Button className={buttonClass} disabled={disabled} onClick={goToNextQuestion} blurOnClick colorScheme="primary">
          Next
        </Button>
      </ButtonGroup>
    </ButtonGroup>
  )
})

Actions.displayName = 'Wizard-Configuration-Questionnaire-Actions'

export default Actions
