import React from 'react'

import { VIEWPORT_SIZE, className } from './index'

const Squares = React.memo(props => {
  const { squareSize = 23, strokeWidth = 8, fill = 'none', rx = 5 } = props
  const spacing = (VIEWPORT_SIZE - 2 * squareSize) / 3
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <rect
        x={`${spacing}`}
        y={`${spacing}`}
        width={`${squareSize}`}
        height={`${squareSize}`}
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        fill={`${fill}`}
        rx={`${rx}`}
      />
      <rect
        x={`${2 * spacing + squareSize}`}
        y={`${spacing}`}
        width={`${squareSize}`}
        height={`${squareSize}`}
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        fill={`${fill}`}
        rx={`${rx}`}
      />
      <rect
        x={`${spacing}`}
        y={`${2 * spacing + squareSize}`}
        width={`${squareSize}`}
        height={`${squareSize}`}
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        fill={`${fill}`}
        rx={`${rx}`}
      />
      <rect
        x={`${2 * spacing + squareSize}`}
        y={`${2 * spacing + squareSize}`}
        width={`${squareSize}`}
        height={`${squareSize}`}
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        fill={`${fill}`}
        rx={`${rx}`}
      />
    </svg>
  )
})

export { Squares }
export default Squares
