import React, { useState, useMemo, useCallback, useEffect } from 'react'
import useStore from '___store'

import { Check } from 'assets/svgIconComponents'
import { Button, ButtonGroup } from 'components'
import { generateTextLocationObject } from '../../selection'
import { classes } from '../..'

const MarkTextConfirmButton = React.memo(({ text }) => {
  const [node, setNode] = useState(null)
  const { markLocation } = useStore('markLocation')

  const setRef = useCallback(node => setNode(node || null), [])

  const end = useMemo(() => {
    const selection = document.getSelection()
    const end = selection.getRangeAt(selection.rangeCount - 1).cloneRange()
    return end.collapse(false) || end
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text.range]) // needed to update the position

  const { top, left } = useMemo(() => end.getBoundingClientRect(), [end])

  const boundaries = useMemo(() => {
    const editor = document.getElementsByClassName(classes.wrapper)[0]
    if (!editor || !(top && left)) return null
    const rect = editor.getBoundingClientRect()
    return { top: top - (rect.top - editor.scrollTop), left: left - rect.left }
  }, [top, left])

  useEffect(() => {
    if (!node) return
    const { top, left } = boundaries
    node.setAttribute('style', `--top: ${top}px; --left: ${left}px`)
  }, [node, boundaries])

  return (
    <div ref={setRef} className={classes.selectionControls.text.wrapper}>
      <ButtonGroup className={classes.selectionControls.text.buttonGroup}>
        <Button
          className={classes.selectionControls.text.button}
          tabbable={false}
          onClick={() => {
            markLocation(generateTextLocationObject(text))
            document.getSelection().removeAllRanges()
          }}
          colorScheme="transparent"
        >
          <Check />
          Mark Text
        </Button>
      </ButtonGroup>
    </div>
  )
})

MarkTextConfirmButton.displayName = 'Wizard-Editor-SelectionControls-MarkTextConfirmButton'

export default MarkTextConfirmButton
