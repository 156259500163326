import { useCallback, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { MutationOptions, useMutation, useQueryClient } from 'react-query'

import { Author, IUser, BEFolder } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'

const generateFolder = (author: Author, name: string, parent?: string | null) => {
  const moment = { _seconds: Math.floor(Date.now() / 1000), _nanoseconds: 0 }
  return {
    status: 'new',
    name: name,
    authorId: author?.id,
    author: { id: author?.id, email: author?.email, firstName: author?.firstName, lastName: author?.lastName, imageUrl: author?.imageUrl },
    parentCategoryId: parent,
    created: moment,
    edited: moment,
    sharingEnabled: false,
    sharedWith: [],
  }
}

export type CreateDocumentFolderVariables = { name: string; parentFolderId?: string | null }
export type CreateDocumentFolderContext = { mutationId: string }
export type CreateDocumentFolderMutationOptions = MutationOptions<BEFolder, unknown, CreateDocumentFolderVariables, CreateDocumentFolderContext>
export type CreateDocumentFolderFunctionType = (name: string, parentFolderId?: string, options?: CreateDocumentFolderMutationOptions) => void
const createDocumentFolderMutationFunction = (variables: CreateDocumentFolderVariables) =>
  documentsAPI.createDocumentFolder(variables.name, variables.parentFolderId)

export const useCreateDocumentFolder = () => {
  const queryClient = useQueryClient()
  const mutationId = useMemo(uuid, [])

  const updateListing = (method: (data: BEFolder[] | undefined) => BEFolder[]) => queryClient.setQueryData([QUERY_KEYS.DOCUMENT_FOLDERS], method)

  const onMutate = (variables: CreateDocumentFolderVariables) => {
    const author = queryClient.getQueryData([QUERY_KEYS.USER]) as IUser
    const optimisticPayload = { id: mutationId, mutating: true, mutation: 'create' }
    const optimisticDocumentFolder = Object.assign(generateFolder(author, variables.name, variables.parentFolderId), optimisticPayload) as BEFolder
    updateListing(data => [optimisticDocumentFolder].concat(data || []))
    return { mutationId }
  }

  const onError = (error: unknown, variables: CreateDocumentFolderVariables, context: CreateDocumentFolderContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.DOCUMENT_FOLDERS, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.DOCUMENT_FOLDERS, context?.mutationId])
    updateListing((data: BEFolder[] | undefined) => data?.filter(({ id }) => id !== context?.mutationId) || [])
  }

  const onSuccess = (documentFolder: BEFolder, variables: CreateDocumentFolderVariables, context: CreateDocumentFolderContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.DOCUMENT_FOLDERS, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.DOCUMENT_FOLDERS, context?.mutationId])
    queryClient.setQueryData([QUERY_KEYS.DOCUMENT_FOLDERS, documentFolder.id], documentFolder)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === context?.mutationId, documentFolder))
  }

  const generatedId = useMemo(() => uuid(), [])
  const documentFolderCreateMutation = useMutation<BEFolder, unknown, CreateDocumentFolderVariables, CreateDocumentFolderContext>(
    [QUERY_KEYS.DOCUMENT_FOLDERS, generatedId],
    createDocumentFolderMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const createFolderMutationFunction: CreateDocumentFolderFunctionType = useCallback(
    (name, parentFolderId, options) => documentFolderCreateMutation.mutate({ name, parentFolderId }, options),
    [documentFolderCreateMutation]
  )

  return { createDocumentFolder: createFolderMutationFunction, creatingDocumentFolder: documentFolderCreateMutation.isLoading }
}

export default useCreateDocumentFolder
