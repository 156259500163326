import React from 'react'

import { classes } from './'

import './style.scss'
import DocsApi from 'api/docs.api'
import { useToast } from 'hooks/useToast'

const SignersListWithStatus = ({ signatures, signDocumentId, signatureRequest }) => {

  const sendReminder = async signer => {
    const res = await DocsApi.signatureSendReminder(signDocumentId, signatureRequest.id, signer.signer_identity_data.email_address)
    if (res) {
      toast('success', 'Reminder sent')
    } else {
      toast('error', 'Something went wrong')
    }
  }

  const toast = useToast()

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Signers</div>
      {signatures?.map((signer, index) => {
        if (signer.status_code === 'OPEN') {
          return (
            <div className={classes.pending.wrapper} key={index}>
              <span>
                {signer.signer_identity_data?.first_name} {signer.signer_identity_data?.last_name} - {signer.signer_identity_data?.email_address}
              </span>
              <div className={classes.pending.reminderGroup.wrapper}>
                <span className={classes.pending.reminderGroup.sendButton} onClick={() => sendReminder(signer)}>Send reminder</span>
                <div className={classes.pending.reminderGroup.divider} />
                <span className={classes.pending.reminderGroup.status}>Pending</span>
              </div>
            </div>
          )
        } else if (signer.status_code === 'SIGNED') {
          return (
            <div className={classes.signed.wrapper} key={index}>
              {signer.signer_identity_data?.first_name} {signer.signer_identity_data?.last_name} - {signer.signer_identity_data?.email_address}{' '}
              <span className={classes.signed.status}>Signed</span>
            </div>
          )
        } else {
          return (
            <div className={classes.signed.wrapper} key={index}>
              {signer.signer_identity_data?.first_name} {signer.signer_identity_data?.last_name} - {signer.signer_identity_data?.email_address}{' '}
              <span className={classes.signed.status}>{signer.status_code}</span>
            </div>
          )
        }
      })}
    </div>
  )
}

export default SignersListWithStatus

