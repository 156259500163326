import NeedApprovalPanel from './NeedApprovalPanel'
import EmptyNeedApproval from './EmptyNeedApproval'

const classes = {
  wrapper: 'Homepage-dashboard-grid-panel-wrapper',
  title: {
    wrapper: 'Homepage-dashboard-grid-panel-title-wrapper',
    showAll: 'Homepage-dashboard-grid-panel-title-showAll',
    textAndIcon: 'Homepage-dashboard-grid-panel-title-textAndIcon',
  },
  empty: {
    wrapper: 'Homepage-dashboard-grid-panel-empty-wrapper',
    icon: 'Homepage-dashboard-grid-panel-empty-icon',
    text: 'Homepage-dashboard-grid-panel-empty-text',
  },
  list: 'Homepage-dashboard-needApproval-list-wrapper',
}

export { classes, EmptyNeedApproval }

export default NeedApprovalPanel

