import React, { FunctionComponent, useMemo } from 'react'

import { VIEWPORT_SIZE, CheckBoxProps, className } from './index'

const ZOOM = 1

const CheckBox: FunctionComponent<CheckBoxProps> = React.memo(({ active = false, strokeWidth = 10, r = 10 }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  return (
    <svg className={`${className} CheckBox`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <rect x="15" y="15" width="70" height="70" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" fill="none" rx={r} />
      <path d="M 30 47 L 45 62 L 70 37" stroke={active ? 'currentColor' : 'none'} strokeWidth={strokeWidth} strokeLinecap="round" fill="none" />
    </svg>
  )
})

export { CheckBox }
export default CheckBox
