import { useQuery } from 'react-query'

import { documentsAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchDocumentList = () => {
  const documentListQuery = useQuery({
    queryKey: [QUERY_KEYS.DOCUMENTS],
    queryFn: documentsAPI.getDocumentList,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return documentListQuery
}

export default useFetchDocumentList
