import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { ITemplate, PartialTemplate } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'
// import { getTemplateListQueryFunction } from './useFetchTemplateList'

export type UpdateTemplateVariables = PartialTemplate
export type UpdateTemplateContext = { mutationId: string }
export type UpdateTemplateMutationOptions = MutateOptions<ITemplate | void, unknown, UpdateTemplateVariables, UpdateTemplateContext>
export type UpdateTemplateFunctionType = (template: PartialTemplate, options?: UpdateTemplateMutationOptions) => void
// const updateTemplateMutationFunction = (variables: UpdateTemplateVariables) => templatesAPI.updateTemplate(variables)

export const useUpdateTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: (ITemplate & { mutationId?: string })[] | undefined) => ITemplate[]) =>
    queryClient.setQueryData([QUERY_KEYS.TEMPLATES], method)

  const onMutate = (variables: UpdateTemplateVariables) => {
    const mutationId = uuid()
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original?: ITemplate }
    if (currentTemplate) {
      const originalTemplate = currentTemplate.original || currentTemplate
      const optimisticTemplate = Object.assign({}, originalTemplate, variables, {
        mutationId,
        mutating: true,
        mutation: 'update',
        original: originalTemplate,
      })
      queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticTemplate))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: UpdateTemplateVariables, context: UpdateTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
      queryClient.removeQueries([QUERY_KEYS.TEMPLATE, id])
      queryClient.resetQueries([QUERY_KEYS.TEMPLATE, id])
      // queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], currentTemplate.original)
      queryClient.cancelQueries([QUERY_KEYS.TEMPLATES])
      queryClient.invalidateQueries([QUERY_KEYS.TEMPLATES])
      queryClient.fetchQuery([QUERY_KEYS.TEMPLATES])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentTemplate.original))
    }
  }

  const onSuccess = (template: ITemplate | void, variables: UpdateTemplateVariables, context: UpdateTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], template)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, template))
  }

  const templateUpdateMutation = useMutation<ITemplate | void, unknown, UpdateTemplateVariables, UpdateTemplateContext>(
    [QUERY_KEYS.TEMPLATE, id],
    templatesAPI.updateTemplate,
    { onMutate, onError, onSuccess }
  )

  const updateMutationFunction: UpdateTemplateFunctionType = (template, options) =>
    templateUpdateMutation.mutate(Object.assign(template, { id: id! }), options)

  return { update: updateMutationFunction, updating: templateUpdateMutation.isLoading }
}

export default useUpdateTemplate
