import { useUpdateTemplate } from 'queries/templates'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const classes = {
  wrapper: 'description-wrapper',
  container: 'description-container',
  title: 'title',
  descriptionArea: 'description-area',
  textarea: 'description-textarea',
}

export const Description = ({ id, templateChars }) => {
  const { t } = useTranslation()
  const [chars, setChars] = useState(templateChars)
  const update = useUpdateTemplate()

  useEffect(() => {
    setChars(templateChars)
  }, [templateChars])

  const handleChange = e => {
    setChars(e.target.value)
  }

  const handleBlur = e => {
    if (chars === templateChars) return
    onUpdateCharacteristics(id, chars)
  }

  const onUpdateCharacteristics = (id, characteristics) => {
    update.mutate({ id, data: { characteristics } })
  }

  return (
    <div className={classes.wrapper}>
      <dl className={classes.container}>
        <h3 className={classes.title}>{t('description')}</h3>
        <div className={classes.descriptionArea}>
          <textarea
            id="description"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={!chars ? 'Dieser Vertrag hat keine Merkmale' : chars}
            placeholder={t('short_description_template')}
            spellCheck={false}
            rows="7"
            resize="none"
            className={classes.textarea}
          ></textarea>
        </div>
      </dl>
    </div>
  )
}

