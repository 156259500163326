import { useCallback } from 'react'
import { useHistory } from 'react-router'

export const usePdfFileActions = () => {
  const { push } = useHistory()

  const clearDocumentLS = useCallback(() => {
    localStorage.removeItem('initialDocValue')
    localStorage.removeItem('currentDocValue')
  }, [])

  const onOpen = (documentId: string) => {
    clearDocumentLS()
    push(`/pdf/${documentId}`)
  }

  return { onOpen }
}
