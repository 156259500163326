import AiSuggestedTemplatesPanel from './AiSuggestedTemplatesPanel'

import './style.scss'

const classes = {
  wrapper: 'Homepage-dashboard-grid-suggestTemplate-wrapper',
  blurBg: 'Homepage-dashboard-grid-suggestTemplate-blurBg',
  chat: 'Homepage-dashboard-grid-suggestTemplate-chat-wrapper',
  title: 'Homepage-dashboard-grid-suggestTemplate-title',
  aiInput : {
    wrapper: 'Homepage-dashboard-grid-suggestTemplate-aiInput-wrapper',
    input: 'Homepage-dashboard-grid-suggestTemplate-aiInput-input',
    button: 'Homepage-dashboard-grid-suggestTemplate-aiInput-button',
    close: 'Homepage-dashboard-grid-suggestTemplate-aiInput-close',
  },
  explanation: 'Homepage-dashboard-grid-suggestTemplate-templatesList-explanation-wrapper',
  explanationText: 'Homepage-dashboard-grid-suggestTemplate-templatesList-explanation',
  explanationBubble: 'Homepage-dashboard-grid-suggestTemplate-templatesList-explanation-bubble',
  emptyTemplates: 'Homepage-dashboard-grid-suggestTemplate-emptyTemplates-wrapper',
  templatesList: {
    wrapper: 'Homepage-dashboard-grid-suggestTemplate-templatesList-wrapper',
    empty: 'Homepage-dashboard-grid-suggestTemplate-templatesList-empty',
  },
}

export { classes }

export default AiSuggestedTemplatesPanel

