import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { IDocument } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { useCallback } from 'react'

export type ShareDocumentVariables = { id: string; email: string; permissions: { use: boolean; edit: boolean } }
export type ShareDocumentFunctionType = (email: string, use?: boolean, edit?: boolean, options?: ShareDocumentMutationOptions) => void
const shareDocumentMutationFunction = (variables: ShareDocumentVariables) =>
  documentsAPI.shareDocument(variables.id, variables.email, variables.permissions)

export type UnshareDocumentVariables = { id: string; shareId: string }
export type UnshareDocumentFunctionType = (shareId: string, options?: ShareDocumentMutationOptions) => void
const unshareDocumentMutationFunction = (variables: UnshareDocumentVariables) => documentsAPI.unshareDocument(variables.id, variables.shareId)

export type ShareDocumentContext = { mutationId: string }
export type ShareDocumentMutationOptions = MutateOptions<IDocument, unknown, ShareDocumentVariables | UnshareDocumentVariables, ShareDocumentContext>

export const useShareDocument = (id?: string | null) => {
  const queryClient = useQueryClient()
  const documentQueryKey = [QUERY_KEYS.DOCUMENT, id]

  const updateListing = (method: (data: (IDocument & { mutationId?: string })[] | undefined) => IDocument[]) =>
    queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: ShareDocumentVariables | UnshareDocumentVariables) => {
    const mutationId = uuid()
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original?: IDocument }
    if (currentDocument) {
      const originalDocument = currentDocument.original || currentDocument
      const optimisticDocument = Object.assign({}, originalDocument, { mutationId, mutating: true, mutation: 'update', original: originalDocument })
      queryClient.setQueryData(documentQueryKey, optimisticDocument)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticDocument))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: ShareDocumentVariables | UnshareDocumentVariables, context: ShareDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) {
      queryClient.removeQueries(documentQueryKey)
      queryClient.resetQueries(documentQueryKey)
      // queryClient.setQueryData(documentQueryKey, currentDocument.original)
      queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
      queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentDocument.original))
    }
  }

  const onSuccess = (
    document: IDocument | undefined,
    variables: ShareDocumentVariables | UnshareDocumentVariables,
    context: ShareDocumentContext | undefined
  ) => {
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) queryClient.setQueryData(documentQueryKey, document)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, document))
  }

  const documentShareMutation = useMutation<IDocument, unknown, ShareDocumentVariables, ShareDocumentContext>(
    documentQueryKey,
    shareDocumentMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const shareMutationFunction: ShareDocumentFunctionType = useCallback(
    (email, use = true, edit = false, options) => documentShareMutation.mutate({ id: id!, email, permissions: { use, edit } }, options),
    [documentShareMutation, id]
  )

  const documentUnshareMutation = useMutation<IDocument, unknown, UnshareDocumentVariables, ShareDocumentContext>(
    documentQueryKey,
    unshareDocumentMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const unshareMutationFunction: UnshareDocumentFunctionType = useCallback(
    (shareId, options) => documentUnshareMutation.mutate({ id: id!, shareId }, options),
    [documentUnshareMutation, id]
  )

  return { share: shareMutationFunction, unshare: unshareMutationFunction, sharing: documentShareMutation.isLoading }
}

export default useShareDocument
