import React, { FunctionComponent, useMemo } from 'react'

import Segment from '../Segment'
import { useEditorPreviewContext } from '../..'
import { WizardLayoutRightPaneEditorSegmentTableCellProps, wizardLayoutRightPaneEditorSegmentTableCellClasses as classes } from '../../../../..'
import { WizardState, getMappedSegmentIds, getSegmentById } from '___store'
import { DOCUMENT_FLOW_MODES, TableCellObject } from '___types'

export const Cell: FunctionComponent<WizardLayoutRightPaneEditorSegmentTableCellProps> = React.memo(({ id }) => {
  const editorContext = useEditorPreviewContext()
  const { mode, dataStructure, numbering } = editorContext

  const staticPseudoState = Object.assign({}, { dataStructure, numbering } as unknown) as WizardState
  const contextCell = getSegmentById(staticPseudoState, id, mode)[0] as TableCellObject

  const styles = contextCell?.styles.concat(contextCell.customStyle) || []

  const mappedSegmentIds = getMappedSegmentIds(
    staticPseudoState,
    id,
    contextCell.content.map(({ id }) => id),
    mode
  )
  const topLayerContentIds = mappedSegmentIds.map(id => id.split(';').pop())
  const contentIds = `${Array.from(new Set(topLayerContentIds)).join(';')}`

  const render = useMemo(
    () => contentIds?.split(';').map(contentId => <Segment key={`${DOCUMENT_FLOW_MODES.PREVIEW}--${contentId}`} id={contentId} />),
    [contentIds]
  )
  const cellClasses = [classes.wrapper, ...styles].filter(cn => cn)

  return (
    <td id={id} className={cellClasses.join(' ')}>
      {render}
    </td>
  )
})

Cell.displayName = 'WizardLayout-RightPane-Editor-Preview-Segment-Table-Cell'

export default Cell
