import { UseQueryResult, useQuery } from 'react-query'
import { getFormattedAnswerValue, getOptionPropertiesFromAnswer } from '___store'

import { Answers, OptionValueTypeUnionType, QuestionnaireOutputEntry } from '___types'
import { documentsAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'
import { useDocument, useTemplate } from '___hooks'
import { useMemo } from 'react'

const generateDocumentTitleQueryFunction = (templateName: string, questionnaireOutput: QuestionnaireOutputEntry[]) =>
  documentsAPI.generateDocumentTitle({ templateName, questionnaireOutput })

// ======================= USE GENERATE DOCUMENT NAME OVERLOAD ======================= //
function useGenerateDocumentName(id: string, answers?: Answers): UseQueryResult<string, unknown>
function useGenerateDocumentName(templateId: string, answers: Answers): UseQueryResult<string, unknown>
// =================================================================================== //
function useGenerateDocumentName(id: string, answers?: Answers) {
  const document = useDocument(id)
  const documentAnswers = answers || document.data?.answers || []
  const templateId = document.data?.templateId
  const { data: template } = useTemplate(templateId || id)
  const templateName = template?.name
  const templateQuestions = template?.questions || []
  const questionnaireOutput = useMemo(
    () =>
      templateQuestions.reduce((result, { id, text }) => {
        const relevantAnswer = documentAnswers?.find(answer => answer.id === id)
        if (!relevantAnswer) return result
        const answerString = Object.values(getOptionPropertiesFromAnswer(relevantAnswer))
          .reduce((accumulated, { type, value }) => {
            if (!type) return accumulated
            const formattedValue = String(getFormattedAnswerValue(type as OptionValueTypeUnionType, value))
            return accumulated.concat(formattedValue)
          }, [] as string[])
          .join('; ')
        return result.concat({ question: text, answer: answerString })
      }, [] as QuestionnaireOutputEntry[]),
    [templateQuestions, documentAnswers]
  )

  const documentName = useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT, id, 'AI-generated-name'],
    queryFn: () => generateDocumentTitleQueryFunction(templateName || '', questionnaireOutput),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    placeholderData: () => 'AI is generating...',
  })

  return documentName
}

export default useGenerateDocumentName
