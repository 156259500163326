import Profile from './Profile'

import './style.scss'

const classes = {
  wrapper: 'Casus-Components-Profile-wrapper',
  profile: 'Casus-Components-Profile-profile',
  details: 'Casus-Components-Profile-details',
  moniker: 'Casus-Components-Profile-moniker',
  address: 'Casus-Components-Profile-address',
} as const

export type Profile = { firstName?: string; lastName?: string; email?: string; imageUrl?: string }
export type ProfileProps = { profile: Profile; showInfo?: boolean }

export { Profile, classes }
export default Profile
