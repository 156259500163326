import { IDocument } from 'types'
import React, { useMemo } from 'react'
import { Editor as IEditor } from 'tinymce'
import { Editor } from '@tinymce/tinymce-react'
// import { useQueryClient } from 'react-query'

import { useTextEditorHandlers } from 'config/textEditor/useTextEditorEventHandlers'
import { useMasterData } from 'queries/app/useMasterdata'

import { isEditorIsOwner, unwrapHtmlElement } from 'utils'

import DocsApi from 'api/docs.api'

import * as config from 'config/textEditor/textEditor.config'
// import { queryKeys } from 'queries/queryKeys'
import { useParams } from 'react-router'
import { useDocumentsUpdate } from 'queries/documents'
import { useUser } from 'queries/user/useUser'
// import {
// generateDocumentDataStructure,
// generateStructureELEMENT,
// } from 'utilities'
import { useFetchTemplate } from 'queries/templates'
import { generateHtml } from 'utilities'
import { Loader } from 'components/common'

interface Props {
  readonly: boolean
  documentFile: IDocument
}
// type T = DocumentSettingsItem | null | undefined

// const persistedValue = localStorage.getItem('currentDocValue')
// const persistedInitialValue = localStorage.getItem('initialDocValue')

export const DocumentPreviewEditor: React.FC<Props> = ({ readonly, documentFile }) => {
  const { id } = useParams<{ id: string }>()
  const {
    onBackspaceDown,
    onEnterDown,
    onAnyCharDown,
    onMouseUp,
    onPaste,
    onChange,
    onBeforeExecCommand,
    onAfterExecCommand,
    setTextSelected,
  } = useTextEditorHandlers()

  const masterdata = useMasterData()!
  // const queryClient = useQueryClient()
  const update = useDocumentsUpdate()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  //const documentFile = queryClient.getQueryData<IDocument>([queryKeys.DOCUMENT, id])

  const { settings, htmlData: v1htmlData, answers } = documentFile!

  const {data: templateData, isLoading} = useFetchTemplate(documentFile?.templateId, false, id)

  const [htmlData, cssData] = useMemo(() => {
    let htmlData = ''
    let cssData = {}
    if (docXmicro && templateData) {
      const { dataStructure, cssData: css, questions }: any = templateData
      const [generatedHTML] = generateHtml(dataStructure, questions, answers)
      htmlData = generatedHTML
      cssData = css
    } else htmlData = v1htmlData
    return [htmlData, cssData]
  }, [docXmicro, templateData, answers, v1htmlData]) as any

  //   const [header, setHeader] = useState<T>(settings?.header)
  //   const [footer, setFooter] = useState<T>(settings?.footer)

  // const initValue = React.useRef(persistedValue ?? htmlData)

  const onInit = (e: any, editor: IEditor): void => {
    const body = editor.getBody()
    const { hyphens, footer, header } = settings
    const { isEditor } = isEditorIsOwner()

    body.setAttribute('lang', 'de_DE')
    body.style.hyphens = hyphens ? 'auto' : 'none'
    body.querySelectorAll('header, footer').forEach(e => e.remove())

    if (footer) body.insertAdjacentHTML('beforeend', '<footer>1</footer>')

    if (header) {
      body.insertAdjacentHTML(
        'afterbegin',
        `<header><img src="${header.imageURL}" alt="logo" style="height: ${header.imageHeight}; width: auto; "/></header>`
      )
    }
    const isReadOnly = readonly || !isEditor
    body.setAttribute('contenteditable', (!isReadOnly).toString())
    if (docXmicro) {
      body.style.userSelect = !isReadOnly ? '' : 'none'
      body.style.pointerEvents = !isReadOnly ? '' : 'none'
    }
    // if (typeof body.innerHTML === 'string') {
    //   // localStorage.setItem(
    //   //   'initialDocValue',
    //   //   persistedInitialValue ?? body.innerHTML
    //   // )
    //   // localStorage.setItem('currentDocValue', persistedValue ?? body.innerHTML)
    // }
    body.querySelectorAll('mark').forEach(unwrapHtmlElement)
  }

  const onKeyDown = (e: any, editor: IEditor) => {
    if (docXmicro) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    switch (e.key) {
      case 'Enter':
        onEnterDown(e, editor)
        break
      case 'Backspace':
        onBackspaceDown(e, editor)
        break
      default:
        onAnyCharDown(e, editor)
        break
    }

    setTextSelected('')
  }

  const onKeyPress = (e: any, editor: IEditor) => {
    if (docXmicro) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    updateDocumentData(editor)
  }

  const handlePaste = (e: any, editor: IEditor) => {
    if (docXmicro) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    onPaste(e, editor)
    updateDocumentData(editor)
  }

  const updateDocumentData = (editor?: IEditor) => {
    const theTheEditor = editor
    if (!theTheEditor) return
    // const body = theTheEditor.getBody()
    // if (typeof body.innerHTML === 'string') {
    //   localStorage.setItem('currentDocValue', body.innerHTML)
    // }
  }

  const handleChange = (e: any, editor: IEditor) => {
    if (docXmicro) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    onChange(e, editor)
    updateDocumentData(editor)
  }

  const handleAfterExecCommand = (e: any, editor: IEditor) => {
    onAfterExecCommand(e, editor)
    // updateDocumentData(editor)
  }

  const updateHyphens = (theEditor?: IEditor) => {
    if (!theEditor) return
    const body = theEditor.getBody()
    const currentHyphens = body.style.hyphens
    const newHyphens = currentHyphens === 'auto' ? 'none' : 'auto'

    body.style.hyphens = newHyphens
    update.mutate({
      id,
      data: {
        settings: {
          ...settings,
          hyphens: newHyphens === 'auto' ? true : false,
        },
      },
    })
    updateDocumentData(theEditor)
  }

  const onLogoUpload = async (imageData: string) => {
    const url = await DocsApi.uploadLogo(id, imageData)
    return url
  }

  const height = window.innerHeight - 152

  const stylesFromCssData = Object.entries(cssData).reduce((acc, [key, value]) => {
    return [acc, `${key} { ${value} }`].join(' ')
  }, '')

  const content_style = [
    config.getStyles(masterdata, 'document-generation', {
      hyphens: settings?.hyphens,
    }),
    stylesFromCssData,
  ].join(' ')

  if(isLoading) return <Loader/>

  return (
    <div
      style={{
        margin: '0 auto',
        paddingTop: 0,
        pointerEvents: readonly ? 'none' : 'auto',
      }}
    >
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY!}
        initialValue={htmlData}
        onInit={onInit}
        onKeyDown={onKeyDown}
        onMouseUp={onMouseUp}
        onKeyPress={onKeyPress}
        onPaste={handlePaste}
        onCut={(e, editor) => updateDocumentData(editor)}
        onChange={handleChange}
        onBeforeExecCommand={onBeforeExecCommand}
        onExecCommand={handleAfterExecCommand}
        init={{
          menubar: false,
          height: height,
          width: 725,
          plugins: config.dp_plugins,
          toolbar: config.dp_toolbar,
          extended_valid_elements: 'span[*] *[*]',
          pagebreak_separator: '<div style="page-break-before: auto; visibility: hidden">',
          paste_as_text: true,
          noneditable_noneditable_class: 'mceNonEditable',
          advlist_number_styles: 'default,lower-alpha,lower-roman',
          language: 'de',
          paste_preprocess: (plugin: any, args: any) => {
            args.content = args.content.replaceAll('<br />', '</p><p>')
          },
          setup: (editor: IEditor) =>
            config.setup(editor, {
              onSave: updateDocumentData,
              onHyphenChange: updateHyphens,
              onLogoUpload: onLogoUpload,
            }),
          content_style,
          automatic_uploads: true,
          // file_picker_types: 'image',
          // file_picker_callback: config.file_picker_callback,
          formats: {},
          style_formats: [],
          contextmenu: false,
          branding: false,
          font_formats: config.font_formats,
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
          resize: false,
          statusbar: false,
          readonly: !!readonly,
          paste_block_drop: true,
          visual: false,
        }}
      />
    </div>
  )
}
