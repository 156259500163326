import { User } from "types"

export const getSubscriptionStatus = (user: User ) => {
    if (!user) {
      return true
    }
  
    if (!user.subs || user.subs.length === 0) {
      return true
    }
  
    const subscription = user?.subs[0]?.status === 'active'
    return subscription
  }
  
  