import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { PdfPreviewPage } from './view/PdfPreview/PdfPreviewPage'

const Pdf: React.FC = () => {
  return (
    <Switch>
      <Route exact path={`/pdf/:id`} component={PdfPreviewPage} />
    </Switch>
  )
}

export default Pdf
