import React from 'react'
import { EmptyReminders, classes } from '.'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IReminder } from 'types'
import { useLoadReminders } from 'queries/reminders'
import { DateTime } from 'luxon'
import { Reminders } from 'assets/svgIconComponents'

interface IProps {
  reminders: IReminder[] | undefined
}

const RemindersList: React.FC<IProps> = ({ reminders }) => {
  if (reminders && reminders.length === 0) {
    return <EmptyReminders />
  }

  return (
    <div className={classes.list.wrapper} data-skeleton={false}>
      {reminders?.map((reminder: IReminder) => (
        <div className={classes.list.item.wrapper}>
          <span className={classes.list.item.message}>{reminder.name}</span>
          <span className={classes.list.item.title}>{DateTime.fromSeconds(reminder.alertTime._seconds).toLocaleString(DateTime.DATE_SHORT)}</span>
        </div>
      ))}
    </div>
  )
}

const SkeletonList: React.FC = () => {
  return (
    <div className={classes.list.wrapper} data-skeleton={true}>
      <div className={classes.list.item.wrapper}>
        <div className={classes.list.item.title} />
        <div className={classes.list.item.time} />
      </div>
      <div className={classes.list.item.wrapper}>
        <div className={classes.list.item.title} />
        <div className={classes.list.item.time} />
      </div>
      <div className={classes.list.item.wrapper}>
        <div className={classes.list.item.title} />
        <div className={classes.list.item.time} />
      </div>
    </div>
  )
}

const RemindersPanel: React.FC = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useLoadReminders()

  const { push } = useHistory()

  return (
    <div className={classes.wrapper}>
      <div className={classes.title.wrapper}>
        <span className={classes.title.textAndIcon}>
          <Reminders /> {t('u_reminders')}
        </span>
        <span onClick={() => push(`/documents/reminders`)} className={classes.title.showAll}>
          {t('show_all')} &gt;
        </span>
      </div>
      {!isLoading ? <RemindersList reminders={data} /> : <SkeletonList />}
    </div>
  )
}

export default RemindersPanel

