import React from 'react'
import { classes } from '.'
import { Icon } from 'components/common'

import './style.scss'

const SESCard = () => {
  return (
    <Card
      title="Basic Security Signature"
      description="Simple electronic signature (SES), for documents without legal form requirements and with low liability risk."
      icon="ses"
    />
  )
}

const QESCard = () => {
    return (
        <Card
            title="Qualified Electronic Signature"
            description="Qualified electronic signature (QES), for documents with legal form requirements and high liability risk."
            icon="qes"
        />
    )
}

const AESCard = () => {
    return (
        <Card
            title="Advanced Electronic Signature"
            description="Advanced electronic signature (AES), for documents without legal form requirements and with calculable liability risk."
            icon="aes"
        />
    )
}

const Card = ({ title, description, icon }) => {
  return (
    <div className={classes.container.card.wrapper}>
      <div className={classes.container.card.icon}>
        <Icon iconName={icon} />
      </div>
      <div className={classes.container.card.text.wrapper}>
        <div className={classes.container.card.text.title}>{title}</div>
        <div className={classes.container.card.text.description}>{description}</div>
      </div>
    </div>
  )
}

const SecurityLabel = ({signatureLevel}) => {
  const getCorrectCard = signatureLevel => {
    switch (signatureLevel) {
      case 'SES':
        return <SESCard />
      case 'QES':
        return <QESCard />
      case 'AES':
        return <AESCard />
      case 'DEMO':
        return <SESCard />
      default:
        return null
    }
  }

  return (
    <div className={classes.container.wrapper}>
      <div className={classes.container.title}>Signature Security</div>
      {getCorrectCard(signatureLevel)}
    </div>
  )
}

export default SecurityLabel

