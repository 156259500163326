import React from 'react'
import { IDocument } from 'types'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'

import DownloadButtonComponent from './DownloadButton.component'
import { queryKeys } from 'queries'
import { useEditor } from 'config/textEditor/useEditor'

export const DownloadButton: React.FC = () => {
  const { onDownload } = useDocumentFileActions()
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const doc = queryClient.getQueryData<IDocument>([queryKeys.DOCUMENT, id])
  const editor = useEditor()

  return (
    <DownloadButtonComponent
      downloading={false}
      downloadFormats={[/* 'pdf',  */ 'docx']}
      onDownload={format => onDownload(id, format, undefined, doc?.name, editor)}
      disabled={false}
    />
  )
}
