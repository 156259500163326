import React from 'react'
import './style.scss'
import ProfileImage from 'assets/icons/profile-ph.jpg'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const classes = {
  title: 'title',
  sharedWithContainer: 'sharedwith-container',
  listContainer: 'list-container',
  listImageContainer: 'list-image-container',
  listImage: 'list-image',
  listPerson: 'list-person',
  listImagePlus: 'list-image-plus',
}

export const SharedWith = ({ sharedWith, id }) => {
  const { t } = useTranslation()
  const { push, location } = useHistory()

  const getFullName = person => {
    return `${person.firstName} ${person.lastName}`
  }

  return (
    <div>
      <h3 className={classes.title}>{t('shared_with')}</h3>
      <ul className={classes.sharedWithContainer}>
        {sharedWith.map((person, index) => (
          <li key={index} className={classes.sharedWithContainer}>
            <div className={classes.listImageContainer}>
              <img src={ProfileImage} alt="" className={classes.listImage} />
              <p className={classes.listPerson}>{getFullName(person)}</p>
            </div>
          </li>
        ))}
        <li onClick={() => push(`${location.pathname}?file=${id}&action=share`)} className={classes.sharedWithContainer}>
          <div className={classes.listImageContainer}>
            <div className={classes.listImagePlus}>+</div>
            <p className={classes.listPerson}>{t('share')}</p>
          </div>
        </li>
      </ul>
    </div>
  )
}

