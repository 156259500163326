import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { ITemplate, Author } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type ShareTemplateVariables = { id: string; email: string; permissions: { use: boolean; edit: boolean } }
export type ShareTemplateContext = { mutationId: string }
export type ShareTemplateMutationOptions = MutateOptions<ITemplate | void, unknown, ShareTemplateVariables, ShareTemplateContext>
export type ShareTemplateFunctionType = (email: string, use?: boolean, edit?: boolean, options?: ShareTemplateMutationOptions) => void
const shareTemplateMutationFunction = (variables: ShareTemplateVariables) =>
  templatesAPI.shareTemplate(variables.id, variables.email, variables.permissions)

export const useShareTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: (ITemplate & { mutationId?: string })[] | undefined) => ITemplate[]) =>
    queryClient.setQueryData([QUERY_KEYS.TEMPLATES], method)

  const onMutate = (variables: ShareTemplateVariables) => {
    const mutationId = uuid()
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original?: ITemplate }
    if (currentTemplate) {
      const originalTemplate = currentTemplate.original || currentTemplate
      const resultingShared = originalTemplate.sharedWith.concat({ email: variables.email, mutating: mutationId } as unknown as Author)
      const optimisticTemplate = Object.assign({}, originalTemplate, variables, {
        sharedWith: resultingShared,
        mutationId,
        mutating: true,
        mutation: 'share',
        original: originalTemplate,
      })
      queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticTemplate))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: ShareTemplateVariables, context: ShareTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
      queryClient.removeQueries([QUERY_KEYS.TEMPLATE, id])
      queryClient.resetQueries([QUERY_KEYS.TEMPLATE, id])
      // queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], currentTemplate.original)
      queryClient.cancelQueries([QUERY_KEYS.TEMPLATES])
      queryClient.invalidateQueries([QUERY_KEYS.TEMPLATES])
      queryClient.fetchQuery([QUERY_KEYS.TEMPLATES])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentTemplate.original))
    }
  }

  const onSuccess = (template: ITemplate | void, variables: ShareTemplateVariables, context: ShareTemplateContext | undefined) => {
    const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
    if (currentTemplate && currentTemplate.mutationId === context?.mutationId) queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], template)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, template))
  }

  const templateShareMutation = useMutation<ITemplate | void, unknown, ShareTemplateVariables, ShareTemplateContext>(
    [QUERY_KEYS.TEMPLATE, id],
    shareTemplateMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const shareMutationFunction: ShareTemplateFunctionType = (email, use = true, edit = false, options) =>
    templateShareMutation.mutate({ id: id!, email, permissions: { use, edit } }, options)

  return { share: shareMutationFunction, sharing: templateShareMutation.isLoading }
}

export default useShareTemplate
