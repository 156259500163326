import React, { FunctionComponent, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import useStore, { WizardModeSelector } from '___store'

import { DOCUMENT_FLOW_MODES, isModeDocumentFlow, WIZARD_URL_PARAM_MATCH_ALL } from '___types'
import { Configuration, Preview, Questionnaire, Review, TemplateSelection } from './LeftPane.Document.Components'

type UseStoreHookResultType = { wizardMode: WizardModeSelector }
export const Document: FunctionComponent = React.memo(() => {
  const { search } = useLocation()
  const { wizardMode: mode } = useStore('selectWizardMode') as UseStoreHookResultType

  const step = useMemo(() => {
    const params = Array.from(search.matchAll(WIZARD_URL_PARAM_MATCH_ALL)).reduce((result, match) => {
      const { groups: { key, value } = {} } = match
      return key ? Object.assign(result, { [key]: value }) : result
    }, {} as Record<string, string>)
    return params.step as string | undefined
  }, [search])

  if (!isModeDocumentFlow(mode)) return null
  if (mode === DOCUMENT_FLOW_MODES.TEMPLATE_SELECT) return <TemplateSelection />
  if ([DOCUMENT_FLOW_MODES.NEW, DOCUMENT_FLOW_MODES.EDIT].includes(mode as typeof DOCUMENT_FLOW_MODES.NEW | typeof DOCUMENT_FLOW_MODES.NEW)) {
    if (step === 'configure') return <Configuration />
    if (step === 'review') return <Review />
    return <Questionnaire />
  }
  if (mode === DOCUMENT_FLOW_MODES.PREVIEW) return <Preview />
  return null
})

Document.displayName = 'WizardLayout-LeftPane-Document'

export default Document
