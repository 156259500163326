import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import './styles.scss'
import { ErrorModal } from './ErrorModal'
import { Loader } from 'components/common'
import { useFetchPublicTemplate } from 'queries/templates/useFetchPublicTemplate'
import { analytics } from 'utils'
import { useTranslation } from 'react-i18next'

const classes = {
  modal: {
    wrapper: 'modal',
    title: 'modal-title',
    intro: 'intro',
    footer: 'footer',
    body: 'modal-body',
  },
  columns: {
    one: 'one-column',
    two: 'two-columns',
    title: {
      wrapper: 'column-title',
    },
    body: {
      wrapper: 'column-body',
    },
  },
  button: {
    wrapper: 'button',
  },
  logo: {
    wrapper: 'logo',
  },
  screen: {
    wrapper: 'screen',
  },
  loader: {
    container: 'loader-container',
  },
  privacy: {
    wrapper: 'privacy',
  },
}

const StepZero = React.memo(() => {
  const { push } = useHistory()
  const { templateId } = useParams()
  const { data: template, isLoading, isError, refetch } = useFetchPublicTemplate(templateId, true)
  const { t, i18n } = useTranslation()

  //change language when template is loaded by the field "language"
  useEffect(() => {
    if (template) {
      const { language } = template
      if (language) {
        i18n.changeLanguage(language)
      } else {
        i18n.changeLanguage('de_CH')
      }
    }
  }, [template, i18n])

  // @ts-ignore
  useEffect(() => {
    refetch()
  }, [refetch, templateId])

  useEffect(() => {
    analytics.logEvent('public_flow_enter', {
      templateId: templateId,
    })
  }, [templateId])

  const handleClick = e => {
    analytics.logEvent('public_flow_start', {
      templateId: templateId,
    })
    // We don't have french version of the questionnaire, so we redirect to the english version
    if (template?.language === 'fr_FR') i18n.changeLanguage('en_GB')
    push(`/public/questionnaire/${templateId}`)
  }

  const modalContent = isLoading ? (
    <div className={classes.loader.container}>
      <Loader />
    </div>
  ) : isError ? (
    <div className={classes.loader.container}>
      <ErrorModal />
    </div>
  ) : template ? (
    <>
      <div className={classes.modal.title}>
        <img className={classes.logo.wrapper} src={template.displayAuthor.companyLogo} alt="company logo" />
      </div>
      <div className={classes.modal.intro}>
        <span>{t('public_flow_step_zero_intro', { companyName: template.displayAuthor.companyName })}</span>
      </div>
      <div className={classes.modal.body}>
        <dl>
          <div className={classes.columns.one}>
            <span className={classes.columns.title.wrapper}>{t('document_name')}</span>

            <span className={classes.columns.body.wrapper}>{template.name}</span>
          </div>

          <div className={classes.columns.one}>
            <span className={classes.columns.title.wrapper}>{t('created_by')}</span>

            <span>
              {template.author.firstName} {template.author.lastName}
              <br />
              {template.displayAuthor.jobPosition}
            </span>
          </div>

          <div className={classes.columns.two}>
            <span className={classes.columns.title.wrapper}>{t('description')}</span>

            <span className={classes.columns.body.wrapper}>{template.characteristics}</span>
          </div>
        </dl>
      </div>
      <div className={classes.modal.intro}>
        <p className={classes.privacy.wrapper}>
          {t('public_flow_tos_1')}&nbsp;<a href="https://www.casus.ch/privacy-policy">{t('privacy_policy')}</a>&nbsp;{t('public_flow_tos_2')}{' '}
        </p>

        <div className={classes.modal.footer}>
          <button onClick={handleClick} className={classes.button.wrapper}>
            {t('start')}
          </button>
        </div>
      </div>
    </>
  ) : null

  return (
    <div className={classes.screen.wrapper}>
      <div className={classes.modal.wrapper}>{modalContent}</div>
    </div>
  )
})

export default StepZero
