import { CasusSidebarProps } from './CasusSidebar.types'
import React, { Fragment } from 'react'
import { Container, GroupItem, GroupsContainer } from './CasusSidebar.styles'
import { Icon, CustomButton } from 'components/common'
import { secondary } from 'config/themes/colors'
import { useTranslation } from 'react-i18next'

const CasusSidebar: React.FC<CasusSidebarProps> = ({
  groups,
  currentGroup,
  isCasus,
  handleOpen,
}) => {
  const { t } = useTranslation()
  return (
    <Container iscasus={isCasus ? 1 : 0}>
      {isCasus && (
        <GroupsContainer>
          {groups.map((groupItem, i) => (
            <Fragment key={i}>
              <GroupItem active={currentGroup === groupItem ? 1 : 0}>
                {groupItem}
              </GroupItem>
              {i < groups.length - 1 && (
                <Icon
                  iconName='arrow-down-long'
                  fill={secondary[300]}
                  style={{ margin: '3vh 0', flexShrink: 0 }}
                />
              )}
            </Fragment>
          ))}
        </GroupsContainer>
      )}
      <CustomButton
        onClick={handleOpen}
        btntype='outlined-pale'
        style={{ marginTop: 'auto' }}
        size='small'
      >
        {t('question_overview')}
        <Icon iconName='list' style={{ marginLeft: 8 }} />
      </CustomButton>
    </Container>
  )
}

export default CasusSidebar
