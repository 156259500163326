import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { className } from './index'

const Cogwheel = React.memo(() => {
  const maskId = useMemo(uuid, [])
  const starRadius = 42
  const outerRadius = 40
  const innerRadius = 18
  const sin30 = Math.sin(Math.PI / 6)
  const cos30 = Math.cos(Math.PI / 6)

  return (
    <svg className={`${className} Cogwheel`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width="100" height="100" stroke="none" fill="black" />
          <circle cx="50" cy="50" r={`${outerRadius}`} stroke="none" fill="white" />
          <circle cx="50" cy="50" r={`${innerRadius}`} stroke="none" fill="black" />
        </mask>
      </defs>
      <path
        d={`M 50 ${50 - starRadius} L ${50 + starRadius * cos30} ${50 + starRadius * sin30} h -${2 * starRadius * cos30} Z`}
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        mask={`url(#${maskId})`}
        fill="currentColor"
      />
      <path
        d={`M 50 ${50 + starRadius} L ${50 - starRadius * cos30} ${50 - starRadius * sin30} h ${2 * starRadius * cos30} Z`}
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        mask={`url(#${maskId})`}
        fill="currentColor"
      />
    </svg>
  )
})

export { Cogwheel }
export default Cogwheel
