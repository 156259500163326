import React, { useMemo } from 'react'

import { classes } from '.'
import { Icon } from 'components/common'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import { HorizontalLines } from 'assets/svgIconComponents'

interface Props {
  actions: IPopupMenuItem[]
}

const ActionsDropdown: React.FC<Props> = ({ actions }) => {
  const dropdown = useMemo(() => {
    return (
      <ul className={classes.thumbnail.sideActions.more.dropdownList}>
        {actions.map((action, index) => {
          return (
            <li
              key={`Document-Thumbnail-sideActions-dropdown-item-${index}`}
              className={classes.thumbnail.sideActions.more.dropdownListItem}
              onMouseDown={e => {
                action.handleClick(e)
              }}
            >
              <div className={classes.thumbnail.sideActions.more.dropdownListItemIcon}>
                <Icon iconName={action.icon} />
              </div>{' '}
              {action.label}
            </li>
          )
        })}
      </ul>
    )
  }, [actions])
  return (
    <div className={classes.thumbnail.sideActions.more.wrapper}>
      <button className={classes.thumbnail.sideActions.more.button} type="button" onClick={() => {}}>
        <HorizontalLines />
      </button>
      {dropdown}
    </div>
  )
}

export default ActionsDropdown

