import React, { FunctionComponent, useMemo, useEffect } from 'react'
import useStore, {
  // WizardTemplateIdSelector,
  WizardEditorStylesSelector,
  WizardDataStructureSelector,
  WizardSectionsSelector,
} from '___store'

// import { useFetchTemplatePreviewImageSrcURL } from '___queries'
// import { useTemplate } from '___hooks'
// import { UseTemplateHookUnknownIdReturnType } from '___hooks/useTemplate'
// import { Spinner } from 'assets/svgIconComponents'
import { Interact, Preview } from './RightPane.Editor.Components'
import { generateNumberingSystemStyleSheet, generateSectionPageLayoutStyleSheet, parseStyleObject } from './helpers'
import { WizardLayoutRightPaneEditorProps, wizardLayoutRightPaneEditorPageClasses as pageClasses } from '../..'

type UseStoreHookResultType = {
  // wizardTemplateId: WizardTemplateIdSelector
  wizardEditorStyles: WizardEditorStylesSelector
  wizardDataStructure: WizardDataStructureSelector
  wizardSections: WizardSectionsSelector
}

export const Editor: FunctionComponent<WizardLayoutRightPaneEditorProps> = React.memo(({ mode }) => {
  const {
    // wizardTemplateId,
    wizardEditorStyles: styles,
    wizardDataStructure,
    wizardSections,
  } = useStore(
    // 'selectWizardTemplateId',
    'selectWizardEditorStyles',
    'selectWizardDataStructure',
    'selectWizardSections'
  ) as UseStoreHookResultType

  // const { paywallBlocked } = useTemplate(wizardTemplateId!) as UseTemplateHookUnknownIdReturnType
  // const { data: src, isLoading: imageSrcLoading } = useFetchTemplatePreviewImageSrcURL(paywallBlocked ? wizardTemplateId : undefined)

  const sectionLayouts = useMemo(() => {
    if (!wizardSections?.length) return ''
    const styleSheets = wizardSections.map(({ layout }) => generateSectionPageLayoutStyleSheet(layout))
    return styleSheets.join('/n')
  }, [wizardSections])

  const numberingStyles = useMemo(() => {
    const numberingEntries = Object.entries(Object.assign({}, wizardDataStructure?.numberingSystem, wizardDataStructure?.numberings))
    if (!numberingEntries.length) return {}
    return numberingEntries.reduce(
      (result, [systemKey, levels]) => Object.assign(result, { [systemKey]: generateNumberingSystemStyleSheet(systemKey, levels) }),
      {} as Record<string, string>
    )
  }, [wizardDataStructure])

  const editorStyles = useMemo(() => {
    if (!styles) return
    const standardStyles = parseStyleObject(styles.standardStyles, `.${pageClasses.wrapper} .`).join('\n')
    const customStyles = parseStyleObject(styles.customStyles, '.').join('\n')
    const customProperties = parseStyleObject(styles.customProperties).join('\n')
    return { standardStyles, customStyles, customProperties }
  }, [styles])

  useEffect(() => {
    const styleElements = Object.entries(Object.assign({}, { sectionLayouts }, numberingStyles, editorStyles))
      .map(([key, entry]) => {
        if (!entry) return null
        const styleElement = document.createElement('style')
        styleElement.dataset.sheet = key
        styleElement.setAttribute('type', 'text/css')
        styleElement.innerHTML = entry
        return styleElement
      })
      .filter(element => element)
    styleElements.forEach(element => document.head.appendChild(element!))
    return () => {
      styleElements.forEach(element => document.head.removeChild(element!))
    }
  }, [sectionLayouts, numberingStyles, editorStyles])

  // if (paywallBlocked) return imageSrcLoading ? <Spinner /> : <img src={src} alt="Template Preview" />
  if (mode === 'interact') return <Interact />
  if (mode === 'preview') return <Preview />
  return null
})

Editor.displayName = 'WizardLayout-RightPane-Editor'

export default Editor
