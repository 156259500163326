import { IDocument } from 'types'
import React, { Fragment, useState } from 'react'

import { useUser } from 'queries/user/useUser'
import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'

import FooterComponent from './Footer.component'
import { FileSharing } from 'components/files/FileAndFolderActions/share/FileSharing'

import { isEditorIsOwner } from 'utils'

interface Props {
  documentFile: IDocument
}

const Footer: React.FC<Props> = ({ documentFile }) => {
  const user = useUser()
  const { check } = useSubscriptionCheck()
  const { onEdit } = useDocumentFileActions()
  const { id, authorId, sharedWith, templateId, signatureRequest } = documentFile

  const [showShare, setShowShare] = useState(false)

  const onShare = () => {
    if (!check({ isBasic: true, isFree: true, isStartup: true })) return
    setShowShare(true)
  }

  const paymentRequired = useIsTemplatePurchaseRequired(templateId)
  const { isEditor, isOwner } = isEditorIsOwner(user?.id, authorId, sharedWith)

  const disabled = {
    edit: paymentRequired || !isEditor || !!signatureRequest,
    share: paymentRequired || !isOwner,
    download: paymentRequired,
  }

  return (
    <Fragment>
      <FooterComponent
        onEdit={() => onEdit(documentFile)}
        onShare={onShare}
        isLoading={false}
        disabled={disabled}
      />
      {!disabled.share && showShare && (
        <FileSharing where='docs' shareFileId={id} onClose={() => setShowShare(false)} />
      )}
    </Fragment>
  )
}

export default Footer
