import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { wizardLayoutLeftPaneDocumentReviewClasses as classes } from 'Layouts/WizardLayout'

const Confirm: FunctionComponent = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.review' })

  return (
    <div className={classes.message.wrapper}>
      <span className={classes.message.primary}>{translate('confirm-message')}</span>
      <span className={classes.message.secondary}>{translate('confirm-guide')}</span>
    </div>
  )
})

Confirm.displayName = 'WizardLayout-LeftPane-Document-Review-Confirm'

export default Confirm
