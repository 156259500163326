import React, { FunctionComponent, useMemo } from 'react'

import { Button } from 'components/CasusComponents'
import { LevelButtonProps, levelButtonClasses as classes } from '.'
import { Security } from 'assets/svgIconComponents'
import { SECURITY_LEVELS } from '___types'

export const LevelButton: FunctionComponent<LevelButtonProps> = React.memo(props => {
  const { level, onClick, disabled } = props
  const title = useMemo(() => {
    switch (level) {
      case SECURITY_LEVELS.BASE:
        return 'Basic'
      case SECURITY_LEVELS.HIGH:
        return 'High'
      case SECURITY_LEVELS.MAX:
        return 'Maximum'
      default:
        return 'Basic'
    }
  }, [level])
  const description = useMemo(() => {
    switch (level) {
      case SECURITY_LEVELS.BASE:
        return 'Simple electronic signature (SES), for documents without legal form requirements and with low liability risk.'
      case SECURITY_LEVELS.HIGH:
        return 'Advanced electronic signature (AES), for documents without legal form requirements and with calculable liability risk.'
      case SECURITY_LEVELS.MAX:
        return 'Qualified electronic signature (QES), for documents with legal form requirements or high liability risk.'
      default:
        return 'Simple electronic signature (SES), for documents without legal form requirements and with low liability risk.'
    }
  }, [level])

  return (
    <Button className={classes.wrapper} onClick={onClick ?? (() => {})} onClickBlur dataSet={{ level: level }} disabled={disabled} transparent>
      <Security level={level} />
      <span className={classes.title}>{title} Security Signature</span>
      <span className={classes.description}>{description}</span>
    </Button>
  )
})

LevelButton.displayName = 'Casus-Components-Signature-Security-LevelButton'

export default LevelButton
