import { Select } from 'components'
import React, { useCallback, useMemo } from 'react'
import useStore from '___store'

const QuestionSelect = React.memo(props => {
  const {
    filter: filterTest,
    sort: sortFunction,
    append: appendOptionsObject,
    className,
    chooseRender = 'Select a question',
    caretLeft,
    directionPriority = 'vertical',
    horizontalDirection = 'left',
    verticalDirection = 'down',
    cornerAnchor = false,
    value,
    onChange,
  } = props

  const { questions = [] } = useStore('selectQuestions')
  const selectedQuestion = useMemo(() => questions.find(q => q.id === value), [questions, value])

  const filterCallback = useCallback(q => (typeof filterTest === 'function' ? q?.filter(filterTest) : q), [filterTest])
  const sortCallback = useCallback(q => (typeof sortFunction === 'function' ? q?.sort(sortFunction) : q), [sortFunction])
  const questionSelectOptionObject = useMemo(
    () =>
      Object.assign(
        sortCallback(filterCallback(questions))
          ?.filter(q => q.id !== value)
          .reduce((obj, q) => Object.assign(obj, { [q.id]: q.text || 'No question text...' }), {}) || {},
        appendOptionsObject
      ),
    [sortCallback, filterCallback, questions, value, appendOptionsObject]
  )

  const fallbackText = useMemo(
    () => (Object.keys(questionSelectOptionObject).length ? chooseRender : 'No questions available'),
    [questionSelectOptionObject, chooseRender]
  )
  const selectText = useMemo(
    () => (selectedQuestion ? selectedQuestion.text || 'No question text...' : fallbackText),
    [selectedQuestion, fallbackText]
  )

  return (
    <Select
      className={className}
      chooseMode={true}
      chooseRender={selectText}
      caretLeft={caretLeft}
      directionPriority={directionPriority}
      horizontalDirection={horizontalDirection}
      verticalDirection={verticalDirection}
      cornerAnchor={cornerAnchor}
      value={value}
      onChange={id => onChange(id)}
      options={questionSelectOptionObject}
    />
  )
})

export default QuestionSelect
