import React from 'react'

import { className } from './index'

const Italic = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <text
      x="50"
      y="50"
      dy="8"
      fontFamily="Times New Roman"
      fontSize="80"
      fontStyle="italic"
      fontWeight="100"
      dominantBaseline="middle"
      textAnchor="middle"
      fill="currentColor"
    >
      I
    </text>
  </svg>
))

export { Italic }
export default Italic
