import { useQuery } from 'react-query'

import { templatesAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchTrashedTemplateList = () => {
  const templateListQuery = useQuery({
    queryKey: [QUERY_KEYS.TRASHED_TEMPLATES],
    queryFn: templatesAPI.getTrashedTemplateList,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return templateListQuery
}

export default useFetchTrashedTemplateList
