import React from 'react'
import { classes } from '.'
import NoSignatureImage from 'assets/illustrations/no-signatures.svg'
import { useTranslation } from 'react-i18next'

const EmptySignatures: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={classes.empty.wrapper}>
      <img src={NoSignatureImage} className={classes.empty.icon} alt="empty state" />
      <p className={classes.empty.text}>{t('no_signatures_available')}</p>
    </div>
  )
}

export default EmptySignatures

