import React, { FunctionComponent, useMemo } from 'react'

import { VIEWPORT_SIZE, HomeProps, className } from './index'

const ZOOM = 1.1

const Home: FunctionComponent<HomeProps> = React.memo(() => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  return (
    <svg className={`${className} Home`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <path
        d="M 20 85 v -35 l 30 -25 l 30 25 v 35 Z M 40 82 v -25 h 20 v 25 Z M 62 25 v -15 h 12 v 25 Z"
        stroke="none"
        strokeLinejoin="round"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path d="M 14 45 l 36 -30 l 36 30" stroke="currentColor" strokeWidth="5" strokeLinejoin="round" fill="none" />
    </svg>
  )
})

export { Home }
export default Home
