import React, { useState, useCallback, useEffect } from 'react'
import { Document, pdfjs, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import DocsApi from 'api/docs.api'

import { ButtonSquare, Loader, Icon } from 'components/common'
import { useHistory, useParams } from 'react-router-dom'

import './styles.scss'
import { Box, styled } from '@material-ui/core'
import { ERR_MSG_DEFAULT } from 'constants/messages'
import { useTranslation } from 'react-i18next'
import { useToast } from 'hooks/useToast'
import { Button, Signature } from 'components/CasusComponents'
import { useDocument } from '___hooks'
import { Download } from 'assets/svgIconComponents'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  background: theme.colorz.brand.main,
  height: '100vh',
  overflowY: 'hidden',
  position: 'relative',
  paddingTop: theme.spacing(6),
}))

export const PdfPreviewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [numPages, setNumPages] = useState<null | number[]>(null)
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const [file, setFile] = useState<null | File>(null)
  const [document, setDocument] = useState<null | object>(null)
  const [error, setError] = useState<null | string>(null)
  const { goBack } = useHistory()
  const { t } = useTranslation()
  const toast = useToast()
  const { push } = useHistory()

  const { downloadPDF } = useDocument(id)

  const onLoadSuccess = ({ numPages }: any) => {
    //pdfjs.PDFDocumentProxy
    const pages: number[] = []
    for (let i = 1; i <= numPages; i++) {
      pages.push(i)
    }
    setNumPages(pages)
  }

  const getDocument = useCallback(async () => {
    const tmpDoc = await DocsApi.getOne(id)
    setDocument(tmpDoc)
  }, [id])

  const getPdf = useCallback(async () => {
    if (id) {
      try {
        const arrayBuffer = await DocsApi.getPdfData(id)

        // Convert the ArrayBuffer response to a Blob
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' })

        // Create a File object from the Blob
        const file = new File([blob], 'your_file_name.pdf', { type: 'application/pdf' })
        setFile(file)
      } catch (err) {
        setError(t(ERR_MSG_DEFAULT))
        debugger
        toast('error', t('file_not_found'))
        push(`/documents`)
      } finally {
        setIsDownloadLoading(false)
      }
    } else {
      setIsDownloadLoading(false)
      setError(t(ERR_MSG_DEFAULT))
    }
  }, [id, push, t, toast])

  useEffect(() => {
    if (!file && !isDownloadLoading) getPdf()
    if (!document && !isDownloadLoading) getDocument()
  }, [getPdf, getDocument, file, isDownloadLoading, document])

  const onClose = () => {
    localStorage.removeItem('initialDocValue')
    localStorage.removeItem('currentDocValue')
    goBack()
  }

  if (isDownloadLoading || !file) return <Loader />

  return (
    <Container>
      {/* {documentFile?.type !== 'pdf' && <DocumentSettingsModalContainer />} */}
      <ButtonSquare btntype="white" size={40} onClick={onClose} style={{ position: 'fixed', top: 16, left: 32, zIndex: 200 }}>
        <Icon iconName="close" />
      </ButtonSquare>
      <div className="pdf-preview-button-group">
        <Signature id={id} />
        <Button onClick={() => typeof downloadPDF === 'function' && downloadPDF()}>
          <Download />
          Download .pdf
        </Button>
      </div>
      <div className="Signature-Preview-Container">
        {!error ? (
          <Document file={file} onLoadSuccess={onLoadSuccess}>
            {numPages?.map((p, i) => (
              <div key={i} style={{ marginTop: 24, marginBottom: 24 }}>
                <Page pageNumber={p} />
              </div>
            ))}
          </Document>
        ) : (
          <div className="Signature-Preview-Error">{error}</div>
        )}
      </div>
    </Container>
  )
}
