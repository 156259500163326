import React from 'react'
import PagesLayout from 'layout/PagesLayout/PagesLayout'
import { Container } from '../../Authenticated.page.styles'
import SignedSidebar from './SignatureSidebar/SignatureSidebar'
import { DetailsProvider } from 'components/DetailsSidebar/provider/DetailsProvider'
import { DetailsSidebar } from 'components/DetailsSidebar/DetailsSidebar'
import { MainLayout } from 'layout'

export const SignedLayout: React.FC = ({ children }) => {
  return (
    <MainLayout>
      <Container>
        <DetailsProvider type="signature">
          <SignedSidebar />
          <PagesLayout isLoading={false} hideHeader>
            {children}
          </PagesLayout>
          <DetailsSidebar />
        </DetailsProvider>
      </Container>
    </MainLayout>
  )
}

