import { useMemo } from 'react'
import useStore, { WizardLocationByTypeAndIdSelector, NavigateQuestionnaireToAction } from '___store'

import {
  CUSTOM_TEXT_SPLIT,
  LOCATION_TYPES,
  OptionValueTypeUnionType,
  SEGMENT_TYPES,
  TextChunks,
  TextLocation,
  extractPropertiesFromCustomText,
} from '___types'
import { useEditorContext } from '..'
import { IteratedChunks, getSpanStyles, parseChunkContent } from '../helpers'
import { getFormattedAnswerValue } from '___store/storeSegments/wizard/typified/helpers'

type UseStoreHookResultType = { wizardLocationByTypeAndId: WizardLocationByTypeAndIdSelector; navigateQuestionnaireTo: NavigateQuestionnaireToAction }
type UseTextMarkerResultType = { content: IteratedChunks; keep: boolean; markerNavigate: (() => void) | null; color: string }

export const useTextMarker = (id: string, range: [number, number], chunks?: TextChunks): UseTextMarkerResultType => {
  const editorContext = useEditorContext()
  const contextChildMarkers = editorContext.locations?.text[id]

  const offsetMarkers = useMemo(() => {
    if (!contextChildMarkers?.length) return []
    return contextChildMarkers.map(marker => ({ type: marker.type, id: marker.id, range: marker.range.map(r => r - range[0]) } as TextLocation))
  }, [contextChildMarkers, range])

  const { wizardLocationByTypeAndId, navigateQuestionnaireTo } = useStore(
    `selectWizardLocationByTypeAndId[${LOCATION_TYPES.TEXT},${id}]`,
    'navigateQuestionnaireTo'
  ) as UseStoreHookResultType
  const { valueSources, keep, replace, concatString, color } = (wizardLocationByTypeAndId || {}) as TextLocation

  const content = useMemo(() => {
    if (replace) {
      const replaceText = replace
        .split(CUSTOM_TEXT_SPLIT)
        .map(replaceString => {
          const properties = extractPropertiesFromCustomText(replaceString, 'markerReplace')
          const replacementText = properties.type
            ? getFormattedAnswerValue(properties.type as OptionValueTypeUnionType, properties.value)
            : replaceString
          //@ts-ignore
          return String(replacementText).replaceAll(new RegExp('\\\\(?=["\'])', 'gm'), '') ?? null
        })
        .join(concatString || ' - ')
      return [{ type: SEGMENT_TYPES.TEXT_CHUNK, text: replaceText, styles: chunks ? getSpanStyles(chunks) : [] }] as IteratedChunks
    }
    return chunks?.length ? parseChunkContent(offsetMarkers, chunks) : []
  }, [replace, concatString, chunks, offsetMarkers])

  const markerNavigate = useMemo(() => {
    const firstConnectedQuestion = valueSources?.find(source => source.split(':')[0] === 'question')
    if (!firstConnectedQuestion) return null
    if (editorContext) return () => navigateQuestionnaireTo(`:${firstConnectedQuestion.split(':')[1]}::`)
    return null // Implement template-automation mode question navigation
  }, [valueSources, editorContext, navigateQuestionnaireTo])

  const useTextMarkerReturn = { content, keep, markerNavigate, color }

  return useTextMarkerReturn
}

export default useTextMarker
