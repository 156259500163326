import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardModeSelector } from '___store'

import { isModeDocumentFlow, isModeTemplateFlow } from '___types'
import Document from './LeftPane.Document'
import { wizardLayoutLeftPaneClasses as classes } from '../..'
import { Template } from 'assets/svgIconComponents'

type UseStoreHookResultType = { wizardMode: WizardModeSelector }
export const LeftPane: FunctionComponent = React.memo(() => {
  const { wizardMode: mode } = useStore('selectWizardMode') as UseStoreHookResultType

  const render = useMemo(() => {
    if (isModeDocumentFlow(mode)) return <Document />
    if (isModeTemplateFlow(mode)) return <Template />
    return null
    //  {
    // if (mode === DOCUMENT_FLOW_MODES.TEMPLATE_SELECT) return <DocumentConfiguration isLoading={isLoading} isFetching={isFetching} />
    // if (mode === DOCUMENT_FLOW_MODES.CONFIGURE) return <DocumentConfiguration isLoading={isLoading} isFetching={isFetching} />
    // if (([DOCUMENT_FLOW_MODES.NEW, DOCUMENT_FLOW_MODES.EDIT, DOCUMENT_FLOW_MODES.REVIEW] as DocumentFlowMode[]).includes(mode as DocumentFlowMode))
    //   return <Questionnaire isLoading={isLoading} isFetching={isFetching} />
    // if (mode === DOCUMENT_FLOW_MODES.PREVIEW) return <DocumentPreview isLoading={isLoading} isFetching={isFetching} />
    // }
  }, [mode])

  return <div className={classes.wrapper}>{render}</div>
})

LeftPane.displayName = 'WizardLayout-LeftPane'

export default LeftPane
