import ShowHideFragment from './ShowHideFragment'
import SubQuestionFragment from './SubQuestionFragment'

import './style.scss'

const classes = {
  fragmentWrapper: 'Wizard-Configuration-AdvancedQuestionConfiguration-Fragment-wrapper',
  fragmentTitle: 'Wizard-Configuration-AdvancedQuestionConfiguration-Fragment-title',
  fragmentText: 'Wizard-Configuration-AdvancedQuestionConfiguration-Fragment-text',
  showHideFragmentRulesDiv: 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment-rulesDiv',
  showHideFragmentButtonGroup: 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment-buttonGroup',
  showHideFragmentButton: 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment-button',
  subQuestionFragmentButtonGroup: 'Wizard-Configuration-AdvancedQuestionConfiguration-SubQuestionFragment-buttonGroup',
  subQuestionFragmentButton: 'Wizard-Configuration-AdvancedQuestionConfiguration-SubQuestionFragment-button',
  subQuestionFragmentSelect: 'Wizard-Configuration-AdvancedQuestionConfiguration-SubQuestionFragment-select',
}

export { ShowHideFragment, SubQuestionFragment, classes }
