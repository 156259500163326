import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, DocumentPlaceholderProps, className } from './index'

const ZOOM = 1

const LINE_START = 20
const LINE_SPACING = 4
const LINES = [20, 25, 20, 0, 55, 55, 45, 55, 35, 35, 35, 35, 50, 50, 50] as const
const RANDOMIZE_PERCENTAGE = 35 as const

const DocumentPlaceholder: FunctionComponent<DocumentPlaceholderProps> = React.memo(({ randomize = false }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const iconId = useMemo(uuid, [])

  const render = useMemo(
    () =>
      LINES.map((length, i) => {
        const randomMultiplier = 1 + (Math.random() * RANDOMIZE_PERCENTAGE - RANDOMIZE_PERCENTAGE / 2) / 100
        const lineLength = Math.min(length * Math.pow(randomMultiplier, Number(randomize)), VIEWPORT_SIZE - LINE_START)
        return (
          <line
            key={`DocumentPlaceholder-SVG-Icon-${iconId}-${i}`}
            x1={LINE_START}
            x2={LINE_START + lineLength}
            y1={LINE_START + i * LINE_SPACING}
            y2={LINE_START + i * LINE_SPACING}
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="butt"
          />
        )
      }),
    [iconId, randomize]
  )

  return (
    <svg className={`${className} DocumentPlaceholder`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      {render}
    </svg>
  )
})

export { DocumentPlaceholder }
export default DocumentPlaceholder
