import React, { useState } from 'react'
import './style.scss'
import SignatureModal from 'components/SignatureModal'

import { Button } from 'components/CasusComponents'
import { Pdf } from 'assets/svgIconComponents'
import { useDownloadDocument } from '___queries/documents'
import { useTranslation } from 'react-i18next'

export const Signature = ({ type, signatureRequest, documentId, storageId, splitId }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslation()

  const { downloadSigned, downloading } = useDownloadDocument(documentId)

  return (
    <>
      <Button onClick={event => setAnchorEl(event.currentTarget)} onClickBlur>
        {signatureRequest ? t('view') : t('request')}
      </Button>
      {signatureRequest &&
        signatureRequest.status_overall === 'SIGNED' &&
        (!downloading ? (
          <Button onClick={() => downloadSigned(splitId)}>
            <Pdf />
            &nbsp;Download Signed
          </Button>
        ) : (
          <Button onClick={() => {}}>
            <Pdf />
            {t('loading')}
          </Button>
        ))}
      <SignatureModal
        close={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        type={type}
        signDocumentId={documentId}
        storageId={storageId}
        signatureRequest={signatureRequest}
        isView={!!signatureRequest}
        splitId={splitId}
      />
    </>
  )
}

