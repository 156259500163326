import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, HorizontalLinesProps, className } from './index'

const ZOOM = 0.6

const HorizontalLines: FunctionComponent<HorizontalLinesProps> = React.memo(({ count = 4, stroke = 'currentColor', strokeWidth = 8 }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const iconId = useMemo(uuid, [])

  return (
    <svg className={`${className} HorizontalLines`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      {new Array(count).fill(null).map((_, i) => {
        const y = 50 * (1 - Number(count > 1)) + i * (100 / (count - 1 || 1))
        return (
          <line
            key={`SVGIcon-HorizontalLines-${iconId}-line-${i}`}
            x1="0"
            x2="100"
            y1={y}
            y2={y}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        )
      })}
    </svg>
  )
})

export { HorizontalLines }
export default HorizontalLines
