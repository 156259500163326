import React, { forwardRef, useState, useMemo, useImperativeHandle, useCallback } from 'react'

import { CheckBox as Icon } from 'assets/svgIconComponents'
import { CheckBoxProps, classes } from '.'
import Button from '../Button'

export const CheckBox = React.memo(
  forwardRef<{ value: boolean | undefined }, CheckBoxProps>((props, ref) => {
    const { className: cn, value, defaultValue, disabled, tabbable = true, title, label, onClickBlur, onChange, onFocus } = props

    const [state, setState] = useState(Boolean(defaultValue))

    const className = useMemo(() => [classes.wrapper, cn].filter(c => c).join(' '), [cn])
    const computedState = useMemo(() => (value === undefined ? state : value), [value, state])

    const emphasis = ['emphasized', 'primary', 'secondary', 'tertiary', 'transparent'].find(prop => props[prop as keyof CheckBoxProps])
    const baselineDataSet = useMemo(() => (computedState ? { emphasis } : {}), [emphasis, computedState])
    const dataSet = useMemo(
      () => Object.assign({}, baselineDataSet, props.dataSet, { toggle: ['off', 'on'][Number(computedState)] }),
      [baselineDataSet, props.dataSet, computedState]
    )

    const onClickHandler = useCallback(() => {
      if (value === undefined) setState(prev => !prev)
      else if (typeof onChange === 'function') onChange(!value)
    }, [value, onChange])

    useImperativeHandle(ref, () => ({ value: state }))

    return (
      <Button
        className={className}
        onClick={onClickHandler}
        dataSet={dataSet}
        disabled={disabled}
        tabbable={tabbable}
        title={title}
        onClickBlur={onClickBlur}
        onFocus={onFocus}
      >
        <Icon active={computedState} strokeWidth={5} />
        {label}
      </Button>
    )
  })
)

CheckBox.displayName = 'Casus-Components-CheckBox'

export default CheckBox
