import React, { FunctionComponent, useMemo } from 'react'

import { LOCATION_TYPES } from '___types'
import { useTableCell } from '../../customHooks'
import Marker from '../Marker'
import Segment from '../Segment'
import { WizardLayoutRightPaneEditorSegmentTableCellProps, wizardLayoutRightPaneEditorSegmentTableCellClasses as classes } from '../../../../..'

export const Cell: FunctionComponent<WizardLayoutRightPaneEditorSegmentTableCellProps> = React.memo(({ id }) => {
  const { contentIds, styles } = useTableCell(id)

  const render = useMemo(() => {
    return contentIds?.map(contentId => {
      if (contentId.slice(0, 7) === 'marker:') return <Marker key={contentId} id={contentId.slice(7)} type={LOCATION_TYPES.SEGMENTS} parent={id} />
      return <Segment key={contentId} id={contentId} />
    })
  }, [contentIds, id])

  const cellClasses = [classes.wrapper, ...styles].filter(cn => cn)

  return (
    <td id={id} className={cellClasses.join(' ')}>
      {render}
    </td>
  )
})

Cell.displayName = 'WizardLayout-RightPane-Editor-Segment-Table-Cell'

export default Cell
