import { useQuery, QueryFunctionContext, useQueryClient, InfiniteData, UseQueryResult } from 'react-query'

import { ITemplate } from '___types'
import { templatesAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'
import { MAPPED_TEMPLATES_FILTERS } from '___types/types.template'

const getTemplateQueryFunction = ({ queryKey }: QueryFunctionContext) => templatesAPI.getTemplate(queryKey[1] as string, queryKey[2] as boolean)

export const useFetchTemplate = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const templateQuery = useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE, id].concat(publicFlow ? 'public' : []),
    queryFn: getTemplateQueryFunction,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: () => {
      const allTemplates = Object.values(MAPPED_TEMPLATES_FILTERS).reduce((allTemplates: ITemplate[], filter) => {
        const templatesQuery = queryClient.getQueryData([QUERY_KEYS.TEMPLATES, filter]) as InfiniteData<ITemplate[]>
        const templates = (templatesQuery?.pages || []).flat(1)
        return allTemplates.concat(templates) as ITemplate[]
      }, [])
      return allTemplates.find(template => template.id === id)
    },
  })
  return templateQuery as UseQueryResult<ITemplate>
}

export default useFetchTemplate
