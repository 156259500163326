import React, { FunctionComponent, useMemo } from 'react'
import useStore, { AddMarkerConditionalAction, RemoveMarkerConditionalAction, WizardSplitsSelector } from '___store'

import { Conditionals } from '___types'
import { CheckBox } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { classes } from '../../..'

type UseStoreHookResultType = {
  wizardSplits: WizardSplitsSelector
  addMarkerConditional: AddMarkerConditionalAction
  removeMarkerConditional: RemoveMarkerConditionalAction
}

type Props = { id: string; conditionals: Conditionals }
export const Splits: FunctionComponent<Props> = React.memo(({ id, conditionals }) => {
  const { wizardSplits, addMarkerConditional, removeMarkerConditional } = useStore(
    'selectWizardSplits',
    'addMarkerConditional',
    'removeMarkerConditional'
  ) as UseStoreHookResultType

  const render = useMemo(
    () =>
      Object.entries(wizardSplits || {}).map(([splitId, label]) => {
        const active = conditionals?.splits?.includes(splitId)
        return (
          <Button
            key={`Marker-Connect-ShowHide-Splits-${splitId}`}
            className={classes.configEntry.markerConfiguration.fragment.connect.tabs.conditionals.entry}
            onClick={() =>
              active ? removeMarkerConditional({ id, conditional: splitId }) : addMarkerConditional({ id, type: 'splits', conditional: splitId })
            }
            tertiary={active || undefined}
          >
            <span>{label}</span>
            <CheckBox active={active} strokeWidth={5} />
          </Button>
        )
      }),
    [wizardSplits, conditionals, removeMarkerConditional, id, addMarkerConditional]
  )

  return <div className={classes.configEntry.markerConfiguration.fragment.connect.tabs.conditionals.splits}>{render}</div>
})

Splits.displayName = ''

export default Splits
