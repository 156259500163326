import React from 'react'
import C from './C_Logo/C_Logo'

import './style.css'
import { classes } from '.'
import { DOCXDocument } from 'assets/svgIconComponents'

const SkeletonListFile: React.FC = () => {
  return (
    <div className={classes.listView.wrapper} data-loading={true}>
      <div className={classes.listView.preview}>
        <DOCXDocument data-loading={true} />
      </div>
      <div className={classes.listView.base.wrapper}>
        <span className={classes.listView.base.title} />
        <span className={classes.listView.base.relativeTime}>
          <C category="" />
        </span>
      </div>
    </div>
  )
}

export default SkeletonListFile

