import React from 'react'

import { className } from './index'

const Rename = React.memo(() => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8906 17.1484C11.4609 17.1484 11.2344 16.9453 11.2344 16.6016C11.2344 16.2422 11.4609 16.0547 11.8906 16.0547H13.5391V1.61719H11.8906C11.4609 1.61719 11.2344 1.42188 11.2344 1.07031C11.2344 0.71875 11.4609 0.53125 11.8906 0.53125H16.4219C16.8438 0.53125 17.0703 0.71875 17.0703 1.07031C17.0703 1.42188 16.8438 1.61719 16.4219 1.61719H14.7891V16.0547H16.4219C16.8438 16.0547 17.0703 16.2422 17.0703 16.6016C17.0703 16.9453 16.8438 17.1484 16.4219 17.1484H11.8906ZM1.78125 14.6875C1.25 14.6875 0.921875 14.375 0.921875 13.8672C0.921875 13.7031 0.945312 13.5391 1.02344 13.3359L4.49219 3.97656C4.72656 3.32812 5.16406 3.01562 5.84375 3.01562C6.53906 3.01562 6.97656 3.32812 7.21875 3.98438L10.6719 13.2969C10.7422 13.5078 10.7734 13.6719 10.7734 13.8516C10.7734 14.3594 10.4219 14.6875 9.88281 14.6875C9.36719 14.6875 9.09375 14.4766 8.92969 13.9297L8.05469 11.5H3.60938L2.74219 13.9297C2.57031 14.4766 2.29688 14.6875 1.78125 14.6875ZM4.05469 10.0469H7.625L5.86719 4.98438H5.82031L4.05469 10.0469Z"
      fill="#001640"
      fill-opacity="0.4"
    />
  </svg>
))

export default Rename

