import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { v4 as uuid } from 'uuid'

import { useFetchTemplate } from 'queries/templates'
import { DocumentGeneration } from 'components/features/documentGeneration/DocumentGeneration'
import { DocumentGenerationLayout } from 'components/features/documentGeneration/layout/DocumentGenerationLayout'
import { DocumentGenerationProvider } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import { initQuestionsToAnswers } from 'components/features/documentGeneration/services/initQuestionsToAnswers'
import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'

import PurchaseTemplateModal from 'pages/Authenticated/Templates/components/PurchaseTemplateModal/PurchaseTemplateModal'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useUser } from 'queries/user/useUser'
import { DocumentGenerationWizard } from 'Wizard'
import { useDocumentsCreate } from 'queries/documents'
import { useSelector } from 'react-redux'
import { useFetchExternalServiceEntries } from 'queries/externalServices/useFetchExternalServiceEntries'
import { CASUS_KEYSTRINGS } from 'Wizard/constants'

const Questionnaire: React.FC = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const { push, goBack } = useHistory()
  const { data = {} as any, isLoading, refetch } = useFetchTemplate(templateId)
  const { isExpired } = useSubscriptionCheck()
  const user = useUser()
  const version = user?.version

  const selectedExternalIds = useSelector((state: any) => state.externalServices.services)

  const { locations } = data || {}
  const externalServiceFields = (
    Object.values(Object.assign({}, locations?.segments, locations?.text)).reduce(
      (acc: any, markerArray: any) =>
        acc.concat(
          markerArray.reduce((accumulated: any, { questionId }: any) => {
            if (questionId?.slice(0, 10) === '_external_') {
              const split = questionId.slice(10).split(':')
              accumulated.push({ id: split[0], field: split[1] })
            }
            return accumulated
          }, [])
        ),
      []
    ) as any
  ).reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: (acc[cur.id] || []).concat(cur.field) }), {})

  const selectedExternalInfo = Object.entries(selectedExternalIds).reduce(
    (acc, [service, id]) => Object.assign(acc, { [service]: { id, fields: externalServiceFields[service] } }),
    {}
  )

  const { data: externalServiceEntries, isLoading: serviceEntriesLoading } = useFetchExternalServiceEntries(selectedExternalInfo) as any

  const externalAnswers = useMemo(
    () =>
      Object.entries(externalServiceFields).reduce(
        (acc, [service, fieldArray]: any) =>
          acc.concat(
            fieldArray.reduce((accumulated: any, field: any) => {
              const id = `_external_${service}:${field}`
              const value = externalServiceEntries && externalServiceEntries[service] && externalServiceEntries[service][field]
              const answer = { id, values: [`${CASUS_KEYSTRINGS.input}:${id}:${value}`] }
              accumulated.push(answer)
              return accumulated
            }, [])
          ),
        []
      ),
    [externalServiceFields, externalServiceEntries]
  )

  // console.log('SELECTED: ', selectedExternalIds)
  // console.log('FIELDS USED: ', externalServiceFields)
  // console.log('ENTRIES: ', externalServiceEntries)

  useEffect(() => {
    if (templateId) refetch()
  }, [templateId, refetch])

  const requirePayment = useIsTemplatePurchaseRequired(templateId)

  const { questions = [], htmlData, isCasus, name, v3 = {} } = data

  const {
    title: v3title,
    dataStructure: v3dataStructure,
    locations: v3locations,
    cssData: v3cssData,
    numberingSystem: v3numberingSystem,
    questions: v3questions,
    questionLayout: v3questionLayout,
  } = v3

  let renderOld = false
  renderOld = version !== 'v3'

  const create = useDocumentsCreate()

  const closeHandler = useCallback(() => push('/documents'), [push])
  const finishHandler = useCallback(
    ({ title, answers }) => {
      create.mutate({
        new: true,
        name: title,
        //@ts-ignore
        v3Answers: answers.map(answer => ({ questionId: answer.id, values: answer.values })),
        templateId,
        category: [],
      })
      push(`/documents`)
    },
    [create, templateId, push]
  )

  // const externalIds = useMemo(
  //   () =>
  //     Object.entries(Object.assign({}, v3locations?.segments, v3locations?.text)).reduce(
  //       (acc, [parentId, markerArray]) =>
  //         //@ts-ignore
  //         acc.concat(markerArray.filter(marker => marker.questionId?.slice(0, 10) === '_external_').map(({ questionId }) => questionId)),
  //       []
  //     ),
  //   [v3locations]
  // )

  // const externalAnswers = useMemo(
  //   () =>
  //     externals.reduce(
  //       (acc, cur) =>
  //         acc.concat(
  //           Object.entries(cur).reduce((accumulated, [field, value]) => {
  //             const externalId = `_external_${cur.id}:${field}`
  //             //@ts-ignore
  //             return externalIds.includes(externalId)
  //               ? //@ts-ignore
  //                 accumulated.concat({ id: externalId, values: [`${CASUS_KEYSTRINGS.input}:${externalId}:${value}`] })
  //               : accumulated
  //             return accumulated
  //           }, [])
  //         ),
  //       []
  //     ),
  //   [externals]
  // )

  const V3component = useMemo(
    () =>
      v3dataStructure ? (
        <DocumentGenerationWizard
          // @ts-ignore
          id={uuid()}
          title={v3title}
          dataStructure={v3dataStructure}
          locations={v3locations}
          cssData={v3cssData}
          numberingSystem={v3numberingSystem}
          questions={v3questions}
          questionLayout={v3questionLayout}
          answers={externalAnswers}
          closeHandler={closeHandler}
          saveHandler={finishHandler}
        />
      ) : null,
    [v3title, v3dataStructure, v3locations, v3cssData, v3numberingSystem, v3questions, v3questionLayout, externalAnswers, closeHandler, finishHandler]
  )

  // console.log('GENERATE DOCUMENT PAGE: ', questions, name, initQuestionsToAnswers(questions))

  const render = useMemo(() => {
    return renderOld ? (
      <DocumentGenerationProvider
        initialData={{
          questions,
          name,
          templateHtmlData: htmlData,
          isCasus,
          mode: 'create',
          answers: questions ? initQuestionsToAnswers(questions) : [],
          templateId: data.id,
          templateContentVersionId: data.contentVersionId,
          cssData: data.cssData,
          templateDataStructure: data.dataStructure,
          documentDataStructure: data.dataStructure,
        }}
      >
        <DocumentGeneration isExpired={isExpired} />
      </DocumentGenerationProvider>
    ) : (
      V3component
    )
  }, [renderOld, data, questions, name, htmlData, isCasus, isExpired, V3component])

  if (requirePayment) return <PurchaseTemplateModal templateId={templateId} open={true} handleClose={goBack} />

  if (isLoading || serviceEntriesLoading)
    return <DocumentGenerationLayout step={2} heading="Einen Moment bitte..." onClose={() => push(`/documents`)} isLoading={true} />

  // console.log('EXTERNAL ANSWERS: ', externalAnswers)

  return render
}

export default Questionnaire
