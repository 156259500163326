import PropTypes from 'prop-types'

import { ORIENTATION, PAPER_NAMES } from './hardcoded/documentSettings'
import { SEGMENT_TAGS, SEGMENT_TYPES } from 'Wizard/constants'

export const numberingSystemType = () => (props, key, componentName) => {
  // console.log('NUMBERING SYSTEM TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          prefix: PropTypes.string,
          type: PropTypes.string.isRequired,
          suffix: PropTypes.string,
          combined: PropTypes.bool,
          combineString: PropTypes.string,
        })
      )
    ).isRequired,
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const tableRowType = () => (props, key, componentName) => {
  // console.log('TABLE ROW TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(SEGMENT_TYPES)).isRequired,
      tag: PropTypes.oneOf(Object.values(SEGMENT_TAGS)).isRequired,
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          type: PropTypes.oneOf(Object.values(SEGMENT_TYPES)).isRequired,
          tag: PropTypes.oneOf(Object.values(SEGMENT_TAGS)).isRequired,
          content: segmentsType(),
          customStyle: PropTypes.string,
          styles: PropTypes.arrayOf(PropTypes.string),
        })
      ).isRequired,
      customStyle: PropTypes.string,
      styles: PropTypes.arrayOf(PropTypes.string),
    }),
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const segmentsType = () => (props, key, componentName) => {
  // console.log('SEGMENTS TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(SEGMENT_TYPES)).isRequired,
        tag: PropTypes.oneOf(Object.values(SEGMENT_TAGS)).isRequired,
        textChunks: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
            customStyle: PropTypes.string,
            styles: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        tableHeader: PropTypes.arrayOf(tableRowType()),
        tableBody: PropTypes.arrayOf(tableRowType()),
        tableFooter: PropTypes.arrayOf(tableRowType()),
        customStyle: PropTypes.string,
        styles: PropTypes.arrayOf(PropTypes.string),
        break: PropTypes.shape({
          type: PropTypes.oneOf(['section', 'page']).isRequired,
          id: PropTypes.string,
          title: PropTypes.string,
          layout: PropTypes.shape({
            orientation: PropTypes.oneOf(Object.values(ORIENTATION)).isRequired,
            paper: PropTypes.oneOf(Object.values(PAPER_NAMES)).isRequired,
            margins: PropTypes.shape({
              top: PropTypes.number,
              left: PropTypes.number,
              bottom: PropTypes.number,
              right: PropTypes.number,
            }),
          }),
        }),
      })
    ).isRequired,
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const dataStructureType = () => (props, key, componentName) => {
  // console.log('DATA STRUCTURE TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.oneOf(Object.values(SEGMENT_TYPES)),
      tag: PropTypes.oneOf(Object.values(SEGMENT_TAGS)),
      segments: segmentsType(),
      customStyle: PropTypes.string,
      styles: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const markerType = type => (props, key, componentName) => {
  // console.log('MARKER TYPE: ', props, key, componentName)
  const shape = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['segments', 'text']).isRequired,
    range: PropTypes.arrayOf(PropTypes.number).isRequired,
    questionId: PropTypes.string,
    optionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultKeep: PropTypes.bool,
  }
  if (type === 'segments') Object.assign(shape, { label: PropTypes.string })
  if (type === 'text') Object.assign(shape, { contentText: PropTypes.string.isRequired })
  const tempPropStructure = {
    [key]: PropTypes.shape(shape),
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const locationsType = () => (props, key, componentName) => {
  // console.log('LOCATIONS TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.shape({
      segments: PropTypes.objectOf(PropTypes.arrayOf(markerType('segments'))).isRequired,
      text: PropTypes.objectOf(PropTypes.arrayOf(markerType('text'))).isRequired,
    }).isRequired,
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const optionType = type => (props, key, componentName) => {
  // console.log('OPTION TYPE: ', props, key, componentName)
  const shape = { id: PropTypes.string.isRequired }
  if (type === 'segments') Object.assign(shape, { markers: PropTypes.arrayOf(PropTypes.string), text: PropTypes.string.isRequired })
  if (type === 'text') Object.assign(shape, { value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  const tempPropStructure = {
    [key]: PropTypes.shape(shape),
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const optionGroupType = type => (props, key, componentName) => {
  // console.log('OPTION GROUP TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      select: PropTypes.oneOf(['single', 'multi']).isRequired,
      minimum: PropTypes.number,
      maximum: PropTypes.number,
      enforceLimit: PropTypes.bool,
      options: PropTypes.arrayOf(optionType(props[key].type)),
      valueType: PropTypes.oneOf(['string', 'number', 'date', 'date-time', 'currency']).isRequired,
    }),
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const advancedQuestionConfigurationType = () => (props, key, componentName) => {
  // console.log('OPTION GROUP TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.shape({
      visibility: PropTypes.oneOf(['show', 'hide']),
      logic: PropTypes.oneOf(['and', 'or']),
      rules: PropTypes.array, // TODO: add rule type
      subQuestionTo: PropTypes.string,
    }),
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const questionType = () => (props, key, componentName) => {
  // console.log('QUESTION TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string,
      markers: PropTypes.arrayOf(PropTypes.string),
      optionGroups: PropTypes.arrayOf(optionGroupType(props[key].type)).isRequired,
      valueType: PropTypes.oneOf(['string', 'number', 'date', 'date-time', 'currency']).isRequired,
      description: PropTypes.string,
      hint: PropTypes.string,
      advanced: advancedQuestionConfigurationType(),
    }),
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const questionsType = () => (props, key, componentName) => {
  // console.log('QUESTIONS TYPE: ', props, key, componentName)
  const tempPropStructure = { [key]: PropTypes.arrayOf(questionType()).isRequired }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const answersType = isRequired => (props, key, componentName) => {
  // console.log('QUESTION TYPE: ', props, key, componentName)
  const tempPropStructure = {}
  tempPropStructure[key] = PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  )
  if (isRequired) tempPropStructure[key] = tempPropStructure[key].isRequired
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}
