import React, { useMemo, useCallback } from 'react'
import useStore from '___store'

import { LANGUAGES, LANGUAGE_INFO } from '___types'
import { extractValueFromInputEvent } from 'utilities/helpers'
import { CheckBox } from 'assets/svgIconComponents'
import { Button, Input, Security, Switch } from 'components/CasusComponents'
import { ButtonGroup, Button as Button2 } from 'components'
import { classes } from '.'
import Integrations from './Integrations'
import Splits from './Splits'
import { classes as classes2 } from '../..'
import ApproverList from './ApproverList'

export const TemplateConfiguration = React.memo(() => {
  const {
    wizardTitle,
    wizardDescription,
    wizardLanguages,
    wizardLanguageSelect,
    wizardSignatureSecurityLevel,
    updateWizardTitle,
    updateWizardDescription,
    addLanguage,
    removeLanguage,
    toggleLanguageSelect,
    setSignatureSecurityLevel,
    concludeConfiguring,
  } = useStore(
    'selectWizardTitle',
    'selectWizardDescription',
    'selectWizardLanguages',
    'selectWizardLanguageSelect',
    'selectWizardSignatureSecurityLevel',
    'updateWizardTitle',
    'updateWizardDescription',
    'addLanguage',
    'removeLanguage',
    'toggleLanguageSelect',
    'setSignatureSecurityLevel',
    'concludeConfiguring'
  )

  const { available } = wizardLanguages || {}

  const languageButtons = useMemo(
    () =>
      Object.values(LANGUAGES).map(language => {
        const active = available?.includes(language)
        const info = LANGUAGE_INFO[language]
        return (
          <Button
            key={`language-${language}-toggle-button`}
            onClick={() => (active ? removeLanguage(language) : addLanguage(language))}
            dataSet={{ active: active ? '' : undefined }}
          >
            <CheckBox active={active} />
            <span>{info.label}</span>
            <img src={info.icon} alt={info.label} />
          </Button>
        )
      }),
    [available, removeLanguage, addLanguage]
  )

  const titleInputHandler = useCallback(event => updateWizardTitle(extractValueFromInputEvent('string', event) || ''), [updateWizardTitle])
  const descriptionInputHandler = useCallback(
    event => updateWizardDescription(extractValueFromInputEvent('string', event) || ''),
    [updateWizardDescription]
  )

  return (
    <>
      <div className={classes.wrapper}>
        <div className={`${classes.section.wrapper} name`}>
          <span className={classes.section.title}>NAME</span>
          <Input
            type="multiline"
            defaultValue={wizardTitle}
            placeholder="Give this template a name..."
            debouncedInput={300}
            showActionButtons={false}
            onInput={titleInputHandler}
          />
        </div>
        <div className={`${classes.section.wrapper} description`}>
          <span className={classes.section.title}>DESCRIPTION</span>
          <Input
            type="multiline"
            defaultValue={wizardDescription}
            placeholder="Give this template a description..."
            debouncedInput={300}
            showActionButtons={false}
            onInput={descriptionInputHandler}
          />
        </div>
        <div className={`${classes.section.wrapper} language`}>
          <span className={classes.section.title}>LANGUAGE(S)</span>
          {languageButtons}
          {available?.length > 1 ? (
            <Switch
              value={wizardLanguageSelect === 'multi'}
              onClick={() => toggleLanguageSelect()}
              emphasized={wizardLanguageSelect === 'multi' || undefined}
            >
              <span>Allow multi-select</span>
            </Switch>
          ) : null}
        </div>
        <ApproverList />
        <Integrations />
        <Splits />
        <Security level={wizardSignatureSecurityLevel} onSelect={setSignatureSecurityLevel} />
      </div>
      <div className={classes2.configEntry.footer}>
        <ButtonGroup>
          <Button2 onClick={concludeConfiguring} colorScheme="primary">
            Conclude
          </Button2>
        </ButtonGroup>
      </div>
    </>
  )
})

TemplateConfiguration.displayName = ''

export default TemplateConfiguration
