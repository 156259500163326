import TemplateConfiguration from './TemplateConfig'

import './style.scss'

const classes = {
  wrapper: 'TemplateConfiguration-wrapper',
  section: { wrapper: 'TemplateConfiguration-section-wrapper', title: 'TemplateConfiguration-section-title' },
  integratons: {
    wrapper: 'TemplateConfiguration-Integrations-wrapper',
    config: 'TemplateConfiguration-Integrations-config',
    cueInput: 'TemplateConfiguration-Integrations-cueInput',
    entry: 'TemplateConfiguration-Integrations-entry',
    entryInfo: 'TemplateConfiguration-Integrations-entryInfo',
  },
  splits: {
    wrapper: 'TemplateConfiguration-Splits-wrapper',
    entry: 'TemplateConfiguration-Splits-entry',
  },
}

export { TemplateConfiguration, classes }
export default TemplateConfiguration
