import { FormEventHandler, KeyboardEventHandler } from 'react'

import { CasusInputType } from '___types'
import Input from './Input'

import './style.scss'

const classes = {
  wrapper: 'Casus-Components-Input-wrapper',
  pre: 'Casus-Components-Input-contentEditablePre',
  input: 'Casus-Components-Input-input',
  actionButtons: {
    wrapper: 'Casus-Components-Input-actionButtons-wrapper',
    confirmButton: 'Casus-Components-Input-actionButtons-confirmButton',
    discardButton: 'Casus-Components-Input-actionButtons-discardButton',
  },
} as const

type InputOptions = {
  inputTime?: boolean
  min?: number | string
  max?: number | string
  step?: number | string
  format?: string
}

export type InputProps = {
  id?: string
  className?: string
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  transparent?: boolean
  disabled?: boolean
  dataSet?: Record<keyof any, unknown>
  type: CasusInputType
  defaultValue?: string | number
  value?: string | number
  placeholder?: string
  options?: InputOptions
  debouncedInput?: number
  autoselect?: boolean
  showActionButtons?: boolean
  onInput?: FormEventHandler<HTMLPreElement | HTMLInputElement>
  onConfirm?: FormEventHandler<HTMLPreElement | HTMLInputElement>
  onDiscard?: FormEventHandler<HTMLPreElement | HTMLInputElement>
}
export type InputTextProps = {
  disabled?: boolean
  defaultValue?: string
  value?: string
  placeholder?: string
  format?: string
  autoselect?: boolean
  onInput: FormEventHandler<HTMLInputElement>
  onEnter: KeyboardEventHandler<HTMLInputElement>
  onEscape: KeyboardEventHandler<HTMLInputElement>
}
export type InputMultilineProps = {
  disabled?: boolean
  defaultValue?: string
  value?: string
  placeholder?: string
  autoselect?: boolean
  onInput: FormEventHandler<HTMLPreElement>
  onEnter: KeyboardEventHandler<HTMLPreElement>
  onEscape: KeyboardEventHandler<HTMLPreElement>
}
export type InputNumberProps = {
  disabled?: boolean
  defaultValue?: number
  value?: number
  min?: number | string
  max?: number | string
  step?: number | string
  autoselect?: boolean
  onInput: FormEventHandler<HTMLPreElement>
  onEnter: KeyboardEventHandler<HTMLPreElement>
  onEscape: KeyboardEventHandler<HTMLPreElement>
}
export type InputDateTimeProps = {
  disabled?: boolean
  defaultValue?: string
  value?: string
  inputTime?: boolean
  autoselect?: boolean
  onInput: FormEventHandler<HTMLInputElement>
  onEnter: KeyboardEventHandler<HTMLInputElement>
  onEscape: KeyboardEventHandler<HTMLInputElement>
}
export type InputCurrencyProps = {
  onInput: FormEventHandler<HTMLInputElement>
}

export { Input, classes }
export default Input
