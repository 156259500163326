import { ViewSignatureRequestModal } from './ViewSignatureRequestModal'

const classes = {
  container: {
    title: 'ViewSignatureModal-Container-Title-wrapper',
    wrapper: 'ViewSignatureModal-Container-wrapper',
  },
}

export { classes }

export default ViewSignatureRequestModal

