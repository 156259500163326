import React, { FunctionComponent, useMemo } from 'react'
// import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, SaveProps, className } from './index'

const ZOOM = 1

const Save: FunctionComponent<SaveProps> = React.memo(({ strokeWidth = 5 }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  // const maskId = useMemo(uuid, [])

  return (
    <svg className={`${className} Save`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <path
        d="M 30 20 h 30 l 20 20 v 30 c 0 5 -5 10 -10 10 h -40 c -5 0 -10 -5 -10 -10 v -40 c 0 -5 5 -10 10 -10 Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <line x1="30" x2="60" y1="35" y2="35" stroke="currentColor" strokeWidth={Number(strokeWidth) * 2} />
      <circle cx="50" cy="58" r={Number(strokeWidth) * 1.75} stroke="none" fill="currentColor" />
    </svg>
  )
})

export { Save }
export default Save
