import Api from './api'

import { AuthCalendlyResponseData, CalendarEvent, User } from 'types'
import {firebase, prettyUser} from 'utils'
import {
  UserType,
  USER_TYPE_LAWYER_ADMIN,
  USER_TYPE_LAWYER_EMPLOYEE,
} from 'constants/user-types'
import AuthApi from 'api/auth.api'
import { auth } from 'config/firebase'

export interface ICreateDocumentFooterHeaderRequestProps {
  startAt: number
  endAt: null
  text: string
  imageData?: string
  imageHeight: string
  imageWidth: 'auto'
}

export interface IUpdateDocumentFooterHeaderRequestProps {
  id: string
  startAt?: number
  endAt?: null
  text?: string
  imageData?: string
  imageHeight?: string
  imageWidth?: 'auto'
}

export interface IGetLegalAdviceEvents {
  upcomingEvents: CalendarEvent[]
  pastEvents: CalendarEvent[]
}

export interface IUpdateUserProfileApiRequest {
  firstName?: string
  lang?: string,
  lastName?: string
  twoFaEnabled?: boolean
  jobPosition?: string
  companyInfo?: { name: string; website: string; employeeCount: string; field: string }
  type?: UserType
  imageData?: string
}

interface IUpdatePhoneNumberApi {
  phoneNumber: string
}

class UserApi extends Api {
  public constructor() {
    super('/users')
  }

  public getMe = async (): Promise<User> => {
    try {
      const res = await this.api().get('/me')
      return prettyUser(res.data.data.user)
    } catch (err) {
      throw err
    }
  }

  public update = async (profileData: IUpdateUserProfileApiRequest): Promise<User> => {
    const res = await this.api().post(`/update`, profileData)
    return prettyUser(res.data.data.user)
  }

  public sendVerificationEmail = async () => {
    return await this.api().post('/request/email/verification', {})
  }

  public updatePhoneNumber = async (data: IUpdatePhoneNumberApi) => {

    const user: firebase.User | null = auth.currentUser
    if (!user) return (window.location.href = '/logout')

    const res = await this.api().post(`/phone/change`, data)
    return prettyUser(res.data.data.user)
  }

  // Promise<AuthCalendlyResponseData | null>
  public authenticateCalendly = async (code?: string | null): Promise<string | void> => {
    if (!code) return
    const res = await this.api().post('/calendly/auth', { code })
    return res.data.data
  }

  public refreshCalendlyToken = async (
    refreshToken: string
  ): Promise<AuthCalendlyResponseData> => {
    const res = await this.api().post('/calendly/auth/refresh', { refreshToken })
    return res.data.data
  }

  public getCalendlyEvents = async (
    user: User,
    calendlyAuthData?: AuthCalendlyResponseData | null
  ): Promise<IGetLegalAdviceEvents> => {
    let token: null | string = null
    if ([USER_TYPE_LAWYER_ADMIN, USER_TYPE_LAWYER_EMPLOYEE].includes(user.type)) {
      if (!calendlyAuthData) throw new Error('Calendly not authenticated!')
      const { access_token, created_at, expires_in, refresh_token } = calendlyAuthData
      token = access_token
      const currentTime = new Date().getTime()
      const expiredTime = created_at + expires_in
      if (currentTime <= expiredTime) {
        const refreshedData = await this.refreshCalendlyToken(refresh_token)
        AuthApi.setCalendlyAuth(refreshedData)
      }
    }
    let opts = {}
    if (token) opts = { headers: { calauth: token } }
    const res = await this.api().get(`/${token ? 'lawyer' : 'client'}/events`, opts)
    return {
      upcomingEvents: res.data.data.okEvents ?? [],
      pastEvents: res.data.data.canceledEvents ?? [],
    }
  }

  public getCalendlyEventByToken = async (
    token: string
  ): Promise<{ lawyerEmail: string }> => {
    const withRetry: Promise<{ lawyerEmail: string }> = new Promise((resolve, reject) => {
      const timeout = setTimeout(async () => {
        try {
          const res = await this.api().get(`/client/events/${token}`)
          if (res.status === 200) return resolve(res.data.data)
        } catch (err) {
          reject(err)
        }
        clearTimeout(timeout)
      }, 1000)
    })

    let counter = 0
    let data: { lawyerEmail: string } | null = null

    while (true) {
      if (counter > 10 || data) break
      data = await withRetry
      counter++
    }
    return data ? data : Promise.reject('No response data')
  }

  public getUserByEmail = async (email: string): Promise<User> => {
    const res = await this.api().get(`/${email}`)
    return res.data.data.user
  }

  public refreshUser = async () => {
    const firebaseUser = auth.currentUser
    if (!firebaseUser) {
      window.location.href = '/logout'
      return null
    }
    return await AuthApi.authenticate(firebaseUser)
  }

  public document_settings_get = async () => {
    return await this.api().get(`/documents/settings`)
  }

  public document_settings_item_create = async (
    endpoint: 'header' | 'footer',
    data: ICreateDocumentFooterHeaderRequestProps
  ) => {
    return await this.api().post(`/documents/settings/${endpoint}`, data)
  }

  public document_settings_item_edit = async (
    endpoint: 'header' | 'footer',
    data: IUpdateDocumentFooterHeaderRequestProps
  ) => {
    return await this.api().patch(`/documents/settings/${endpoint}/${data.id}`, data)
  }

  public document_settings_item_delete = async (
    endpoint: 'header' | 'footer',
    id: string
  ) => {
    return await this.api().delete(`/documents/settings/${endpoint}/${id}`)
  }
}

const api = new UserApi()

export default api
