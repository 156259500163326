import React, { FunctionComponent, ReactChild, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { mapTemplatesFilter } from '___types'
import { useTemplateList } from '___hooks'
import Button from 'components/CasusComponents/Button'
import Grid from 'components/CasusComponents/Grid'
import { TemplateThumbnail } from 'components/CasusComponents/Template'
import { TemplatesGridProps, classes } from '.'

export const TemplatesGrid: FunctionComponent<TemplatesGridProps> = React.memo(({ filter, onClick }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'components.general' })
  const [nestedFolderIds, setNestedFolderIds] = useState([] as { id: string; name: string }[])
  const lastFolderId = useMemo(() => nestedFolderIds[nestedFolderIds.length - 1]?.id, [nestedFolderIds])
  const {
    list: { isLoading: listIsLoading, isFetching: listIsFetching, error: listError, data },
  } = useTemplateList()

  const templates = useMemo(
    () =>
      (data || []).reduce(
        (result, template) =>
          template
            ? result.concat(
                <TemplateThumbnail
                  key={`Casus-Components-Grid-Templates-TemplateThumbnail-${template.id}`}
                  id={template.id}
                  name={template.name}
                  edited={template.edited}
                  sharedWith={template.sharedWith}
                  // paywallBlocked={template.paywallBlocked}
                  folderId={lastFolderId}
                  onClick={onClick}
                  // beingDeleted={template.beingDeleted}
                />
              )
            : result,
        [] as ReactChild[]
      ),
    [data, lastFolderId, onClick]
  )

  const title = useMemo(() => translate(`${mapTemplatesFilter(filter)}-templates`), [translate, filter])

  const errorMessage = useMemo(() => {
    if (typeof listError === 'string') return listError
    if (listError instanceof Error) return listError.message
    return ''
  }, [listError])

  const breadCrumbs = useMemo(
    () =>
      nestedFolderIds.length ? (
        <>
          {[title].concat(nestedFolderIds.map(folder => folder.name)).map((crumb, i) => (
            <Button onClick={() => setNestedFolderIds(prev => prev.slice(0, i))} transparent>
              {crumb}
            </Button>
          ))}
        </>
      ) : (
        title
      ),
    [nestedFolderIds, title]
  )

  if (!(templates.length || nestedFolderIds.length)) return null

  return (
    <Grid className={classes.wrapper} isLoading={listIsLoading} isFetching={listIsFetching} error={errorMessage} title={breadCrumbs}>
      {templates}
    </Grid>
  )
})

TemplatesGrid.displayName = 'Casus-Components-Grid-Templates'

export default TemplatesGrid
