import { Check, Cross } from 'assets/svgIconComponents'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TextInputPreview from './components'

const classes = {
  wrapper: 'Input-wrapper',
  textarea: 'Input-textarea',
  input: 'Input-input',
  buttonGroup: 'Input-buttonGroup',
  confirmButton: 'Input-confirmButton',
  discardButton: 'Input-discardButton',
}

const setHeight = (node, maxLines, extraLines, perLine = 0.9) => {
  if (node) {
    const value = node.value
    node.setAttribute('style', `height: ${perLine}rem; overflow: hidden; opacity: 0;`)
    node.value = value || node.placeholder
    let style = 'height: ' + (extraLines ? `calc(${node.scrollHeight}px + ${extraLines * perLine}rem)` : `${node.scrollHeight}px`)
    if (maxLines) style += `; max-height: ${maxLines * perLine}rem`
    node.setAttribute('style', style)
    node.value = value
  }
}

export const TextInput = React.memo(
  ({
    id,
    className: cn,
    inputClass: ic,
    disabled,
    defaultValue,
    value,
    onChange,
    onBlur,
    onDiscard,
    needsConfirmation = false,
    placeholder,
    multiline = false,
    maxLines,
    extraLines = 1,
    autofocus = false,
    blurShowUnmodified = false,
    parseLinks = false,
  }) => {
    const [stateValue, setStateValue] = useState(value || defaultValue)
    const [inputNode, setInputNode] = useState(null)

    const ref = useCallback(node => setInputNode(node || null), [])

    const calculatedMultiline = multiline || Boolean((needsConfirmation ? stateValue : value).match('\n'))
    const modified = useMemo(() => stateValue !== (value || defaultValue), [stateValue, value, defaultValue])

    const adjustHeight = useCallback(() => setHeight(inputNode, maxLines, extraLines), [inputNode, maxLines, extraLines])
    useEffect(() => {
      if (multiline) adjustHeight()
    }, [multiline, adjustHeight])

    const className = useMemo(() => [classes.wrapper, cn].filter(cn => cn).join(' '), [cn])
    const textAreaClass = useMemo(() => [classes.textarea, ic].filter(ic => ic).join(' '), [ic])
    const inputClass = useMemo(() => [classes.input, ic].filter(ic => ic).join(' '), [ic])

    useEffect(() => {
      if (multiline !== calculatedMultiline) return inputNode?.focus()
    }, [multiline, calculatedMultiline, inputNode])

    return (
      <div
        id={id}
        className={className}
        data-modified={modified}
        data-can-discard={typeof onDiscard === 'function'}
        style={{ '--unmodified': `"${value || defaultValue || placeholder || ''}"` }}
        data-type="text"
        data-showing-placeholder={Boolean(placeholder && !(value || defaultValue))}
        data-show-unmodified={blurShowUnmodified}
        data-multiline={calculatedMultiline}
        onBlur={event => typeof onBlur === 'function' && onBlur(event, modified, needsConfirmation ? stateValue : value)}
      >
        {calculatedMultiline ? (
          <textarea
            ref={ref}
            className={textAreaClass}
            disabled={disabled}
            tabIndex={disabled ? -1 : 0}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={placeholder || ''}
            wrap="soft"
            value={needsConfirmation ? stateValue : value}
            onInput={event => {
              setStateValue(event.target.value)
              adjustHeight()
              return !needsConfirmation && typeof onChange === 'function' && onChange(event.target.value)
            }}
            onChange={() => {}}
            onKeyDown={event => {
              if (!event.getModifierState('Shift') && event.key === 'Enter' && needsConfirmation) {
                event.preventDefault()
                event.target.blur()
                if (typeof onChange === 'function') onChange(stateValue)
              }
            }}
            autoFocus={autofocus}
            // onFocus={focusEnd}
          />
        ) : (
          <input
            ref={ref}
            className={inputClass}
            disabled={disabled}
            tabIndex={disabled ? -1 : 0}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={placeholder || ''}
            wrap="soft"
            value={needsConfirmation ? stateValue : value}
            onInput={event => {
              setStateValue(event.target.value)
              return !needsConfirmation && typeof onChange === 'function' && onChange(event.target.value)
            }}
            onChange={() => {}}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                if (!event.getModifierState('Shift') && needsConfirmation) {
                  event.preventDefault()
                  event.target.blur()
                  if (typeof onChange === 'function') onChange(stateValue)
                }
                if (event.getModifierState('Shift')) {
                  setStateValue(event.target.value + '\n')
                  if (!needsConfirmation && typeof onChange === 'function') onChange(stateValue + '\n')
                }
              }
            }}
            autoFocus={autofocus}
          />
        )}
        <TextInputPreview content={value || defaultValue || placeholder || ''} parseLinks={parseLinks} />
        {needsConfirmation && modified ? (
          <div className={classes.buttonGroup} data-hide-on-blur={blurShowUnmodified} data-multiline={calculatedMultiline}>
            <button
              className={classes.confirmButton}
              type="button"
              onClick={event => {
                event.target.blur()
                if (typeof onChange === 'function') onChange(stateValue)
              }}
            >
              <Check />
            </button>
            {typeof onDiscard === 'function' ? (
              <button
                className={classes.discardButton}
                type="button"
                onClick={() => {
                  if (inputNode) inputNode.focus()
                  setStateValue(value || defaultValue)
                  if (calculatedMultiline) setTimeout(adjustHeight, 0)
                  onDiscard()
                }}
              >
                <Cross />
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }
)

TextInput.displayName = 'Input'

export default TextInput
