import React from 'react'
// useEffect, useState
import {
  // useHistory,
  useParams,
} from 'react-router-dom'
import './styles.scss'
import {
  Icon,
  // Loader
} from 'components/common'
// import DocumentsApi from 'api/docs.api'
import { useDownloadPublic } from './hooks/useDownloadPublic'
import { useTranslation } from 'react-i18next'
import theme from 'config/themes/theme'
import { usePublicDocument } from './hooks/usePublicDocument'
import { analytics } from 'utils'

// extrapolate classes to a const
const classes = {
  modal: {
    title: 'modal-title',
    intro: 'intro',
    footer: 'footer',
  },
  button: {
    wrapper: 'button',
    download: {
      wrapper: 'download',
    },
  },
  icon: {
    wrapper: 'icon',
    download: {
      wrapper: 'download-icon',
    },
  },
}

const ErrorOccurred = React.memo(({ close, text }) => {
  return (
    <div>
      <div className={classes.modal.intro}>
        <Icon iconName="error" fill={theme.colorz.brand} className={classes.icon.wrapper} />
        <span className={classes.modal.title}>{text}</span>
      </div>
    </div>
  )
})

const FinalStep = React.memo(() => {
  const { documentId } = useParams()

  const document = usePublicDocument(documentId)

  const { pdf, docx } = useDownloadPublic(documentId)

  const { t } = useTranslation()

  const handleDownload = async type => {
    analytics.logEvent('public_flow_download_document', {
      id: documentId,
      templateId: document?.data?.templateId,
      type,
    })

    if (type === 'pdf') {
      pdf.mutate()
    } else {
      docx.mutate()
    }
  }

  const modalContent =
    pdf.isError || docx.isError ? (
      <ErrorOccurred text={t('err_msg_default')} />
    ) : (
      <>
        <div className={classes.modal.title}>
          <Icon iconName="check" fill={theme.colorz.brand} className={classes.icon.wrapper} />
          <span>{t('public_flow_final_step_title')}</span>
        </div>
        <div className={classes.modal.intro}>
          <span>{t('public_flow_final_step_body')}</span>
        </div>
        <div className={classes.modal.intro}>
          <div className={classes.modal.footer}>
            <button disabled={pdf.isLoading} onClick={e => handleDownload('pdf')} className={classes.button.wrapper}>
              <div className={classes.button.download.wrapper}>
                <Icon iconName="pdf" fill={theme.colorz.brand} className={classes.icon.download.wrapper} />
                {!pdf.isLoading ? `${t('download')} .PDF` : t('loading')}
              </div>
            </button>
            <button disabled={docx.isLoading} onClick={e => handleDownload('docx')} className={classes.button.wrapper}>
              <div className={classes.button.download.wrapper}>
                <Icon iconName="word" fill={theme.colorz.brand} className={classes.icon.download.wrapper} />
                {!docx.isLoading ? `${t('download')} .DOCX` : t('loading')}
              </div>
            </button>
          </div>
        </div>
      </>
    )

  return (
    <div className="screen">
      <div className="modal">{modalContent}</div>
    </div>
  )
})

export default FinalStep
