import React, { FunctionComponent } from 'react'
import { SignatureSecurityLevel, Signee } from '___types'

import { Publishing as DocumentPublishing } from './Document.Publishing'
import { Publishing as TemplatePublishing } from './Template.Publishing'

import './style.scss'

export const TemplatePublishingCustomLastStepClasses = {
  wrapper: 'Casus-Components-Publishing-Template-CustomLastStep-wrapper',
  callToAction: 'Casus-Components-Publishing-Template-CustomLastStep-callToAction',
}

export const TemplatePublishingModalContentClasses = {
  wrapper: 'Casus-Components-Publishing-Template-ModalContent-wrapper',
  splits: { wrapper: 'Casus-Components-Publishing-Template-ModalContent-splits-wrapper' },
  section: 'Casus-Components-Publishing-Template-ModalContent-section',
  sectionColumn: 'Casus-Components-Publishing-Template-ModalContent-sectionColumn',
  sectionPrimary: 'Casus-Components-Publishing-Template-ModalContent-sectionPrimary',
  sectionSecondary: 'Casus-Components-Publishing-Template-ModalContent-sectionSecondary',
  expirationSection: 'Casus-Components-Publishing-Template-ModalContent-expirationSection',
}

export const DocumentPublishingModalContentClasses = {
  wrapper: 'Casus-Components-Publishing-Document-ModalContent-wrapper',
  splits: { wrapper: 'Casus-Components-Publishing-Document-ModalContent-splits-wrapper' },
  section: 'Casus-Components-Publishing-Document-ModalContent-section',
  sectionColumn: 'Casus-Components-Publishing-Document-ModalContent-sectionColumn',
  sectionPrimary: 'Casus-Components-Publishing-Document-ModalContent-sectionPrimary',
  sectionSecondary: 'Casus-Components-Publishing-Document-ModalContent-sectionSecondary',
  expirationSection: 'Casus-Components-Publishing-Document-ModalContent-expirationSection',
}

const classes = {
  wrapper: 'Casus-Components-Publishing-wrapper',
  documentModalContent: DocumentPublishingModalContentClasses,
  templateModalContent: TemplatePublishingModalContentClasses,
}

const Publishing: FunctionComponent<PublishingProps> = React.memo(({ id, entity }) => {
  if (entity === 'document') return <DocumentPublishing id={id} />
  if (entity === 'template') return <TemplatePublishing id={id} />
  return null
})

export type PublishingProps = { id: string; entity: 'document' | 'template' }
export type DocumentPublishingProps = { id: string }
export type DocumentPublishingModalContentProps = {
  conclude: (...params: unknown[]) => void
  context: DocumentPublishingModalContentContext
  splits?: Record<string, string>
}
export type DocumentPublishingModalContentContext = {
  notifyEmail: string
  includeEmail: boolean
  previewAvailable: boolean
  signatureAvailable: boolean
  signatureConfig: { concludeText: string; concludeDisabled: boolean; security: SignatureSecurityLevel; signees: Signee[]; message: string }
  expires: boolean
  expirationTime: string | null
  singleUse: boolean
  split: string | null
}
export type TemplatePublishingProps = { id: string }
export type TemplatePublishingModalContentProps = {
  context: TemplatePublishingModalContentContext
  splits?: Record<string, string>
  conclude: (...params: unknown[]) => void
}
export type TemplatePublishingModalContentContext = {
  customLastStepAvailable: boolean
  lastStepConfig: TemplatePublishingCustomLastStepContext
  previewAvailable: boolean
  signatureAvailable: boolean
  signatureConfig: { concludeText: string; concludeDisabled: boolean; security: SignatureSecurityLevel; signees: Signee[]; message: string }
  expires: boolean
  expirationTime: string | null
  singleUse: boolean
  split: string | null
}
export type TemplatePublishingCustomLastStepProps = {
  context?: Record<string, unknown>
  conclude: (...params: unknown[]) => void
}
export type TemplatePublishingCustomLastStepContext = { message: string; ctaLabel: string; ctaLink: string }

export { Publishing, DocumentPublishing, TemplatePublishing, classes }
export default Publishing
