import { useQuery } from 'react-query'
import { QUERY_KEYS, STALE_TIME } from '___queries'
import integrationsApi from 'api/integrations.api'

export const useFetchEmployeeCategories = ({ employeeId, serviceType = 'bamboo-hr' }: { employeeId: string; serviceType: string }) => {
  const employeeCategoriesQuery = useQuery({
    queryKey: [QUERY_KEYS.EXTERNAL_SERVICE_EMPLOYEE_CATEGORIES, { employeeId, serviceType }],
    queryFn: () => integrationsApi.getIntegrationEmployeeCategories(employeeId, serviceType),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    enabled: false,
  })
  return employeeCategoriesQuery
}

export default useFetchEmployeeCategories
