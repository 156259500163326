import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, className } from './index'

const Team = React.memo(props => {
  const { strokeWidth = 8, fill = 'none' } = props
  const maskId = useMemo(uuid, [])

  const path = 'M 30 90 C 20 90 30 60 60 60 C 90 60 100 90 90 90 Z'
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>
        <mask id={maskId} x={`-${strokeWidth}`} y={`-${strokeWidth}`} width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <path d={path} stroke="black" strokeWidth={`${strokeWidth + 10}`} strokeLinejoin="round" fill="black" />
          <ellipse cx="60" cy="35" rx="12" ry="15" stroke="black" strokeWidth={`${strokeWidth + 10}`} fill="black" />
        </mask>
      </defs>
      <path d={path} stroke="currentColor" strokeWidth={`${strokeWidth}`} strokeLinejoin="round" fill={`${fill}`} />
      <ellipse cx="60" cy="35" rx="12" ry="15" stroke="currentColor" strokeWidth={`${strokeWidth}`} fill={`${fill}`} />
      <path
        d="M 10 80 C 0 80 5 55 30 55 C 55 55 60 80 50 80 Z"
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        strokeLinejoin="round"
        fill={`${fill}`}
        mask={`url(#${maskId})`}
      />
      <ellipse cx="30" cy="32" rx="10" ry="12" stroke="currentColor" strokeWidth={`${strokeWidth}`} fill={`${fill}`} mask={`url(#${maskId})`} />
    </svg>
  )
})

export { Team }
export default Team
