import React, { FunctionComponent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDocument, useDocumentList } from '___hooks'
import { Button, Select } from 'components/CasusComponents'
import {
  MainLayoutRightPaneTemplateRelocationModalContentProps,
  mainLayoutRightPaneTemplateRelocationModalContentClasses as classes,
} from 'Layouts/___MainLayout'

export const ModalContent: FunctionComponent<MainLayoutRightPaneTemplateRelocationModalContentProps> = React.memo(({ id, cancel, conclude }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: '' })
  const [folderId, setFolderId] = useState<string>()
  const {
    folders: { data },
  } = useDocumentList()
  const { data: template } = useDocument(id!)

  const currentCategory = useMemo(
    () => (Array.isArray(template?.category) ? (template!.category as string[])[0] : template?.category) || '',
    [template]
  )
  const folders = useMemo(
    () =>
      data?.reduce(
        (result, folder) => (folder.id !== currentCategory ? Object.assign(result, { [folder.id]: folder.name }) : result),
        Object.assign({}, currentCategory ? { unnest: translate('remove-from-folder') } : undefined)
      ) || {},
    [data, currentCategory, translate]
  )

  return (
    <>
      <div className={classes.wrapper}>Where do you want to move this document?</div>
      <div className="Modal-footer">
        <Select className={classes.select} options={folders} value={folderId} placeholder={translate('select-folder')} onSelect={setFolderId} />
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={() => conclude(folderId === 'unnest' ? '' : folderId)} disabled={!folderId ? true : undefined} primary>
          Move Document
        </Button>
      </div>
    </>
  )
})

ModalContent.displayName = 'MainLayout-RightPane-Template-Publishing-ModalContent'

export default ModalContent
