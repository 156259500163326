import React, { useMemo } from 'react'
import { DateTime } from 'luxon'
import useStore from '___store'

// import { Cogwheel, Deselect } from 'assets/svgIconComponents'
// import { Button, ButtonGroup } from 'components'
import { CASUS_CLASSES, CASUS_REGEXES } from 'Wizard/constants'
import { dateTimeISOregex } from 'Wizard/parsing'
import { classes } from '../..'
import { getSpanStyles } from './index'
// import MarkerAssignSelect from './MarkerAssignSelect'
import { createTextContent, parseTextChunks } from 'Wizard/helpers'

// const LeadingButtonGroup = React.memo(({ id, assignedQuestionId, currentQuestionId, assignedOptionIds }) => {
//   return (
//     <div className={CASUS_CLASSES.textMarkerControls} data-position="leading">
//       <div>
//         <ButtonGroup className={classes.marker.text.buttonGroup} collapsed>
//           <MarkerAssignSelect
//             key={currentQuestionId}
//             markerId={id}
//             assignedQuestionId={assignedQuestionId}
//             currentQuestionId={currentQuestionId}
//             assignedOptionIds={assignedOptionIds}
//           />
//         </ButtonGroup>
//       </div>
//     </div>
//   )
// })

// const TrailingButtonGroup = React.memo(({ id }) => {
//   const { unwrapMarker, setConfiguring } = useStore('unwrapMarker', 'setConfiguring')

//   return (
//     <div className={CASUS_CLASSES.textMarkerControls} data-position="trailing">
//       <div>
//         <ButtonGroup className={classes.marker.text.buttonGroup} collapsed>
//           <Button
//             className={classes.marker.text.button}
//             tabbable={false}
//             onClick={() => setConfiguring({ key: 'marker', id })}
//             colorScheme="transparent"
//           >
//             <Cogwheel />
//           </Button>
//           <Button className={classes.marker.text.button} tabbable={false} onClick={() => unwrapMarker({ id })} colorScheme="transparent">
//             <Deselect />
//           </Button>
//         </ButtonGroup>
//       </div>
//     </div>
//   )
// })

// const mapChunkToSpan = (id, text, styles, i = 0) =>
//   (styles.length && (
//     <span key={`text-marker-${id}-chunk-${i}`} className={[CASUS_CLASSES.textChunk, ...styles].join(' ')}>
//       {text}
//     </span>
//   )) ||
//   text

const mapChunkToSpan = (chunk, id, i = 0) => {
  const { type, id: markerId, text, classes, textChunks } = chunk
  if (type === 'marker') return <TextMarker key={markerId} id={markerId} textChunks={textChunks} />
  if (classes?.length < 2) return text
  return (
    <span key={`text-marker-${id}-chunk-${i}`} className={classes.join(' ')}>
      {text}
    </span>
  )
}

export const TextMarker = React.memo(({ id, textChunks }) => {
  const {
    locationById: {
      range = [],
      questionId,
      // optionIds = [],
      defaultKeep,
      keep,
      replace,
      color,
    } = {},
    currentConfiguringQuestion: { id: currentQuestionId } = {},
    textMarkerLocationsByParentId: markers = [],
    mode,
    setConfiguring,
  } = useStore(
    `selectLocationById[${id}]`,
    'selectCurrentConfiguringQuestion',
    `selectTextMarkerLocationsByParentId[${id}]`,
    'selectMode',
    'setConfiguring'
  )

  const markerMode = mode === 'template-creation' ? 'edit' : 'preview'

  const offsetMarkers = useMemo(() => markers.map(marker => ({ id: marker.id, range: marker.range.map(r => r - range[0]) })), [markers, range])

  const chunks = useMemo(() => {
    if (!replace) return createTextContent(parseTextChunks(offsetMarkers, textChunks))
    const replaceTexts =
      replace
        ?.split(CASUS_REGEXES.customTextSplit)
        .filter(s => s)
        .reduce((acc, cur) => {
          const value = (Array.from(cur.matchAll(CASUS_REGEXES.markerReplaceMatchAll)) || [])[0]?.groups?.value
          return acc.concat((value?.match(dateTimeISOregex) && DateTime.fromISO(value))?.toLocaleString() || value)
        }, [])
        .join('; ') || ''
    return [{ type: 'text', text: replaceTexts, classes: getSpanStyles(textChunks) }]
  }, [replace, offsetMarkers, textChunks])

  const content = useMemo(() => chunks.map((chunk, i) => mapChunkToSpan(chunk, id, i)), [chunks, id])

  // const children = useMemo(() => {
  //   const resultingChunks = replace ? [{ spanStyles: getSpanStyles(textChunks), text: replace }] : mapChunks(textChunks)
  //   return resultingChunks.map(({ spanStyles, text }, i) => mapChunkToSpan(id, text, spanStyles, i))
  // }, [textChunks, id, replace])

  // console.groupCollapsed(`REPLACEMENT MARKER %c${id}`, 'color: lightgreen;', 'RENDER!')
  // textChunks.forEach(({ text }) => console.log(text))
  // console.groupEnd()

  const markerClassName = [classes.marker.text.wrapper, CASUS_CLASSES.textMarker].join(' ')

  const availability = currentQuestionId ? (!questionId && 'available') || (questionId !== currentQuestionId && 'unavailable') : undefined

  return (
    <mark
      id={id}
      className={markerClassName}
      data-color-variant={color}
      data-mode={markerMode}
      data-availability={availability}
      data-remove={markerMode === 'preview' ? !(keep === undefined ? defaultKeep : keep) : undefined}
      onClick={event => {
        event.stopPropagation()
        setConfiguring({ key: 'marker', id })
      }}
      // style={{ '--color': !currentQuestionId ? color : undefined }}
    >
      {/* <LeadingButtonGroup id={id} assignedQuestionId={questionId} currentQuestionId={currentQuestionId} assignedOptionIds={optionIds} /> */}
      {content}
      {/* <TrailingButtonGroup id={id} /> */}
    </mark>
  )
})

TextMarker.displayName = 'Wizard-Editor-TextMarker'

export default TextMarker
