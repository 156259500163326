import React, { FunctionComponent, PropsWithChildren, MouseEventHandler, useContext } from 'react'

import Select from './Select'
import SelectOption from './Select.Option'

import './style.scss'

const classes = {
  wrapper: 'Casus-Components-Select-wrapper',
  button: 'Casus-Components-Select-button',
  options: {
    wrapper: 'Casus-Components-Select-options-wrapper',
    entry: 'Casus-Components-Select-options-entry',
  },
} as const

type SelectContextType = { onSelect: MouseEventHandler<HTMLButtonElement>; active: string[]; unselectable: boolean }
export const SelectContext = React.createContext<SelectContextType>({} as SelectContextType)
type SelectContextProviderProps = PropsWithChildren<SelectContextType>
export const SelectContextProvider: FunctionComponent<SelectContextProviderProps> = ({ onSelect, active, unselectable, children }) => {
  return <SelectContext.Provider value={{ onSelect, active, unselectable }}>{children}</SelectContext.Provider>
}
export const useSelectContext = () => {
  const currentSelectContext = useContext(SelectContext)
  if (!currentSelectContext) {
    throw new Error('useSelectContext has to be used within <SelectContext.Provider>')
  }
  return currentSelectContext
}

export type SelectProps = {
  className?: string
  options: Record<string, string>
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  defaultValue?: string | string[]
  value?: string | string[]
  multiselect?: boolean
  unselectable?: boolean
  placeholder?: string
  disabled?: boolean
  dataSet?: Record<keyof any, unknown>
  loading?: boolean
  showSpinner?: boolean
  searchable?: boolean
  entryRender?: (id: string, label: string, unselectCallback: () => void, options: Record<string, string>) => JSX.Element
  onSelect: (id: string, value: string | string[]) => void
}
export type SelectOptionProps = { id: string; label: string }

export { Select, SelectOption, classes }
export default Select
