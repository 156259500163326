import TemplatesApi from 'api/templates.api'
import { useQuery } from 'react-query'
import { queryKeys } from 'queries/queryKeys'

export const useFetchPublicTemplate = (id?: string | null | false) => {
  const query = useQuery([queryKeys.TEMPLATE, id], () => (id ? TemplatesApi.getOne(id, true, true) : null), {
    enabled: false,
    refetchOnWindowFocus: false,
  })
  return query
}

