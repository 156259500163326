import Popup from './Popup'

import './style.scss'

const classes = {
  wrapper: 'Wizard-Popup-wrapper',
  header: { wrapper: 'Wizard-Popup-Header-wrapper' },
  content: 'Wizard-Popup-content',
  footer: { wrapper: 'Wizard-Popup-Footer-wrapper' },
}

export { Popup, classes }
export default Popup
