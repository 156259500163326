import React from 'react'

import { classes } from '.'
import './style.scss'
import Signer from './components/Signer'

const EmptySigners = () => {
  return (
    <div className={classes.container.empty.container}>
      <div className={classes.container.empty.text}>No signers added yet. Add signer from the form above.</div>
    </div>
  )
}

const Signers = ({ signingWith, handleDelete, handleEdit }) => {
  return (
    <div className={classes.container.signers.container}>
      {signingWith.map((signer, index) => {
        return <Signer key={index} signer={signer} handleDelete={handleDelete} handleEdit={handleEdit} />
      })}
    </div>
  )
}

const SignersList = ({ signingWith, handleDelete, handleEdit }) => {
  return (
    <div className={classes.container.wrapper}>
      <div className={classes.container.title}>Added Signers ({signingWith?.length})</div>
      {signingWith?.length > 0 ? <Signers signingWith={signingWith} handleDelete={handleDelete} handleEdit={handleEdit} /> : <EmptySigners />}
    </div>
  )
}

export default SignersList

